import { TMainNewsItem, TMainNewsState } from '../../types/mainNews';
import { defaultState } from '../../reducers/mainNews';
import { ERequestStatus } from '../../types/requestStatus';

export const mkMainNews = (data?: TMainNewsItem[]): TMainNewsItem[] => {
  return [
    {
      title: 'Доля непроданных новостроек превысила 40%',
      slug: 'dolja-neprodannyh-novostroek-prevysila-40',
      id: 330037,
      datePublish: '2023-03-30T13:17:21.038447+00:00',
      noIndex: false,
    },
    {
      title: 'Установку умных счетчиков хотят включить в тарифы ЖКХ',
      slug: 'ustanovku-umnyh-schetchikov-hotjat-vkljuchit-v-tarify-zhkh',
      id: 330036,
      datePublish: '2023-03-28T12:17:21.038447+00:00',
      noIndex: false,
    },
    {
      title: 'Меры поддержки от Циан в ноябре',
      slug: 'mery-podderzhki-ot-tsian-v-nojabre',
      id: 327681,
      datePublish: '2022-10-24T09:00:00+00:00',
      noIndex: false,
    },
    {
      title: 'В России ввод жилья за семь месяцев увеличился на 32%',
      slug: 'v-rossii-vvod-zhilja-za-sem-mesjatsev-uvelichilsja-na-32',
      id: 326548,
      datePublish: '2022-08-08T14:48:00+00:00',
      noIndex: false,
    },
    {
      title: 'Обязательную установку «умных» счетчиков предложили отсрочить до 2024 года',
      slug: 'objazatelnuju-ustanovku-umnyh-schetchikov-predlozhili-otsrochit-do-2024-goda',
      id: 320092,
      datePublish: '2021-08-24T17:47:00+00:00',
      noIndex: false,
    },
    ...(data || []),
  ];
};

export const mkMainNewsState = (data?: Partial<TMainNewsState>): TMainNewsState => {
  return {
    ...defaultState,
    status: ERequestStatus.Succeed,
    items: mkMainNews(),
    ...data,
  };
};

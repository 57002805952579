import {
  ESetCurrentLayoutActionType,
  ESetInitialPageNumberActionType,
  ESetListingAppendTypeActionType,
  ESetPageTypeActionType,
  ESetFavoriteListingType,
  EToggleSearchingModalActionType,
  TSettingsActions,
} from '../../actions/settings';
import { ISettings } from '../../types/settings';

export const defaultState: ISettings = {
  currentLayout: 'LayoutNew',
  initialPageNumber: 1,
  pageType: '',
  listingAppendType: 'set',
  favoriteListingType: null,
  isSearchingModalOpened: false,
};

export const settingsReducer = (state = defaultState, action: TSettingsActions) => {
  switch (action.type) {
    case ESetCurrentLayoutActionType.Set:
      return { ...state, currentLayout: action.payload };

    case ESetInitialPageNumberActionType.Set:
      return { ...state, initialPageNumber: action.payload };

    case ESetPageTypeActionType.Set:
      return { ...state, pageType: action.payload };

    case ESetListingAppendTypeActionType.Set:
      return { ...state, listingAppendType: action.payload };

    case ESetFavoriteListingType.Set:
      return { ...state, favoriteListingType: action.payload };

    case EToggleSearchingModalActionType.Set:
      return {
        ...state,
        isSearchingModalOpened: action.payload !== undefined ? action.payload : !state.isSearchingModalOpened,
      };

    default:
      return state;
  }
};

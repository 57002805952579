import { ISeoData, ISeoDataAction, ISeoItem } from '../../../types/seo_data';

export const SEO_DATA_SUCCESS = 'seo/SUCCESS_ACTION';
export const SEO_DATA_FAILURE = 'seo/FAILURE_ACTION';

export const initialState: ISeoData = {
  seo: {
    seoUrl: '',
    seoTitle: '',
    seoDescription: '',
    seoKeywords: '',
    noIndex: undefined,
  },
};

const defaultAction: ISeoDataAction = {
  type: '',
  payload: initialState,
};

export const seoDataReducer = (state = initialState, action = defaultAction) => {
  switch (action.type) {
    case SEO_DATA_SUCCESS:
      return Object.assign({}, state, {
        seo: action.payload.seo,
      });
    case SEO_DATA_FAILURE:
      return Object.assign({}, state);
    default:
      return state;
  }
};

export const getSeoSuccess = (seo: ISeoItem) => {
  return {
    type: SEO_DATA_SUCCESS,
    payload: { seo },
  };
};

const getSeoFailure = (detail: string, status: string) => {
  return {
    type: SEO_DATA_FAILURE,
    error: { detail, status },
  };
};

export const options = {
  onSuccess: getSeoSuccess,
  onError: getSeoFailure,
};

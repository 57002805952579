import { ISliderNameAndAudienceType, TSliderState } from '../../types/slider';
import { ERequestStatus } from '../../types/requestStatus';

/** Создает пустую структуру слайдера с именем */
export const createSlider = ({ name, audienceAccessType }: ISliderNameAndAudienceType): TSliderState => ({
  name,
  title: undefined,
  items: [],
  status: ERequestStatus.Initial,
  audienceAccessType,
});

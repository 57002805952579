import React, { useCallback, useState } from 'react';

import { MenuItemMobile } from './components/MenuItemMobile';
import { TMainMenuGroupProps } from './types';
import * as s from './MainMenuGroupMobile.css';
import { Submenu } from './components/Submenu';

/**
 * Группа главного меню (мобилка)
 */
export const MainMenuGroupMobile = ({ items, onClick, activeItemSlugs }: TMainMenuGroupProps) => {
  /** Слаг раскрытого пункта */
  const [openedItemSlug, setOpenedItemSlug] = useState(activeItemSlugs.itemSlug);

  /**
   * Обрабатывает клик по пункту меню
   */
  const handleItemClick = useCallback(
    (slug: string, isItemAsGroup: boolean) => {
      // Если кликаем по группе, то выше событие не отправляем
      if (isItemAsGroup) {
        setOpenedItemSlug(openedItemSlug === slug ? '' : slug);

        return;
      }

      // Кликать можно только по пунктам без вложенных подпунктов
      onClick({ itemSlug: slug, subItemSlug: '' });
      setOpenedItemSlug('');
    },
    [onClick, openedItemSlug],
  );

  /**
   * Обрабатывает клик по пункту подменю
   */
  const handleSubItemClick = useCallback(
    (itemSlug: string, subItemSlug) => {
      onClick({ itemSlug, subItemSlug });
    },
    [onClick],
  );

  return (
    <ul className={`${s['wrapper']}`}>
      {items.map(({ items, slug, url, ...otherProps }) => {
        return (
          <MenuItemMobile
            key={`menu-item-${slug}`}
            url={url}
            isOpened={openedItemSlug === slug}
            isActive={!items?.length && activeItemSlugs.itemSlug === slug}
            onClick={() => handleItemClick(slug, Boolean(items?.length))}
            {...otherProps}
          >
            {items?.length && (
              <Submenu
                slug={slug}
                items={items}
                activeSubItemSlug={activeItemSlugs.subItemSlug}
                onClick={subItemSlug => handleSubItemClick(slug, subItemSlug)}
              />
            )}
          </MenuItemMobile>
        );
      })}
    </ul>
  );
};

import { TUserState } from '../../../../types/user';
import { IFastFiltersUserSchema } from '../../../../repositories/monolith-python/entities/schemas/FastFiltersUserSchema';
import { defaultState } from '../../../../reducers/user';

/**
 * Приводит пользователя к формату TUserState
 */
export function prepareUser(user: IFastFiltersUserSchema): TUserState {
  return {
    ...defaultState,
    ...user,
  } as TUserState;
}

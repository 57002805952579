import React from 'react';
import { Avatar } from '@cian/ui-kit';
import { HonestWork, PopupDirection } from '@cian/honestwork-component';

import s from './UserCard.css';

interface IUserCardParams {
  /** Ссылка на изображение */
  imageSrc?: string;
  /** Имя пользователя */
  name: string;
  /** Описание */
  description?: string;
  /** Размер */
  size?: 'L' | 'M' | 'XS';
  /** Иконка доверенного пользователя */
  isTrusted?: boolean;
  /** Ссылка пользователя */
  url?: string;
  /** Событие клика */
  onClick?(e: React.MouseEvent): void;
}

/**
 * Карточка пользователя
 */
export const UserCard = ({
  imageSrc,
  name,
  description,
  size = 'M',
  isTrusted = false,
  url,
  onClick,
}: IUserCardParams) => {
  const sizes = {
    L: 64,
    M: 40,
    XS: 30,
  };

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (onClick) {
      onClick(e);
    }
  };

  return (
    <div className={s['wrapper']}>
      <div className={s['image']} onClick={handleClick}>
        {url ? (
          <a href={url} onClick={handleClick}>
            <Avatar src={imageSrc} type="user" size={sizes[size]} />
          </a>
        ) : (
          <Avatar src={imageSrc} type="user" size={sizes[size]} />
        )}
      </div>

      <div className={s['content']}>
        <div className={s['name-wrapper']}>
          {url ? (
            <a href={url} className={s['name']} onClick={handleClick} title={name}>
              {name}
            </a>
          ) : (
            <div className={s['name']} onClick={handleClick} title={name}>
              {name}
            </div>
          )}

          {isTrusted && <HonestWork className={s['icon-trusted']} direction={PopupDirection.RIGHT} />}
        </div>

        {description && (
          <div className={s['description']} title={description}>
            {description}
          </div>
        )}
      </div>
    </div>
  );
};

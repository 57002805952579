import { useEffect, useState } from 'react';

import { useCurrentListing } from '../useCurrentListing';

/**
 * Слушает мету и высчитывает общее количество страниц
 */
export const useCurrentListingTotalPages = () => {
  const {
    meta: { total: totalPosts, limit },
  } = useCurrentListing();
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    if (!totalPosts || !limit) {
      setTotalPages(0);

      return;
    }

    setTotalPages(Math.ceil(totalPosts / limit));
  }, [limit, totalPosts]);

  return totalPages;
};

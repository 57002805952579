import { ERequestStatus } from '../../../types/requestStatus';
import { TRecommendedOfferItemsState } from '../../../types/recommendedOffers';
import { EGetOffersFromHistoryActionType, TGetOffersFromHistory } from '../../../actions/recommendedOffers';

export const defaultState: TRecommendedOfferItemsState = {
  status: ERequestStatus.Initial,
  items: [],
};

export const offersFromHistoryReducer = (state = defaultState, action: TGetOffersFromHistory) => {
  switch (action.type) {
    case EGetOffersFromHistoryActionType.Loading:
      return { ...state, status: ERequestStatus.Loading };

    case EGetOffersFromHistoryActionType.Succeed:
      return { ...state, items: action.payload, status: ERequestStatus.Succeed };

    case EGetOffersFromHistoryActionType.Failed:
      return { ...state, status: ERequestStatus.Failed };

    default:
      return state;
  }
};

import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { TThunkDispatch } from '../../types/redux';
import { getAuthorArticles, getAuthorArticlesReset } from '../../actions/authorArticles';
import { selectAuthorArticles } from '../../selectors/authorArticles';
import { selectCurrentPost } from '../../selectors/posts/selectCurrentPost';
import { ApplicationContext, buildPostUrl } from '../../utils';
import { EType } from '../../repositories/journal/entities/journal/JournalListAttributesSchema';
import s from './AuthorArticlesContainer.css';

/** Список статей автора */
export const AuthorArticlesContainer = () => {
  const dispatch = useDispatch<TThunkDispatch>();
  const articles = useSelector(selectAuthorArticles);
  const {
    attributes: { seoTitle: titleAsAuthorName },
  } = useSelector(selectCurrentPost);
  const {
    custom: { authorArticlesLimit },
  } = useContext(ApplicationContext);
  const [authorName, setAuthorName] = useState<string | undefined>();

  /**
   * Запрашивает список статей автора, если еще ни разу не запрашивали
   */
  useEffect(() => {
    if (!titleAsAuthorName || titleAsAuthorName === authorName || !authorArticlesLimit) {
      return;
    }

    dispatch(getAuthorArticles({ authorName: titleAsAuthorName, limit: authorArticlesLimit })).finally();
  }, [dispatch, authorArticlesLimit, titleAsAuthorName, authorName]);

  /**
   * Запоминает изменение автора
   */
  useEffect(() => {
    if (titleAsAuthorName !== authorName) {
      setAuthorName(titleAsAuthorName);
    }
  }, [dispatch, titleAsAuthorName, authorName]);

  /**
   * Сбрасывает статьи автора при размонтировании
   */
  useEffect(() => {
    return () => {
      dispatch(getAuthorArticlesReset());
    };
  }, [dispatch]);

  if (!articles?.length) {
    return null;
  }

  return (
    <div className={s['wrapper']}>
      <h2 className={s['title']}>Статьи автора</h2>
      <ul className={s['list-wrapper']}>
        {articles.map(({ title, id, slug }, index) => (
          <li key={`author-article-${index}`} className={s['item-wrapper']}>
            <Link to={buildPostUrl({ type: EType.Articles, slug, id })} className={s['link']}>
              {title}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

/* eslint-disable */
//tslint:disable

import { IAuthorsLinkSchema } from './AuthorsLinkSchema';
import { ISimilarJournalInfoSchema } from '../similar_journal/SimilarJournalInfoSchema';

export interface IJournalAttributesSchema {
  /** На правах рекламы **/
  advertising?: boolean | null;
  /** Автор **/
  author?: string | null;
  /** Ссылки на авторов **/
  authorsLink?: IAuthorsLinkSchema[] | null;
  /** Описание блокировки **/
  blockDescription?: string | null;
  /** Причина блокировки **/
  blockReason?: string | null;
  /** Категория **/
  category?: string | null;
  /** Количество комментарий **/
  commentsCount?: number | null;
  /** Содержание **/
  content?: string | null;
  /** Дата публикации **/
  datePublish: string;
  /** Отключение комментарий **/
  disableComments?: boolean | null;
  /** Количество дизлайков **/
  dislikesCount?: number | null;
  /** ID сущности **/
  id?: number | null;
  /** Ссылка на изображение **/
  image?: string | null;
  /** Ссылка на миниатюру **/
  imageThumbnail?: string | null;
  /** Ссылка на миниатюру для мобильных устройств **/
  imageThumbnailMobile?: string | null;
  /** Количество лайков **/
  likesCount?: number | null;
  /** Без индекса **/
  noIndex?: boolean | null;
  /** Количество просмотров **/
  numViews?: number | null;
  /** Регионы **/
  regions?: number[] | null;
  /** Рубрики (для статей) **/
  rubrics?: number[] | null;
  /** Описание для поисковой оптимизации **/
  seoDescription?: string | null;
  /** Ключевые слова для поисковой оптимизации **/
  seoKeywords?: string | null;
  /** Заголовок для поисковой оптимизации **/
  seoTitle?: string | null;
  /** Похожие статьи/новости **/
  similarJournals?: ISimilarJournalInfoSchema[] | null;
  /** Слаг **/
  slug: string;
  /** Подзаголовок **/
  subtitle?: string | null;
  /** Список тегов **/
  tags?: string[] | null;
  /** Телеграм канал **/
  telegramChannel?: ETelegramChannel | null;
  /** Заголовок **/
  title: string;
  /** Тип **/
  type: EType;
  /** Дата обновления **/
  updatedAt: string;
  /** Количество голосов **/
  votesCount?: number | null;
}

export enum ETelegramChannel {
  /** Коммерческая (CRE) **/
  Commercial = 'commercial',
  /** Частным (B2C) **/
  Private = 'private',
  /** Профессионалам (B2B) **/
  Professionals = 'professionals',
}
export enum EType {
  /** Статьи **/
  Articles = 'articles',
  /** Блоги **/
  Blogs = 'blogs',
  /** 'Новости **/
  News = 'news',
  /** Вопросы **/
  Questions = 'questions',
  /** Опросы **/
  Quizzes = 'quizzes',
}

import { IApplicationState } from '../../../shared/types/redux';
import { IApplicationContext } from '../../../shared/types/applicationContext';
import { createReduxStore } from '../../../shared/utils/redux';
import { setConfigNode } from '../../../shared/reducers/modules/config/config';
import { fetchUser } from '../../../shared/reducers/modules/current_user';
import { getRegions } from '../../../shared/reducers/modules/regions/regions';

export function createApplicationState(context: IApplicationContext) {
  const { config } = context;

  const initialState = config.getStrict<IApplicationState>('initialState');

  const store = createReduxStore(context, true, initialState);
  // const auth = getCookie('CIAN_GK');

  // TODO это получение юзера, если он авторизирован для проверки доступов
  const state = store.getState();
  store.dispatch(setConfigNode(state.config.regionsApiScheme, state.config.regionsApiHost));
  // if (auth) {
  store.dispatch(fetchUser());
  // }
  store.dispatch(getRegions(state.config.regionsApiScheme, state.config.regionsApiHost));

  return store;
}

import React from 'react';

import * as s from './MainArticleAndMainNewsLayout.css';

interface IMainArticleAndMainNewsLayout {
  articleNode: React.ReactNode;
  newsNode: React.ReactNode;
}

/** Стилевой шаблон для главной статьи и блока новостей */
export const MainArticleAndMainNewsLayout = ({ articleNode, newsNode }: IMainArticleAndMainNewsLayout) => (
  <div className={s['wrapper']}>
    <div className={s['main-article-wrapper']}>{articleNode}</div>

    <div className={s['main-news-wrapper']}>
      <div className={s['main-news-inner-wrapper']}>{newsNode}</div>
    </div>
  </div>
);

/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TGetJournalModel,
  IGetJournalRequest,
  IMappers,
  IGetJournalResponse,
  IGetJournalResponseError,
  TGetJournalResponse,
} from './types';

const defaultConfig: TGetJournalModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'GET',
  microserviceName: 'journal',
  pathApi: '/v1/get-journal/',
  hostType: 'api',
} as TGetJournalModel;

function createGetJournalModel(parameters: IGetJournalRequest): TGetJournalModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetJournalOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IGetJournalRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchGetJournal<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IGetJournalOptions<TResponse200, TResponse400>): Promise<TResponse200 | TResponse400 | TGetJournalResponse> {
  const { response, statusCode } = await httpApi.fetch(createGetJournalModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetJournalResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IGetJournalResponseError);
    }
  }

  return { response, statusCode } as TGetJournalResponse;
}

export { defaultConfig, createGetJournalModel, fetchGetJournal };

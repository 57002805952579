import * as React from 'react';

import { IUser } from '../../types/user';
import { IListItemProps } from '../../types/list_item_props';
import { ListContent } from '../list/list_content';
import { ListItem } from '../list_item/list_item';

import s from './read_topic.css';

interface IProps {
  user: IUser;
  showMobile?: boolean;
  showDesktop?: boolean;
  items: IListItemProps[];
}

export const ReadTopic = (props: IProps) => (
  <div
    className={`cg-col-24
        ${s['read-topic']}
        ${props.showMobile && s['read-topic-mobile']}
        ${props.showDesktop && s['read-topic-dekstop']}`}
  >
    <div className="cg-row">
      <div className={`cg-col-16 cg-col-xs-24 ${s['read-topic__content']}`}>
        <div className={`${s['read-topic__header']}`}>
          <h2 className={s['read-topic__header_text']}>Читайте по теме</h2>
        </div>
        <ListContent
          currentUser={props.user}
          items={props.items}
          listItemClass={ListItem}
          containerStyle={s['list-content']}
          scrollComments={() => {}}
        />
      </div>
    </div>
  </div>
);

import { ERequestStatus } from '../../types/requestStatus';
import { TQuestionListState } from '../../types/questionList';
import { EOrdering } from '../../repositories/journal/entities/journal/JournalBlogsMetaV2Schema';
import {
  EGetQuestionListActionType,
  TQuestionListActions,
  EResetQuestionListActionType,
} from '../../actions/questionList';

export const defaultState: TQuestionListState = {
  status: ERequestStatus.Initial,
  meta: {
    b2b: false,
    limit: 18,
    offset: 0,
    regionId: 0,
    category: null,
    tag: null,
    total: 0,
    ordering: EOrdering.Date_publish,
  },
  items: [],
};

export const questionListReducer = (state = defaultState, action: TQuestionListActions) => {
  switch (action.type) {
    case EGetQuestionListActionType.Loading:
      return { ...state, status: ERequestStatus.Loading };

    case EGetQuestionListActionType.Succeed:
      return { ...state, ...action.payload, status: ERequestStatus.Succeed };

    case EGetQuestionListActionType.AppendSucceed:
      return {
        ...state,
        meta: action.payload.meta,
        items: [...state.items, ...action.payload.items],
        status: ERequestStatus.Succeed,
      };

    case EGetQuestionListActionType.Failed:
      return { ...state, status: ERequestStatus.Failed };

    case EResetQuestionListActionType.Reset:
      return defaultState;

    default:
      return state;
  }
};

import { useEffect, useState } from "react";
import * as React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Spinner } from "@cian/ui-kit";
import debounce from 'lodash/debounce';

import { formatDate } from '../../../app/helpers/format_helper';
import { ISearchItem } from '../../types/search';
import { getCategory } from '../../../app/helpers/category_helper';
import { declineNumeric } from '../../../app/helpers/format_helper';

import s from './search.css';

interface IProps {
  items: ISearchItem[];
  closeSearch(): void;
  performSearch(value: string): void;
  isEmpty: boolean;
}

export const Search: React.FC<IProps> = props => {
  let browserHistory = useHistory();
  const [searchValue, setSearchValue] = useState('');

  const searchFunc = debounce(() => performSearch(), 300);
  let searchContainer: HTMLDivElement | null;
  let search: HTMLDivElement | null;

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    document.addEventListener('keyup', closeSearchKeyPress);
    document.addEventListener('click', closeSearchClick);
    return () => {
      document.body.style.overflow = 'auto';
      document.removeEventListener('keyup', closeSearchKeyPress);
      document.removeEventListener('click', closeSearchClick);
    };
  }, []);

  function onSearchValueChange(value: string) {
    setSearchValue(value);
    searchFunc();
  }

  function renderSuggestions() {
    return (
      <div className={s['result-global']}>
        {
          props.items.map((item: ISearchItem, i: number) => {
            if (item.type === 'tags') {
              return (
                <Link
                    to={`/magazine/?tag=${item.attributes.name}`}
                    onClick={() => props.closeSearch()}
                    key={`search-item-${i}`}
                    className={s['result-global__item--hash']}>
                  <h4>#{item.attributes.name}</h4>
                  <span>{item.attributes.objectsCount} {
                    declineNumeric(item.attributes.objectsCount, ['материал', 'материала', 'материалов'])
                  }</span>
                </Link>
              );
            } else {
              const category = getCategory(item.attributes.type);
              return (
                <Link
                    to={`/${category.linkType}-${item.attributes.slug}-${item.attributes.id}`}
                    onClick={() => props.closeSearch()}
                    key={`search-item-${i}`}
                    className={s['result-global__item--category']}>
                  {getCategories(item)}
                  <h4>{item.attributes.title}</h4>
                  <span>{formatDate(item.attributes.datePublish)}</span>
                </Link>
              );
            }
          })
        }
      </div>
    );
  }

  const getCategories = (item: ISearchItem) => {
    const { attributes: { rubrics } } = item;

    const categories = rubrics && rubrics.map((rubric, index) => {
      return (
        <span
          key={`${index}_${rubric.id}`}
          className={s['result-global__item--category__type']}
        >
          {rubric.name}
        </span>
      );
    });

    return <div>{categories}</div>;
  }

  function performSearch() {
    if (searchValue && (searchValue.length > 0)) {
      props.performSearch(searchValue);
    }
  }

  const renderList = () => {
    if ((searchValue.length >= 3) && (props.items.length === 0)) {
      if (props.isEmpty) {
        return <div className={s['search-form__no-result']}>
          К сожалению ничего не нашлось
        </div>;
      } else {
        return (
          <div className={s['search-preloader']}>
            <Spinner size={48} />
          </div>
        );
      }
    } else {
      return renderSuggestions();
    }
  }

  const closeSearchKeyPress = (e: KeyboardEvent) => {
    if (e.keyCode === 27) {
      props.closeSearch();
    }
  }

  const closeSearchClick = (e: Event) => {
    let elem = e.target;

    while (elem) {
        if (elem === searchContainer) {
          props.closeSearch();
          return;
        }

        if (elem === search) {
          return;
        }

        elem = (elem as any).parentElement;
    }
  }

  return (
    <div
        ref={(searchContainer) => searchContainer = searchContainer}
        className={s['search-container']}>
      <div
        ref={(search) => search = search}
        className={`${s['search']} cg-col-14 cg-col-xs-24`}>
        <form
            className={s['search-form']}
            onSubmit={(e) => {
              e.preventDefault();
              browserHistory.push(`/magazine/?search=${encodeURIComponent(searchValue)}`);
              props.closeSearch();
            }}>
          <span className="search-form__input-container">
            <input
              type="text" placeholder="Поиск" autoComplete="off"
              className="search-form__input"
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                onSearchValueChange((e.currentTarget as HTMLInputElement).value)
              }}
              value={ searchValue }
            />
          </span>
          <button className={s['search-form__submit']} />
          <div
              onClick={() => props.closeSearch()}
              className={s['search-form__reset']} />
        </form>
        { renderList() }
      </div>
    </div>);
}

import { useEffect, useState } from 'react';

/**
 * Вырезает скрипты из контента и вставляет их в body
 */
export const useContentScripts = (scriptSources: string[]) => {
  const [insertedScripts, setInsertedScripts] = useState<HTMLScriptElement[]>([]);

  useEffect(() => {
    scriptSources.forEach(scriptSource => {
      if (!document.body.querySelector(`script[src="${scriptSources}"]`)) {
        const script = document.createElement('script');
        script.src = scriptSource;
        document.body.appendChild(script);
        setInsertedScripts(values => [...values, script]);
      }
    });

    return () => {
      insertedScripts.forEach(insertedScript => {
        return document.body.removeChild(insertedScript);
      });
    };
  }, [insertedScripts, scriptSources]);
};

import React, { useCallback, useState } from 'react';

import { MenuItemDesktop } from './components/MenuItemDesktop';
import { TMainMenuGroupProps } from './types';
import { Submenu } from './components/Submenu';
import { debounce } from '../../utils';
import * as s from './MainMenuGroupDesktop.css';

/**
 * Группа главного меню (десктоп)
 */
export const MainMenuGroupDesktop = ({ items, onClick, activeItemSlugs }: TMainMenuGroupProps) => {
  /** Слаг наведенного пункта */
  const [hoveredItemSlug, setHoveredItemSlug] = useState('');

  /**
   * Обрабатывает клик по пункту меню
   */
  const handleItemClick = useCallback(
    (slug: string) => {
      onClick({ itemSlug: slug, subItemSlug: '' });
      // сбрасываем hover
      setHoveredItemSlug('');
    },
    [onClick],
  );

  /**
   * Обрабатывает клик по пункту подменю
   */
  const handleSubItemClick = useCallback(
    (itemSlug: string, subItemSlug) => {
      onClick({ itemSlug, subItemSlug });
      // сбрасываем hover
      setHoveredItemSlug('');
    },
    [onClick],
  );

  /**
   * Обрабатывает наведение/отведение курсора на пункт меню
   */
  const handleMouseEnterOrLeave = debounce((slug: string) => {
    setHoveredItemSlug(slug);
  }, 100);

  return (
    <ul className={`${s['wrapper']}`}>
      {items.map(({ items, slug, url, ...otherProps }) => {
        return (
          <MenuItemDesktop
            key={`menu-item-${slug}`}
            url={url}
            isHovered={hoveredItemSlug === slug}
            isActive={activeItemSlugs.itemSlug === slug}
            onMouseEnter={() => handleMouseEnterOrLeave(slug)}
            onMouseLeave={() => handleMouseEnterOrLeave('')}
            onClick={() => url && handleItemClick(slug)}
            {...otherProps}
          >
            {items?.length && (
              <Submenu
                slug={slug}
                items={items}
                activeSubItemSlug={activeItemSlugs.subItemSlug}
                onClick={subItemSlug => handleSubItemClick(slug, subItemSlug)}
              />
            )}
          </MenuItemDesktop>
        );
      })}
    </ul>
  );
};

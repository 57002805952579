import * as React from 'react';

import { LoadError } from '../../shared/components/load_error/load_error'

interface IProps {
  [key: string]: any
}
interface IState {
  loading: boolean;
  CurrentComponent?: any;
}

interface IAsyncConfig {
  resolve: any;
}

export const asyncComponent = (config: IAsyncConfig) => {
  return class AsyncComponentDecorator extends React.Component<IProps, IState> {
    public constructor(props: IProps) {
      super(props);

      this.state = {
        loading: true
      }
    }

    componentDidMount() {
      config.resolve().then((component: any) => {
        this.setState({
          loading: false,
          CurrentComponent: component.default
        })
      }).catch(() => {
        this.setState({
          loading: false,
          CurrentComponent: LoadError
        })
      })
    }

    render() {
      const { loading, CurrentComponent } = this.state;

      if (loading) return null;

      return <CurrentComponent {...this.props} />
    }
  }
}

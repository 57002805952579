/* eslint-disable */

//tslint:disable

import { IModel, IModelResponse } from '@cian/http-api/shared/model';
import { IArticleAuthorDataSchema } from '../../entities/journal/ArticleAuthorDataSchema';

export type TGetArticlesAuthorModel = IModel<IGetArticlesAuthorRequest, TGetArticlesAuthorResponse>;

export interface IGetArticlesAuthorRequest {
  /** Имя автора **/
  authorName?: string | null;
  /** Аудитория **/
  b2b?: boolean | null;
  /** Количество результатов **/
  limit?: number | null;
  /** Сортировка **/
  ordering?: EOrdering | null;
}

export type TGetArticlesAuthorResponse = IGetArticlesAuthorResponse200 | IGetArticlesAuthorResponse400;

export interface IGetArticlesAuthorResponse200 extends IModelResponse<IGetArticlesAuthorResponse> {
  statusCode: 200;
}

export interface IGetArticlesAuthorResponse400 extends IModelResponse<IGetArticlesAuthorResponseError> {
  statusCode: 400;
}

export interface IGetArticlesAuthorResponse {
  /** Данные **/
  data: IArticleAuthorDataSchema[];
}

export interface IGetArticlesAuthorResponseError {
  errors: any[];
  message: string;
}

export type TResponses = IGetArticlesAuthorResponse | IGetArticlesAuthorResponseError;

export enum EOrdering {
  /** Обсуждаемое **/
  Comments_count = 'comments_count',
  /** Дата публикации **/
  Date_publish = 'date_publish',
  /** Популярное **/
  Num_views = 'num_views',
}

export interface IMappers<TResponse200, TResponse400> {
  200(response: IGetArticlesAuthorResponse): TResponse200;
  400(response: IGetArticlesAuthorResponseError): TResponse400;
}

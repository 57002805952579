import React, { useEffect, useState } from 'react';
import { Button } from '@cian/ui-kit';

import { useTimeout, useWindowScrollTrend } from '../../../../../utils';
import { ReactPortal } from '../../../../../components/ReactPortal';
import * as s from './DownloadAllDocumentsPortalButton.css';

interface IDownloadAllDocumentsPortalButton {
  transitionDuration?: number;
  onClick(e: React.MouseEvent): void;
}

/**
 * "Плавающая" кнопка в портале
 */
export const DownloadAllDocumentsPortalButton = ({
  transitionDuration = 200,
  onClick,
}: IDownloadAllDocumentsPortalButton) => {
  const [className, setClassName] = useState('');
  const [isReady, setIsReady] = useState(false);
  const scrollTrend = useWindowScrollTrend();

  /**
   * Откладывает анимацию показа кнопки на некоторое время,
   * чтобы избежать дребезгов скрола при рендере
   */
  useTimeout(() => {
    setClassName('_top');
    setIsReady(true);
  }, 1000);

  /**
   * Определяет имя класса-модификатора в зависимости от
   * тенденции скрола
   */
  useEffect(() => {
    setClassName(scrollTrend === 'top' ? '_top' : '');
  }, [scrollTrend]);

  return (
    <ReactPortal wrapperId={'download-all-documents-portal-button'}>
      <div
        className={`${s['wrapper']} ${isReady ? s[className] : ''}`}
        style={{ transitionDuration: `${transitionDuration}ms` }}
      >
        <Button size={'M'} fullWidth onClick={onClick}>
          Скачать все шаблоны
        </Button>
      </div>
    </ReactPortal>
  );
};

import { ERequestStatus } from './requestStatus';

export interface IRegionsItem {
  id: number;
  displayName: string;
  baseHost?: string;
  mainTownId?: number | null;
}

export enum ERegionsStatus {
  Initial = 'initial',
  Loading = 'loading',
  Succeed = 'succeed',
  Failed = 'failed',
}

export interface IRegions {
  items: IRegionsItem[];
  status: ERegionsStatus | ERequestStatus;
}

export interface IRegionsAction {
  type: string;
  payload: IRegionsItem[];
}

export interface IRegionsResponse {
  id: number;
  name: string;
  penName: string;
}

import * as React from 'react';
import { Location } from 'history';

import { HonestWork, PopupDirection } from '@cian/honestwork-component';

import { IComment } from '../../types/posts';
import { formatDateFull } from '../../../app/helpers/format_helper';
import { canEditDelete } from '../../../app/helpers/comments_helper';
import { IUser } from '../../types/user';
import { permissionsFull, permissionsExtend } from './permissions';
import { CommentForm } from './comment_form';
import { ICommentState } from '../../types/posts';
import { getCleanText } from '../../../app/helpers/make_html_helper';
import { avatar } from "./avatar";

import s from './comments.css';

interface IProps {
  location: Location;
  user: IUser;
  likesShown: boolean;
  canComment: boolean;
  answer: IComment;
  onCommentDelete(id: number, email: string): void;
  onCommentHide(id: number, email: string): void;
  onCommentSubmit(comment: string, userId: number, fullName: string, email: string, parentID: number | null, subs: boolean): void;
  commentsState: ICommentState;
  parentID: number | null;
  onCommentEdit(id: number, comment: string, email: string): void;
  cardType: string;
}

interface IState {
  editingComment: boolean;
  replyTo: string;
  likesCount: number;
  dislikesCount: number;
  currentUserLike: boolean;
  currentUserDislike: boolean;
  savedFields?: {
    comment: string;
    name: string;
    email: string;
    subscribe: boolean;
  };
}

export class Answer extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);

    this.state = {
      editingComment: false,
      replyTo: '',
      likesCount: props.answer.likesCount || 0,
      dislikesCount: props.answer.dislikesCount || 0,
      currentUserLike: false,
      currentUserDislike: false,
    };
  }

  public componentWillReceiveProps(nextProps: IProps) {
    if (!nextProps.commentsState.error && nextProps.commentsState.loaded) {
      this.setState({
        replyTo: '',
        editingComment: false,
        likesCount: this.state.likesCount,
        dislikesCount: this.state.dislikesCount,
        currentUserLike: this.state.currentUserLike,
        currentUserDislike: this.state.currentUserDislike,
      });
    }
  }

  public render() {
    const { answer } = this.props;

    return (
      <div
          id={`${answer.id}`}
          className={`
            ${s['comments__list-answer_item']}
            ${this.isSelected() ? s['comments__list_item-new'] : ''}`}>
        <div className={s['comments__item_wrap-info']}>
          {answer.user && avatar(answer.user)}
          { !answer.user && <div className={s['comments__form_photo-default']}/> }
          <div className={s['comments__item_info']}>
            <span className={s['comments__item_name']}>
              {this.userName}
              { answer.user && answer.user.userTrust &&
                <HonestWork
                  className={s['comments__item_honest']}
                  direction={PopupDirection.RIGHT}
                />
              }
            </span>
            <span className={s['comments__item_date']}>
              {formatDateFull(answer.datePublish)}
            </span>
          </div>
        </div>
        <p
            dangerouslySetInnerHTML={{ __html: getCleanText(answer.comment) }}
            className={s['comments__item_text']} />
        <div className={`${s['comments__item_bottom-line']} ${s['answer__item_bottom-line']}`}>
          { this.isCanEdit &&
            <span
                onClick={() => this.editComment(answer.user.fullName || answer.user.companyName)}>
              Редактировать
            </span>
          }
          { this.isCanDelete &&
            <span
                onClick={() => this.props.onCommentDelete(answer.id, answer.user.email)}>
              Удалить
            </span>
          }
          { this.isCanHide &&
            <span
                onClick={() => this.props.onCommentHide(answer.id, answer.user.email)}>
              Скрыть
            </span>
          }
          </div>
          { this.state.editingComment &&
            <CommentForm
                onOpen={() => {}}
                onClose={() => this.setState({
                  replyTo: '',
                  editingComment: false,
                  likesCount: this.state.likesCount,
                  dislikesCount: this.state.dislikesCount,
                  currentUserLike: this.state.currentUserLike,
                  currentUserDislike: this.state.currentUserDislike,
                })}
                onCommentSubmit={(
                  comment: string,
                  userId: number,
                  fullName: string,
                  email: string,
                  parentID: number | null,
                  subs: boolean,
                ) => this.props.onCommentSubmit(comment, userId, fullName, email, parentID, subs)}
                commentsState={this.props.commentsState}
                replyTo={this.state.replyTo}
                parentID={this.props.parentID}
                editingComment={this.state.editingComment}
                onCommentEdit={this.props.onCommentEdit}
                isOpened={true}
                user={this.props.user}
                comment={this.props.answer}
                saveFields={this.saveFields}
                savedFields={this.state.savedFields}
                containerStyle={s['answer__comment-form']}
                cardType={this.props.cardType} />
          }
      </div>
    );
  }

  private get isAuthor() {
    const { user, answer } = this.props;

    return user && answer.user && (user.userId === answer.user.userId);
  }

  private get isCanEdit() {
    const { answer } = this.props;

    return this.isAuthor && canEditDelete(answer.datePublish) && !this.state.editingComment;
  }

  private get isCanDelete() {
    const { answer } = this.props;

    return this.isAuthor && canEditDelete(answer.datePublish);
  }

  private get isCanHide() {
    return this.props.user &&
      (this.props.user.roles.includes(permissionsFull) ||
            this.props.user.roles.includes(permissionsExtend));
  }

  private isSelected() {
    const hash = this.props.location.hash;
    return (
      hash &&
      Number(hash.replace('#', '')) === this.props.answer.id
    );
  }

  private editComment = (replyTo?: string) => {
    this.setState({
      replyTo: replyTo || '',
      editingComment: true,
      likesCount: this.state.likesCount,
      dislikesCount: this.state.dislikesCount,
      currentUserLike: this.state.currentUserLike,
      currentUserDislike: this.state.currentUserDislike,
    });
  }

  private saveFields = (comment: string, name: string, email: string, subscribe: boolean) => {
    this.setState({
      replyTo: this.state.replyTo,
      editingComment: this.state.editingComment,
      likesCount: this.state.likesCount,
      dislikesCount: this.state.dislikesCount,
      currentUserLike: this.state.currentUserLike,
      currentUserDislike: this.state.currentUserDislike,
      savedFields: {
        comment,
        name,
        email,
        subscribe,
      },
    });
  }

  private get userName() {
    const { answer } = this.props;
    const name = answer.user && answer.user.userId > 0
      ? (answer.user.fullName || answer.user.companyName || `ID: ${answer.user.userId}`)
      : 'Аноним';

    if (answer.user) {
      if (answer.user.specialistLink) {
        return <a className={s['comments__item_name-text']} href={`${answer.user.specialistLink}`}>{name}</a>;
      }
    }

    return <span className={s['comments__item_name-text']}>{name}</span>;
  }
}

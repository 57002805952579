import React, { forwardRef } from 'react';
import { Splide, SplideSlide, SplideTrack, Options } from '@splidejs/react-splide';
import { Image } from '@cian/ui-kit';

import { IExtractAndReplacePhotoGallerySlideItem } from '../../../../containers/post_card/helpers';
import * as s from './ThumbsGallery.css';

export interface IThumbsGallery {
  slides: IExtractAndReplacePhotoGallerySlideItem[];
  activeIndex: number;
  options?: Options;
  children?: React.ReactNode;
}

/**
 * Фотогалерея миниатюр
 */
export const ThumbsGallery = forwardRef<Splide, IThumbsGallery>(({ slides, activeIndex, options, children }, ref) => {
  const defaultOptions: Options = {
    arrows: false,
    fixedWidth: 64,
    fixedHeight: 64,
    gap: 8,
    isNavigation: true,
    pagination: false,
    keyboard: true,
    focus: 'center',
  };

  return (
    <Splide ref={ref} className={s['thumbs-gallery']} hasTrack={false} options={{ ...defaultOptions, ...options }}>
      <SplideTrack>
        {slides.map(({ image }, index) => (
          <SplideSlide
            key={index}
            className={`${s['slide']} ${activeIndex === index ? s['is-active'] : ''}`}
            tabIndex={index}
          >
            <Image src={image} borderRadius={0} objectFit={'cover'} />
          </SplideSlide>
        ))}
      </SplideTrack>

      {children}
    </Splide>
  );
});

ThumbsGallery.displayName = 'ThumbsGallery';

import { HttpBadStatusCodeError } from '@cian/peperrors/shared';

import { TThunkAction } from '../../../types/redux';
import { getAuthorArticlesLoading } from './getAuthorArticlesLoading';
import { getAuthorArticlesSucceed } from './getAuthorArticlesSucceed';
import { getAuthorArticlesFailed } from './getAuthorArticlesFailed';
import {
  fetchGetArticlesAuthor,
  IGetArticlesAuthorRequest,
  TGetArticlesAuthorResponse,
} from '../../../repositories/journal/v1/get-articles-author';

export interface IGetAuthorArticlesParams {
  authorName: string;
  ordering?: IGetArticlesAuthorRequest['ordering'];
  limit?: number;
}

export const getAuthorArticles = ({
  authorName,
  ordering,
  limit,
}: IGetAuthorArticlesParams): TThunkAction<Promise<void>> => {
  return async (dispatch, getState, context) => {
    const { httpApi, logger } = context;
    const {
      userNew: { isB2BModeEnabled },
    } = getState();

    dispatch(getAuthorArticlesLoading());

    try {
      const { response, statusCode }: TGetArticlesAuthorResponse = await fetchGetArticlesAuthor({
        httpApi,
        parameters: {
          b2b: isB2BModeEnabled,
          authorName,
          ordering,
          limit,
        },
      });

      if (statusCode !== 200 || !response?.data) {
        await Promise.reject(
          new HttpBadStatusCodeError('Не удалось загрузить статьи автора', statusCode, 'actions.getAuthorArticles'),
        );

        return;
      }

      dispatch(getAuthorArticlesSucceed(response.data));
    } catch (e) {
      logger.error(e, {
        domain: 'actions.getAuthorArticles',
      });

      dispatch(getAuthorArticlesFailed());
    }
  };
};

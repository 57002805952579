/* eslint-disable */

//tslint:disable

import { IModel, IModelResponse } from '@cian/http-api/shared/model';
import { IBlogQuestionListDataV2Schema } from '../../entities/journal/BlogQuestionListDataV2Schema';
import { IJournalBlogsMetaV2Schema } from '../../entities/journal/JournalBlogsMetaV2Schema';

export type TGetBlogListModel = IModel<IGetBlogListRequest, TGetBlogListResponse>;

export interface IGetBlogListRequest {
  /** ID автора **/
  authorId?: number | null;
  /** Аудитория **/
  b2b?: boolean | null;
  /** Название категории **/
  category?: string | null;
  /** Лимит **/
  limit?: number | null;
  /** Сдвиг **/
  offset?: number | null;
  /** Сортировка **/
  ordering?: EOrdering | null;
  /** ID региона **/
  regionId?: number | null;
  /** Название тега **/
  tag?: string | null;
}

export type TGetBlogListResponse = IGetBlogListResponse200 | IGetBlogListResponse400;

export interface IGetBlogListResponse200 extends IModelResponse<IGetBlogListResponse> {
  statusCode: 200;
}

export interface IGetBlogListResponse400 extends IModelResponse<IGetBlogListResponseError> {
  statusCode: 400;
}

export interface IGetBlogListResponse {
  /** Данные **/
  data: IBlogQuestionListDataV2Schema[];
  /** Мета данные **/
  meta: IJournalBlogsMetaV2Schema;
}

export interface IGetBlogListResponseError {
  errors: any[];
  message: string;
}

export type TResponses = IGetBlogListResponse | IGetBlogListResponseError;

export enum EOrdering {
  /** Обсуждаемое **/
  Comments_count = 'comments_count',
  /** Дата публикации **/
  Date_publish = 'date_publish',
  /** Понравившееся **/
  Likes_count = 'likes_count',
  /** Популярное **/
  Num_views = 'num_views',
}

export interface IMappers<TResponse200, TResponse400> {
  200(response: IGetBlogListResponse): TResponse200;
  400(response: IGetBlogListResponseError): TResponse400;
}

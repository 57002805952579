import { ERequestStatus } from '../../types/requestStatus';
import { TSimilarArticlesState } from '../../types/similarArticles';
import { EGetSimilarArticlesActionType, TSimilarArticlesActions } from '../../actions/similarArticles';

export const defaultState: TSimilarArticlesState = {
  status: ERequestStatus.Initial,
  items: [],
};

export const similarArticlesReducer = (state = defaultState, action: TSimilarArticlesActions) => {
  switch (action.type) {
    case EGetSimilarArticlesActionType.Loading:
      return { ...state, status: ERequestStatus.Loading };

    case EGetSimilarArticlesActionType.Succeed:
      return { ...state, items: action.payload, status: ERequestStatus.Succeed };

    case EGetSimilarArticlesActionType.Failed:
      return { ...state, status: ERequestStatus.Failed };

    case EGetSimilarArticlesActionType.Reset:
      return { ...state, ...defaultState };

    default:
      return state;
  }
};

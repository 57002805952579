import React from 'react';

import { MenuItemMobile } from './components/MenuItemMobile';
import { IMenuItem, TMenuItemCommonProps } from './types';
import * as s from './MainMenuGroupMobile.css';

export type TMainMenuSimpleGroupMobileProps = Omit<IMenuItem, 'slug' | 'items'> &
  Omit<TMenuItemCommonProps, 'isActive'> & {
    /** Скоуп для вложения */
    children?: React.ReactNode;
    /** Развернуто ли группа меню */
    isOpened?: boolean;
  };

/**
 * Упрощенная группа главного меню (мобилка)
 * Не содержит вложенных items.
 * Управляется ключом isOpen
 */
export const MainMenuSimpleGroupMobile = ({ ...otherProps }: TMainMenuSimpleGroupMobileProps) => {
  return (
    <ul className={`${s['wrapper']}`}>
      <MenuItemMobile {...otherProps} />
    </ul>
  );
};

import * as React from 'react';

import { IPostCard } from '../../types/post_card';
import { IUser } from '../../types/user';

import { Permission } from '../../../app/permissions/permission';

import s from './moder_bar.css';

interface IProps {
  user: IUser;
  model: IPostCard;
  onHide(): void;
  onEdit(): void;
  onPublish(): void;
}

export const ModerBar = (props: IProps) => {
  const permission = new Permission(props.user, props.model);
  const canEdit = permission.canEdit();
  const canPublish = permission.canPublish();
  const canHide = permission.canHide();

  if (canEdit || canPublish || canHide) {
    return (
      <div className={s['advice-card-header-editbar']}>
        {
          canHide &&
            <div
                onClick={() => props.onHide()}
                className={s['advice-card-header-editbar_hide']}>
              <span className={s['hide-icon']} />
              <span> Скрыть</span>
            </div>
        }
        {
          canEdit &&
            <div
                onClick={() => props.onEdit()}
                className={s['advice-card-header-editbar_edit']}>
              <span className={s['edit-icon']} />
              <span> Редактировать</span>
            </div>
        }
        {
          canPublish &&
            <div
                onClick={() => props.onPublish()}
                className={s['advice-card-header-editbar_publish']}>
              <span className={s['publish-icon']} />
              <span> Опубликовать</span>
            </div>
        }
      </div>
    );
  } else {
    return (<div />);
  }
};

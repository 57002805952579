import React from 'react';

import { Skeleton, RoundSkeleton } from '../../Skeleton';
import * as s from './DocumentCardListingItem.css';

/**
 * Скелетон для карточки списка Документ
 */
export const SkeletonDocumentCardListingItem = () => {
  return (
    <div className={s['wrapper']}>
      <div className={s['content-wrapper']}>
        <div className={s['headnote']}>
          <RoundSkeleton size={48} />
          <Skeleton width={100} />
        </div>

        <div>
          <Skeleton width={'90%'} height={20} margin={'0 0 14px 0'} />
          <Skeleton width={'50%'} height={20} />
        </div>

        <Skeleton width={'60%'} />

        <div className={`${s['headnote']} ${s['footnote-wrapper']}`}>
          <Skeleton width={100} />
          <Skeleton width={30} margin={'0 0 0 auto'} />
        </div>
      </div>
    </div>
  );
};

import * as queryString from 'qs';

import { TPollActions } from "../../../actions/poll";
import { EPollActionType } from "../../../actions/poll/types";
import { IPollState } from '../../../types/poll';
// import { TReduxActions } from '../../../types/redux';
import { isServer } from "../../../utils/isomorphic";
import { IApplicationContext } from "../../../types/applicationContext";

interface IVoteInfo {
  vote: number[];
}

interface IVote {
  answers: [any];
  title: string;
  userVote: number[];
  isMultiAnswer: boolean;
  answerCount: number;
  status: string;
}

export const defaultState: IPollState = {
  id: 0,
  answers: [],
  title: '',
  isUserVote: false,
  userVote: [],
  isMultiAnswer: false,
  answerCount: 0,
  status: '',
};

export function pollReducer(state: IPollState = defaultState, {type, payload}: TPollActions) {
  switch (type) {
    case EPollActionType.success:
      return {
        ...state,
        id: payload.id,
        answers: payload.answers,
        title: payload.title,
        isUserVote: payload.userVote.length !== 0 || payload.status !== 'active',
        userVote: payload.userVote,
        isMultiAnswer: payload.isMultiAnswer,
        answerCount: payload.answerCount,
      };

    case EPollActionType.failure:
      return state;

    default:
      return state;
  }
}

export const loadPollInfo = (
    type: string,
    id: string,
    onSuccess: (data: any) => void,
    onFail: () => void,
) => {
  return (dispatch: any, getState: () => any, context: IApplicationContext) => {
    const params = queryString.stringify({
      type,
      object_pk: id,
    });
    return context.makeRequest(
      {
        method: 'GET',
        uri: {
          scheme: isServer ? getState().config.journalApiSchemeNode : getState().config.journalApiScheme,
          host: isServer ? getState().config.journalApiHostNode : getState().config.journalApiHost,
          path: (isServer ? getState().config.journalApiPathNode : getState().config.journalApiPath) + '/v1/get-poll/',
          query: params,
          subdomain: '',
          basePath: '',
        },
      })
      .then((poll: IVote) => {
        onSuccess(poll);
      })
      .catch((err: Error) => {
        onFail();
      },
    );
  };
};

export const submitPoll = (
  type: string,
  objectID: string,
  pollID: number,
  vote: number[],
  onSuccess: (userVote: number[]) => void,
  onFail: () => void,
) => {
  return (dispatch: any, getState: () => any, context: IApplicationContext) => {
    return context.makeRequest(
      {
        method: 'POST',
        uri: {
          scheme: getState().config.journalApiScheme,
          host: getState().config.journalApiHost,
          path: getState().config.journalApiPath + '/v1/poll-vote/',
          subdomain: '',
          basePath: '',
        },
        body: JSON.stringify({
          content_type_model: type,
          vote,
          object_pk: objectID,
          poll_id: pollID,
        }),
      },
    )
    .then((data: IVoteInfo) => {
      onSuccess(data.vote);
    })
    .catch((err: Error) => {
      onFail();
    });
  };
};

import { TMenuActions, EToggleMobileMenuActionType, EGetMenuActionType } from '../../actions/menu';
import { TMenuState } from '../../types/menu';
import { ERequestStatus } from '../../types/requestStatus';

export const defaultState: TMenuState = {
  status: ERequestStatus.Initial,
  isMobileMenuOpened: false,
  items: [],
};

export const menuReducer = (state = defaultState, action: TMenuActions) => {
  switch (action.type) {
    case EGetMenuActionType.Loading:
      return { ...state, status: ERequestStatus.Loading };

    case EGetMenuActionType.Succeed:
      return { ...state, items: action.payload, status: ERequestStatus.Succeed };

    case EGetMenuActionType.Failed:
      return { ...state, status: ERequestStatus.Failed };

    case EToggleMobileMenuActionType.Set:
      return { ...state, isMobileMenuOpened: action.payload };

    default:
      return state;
  }
};

import { ERequestStatus } from '../../types/requestStatus';
import { TDocumentGroupListState } from '../../types/documentGroupList';
import { EGetDocumentGroupListActionType, TDocumentGroupListActions } from '../../actions/documentGroupList';

export const defaultState: TDocumentGroupListState = {
  status: ERequestStatus.Initial,
  items: [],
};

export const documentGroupListReducer = (state = defaultState, action: TDocumentGroupListActions) => {
  switch (action.type) {
    case EGetDocumentGroupListActionType.Loading:
      return { ...state, status: ERequestStatus.Loading };

    case EGetDocumentGroupListActionType.Succeed:
      return { ...state, items: action.payload, status: ERequestStatus.Succeed };

    case EGetDocumentGroupListActionType.Failed:
      return { ...state, status: ERequestStatus.Failed };

    default:
      return state;
  }
};

import { ITypedReduxAction } from '../../../types/redux/actionType';
import { IPostContentRecommendedOffers } from '../../../types/postContentRecommendedOffers';

export enum EGetPostContentOffersActionType {
  Loading = 'postContentRecommendedOffers/getPostContentRecommendedOffers/loading',
  Succeed = 'postContentRecommendedOffers/getPostContentRecommendedOffers/succeed',
  Failed = 'postContentRecommendedOffers/getPostContentRecommendedOffers/failed',
  Reset = 'postContentRecommendedOffers/getPostContentRecommendedOffers/reset',
}

export type TGetPostContentOffersLoading = ITypedReduxAction<
  EGetPostContentOffersActionType.Loading,
  IPostContentRecommendedOffers['id']
>;
export type TGetPostContentOffersSucceed = ITypedReduxAction<
  EGetPostContentOffersActionType.Succeed,
  IPostContentRecommendedOffers
>;
export type TGetPostContentOffersFailed = ITypedReduxAction<
  EGetPostContentOffersActionType.Failed,
  IPostContentRecommendedOffers['id']
>;
export type TGetPostContentOffersReset = ITypedReduxAction<EGetPostContentOffersActionType.Reset>;

export type TGetPostContentOffers =
  | TGetPostContentOffersLoading
  | TGetPostContentOffersSucceed
  | TGetPostContentOffersFailed
  | TGetPostContentOffersReset;

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Checkbox, Outside } from '@cian/ui-kit';

import { ICommentState, IComment } from '../../types/posts';
import { IUser } from '../../types/user';
import { IScrollComment } from '../../types/scroll_comment';
import { setCookie, getCookie } from '../../../app/helpers/cookie_helper';
import { validateEmail } from '../../utils/validateEmail';
import { unescapeHtml } from '../../../app/helpers/make_html_helper';
import { profanity } from '../../../app/profanity/profanity';
import intersection from 'lodash/intersection';
import * as Analytics from '../../../app/helpers/analytics_helper';

import s from './comments.css';

interface IProps {
  onOpen(): void;
  onClose(): void;
  onCommentSubmit(
    comment: string,
    userId: number,
    fullName: string,
    email: string,
    parentID: number | null,
    subs: boolean,
  ): void;
  commentsState: ICommentState;
  isOpened: boolean;
  user: IUser;
  replyTo: string;
  parentID: number | null;
  editingComment?: boolean;
  comment?: IComment;
  onCommentEdit(id: number, comment: string, email: string): void;
  scrollCommentState?: IScrollComment;
  scrollCommentDisabled?(): void;
  saveFields?(comment: string, name: string, email: string, subscribe: boolean): void;
  savedFields?: {
    comment: string;
    name: string;
    email: string;
    subscribe: boolean;
  };
  containerStyle?: any;
  cardType: string;
}

interface IState {
  commentText: string;
  commentEmail: string;
  commentName: string;
  commentSubscribe: boolean;
  hideWarning?: boolean;
  invalidCommentText?: string;
  invalidCommentName?: string;
  invalidCommentEmail?: string;
  isOpenedScroll?: boolean;
}

export class CommentForm extends React.Component<IProps, IState> {
  public commentInput: HTMLTextAreaElement | null;
  //TODO isClickCloseWarning нужен для того, чтобы форма не закрывалась при клике на закрытие предупреждения
  public isClickCloseWarning: boolean;

  public constructor(props: IProps) {
    super(props);

    this.state = {
      commentText: props.editingComment && props.comment ? props.comment.comment : '',
      commentEmail: props.editingComment && props.comment ? props.comment.user.email : props.user.email,
      commentName:
        props.editingComment && props.comment
          ? props.comment.user.fullName || props.comment.user.companyName || ''
          : props.user.fullName,
      commentSubscribe: true,
      hideWarning: false,
      invalidCommentText: '',
      invalidCommentName: '',
      invalidCommentEmail: '',
      isOpenedScroll: false,
    };

    this.isClickCloseWarning = false;
  }

  public componentWillMount() {
    if (this.props.savedFields) {
      this.setState({
        commentText: this.props.savedFields.comment,
        commentName: this.props.savedFields.name,
        commentEmail: this.props.savedFields.email,
        commentSubscribe: this.props.savedFields.subscribe,
      });
    }
  }

  public componentDidMount() {
    const isWarningHidden = !!getCookie('hide_wraning_message');

    if (this.state.hideWarning !== isWarningHidden) {
      this.setState({
        hideWarning: isWarningHidden,
      });
    }
  }

  public componentWillReceiveProps(nextProps: IProps) {
    if (nextProps.scrollCommentState && nextProps.scrollCommentState.isScrollForm) {
      this.scrollToEl();
    }

    if (this.props.isOpened !== nextProps.isOpened) {
      this.hideErrors();
    }

    if (!this.props.commentsState.loaded && nextProps.commentsState.loaded && !nextProps.commentsState.error) {
      this.setState({
        commentText: '',
        commentName: '',
        commentEmail: '',
        commentSubscribe: true,
      });
    }

    if (nextProps.commentsState.error && nextProps.commentsState.error.length) {
      const invalidCommentText = nextProps.commentsState.error.filter(
        error => error.source.pointer === '/data/attributes/comment',
      );
      const invalidCommentEmail = nextProps.commentsState.error.filter(
        error =>
          error.source.pointer === '/data/attributes/email' ||
          error.source.pointer === '/data' ||
          error.source.pointer === '/data/attributes/userId',
      );
      const invalidCommentName = nextProps.commentsState.error.filter(
        error => error.source.pointer === '/data/attributes/nonFieldErrors',
      );

      this.setState(
        Object.assign({}, this.state, {
          invalidCommentText: invalidCommentText.length ? invalidCommentText[0].detail : '',
          invalidCommentEmail: invalidCommentEmail.length ? invalidCommentEmail[0].detail : '',
          invalidCommentName: invalidCommentName.length ? invalidCommentName[0].detail : '',
        }),
      );
    }
  }

  public componentWillUnmount() {
    const { commentText, commentEmail, commentName, commentSubscribe } = this.state;

    if (this.props.saveFields) {
      this.props.saveFields(commentText, commentName, commentEmail, commentSubscribe);
    }
  }

  public render() {
    const { editingComment, containerStyle } = this.props;
    const isQuestion = this.props.cardType === 'Question';
    const MAX_COMMENT_LENGTH = 10000;

    if (!this.props.isOpened) {
      return (
        <div className={s['comments__add-comment']}>
          <input
            onClick={() => this.props.onOpen()}
            className={s['comments__add-comment_input']}
            type="text"
            placeholder="Оставить комментарий"
          />
        </div>
      );
    } else {
      return (
        <Outside
          onOutside={() => {
            if (this.isClickCloseWarning) {
              this.isClickCloseWarning = false;
            } else {
              this.props.onClose();
            }
          }}
        >
          <div className={`${s['comments__form']} ${containerStyle}`}>
            <div className={s['comments__form_wrap-info']}>
              {this.props.user.avatar ? (
                <div
                  style={{
                    backgroundImage: `url(${unescapeHtml(this.props.user.avatar)})`,
                    backgroundSize: 'contain',
                  }}
                  className={s['comments__form_photo']}
                />
              ) : (
                <div className={s['comments__form_photo-default']} />
              )}
              <div className={s['comments__form_info']}>
                <span className={s['comments__form_sender']}>{this.getSender()}</span>
                {!editingComment && this.props.replyTo && (
                  <span className={s['comments__form_addressed']}>
                    <span className={s['comments__form_icon-answer']} />
                    {this.props.replyTo}
                  </span>
                )}
              </div>
            </div>
            {!this.state.hideWarning && (this.props.user.userId < 0 || this.props.cardType === 'Question') && (
              <div className={s['comments__form_warning']}>
                <span
                  role="button"
                  className={s['comments__form_warning-close']}
                  onClick={() => {
                    this.isClickCloseWarning = true;
                    this.setState(
                      {
                        commentText: this.state.commentText,
                        commentEmail: this.state.commentEmail,
                        commentName: this.state.commentName,
                        commentSubscribe: this.state.commentSubscribe,
                        hideWarning: true,
                      },
                      () => setCookie('hide_wraning_message', 'true'),
                    );
                  }}
                />
                <span>
                  Внимание! Материалы, частично или полностью скопированные с других источников, должны содержать ссылку
                  на источник. В случае нарушения авторских прав третьих лиц вы можете быть привлечены к ответственности
                  по ст. 146 УК России.
                </span>
              </div>
            )}
            <div className={s['comments__form_add-comment-wrap']}>
              <textarea
                autoFocus={true}
                ref={input => {
                  this.commentInput = input;
                }}
                onChange={(e: React.FormEvent<any>) =>
                  this.setState({
                    commentText: (e.target as HTMLTextAreaElement).value,
                    commentEmail: this.state.commentEmail,
                    commentName: this.state.commentName,
                    commentSubscribe: this.state.commentSubscribe,
                    invalidCommentText: '',
                  })
                }
                className={`
                    ${s['comments__form_add-comment']}
                    ${this.state.invalidCommentText ? s['comments__form_add-comment-empty'] : ''}
                  `}
                placeholder="Оставьте комментарий"
                value={unescapeHtml(this.state.commentText).split('<br />').join('\r\n')}
                maxLength={isQuestion ? MAX_COMMENT_LENGTH : undefined}
              />
              {this.state.invalidCommentText ? (
                <div className={s['comments__form_add-comment-error']}>{this.state.invalidCommentText}</div>
              ) : (
                <div className={s['comments__form_add-comment-offset-mobile']} />
              )}
            </div>
            {this.props.user.userId < 0 && (
              <div className={s['comments__form_email-wrap']}>
                <input
                  className={`
                      ${s['comments__form_email']}
                      ${this.state.invalidCommentEmail ? s['comments__form_email-invalid'] : ''}
                    `}
                  value={this.state.commentEmail}
                  disabled={!!this.props.user.email}
                  onChange={(e: React.FormEvent<any>) =>
                    this.setState({
                      commentText: this.state.commentText,
                      commentEmail: (e.target as HTMLInputElement).value,
                      commentName: this.state.commentName,
                      commentSubscribe: this.state.commentSubscribe,
                      invalidCommentEmail: '',
                    })
                  }
                  type="email"
                  placeholder="Ваш email"
                />
                {this.getEmailToolbar()}
              </div>
            )}
            {this.props.user.userId < 0 && (
              <div className={s['comments__form_name-wrap']}>
                <input
                  className={`
                    ${s['comments__form_name']}
                    ${this.state.invalidCommentName ? s['comments__form_name-empty'] : ''}
                  `}
                  value={this.state.commentName}
                  disabled={!!this.props.user.fullName}
                  onChange={(e: React.FormEvent<any>) =>
                    this.setState({
                      commentText: this.state.commentText,
                      commentEmail: this.state.commentEmail,
                      commentName: (e.target as HTMLInputElement).value,
                      commentSubscribe: this.state.commentSubscribe,
                      invalidCommentName: '',
                    })
                  }
                  type="text"
                  placeholder="Ваше имя"
                />
                {this.state.invalidCommentName ? (
                  <div className={s['comments__form_name-error']}>{this.state.invalidCommentName}</div>
                ) : (
                  <div className={s['comments__form_name-offset-mobile']} />
                )}
              </div>
            )}
            {this.publishButton()}
          </div>
        </Outside>
      );
    }
  }

  private submitComment() {
    const { editingComment, onCommentEdit, comment } = this.props;
    const checkProfanity = intersection(profanity, this.state.commentText.split(/(\s|\\r\\n)/)).length === 0;
    const validateText = this.state.commentText && checkProfanity;
    const validateFields = [!!validateText];
    const analyticsAction = this.props.cardType === 'Question' ? 'add_advice' : 'add_comment';

    if (this.props.user.userId < 0) {
      validateFields.push(validateEmail(this.state.commentEmail), !!this.state.commentName);
    }

    if (validateFields.indexOf(false) !== -1) {
      let invalidCommentText = this.state.commentText ? '' : 'Введите текст комментария';

      if (!checkProfanity) {
        invalidCommentText = 'Ненормативная лексика';
      }

      this.setState({
        commentText: this.state.commentText,
        commentEmail: this.state.commentEmail,
        commentName: this.state.commentName,
        commentSubscribe: this.state.commentSubscribe,
        invalidCommentEmail: validateEmail(this.state.commentEmail) ? '' : 'Введите корректный адрес электронной почты',
        invalidCommentName: this.state.commentName ? '' : 'Укажите имя',
        invalidCommentText,
      });

      return;
    }

    if (editingComment && comment) {
      onCommentEdit(comment.id, this.state.commentText, this.state.commentEmail);
    } else if (this.props.onCommentSubmit) {
      Analytics.addComment(analyticsAction);
      this.props.onCommentSubmit(
        this.state.commentText,
        this.props.user.userId,
        this.state.commentName,
        this.state.commentEmail,
        this.props.parentID || null,
        this.state.commentSubscribe,
      );
    }
  }

  private scrollToEl = () => {
    const node = ReactDOM.findDOMNode(this);

    if (node && node instanceof Element) {
      node.scrollIntoView();
    }

    const scrolledY = window.scrollY;
    const OFFSET_TOP = 20;

    if (scrolledY) {
      window.scroll(0, scrolledY - OFFSET_TOP);
    }

    if (this.props.scrollCommentDisabled) {
      this.props.scrollCommentDisabled();
    }
  };

  private publishButton = () => (
    <div className={s['comments__form_publish']}>
      <button
        className={s['comments__form_publish-btn']}
        disabled={!this.props.commentsState.loaded}
        onClick={() => this.submitComment()}
      >
        Опубликовать
      </button>
      <Checkbox
        label="Сообщать о новых комментариях на почту"
        onChange={(_, value) =>
          this.setState({
            commentText: this.state.commentText,
            commentEmail: this.state.commentEmail,
            commentName: this.state.commentName,
            commentSubscribe: value,
          })
        }
        checked={this.state.commentSubscribe}
      />
    </div>
  );

  private getEmailToolbar = () => {
    let toolbar = <div className={s['comments__form_email-offset-mobile']} />;

    if (this.state.invalidCommentEmail) {
      if (this.state.invalidCommentEmail === 'Пользователь с таким e-mail уже зарегистрирован. Попробуйте войти.') {
        toolbar = (
          <div className={s['comments__form_email-error']}>
            Пользователь с таким e-mail уже зарегистрирован. Попробуйте
            <span
              className={s['login__error']}
              onClick={() => {
                if ((window as any).waitForLoginPopup) {
                  (window as any).waitForLoginPopup();
                }
                this.hideErrors();
              }}
            >
              {' '}
              войти.
            </span>
          </div>
        );
      } else {
        toolbar = <div className={s['comments__form_email-error']}>{this.state.invalidCommentEmail}</div>;
      }
    }

    return toolbar;
  };

  private hideErrors = () => {
    this.setState(
      Object.assign({}, this.state, {
        invalidCommentText: '',
        invalidCommentName: '',
        invalidCommentEmail: '',
      }),
    );
  };

  private getSender = () => {
    let sender = 'Вы';

    if (this.props.user.fullName) {
      sender = this.props.user.fullName;
    } else if (this.props.user.companyName) {
      sender = this.props.user.companyName;
    }

    return sender;
  };
}

import React, { useCallback, useEffect, useMemo } from 'react';
import { IconButton, BulletList16 as IconList16 } from '@cian/ui-kit';
import { useDispatch, useSelector } from 'react-redux';

import { TThunkDispatch } from '../../../types/redux';
import { useCurrentListing } from '../../../utils';
import { IconTable16 } from '../../../components/Icons';
import { EListingTypes } from '../../../types/listing';
import { selectFavoriteListingType } from '../../../selectors/settings/selectFavoriteListingType';
import { saveFavoriteListingType } from '../../../actions/settings/saveFavoriteListingType';
import { tryToSetFavoriteListingType } from '../../../actions/settings/tryToSetFavoriteListingType';
import { ERequestStatus } from '../../../types/requestStatus';
import * as s from './ListingTypeContainer.css';

/**
 * Переключатель типов листинга
 */
export const ListingTypeContainer = () => {
  const dispatch = useDispatch<TThunkDispatch>();
  const favoriteListingType = useSelector(selectFavoriteListingType);
  const listingTypeOptions = useMemo(
    () => [
      { name: EListingTypes.Cards, icon: <IconTable16 /> },
      { name: EListingTypes.List, icon: <IconList16 /> },
    ],
    [],
  );
  const {
    status: listingStatus,
    meta: { total },
  } = useCurrentListing();

  /**
   * Считываем с куки значение favoriteListingType
   */
  useEffect(() => {
    if (favoriteListingType !== null) {
      return;
    }

    dispatch(tryToSetFavoriteListingType());
  }, [dispatch, favoriteListingType]);

  /**
   * Сохраняем тип списка
   */
  const handleListingTypeClick = useCallback(
    (listingTypeName: EListingTypes) => {
      dispatch(saveFavoriteListingType(listingTypeName));
    },
    [dispatch],
  );

  return (
    <div className={s['wrapper']}>
      {listingTypeOptions.map(({ name, icon }, index) => (
        <div key={index} className={`${s['button-wrapper']} ${favoriteListingType === name ? s['_active'] : ''}`}>
          <IconButton
            icon={icon}
            onClick={() => handleListingTypeClick(name)}
            disabled={[ERequestStatus.Loading, ERequestStatus.Failed].includes(listingStatus) || !total}
          />
        </div>
      ))}
    </div>
  );
};

import { ITypedReduxAction } from '../../../types/redux/actionType';
import { IMainArticles } from '../../../types/mainArticles';

export enum EGetMainArticlesActionType {
  Loading = 'mainArticles/getMainArticles/loading',
  Succeed = 'mainArticles/getMainArticles/succeed',
  AppendSucceed = 'mainArticles/getMainArticles/appendSucceed',
  Failed = 'mainArticles/getMainArticles/failed',
}

export type TGetMainArticlesLoading = ITypedReduxAction<EGetMainArticlesActionType.Loading>;
export type TGetMainArticlesSucceed = ITypedReduxAction<EGetMainArticlesActionType.Succeed, IMainArticles>;
export type TGetMainArticlesAppendSucceed = ITypedReduxAction<EGetMainArticlesActionType.AppendSucceed, IMainArticles>;
export type TGetMainArticlesFailed = ITypedReduxAction<EGetMainArticlesActionType.Failed>;

export type TGetMainArticles =
  | TGetMainArticlesLoading
  | TGetMainArticlesSucceed
  | TGetMainArticlesAppendSucceed
  | TGetMainArticlesFailed;

import * as React from 'react';
import { Location } from 'history';

import { IComment } from '../../types/posts';
import { ICommentState } from '../../types/posts';
import { IUser } from '../../types/user';
import { CommentForm } from './comment_form';
import { Answer } from './answer';

import s from './comments.css';

interface IProps {
  location: Location;
  user: IUser;
  likesShown: boolean;
  canComment: boolean;
  commentsState: ICommentState;
  replyTo: string;
  parentID: number | null;
  onCommentSubmit(comment: string, userId: number, fullName: string, email: string, parentID: number | null, subs: boolean): void;
  answers: IComment[];
  onCommentEdit(id: number, comment: string, email: string): void;
  onCommentDelete(id: number, email: string): void;
  onCommentHide(id: number, email: string): void;
  cardType: string;
}

interface IState {
  addingComment: boolean;
  replyTo: string;
  editingComment: boolean;
  selectedAnswer?: IComment;
  savedFields?: {
    comment: string;
    name: string;
    email: string;
    subscribe: boolean;
  };
}

class Answers extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      addingComment: false,
      replyTo: '',
      editingComment: false,
    };
  }

  public componentWillReceiveProps(nextProps: IProps) {
    if (!nextProps.commentsState.error && nextProps.commentsState.loaded) {
      this.setState({
        addingComment: false,
        replyTo: '',
        editingComment: false,
      });
    }
  }

  public render() {
    return (
      <div className={s['comments__list-answer']}>
        {
          this.props.answers.map((answer: IComment, answerIndex: number) =>
            <Answer
                location={this.props.location}
                likesShown={this.props.likesShown}
                canComment={this.props.canComment}
                key={`answer-${answer.id}`}
                answer={answer}
                onCommentDelete={this.props.onCommentDelete}
                onCommentHide={this.props.onCommentHide}
                user={this.props.user}
                onCommentSubmit={this.props.onCommentSubmit}
                parentID={this.props.parentID}
                commentsState={this.props.commentsState}
                onCommentEdit={this.props.onCommentEdit}
                cardType={this.props.cardType} />,
          )
        }
        { this.props.canComment &&
              (this.state.addingComment || this.state.editingComment) ?
            <CommentForm
                onOpen={() => {}}
                onClose={() => this.setState({
                  addingComment: false,
                  replyTo: '',
                  editingComment: false,
                })}
                onCommentSubmit={(
                  comment: string,
                  userId: number,
                  fullName: string,
                  email: string,
                  parentID: number | null,
                  subs: boolean,
                ) => this.props.onCommentSubmit(comment, userId, fullName, email, parentID, subs)}
                commentsState={this.props.commentsState}
                replyTo={this.state.replyTo}
                parentID={this.props.parentID}
                comment={this.state.selectedAnswer}
                editingComment={this.state.editingComment}
                onCommentEdit={this.props.onCommentEdit}
                isOpened={true}
                user={this.props.user}
                saveFields={this.saveFields}
                savedFields={this.state.savedFields}
                cardType={this.props.cardType} />
          :
            this.props.canComment &&
              <div className={s['comments__list-answer_add']}>
                <button
                    className={s['comments__list-answer_add-btn']}
                    onClick={this.handleAddComment}>
                  Добавить комментарий
                </button>
              </div>
        }
      </div>
    );
  }

  private handleAddComment = () => {
    if (this.props.user.userId === -1) {
      window.waitForLoginPopup();

      return;
    }
    this.addComment(this.props.replyTo)
  };

  protected addComment = (replyTo?: string) => {
    this.setState({
      addingComment: true,
      replyTo: replyTo || '',
      editingComment: false,
      selectedAnswer: undefined,
    });
  }

  private saveFields = (comment: string, name: string, email: string, subscribe: boolean) => {
    this.setState({
      addingComment: this.state.addingComment,
      replyTo: this.state.replyTo,
      editingComment: this.state.editingComment,
      savedFields: {
        comment,
        name,
        email,
        subscribe,
      },
    });
  }
}

export { Answers }

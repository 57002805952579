import React from 'react';
import { useDeviceType, Button } from '@cian/ui-kit';

import { DownloadAllDocumentsPortalButton } from './components/DownloadAllDocumentsPortalButton';
import { DOWNLOAD_ALL_DOCUMENTS_LINK } from '../../../constants';

/**
 * Кнопка "Скачать все шаблоны"
 */
export const DownloadAllDocuments = () => {
  const deviceType = useDeviceType();
  const isPhone = deviceType === 'phone';
  const portalButtonTransition = 200;

  const handleClick = () => {
    window.open(DOWNLOAD_ALL_DOCUMENTS_LINK, '_blank');
  };

  return isPhone ? (
    <DownloadAllDocumentsPortalButton transitionDuration={portalButtonTransition} onClick={handleClick} />
  ) : (
    <Button size={'M'} onClick={handleClick}>
      Скачать все шаблоны
    </Button>
  );
};

import * as React from 'react';

export const Man = () => (
  // eslint-disable-next-line react/forbid-elements
  <svg width="400" height="192" viewBox="0 0 400 192" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_3730_35864)">
      <path
        d="M111.929 58.2338C111.865 58.2338 111.802 58.2338 111.738 58.2338C111.788 57.9327 111.824 57.6316 111.824 57.3214C111.824 53.8996 108.681 51.1303 104.803 51.1303C104.169 51.1303 103.557 51.2124 102.973 51.3493C100.797 43.6573 93.5341 38 84.9022 38C75.805 38 68.2224 44.2823 66.5207 52.6131C64.4539 51.9743 62.2367 51.623 59.9236 51.623C48.9193 51.623 40 59.493 40 69.197C40 78.9011 49.6949 86 60.6992 86C65.5398 86 107.08 85.708 111.934 85.708C120.534 85.708 127.505 79.558 127.505 71.9709C127.505 64.3838 120.534 58.2338 111.934 58.2338H111.929Z"
        fill="white"
      />
      <path
        d="M334.294 75.8565C334.193 75.8565 334.092 75.8565 333.992 75.8565C334.071 75.3824 334.128 74.9083 334.128 74.4199C334.128 69.0326 329.179 64.6726 323.074 64.6726C322.075 64.6726 321.113 64.8019 320.193 65.0174C316.767 52.9069 305.332 44 291.742 44C277.419 44 265.481 53.8909 262.801 67.007C259.548 66.0014 256.057 65.4483 252.415 65.4483C235.09 65.4483 221.047 77.839 221.047 93.1171C221.047 108.395 236.311 119.572 253.636 119.572C261.257 119.572 326.658 119.112 334.301 119.112C347.841 119.112 358.816 109.43 358.816 97.4844C358.816 85.5391 347.841 75.8565 334.301 75.8565H334.294Z"
        fill="white"
      />
      <path
        d="M212.731 192.49L210.933 169.345L180.887 161.678L146.856 168.117L120.181 189.27L120.078 192.49H212.731Z"
        fill="white"
      />
      <path
        d="M160.039 25.5455L151.455 44.6569C151.455 44.6569 145.63 46.4975 148.8 54.571C151.77 62.145 158.307 57.5354 158.307 57.5354C158.307 57.5354 158.573 64.9954 158.514 66.836C158.307 72.9657 155.343 81.2456 155.343 81.2456L170.773 93.6137L179.666 82.7821L181.1 76.5491C181.1 76.5491 186.068 75.6859 187.745 74.8117C192.143 72.5205 195.363 68.101 197.252 62.1395C201.596 48.4141 199.907 29.232 199.907 29.232L198.68 21.5657C198.68 21.5657 172.923 18.1941 171.702 18.1941C170.48 18.1941 160.05 25.5509 160.05 25.5509L160.039 25.5455Z"
        fill="white"
      />
      <path
        d="M179.163 46.8754C179.732 46.8754 180.193 45.9786 180.193 44.8723C180.193 43.766 179.732 42.8691 179.163 42.8691C178.594 42.8691 178.133 43.766 178.133 44.8723C178.133 45.9786 178.594 46.8754 179.163 46.8754Z"
        fill="#231F20"
      />
      <path
        d="M191.382 46.2426C191.95 46.2426 192.411 45.3458 192.411 44.2395C192.411 43.1332 191.95 42.2363 191.382 42.2363C190.813 42.2363 190.352 43.1332 190.352 44.2395C190.352 45.3458 190.813 46.2426 191.382 46.2426Z"
        fill="#231F20"
      />
      <path
        d="M154.109 80.5308L155.95 172.917L179.253 167.607L197.854 166.787L182.114 80.3245L172.917 90.7489L154.109 80.5308Z"
        fill="#BCE3F8"
      />
      <path
        d="M263.668 134.492L226.879 135.312L223.659 128.873C223.659 128.873 215.075 101.585 204.037 89.4772C199.65 84.6614 180.886 77.5217 180.886 77.5217L180.066 82.0715C180.066 82.0715 184.665 97.7408 187.168 116.461C189.356 132.815 190.393 151.399 191.305 160.304C192.022 167.28 193.216 181.098 194.161 192.342H219.25L215.222 153.099C215.222 153.099 225.081 157.155 240.669 159.229C252.169 160.76 264.586 159.739 264.586 159.739L263.668 134.498V134.492Z"
        fill="#036CF8"
      />
      <path
        d="M192.056 166.325L179.215 167.264C179.215 167.264 179.172 167.264 179.15 167.275L165.408 169.99V171.098L179.329 168.35L192.104 167.417L192.061 166.331L192.056 166.325Z"
        fill="#231F20"
      />
      <path
        d="M115.887 185.285L115.772 192.338H165.158L165.658 160.196C165.658 160.196 163.92 162.498 163.307 143.794C163.171 139.608 165.044 127.136 165.044 127.136L163.817 128.054C163.817 128.054 162.9 114.155 162.183 104.241C161.863 99.8375 160.375 86.7472 158.095 81.7576C157.6 80.6826 156.129 78.8963 156.129 78.8963L157.432 74.0696C157.432 74.0696 150.765 75.6767 131.523 95.608C122.407 105.05 107.46 138.354 105.001 153.094C99.5603 185.747 115.887 185.285 115.887 185.285Z"
        fill="#036CF8"
      />
      <path
        d="M167.703 99.028L171.688 104.137L175.673 103.833L177.921 96.1667L172.606 91.6711L167.703 99.028Z"
        fill="#036CF8"
      />
      <path
        d="M171.689 104.679C171.521 104.679 171.363 104.603 171.26 104.473L167.275 99.3636C167.129 99.179 167.123 98.9238 167.253 98.7284L172.162 91.3715C172.248 91.2412 172.384 91.1544 172.536 91.1327C172.688 91.1109 172.846 91.1544 172.965 91.2575L178.28 95.7531C178.443 95.8942 178.509 96.1168 178.449 96.3177L176.201 103.984C176.136 104.201 175.946 104.353 175.723 104.375L171.738 104.684C171.738 104.684 171.711 104.684 171.695 104.684L171.689 104.679ZM168.372 98.9998L171.939 103.571L175.262 103.316L177.303 96.3503L172.721 92.4737L168.372 98.9998Z"
        fill="#036CFD"
      />
      <path
        d="M171.842 103.805L169.084 162.594L180.583 185.436L186.865 159.836L175.447 103.343L171.842 103.805Z"
        fill="#036CF8"
      />
      <path
        d="M180.583 185.979C180.383 185.979 180.192 185.865 180.1 185.681L168.601 162.839C168.557 162.758 168.541 162.666 168.541 162.568L171.299 103.778C171.31 103.512 171.511 103.301 171.771 103.263L175.377 102.801C175.659 102.769 175.92 102.953 175.979 103.23L187.403 159.723C187.419 159.799 187.419 159.88 187.403 159.962L181.115 185.561C181.061 185.784 180.877 185.947 180.649 185.968C180.627 185.968 180.605 185.968 180.589 185.968L180.583 185.979ZM169.632 162.475L180.404 183.878L186.311 159.826L175.013 103.947L172.363 104.283L169.632 162.475Z"
        fill="#036CFD"
      />
      <path
        d="M185.383 149.923L220.951 145.634L217.986 85.3348L285.132 78.282L288.503 142.056L185.795 154.625L185.383 149.923Z"
        fill="#C1E8FD"
      />
      <path
        d="M186.099 154.96C185.974 154.96 185.855 154.916 185.757 154.84C185.643 154.748 185.572 154.612 185.556 154.466L185.149 149.764C185.122 149.471 185.333 149.215 185.627 149.177L220.69 144.948L217.752 85.1542C217.736 84.8664 217.953 84.6166 218.241 84.5895L261.573 80.1971C261.877 80.1754 262.138 80.3817 262.17 80.6803C262.203 80.979 261.986 81.245 261.687 81.2776L218.865 85.6211L221.803 145.404C221.819 145.686 221.607 145.936 221.325 145.969L186.278 150.198L186.593 153.82L263.343 144.595L268.039 143.922C268.338 143.878 268.609 144.085 268.653 144.383C268.696 144.682 268.49 144.953 268.191 144.997L263.479 145.67L186.159 154.965C186.159 154.965 186.115 154.965 186.094 154.965L186.099 154.96Z"
        fill="#231F20"
      />
      <path
        d="M154.408 59.3827C153.512 59.3827 152.513 59.1709 151.422 58.6334C148.913 57.4064 147.181 54.0347 147.301 50.6142C147.404 47.6389 148.881 45.3586 151.351 44.3596C155.494 42.6819 158.963 47.7149 159.11 47.9321C159.278 48.1819 159.213 48.5185 158.963 48.6868C158.713 48.8551 158.377 48.79 158.208 48.5402C158.176 48.4968 155.086 44.0175 151.758 45.364C149.701 46.2001 148.473 48.1221 148.387 50.6522C148.283 53.6167 149.793 56.63 151.899 57.6616C156.216 59.7736 158.659 55.9785 158.762 55.8156C158.92 55.5604 159.256 55.4844 159.511 55.6473C159.767 55.8047 159.843 56.1413 159.68 56.3965C159.658 56.4345 157.752 59.3881 154.408 59.3881V59.3827Z"
        fill="#231F20"
      />
      <path
        d="M164.638 13.4876C163.786 13.8025 158.302 16.0448 153.6 28.6138C151.016 35.5309 150.945 44.864 150.945 44.864C150.945 44.864 153.714 44.6957 154.827 45.0703C157.281 45.8901 157.607 49.7993 160.246 49.1586C163.617 48.3388 165.561 42.6162 166.582 38.6311C167.603 34.6459 168.504 27.794 168.911 24.3083C169.014 23.4125 170.078 22.9998 170.763 23.5917C172.505 25.0902 175.725 27.3542 180.66 28.9993C183.19 29.8409 186.177 29.9983 188.093 29.3468C188.739 29.1296 189.233 28.7333 189.586 28.3587C189.999 27.9243 190.661 27.8754 191.123 28.2501C191.649 28.679 192.382 29.2274 193.262 29.7431C194.614 30.5358 196.118 31.0191 197.681 31.1548C203.453 31.6489 205.94 27.528 205.565 23.4071C205.163 18.9604 196.623 14.416 191.921 13.1889C171.952 7.97673 166.012 12.9826 164.633 13.493L164.638 13.4876Z"
        fill="#231F20"
      />
      <path
        d="M181.071 76.7854C171.988 76.7854 167.259 70.4928 167.063 70.2213C166.884 69.9824 166.938 69.6404 167.177 69.4612C167.421 69.282 167.758 69.3363 167.937 69.5752C167.986 69.6404 172.699 75.9059 181.489 75.6887C191.381 75.4661 196.805 63.8635 198.032 56.1158C200.041 43.449 199.259 28.9851 199.254 28.8439C199.238 28.5453 199.466 28.2902 199.764 28.2739C200.063 28.2521 200.318 28.4856 200.334 28.7842C200.345 28.9308 201.133 43.5033 199.102 56.2895C198.364 60.9425 196.398 65.8615 193.852 69.4558C190.523 74.1413 186.256 76.6714 181.511 76.78C181.364 76.78 181.218 76.78 181.071 76.78V76.7854Z"
        fill="#231F20"
      />
      <path
        d="M165.044 14.0309C164.941 14.0309 164.832 13.9984 164.74 13.9386C164.669 13.8898 163.024 12.7605 161.971 11.0122C161.113 9.57885 160.592 7.66771 161.705 6.60898C163.078 5.30049 164.908 6.50039 164.984 6.54925C165.234 6.71756 165.299 7.05419 165.136 7.30394C164.968 7.55369 164.637 7.61884 164.387 7.45596C164.332 7.41795 163.198 6.69042 162.454 7.39624C161.846 7.97175 162.264 9.38339 162.905 10.453C163.828 11.9949 165.337 13.0319 165.353 13.0428C165.603 13.2111 165.663 13.5477 165.494 13.7975C165.391 13.9495 165.217 14.0364 165.044 14.0364V14.0309Z"
        fill="#231F20"
      />
      <path
        d="M165.609 13.9875C165.387 13.9875 165.18 13.8518 165.099 13.6292C165.077 13.5695 164.567 12.147 164.697 10.6105C164.8 9.38885 165.332 7.98807 166.521 7.72746C167.178 7.5863 167.634 7.7709 167.906 7.95007C168.568 8.39528 168.726 9.20425 168.742 9.29655C168.791 9.58974 168.595 9.87207 168.302 9.92636C168.009 9.97523 167.727 9.77977 167.672 9.48658C167.651 9.36171 167.537 9.0088 167.298 8.85134C167.162 8.75904 166.983 8.74276 166.755 8.79162C166.206 8.91107 165.848 9.84492 165.777 10.7082C165.669 12.0112 166.114 13.2546 166.12 13.2654C166.223 13.5478 166.076 13.8572 165.794 13.9604C165.734 13.9821 165.669 13.993 165.609 13.993V13.9875Z"
        fill="#231F20"
      />
      <path
        d="M153.524 56.7484C153.361 56.7484 153.198 56.6723 153.089 56.5312C152.91 56.2923 152.959 55.9502 153.198 55.7711C153.258 55.7222 154.773 54.506 154.365 51.4493C154.012 48.816 152.237 48.778 152.215 48.778C151.917 48.778 151.672 48.5337 151.678 48.2296C151.678 47.931 151.922 47.6921 152.221 47.6921C153.165 47.6921 155.06 48.4577 155.44 51.3081C155.934 55.0327 153.936 56.58 153.85 56.6452C153.752 56.7158 153.638 56.7538 153.524 56.7538V56.7484Z"
        fill="#231F20"
      />
      <path
        d="M156.129 79.1272C156.08 79.1272 156.031 79.1218 155.982 79.1055C155.694 79.0241 155.521 78.7255 155.602 78.4377C155.618 78.378 157.28 72.3025 157.654 67.959C157.882 65.3203 157.882 57.5074 157.882 57.426C157.882 57.1274 158.127 56.8831 158.425 56.8831C158.724 56.8831 158.968 57.1274 158.968 57.426C158.968 57.7463 158.968 65.3638 158.735 68.0513C158.349 72.4925 156.715 78.4757 156.65 78.7255C156.585 78.9644 156.368 79.1272 156.129 79.1272Z"
        fill="#231F20"
      />
      <path
        d="M178.052 66.9478C178.019 66.9478 177.987 66.9478 177.954 66.937C177.824 66.9152 174.718 66.318 172.167 62.7292C170.131 59.8625 170.739 55.1823 170.766 54.9815C170.804 54.6828 171.081 54.4711 171.379 54.52C171.678 54.558 171.884 54.8349 171.846 55.128C171.841 55.1715 171.265 59.5856 173.052 62.0994C175.348 65.3299 178.117 65.8619 178.15 65.8674C178.443 65.9217 178.644 66.204 178.59 66.4972C178.541 66.7578 178.313 66.9424 178.057 66.9424L178.052 66.9478Z"
        fill="#231F20"
      />
      <path
        d="M178.672 68.048C178.503 68.048 178.34 67.9665 178.232 67.8199C178.194 67.7656 177.852 67.2444 177.993 66.43C178.112 65.7459 178.764 65.2355 178.84 65.1813C179.079 65.0021 179.421 65.0509 179.6 65.2898C179.779 65.5287 179.736 65.8708 179.497 66.05C179.339 66.1694 179.095 66.4354 179.063 66.62C178.997 66.9947 179.128 67.2118 179.133 67.2227C179.285 67.4724 179.215 67.8091 178.97 67.9665C178.878 68.0262 178.775 68.0534 178.672 68.0534V68.048Z"
        fill="#231F20"
      />
      <path
        d="M171.646 55.3387C171.608 55.3387 171.565 55.3387 171.527 55.3387C170.837 55.3007 170.251 54.7143 170.186 54.6438C169.98 54.4266 169.985 54.0845 170.202 53.8782C170.419 53.6719 170.761 53.6773 170.968 53.8891C171.103 54.0302 171.397 54.242 171.587 54.2528C171.961 54.2746 172.168 54.1171 172.179 54.1117C172.412 53.9325 172.749 53.9597 172.939 54.1877C173.129 54.4157 173.107 54.7415 172.89 54.9369C172.841 54.9804 172.407 55.3441 171.657 55.3441L171.646 55.3387Z"
        fill="#231F20"
      />
      <path
        d="M186.405 58.818C186.171 58.818 185.954 58.666 185.884 58.4325C185.797 58.1448 185.96 57.8407 186.247 57.7538C186.274 57.743 189.168 56.8471 189.983 55.1043C190.265 54.5071 190.254 53.8827 189.966 53.1932C189.755 52.6991 189.418 52.3679 188.94 52.1887C187.632 51.6946 185.563 52.4493 184.874 52.7805C184.602 52.9108 184.282 52.7968 184.152 52.5253C184.021 52.2539 184.135 51.9335 184.407 51.8032C184.526 51.7435 187.317 50.4187 189.326 51.1734C190.086 51.4612 190.64 51.9987 190.971 52.7697C191.383 53.7415 191.383 54.6808 190.971 55.5658C189.95 57.7484 186.703 58.7474 186.562 58.7908C186.508 58.8071 186.459 58.8126 186.405 58.8126V58.818Z"
        fill="#231F20"
      />
      <path
        d="M173.448 40.8469C173.269 40.8469 173.084 40.8089 172.905 40.7329C172.221 40.4343 171.906 39.6416 172.205 38.9575C172.286 38.7674 173.062 37.0626 175.05 35.4501C176.852 33.9896 178.427 33.6095 178.6 33.5715C179.333 33.4086 180.056 33.8701 180.218 34.6031C180.381 35.3361 179.92 36.0582 179.187 36.2211C179.187 36.2211 178.068 36.498 176.76 37.5621C175.283 38.7566 174.702 40.0325 174.697 40.0433C174.469 40.5483 173.975 40.8469 173.453 40.8469H173.448Z"
        fill="#231F20"
      />
      <path
        d="M194.504 38.7831C194.053 38.7831 193.614 38.5605 193.353 38.1479C193.353 38.1479 192.864 37.4258 191.778 36.7634C190.546 36.0142 189.493 35.9164 189.482 35.9164C188.733 35.8676 188.168 35.2215 188.211 34.4722C188.26 33.723 188.906 33.1692 189.65 33.2072C189.824 33.218 191.377 33.3483 193.185 34.4451C194.824 35.4441 195.568 36.5734 195.644 36.6983C196.046 37.3335 195.85 38.1696 195.221 38.5714C194.998 38.7126 194.748 38.7777 194.499 38.7777L194.504 38.7831Z"
        fill="#231F20"
      />
      <path
        d="M179.66 83.1958C179.622 83.1958 179.584 83.1958 179.541 83.185C179.248 83.1198 179.063 82.8321 179.128 82.5389L180.431 76.6371C180.497 76.3439 180.784 76.1594 181.077 76.2245C181.371 76.2897 181.555 76.5774 181.49 76.8706L180.187 82.7723C180.133 83.0275 179.905 83.1958 179.655 83.1958H179.66Z"
        fill="#231F20"
      />
      <path
        d="M151.454 135.954C151.243 135.954 151.042 135.829 150.955 135.623L142.778 116.511C142.697 116.327 142.729 116.115 142.854 115.957L151.769 104.882L142.371 98.9743C142.159 98.8386 142.067 98.578 142.149 98.3391L148.485 79.738C148.582 79.4557 148.892 79.2982 149.174 79.4014C149.456 79.4991 149.608 79.8086 149.511 80.0909L143.31 98.2848L152.861 104.29C152.991 104.371 153.083 104.507 153.105 104.659C153.132 104.811 153.089 104.968 152.991 105.088L143.897 116.381L151.943 135.194C152.063 135.471 151.932 135.791 151.655 135.905C151.585 135.938 151.514 135.948 151.444 135.948L151.454 135.954Z"
        fill="#231F20"
      />
      <path
        d="M130.502 149.446C130.323 149.446 130.144 149.354 130.041 149.191C129.883 148.936 129.959 148.604 130.215 148.441L165.191 126.496C165.446 126.333 165.777 126.414 165.94 126.67C166.097 126.925 166.021 127.256 165.766 127.419L130.79 149.364C130.698 149.419 130.6 149.446 130.502 149.446Z"
        fill="#231F20"
      />
      <path
        d="M139.293 143.827C139.249 143.827 139.206 143.827 139.157 143.81C138.864 143.734 138.69 143.441 138.766 143.148L142.751 127.511C142.827 127.218 143.12 127.044 143.413 127.12C143.707 127.196 143.88 127.49 143.804 127.783L139.819 143.419C139.754 143.664 139.537 143.827 139.293 143.827Z"
        fill="#231F20"
      />
      <path
        d="M116.499 186.234C116.277 186.234 116.07 186.098 115.989 185.881C115.886 185.599 116.027 185.289 116.309 185.186C116.445 185.137 129.888 180.148 139.053 175.701C156.269 167.345 165.326 159.901 165.412 159.831C165.646 159.641 165.988 159.673 166.178 159.901C166.368 160.13 166.335 160.477 166.107 160.667C166.015 160.743 156.861 168.268 139.531 176.678C130.322 181.147 116.825 186.153 116.689 186.201C116.629 186.223 116.564 186.234 116.499 186.234Z"
        fill="#231F20"
      />
      <path d="M140.21 137.865L139.293 143.382L130.709 148.393L140.21 137.865Z" fill="#231F20" />
      <path
        d="M163.002 149.51C163.078 149.733 163.29 149.869 163.518 149.869C163.572 149.869 163.632 149.858 163.686 149.842C163.882 149.782 183.313 143.69 188.754 144.57C189.15 144.635 189.682 144.885 190.247 145.151C191.387 145.683 192.804 146.345 194.183 145.987C194.997 145.77 195.595 145.314 195.904 144.678C196.409 144.879 197.419 145.216 198.521 145.216C199.401 145.216 200.334 144.999 201.105 144.32C202.083 143.457 202.408 142.436 202.077 141.236C202.56 141.394 203.136 141.518 203.733 141.518C204.423 141.518 205.139 141.35 205.775 140.861C206.6 140.226 207.072 139.434 207.165 138.5C207.327 136.925 206.415 134.998 204.455 132.772C203.809 132.033 202.707 130.958 201.887 130.399C205.129 130.969 209.798 131.735 212.084 130.247C212.827 129.758 213.886 129.302 213.989 128.477C214.087 127.728 213.685 126.935 212.806 126.126C208.94 122.597 196.898 119.35 188.873 120.729C173.692 123.346 163.887 130.942 163.789 131.018"
        fill="white"
      />
      <path
        d="M172.504 91.704C172.412 91.704 172.314 91.6768 172.227 91.628L158.632 83.5545C158.377 83.4025 158.29 83.0658 158.442 82.8107C158.594 82.55 158.931 82.4686 159.186 82.6206L172.39 90.4661L180.062 81.8062C180.263 81.5836 180.605 81.5619 180.828 81.7628C181.05 81.9637 181.072 82.3057 180.876 82.5283L172.906 91.5194C172.797 91.6388 172.651 91.704 172.499 91.704H172.504Z"
        fill="#231F20"
      />
      <path
        d="M162.693 106.825C162.59 106.825 162.487 106.798 162.4 106.738C162.15 106.575 162.074 106.239 162.242 105.989L172.053 90.8625C172.216 90.6128 172.553 90.5368 172.803 90.7051C173.052 90.868 173.128 91.2046 172.96 91.4543L163.149 106.581C163.046 106.743 162.872 106.83 162.693 106.83V106.825Z"
        fill="#231F20"
      />
      <path
        d="M185.279 102.328C185.154 102.328 185.029 102.285 184.926 102.198L172.558 91.6702C172.329 91.4748 172.302 91.1327 172.498 90.9047C172.693 90.6767 173.035 90.6495 173.263 90.845L185.631 101.373C185.86 101.568 185.887 101.91 185.691 102.138C185.583 102.263 185.431 102.328 185.279 102.328Z"
        fill="#231F20"
      />
      <path
        d="M299.444 134.862C297.94 133.51 296.333 134.634 293.874 136.996C293.418 137.435 292.212 138.695 291.653 139.374C292.256 137.707 293.19 134.471 293.673 132.962C294.107 131.599 295.356 125.996 293.559 123.504C293.01 122.743 293.005 122.434 292.038 122.434L291.349 122.662C288.683 122.662 288.053 128.026 287.054 130.942C287.13 128.651 286.967 123.547 285.702 122.228C285.138 121.636 284.416 121.37 283.558 121.435C280.821 121.652 280.903 126.039 280.539 129.411C280.43 130.383 279.54 133.266 279.388 134.009C279.404 131.843 279.551 125.208 277.808 123.694C277.227 123.189 276.532 123.004 275.739 123.14C273.193 123.585 273.611 127.614 273.562 130.638C273.546 131.702 273.448 133.282 273.334 134.009C272.4 139.873 268.475 144.846 268.426 144.895C268.323 144.998 263.035 145.715 262.6 145.813C262.318 145.818 262.041 145.112 262.03 145.411C262.03 145.498 261.933 147.632 262.389 151.427C262.97 156.243 263.561 159.94 265.386 159.94C265.429 159.94 265.473 159.94 265.516 159.935C266.971 159.804 266.705 158.458 266.743 157.692C270.576 157.361 278.427 156.541 284.318 153.859C289.291 151.595 297.446 144.727 299.526 140.242C300.259 138.657 301.263 136.491 299.45 134.862H299.444ZM265.418 158.871C265.418 158.871 265.402 158.871 265.391 158.871C265.136 158.871 264.902 158.528 264.691 157.991C264.691 157.991 265.331 157.975 266.352 157.904C266.309 158.246 266.129 158.805 265.413 158.871H265.418Z"
        fill="white"
      />
      <path
        d="M165.358 160.819C165.238 160.819 165.119 160.803 164.999 160.765C164.516 160.623 163.506 160.33 162.958 153.066C162.654 148.994 162.648 144.596 162.768 142.223C163.436 129.383 164.359 126.57 165.635 126.196C166.053 126.071 166.46 126.125 166.818 126.348C167.719 126.907 168.002 128.395 168.034 128.563C168.089 128.856 167.888 129.138 167.595 129.193C167.301 129.247 167.019 129.046 166.965 128.753C166.894 128.373 166.628 127.51 166.243 127.271C166.15 127.211 166.063 127.205 165.939 127.238C165.439 127.444 164.505 129.681 163.848 142.277C163.512 148.733 164.228 159.027 165.32 159.728C165.341 159.728 165.385 159.738 165.472 159.679C166.606 158.848 167.258 152.865 167.361 148.885C167.367 148.586 167.605 148.342 167.915 148.358C168.214 148.364 168.452 148.614 168.442 148.918C168.398 150.612 168.083 159.119 166.107 160.564C165.873 160.737 165.613 160.824 165.347 160.824L165.358 160.819Z"
        fill="#231F20"
      />
      <path
        d="M164.533 131.867C164.376 131.867 164.219 131.796 164.11 131.666C163.92 131.432 163.958 131.09 164.186 130.9C164.284 130.819 174.089 122.941 189.27 120.221C197.3 118.787 209.342 122.154 213.202 125.818C214.087 126.655 214.484 127.48 214.386 128.256C214.277 129.109 213.849 129.798 213.099 130.303C209.635 132.649 199.987 130.146 199.58 130.037C199.292 129.961 199.119 129.662 199.195 129.375C199.271 129.087 199.564 128.913 199.857 128.989C202.512 129.69 209.956 131.123 212.491 129.402C212.974 129.076 213.24 128.658 213.306 128.12C213.354 127.708 213.061 127.181 212.448 126.606C209.141 123.473 197.528 119.841 189.454 121.285C174.562 123.951 164.957 131.655 164.865 131.736C164.761 131.818 164.642 131.856 164.523 131.856L164.533 131.867Z"
        fill="#231F20"
      />
      <path
        d="M204.143 141.8C202.633 141.8 201.26 140.964 201.173 140.91C200.917 140.752 200.841 140.416 200.999 140.16C201.156 139.905 201.488 139.829 201.748 139.981C201.77 139.998 204.088 141.398 205.511 140.264C206.108 139.791 206.428 139.232 206.499 138.553C206.629 137.239 205.777 135.464 204.045 133.417C201.699 130.643 197.084 129.063 197.041 129.046C196.759 128.949 196.607 128.645 196.699 128.357C196.797 128.075 197.101 127.917 197.388 128.015C197.589 128.08 202.335 129.709 204.876 132.711C206.836 135.024 207.742 137.022 207.585 138.656C207.487 139.628 207.02 140.454 206.195 141.111C205.56 141.616 204.843 141.789 204.153 141.789L204.143 141.8Z"
        fill="#231F20"
      />
      <path
        d="M198.924 145.639C197.409 145.639 196.058 144.971 195.965 144.928C195.699 144.792 195.591 144.466 195.721 144.2C195.857 143.934 196.182 143.82 196.448 143.956C196.476 143.972 199.234 145.319 200.77 143.907C201.291 143.429 202.513 142.306 199.896 139.2C197.247 136.056 193.408 135.242 193.37 135.237C193.077 135.177 192.887 134.889 192.947 134.596C193.006 134.303 193.289 134.113 193.587 134.172C193.761 134.205 197.833 135.057 200.732 138.505C202.942 141.133 203.197 143.163 201.509 144.711C200.738 145.417 199.804 145.639 198.924 145.639Z"
        fill="#231F20"
      />
      <path
        d="M163.922 150.466C163.694 150.466 163.482 150.319 163.406 150.091C163.314 149.804 163.466 149.5 163.753 149.407C164.562 149.141 183.636 142.919 189.342 143.897C189.89 143.989 190.493 144.282 191.128 144.591C192.154 145.091 193.316 145.656 194.304 145.384C194.891 145.227 195.26 144.906 195.396 144.439C195.629 143.641 195.184 142.436 194.288 141.448C193.208 140.259 191.628 139.895 191.617 139.89C191.324 139.824 191.139 139.537 191.204 139.244C191.269 138.95 191.557 138.76 191.85 138.831C191.926 138.847 193.778 139.265 195.097 140.72C196.275 142.018 196.791 143.56 196.444 144.744C196.199 145.574 195.548 146.171 194.598 146.432C193.219 146.807 191.802 146.117 190.661 145.563C190.097 145.286 189.565 145.031 189.168 144.961C183.728 144.038 164.296 150.368 164.101 150.434C164.047 150.45 163.987 150.461 163.932 150.461L163.922 150.466Z"
        fill="#231F20"
      />
      <path
        d="M163.512 150.433L167.806 149.309C167.806 149.309 167.296 160.022 165.455 160.141C163.924 160.244 163.512 150.433 163.512 150.433Z"
        fill="#231F20"
      />
      <path
        d="M167.472 129.406L164.328 131.284C164.328 131.284 165.262 126.87 166.163 126.827C166.918 126.789 167.472 129.411 167.472 129.411V129.406Z"
        fill="#231F20"
      />
      <path
        d="M199.489 121.135C199.489 121.135 199.445 121.135 199.418 121.135C199.119 121.097 198.908 120.825 198.946 120.526L200.537 107.99L191.833 102.452C191.703 102.371 191.616 102.24 191.589 102.088C191.562 101.936 191.6 101.784 191.692 101.665L196.969 94.8237L187.582 81.044C187.414 80.7942 187.479 80.4576 187.723 80.2893C187.973 80.121 188.31 80.1861 188.478 80.4304L198.082 94.536C198.213 94.7315 198.207 94.9866 198.066 95.1712L192.908 101.855L201.4 107.257C201.579 107.371 201.671 107.577 201.65 107.784L200.015 120.662C199.983 120.934 199.749 121.135 199.478 121.135H199.489Z"
        fill="#231F20"
      />
      <path d="M185.59 149.413L220.745 140.829L220.951 145.732L185.59 149.413Z" fill="#231F20" />
      <path d="M263.875 157.996L266.839 157.692L266.123 159.63H264.385L263.875 157.996Z" fill="#231F20" />
      <path
        d="M264.445 158.157C264.152 158.157 263.908 157.924 263.902 157.625C263.897 157.327 264.136 157.077 264.435 157.071C264.549 157.071 275.695 156.778 284.154 152.972C289.051 150.768 297.244 143.922 299.231 139.67C300.203 137.585 300.48 136.424 299.416 135.473C298.368 134.54 296.799 135.055 294.752 136.999C293.493 138.199 291.136 141.261 291.115 141.288C290.952 141.505 290.653 141.565 290.42 141.429C290.186 141.299 290.083 141.017 290.175 140.762C290.197 140.707 292.179 135.484 293.167 132.395C293.818 130.359 294.53 125.576 293.205 123.762C292.846 123.268 292.352 123.029 291.706 123.029C289.643 123.029 288.964 126.309 288.367 129.197C288.172 130.153 287.982 131.054 287.754 131.798C286.738 135.05 285.028 139.735 285.012 139.779C284.909 140.061 284.599 140.202 284.317 140.105C284.035 140.001 283.888 139.692 283.991 139.41C284.007 139.361 285.712 134.702 286.717 131.477C286.934 130.788 287.113 129.908 287.303 128.98C287.949 125.847 288.758 121.949 291.701 121.943C292.694 121.943 293.514 122.351 294.079 123.122C295.925 125.652 294.644 131.342 294.198 132.726C293.704 134.263 292.971 136.315 292.352 138.009C292.933 137.325 293.531 136.652 293.998 136.206C296.522 133.801 298.591 133.28 300.133 134.659C301.99 136.315 300.958 138.514 300.209 140.126C298.075 144.687 289.703 151.658 284.594 153.96C275.929 157.864 264.565 158.152 264.451 158.157H264.44H264.445Z"
        fill="#231F20"
      />
      <path
        d="M284.279 140.606C284.214 140.606 284.149 140.595 284.083 140.568C283.801 140.459 283.665 140.144 283.768 139.867C283.79 139.818 285.745 134.671 286.098 131.501L286.119 131.316C286.776 125.528 286.005 123.373 285.24 122.585C284.887 122.222 284.453 122.064 283.904 122.108C281.988 122.26 281.597 125.816 281.282 128.672C281.146 129.915 281.016 131.093 280.777 131.978C279.995 134.883 278.562 139.998 278.545 140.046C278.464 140.334 278.165 140.502 277.878 140.421C277.59 140.34 277.422 140.041 277.503 139.753C277.519 139.704 278.953 134.595 279.729 131.696C279.946 130.887 280.071 129.752 280.207 128.552C280.587 125.126 281.01 121.244 283.823 121.022C284.697 120.957 285.441 121.223 286.022 121.825C288.052 123.927 287.406 129.579 287.194 131.435L287.173 131.62C286.803 134.932 284.86 140.041 284.778 140.253C284.697 140.47 284.491 140.6 284.273 140.6L284.279 140.606Z"
        fill="#231F20"
      />
      <path
        d="M268.268 144.847C268.149 144.847 268.024 144.809 267.926 144.722C267.693 144.532 267.66 144.19 267.85 143.956C267.893 143.908 271.955 138.891 272.91 132.935C273.03 132.196 273.046 131.176 273.068 130.09C273.122 127.022 273.187 123.205 275.804 122.755C276.618 122.613 277.33 122.804 277.927 123.319C279.979 125.089 279.871 130.345 279.833 132.071V132.24C279.773 135.313 278.416 140.172 278.361 140.373C278.28 140.661 277.981 140.829 277.693 140.748C277.406 140.666 277.237 140.368 277.319 140.08C277.335 140.031 278.692 135.15 278.747 132.218V132.044C278.866 126.696 277.981 124.796 277.221 124.139C276.874 123.841 276.483 123.743 275.994 123.824C274.262 124.123 274.208 127.44 274.164 130.111C274.143 131.23 274.126 132.289 273.996 133.108C272.992 139.358 268.876 144.434 268.702 144.646C268.594 144.776 268.442 144.847 268.284 144.847H268.268Z"
        fill="#231F20"
      />
      <path
        d="M265.168 160.141C263.289 160.141 262.681 156.384 262.089 151.492C261.622 147.637 261.714 145.471 261.72 145.379C261.736 145.08 261.997 144.868 262.29 144.863C262.589 144.879 262.822 145.129 262.806 145.433C262.806 145.455 262.713 147.61 263.17 151.362C263.403 153.273 264.103 159.055 265.178 159.055C265.189 159.055 265.195 159.055 265.206 159.055C266.205 158.968 266.188 157.953 266.183 157.834C266.172 157.535 266.405 157.28 266.704 157.269C266.997 157.253 267.258 157.492 267.269 157.79C267.301 158.539 266.927 159.989 265.303 160.136C265.26 160.136 265.216 160.141 265.173 160.141H265.168Z"
        fill="#231F20"
      />
      <path
        d="M143.485 174.453C134.391 179.361 115.893 185.388 115.893 185.388L115.828 192.185H123.554C128.538 189.769 134.576 186.805 137.356 185.284C157.287 174.398 159.839 165.255 159.839 165.255C159.839 165.255 147.623 172.221 143.485 174.453Z"
        fill="#231F20"
      />
      <path
        d="M256.711 97.7243C254.348 97.8797 252.56 99.9205 252.715 102.283C252.768 103.089 253.04 103.827 253.47 104.446C253.558 104.572 253.653 104.694 253.753 104.809L254.063 105.184L257.436 108.747L260.571 104.36C261.068 103.607 261.333 102.691 261.269 101.72C261.113 99.3579 259.073 97.569 256.71 97.7244L256.711 97.7243ZM257.12 103.953C256.037 104.024 255.102 103.204 255.031 102.121C254.96 101.038 255.78 100.102 256.863 100.031C257.945 99.96 258.881 100.78 258.952 101.863C259.024 102.946 258.203 103.882 257.12 103.953Z"
        fill="#006CFD"
      />
      <path d="M259.666 117.707L256.454 117.24L256.012 110.52L259.18 110.311L259.666 117.707Z" fill="#006CFD" />
      <path
        d="M259.558 122.942L253.209 116.26L247.79 123.716L244.697 121.467L252.817 110.294L262.33 120.307L259.558 122.942Z"
        fill="#006CFD"
      />
    </g>
    <defs>
      <clipPath id="clip0_3730_35864">
        <rect width="400" height="192" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

import { ITypedReduxAction } from '../../types/redux/actionType';
import { IPageInfoSchema } from '../../repositories/journal/entities/page_info/PageInfoSchema';

export enum ESetPagesMetaActionType {
  Set = 'pagesMeta/set',
}

export type TSetPagesMeta = ITypedReduxAction<ESetPagesMetaActionType.Set, IPageInfoSchema[]>;

export type TPagesMetaActions = TSetPagesMeta;

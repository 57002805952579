import React from 'react';

import * as s from './RubricButton.css';

export interface IRubricLink {
  children: string;
  url?: string;
  noIndex?: boolean;
  onClick?(e: React.MouseEvent): void;
}

/**
 * Кнопка рубрики
 */
export const RubricButton = ({ children, url, noIndex, onClick }: IRubricLink) => {
  if (!url) {
    return <span className={s['wrapper']}>{children}</span>;
  }

  return (
    <a href={url} className={s['wrapper']} onClick={onClick} rel={noIndex ? 'nofollow' : undefined} itemProp="url">
      {children}
    </a>
  );
};

import { ITypedReduxAction } from '../../../types/redux/actionType';
import { IRecommendedOfferSchema } from '../../../repositories/search-engine/entities/recommended_offers/RecommendedOfferSchema';

export enum EGetOffersFromRecommendedApiActionType {
  Loading = 'recommendedOffers/getOffersFromRecommendedApi/loading',
  Succeed = 'recommendedOffers/getOffersFromRecommendedApi/succeed',
  Failed = 'recommendedOffers/getOffersFromRecommendedApi/failed',
}

export type TGetOffersFromRecommendedApiLoading = ITypedReduxAction<EGetOffersFromRecommendedApiActionType.Loading>;
export type TGetOffersFromRecommendedApiSucceed = ITypedReduxAction<
  EGetOffersFromRecommendedApiActionType.Succeed,
  IRecommendedOfferSchema[]
>;
export type TGetOffersFromRecommendedApiFailed = ITypedReduxAction<EGetOffersFromRecommendedApiActionType.Failed>;

export type TGetOffersFromRecommendedApi =
  | TGetOffersFromRecommendedApiLoading
  | TGetOffersFromRecommendedApiSucceed
  | TGetOffersFromRecommendedApiFailed;

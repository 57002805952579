import { HttpBadStatusCodeError } from '@cian/peperrors/shared';

import { TThunkAction } from '../../../types/redux';
import { getOffersFromHistoryLoading } from './getOffersFromHistoryLoading';
import { getOffersFromHistorySucceed } from './getOffersFromHistorySucceed';
import { getOffersFromHistoryFailed } from './getOffersFromHistoryFailed';
import {
  fetchGetOffersFromHistory,
  TGetOffersFromHistoryResponse,
} from '../../../repositories/search-engine/v1/get-offers-from-history';

export const getOffersFromHistory = (): TThunkAction<Promise<void>> => {
  return async (dispatch, getState, context) => {
    const { httpApi, logger } = context;

    dispatch(getOffersFromHistoryLoading());

    try {
      const { response, statusCode }: TGetOffersFromHistoryResponse = await fetchGetOffersFromHistory({
        httpApi,
        parameters: {},
      });

      if (statusCode !== 200 || !response?.offers) {
        await Promise.reject(
          new HttpBadStatusCodeError(
            'Не удалось загрузить просмотренные объявления',
            statusCode,
            'actions.getOffersFromHistory',
          ),
        );

        return;
      }

      dispatch(getOffersFromHistorySucceed(response.offers));
    } catch (e) {
      logger.error(e, {
        domain: 'actions.getOffersFromHistory',
      });

      dispatch(getOffersFromHistoryFailed());
    }
  };
};

import { ERequestStatus } from '../../types/requestStatus';
import { TNewsListState } from '../../types/newsList';
import { EOrdering } from '../../repositories/journal/entities/journal/JournalMetaV2Schema';
import { EGetNewsListActionType, TNewsListActions, EResetNewsListActionType } from '../../actions/newsList';

export const defaultState: TNewsListState = {
  status: ERequestStatus.Initial,
  meta: {
    b2b: false,
    limit: 18,
    offset: 0,
    regionId: 0,
    rubricId: 0,
    tag: null,
    total: 0,
    ordering: EOrdering.Date_publish,
  },
  items: [],
};

export const newsListReducer = (state = defaultState, action: TNewsListActions) => {
  switch (action.type) {
    case EGetNewsListActionType.Loading:
      return { ...state, status: ERequestStatus.Loading };

    case EGetNewsListActionType.Succeed:
      return { ...state, ...action.payload, status: ERequestStatus.Succeed };

    case EGetNewsListActionType.AppendSucceed:
      return {
        ...state,
        meta: action.payload.meta,
        items: [...state.items, ...action.payload.items],
        status: ERequestStatus.Succeed,
      };

    case EGetNewsListActionType.Failed:
      return { ...state, status: ERequestStatus.Failed };

    case EResetNewsListActionType.Reset:
      return defaultState;

    default:
      return state;
  }
};

import React from 'react';
import { Link } from 'react-router-dom';
import { UIHeading2, UIHeading3, useDeviceType } from '@cian/ui-kit';

import { CatImage } from './components/CatImage';
import * as s from './NothingFound.css';

interface INothingFound {
  pathname?: string;
  showResetFiltersLink?: boolean;
}

/** Заглушка Ничего не найдено */
export const NothingFound = ({ pathname = '/magazine/', showResetFiltersLink = false }: INothingFound) => {
  const deviceType = useDeviceType();
  const isPhone = () => deviceType === 'phone';

  return (
    <div className={s['wrapper']}>
      <Link to={pathname}>
        <CatImage />
      </Link>

      <div className={s['title']}>
        {isPhone() ? (
          <UIHeading3 as={'h2'}>Ничего не найдено</UIHeading3>
        ) : (
          <UIHeading2 as={'h2'}>Ничего не найдено</UIHeading2>
        )}
      </div>

      <div className={s['description']}>
        Попробуйте поменять запрос
        <br />и начать поиск заново
      </div>

      {showResetFiltersLink && (
        <div className={s['description']}>
          <Link to={pathname}>Сбросить фильтры</Link>
        </div>
      )}
    </div>
  );
};

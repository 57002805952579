import { TThunkAction } from '../../../types/redux';
import { EListingTypes } from '../../../types/listing';
import { getCookie } from '../../../../app/helpers/cookie_helper';
import { setFavoriteListingType } from '../setFavoriteListingType';

/**
 * Пытается считать значение куки и записать в стор.
 * Если нет значения в куке, записывает значение в стор по умолчанию
 */
export const tryToSetFavoriteListingType = (): TThunkAction => {
  return dispatch => {
    const favoriteListingType = getCookie('favoriteListingType');

    if (favoriteListingType && (Object.values(EListingTypes) as string[]).includes(favoriteListingType)) {
      dispatch(setFavoriteListingType(favoriteListingType as EListingTypes));

      return;
    }

    // После неудачной попытки устанавливаем значение по умолчанию
    dispatch(setFavoriteListingType(EListingTypes.Cards));
  };
};

import { IAdFoxBannerOnErrorError } from '@cian/adfox-component';
import { useBooleanState } from '@cian/react-utils';

import React, { memo, useCallback } from 'react';

import { AdfoxBanner } from './AdfoxBanner';
import { useAdfoxContext } from './external/context/AdfoxContext';

type AdfoxBannerContainerProps = {
  onError?(error: IAdFoxBannerOnErrorError): void;
  onStub?(): void;
  onRender?(): void;
};

type HandleError = (error: IAdFoxBannerOnErrorError) => void;

export const AdfoxBannerContainer = memo<AdfoxBannerContainerProps>(function AdfoxBannerContainer(props) {
  const { onStub, onError, onRender } = props;

  const { state: isBannerHidden, setTrue: setBannerHidden } = useBooleanState();
  const { params, size } = useAdfoxContext();

  const handleStub = useCallback<VoidFunction>(() => {
    onStub?.();

    setBannerHidden();
  }, [onStub, setBannerHidden]);

  const handleError = useCallback<HandleError>(
    error => {
      onError?.(error);

      setBannerHidden();
    },
    [onError, setBannerHidden],
  );

  const handleRender = useCallback<VoidFunction>(() => {
    onRender?.();
  }, [onRender]);

  if (isBannerHidden) {
    return null;
  }

  return (
    <AdfoxBanner {...size} lazyLoad params={params} onError={handleError} onRender={handleRender} onStub={handleStub} />
  );
});

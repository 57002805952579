import React from 'react';
import { Car16, Walk16 } from '@cian/ui-kit';

import {
  ETransportType,
  IUndergroundForRecommendationSchema,
} from '../../../../repositories/search-engine/entities/recommended_offers/UndergroundForRecommendationSchema';
import { UndergroundIcon, UndergroundType } from '../UndergroundIcon';
import * as s from './Underground.css';

export type TUndergroundParams = IUndergroundForRecommendationSchema;

/**
 * Элемент метро в карточке подобных статей
 */
export const Underground = ({ time, transportType, color, name, iconType }: TUndergroundParams) => {
  return (
    <div className={s['wrapper']}>
      <div className={`${s['name']} ${time && transportType ? s['_ellipsis'] : ''}`} title={name}>
        <span style={{ color: color ? `#${color}` : undefined }} className={s['icon-wrapper']}>
          <UndergroundIcon type={iconType as UndergroundType} />
        </span>
        <span className={s['ellipsis']}>{name}</span>
      </div>

      {time && transportType && (
        <div className={s['transport']}>
          {transportType === ETransportType.Transport && <Car16 />}
          {transportType === ETransportType.Walk && <Walk16 />}
          <span>{time}</span>
        </div>
      )}
    </div>
  );
};

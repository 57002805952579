import { ERequestStatus } from '../../types/requestStatus';
import { ISliders } from '../../types/slider';
import { EGetSliderActionType, TSliderActions } from '../../actions/slider';
import { createSlider } from '../../utils';

export const defaultState: ISliders = {
  items: [],
};

export const slidersReducer = (state = defaultState, action: TSliderActions) => {
  switch (action.type) {
    case EGetSliderActionType.Loading: {
      const { name, audienceAccessType } = action.payload;
      const index = state.items.findIndex(item => item.name === name && item.audienceAccessType === audienceAccessType);
      const item = index > -1 ? state.items[index] : createSlider({ name, audienceAccessType });

      return {
        ...state,
        items: [
          ...state.items.slice(0, index),
          { ...item, status: ERequestStatus.Loading },
          ...state.items.slice(index + 1),
        ],
      };
    }
    case EGetSliderActionType.Succeed: {
      const { name, audienceAccessType } = action.payload;
      const index = state.items.findIndex(item => item.name === name && item.audienceAccessType === audienceAccessType);
      const item = index > -1 ? state.items[index] : createSlider({ name, audienceAccessType });

      return {
        ...state,
        items: [
          ...state.items.slice(0, index),
          { ...item, ...action.payload, status: ERequestStatus.Succeed },
          ...state.items.slice(index + 1),
        ],
      };
    }

    case EGetSliderActionType.Failed: {
      const { name, audienceAccessType } = action.payload;
      const index = state.items.findIndex(item => item.name === name && item.audienceAccessType === audienceAccessType);
      const item = index > -1 ? state.items[index] : createSlider({ name, audienceAccessType });

      return {
        ...state,
        items: [
          ...state.items.slice(0, index),
          { ...item, status: ERequestStatus.Failed },
          ...state.items.slice(index + 1),
        ],
      };
    }

    default:
      return state;
  }
};

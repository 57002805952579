import { IPostItemAttributes } from '../../../types/posts';
import { ETelegramChannel } from '../../../repositories/journal/entities/journal/JournalAttributesSchema';

/**
 * Предназначен ли пост для B-аудитории (профики)
 */
export const isProfessionalAudience = (
  tags: Pick<IPostItemAttributes, 'tags'>['tags'],
  tgAudience?: ETelegramChannel | null
) => {
  if (tgAudience) {
    return tgAudience === ETelegramChannel.Professionals;
  }

  if (!tags || !tags.length) {
    return false;
  }

  return tags.includes('профессионалам') || tags.includes('риелторы');
}

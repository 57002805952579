export function disableScroll() {
  document.body.style.overflow = 'hidden';
  document.body.style.top = `-${document.body.scrollTop}px`;
  document.body.style.left = '0';
  document.body.style.right = '0';
  document.body.style.bottom = '0';
  document.body.style.maxHeight = '100vh';
  document.body.style.position = 'fixed';
}

export function enableScroll() {
  document.body.style.overflow = 'visible';
  document.body.style.position = 'static';
  document.body.style.left = 'auto';
  document.body.style.right = 'auto';
  document.body.style.bottom = 'auto';
  document.body.style.maxHeight = 'initial';
  document.body.scrollTop = (parseInt(String(document.body.style.top), 10) * -1);
  document.body.style.top = 'auto';
  document.body.style.transform = 'none';
}

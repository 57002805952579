import { HttpBadStatusCodeError } from '@cian/peperrors/shared';
import { CancellationToken } from '@cian/http-api';

import { TThunkAction } from '../../../types/redux';
import {
  fetchQuickSearch,
  TQuickSearchResponse,
  IQuickSearchRequest,
} from '../../../repositories/journal/v1/quick-search';
import { IQuickSearchingResults } from '../../../types/quickSearchingResults';
import { ERequestStatus } from '../../../types/requestStatus';

export type TGetQuickSearchingResultsCallbackAction =
  | ERequestStatus.Loading
  | ERequestStatus.Succeed
  | ERequestStatus.Failed;

export interface IGetQuickSearchingResultsParams extends Pick<IQuickSearchRequest, 'q' | 'limit'> {
  cancellationToken: CancellationToken;
  callback(action: TGetQuickSearchingResultsCallbackAction, data?: IQuickSearchingResults): void;
}

export const getQuickSearchingResults = ({
  callback,
  cancellationToken,
  ...otherProps
}: IGetQuickSearchingResultsParams): TThunkAction<Promise<void>> => {
  return async (_, getState, context) => {
    const { httpApi, logger } = context;
    const {
      userNew: { isB2BModeEnabled },
      geo: { currentRegion },
    } = getState();

    callback(ERequestStatus.Loading);

    try {
      const { response, statusCode }: TQuickSearchResponse = await fetchQuickSearch({
        httpApi,
        parameters: {
          regionId: currentRegion.id,
          b2b: isB2BModeEnabled,
          ...otherProps,
        },
        config: {
          cancellationToken,
        },
      });

      if (statusCode !== 200) {
        await Promise.reject(
          new HttpBadStatusCodeError(
            'Не удалось загрузить список результатов поиска',
            statusCode,
            'actions.getQuickSearchingResults',
          ),
        );

        return;
      }

      callback(ERequestStatus.Succeed, { meta: response.meta, items: response.data });
    } catch (e) {
      /* istanbul ignore next*/
      if (cancellationToken.isCanceled()) {
        return;
      }

      logger.error(e, {
        domain: 'actions.getQuickSearchingResults',
      });

      callback(ERequestStatus.Failed);
    }
  };
};

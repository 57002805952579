import React, { useCallback, useState } from 'react';
import { Button, Input, useDeviceType, Outside } from '@cian/ui-kit';

import * as s from './NewsSubscribeForm.css';

interface INewsSubscribeFormParams {
  isInvalid?: boolean;
  isDisabled?: boolean;
  isSubscribed?: boolean;
  onSubmit(email: string): void;
  onChange(email: string): void;
}

/** Форма подписки */
export const NewsSubscribeForm = ({
  isInvalid,
  isDisabled,
  isSubscribed,
  onSubmit,
  onChange,
}: INewsSubscribeFormParams) => {
  const [value, setValue] = useState('');
  const deviceType = useDeviceType();
  const isPhone = () => deviceType === 'phone';

  /** Сохраняет в стэйт */
  const handleChange = useCallback(
    (_, value: string) => {
      setValue(value);
      onChange(value);
    },
    [onChange],
  );

  /** Отправляет значение по клику или Enter */
  const handleSubmit = useCallback(
    (e: { preventDefault: () => void }) => {
      e.preventDefault();
      onSubmit(value);
    },
    [onSubmit, value],
  );

  return (
    <div className={`${s['wrapper']}`} data-web-ui-test-id="SubscribeForm">
      {isSubscribed && (
        <div className={`${s['subscribed-message']}`}>
          Спасибо!
          <br />
          Вы успешно подписаны на нашу рассылку!
        </div>
      )}

      {!isSubscribed && (
        <div className={`${s['inner-wrapper']}`}>
          <div className={`${s['title']}`}>Хотите быть в&nbsp;курсе новостей рынка недвижимости?</div>

          <div className={`${s['form-wrapper']}`}>
            <div className={`${s['form-title']}`}>Подпишитесь на рассылку:</div>

            <form className={`${s['form']}`} onSubmit={handleSubmit}>
              <div className={`${s['input-wrapper']}`}>
                <Outside active onOutside={() => onChange(value)} insideRefs={[]}>
                  <Input
                    value={value}
                    invalid={isInvalid}
                    disabled={isDisabled}
                    size={'M'}
                    placeholder={'Ваш email'}
                    onChange={handleChange}
                  />
                </Outside>

                <div className={`${s['error']} ${isInvalid ? s['_active'] : ''}`}>
                  {isInvalid && 'Неверный формат email'}
                </div>
              </div>

              <Button theme="fill_primary" size={'M'} fullWidth={isPhone() ? true : undefined} disabled={isDisabled}>
                Подписаться
              </Button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

import * as React from 'react';
import { connect } from 'react-redux';

import { IConfig } from '../../types/config';
import { ISearch } from '../../types/journal';
import { Search as SearchWidget } from '../../components/search/search';

import { toggleSearch } from '../../reducers/modules/config/config';
import { performSearch } from '../../reducers/modules/search/search';
import { TDispatch } from '../../types/redux';

interface IProps {
  config: IConfig;
  search: ISearch;
  dispatch: TDispatch;
}

class SearchComponent extends React.Component<IProps> {
  public render() {
    if (this.props.config.searchShown) {
      return (
        <SearchWidget
          items={this.props.search.items}
          closeSearch={() => this.props.dispatch(toggleSearch(false))}
          performSearch={(value: string) =>
            this.props.dispatch(performSearch(value, Number(this.props.config.regionID)))
          }
          isEmpty={this.props.search.isEmpty}
        />
      );
    } else {
      return <div />;
    }
  }
}

export const Search = connect((state: { config: IConfig; search: ISearch }) => ({
  config: state.config,
  search: state.search,
}))(SearchComponent);

import { IPostItemAttributes } from '../../../types/posts';
import {ETelegramChannel} from "../../../repositories/journal/entities/journal/JournalAttributesSchema";

/**
 * Предназначен ли пост для CRE-аудитории (коммерческие)
 */
export const isCommercialAudience = (
  tags: Pick<IPostItemAttributes, 'tags'>['tags'],
  tgAudience?: ETelegramChannel | null
) => {
  if (tgAudience) {
    return tgAudience === ETelegramChannel.Commercial;
  }

  if (!tags || !tags.length) {
    return false;
  }

  return tags.includes('коммерческая недвижимость');
}

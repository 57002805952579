import { ITypedReduxAction } from '../../../types/redux/actionType';
import { TDiscussion } from '../../../types/discussions';

export enum EGetDiscussionsActionType {
  Loading = 'discussions/getDiscussions/loading',
  Succeed = 'discussions/getDiscussions/succeed',
  Failed = 'discussions/getDiscussions/failed',
}

export type TGetDiscussionsLoading = ITypedReduxAction<EGetDiscussionsActionType.Loading>;
export type TGetDiscussionsSucceed = ITypedReduxAction<EGetDiscussionsActionType.Succeed, TDiscussion[]>;
export type TGetDiscussionsFailed = ITypedReduxAction<EGetDiscussionsActionType.Failed>;

export type TGetDiscussions = TGetDiscussionsLoading | TGetDiscussionsSucceed | TGetDiscussionsFailed;

import * as React from 'react';

import { IUser } from '../../types/user';
import { IAnswer } from '../../types/answer';
import { plural } from "@cian/utils/lib/shared/plural";
import { trackPollAnswerClick } from "./tracking";

import s from './poll.css';

interface IProps {
  answers: IAnswer[];
  answerCount: number;
  title: string;
  isMultiAnswer: boolean;
  isUserVote: boolean;
  onVotePressed(id: number): void;
  onSubmitPressed(): void;
  type: string;
  userVote: number[];
  user?: IUser;
}

export class Poll extends React.Component<IProps, {}> {

  public render() {
    if (this.props.title === '') {
      return null
    }

    const isMultiAnswer = this.props.isMultiAnswer;
    const isUserVote = this.props.isUserVote;

    let answersBlock;
    if (isUserVote) {
      answersBlock = this.props.answers.map((answer: IAnswer) => {
        const divStyle = {
          width: answer.percent+'%'
        };
        return (
          <div className={`${s['survey__option']} ${this.props.userVote.includes(answer.id) ? s['isChecked'] : ''} ${s['survey__option_result']}`}>
            <div className={`${s['survey__option-bg']}`} style={ divStyle } />
            <img
              src={`${answer.image}`}
              className={`${s['survey__emoji']}`}
              alt="" />
            <span className={`${s['survey__option-text']}`}>{`${answer.name}`}
              <span> ({`${answer.count}`} { plural(answer.count, ['голос', 'голоса', 'голосов']) })</span>
            </span>
            <span className={`${s['survey__percent']}`}>{`${answer.percent}`}%</span>
          </div>
        )
      });
    } else {
      answersBlock = this.props.answers.map((answer: IAnswer) => (
        <div className={`${s['survey__option-wrapper']}`}>
          <input type={`${isMultiAnswer ? 'checkbox' : 'radio'}`} className={`${s['survey__input']}`}
                 id={`vote-${answer.id}`} data-id={`${answer.id}`} name="options"
                 onClick={() => {
                   if (!isUserVote) {
                     this.props.onVotePressed(answer.id);
                     trackPollAnswerClick(answer.name);
                   }
                 }}
          />
          <label className={`${s['survey__option']} ${isMultiAnswer ? s['survey__option_multiple'] : ''}`}
                 htmlFor={`vote-${answer.id}`}>
            <img
              src={`${answer.image}`}
              className={`${s['survey__emoji']}`}
              alt=""/>
            <span className={`${s['survey__option-text']}`}>{`${answer.name}`}</span>
          </label>
        </div>
      ));
    }

    return (
      <div className={`${s['survey']}`}>
        <h2 className={`${s['survey__title']} ${isUserVote ? s['survey__title_result'] : ''}`}>{ this.props.title }</h2>
        <div className={`${s['survey__options']}`}>
        { isUserVote && <span className={`${s['survey__quantity']}`}>Всего проголосовало <span>{ this.props.answerCount } { plural(this.props.answerCount, ['человек', 'человека', 'человек']) }</span></span> }
        { answersBlock }
        </div>
        { isMultiAnswer && !isUserVote &&
          <button className={`${s['survey__button']}`} onClick={() => {
            this.props.onSubmitPressed();
          }}>Ответить</button>
        }
      </div>
    );
  }
}

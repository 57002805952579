/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TGetDocumentListModel,
  IGetDocumentListRequest,
  IMappers,
  IGetDocumentListResponse,
  IGetDocumentListResponseError,
  TGetDocumentListResponse,
} from './types';

const defaultConfig: TGetDocumentListModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'GET',
  microserviceName: 'journal',
  pathApi: '/v1/get-document-list/',
  hostType: 'api',
} as TGetDocumentListModel;

function createGetDocumentListModel(parameters: IGetDocumentListRequest): TGetDocumentListModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetDocumentListOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IGetDocumentListRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchGetDocumentList<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IGetDocumentListOptions<TResponse200, TResponse400>): Promise<
  TResponse200 | TResponse400 | TGetDocumentListResponse
> {
  const { response, statusCode } = await httpApi.fetch(createGetDocumentListModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetDocumentListResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IGetDocumentListResponseError);
    }
  }

  return { response, statusCode } as TGetDocumentListResponse;
}

export { defaultConfig, createGetDocumentListModel, fetchGetDocumentList };

import React from 'react';
import { UIHeading2 } from '@cian/ui-kit';
import { Link } from 'react-router-dom';

import { IMainNews } from '../../types/mainNews';
import { NewsItem } from '../MainNews/components/NewsItem';
import { SidebarCard } from '../SidebarCard';
import * as s from './SidebarNews.css';

/**
 * Лента новостей в сайдбаре
 */
export const SidebarNews = ({ items }: IMainNews) => (
  <SidebarCard>
    <UIHeading2>Главные новости</UIHeading2>

    <div className={s['items-wrapper']}>
      {items.map(item => (
        <NewsItem key={`news-item-${item.id}`} {...item} />
      ))}
    </div>

    <Link to={`/novosti/`} className={s['more-link']}>
      Смотреть все
    </Link>
  </SidebarCard>
);

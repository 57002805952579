import * as React from 'react';
import { connect } from 'react-redux';

import { IUser } from '../../types/user';
import { ILike } from '../../types/likes';
import { Likes as LikesComponent } from '../../components/likes/likes';
import { loadLikeInfo, submitVote } from '../../reducers/modules/likes/likes';
import { requestAuthentication } from '../../../app/helpers/authentication_helper';
import { TDispatch } from "../../types/redux";

interface IProps {
  disabled: boolean;
  likesCount: number;
  dislikesCount: number;
  type: string;
  objectID: string;
  isCompact: boolean;
  userLike: 1 | -1 | null;
  user?: IUser;
  dispatch: TDispatch;
  onLikesUpdate(likesCount: number, dislikesCount: number, userLike: 1 | -1 | null): void;
}

interface IContainerProps {
  disabled: boolean;
  likesCount: number;
  dislikesCount: number;
  type: string;
  objectID: string;
  isCompact?: boolean;
  userLike: 1 | -1 | null;
  onLikesUpdate(likesCount: number, dislikesCount: number, userLike: 1 | -1 | null): void;
}

interface IState {
  likes?: ILike[];
  dislikes?: ILike[];
  likesFetched?: boolean;
  dislikesFetched?: boolean;
}

class LikesContainer extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);

    this.state = {
      likes: [],
      dislikes: [],
      likesFetched: false,
      dislikesFetched: false,
    };
  }

  public render() {
    return (
      <LikesComponent
        disabled={this.props.disabled}
        isCompact={this.props.isCompact}
        likesCount={this.props.likesCount}
        dislikesCount={this.props.dislikesCount}
        likes={this.state.likes || []}
        dislikes={this.state.dislikes || []}
        likesFetched={!!this.state.likesFetched}
        dislikesFetched={!!this.state.dislikesFetched}
        fetchLikes={() => this.fetchLikes()}
        fetchDislikes={() => this.fetchDislikes()}
        onLikePressed={this.handleLikePressed}
        onDislikePressed={this.handleDislikePressed}
        type={this.props.type}
        userLike={this.props.userLike}
        user={this.props.user} />
    );
  }

  private handleLikePressed = () => {
    if (this.props.user && this.props.user.userId > 0) {
      this.submitLike();
    } else {
      requestAuthentication(this.props.objectID);
    }
  }

  private handleDislikePressed = () => {
    if (this.props.user && this.props.user.userId > 0) {
      this.submitDislike();
    } else {
      requestAuthentication(this.props.objectID);
    }
  }

  private submitLike() {
    this.props.dispatch(submitVote(this.props.type, this.props.objectID, 1, (likes, dislikes) => {
      this.props.onLikesUpdate(likes, dislikes, 1);
    }, () => {}) as any);
  }

  private submitDislike() {
    this.props.dispatch(submitVote(this.props.type, this.props.objectID, -1, (likes, dislikes) => {
      this.props.onLikesUpdate(likes, dislikes, -1);
    }, () => {}) as any);
  }

  private fetchLikes() {
    this.props.dispatch(loadLikeInfo(this.props.type, this.props.objectID, 1, (data) => {
      this.setState({
        likes: data,
        dislikes: this.state.dislikes,
        likesFetched: true,
        dislikesFetched: this.state.dislikesFetched,
      });
    }, () => {
      this.setState({
        likes: [],
        dislikes: this.state.dislikes,
        likesFetched: true,
        dislikesFetched: this.state.dislikesFetched,
      });
    }) as any);
  }

  private fetchDislikes() {
    this.props.dispatch(loadLikeInfo(this.props.type, this.props.objectID, -1, (data) => {
      this.setState({
        likes: this.state.likes,
        dislikes: data,
        likesFetched: this.state.likesFetched,
        dislikesFetched: true,
      });
    }, () => {
      this.setState({
        likes: this.state.likes,
        dislikes: [],
        likesFetched: this.state.likesFetched,
        dislikesFetched: true,
      });
    }) as any);
  }
}

export const Likes = connect<IContainerProps, {}, IContainerProps>(
  (state: {
    user: IUser,
  }, props: IContainerProps) => ({
    user: state.user,
    disabled: props.disabled,
    likesCount: props.likesCount,
    dislikesCount: props.dislikesCount,
    type: props.type,
    objectID: props.objectID,
    isCompact: !!props.isCompact,
    onLikesUpdate: props.onLikesUpdate,
    userLike: props.userLike
  }),
)(LikesContainer);

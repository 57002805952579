import React from 'react';

import * as s from './BreadCrumbsLayout.css';

interface IProps {
  children: React.ReactNode;
}

/**
 * Стилевая обертка для хлебных крошек
 */
export const BreadCrumbsLayout = ({ children }: IProps) => {
  return <div className={s['wrapper']}>{children}</div>;
};

import React, { useCallback } from 'react';
import { Image, useDeviceType, Like16 as IconLike16 } from '@cian/ui-kit';

import { IArticlesMainAttributesRubricSchema } from '../../repositories/journal/entities/journal/ArticlesMainAttributesRubricSchema';
import { IArticlesMainAttributesSchema } from '../../repositories/journal/entities/journal/ArticlesMainAttributesSchema';
import { IRubricLink, RubricButton } from '../RubricButton';
import { formatDate } from '../../utils';
import { Card, ICardParams } from '../Card';
import { IconWithCount } from '../IconWithCount';
import { IconMessage16 } from '../Icons';
import { ListingItemType } from './constants';
import { IStatusLink, Status } from './components/Status';
import * as s from './CardListingItem.css';

export interface ICardListingItemRubric {
  /** Название */
  name: string;
  /** Ссылка */
  url: IRubricLink['url'];
}

export interface ICardListingItem
  extends Partial<Pick<IArticlesMainAttributesSchema, 'title' | 'image' | 'datePublish'>> {
  subtitle?: IArticlesMainAttributesSchema['subtitle'] | React.ReactNode;
  height?: ICardParams['height'];
  rubric?: ICardListingItemRubric;
  numComments?: IArticlesMainAttributesSchema['commentsCount'];
  numLikes?: IArticlesMainAttributesSchema['likesCount'];
  url: string;
  children?: React.ReactNode;
  noIndex?: boolean;
  type: IArticlesMainAttributesSchema['type'];
  status?: IStatusLink;
  onRubricButtonClick?(e: React.MouseEvent, url?: IArticlesMainAttributesRubricSchema['url']): void;
  onClick?(e: React.MouseEvent): void;
}

/**
 * Компонент карточки в списке
 */
export const CardListingItem = ({
  height,
  title,
  url,
  subtitle,
  image,
  rubric,
  datePublish,
  numComments = 0,
  numLikes = 0,
  children,
  noIndex,
  type,
  status,
  onRubricButtonClick,
  onClick,
}: ICardListingItem) => {
  const deviceType = useDeviceType();
  const isPhone = () => deviceType === 'phone';

  const handleArticleClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      e.preventDefault();

      if (onClick) {
        onClick(e);
      }
    },
    [onClick],
  );

  const handleRubricClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      e.preventDefault();

      if (!rubric) {
        return;
      }

      if (onRubricButtonClick) {
        onRubricButtonClick(e, rubric?.url);
      }
    },
    [onRubricButtonClick, rubric],
  );

  return (
    <Card height={height} data-web-ui-test-id="CardListItem" onClick={handleArticleClick}>
      <article className={s['card-wrapper']} itemScope itemType={ListingItemType[type]}>
        {image && (
          <a
            href={url}
            className={s['image-wrapper']}
            title={title}
            onClick={handleArticleClick}
            rel={noIndex ? 'nofollow' : undefined}
          >
            <Image
              alt={title}
              borderRadius={0}
              objectFit="cover"
              preloader
              src={image}
              theme="light"
              title={title}
              height={isPhone() ? 145 : 180}
              itemProp="image"
            />
          </a>
        )}

        <div className={`${s['content-wrapper']} ${image ? s['_has-image'] : ''}`} itemProp="articleBody">
          {(rubric || status) && (
            <div className={s['headnote']}>
              {rubric && (
                <RubricButton url={rubric.url} onClick={handleRubricClick} noIndex={noIndex}>
                  {rubric.name.toLowerCase()}
                </RubricButton>
              )}

              {status ? (
                <div className={s['status-wrapper']}>
                  <Status {...status} />
                </div>
              ) : null}
            </div>
          )}

          {title && (
            <a
              href={url}
              className={s['title']}
              title={title}
              rel={noIndex ? 'nofollow' : undefined}
              itemProp="headline"
              onClick={handleArticleClick}
            >
              {title}
            </a>
          )}

          {subtitle && (
            <div className={s['subtitle']} itemProp="description">
              {subtitle}
            </div>
          )}

          <div className={s['footnote-wrapper']}>
            {children}

            {(datePublish || numComments) && (
              <div className={s['footnote']} itemProp="hasPart" itemScope itemType="http://schema.org/PublicationIssue">
                {datePublish && (
                  <div className={s['date-publish']} itemProp="datePublished">
                    {formatDate(new Date(datePublish))}
                  </div>
                )}

                {numComments ? <IconWithCount count={numComments} icon={<IconMessage16 />} /> : null}
                {numLikes ? <IconWithCount count={numLikes} icon={<IconLike16 />} /> : null}
              </div>
            )}
          </div>
        </div>
      </article>
    </Card>
  );
};

import { ERequestStatus } from '../../types/requestStatus';
import { TMainArticlesState } from '../../types/mainArticles';
import {
  EGetMainArticlesActionType,
  TMainArticlesActions,
  EResetMainArticlesActionType,
} from '../../actions/mainArticles';

export const defaultState: TMainArticlesState = {
  status: ERequestStatus.Initial,
  meta: {
    b2b: false,
    limit: 10,
    offset: 0,
    regionId: 0,
    tag: '',
    total: 0,
  },
  items: [],
};

export const mainArticlesReducer = (state = defaultState, action: TMainArticlesActions) => {
  switch (action.type) {
    case EGetMainArticlesActionType.Loading:
      return { ...state, status: ERequestStatus.Loading };

    case EGetMainArticlesActionType.Succeed:
      return { ...state, ...action.payload, status: ERequestStatus.Succeed };

    case EGetMainArticlesActionType.AppendSucceed:
      return {
        ...state,
        meta: action.payload.meta,
        items: [...state.items, ...action.payload.items],
        status: ERequestStatus.Succeed,
      };

    case EGetMainArticlesActionType.Failed:
      return { ...state, status: ERequestStatus.Failed };

    case EResetMainArticlesActionType.Reset:
      return defaultState;

    default:
      return state;
  }
};

import * as React from 'react';

import { SubmenuItem } from '../SubmenuItem';
import { TSubmenuGroupProps } from '../../types';
import * as s from './Submenu.css';

/**
 * Группа подменю
 */
export const Submenu = ({ items, onClick, activeSubItemSlug }: TSubmenuGroupProps) => {
  return (
    <div className={`${s['wrapper']}`}>
      <ul className={`${s['inner-wrapper']}`}>
        {items.map(({ slug, ...otherProps }) => {
          return (
            <SubmenuItem
              key={`submenu-item-${slug}`}
              isActive={activeSubItemSlug === slug}
              onClick={() => onClick(slug)}
              {...otherProps}
            />
          );
        })}
      </ul>
    </div>
  );
};

import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useDeviceType } from '@cian/ui-kit';

import { DocumentListingHeaderContainer } from '../ListingHeader';
import { buildPostUrl, useGetListing, useTimeoutEffect } from '../../utils';
import { IApplicationState, TThunkDispatch } from '../../types/redux';
import { selectCurrentPageMeta } from '../../selectors/pagesMeta';
import { EType } from '../../repositories/journal/entities/page_info/PageInfoSchema';
import { ERequestStatus } from '../../types/requestStatus';
import { BreadCrumbsContainer } from '../BreadCrumbs';
import {
  DocumentListingItem,
  DocumentCardListingItem,
  SkeletonDocumentListingItem,
  SkeletonDocumentCardListingItem,
} from '../../components/ListingItem';
import { DocumentListingItemLayout } from './components/DocumentListingItemLayout';
import { DocumentListingGroupLayout } from './components/DocumentListingGroupLayout';
import { BreadCrumbsLayout } from './components/BreadCrumbsLayout';
import { unescapeHtml } from '../../../app/helpers/make_html_helper';
import { getDocumentGroupList } from '../../actions/documentGroupList';
import { NothingFound } from '../../components/NotFound';
import { Skeleton } from '../../components/Skeleton';
import { selectDocumentGroupList } from '../../selectors/documentGroupList';

/**
 * Листинг документов
 */
export const DocumentListing = () => {
  const dispatch = useDispatch<TThunkDispatch>();
  const history = useHistory();
  const { pathname } = useLocation();
  const {
    type: pageType,
    pathname: currentPageTypePathname,
    title,
  } = useSelector((state: IApplicationState) => selectCurrentPageMeta(state, pathname));
  const deviceType = useDeviceType();
  const isPhone = deviceType === 'phone';
  const ListingItemComponent = isPhone ? DocumentCardListingItem : DocumentListingItem;
  const { items: documentGroups, status: documentGroupsStatus } = useSelector(selectDocumentGroupList);
  const [isSkeletonShown, setIsSkeletonShown] = useState(
    documentGroupsStatus === ERequestStatus.Loading && !documentGroups.length,
  );

  /** Если не нашел нужный тип, выкидывает на дефолтный */
  useEffect(() => {
    if (![EType.Documents].includes(pageType)) {
      history.replace(currentPageTypePathname);
    }
  }, [currentPageTypePathname, history, pageType]);

  /** Забирает статьи в стор и трекает показ листинга */
  useGetListing(
    () => {
      dispatch(getDocumentGroupList()).finally();
    },
    {
      dependencyNames: [],
      eventTrackerData: {
        page: {
          extra: {
            journalPageType: 'Rubric',
            journalRubrics: title,
          },
        },
      },
    },
  );

  /** Создает небольшую задержку перед скрытием скелетона, когда элементов не найдено */
  useTimeoutEffect(() => {
    setIsSkeletonShown(documentGroupsStatus === ERequestStatus.Loading && !documentGroups.length);
  }, [!isSkeletonShown || documentGroups.length ? 0 : 500]);

  /** При клике по заголовку элемента роутит на соответствующую страницу */
  const handleClick = useCallback(
    (e, url: string) => {
      e.preventDefault();
      history.push(url);
    },
    [history],
  );

  return (
    <>
      <DocumentListingHeaderContainer />

      <div>
        {isSkeletonShown && (
          <>
            <DocumentListingGroupLayout>
              <Skeleton width={150} height={24} />
            </DocumentListingGroupLayout>

            {[...Array(6)].map((_, index) => (
              <DocumentListingItemLayout key={index}>
                {isPhone ? <SkeletonDocumentCardListingItem /> : <SkeletonDocumentListingItem />}
              </DocumentListingItemLayout>
            ))}
          </>
        )}

        {Boolean(documentGroups.length) &&
          documentGroups.map(({ name: groupName, items }) => (
            <Fragment key={groupName}>
              <DocumentListingGroupLayout>{groupName}</DocumentListingGroupLayout>

              {items.map(({ title, subtitle, slug, type, id, datePublish, commentsCount }, index) => (
                <DocumentListingItemLayout key={index}>
                  <ListingItemComponent
                    title={unescapeHtml(title)}
                    subtitle={unescapeHtml(subtitle)}
                    url={buildPostUrl({ type, slug, id })}
                    rubric={{ name: groupName }}
                    datePublish={datePublish || undefined}
                    numComments={commentsCount}
                    onArticleClick={e => handleClick(e, buildPostUrl({ type, slug, id }))}
                  />
                </DocumentListingItemLayout>
              ))}
            </Fragment>
          ))}

        {[ERequestStatus.Succeed, ERequestStatus.Failed].includes(documentGroupsStatus) && !documentGroups.length && (
          <NothingFound pathname={currentPageTypePathname} />
        )}
      </div>

      {/* Хлебные крошки для мобилки */}
      {isPhone && (
        <BreadCrumbsLayout>
          <BreadCrumbsContainer />
        </BreadCrumbsLayout>
      )}
    </>
  );
};

import { IProductCa } from '../../../types/analytics';
import { IRecommendedOfferSchema } from '../../../repositories/search-engine/entities/recommended_offers/RecommendedOfferSchema';

export interface ITransformProductParams {
  realtyId: IRecommendedOfferSchema['realtyId'];
}

/**
 * Приводит данные офера к формату CA $.product
 */
export const transformProduct = ({ realtyId }: ITransformProductParams): IProductCa => ({
  id: realtyId,
});

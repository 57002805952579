import * as React from 'react';

import s from './social_networks.css';

export const SocialNetworks = () => {
  const vkWidget = `
    <iframe
        name="fXD-cian-vk-widget"
        src="https://vk.com/widget_community.php?gid=115699231&width=292&height=187"
        width="100%"
        height="220"
        frameborder="0"
        style="max-width:290px">
    </iframe>`;

  return (
    <div className={s['social-networks']}>
      <div className={s['social-networks__vk']} dangerouslySetInnerHTML={{__html: vkWidget}}/>
    </div>
  );
};

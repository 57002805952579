import { IBreadItem } from '../../shared/types/bread_crumb';
import { pages as breadcrumbs } from '../../shared/components/bread_crumbs/bread_crumbs_item';

export const getBreadCrumbsNames = (currentBreadcrumbs: IBreadItem[]): string[] => {
  const names: string[] = [];
  let currentBreadcrumb;

  for (let i = 0; i < currentBreadcrumbs.length; i++) {
    if (currentBreadcrumbs[i].name && currentBreadcrumbs[i].url) {
      currentBreadcrumb = {
        name: currentBreadcrumbs[i].name,
        url: currentBreadcrumbs[i].url,
      };
    } else {
      currentBreadcrumb = breadcrumbs.find(
        (page) => (currentBreadcrumbs[i].path === page.pathname),
      );
    }
    if (currentBreadcrumb && currentBreadcrumb.name) {
      names.push(currentBreadcrumb.name);
    }
  }
  return names;
};

import * as React from 'react';
import { Button, useDeviceType } from "@cian/ui-kit";
import { RequestDemandButton } from '../../containers/realtor_demand/request_demand_button';
import { DemandBannerLocation } from '../../containers/realtor_demand/contants/demand_banner_location';

import s from './demand_banner.css';

export interface IDemandBannerProps {
  /** Банер отображается в листинге - горизонтальное отображение в десктопе **/
  listView?: boolean;
}

export const DemandBanner: React.SFC<IDemandBannerProps> = ({ listView }) => {
  const deviceType = useDeviceType();
  return (
    <div className={`${s['container']} ${listView ? s['inlist'] : ''}`}>
      <div className={s['logo']}/>
      <h4 className={s['title']}>Подберем риелтора</h4>
      <div className={s['description']}>
        Надежный специалист возьмет на себя решение юридических вопросов и поможет выгодно продать сложные объекты
      </div>
      <div className={s['action']}>
        <RequestDemandButton
          bannerLocation={listView ? DemandBannerLocation.LISTING_BANNER : DemandBannerLocation.ADD_QUESTION}
          renderButton={({ onClick }) => (
            <Button
              theme="fill_primary"
              size={deviceType === 'phone' ? 'M' : 'XS'}
              onClick={onClick}
            >
              Подробнее
            </Button>
          )}
        />
      </div>
    </div>
  )
}

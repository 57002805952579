import { IRegionsItem} from "../../../../types/regions";
import { IRegionGroups } from './List';

/**
 * Группирует регионы по первой букве
 * { "A": [{}, {}, {}] }
 */
export function prepareGroups(regions: IRegionsItem[]): IRegionGroups {
  return regions.reduce((acc, region): IRegionGroups => {
    const firstLetter = region.displayName.charAt(0).toUpperCase();

    if (!acc[firstLetter]) {
      acc[firstLetter] = [];
    }

    acc[firstLetter].push(region);

    return acc;
  }, {} as IRegionGroups)
}

import * as React from 'react';

import { IDiscussedItem } from '../../types/discussed';
import { DiscussedItem } from './discussed_item';

import s from './discussed.css';

interface IProps {
  comments: IDiscussedItem[];
}

export const Discussed: React.FC<IProps> = props => {
  const sliceComments = () => {
    return props.comments.slice(0, 5);
  }

  return (
    <div className={s['discussed']}>
      <div className={s['discussed-header']}>
        <span className={s['discussed-header__text']}>Сейчас обсуждают</span>
      </div>
      <div className={s['discussed-comments']}>
        {
          sliceComments().map(comment => {
            return <DiscussedItem key={`comment-${comment.id}`} comment={comment} />;
          })
        }
      </div>
    </div>
  );
}

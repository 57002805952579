import { IGeo } from '../../types/geo';
import { ESetCurrentRegionActionType, TGeoActions } from '../../actions/geo';

export const defaultState: IGeo = {
  currentRegion: {
    id: 0,
    name: 'Вся Россия',
  },
};

export const geoReducer = (state = defaultState, action: TGeoActions) => {
  switch (action.type) {
    case ESetCurrentRegionActionType.Set:
      return { ...state, currentRegion: action.payload };

    default:
      return state;
  }
};

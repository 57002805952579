import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Splide } from '@splidejs/react-splide';
import {
  ArrowL16 as IconChevronLeft16,
  ArrowR16 as IconChevronRight16,
  useDeviceType,
  Fullscreen16 as IconFullscreen16,
  Close16 as IconClose16,
  UIText2,
} from '@cian/ui-kit';

import {
  IExtractAndReplacePhotoGalleryItem,
  IExtractAndReplacePhotoGallerySlideItem,
} from '../../containers/post_card/helpers';
import { ReactPortal } from '../ReactPortal';
import { ThumbsGallery } from './components/ThumbsGallery';
import { MainGallery } from './components/MainGallery';
import { useEventListener } from '../../utils';
import * as s from './PostPhotoGallery.css';

export interface IPostPhotoGallery {
  slides: IExtractAndReplacePhotoGallerySlideItem[];
  source?: IExtractAndReplacePhotoGalleryItem['source'];
  description?: IExtractAndReplacePhotoGalleryItem['description'];
  objectFit?: IExtractAndReplacePhotoGalleryItem['objectFit'];
}

/**
 * Фотогалерея в теле поста
 */
export const PostPhotoGallery = ({ slides, source, description, objectFit }: IPostPhotoGallery) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isFullscreenEnabled, setIsFullscreenEnabled] = useState(false);
  const [currentDescription, setCurrentDescription] = useState<string | undefined>(undefined);
  const [currentSource, setCurrentSource] = useState<string | undefined>(undefined);
  const mainGalleryRef = useRef<Splide>(null);
  const thumbsGalleryRef = useRef<Splide>(null);
  const fullscreenMainGalleryRef = useRef<Splide>(null);
  const fullscreenThumbsGalleryRef = useRef<Splide>(null);
  const descriptionRef = useRef<HTMLDivElement>(null);
  const fullscreenWrapperRef = useRef<HTMLDivElement>(null);
  const deviceType = useDeviceType();
  const isPhone = deviceType === 'phone';

  /** Синхронизирует основную и миниатюрную галереи */
  useEffect(() => {
    if (mainGalleryRef?.current && thumbsGalleryRef?.current?.splide) {
      mainGalleryRef.current.sync(thumbsGalleryRef.current.splide);
    }
  }, [deviceType]);

  /** Синхронизирует основную и полноэкранную галереи */
  useEffect(() => {
    document.body.style.overflow = isFullscreenEnabled ? 'hidden' : '';

    if (!isFullscreenEnabled) {
      return;
    }

    if (mainGalleryRef?.current && fullscreenMainGalleryRef?.current?.splide) {
      mainGalleryRef.current.sync(fullscreenMainGalleryRef.current.splide);
    }

    if (mainGalleryRef?.current && fullscreenThumbsGalleryRef?.current?.splide) {
      mainGalleryRef.current.sync(fullscreenThumbsGalleryRef.current.splide);
    }
  }, [isFullscreenEnabled]);

  /** Устанавливает текущее описание и источник */
  useEffect(() => {
    setCurrentDescription(slides[activeIndex].description || description);
    setCurrentSource(slides[activeIndex].source || source);
  }, [activeIndex, description, slides, source]);

  /** Плавно меняет размер блока описания/источника при их изменении */
  useEffect(() => {
    if (!descriptionRef?.current) {
      return;
    }

    const container = descriptionRef.current as HTMLElement;
    const innerContainer = container.children[0];

    container.style.height = `${innerContainer.getBoundingClientRect().height}px`;
  }, [currentDescription, currentSource]);

  /** Закрывает полноэкранную галерею при нажатии на Escape */
  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (!isFullscreenEnabled) {
        return;
      }

      if (e.code === 'Escape') {
        setIsFullscreenEnabled(false);
      }
    },
    [isFullscreenEnabled],
  );

  /** Слушает нажатие клавиш у полноэкранной галереи */
  useEventListener('keydown', handleKeyDown, fullscreenWrapperRef);

  return (
    <div className={s['wrapper']}>
      <MainGallery
        ref={mainGalleryRef}
        slides={slides}
        onMounted={gallery => setActiveIndex(gallery.index)}
        onMove={gallery => setActiveIndex(gallery.index)}
        description={description}
        objectFit={objectFit === 'cover' ? 'cover' : 'contain'}
      >
        <div className={`splide__arrows ${s['arrows']}`}>
          <button className={`splide__arrow splide__arrow--prev ${s['arrow']} ${s['arrow-prev']}`}>
            <IconChevronLeft16 color={'white_100'} />
          </button>

          <button className={`splide__arrow splide__arrow--next ${s['arrow']} ${s['arrow-next']}`}>
            <IconChevronRight16 color={'white_100'} />
          </button>
        </div>

        <button className={s['fullscreen-button']} onClick={() => setIsFullscreenEnabled(true)}>
          <IconFullscreen16 color={'white_100'} />
        </button>
      </MainGallery>

      {!isPhone && slides?.length > 1 && (
        <ThumbsGallery ref={thumbsGalleryRef} slides={slides} activeIndex={activeIndex} />
      )}

      <div ref={descriptionRef} className={s['description']}>
        <div className={s['description-inner']}>
          {currentDescription && <UIText2 color={'black_100'}>{currentDescription}</UIText2>}
          {currentSource && <UIText2 color={'gray60_100'}>Источник: {currentSource}</UIText2>}
        </div>
      </div>

      <ReactPortal wrapperId={'photo-gallery-portal'}>
        <div ref={fullscreenWrapperRef}>
          {isFullscreenEnabled && (
            <div className={s['fullscreen-overlay']}>
              <MainGallery
                ref={fullscreenMainGalleryRef}
                slides={slides}
                description={description}
                objectFit={'contain'}
                options={{ start: activeIndex, width: '100vw', height: 'calc(100vh - 200px)', heightRatio: undefined }}
              >
                <div className={`splide__arrows ${s['arrows']}`}>
                  <button className={`splide__arrow splide__arrow--prev ${s['arrow']} ${s['arrow-prev']}`}>
                    <IconChevronLeft16 color={'white_100'} />
                  </button>

                  <button className={`splide__arrow splide__arrow--next ${s['arrow']} ${s['arrow-next']}`}>
                    <IconChevronRight16 color={'white_100'} />
                  </button>
                </div>
              </MainGallery>

              <button className={s['close-button']} onClick={() => setIsFullscreenEnabled(false)}>
                <IconClose16 color={'white_100'} />
              </button>

              <ThumbsGallery
                ref={fullscreenThumbsGalleryRef}
                slides={slides}
                activeIndex={activeIndex}
                options={{ start: activeIndex, width: '100vw', focus: undefined }}
              />
            </div>
          )}
        </div>
      </ReactPortal>
    </div>
  );
};

import * as React from 'react';
import { connect } from 'react-redux';
import { ModalWindow } from '@cian/ui-kit/modal';

import { IConfig } from '../../types/config';
import { ERegionsStatus, IRegions, IRegionsItem } from '../../types/regions';
import { Header, Tabs, List } from '../../components/GeoSwitcher';
import { updateRegion, toggleRegions } from '../../reducers/modules/config/config';
import { TDispatch } from '../../types/redux';

interface IProps {
  config: IConfig;
  /** Коллекция регионов */
  regions: IRegions;
  dispatch: TDispatch;
}

interface IState {
  /** Поле ввода названия региона */
  inputValue: string;
  /** Активный объект региона */
  currentRegion: IRegionsItem;
}

class RegionsComponent extends React.Component<IProps, IState> {
  private regions: IRegionsItem[];

  public constructor(props: IProps) {
    super(props);

    this.state = {
      inputValue: '',
      currentRegion:
        props.regions.items.find(region => region.id === Number(props.config.regionID)) || this.getFakeRegion(),
    };

    this.regions = props.regions.items || [];
  }

  // eslint-disable-next-line react/no-deprecated
  public componentWillReceiveProps(nextProps: IProps) {
    if (nextProps.config.regionsShown) {
      document.body.style.overflow = 'hidden';
    }

    if (nextProps.regions.items.length > 0 && nextProps.regions.items[0].id === 0) {
      this.regions = [...nextProps.regions.items];
      this.regions.splice(0, 1);
    }
  }

  public render() {
    if (!this.props.config.regionsShown) {
      return null;
    }

    return (
      <ModalWindow
        open={this.props.config.regionsShown}
        onClose={() => this.props.dispatch(toggleRegions(false))}
        header={
          <Header
            inputValue={this.state.inputValue}
            suggests={this.getSuggests()}
            loading={this.props.regions.status === ERegionsStatus.Loading}
            onChangeInputValue={this.handleChangeInputValue}
            onSelectRegion={this.handleSelectRegion}
            onSubmitRegion={this.handleSubmitRegion}
          />
        }
        content={
          <>
            <Tabs regions={this.getTabsRegions()} onClick={this.handleSelectRegion} />
            <List regions={this.regions} onClick={this.handleSelectRegion} activeRegion={this.state.currentRegion} />
          </>
        }
        width={1179}
        height={'100%'}
        size="M"
        fixed={true}
      />
    );
  }

  /**
   * Сохраняет объект региона
   */
  private handleSelectRegion = (region: IRegionsItem) => {
    this.setState({ inputValue: region.displayName });
    this.setState({ currentRegion: region });
  };

  /**
   * Сохраняет значение поля ввода региона
   */
  private handleChangeInputValue = (value: string) => {
    this.setState({ inputValue: value });
  };

  /**
   * Устанавливает и запоминает в куке новый регион
   */
  private handleSubmitRegion = () => {
    if (!this.state.currentRegion) {
      this.props.dispatch(toggleRegions(false));

      return;
    }

    const { id, displayName, baseHost, mainTownId } = this.state.currentRegion;

    // Если регион не сменился ничего не делаем
    if (id === Number(this.props.config.regionID)) {
      this.props.dispatch(toggleRegions(false));

      return;
    }

    this.props.dispatch(updateRegion(String(id), displayName));
    this.props.dispatch(toggleRegions(false));

    const { location } = document;
    const path = location.pathname + location.search + location.hash;

    // Пишем в куку
    if (baseHost) {
      document.cookie = `content_is_russia=0;path=/;domain=.cian.ru;`;

      if (baseHost.includes('www')) {
        document.cookie = `session_region_id=${id || 1};path=/;domain=.cian.ru;`;
        document.cookie = `session_region_name=${encodeURIComponent(displayName)};path=/;domain=.cian.ru;`;
        document.cookie = `session_main_town_region_id=${id || mainTownId || 1};path=/;domain=.cian.ru;`;
      } else {
        document.cookie = `session_region_id=${id || 0};path=/;domain=.cian.ru;`;
        document.cookie = `session_region_name=${encodeURIComponent(displayName)};path=/;domain=.cian.ru;`;
        document.cookie = `session_main_town_region_id=${id || mainTownId || 0};path=/;domain=.cian.ru;`;
      }

      location.replace(`${baseHost}${path}`);
    } else {
      /* Отсутствие baseHost - признак Всей России */
      const host = location.host.replace(/^[^.]+\./g, 'www.');

      document.cookie = `content_is_russia=1;path=/;domain=.cian.ru;`;
      document.cookie = `session_region_id=${id};path=/;domain=.cian.ru;`;
      document.cookie = `session_region_name=${encodeURIComponent(displayName)};path=/;domain=.cian.ru;`;
      document.cookie = `session_main_town_region_id=${id};path=/;domain=.cian.ru;`;
      location.replace(`${location.protocol}//${host}${path}`);
    }
  };

  /**
   * Возвращает список регионов по части названия
   */
  private getSuggests = (): IRegionsItem[] => {
    const regex = new RegExp(this.state.inputValue, 'i');

    return this.regions.filter(region => regex.test(region.displayName));
  };

  /**
   * Возвращает дефолтный регион для всей России
   */
  private getFakeRegion = (): IRegionsItem => {
    return {
      displayName: 'Вся Россия',
      id: 0,
    };
  };

  /**
   * Возвращает список регионов для табов
   */
  private getTabsRegions = (): IRegionsItem[] => {
    const tabs: IRegionsItem[] = [this.getFakeRegion()];
    const moscow = this.regions.find(region => region.id === 1);
    const spb = this.regions.find(region => region.id === 2);

    // Если регион Москва есть, то меняем название
    if (moscow) {
      tabs.push({
        ...moscow,
        displayName: 'Москва и МО',
      });
    }

    // Если регион СПБ есть, то меняем название
    if (spb) {
      tabs.push({
        ...spb,
        displayName: 'Санкт-Петербург и ЛО',
      });
    }

    return tabs;
  };
}

export const Regions = connect((state: { config: IConfig; regions: IRegions }) => ({
  regions: state.regions,
  config: state.config,
}))(RegionsComponent);

import * as React from 'react';
import { Provider } from 'react-redux';
import { ErrorLogComponent } from '@cian/error-log-component';
import { UiKitContextProvider } from '@cian/ui-kit/context';
import { uaToDeviceType } from '@cian/ui-kit/utils';

import { IReduxStore } from '../../types/redux';
import { IApplicationContext } from '../../types/applicationContext';
import { ApplicationContext } from '../../utils';

require('@cian/honestwork-component/dist/frontend-component-honestwork.css');

import { LayoutNew, Layout } from '../Layout';

export interface IApplicationProps {
  context: IApplicationContext;
  reduxStore: IReduxStore;
}

export const App: React.FC<IApplicationProps> = ({ reduxStore, context }) => {
  const {
    logger,
    custom: { userAgent, canSeeNewDesign },
  } = context;

  return (
    <ErrorLogComponent logger={logger}>
      <ApplicationContext.Provider value={context}>
        <UiKitContextProvider deviceType={uaToDeviceType(userAgent || undefined)}>
          <Provider store={reduxStore}>{canSeeNewDesign ? <LayoutNew /> : <Layout />}</Provider>
        </UiKitContextProvider>
      </ApplicationContext.Provider>
    </ErrorLogComponent>
  );
};

import * as React from 'react';

interface IProps {
  show: boolean;
  containerStyle?: any;
  tooltipStyle?: any;
  content: string;
}

import s from './tooltip.css';

export class Tooltip extends React.Component<IProps> {
  public render() {
    return <div className={`${s['tooltip-container']} ${this.props.containerStyle ? this.props.containerStyle : ''}`}>
      { this.props.children }
      { this.props.show &&
          <div
              className={`${s[`tooltip`]}
                ${this.props.tooltipStyle ? this.props.tooltipStyle : ''}
              `}>
            {this.props.content}
          </div> }
    </div>;
  }
};

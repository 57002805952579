import { RefObject, useCallback, useEffect, useState } from 'react';

interface IUseCollapseOptions {
  isDefaultOpened?: boolean;
  transitionDurationMs?: number;
}

/**
 * Создает эффект коллапса путем переключения css-свойства max-height
 * ref-компонент по структуре должен иметь дочерний элемент
 * ------------------------------------------------------------------
 * Пример использования:
 * <div ref={ref}>
 *   <div>...</div>
 * </div>
 */
export function useCollapse<T extends HTMLElement | void = void>(
  parentRef: RefObject<T>,
  { isDefaultOpened = false, transitionDurationMs = 300 }: IUseCollapseOptions = {},
) {
  const [isOpened, setIsOpened] = useState(isDefaultOpened);
  const setStyles = useCallback(
    ({
      overflow = 'hidden',
      maxHeight = '10000px',
      transitionProperty = 'max-height',
      transitionTimingFunction = 'ease-in-out',
      transitionDuration = `${transitionDurationMs}ms`,
    }: Record<string, string>) => {
      if (!parentRef?.current) {
        return;
      }

      const style = parentRef.current.style;

      style.overflow = overflow;
      style.maxHeight = maxHeight;
      style.transitionProperty = transitionProperty;
      style.transitionTimingFunction = transitionTimingFunction;
      style.transitionDuration = transitionDuration;
    },
    [parentRef, transitionDurationMs],
  );

  useEffect(() => {
    if (!parentRef?.current) {
      return;
    }

    setIsOpened(isDefaultOpened);

    return () => {
      setStyles({
        overflow: '',
        maxHeight: '',
        transitionProperty: '',
        transitionTimingFunction: '',
        transitionDuration: '',
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!parentRef?.current) {
      return;
    }

    const contentWrapper = parentRef.current.childNodes[0] as HTMLElement;

    if (!contentWrapper) {
      return;
    }

    if (isOpened) {
      const { height } = contentWrapper.getBoundingClientRect();

      setStyles({ maxHeight: `${height}px` });
    } else {
      setStyles({ maxHeight: `0` });
    }
  }, [isOpened, parentRef, setStyles]);

  const toggle = (value?: boolean) => {
    setIsOpened(isOpened => (value !== undefined ? value : !isOpened));
  };

  return [toggle, isOpened] as const;
}

import { HttpBadStatusCodeError } from '@cian/peperrors/shared';

import { TThunkAction } from '../../../types/redux';
import { getOffersFromRecommendedApiLoading } from './getOffersFromRecommendedApiLoading';
import { getOffersFromRecommendedApiSucceed } from './getOffersFromRecommendedApiSucceed';
import { getOffersFromRecommendedApiFailed } from './getOffersFromRecommendedApiFailed';
import {
  fetchGetRecommendedOffers,
  TGetRecommendedOffersResponse,
} from '../../../repositories/search-engine/v3/get-recommended-offers';

export const getOffersFromRecommendedApi = (): TThunkAction<Promise<void>> => {
  return async (dispatch, getState, context) => {
    const { httpApi, logger } = context;

    dispatch(getOffersFromRecommendedApiLoading());

    try {
      const { response, statusCode }: TGetRecommendedOffersResponse = await fetchGetRecommendedOffers({
        httpApi,
        parameters: {
          cianOfferId: 0,
        },
      });

      if (statusCode !== 200 || !response?.offers) {
        await Promise.reject(
          new HttpBadStatusCodeError(
            'Не удалось загрузить рекомендованные объявления',
            statusCode,
            'actions.getOffersFromRecommendedApi',
          ),
        );

        return;
      }

      dispatch(getOffersFromRecommendedApiSucceed(response.offers));
    } catch (e) {
      logger.error(e, {
        domain: 'actions.getOffersFromRecommendedApi',
      });

      dispatch(getOffersFromRecommendedApiFailed());
    }
  };
};

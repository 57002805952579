import * as React from 'react';

import { SidebarDiscussionsContainer } from '../SidebarDiscussions';
import { SidebarNewsContainer } from '../SidebarNews';
import { SidebarSubscribeFormContainer } from '../SidebarSubscribeForm';

export const SidebarContainer = () => (
  <>
    <SidebarDiscussionsContainer />
    <SidebarNewsContainer />
    <SidebarSubscribeFormContainer />
  </>
);

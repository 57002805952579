import * as React from 'react';
import { ArticleParagraph1, UIHeading2, UIHeading3, Button, useDeviceType } from '@cian/ui-kit';

import { Image } from './components/Image';
import * as s from './FindRealtorBanner.css';

export interface IRealtorSendRequestParams {
  onClick?(e: React.MouseEvent): void;
}

/**
 * Баннер "Подобрать риелтора"
 */
export const FindRealtorBanner = ({ onClick }: IRealtorSendRequestParams) => {
  const deviceType = useDeviceType();
  const isDesktop = () => deviceType === 'desktop';
  const isPhone = () => deviceType === 'phone';
  const HeadingComponent = isDesktop() ? UIHeading2 : UIHeading3;

  return (
    <div className={s['wrapper']} onClick={onClick}>
      <div className={s['content-wrapper']}>
        <HeadingComponent>Риелтор поможет с продажей или покупкой объекта</HeadingComponent>

        {!isPhone() && (
          <ArticleParagraph1>
            Проведет сделку, решит юридические вопросы, поможет подобрать ипотечную программу
          </ArticleParagraph1>
        )}

        {isDesktop() && (
          <Button size={'M'} theme={'fill_white_primary'}>
            Отправить заявку
          </Button>
        )}
      </div>

      {isDesktop() ? (
        <Image />
      ) : (
        <div className={s['button-wrapper']}>
          <Button size={'M'} theme={'fill_white_primary'} fullWidth>
            Отправить заявку
          </Button>
        </div>
      )}
    </div>
  );
};

/* eslint-disable */
//tslint:disable

export interface IUndergroundForRecommendationSchema {
  /** Цвет ветки **/
  color: string;
  /** Тип иконки метро **/
  iconType?: string | null;
  /** Название **/
  name: string;
  /** Время до **/
  time?: string | null;
  /** Способ передвижения до станции **/
  transportType?: ETransportType | null;
}

export enum ETransportType {
  /** На транспорте **/
  Transport = 'transport',
  /** Пешком **/
  Walk = 'walk',
}

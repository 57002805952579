import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, useDeviceType } from '@cian/ui-kit';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { buildPostUrl, prepareListingOrdering, preparePageNumber, useGetListing, useTimeoutEffect } from '../../utils';
import { getNewsList, resetNewsList } from '../../actions/newsList';
import { NEWS_LIST_REQUEST_LIMIT } from '../../constants';
import { IApplicationState, TThunkDispatch } from '../../types/redux';
import { selectListingAppendType } from '../../selectors/settings/selectListingAppendType';
import { selectCurrentPageMeta, selectPageMetaItems } from '../../selectors/pagesMeta';
import { ListingHeaderContainer } from '../ListingHeader';
import {
  CardListingItem,
  ListingItem,
  SkeletonCardListingItem,
  SkeletonListingItem,
} from '../../components/ListingItem';
import { NothingFound } from '../../components/NotFound';
import { ERequestStatus } from '../../types/requestStatus';
import { EType as EPostType } from '../../repositories/journal/entities/journal/JournalListAttributesSchema';
import { PaginationContainer } from '../Pagination';
import { selectNewsList } from '../../selectors/newsList';
import { EListingTypes } from '../../types/listing';
import { selectFavoriteListingType } from '../../selectors/settings/selectFavoriteListingType';
import { EType } from '../../repositories/journal/entities/page_info/PageInfoSchema';
import { BreadCrumbsContainer } from '../BreadCrumbs';
import { unescapeHtml } from '../../../app/helpers/make_html_helper';
import { prepareSubtitle } from './helpers';
import * as s from './Listings.css';

/**
 * Листинг новостей
 */
export const NewsList = () => {
  const dispatch = useDispatch<TThunkDispatch>();
  const history = useHistory();
  const { search, pathname } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const qsPage = preparePageNumber(searchParams.get('page') as string);
  const qsOrdering = prepareListingOrdering(searchParams.get('ordering') || '') || '';
  const appendType = useSelector(selectListingAppendType);
  const favoriteListingType = useSelector(selectFavoriteListingType);
  const {
    type: pageType,
    pathname: currentPageTypePathname,
    category,
  } = useSelector((state: IApplicationState) => selectCurrentPageMeta(state, pathname));
  const pagesMeta = useSelector(selectPageMetaItems);
  const deviceType = useDeviceType();
  const [IsShownAsList, setIsShownAsList] = useState(false);
  const isPhone = () => deviceType === 'phone';
  const { items: news, status: newsStatus } = useSelector(selectNewsList);
  const [isSkeletonShown, setIsSkeletonShown] = useState(newsStatus === ERequestStatus.Loading && appendType === 'set');

  /** Если не нашел нужный тип, выкидывает на дефолтный */
  useEffect(() => {
    if (EType.News !== pageType) {
      history.replace(currentPageTypePathname);
    }
  }, [currentPageTypePathname, history, pageType]);

  /** Запрашивает новости/сохраняет в стор */
  useGetListing(
    () => {
      dispatch(
        getNewsList({
          tag: undefined,
          offset: (qsPage - 1) * NEWS_LIST_REQUEST_LIMIT,
          limit: NEWS_LIST_REQUEST_LIMIT,
          setType: appendType,
          ordering: qsOrdering || undefined,
          category: category || undefined,
        }),
      ).finally();
    },
    { dependencyNames: ['page', 'ordering', 'category', 'deviceType'] },
  );

  /** При выходе сбрасывает новости */
  useEffect(() => {
    return () => {
      dispatch(resetNewsList());
    };
  }, [dispatch]);

  /** Устанавливает вид листинга: карточки или список */
  useEffect(() => {
    setIsShownAsList(favoriteListingType === EListingTypes.List && deviceType !== 'phone');
  }, [favoriteListingType, deviceType]);

  /** Создает небольшую задержку перед скрытием скелетона, когда элементов не найдено */
  useTimeoutEffect(() => {
    setIsSkeletonShown(newsStatus === ERequestStatus.Loading && appendType === 'set');
  }, [!isSkeletonShown || news.length ? 0 : 500]);

  /**
   * При клике по плашке новости роутит на соответствующую страницу новости
   * При клике по шильдику категории в плашке новости роутит на соответствующую категорию
   */
  const handleClick = useCallback(
    (e, url: string) => {
      e.preventDefault();
      history.push(url);
    },
    [history],
  );

  return (
    <>
      <ListingHeaderContainer />

      <div className={IsShownAsList ? s['list-wrapper'] : s['cards-wrapper']}>
        {isSkeletonShown &&
          [...Array(6)].map((_, index) =>
            IsShownAsList ? (
              <div key={index} className={s['list-item-wrapper']}>
                <SkeletonListingItem />
              </div>
            ) : (
              <Col key={index} xs={12} m={6} l={4}>
                <SkeletonCardListingItem />
              </Col>
            ),
          )}

        {((newsStatus === ERequestStatus.Succeed && appendType === 'set') || appendType === 'append') &&
          news.map(
            (
              {
                id,
                attributes: {
                  image,
                  title,
                  subtitle,
                  datePublish,
                  commentsCount,
                  category,
                  slug,
                  noIndex,
                  type,
                  disableComments,
                  likesCount,
                },
              },
              index,
            ) => {
              const pageMeta = pagesMeta.find(
                pageMeta => pageMeta.type === EType.News && pageMeta.category === category,
              );

              return IsShownAsList ? (
                <div key={index} className={s['list-item-wrapper']}>
                  <ListingItem
                    title={unescapeHtml(title)}
                    subtitle={prepareSubtitle(subtitle, type)}
                    image={image}
                    rubric={pageMeta ? { name: pageMeta.title, url: pageMeta.pathname } : undefined}
                    datePublish={datePublish}
                    numComments={disableComments ? undefined : commentsCount}
                    numLikes={likesCount}
                    noIndex={Boolean(noIndex)}
                    type={type}
                    url={buildPostUrl({ type: EPostType.News, slug, id })}
                    onRubricButtonClick={handleClick}
                    onClick={e => handleClick(e, buildPostUrl({ type: EPostType.News, slug, id }))}
                  />
                </div>
              ) : (
                <Col key={index} xs={12} m={6} l={4}>
                  <CardListingItem
                    title={unescapeHtml(title)}
                    subtitle={prepareSubtitle(subtitle, type, Boolean(image))}
                    height={isPhone() ? undefined : 400}
                    image={image}
                    rubric={pageMeta ? { name: pageMeta?.title, url: pageMeta?.pathname } : undefined}
                    datePublish={datePublish}
                    numComments={disableComments ? undefined : commentsCount}
                    numLikes={likesCount}
                    noIndex={Boolean(noIndex)}
                    url={buildPostUrl({ type: EPostType.News, slug, id })}
                    type={type}
                    onRubricButtonClick={handleClick}
                    onClick={e => handleClick(e, buildPostUrl({ type: EPostType.News, slug, id }))}
                  />
                </Col>
              );
            },
          )}

        {[ERequestStatus.Succeed, ERequestStatus.Failed].includes(newsStatus) && !news.length && (
          <NothingFound pathname={currentPageTypePathname} showResetFiltersLink={qsPage > 1} />
        )}
      </div>

      {newsStatus === ERequestStatus.Succeed && (
        <div className={s['controls-wrapper']}>
          <PaginationContainer />
        </div>
      )}

      {/* Хлебные крошки для мобилки */}
      {isPhone() && (
        <div className={s['bread-crumbs-wrapper']}>
          <BreadCrumbsContainer />
        </div>
      )}
    </>
  );
};

import { getConfig } from '@cian/config/browser';
import { getHttpApi } from '@cian/http-api/browser';
import { getLogger } from '@cian/logger/browser';
import { getTelemetry } from '@cian/telemetry/browser';

import { IApplicationContext, IApplicationContextCustom } from '../../../shared/types/applicationContext';
import { createMakeRequest } from '../../client';
import { GET_USER_GA_DATA_LAYER_DEFAULT } from '../../../app/helpers/request_helper';

export function createContext(): IApplicationContext {
  const config = getConfig();
  const httpApi = getHttpApi();

  return {
    makeRequest: createMakeRequest(
      httpApi,
      (window as any).__content_api_schema__,
      (window as any).__content_api_host__,
      (window as any).__content_api_path__,
    ),
    getUserGaDataLayer: (window as any).__get_user_ga_data_layer_timeout__ || GET_USER_GA_DATA_LAYER_DEFAULT,
    config,
    logger: getLogger(),
    httpApi,
    telemetry: getTelemetry(),
    custom: config.getStrict<IApplicationContextCustom>('applicationContext.custom'),
  };
}

import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useDeviceType } from '@cian/ui-kit';
import { Search16 as IconSearch16, Locations16 as IconLocation16 } from '@cian/ui-kit/icons';

import { selectAllGroups, selectActiveItems } from './utils';
import {
  MainMenuGroupDesktop,
  MainMenuSimpleGroupMobile,
  MainMenuGroupMobile,
  IMenuGroup,
  TActiveSlugs,
} from '../../components/MainMenuGroup';
import { IconBook16 } from '../../components/Icons';
import { ReactPortal } from '../../components/ReactPortal';
import { useIsMounted, useTimeoutEffect, useWindowSize, ApplicationContext } from '../../utils';
import { B2BModeSwitcherContainer } from '../B2BModeSwitcher';
import { TDispatch } from '../../types/redux';
import { toggleMobileMenu } from '../../actions/menu';
import { isMobileMenuOpenedSelect } from '../../selectors/menu';
import { toggleRegions } from '../../reducers/modules/config/config';
import { selectCurrentRegion } from '../../selectors/geo/currentRegion';
import { toggleSearchingModal } from '../../actions/settings';
import * as s from './MainMenuContainer.css';

export const MainMenuContainer = () => {
  const dispatch = useDispatch<TDispatch>();
  const location = useLocation();
  const isMobileMenuOpened = useSelector(isMobileMenuOpenedSelect);
  const groups = useSelector(selectAllGroups);
  const activeItems = useSelector(selectActiveItems(location.pathname));
  const currentRegion = useSelector(selectCurrentRegion);
  const [activeItemSlugs, setActiveItemSlugs] = useState<TActiveSlugs>({
    itemSlug: activeItems.item?.slug || '',
    subItemSlug: activeItems.subItem?.slug || '',
  });
  const [isBackdropShown, setIsBackdropShown] = useState(false);
  const deviceType = useDeviceType();
  const isDesktop = () => deviceType === 'desktop';
  const backdropRef = useRef<HTMLDivElement>(null);
  const mobileMenuInnerWrapperRef = useRef<HTMLDivElement>(null);
  const mobileMenuOpenedTimerRef = useRef<ReturnType<typeof setTimeout>>();
  const handleMobileMenuToggleTimerRef = useRef<ReturnType<typeof setTimeout>>();
  const switchRef = useRef<HTMLDivElement>(null);
  const isMounted = useIsMounted();
  const context = useContext(ApplicationContext);
  const { height: windowHeight } = useWindowSize();

  /** Доп. набор кастомных пунктов меню для мобилки */
  const additionalGroup: IMenuGroup = {
    items: [
      {
        slug: 'b2b-mode-switcher',
        label: 'Циан.Журнал Pro',
        iconAfter: (
          <span ref={switchRef} onClick={e => e.stopPropagation()}>
            <B2BModeSwitcherContainer />
          </span>
        ),
      },
      {
        slug: 'search',
        label: 'Поиск по журналу',
        iconAfter: <IconSearch16 />,
      },
      {
        slug: 'region',
        label: currentRegion.name,
        iconAfter: <IconLocation16 />,
      },
    ],
  };

  /** Слушает изменение урла и устанавливает активные пункты */
  useEffect(() => {
    const itemSlug = activeItems.item?.slug || '';
    const subItemSlug = activeItems.subItem?.slug || '';

    if (itemSlug === activeItemSlugs.itemSlug && subItemSlug === activeItemSlugs.subItemSlug) {
      return;
    }
    setActiveItemSlugs({ itemSlug, subItemSlug });
  }, [activeItemSlugs, activeItems]);

  /** Плавно анимирует backdrop для мобильного меню */
  useTimeoutEffect(() => {
    if (backdropRef?.current) {
      backdropRef.current.classList.toggle(s['_shown'], isMobileMenuOpened);
    }
  }, [50, ['phone', 'tablet']]);

  /** Плавно переключает backdrop для мобильного меню */
  useTimeoutEffect(() => {
    setIsBackdropShown(isMobileMenuOpened);
  }, [isMobileMenuOpened ? 0 : 300, ['phone', 'tablet']]);

  /**
   * Вычисляет и устанавливает высоту мобильного меню для включения скрола,
   * когда высота меню больше, чем высота клиента браузера
   */
  useEffect(() => {
    if (!isBackdropShown || !mobileMenuInnerWrapperRef?.current) {
      return;
    }

    const headerRoot = document.getElementById('header-frontend');
    const mobileMenuInnerWrapperRoot = mobileMenuInnerWrapperRef?.current as HTMLElement;
    const mobileMenuRoot = mobileMenuInnerWrapperRef?.current?.parentElement?.parentElement
      ?.parentElement as HTMLElement;

    if (headerRoot && mobileMenuInnerWrapperRoot && mobileMenuRoot && windowHeight) {
      mobileMenuInnerWrapperRoot.style.maxHeight = `calc(${windowHeight}px - ${
        headerRoot.getBoundingClientRect().height
      }px - ${mobileMenuRoot.getBoundingClientRect().height}px)`;
    }
  }, [windowHeight, isBackdropShown]);

  /** Блокирует скрол у body пока мобильное меню раскрыто */
  useEffect(() => {
    // Блокируем/разблокируем скрол у body
    const bodyRoot = document.getElementsByTagName('body')[0];
    bodyRoot.style.overflowY = isMobileMenuOpened ? 'hidden' : '';

    return function cleanup() {
      bodyRoot.style.overflowY = '';
    };
  }, [isMobileMenuOpened]);

  /** Обрабатывает событие открытия/закрытия мобильного меню */
  const handleMobileMenuToggle = useCallback(
    (value: boolean | undefined = undefined) => {
      if (handleMobileMenuToggleTimerRef.current) {
        return;
      }

      // эффект debounce (не даем перегружать)
      handleMobileMenuToggleTimerRef.current = setTimeout(() => {
        clearTimeout(handleMobileMenuToggleTimerRef.current);
        handleMobileMenuToggleTimerRef.current = undefined;
      }, 300);

      dispatch(toggleMobileMenu(value !== undefined ? value : !isMobileMenuOpened));
    },
    [dispatch, isMobileMenuOpened, handleMobileMenuToggleTimerRef],
  );

  /** Схлопывает меню при изменении вьюпорта */
  useEffect(() => {
    handleMobileMenuToggle(false);
  }, [deviceType, handleMobileMenuToggle]);

  /** Обрабатывает событие клика по кастомным пунктам меню */
  const handleClickCustom = useCallback(
    ({ itemSlug }) => {
      if (itemSlug === 'b2b-mode-switcher') {
        // имитация клика по переключателю
        if (switchRef?.current) {
          switchRef.current.querySelector('input')?.click();
        }

        return;
      }

      clearTimeout(mobileMenuOpenedTimerRef.current);

      // Немного выжидаем анимацию и закрываем
      mobileMenuOpenedTimerRef.current = setTimeout(() => {
        if (!isMounted()) {
          return;
        }

        handleMobileMenuToggle(false);

        if (itemSlug === 'search') {
          setTimeout(() => {
            dispatch(toggleSearchingModal(true));
          }, 300);
        }

        if (itemSlug === 'region') {
          setTimeout(() => {
            dispatch(toggleRegions(true));
          }, 300);
        }
      }, 200);
    },
    [dispatch, handleMobileMenuToggle, isMounted],
  );

  /** Обрабатывает событие клика по пунктам меню */
  const handleClick = useCallback(
    (activeSlugs: TActiveSlugs) => {
      /**
       * Исключение для пункта меню Главная:
       * не подсвечиваем пункт, как активный
       */
      if (activeSlugs.itemSlug === 'main-page') {
        setActiveItemSlugs({ itemSlug: 'main-page', subItemSlug: '' });
      } else {
        setActiveItemSlugs(activeSlugs);
      }

      // Схлопываем после клика по пункту со ссылкой
      handleMobileMenuToggle(false);
    },
    [handleMobileMenuToggle],
  );

  return (
    <div className={s['wrapper']} data-web-ui-test-id="MainMenuContainer">
      {isDesktop() ? (
        <>
          <MainMenuGroupDesktop
            items={[
              {
                slug: 'main-page',
                label: 'Главная',
                url: '/magazine/',
                iconBefore: <IconBook16 />,
              },
            ]}
            activeItemSlugs={activeItemSlugs}
            onClick={handleClick}
          />

          <div className={`${s['b2b-mode-switcher-wrapper']}`}>
            <B2BModeSwitcherContainer />
          </div>

          {groups.map((group, index) => (
            <MainMenuGroupDesktop
              key={index}
              items={group.items}
              activeItemSlugs={activeItemSlugs}
              onClick={handleClick}
            />
          ))}
        </>
      ) : (
        <>
          <MainMenuSimpleGroupMobile
            isOpened={isMobileMenuOpened}
            isAbsolute={true}
            label={'Меню'}
            controlsType={'chevrons'}
            onClick={() => handleMobileMenuToggle()}
          >
            <div ref={mobileMenuInnerWrapperRef} className={s['mobile-menu-inner-wrapper']}>
              {groups.map((group, index) => {
                return (
                  <MainMenuGroupMobile
                    key={index}
                    items={
                      index === 0
                        ? [{ slug: 'main-page', label: 'Главная', url: '/magazine/' }, ...group.items]
                        : group.items
                    }
                    activeItemSlugs={activeItemSlugs}
                    onClick={handleClick}
                  />
                );
              })}

              <MainMenuGroupMobile
                items={additionalGroup.items}
                activeItemSlugs={activeItemSlugs}
                onClick={handleClickCustom}
              />
            </div>
          </MainMenuSimpleGroupMobile>

          {/* Задний фон под меню, блокирующий контент */}
          <ReactPortal wrapperId={'mobile-menu-backdrop'} parent={context.config.getStrict<string>('projectName')}>
            <div ref={backdropRef} className={s['backdrop-wrapper']}>
              {isBackdropShown && <div className={s['backdrop']} onClick={() => handleMobileMenuToggle(false)} />}
            </div>
          </ReactPortal>
        </>
      )}
    </div>
  );
};

import { useState } from "react";
import * as React from 'react';
import { openDemandForm } from './utils/openDemandForm';
import { DemandAdvantages } from '../../components/realtor_demand/demand_advantages';
import { connect } from 'react-redux';
import { Button, ModalWindow, TDeviceType, useDeviceType } from "@cian/ui-kit";
import { ElementInViewPort } from '../../components/element_in_viewport';
import { trackBannerClicked, trackBannerViewed, trackOpenFormClicked } from './tracking';

export interface IRenderButtonProps {
  onClick(): void;
}

export interface IRequestDemandButtonComponentProps {
  deviceType?: TDeviceType,
  renderButton: (props: IRenderButtonProps) => React.ReactElement<any>;
  bannerLocation: string;

  onCloseDialog?(): void;
}

const RequestDemandButtonComponent: React.FC<IRequestDemandButtonComponentProps> = props => {
  let viewportTracked = false;
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const deviceType = useDeviceType();

  const closeDialog = () => {
    setIsDialogOpen(false);
    props.onCloseDialog ? props.onCloseDialog() : null;
  }

  const openDialog = () => {
    setIsDialogOpen(true);
    trackBannerClicked(props.bannerLocation, deviceType);
  }

  const handleSubmit = () => {
    openDemandForm(props.bannerLocation);
    trackOpenFormClicked(props.bannerLocation, deviceType);
    closeDialog();
  }

  const handleInViewPort = (isVisible: boolean) => {
    if (isVisible && !viewportTracked) {
      trackBannerViewed(props.bannerLocation, deviceType);
      viewportTracked = true;
    }
  }

  const { renderButton } = props;

  return (
    <ElementInViewPort onChange={handleInViewPort}>
      <div>
        { renderButton({ onClick: openDialog }) }
        <ModalWindow
          title="Риелтор поможет продать или купить сложные объекты"
          onClose={closeDialog}
          fixed={false}
          size={deviceType === 'phone' ? "M" : "XS"}
          open={isDialogOpen}
          footer={
            <Button
              theme="fill_primary"
              size={deviceType === 'phone' ? "M" : "XS"}
              fullWidth={true}
              onClick={handleSubmit}
            >Отправить заявку риелтору
            </Button>
          }
          maxWidth="400px"
        >
          <DemandAdvantages/>
        </ModalWindow>
      </div>
    </ElementInViewPort>
  )
}

export interface IRequestDemandButtonProps {
  deviceType?: TDeviceType,
}
export const RequestDemandButton = connect<IRequestDemandButtonProps, {}, IRequestDemandButtonComponentProps>((
  state: {
    deviceType: TDeviceType,
  }) => ({
    deviceType: state.deviceType,
  }),
)(RequestDemandButtonComponent);

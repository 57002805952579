import { HttpBadStatusCodeError } from '@cian/peperrors/shared';

import { TThunkAction } from '../../../types/redux';
import { getNewsListLoading } from './getNewsListLoading';
import { getNewsListSucceed } from './getNewsListSucceed';
import { getNewsListAppendSucceed } from './getNewsListAppendSucceed';
import { getNewsListFailed } from './getNewsListFailed';
import {
  fetchGetNewsList,
  TGetNewsListResponse,
  IGetNewsListRequest,
} from '../../../repositories/journal/v2/get-news-list';

export interface IGetNewsListParams extends Omit<IGetNewsListRequest, 'b2b' | 'regionId'> {
  setType?: 'set' | 'append';
}

export const getNewsList = ({ setType = 'set', ...otherProps }: IGetNewsListParams): TThunkAction<Promise<void>> => {
  return async (dispatch, getState, context) => {
    const { httpApi, logger } = context;
    const {
      userNew: { isB2BModeEnabled },
      geo: { currentRegion },
    } = getState();

    dispatch(getNewsListLoading());

    try {
      const { response, statusCode }: TGetNewsListResponse = await fetchGetNewsList({
        httpApi,
        parameters: {
          regionId: currentRegion.id,
          b2b: isB2BModeEnabled,
          ...otherProps,
        },
      });

      if (statusCode !== 200) {
        await Promise.reject(
          new HttpBadStatusCodeError('Не удалось загрузить список новостей', statusCode, 'actions.getNewsList'),
        );

        return;
      }

      if (setType === 'append') {
        dispatch(getNewsListAppendSucceed({ meta: response.meta, items: response.data }));
      } else {
        dispatch(getNewsListSucceed({ meta: response.meta, items: response.data }));
      }
    } catch (e) {
      logger.error(e, {
        domain: 'actions.getNewsList',
      });

      dispatch(getNewsListFailed());
    }
  };
};

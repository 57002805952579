/**
 * Создает HTML-контейнер в body или root-контейнере
 */
export function createWrapperAndAppendToDOM(wrapperId: string, parent?: string) {
  const wrapperElement = document.createElement('div');
  wrapperElement.setAttribute('id', wrapperId);

  if (parent && parent !== 'body') {
    const parentRoot = document.getElementById(parent);

    if (parentRoot) {
      parentRoot.appendChild(wrapperElement);

      return wrapperElement;
    }
  }

  document.body.appendChild(wrapperElement);

  return wrapperElement;
}

import * as React from 'react';
import { Link } from 'react-router-dom';

import { IUser } from '../../types/user';
import { ISeoUrl } from '../../types/seo_urls';

import s from './seo_urls.css';

interface IProps {
  title: string;
  user: IUser;
  showMobile?: boolean;
  showDesktop?: boolean;
  items: ISeoUrl[];
}

export const SeoUrls = (props: IProps) =>
  <div
      className={`cg-col-24
        ${s['seo-urls']}
        ${props.showMobile && s['seo-urls-mobile']}
        ${props.showDesktop && s['seo-urls-dekstop']}`
      }>
    <div className="cg-row">
      <div className={`cg-col-16 cg-col-xs-24 ${s['seo-urls__content']}`}>
        <div className={`${s['seo-urls__header']}`}>
          <h2 className={s['seo-urls__header_text']}>{props.title}</h2>
        </div>
        <ul className={s['seo-urls__list']}>
          {props.items.map((item, index) => <li key={`seo_url-${index}`}>
            <Link to={item.url} className={s['seo-urls__item']}>{item.title}</Link>
          </li>)}
        </ul>
      </div>
    </div>
  </div>;

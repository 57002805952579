import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { getObserver } from './utils';

interface IElementInViewportProps {
  children: JSX.Element;

  onChange(isVisible: boolean): void;
}

interface IElementInViewportState {
  isVisible: boolean;
}

export class ElementInViewPort extends React.Component<IElementInViewportProps, IElementInViewportState> {
  private target: HTMLElement;
  private detachObserver = () => {};

  public state: IElementInViewportState = {
    isVisible: false,
  };

  private handleVisibilityChange = (isVisible: boolean) => {
    if (isVisible !== this.state.isVisible) {
      this.setState({
        isVisible,
      });
    }
  };

  public componentDidMount() {
    this.target = ReactDOM.findDOMNode(this) as HTMLElement;
    const observer = getObserver();

    this.detachObserver = observer({
      target: this.target,
      callback: this.handleVisibilityChange,
    });
  }

  public componentWillUnmount() {
    this.detachObserver();
  }

  public componentDidUpdate(prevProps: IElementInViewportProps, prevState: IElementInViewportState) {
    if (this.state.isVisible !== prevState.isVisible) {
      this.props.onChange(this.state.isVisible);
    }
  }

  public render() {
    return this.props.children;
  }
}

/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TGetArticlesAuthorModel,
  IGetArticlesAuthorRequest,
  IMappers,
  IGetArticlesAuthorResponse,
  IGetArticlesAuthorResponseError,
  TGetArticlesAuthorResponse,
} from './types';

const defaultConfig: TGetArticlesAuthorModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'GET',
  microserviceName: 'journal',
  pathApi: '/v1/get-articles-author/',
  hostType: 'api',
} as TGetArticlesAuthorModel;

function createGetArticlesAuthorModel(parameters: IGetArticlesAuthorRequest): TGetArticlesAuthorModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetArticlesAuthorOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IGetArticlesAuthorRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchGetArticlesAuthor<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IGetArticlesAuthorOptions<TResponse200, TResponse400>): Promise<
  TResponse200 | TResponse400 | TGetArticlesAuthorResponse
> {
  const { response, statusCode } = await httpApi.fetch(createGetArticlesAuthorModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetArticlesAuthorResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IGetArticlesAuthorResponseError);
    }
  }

  return { response, statusCode } as TGetArticlesAuthorResponse;
}

export { defaultConfig, createGetArticlesAuthorModel, fetchGetArticlesAuthor };

import { setCookie, deleteCookie } from '@cian/utils/lib/browser/cookie';

import { TThunkAction } from '../../../types/redux';
import { setIsB2BModeEnabled } from '../setIsB2BModeEnabled';
import { setIsB2BForbiddingMessageShown } from '../setIsB2BForbiddingMessageShown';
import { toggleMobileMenu } from '../../menu';

/**
 * Пытается переключить режим b2b
 */
export const tryToToggleIsB2BModeEnabled = (): TThunkAction<Promise<void>> => {
  return async (dispatch, getState) => {
    const {
      userNew: { isAuthenticated, lkType, isB2BModeEnabled },
    } = getState();

    // Если не авторизован, то сбрасываем на выключенное состояние
    if (!isAuthenticated) {
      dispatch(setIsB2BModeEnabled(false));

      return;
    }

    /*
     * Если нет типа ЛК или тип - не собственник/не агент, то
     * сбрасываем на выключенное состояние,
     * выводим запрещающее сообщение и удаляем куку (если есть)
     */
    if (!lkType || !['homeowner', 'specialist'].includes(lkType)) {
      // Запрещающее сообщение только для переключения C -> B
      dispatch(setIsB2BForbiddingMessageShown(!isB2BModeEnabled));
      dispatch(setIsB2BModeEnabled(false));
      deleteCookie('isB2BModeEnabled');

      return;
    }

    // Для агента и собственника меняем значение
    dispatch(setIsB2BModeEnabled(!isB2BModeEnabled));
    // Закрываем меню с задержкой, чтобы дождаться смены положения переключателя
    setTimeout(() => dispatch(toggleMobileMenu(false)), 200);

    if (typeof window !== 'undefined') {
      const now = new Date();

      // Для специалиста (агента) храним куку 1 месяц, для собственника - 3 месяца
      /* istanbul ignore next */
      setCookie('isB2BModeEnabled', String(!isB2BModeEnabled), {
        domain: process.env.NODE_ENV === 'development' ? '' : '.cian.ru',
        expires:
          lkType === 'specialist'
            ? new Date(now.setMonth(now.getMonth() + 1))
            : new Date(now.setMonth(now.getMonth() + 3)),
      });

      // Релоадим с задержкой, чтобы дождаться плавного закрытия меню в мобилке
      setTimeout(() => window.location.replace('/magazine/'), 300);
    }
  };
};

import { HttpBadStatusCodeError } from '@cian/peperrors/shared';

import { TThunkAction } from '../../../types/redux';
import { getMainNewsLoading } from './getMainNewsLoading';
import { getMainNewsSucceed } from './getMainNewsSucceed';
import { getMainNewsFailed } from './getMainNewsFailed';
import { fetchGetNewsMain, TGetNewsMainResponse } from '../../../repositories/journal/v1/get-news-main';

export interface IGetMainNewsParams {
  limit?: number;
  tag?: string;
}

export const getMainNews = ({ limit, tag }: IGetMainNewsParams): TThunkAction<Promise<void>> => {
  return async (dispatch, getState, context) => {
    const { httpApi, logger } = context;
    const {
      userNew: { isB2BModeEnabled },
      geo: { currentRegion },
    } = getState();

    dispatch(getMainNewsLoading());

    try {
      const { response, statusCode }: TGetNewsMainResponse = await fetchGetNewsMain({
        httpApi,
        parameters: {
          tag,
          regionId: currentRegion.id,
          b2b: isB2BModeEnabled,
          limit,
        },
      });

      if (statusCode !== 200 || !response?.data) {
        await Promise.reject(
          new HttpBadStatusCodeError('Не удалось загрузить новости для Главной', statusCode, 'actions.getMainNews'),
        );

        return;
      }

      dispatch(getMainNewsSucceed(response.data));
    } catch (e) {
      logger.error(e, {
        domain: 'actions.getMainNews',
      });

      dispatch(getMainNewsFailed());
    }
  };
};

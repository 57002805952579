import { ITypedReduxAction } from '../../../types/redux/actionType';
import { IMenuBlockItemsSchema } from '../../../repositories/journal/entities/menu/MenuBlockItemsSchema';

export enum EGetMenuActionType {
  Loading = 'menu/getMenu/loading',
  Succeed = 'menu/getMenu/succeed',
  Failed = 'menu/getMenu/failed',
}

export type TGetMenuLoading = ITypedReduxAction<EGetMenuActionType.Loading>;
export type TGetMenuSucceed = ITypedReduxAction<EGetMenuActionType.Succeed, IMenuBlockItemsSchema[]>;
export type TGetMenuFailed = ITypedReduxAction<EGetMenuActionType.Failed>;

export type TGetMenu = TGetMenuLoading | TGetMenuSucceed | TGetMenuFailed;

import { HttpBadStatusCodeError } from '@cian/peperrors/shared';

import { TThunkAction } from '../../../types/redux';
import { getQuestionListLoading } from './getQuestionListLoading';
import { getQuestionListSucceed } from './getQuestionListSucceed';
import { getQuestionListAppendSucceed } from './getQuestionListAppendSucceed';
import { getQuestionListFailed } from './getQuestionListFailed';
import {
  fetchGetQuestionList,
  TGetQuestionListResponse,
  IGetQuestionListRequest,
} from '../../../repositories/journal/v2/get-question-list';

export interface IGetQuestionListParams extends Omit<IGetQuestionListRequest, 'b2b' | 'regionId'> {
  setType?: 'set' | 'append';
}

export const getQuestionList = ({
  setType = 'set',
  ...otherProps
}: IGetQuestionListParams): TThunkAction<Promise<void>> => {
  return async (dispatch, getState, context) => {
    const { httpApi, logger } = context;
    const {
      userNew: { isB2BModeEnabled },
      geo: { currentRegion },
    } = getState();

    dispatch(getQuestionListLoading());

    try {
      const { response, statusCode }: TGetQuestionListResponse = await fetchGetQuestionList({
        httpApi,
        parameters: {
          regionId: currentRegion.id,
          b2b: isB2BModeEnabled,
          ...otherProps,
        },
      });

      if (statusCode !== 200) {
        await Promise.reject(
          new HttpBadStatusCodeError('Не удалось загрузить список вопросов', statusCode, 'actions.getQuestionList'),
        );

        return;
      }

      if (setType === 'append') {
        dispatch(getQuestionListAppendSucceed({ meta: response.meta, items: response.data }));
      } else {
        dispatch(getQuestionListSucceed({ meta: response.meta, items: response.data }));
      }
    } catch (e) {
      logger.error(e, {
        domain: 'actions.getQuestionList',
      });

      dispatch(getQuestionListFailed());
    }
  };
};

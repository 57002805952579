import React from 'react';
import { Link } from 'react-router-dom';
import { UIHeading4, UIText2 } from '@cian/ui-kit';

import { formatDate } from '../../utils';
import * as s from './QuickSearchingItem.css';

interface IQuickSearchingItemParams {
  name: string;
  date?: string;
  url: string;
  rubrics?: string[];
  onClick?(): void;
}

/** Элемент списка быстрого поиска */
export const QuickSearchingItem = ({ name, date, url, rubrics, onClick }: IQuickSearchingItemParams) => {
  return (
    <Link to={url} className={s['wrapper']} onClick={onClick}>
      <div className={s['inner-wrapper']}>
        {rubrics?.length && (
          <div className={s['rubrics']}>
            {rubrics.map((name, index) => (
              <div key={`rubric-${index}`} className={s['rubric']}>
                {name}
              </div>
            ))}
          </div>
        )}

        <div className={s['title']}>
          <UIHeading4>{name}</UIHeading4>
        </div>

        {date && (
          <div className={s['description']}>
            <UIText2>{formatDate(new Date(date))}</UIText2>
          </div>
        )}
      </div>
    </Link>
  );
};

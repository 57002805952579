import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  RoundButton as RoundedButton,
  ArrowL12 as IconChevronLeft12,
  ArrowR12 as IconChevronRight12,
} from '@cian/ui-kit';

import { debounce, useIsMounted } from '../../utils';
import { IScrollingParams, Scrolling } from './Scrolling';
import { useIsShown } from './utils';
import * as s from './ScrollingWithControls.css';

export interface IScrollingWithControls extends IScrollingParams {
  scrollLength?: number;
  controlsType?: 'relative' | 'absolute';
  controlsSize?: 'XS' | 'M';
}

/** Панель прокрутки тэгов с контролами */
export const ScrollingWithControls = ({
  scrollLength = 200,
  controlsType = 'relative',
  controlsSize = 'XS',
  children,
}: IScrollingWithControls) => {
  const tagsWrapperRef = useRef<HTMLDivElement>(null);
  const [canScrollPrev, setCanScrollPrev] = useState(false);
  const [canScrollNext, setCanScrollNext] = useState(false);
  const [canSeeControls, setCanSeeControls] = useState(false);
  const isMounted = useIsMounted();
  const { prev: isShownPrev, next: isShownNext } = useIsShown([
    controlsType,
    canSeeControls,
    canScrollPrev,
    canScrollNext,
  ]);

  /** Скроллит контейнер тэгов */
  const scroll = useCallback(
    (toPrev?: boolean) => {
      const ref = tagsWrapperRef?.current;

      if (!ref) {
        return;
      }

      ref.scrollBy({ left: (toPrev ? -1 : 1) * scrollLength, behavior: 'smooth' });
    },
    [scrollLength],
  );

  /** Проверяет состояние контролов */
  const checkForScrollPosition = useCallback(() => {
    const ref = tagsWrapperRef?.current;

    // Проверяем isMounted, т.к. данная функция может быть вызвана через debounce
    if (!ref || !isMounted()) {
      return;
    }

    const { scrollLeft, scrollWidth, clientWidth } = ref;

    /** Показывает/прячет контролы (если все тэги умещаются в строке -> прячет контролы) */
    setCanSeeControls(scrollWidth > clientWidth);
    /** Дизэйблит контрол, если достигнут левый предел прокрутки */
    setCanScrollPrev(scrollLeft > 0);
    /** Дизэйблит контрол, если достигнут правый предел прокрутки */
    setCanScrollNext(Math.ceil(scrollLeft) < scrollWidth - clientWidth);
  }, [isMounted]);

  /** Проверяет состояние контролов с задержкой */
  const checkForScrollPositionDebounced = debounce(checkForScrollPosition, 100);

  /** Включает прослушку скроллинга и дизэйблит контролы */
  useEffect(() => {
    const ref = tagsWrapperRef?.current;

    if (!ref) {
      return;
    }

    checkForScrollPosition();
    ref.addEventListener('scroll', checkForScrollPositionDebounced);

    return () => {
      ref.removeEventListener('scroll', checkForScrollPositionDebounced);
    };
  }, [checkForScrollPosition, checkForScrollPositionDebounced]);

  return (
    <div className={`${s['wrapper']}`}>
      {isShownPrev && (
        <div className={`${s['control-wrapper']} ${s['prev']} ${s[controlsType]}`}>
          <RoundedButton
            icon={<IconChevronLeft12 />}
            size={controlsSize}
            theme="fill_light"
            title="Показать предыдущие"
            disabled={!canSeeControls || !canScrollPrev}
            onClick={() => scroll(true)}
          />
        </div>
      )}

      <div className={s['scrolling-wrapper']}>
        <Scrolling ref={tagsWrapperRef}>{children}</Scrolling>
      </div>

      {isShownNext && (
        <div className={`${s['control-wrapper']} ${s['next']} ${s[controlsType]}`}>
          <RoundedButton
            icon={<IconChevronRight12 />}
            size={controlsSize}
            theme="fill_light"
            title="Показать следующие"
            disabled={!canSeeControls || !canScrollNext}
            onClick={() => scroll()}
          />
        </div>
      )}
    </div>
  );
};

import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SidebarSubscribeForm } from '../../components/SubscribeForms';
import { validateEmail } from '../../utils';
import { selectIsSubscribed } from '../../selectors/subscription/selectIsSubscribed';
import { subscribe } from '../../reducers/modules/subscribe/subscribe';

/**
 * Форма подписки для поста
 */
export const SidebarSubscribeFormContainer = () => {
  const [errorText, setErrorText] = useState<string>('');
  const [subscribedText, setSubscribedText] = useState<string>('');
  const dispatch = useDispatch();
  const isSubscribed = useSelector(selectIsSubscribed);

  /** Готовит сообщение об успехе */
  useEffect(() => {
    if (isSubscribed) {
      setSubscribedText('Теперь вам на почту будут приходить последние новости');

      return;
    }

    setSubscribedText('');
  }, [isSubscribed]);

  /** Проверяет валидность значения и дергает ручку подписки */
  const handleSubmit = useCallback(
    email => {
      if (!validateEmail(email)) {
        setErrorText('Проверьте правильность написания почты');

        return;
      }

      dispatch(subscribe(email));
    },
    [dispatch],
  );

  const handleChange = useCallback(() => {
    if (errorText) {
      setErrorText('');
    }
  }, [errorText]);

  return (
    <SidebarSubscribeForm
      isDisabled={false}
      errorText={errorText}
      subscribedText={subscribedText}
      onChange={handleChange}
      onSubmit={handleSubmit}
    />
  );
};

/* eslint-disable max-lines */
import * as React from 'react';
import { Button, Checkbox, Input, MultiSelect, Select, Tags, TDeviceType } from '@cian/ui-kit';
import differenceWith from 'lodash/differenceWith';
import isEqual from 'lodash/isEqual';

import { IRegionsItem } from '../../types/regions';
import { ICategory } from '../../types/client_add_form';
import { IPostItem } from '../../types/posts';
import { IUser } from '../../types/user';
import { unescapeHtml } from '../../../app/helpers/make_html_helper';
import { validateEmail } from '../../utils/validateEmail';
import { Tooltip } from '../tooltip/tooltip';
import { IError } from '../../types/error_response';
import { PageBlocked } from '../page_blocked/page_blocked';
import { DemandBanner } from '../realtor_demand/demand_banner';

interface IProps {
  user: IUser;
  item?: IPostItem;
  regions: IRegionsItem[];
  tags: string[];
  onSubmitAddForm(
    content: string,
    status: string,
    is_subscribed_to_comments: boolean,
    category: string,
    regions_id: number[],
    tags: string,
    title: string,
    imageData: string,
    userEmail: string,
    userName: string,
  ): void;
  error?: IError[];
  fetching: boolean;
  heading: string;
  entityName: string;
  styles: any;
  showLawBlock: boolean;
  showDescribeBlock: boolean;
  showImage: boolean;
  showSubscribe: boolean;
  categories: ICategory[];
  contentLimit?: number;
  editingItem?: IPostItem;
  isEdit: boolean;
  onSubmitEditForm(
    id: number,
    content: string,
    status: string,
    is_subscribed_to_comments: boolean,
    category: string,
    regions_id: number[],
    tags: string,
    title: string,
    imageData: string,
    imageClear: boolean,
  ): void;
  type: string;
  deviceType: TDeviceType;
  uploadImage(img: string): any;
}

interface IState {
  optionsHeader?: string;
  quillText: string;
  filteredTags?: string[];
  dropdownTagsVisible?: boolean;
  addedTags: string[];
  remainingTags?: string[];
  title: string;
  visibleBsideTip?: boolean;
  visibleBsideTipSource?: boolean;
  selectedRegion: number[];
  selectedCategory: string;
  enteredTag: string;
  imageData: string;
  loadingImage?: boolean;
  failImage?: boolean;
  emptyTitle?: boolean;
  emptyContent?: boolean;
  emptyRegion?: boolean;
  subscribe?: boolean;
  imageClear: boolean;
  isSavedImage?: string;
  userEmail?: string;
  userName?: string;
  invalidEmail?: string;
  emptyUserName?: boolean;
}

interface IModules {
  toolbar: {
    container: string;
    handlers: {
      image(): void;
    };
  };
}

const IS_ALREADY_LOGIN = 'Пользователь с таким e-mail уже зарегистрирован. Попробуйте войти.';

// eslint-disable-next-line import/no-default-export
export default class ClientAddForm extends React.Component<IProps, IState> {
  public Quill: any;
  public Delta: any;
  public modules: IModules = {
    toolbar: {
      container: '#toolbar',
      handlers: {
        image: () => this.imageHandler(),
      },
    },
  };
  public formats: string[] = ['header', 'bold', 'italic', 'strike', 'image', 'video'];
  public tags: HTMLInputElement | null;
  public fileInput: HTMLInputElement | null;

  public constructor(props: IProps) {
    super(props);

    this.state = {
      optionsHeader: '',
      quillText: props.editingItem ? unescapeHtml(props.editingItem.attributes.content) : '',
      filteredTags: props.tags,
      dropdownTagsVisible: false,
      addedTags: props.editingItem && props.editingItem.attributes.tags ? props.editingItem.attributes.tags : [],
      remainingTags: props.tags,
      title: props.editingItem ? props.editingItem.attributes.title : '',
      visibleBsideTip: false,
      visibleBsideTipSource: false,
      selectedRegion:
        props.editingItem && props.editingItem.attributes.regions.length
          ? props.editingItem.attributes.regions.map(region => region.id)
          : [0],
      selectedCategory:
        props.editingItem && props.editingItem.attributes.category ? props.editingItem.attributes.category : '',
      enteredTag: '',
      imageData: '',
      loadingImage: false,
      emptyTitle: false,
      emptyContent: false,
      emptyRegion: false,
      subscribe: true,
      imageClear: false,
      isSavedImage: props.editingItem ? props.editingItem.attributes.image : '',
      userEmail: '',
      userName: '',
      invalidEmail: '',
      emptyUserName: false,
    };

    if (typeof document !== 'undefined') {
      this.Quill = require('react-quill');
      this.Delta = require('quill-delta');
    }
  }

  public componentWillReceiveProps(nextProps: IProps) {
    if (nextProps.error && nextProps.error.length) {
      const invalidEmail = nextProps.error.filter(
        error =>
          error.source.pointer === '/data/attributes/email' ||
          error.source.pointer === '/data' ||
          error.source.pointer === '/data/attributes/userId',
      );

      this.setState(
        Object.assign({}, this.state, {
          invalidEmail: invalidEmail.length ? invalidEmail[0].detail : '',
        }),
      );
    }
  }

  public componentDidMount() {
    if (document.getElementById('login-btn')) {
      (document.getElementById('login-btn') as Element).addEventListener('click', this.hideErrors);
    }
  }

  public render() {
    const { heading, type, user, styles } = this.props;

    if (this.authorizationRequired()) {
      if (type === 'question') {
        return <span>Для вашего аккаунта {user.email} заблокирована возможность добавления вопроса</span>;
      }

      return <span>Писать блоги могут только авторизованные пользователи в статусе 'Специалист'</span>;
    }

    return (
      <div className={`${styles[`blog-add`]} cg-row`}>
        <form
          className={`${styles[`blog-add-content-wrap`]} cg-col-16 cg-col-xs-24`}
          onSubmit={(e: any) => e.preventDefault()}
        >
          <h1 className={styles[`blog-add-content_main-heading`]}>{heading}</h1>
          {!user.isEmailConfirmed ? (
            <PageBlocked
              content={
                <div className={styles['page-block-content']}>
                  Добавление новых вопросов доступно только зарегистрированным пользователям с подтвержденным email.
                  Войдите в{' '}
                  <a className={styles['page-block-content_link']} href="https://my.cian.ru/">
                    Личный кабинет{' '}
                  </a>
                  для подтверждения.
                </div>
              }
            />
          ) : (
            <div className={styles[`blog-add-content`]}>
              <div id="hidden-container" className={styles['hidden-block']} />
              {this.renderTitle()}
              {this.renderImage()}
              {this.renderQuill()}
              <div className={`cg-row ${styles['custom-row']}`}>
                {this.renderCaregory()}
                {this.renderRegion()}
              </div>
              {this.renderTags()}
              {(!user || user.userId === -1) && this.renderUserCredentials()}
              {this.renderMessage()}
              {this.renderButtons()}
            </div>
          )}
        </form>
        <div className={`${styles[`blog-add-bside`]} cg-skip-1 cg-col-7 cg-col-xs-24`}>
          {this.renderDescribeBlock()}
          {this.renderLawBlock()}
          {this.renderRealtorDemandBlock()}
        </div>
      </div>
    );
  }

  private imageHandler() {
    // eslint-disable-next-line react/no-string-refs
    const quill = (this.refs.editor as any).getEditor();

    const hiddenContainer = document.getElementById('hidden-container') as HTMLInputElement;
    let fileInput = document.getElementById('tmp-file-input') as HTMLInputElement;
    if (fileInput == null) {
      fileInput = document.createElement('input');
      fileInput.setAttribute('type', 'file');
      fileInput.setAttribute('id', 'tmp-file-input');
      fileInput.setAttribute('accept', 'image/png, image/gif, image/jpeg, image/bmp, image/x-icon');
      fileInput.classList.add('ql-image');
      fileInput.addEventListener('change', () => {
        if (fileInput?.files != null && fileInput?.files?.[0] != null) {
          this.uploadTextImage(fileInput?.files?.[0], (src: string) => {
            const range = quill.getSelection(true);
            quill.updateContents(new this.Delta().retain(range.index).delete(range.length).insert({ image: src }));
            fileInput.value = '';
          });
        }
      });
      hiddenContainer.appendChild(fileInput);
    }
    fileInput.click();
  }

  private uploadTextImage(file: any, cb: (src: string) => void) {
    const reader = new FileReader();
    reader.onload = e => {
      const image = (e.target as any).result;
      this.props.uploadImage(image).then((res: any) => {
        const url = res.data.attributes.file;
        cb(url);
      });
    };
    reader.readAsDataURL(file);
  }

  private renderUserCredentials() {
    const { invalidEmail: stateInvalidEmail, userEmail, emptyUserName, userName } = this.state;
    const { styles } = this.props;

    let invalidEmail: any = stateInvalidEmail;

    if (invalidEmail === IS_ALREADY_LOGIN) {
      invalidEmail = (
        <div>
          Пользователь с таким e-mail уже зарегистрирован. Попробуйте
          <span
            className={styles['login__error']}
            onClick={() => {
              if ((window as any).waitForLoginPopup) {
                (window as any).waitForLoginPopup();
              }
              this.hideErrors();
            }}
          >
            {' '}
            войти.
          </span>
        </div>
      );
    }

    return (
      <div className={`cg-row ${styles['custom-row']}`}>
        <div
          className={`
              cg-col-12
              cg-col-xs-24
              ${styles['blog-add-content_email-wrap']}
            `}
        >
          <h3
            className={`
                ${styles['blog-add-content_heading']}
                ${styles['blog-add-content_email']}
              `}
          >
            Ваш email
          </h3>
          <Tooltip
            show={!!invalidEmail}
            content={invalidEmail ? invalidEmail : ''}
            tooltipStyle={styles['tooltip-tablet']}
          >
            <input
              type="text"
              className={`
                    ${styles['blog-credentials_input-tag']}
                    ${invalidEmail ? styles['blog-credentials_input-tag-error'] : ''}
                  `}
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                this.setState(
                  Object.assign({}, this.state, {
                    userEmail: (e.currentTarget as HTMLInputElement).value,
                    invalidEmail: '',
                  }),
                );
              }}
              value={userEmail}
            />
          </Tooltip>
        </div>
        <div className={styles['hidden-block']} />
        <div className={`cg-col-12 cg-col-xs-24 ${styles['blog-add-content_name-wrap']}`}>
          <h3
            className={`
                ${styles['blog-add-content_heading']}
                ${styles['blog-add-content_name']}
              `}
          >
            Ваше имя
          </h3>
          <Tooltip show={!!emptyUserName} content="Укажите имя" tooltipStyle={styles['tooltip-tablet']}>
            <input
              type="text"
              className={`
                  ${styles['blog-credentials_input-tag']}
                  ${emptyUserName ? styles['blog-credentials_input-tag-error'] : ''}
                `}
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                this.setState(
                  Object.assign({}, this.state, {
                    userName: (e.currentTarget as HTMLInputElement).value,
                    emptyUserName: false,
                  }),
                );
              }}
              value={userName}
            />
          </Tooltip>
        </div>
      </div>
    );
  }

  private CustomToolbar = () => (
    <div id="toolbar" className={this.props.styles['quill-editor_toolbar']}>
      <select className="ql-header" value={this.state.optionsHeader} onChange={this.handleSelect}>
        <option value="1"></option>
        <option value="2"></option>
        <option value=""></option>
      </select>
      <button className="ql-bold"></button>
      <button className="ql-italic"></button>
      <button className="ql-strike"></button>
      <button className="ql-image"></button>
      <button className="ql-video"></button>
    </div>
  );

  private handleSelect = (e: any) => {
    this.setState({
      optionsHeader: e.target.value,
      title: this.state.title,
      selectedRegion: this.state.selectedRegion,
      selectedCategory: this.state.selectedCategory,
      quillText: this.state.quillText,
      addedTags: this.state.addedTags,
      enteredTag: this.state.enteredTag,
      imageData: this.state.imageData,
      imageClear: this.state.imageClear,
    });
  };

  private handleChange = (value: string) => {
    if (this.refs.editor) {
      const currentLength = (this.refs.editor as any).getEditor().getLength();
      if (this.props.contentLimit && currentLength > this.props.contentLimit + 1) {
        (this.refs.editor as any).getEditor().deleteText(this.props.contentLimit, currentLength);
        (this.refs.editor as any).getEditor().update();
      } else {
        this.setState({
          quillText: value,
          title: this.state.title,
          selectedRegion: this.state.selectedRegion,
          selectedCategory: this.state.selectedCategory,
          addedTags: this.state.addedTags,
          enteredTag: this.state.enteredTag,
          imageData: this.state.imageData,
          imageClear: this.state.imageClear,
          emptyContent: false,
        });
      }
    }
  };

  private showDropdown = () => {
    document.addEventListener('click', this.hideDropdown);

    if (this.state.addedTags) {
      this.setState({
        dropdownTagsVisible: true,
        filteredTags: differenceWith(this.props.tags, this.state.addedTags, isEqual),
        title: this.state.title,
        selectedRegion: this.state.selectedRegion,
        selectedCategory: this.state.selectedCategory,
        quillText: this.state.quillText,
        addedTags: this.state.addedTags,
        enteredTag: this.state.enteredTag,
        imageData: this.state.imageData,
        imageClear: this.state.imageClear,
      });
    }
  };

  private hideDropdown = () => {
    this.setState({
      dropdownTagsVisible: false,
      title: this.state.title,
      selectedRegion: this.state.selectedRegion,
      selectedCategory: this.state.selectedCategory,
      quillText: this.state.quillText,
      addedTags: this.state.addedTags,
      enteredTag: this.state.enteredTag,
      imageData: this.state.imageData,
      imageClear: this.state.imageClear,
    });
    document.removeEventListener('click', this.hideDropdown);
  };

  private onChangeTags = (e: any) => {
    if (this.state.remainingTags) {
      const filteredTags = this.state.remainingTags.filter(tag => {
        return tag.toLowerCase().includes(e.target.value.toLowerCase());
      });

      this.setState({
        filteredTags,
        title: this.state.title,
        selectedRegion: this.state.selectedRegion,
        selectedCategory: this.state.selectedCategory,
        quillText: this.state.quillText,
        addedTags: this.state.addedTags,
        enteredTag: e.target.value,
        imageData: this.state.imageData,
        dropdownTagsVisible: !!filteredTags.length,
        imageClear: this.state.imageClear,
      });
    }
  };

  private addTag = (tag: string) => {
    const { addedTags } = this.state;

    if (addedTags) {
      addedTags.push(tag);
      this.setState({
        addedTags,
        remainingTags: differenceWith(this.props.tags, addedTags, isEqual),
        title: this.state.title,
        selectedRegion: this.state.selectedRegion,
        selectedCategory: this.state.selectedCategory,
        quillText: this.state.quillText,
        enteredTag: '',
        imageData: this.state.imageData,
        imageClear: this.state.imageClear,
      });
    }
  };

  private onSubmitAddForm = (
    content: string,
    status: string,
    isSubscribedToComments: boolean,
    category: string,
    regionsId: number[],
    tags: string,
    title: string,
    imageData: any,
    userEmail?: string,
    userName?: string,
  ) => {
    const filledContent = (this.refs.editor as any).getEditor().getLength() > 1;
    let regions = regionsId;
    const validatedFields = [!!title, !!filledContent, !!regions.length];

    if (this.props.user && this.props.user.userId === -1) {
      validatedFields.push(!!validateEmail(userEmail || ''), !!userName);
    }

    if (validatedFields.indexOf(false) !== -1) {
      let invalidEmail = this.state.invalidEmail;

      if (invalidEmail === IS_ALREADY_LOGIN) {
        invalidEmail = IS_ALREADY_LOGIN;
      } else {
        invalidEmail = validateEmail(userEmail || '') ? '' : 'Введите корректный адрес электронной почты';
      }

      this.setState({
        visibleBsideTipSource: this.state.visibleBsideTipSource,
        title: this.state.title,
        selectedRegion: this.state.selectedRegion,
        selectedCategory: this.state.selectedCategory,
        quillText: this.state.quillText,
        addedTags: this.state.addedTags,
        enteredTag: this.state.enteredTag,
        imageData: this.state.imageData,
        emptyTitle: !title,
        emptyContent: !filledContent,
        emptyRegion: !regions.length,
        imageClear: this.state.imageClear,
        invalidEmail,
        emptyUserName: !userName,
      });

      return;
    }

    if (regions.indexOf(0) !== -1) {
      regions = [];
    }

    this.props.onSubmitAddForm(
      unescapeHtml(content),
      status,
      isSubscribedToComments,
      category,
      regions,
      tags,
      title,
      imageData,
      userEmail || '',
      userName || '',
    );
  };

  private onSubmitEditForm = (
    id: number,
    content: string,
    status: string,
    isSubscribedToComments: boolean,
    category: string,
    regionsId: number[],
    tags: string,
    title: string,
    imageData: any,
    imageClear: boolean,
  ) => {
    const filledContent = (this.refs.editor as any).getEditor().getLength() > 1;
    let regions = regionsId;

    if (!title || !filledContent || !regions.length) {
      this.setState({
        visibleBsideTipSource: this.state.visibleBsideTipSource,
        title: this.state.title,
        selectedRegion: this.state.selectedRegion,
        selectedCategory: this.state.selectedCategory,
        quillText: this.state.quillText,
        addedTags: this.state.addedTags,
        enteredTag: this.state.enteredTag,
        imageData: this.state.imageData,
        emptyTitle: !title,
        emptyContent: !filledContent,
        emptyRegion: !regions.length,
        imageClear: this.state.imageClear,
      });

      return;
    }

    if (regions.indexOf(0) !== -1) {
      regions = [];
    }

    this.props.onSubmitEditForm(
      id,
      unescapeHtml(content),
      status,
      isSubscribedToComments,
      category,
      regions,
      tags,
      title,
      imageData,
      imageClear,
    );
  };

  private uploadImage = () => {
    const image = this.fileInput?.files;

    this.setState({
      addedTags: this.state.addedTags,
      remainingTags: this.state.addedTags,
      title: this.state.title,
      selectedRegion: this.state.selectedRegion,
      selectedCategory: this.state.selectedCategory,
      quillText: this.state.quillText,
      enteredTag: this.state.enteredTag,
      imageData: this.state.imageData,
      loadingImage: true,
      failImage: false,
      imageClear: this.state.imageClear,
    });

    if (image && image[0]) {
      const reader = new FileReader();
      const MEGABYTE = 1048576;
      const MAX_SIZE = MEGABYTE * 10;
      const validImgType = /(gif|jpg|jpeg|png)$/i.test(image[0].type);

      if (image[0].size > MAX_SIZE || !validImgType) {
        this.setState({
          addedTags: this.state.addedTags,
          remainingTags: this.state.addedTags,
          title: this.state.title,
          selectedRegion: this.state.selectedRegion,
          selectedCategory: this.state.selectedCategory,
          quillText: this.state.quillText,
          enteredTag: this.state.enteredTag,
          imageData: this.state.imageData,
          loadingImage: false,
          failImage: true,
          imageClear: this.state.imageClear,
        });

        return;
      }

      reader.onload = (e: any) => {
        this.setState({
          addedTags: this.state.addedTags,
          remainingTags: this.state.addedTags,
          title: this.state.title,
          selectedRegion: this.state.selectedRegion,
          selectedCategory: this.state.selectedCategory,
          quillText: this.state.quillText,
          enteredTag: '',
          imageData: e.target.result,
          loadingImage: false,
          imageClear: false,
        });
      };

      reader.readAsDataURL(image[0]);
    } else {
      this.setState({
        addedTags: this.state.addedTags,
        remainingTags: this.state.addedTags,
        title: this.state.title,
        selectedRegion: this.state.selectedRegion,
        selectedCategory: this.state.selectedCategory,
        quillText: this.state.quillText,
        enteredTag: this.state.enteredTag,
        imageData: this.state.imageData,
        loadingImage: false,
        failImage: false,
        imageClear: this.state.imageClear,
      });
    }
  };

  private renderTitle = () => (
    <div>
      <h3 className={this.props.styles[`blog-add-content_heading`]}>Заголовок {this.props.entityName}</h3>
      <Tooltip
        show={!!this.state.emptyTitle}
        tooltipStyle={this.props.styles['tooltip-tablet']}
        content="Укажите заголовок"
      >
        <Input
          onChange={(_, value: string) =>
            this.setState({
              title: value,
              selectedRegion: this.state.selectedRegion,
              selectedCategory: this.state.selectedCategory,
              quillText: this.state.quillText,
              addedTags: this.state.addedTags,
              enteredTag: this.state.enteredTag,
              imageData: this.state.imageData,
              imageClear: this.state.imageClear,
              emptyTitle: false,
            })
          }
          value={this.state.title}
          maxLength={120}
        />
      </Tooltip>
    </div>
  );

  private renderImage = () => (
    <div>
      {this.props.showImage && (
        <div
          className={`
                ${this.props.styles[`blog-add-content-upload`]}
                ${this.state.loadingImage ? this.props.styles['loading'] : ''}
                ${this.state.failImage ? this.props.styles['error-img'] : ''}
              `}
        >
          <h3 className={this.props.styles[`blog-add-content_heading`]}>Обложка (необязательно)</h3>
          {/* tslint:disable-next-line:max-line-length */}
          <span className={this.props.styles[`blog-add-content-upload_description`]}>
            JPG, PNG или GIF. Максимальный размер файла 10 мб
          </span>
          {!(this.state.imageData || this.state.isSavedImage) && (
            <div className={this.props.styles[`blog-add-content-upload_input-wrap`]}>
              <label>
                <input
                  type="file"
                  className={this.props.styles[`blog-add-content-upload_add-img`]}
                  ref={fileInput => (this.fileInput = fileInput)}
                  onChange={this.uploadImage}
                />
                <span>Добавить обложку</span>
                <div className={this.props.styles[`loader`]}></div>
              </label>
            </div>
          )}
          {(this.state.isSavedImage || this.state.imageData) && (
            <div className={this.props.styles[`blog-add-content-upload_preview`]}>
              <img src={this.state.isSavedImage || this.state.imageData} alt="upload-img" />
              <button
                className={this.props.styles[`blog-add-content-upload_preview-btn`]}
                onClick={() =>
                  this.setState({
                    selectedCategory: this.state.selectedCategory,
                    selectedRegion: this.state.selectedRegion,
                    title: this.state.title,
                    quillText: this.state.quillText,
                    addedTags: this.state.addedTags,
                    enteredTag: this.state.enteredTag,
                    imageData: '',
                    imageClear: true,
                    isSavedImage: '',
                  })
                }
              />
            </div>
          )}
        </div>
      )}
    </div>
  );

  private renderQuill = () => {
    const Quill = this.Quill;
    const CustomToolbar = this.CustomToolbar;

    return (
      <div>
        <h3 className={this.props.styles[`blog-add-content_heading`]}>Текст {this.props.entityName}</h3>
        {Quill && (
          <div className={this.props.styles['blog-add__quill-wrap']}>
            <Tooltip
              show={!!this.state.emptyContent}
              content="Укажите текст"
              tooltipStyle={`${this.props.styles['tooltip-tablet']} ${this.props.styles['tooltip-quill']}`}
            >
              <CustomToolbar />
              <Quill
                ref={'editor'}
                theme="snow"
                modules={this.modules}
                formats={this.formats}
                onChange={this.handleChange}
                style={{ height: 310 }}
                value={this.state.quillText}
                className={`${
                  this.state.emptyContent ? this.props.styles['req'] : this.props.styles['blog-add__tooltip-quill']
                }`}
              />
            </Tooltip>
          </div>
        )}
      </div>
    );
  };

  private renderCaregory = () => {
    const { styles, categories } = this.props;
    const { selectedCategory, selectedRegion, title, quillText, addedTags, enteredTag, imageData, imageClear } =
      this.state;

    return (
      <div className={`${styles[`blog-add-content-category`]}`}>
        <h3
          className={`
            ${styles[`blog-add-content_heading`]}
            ${styles[`blog-add-content_heading-tag-region`]}
          `}
        >
          Категория
        </h3>
        <span>
          <div>
            <Select
              value={selectedCategory}
              onChange={(value: string) => {
                this.setState({
                  selectedCategory: value,
                  selectedRegion,
                  title,
                  quillText,
                  addedTags,
                  enteredTag,
                  imageData,
                  imageClear,
                });
              }}
              options={categories}
            />
          </div>
        </span>
      </div>
    );
  };

  private renderRegion = () => {
    const regionsWithDelimeter = [...this.props.regions];

    const regionsIds = regionsWithDelimeter.map(region => region.id);

    return (
      <div>
        <div
          className={`
              ${this.props.styles[`blog-add-content-region`]}
              ${this.state.emptyRegion ? this.props.styles['req'] : ''}
            `}
        >
          <h3
            className={`
                ${this.props.styles[`blog-add-content_heading`]}
                ${this.props.styles[`blog-add-content_heading-tag-region`]}
              `}
          >
            Регион
          </h3>
          <span>
            <div>
              <Tooltip
                show={!!this.state.emptyRegion}
                content="Укажите регион"
                tooltipStyle={this.props.styles['tooltip-tablet']}
                containerStyle={this.props.styles['region-tooltip']}
              >
                <MultiSelect
                  placeholder="Выберите регион"
                  value={this.state.selectedRegion}
                  onChange={(value: number[]) => {
                    this.setState({
                      selectedRegion: this.regionsFilter(value),
                      title: this.state.title,
                      selectedCategory: this.state.selectedCategory,
                      quillText: this.state.quillText,
                      addedTags: this.state.addedTags,
                      enteredTag: this.state.enteredTag,
                      imageData: this.state.imageData,
                      imageClear: this.state.imageClear,
                    });
                  }}
                  options={regionsWithDelimeter.map((region: any) => {
                    return {
                      value: region.id,
                      label: region.displayName === 'Любой' ? 'Вся Россия' : region.displayName,
                    };
                  })}
                />
              </Tooltip>
            </div>
          </span>
          <div className={`${this.props.styles[`blog-add-content-chips-wrapper`]} cg-col-xs-24`}>
            {this.state.selectedRegion.map((selectedRegion, i) => {
              const displayName = this.props.regions[regionsIds.indexOf(selectedRegion)]?.displayName || 'Любой';

              return (
                <Tags
                  key={`region-${i}`}
                  options={[
                    {
                      value: displayName === 'Любой' ? 'Вся Россия' : displayName,
                      label: displayName === 'Любой' ? 'Вся Россия' : displayName,
                    },
                  ]}
                  onClose={() => {
                    this.state.selectedRegion.splice(i, 1);
                    this.setState({
                      selectedRegion: this.state.selectedRegion,
                      title: this.state.title,
                      selectedCategory: this.state.selectedCategory,
                      quillText: this.state.quillText,
                      addedTags: this.state.addedTags,
                      enteredTag: this.state.enteredTag,
                      imageData: this.state.imageData,
                      imageClear: this.state.imageClear,
                    });
                  }}
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  private renderTags = () => {
    const MAX_ADDED_TAGS = 5;

    return (
      <div className={`cg-row ${this.props.styles['custom-row']} ${this.props.styles['custom-row']}`}>
        <h3 className={this.props.styles[`blog-add-content_heading`]}>Теги</h3>
        <div className={this.props.styles[`blog-add-content-tags_wrap`]}>
          <input
            ref={tags => (this.tags = tags)}
            type="text"
            className={this.props.styles[`blog-add-content_input-tag`]}
            onClick={this.showDropdown}
            onChange={this.onChangeTags}
            value={this.state.enteredTag}
            disabled={this.state.addedTags && this.state.addedTags.length === MAX_ADDED_TAGS}
          />
          {this.state.dropdownTagsVisible && (
            <ul className={this.props.styles['blog-add-content_dropdown']}>
              {this.state.filteredTags &&
                this.state.filteredTags.map((tag, i) => {
                  return (
                    <li
                      key={`${tag}-${i}`}
                      className={this.props.styles['blog-add-content_dropdown-item']}
                      onClick={() => this.addTag(tag)}
                    >
                      {tag}
                    </li>
                  );
                })}
            </ul>
          )}
        </div>
        <div className={`${this.props.styles[`blog-add-content-chips-wrapper`]} cg-col-xs-24`}>
          {this.state.addedTags &&
            this.state.addedTags.map((addedTag, i) => {
              return (
                <Tags
                  key={`tag-${i}`}
                  options={[{ value: addedTag, label: addedTag }]}
                  onClose={() => {
                    this.state.addedTags.splice(i, 1);
                    this.setState({
                      addedTags: this.state.addedTags,
                      remainingTags: differenceWith(this.props.tags, this.state.addedTags, isEqual),
                      title: this.state.title,
                      selectedRegion: this.state.selectedRegion,
                      selectedCategory: this.state.selectedCategory,
                      quillText: this.state.quillText,
                      enteredTag: this.state.enteredTag,
                      imageData: this.state.imageData,
                      imageClear: this.state.imageClear,
                    });
                  }}
                />
              );
            })}
        </div>
      </div>
    );
  };

  private renderMessage = () => (
    <div>
      {this.props.showSubscribe && (
        <Checkbox
          checked={!!this.state.subscribe}
          onChange={(_, value: boolean) => {
            this.setState(
              Object.assign({}, this.state, {
                subscribe: value,
              }),
            );
          }}
          label="Сообщать о новых комментариях на email"
        />
      )}
    </div>
  );

  private renderButtons = () => (
    <div className={`${this.props.styles[`blog-add-content-footer`]} cg-row`}>
      <Button
        disabled={this.props.fetching || !!this.props.item}
        theme="fill_primary"
        size={this.props.deviceType === 'phone' ? 'M' : 'XS'}
        fullWidth={this.props.deviceType === 'phone' ? true : false}
        onClick={(e: any) => {
          const addedTagsTypes = this.state.addedTags.map(tag => {
            return tag;
          });

          if (this.props.isEdit && this.props.editingItem) {
            this.onSubmitEditForm(
              Number(this.props.editingItem.id),
              this.state.quillText,
              'unchecked',
              !!this.state.subscribe,
              this.state.selectedCategory,
              this.state.selectedRegion,
              JSON.stringify(addedTagsTypes),
              this.state.title,
              this.state.imageData,
              this.state.imageClear,
            );
          } else {
            this.onSubmitAddForm(
              this.state.quillText,
              'unchecked',
              !!this.state.subscribe,
              this.state.selectedCategory,
              this.state.selectedRegion,
              JSON.stringify(addedTagsTypes),
              this.state.title,
              this.state.imageData,
              this.state.userEmail,
              this.state.userName,
            );
          }
        }}
      >
        Опубликовать
      </Button>
      {this.props.type === 'blog' && (
        <Button
          disabled={this.props.fetching || !!this.props.item}
          theme="stroke_primary"
          size={this.props.deviceType === 'phone' ? 'M' : 'XS'}
          fullWidth={this.props.deviceType === 'phone' ? true : false}
          onClick={() => {
            const addedTagsTypes = this.state.addedTags.map(tag => {
              return tag;
            });

            if (this.props.isEdit && this.props.editingItem) {
              this.onSubmitEditForm(
                Number(this.props.editingItem.id),
                this.state.quillText,
                'draft',
                !!this.state.subscribe,
                this.state.selectedCategory,
                this.state.selectedRegion,
                JSON.stringify(addedTagsTypes),
                this.state.title,
                this.state.imageData,
                this.state.imageClear,
              );
            } else {
              this.onSubmitAddForm(
                this.state.quillText,
                'draft',
                !!this.state.subscribe,
                this.state.selectedCategory,
                this.state.selectedRegion,
                JSON.stringify(addedTagsTypes),
                this.state.title,
                this.state.imageData,
              );
            }
          }}
        >
          Сохранить в черновик
        </Button>
      )}
    </div>
  );

  private renderDescribeBlock = () => (
    <div>
      {this.props.showDescribeBlock ? (
        <section
          className={`
                ${this.props.styles[`blog-add-bside-tip`]}
                ${this.state.visibleBsideTip && this.props.styles[`blog-add-bside-tip_visible-mobile`]}`}
        >
          <h3 className={this.props.styles['blog-add-bside-tip_title-dekstop']}>
            <span className={this.props.styles['blog-add-bside-tip_title-dekstop-icon']} />
            <span className={this.props.styles['blog-add-bside-tip_title-dekstop-text']}>о чём рассказать?</span>
          </h3>
          <h3
            className={this.props.styles['blog-add-bside-tip_title-mobile']}
            onClick={() => {
              this.setState({
                visibleBsideTip: !this.state.visibleBsideTip,
                title: this.state.title,
                selectedRegion: this.state.selectedRegion,
                selectedCategory: this.state.selectedCategory,
                quillText: this.state.quillText,
                addedTags: this.state.addedTags,
                enteredTag: this.state.enteredTag,
                imageData: this.state.imageData,
                imageClear: this.state.imageClear,
              });
            }}
          >
            <span
              className={`
                    ${this.props.styles['blog-add-bside-tip_title-dekstop-icon']}
                    ${this.props.styles['blog-add-bside-tip_title-tablet-icon']}
                  `}
            />
            <span className={this.props.styles['blog-add-bside-tip_title-dekstop-text']}>о чём рассказать?</span>
            <span className={this.props.styles['blog-add__arrow-bottom']} />
          </h3>
          <ul className={this.props.styles[`blog-add-bside-tip-list`]}>
            <li>
              <span>Поделитесь профессиональным опытом</span>
            </li>
            <li>
              <span>Расскажите о «подводных камнях» при сделках с недвижимостью</span>
            </li>
            <li>
              <span>Выразите свое мнение о динамике цен и ситуации на рынке</span>
            </li>
            <li>
              <span>Обсудите с коллегами актуальные темы и события сферы</span>
            </li>
          </ul>
        </section>
      ) : (
        <section
          className={`
                ${this.props.styles[`blog-add-bside-tip`]}
                ${this.state.visibleBsideTip && this.props.styles[`blog-add-bside-tip_visible-mobile`]}`}
        >
          <h3 className={this.props.styles['blog-add-bside-tip_title-dekstop']}>
            <span className={this.props.styles['blog-add-bside-tip_title-dekstop-icon']} />
            <span className={this.props.styles['blog-add-bside-tip_title-dekstop-text']}>О чём спросить?</span>
          </h3>
          <h3
            className={this.props.styles['blog-add-bside-tip_title-mobile']}
            onClick={() => {
              this.setState({
                visibleBsideTip: !this.state.visibleBsideTip,
                title: this.state.title,
                selectedRegion: this.state.selectedRegion,
                selectedCategory: this.state.selectedCategory,
                quillText: this.state.quillText,
                addedTags: this.state.addedTags,
                enteredTag: this.state.enteredTag,
                imageData: this.state.imageData,
                imageClear: this.state.imageClear,
              });
            }}
          >
            <span
              className={`
                    ${this.props.styles['blog-add-bside-tip_title-dekstop-icon']}
                    ${this.props.styles['blog-add-bside-tip_title-tablet-icon']}
                  `}
            />
            <span className={this.props.styles['blog-add-bside-tip_title-dekstop-text']}>О чём спросить?</span>
            <span className={this.props.styles['blog-add__arrow-bottom']} />
          </h3>
          <ul className={this.props.styles[`blog-add-bside-tip-list`]}>
            <li>
              <span>Спросите о юридических тонкостях в сделках с недвижимостью</span>
            </li>
            <li>
              <span>Расскажите о своей ситуации и получите советы от профессионалов рынка</span>
            </li>
            <li>
              <span>Попросите оценить стоимость вашего объекта</span>
            </li>
          </ul>
        </section>
      )}
    </div>
  );

  private renderRealtorDemandBlock = () =>
    this.props.type === 'question' ? (
      <section className={this.props.styles['blog-add-bside-realtor-demand']}>
        <DemandBanner />
      </section>
    ) : null;

  private renderLawBlock = () => (
    <div>
      {this.props.showLawBlock && (
        <section
          className={`
                ${this.props.styles[`blog-add-bside-tip-source`]}
                ${this.state.visibleBsideTipSource && this.props.styles['blog-add-bside-tip-source_visible-mobile']}`}
        >
          <h3 className={this.props.styles['blog-add-bside-tip-source_title-dekstop']}>
            <span className={this.props.styles['blog-add-bside-tip-source_title-dekstop-icon']} />
            <span className={this.props.styles['blog-add-bside-tip-source_title-dekstop-text']}>
              указывайте источник
            </span>
          </h3>
          <h3
            className={this.props.styles['blog-add-bside-tip-source_title-mobile']}
            onClick={() => {
              this.setState({
                visibleBsideTipSource: !this.state.visibleBsideTipSource,
                title: this.state.title,
                selectedRegion: this.state.selectedRegion,
                selectedCategory: this.state.selectedCategory,
                quillText: this.state.quillText,
                addedTags: this.state.addedTags,
                enteredTag: this.state.enteredTag,
                imageData: this.state.imageData,
                imageClear: this.state.imageClear,
              });
            }}
          >
            <span className={this.props.styles['blog-add-bside-tip-source_title-dekstop-icon']} />
            <span className={this.props.styles['blog-add-bside-tip-source_title-dekstop-text']}>
              указывайте источник
            </span>
            <span className={this.props.styles['blog-add__arrow-bottom']} />
          </h3>
          <p>
            Материалы частично или полностью скопированные с других источников, должны содержать ссылку на источник. В
            случае нарушения авторских прав третьих лиц вы можете быть привлечены к ответственности по ст. 146 УК России
          </p>
        </section>
      )}
    </div>
  );

  private regionsFilter = (regions: number[]) => {
    const zeroPosition = regions.indexOf(0);

    if (regions[regions.length - 1] === 0) {
      regions = [0];
    } else if (zeroPosition !== -1) {
      regions.splice(zeroPosition, 1);
    }

    return regions;
  };

  private authorizationRequired = () => {
    return (this.props.type === 'blog' && this.props.user.userId < 0) || this.props.user.isBlock;
  };

  private hideErrors = () => {
    this.setState(
      Object.assign({}, this.state, {
        emptyTitle: false,
        emptyContent: false,
        emptyRegion: false,
        invalidEmail: '',
        emptyUserName: false,
      }),
    );
  };
}

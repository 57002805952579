import { ISubscribe, ISubscribeListAction } from '../../../types/subscribe';

export const SUBSCRIBE_LIST = 'subscribe_list/ACTION';
export const SUBSCRIBE_LIST_REQUEST = 'subscribe_list/ACTION_REQUEST';
export const SUBSCRIBE_LIST_SUCCESS = 'subscribe_list/ACTION_SUCCESS';
export const SUBSCRIBE_LIST_FAILURE = 'subscribe_list/ACTION_FAILURE';
export const SUBSCRIBE_LIST_RESET = 'subscribe_list/ACTION_RESET';

export const initialState: ISubscribe = {
  status: '',
  statusCode: 0,
};

const defaultAction: ISubscribeListAction = {
  type: '',
  payload: {
    data: {
      status: '',
    },
  },
  error: {
    status: '0',
  },
};

export const listSubscribeReducer = (state = initialState, action = defaultAction) => {
  switch (action.type) {
    case SUBSCRIBE_LIST_REQUEST:
      return state;
    case SUBSCRIBE_LIST_SUCCESS:
      return Object.assign({}, state, { status: 'ok' });
    case SUBSCRIBE_LIST_FAILURE:
      return Object.assign({}, state, { statusCode: parseInt(action.error.status, 10) || 400 });
    case SUBSCRIBE_LIST_RESET:
      return Object.assign({}, initialState);
    default:
      return state;
  }
};

export const subscribeToList = (journal_type: string, email: string, categories?: string[], regions?: number[]) => {
  return {
    type: SUBSCRIBE_LIST,
    method: 'POST',
    payload: {
      pathname: '/v1/subscribe-journal',
      data: {
        attributes: {
          journal_type,
          email,
          categories,
          regions,
        },
      },
    },
    request: true,
  };
};

export const subscribeToListReset = () => {
  return {
    type: SUBSCRIBE_LIST_RESET,
  };
};

import React from 'react';

import * as s from './PostCardHeaderLayout.css';

/**
 * Шаблон компонента PostCardHeader
 */
export const PostCardHeaderLayout = ({ children }: { children: React.ReactNode }) => (
  <div className={s['wrapper']}>{children}</div>
);

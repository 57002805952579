/* eslint-disable */

//tslint:disable

import { IModel, IModelResponse } from '@cian/http-api/shared/model';
import { ITagDataSchema } from '../../entities/tag/TagDataSchema';

export type TGetTagsModel = IModel<IGetTagsRequest, TGetTagsResponse>;

export interface IGetTagsRequest {
  /** Аудитория **/
  b2b?: boolean | null;
  /** Тип страницы **/
  pageType?: EPageType | null;
  /** ID рубрики **/
  rubricId?: number | null;
}

export type TGetTagsResponse = IGetTagsResponse200 | IGetTagsResponse400;

export interface IGetTagsResponse200 extends IModelResponse<IGetTagsResponse> {
  statusCode: 200;
}

export interface IGetTagsResponse400 extends IModelResponse<IGetTagsResponseError> {
  statusCode: 400;
}

export interface IGetTagsResponse {
  /** Данные **/
  data: ITagDataSchema[];
}

export interface IGetTagsResponseError {
  errors: any[];
  message: string;
}

export type TResponses = IGetTagsResponse | IGetTagsResponseError;

export enum EPageType {
  /** Страница листингов статей **/
  Articles = 'articles',
  /** Страница листингов блогов **/
  Blogs = 'blogs',
  /** Главная страница **/
  Main = 'main',
  /** Страница листингов новостей **/
  News = 'news',
  /** Страница листингов вопросов **/
  Questions = 'questions',
}

export interface IMappers<TResponse200, TResponse400> {
  200(response: IGetTagsResponse): TResponse200;
  400(response: IGetTagsResponseError): TResponse400;
}

import React, { useContext } from 'react';
import { RouteComponentProps, Switch } from 'react-router';

import { PostCard } from '../post_card/post_card';
import { PostPreview } from '../post_preview/post_preview';
import { AddBlog } from '../add_blog/add_blog';
import { RatingTable } from '../rating_table/rating_table';
import { AddQuestion } from '../add_question/add_question';
import { Unsubscribe } from '../unsubscribe/unsubscribe';
import { ApplicationContext } from '../../utils';
import { PageReload } from '../../components/PageReload';
import { Route } from '../../components/Route';

export interface IRoutesParams {
  onRender?(routeProps: RouteComponentProps): void;
}

export const Routes = (props: IRoutesParams) => {
  const {
    custom: { isNewDesignEnabled, newDesignSubdomains, subdomain },
  } = useContext(ApplicationContext);
  const isCorrectSubdomain = newDesignSubdomains?.length ? newDesignSubdomains.includes(subdomain) : true;

  return (
    <Switch>
      <Route path={`/magazine/`}>
        <PageReload />
      </Route>

      <Route path={`/stati/`}>
        <PageReload />
      </Route>

      <Route path={`/magazine-:path/`}>
        <PageReload />
      </Route>

      <Route path={`/novosti/market/`}>
        <PageReload />
      </Route>

      <Route path={`/novosti/company/`}>
        <PageReload />
      </Route>

      <Route path={`/novosti/`}>
        <PageReload />
      </Route>

      <Route path={`/blogs-specialist/`}>
        <PageReload />
      </Route>

      <Route path={`/blogs-tendentsiy-rynka/`}>
        <PageReload />
      </Route>

      <Route path={`/blogs/`}>
        <PageReload />
      </Route>

      <Route path={`/moi-blog/`}>
        <PageReload />
      </Route>

      <Route path={`/forum-rieltorov/`}>
        <PageReload />
      </Route>

      <Route path={`/forum-rieltorov-specialist/`}>
        <PageReload />
      </Route>

      <Route path={`/forum-rieltorov-tendentsiy-rynka/`}>
        <PageReload />
      </Route>

      <Route path={`/forum-rieltorov-gorodskaya-nedvizhimost/`}>
        <PageReload />
      </Route>

      <Route path={`/forum-rieltorov-kommercheskaya-nedvizhimost/`}>
        <PageReload />
      </Route>

      <Route path={`/forum-rieltorov-zagorodnaya-nedvizhimost/`}>
        <PageReload />
      </Route>

      <Route path={`/forum-rieltorov-zarubezhnaya-nedvizhimost/`}>
        <PageReload />
      </Route>

      <Route path={`/forum-rieltorov-ipoteka/`}>
        <PageReload />
      </Route>

      <Route path={`/forum-rieltorov-:id/`} component={PostCard} {...props}>
        {isNewDesignEnabled && isCorrectSubdomain && <PageReload />}
      </Route>

      <Route path={`/novosti-:id/`} component={PostCard} {...props}>
        {isNewDesignEnabled && isCorrectSubdomain && <PageReload />}
      </Route>

      <Route path={`/blogs-:id/`} component={PostCard} {...props}>
        {isNewDesignEnabled && isCorrectSubdomain && <PageReload />}
      </Route>

      <Route path={`/stati-:id/`} component={PostCard} {...props}>
        {isNewDesignEnabled && isCorrectSubdomain && <PageReload />}
      </Route>

      <Route path={`/preview`} component={PostPreview} {...props} />
      <Route path={`/add-blog/`} component={AddBlog} {...props} />
      <Route path={`/content-preview`} component={PostPreview} {...props} />
      <Route path={`/reyting-rieltorov/`} component={RatingTable} {...props} />
      <Route path={`/add-vopros/`} component={AddQuestion} {...props} />
      <Route path={`/unsubscribe-email/`} component={Unsubscribe} {...props} />
    </Switch>
  );
};

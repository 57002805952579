import * as React from 'react';

export const DogAndCloud = () => (
  // eslint-disable-next-line react/forbid-elements
  <svg width="130" height="114" viewBox="0 0 130 114" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M103.427 29.958C103.345 29.958 103.264 29.958 103.182 29.958C103.246 29.5729 103.293 29.1879 103.293 28.7911C103.293 24.4152 99.2728 20.8736 94.3134 20.8736C93.5024 20.8736 92.7206 20.9787 91.9738 21.1537C89.1907 11.3166 79.9021 4.08179 68.8631 4.08179C57.229 4.08179 47.532 12.116 45.3557 22.7699C42.7127 21.953 39.8771 21.5038 36.9189 21.5038C22.846 21.5038 11.4395 31.5684 11.4395 43.9785C11.4395 56.3886 23.8379 65.4671 37.9108 65.4671C44.1013 65.4671 97.2249 65.0937 103.433 65.0937C114.431 65.0937 123.346 57.2287 123.346 47.5259C123.346 37.823 114.431 29.958 103.433 29.958H103.427Z"
      fill="white"
    />
    <path
      d="M96.707 68.7873C95.3867 43.952 93.3177 44.1293 94.2292 35.9437C95.5712 23.8588 110.919 2.42367 101.666 0.622337C96.537 -0.375993 90.5108 9.29623 90.5108 9.29623C90.5108 9.29623 81.5909 1.08533 73.4017 6.23252C64.9666 11.5352 62.3876 29.3424 63.0748 38.7289C63.4872 44.3752 68.1714 45.3374 68.1714 45.3374C68.1714 45.3374 66.1205 49.4573 59.9098 53.4615C47.5175 61.4481 45.9911 67.0619 45.0362 72.4659C44.6238 74.8062 45.1736 80.0402 45.1736 80.0402L96.6744 79.9027C96.6744 79.9027 97.0868 75.9094 96.7106 68.7837L96.707 68.7873Z"
      fill="#036CFA"
    />
    <path
      d="M75.0512 3.56666C74.8885 3.56666 74.7438 3.45815 74.7004 3.29538C74.6859 3.24474 74.3893 2.03661 75.1887 1.11063C75.9628 0.213576 77.1456 0.361878 77.1962 0.369112C77.3951 0.394432 77.5326 0.578906 77.5073 0.774232C77.482 0.973174 77.2975 1.10701 77.1022 1.08531C77.0841 1.08531 76.2449 0.99126 75.7349 1.58085C75.1851 2.21747 75.3949 3.10728 75.3985 3.11452C75.4455 3.30984 75.3298 3.50517 75.1344 3.55219C75.1055 3.55943 75.0766 3.56304 75.044 3.56304L75.0512 3.56666Z"
      fill="#231F20"
    />
    <path
      d="M68.9645 6.02648C68.9175 6.02648 68.8668 6.01563 68.8198 5.99754C68.6353 5.91796 68.5521 5.70817 68.6317 5.52369C68.6534 5.47667 69.1526 4.33727 70.3498 4.08769C71.5073 3.85258 72.361 4.68452 72.3935 4.72069C72.5346 4.86176 72.5346 5.08964 72.3935 5.23071C72.2525 5.37178 72.0246 5.37178 71.8835 5.23071C71.8727 5.22347 71.265 4.63388 70.4945 4.79303C69.6734 4.96304 69.2973 5.7986 69.2936 5.80583C69.2358 5.94328 69.1019 6.02286 68.9609 6.02286L68.9645 6.02648Z"
      fill="#231F20"
    />
    <path
      d="M85.3131 14.0546C85.5277 13.8232 85.2731 13.238 84.7444 12.7476C84.2157 12.2571 83.6131 12.0471 83.3984 12.2785C83.1838 12.5099 83.4384 13.0951 83.9671 13.5856C84.4958 14.076 85.0984 14.286 85.3131 14.0546Z"
      fill="#231F20"
    />
    <path
      d="M81.6705 15.58C81.8852 15.3486 81.6306 14.7634 81.1019 14.273C80.5731 13.7825 79.9705 13.5725 79.7559 13.8039C79.5412 14.0353 79.7958 14.6205 80.3245 15.1109C80.8532 15.6014 81.4558 15.8114 81.6705 15.58Z"
      fill="#231F20"
    />
    <path
      d="M83.9464 26.5649C83.8921 26.5649 83.8415 26.5649 83.7872 26.5649C81.0527 26.4925 79.6746 23.7254 79.6167 23.6061C79.5299 23.4252 79.6022 23.2118 79.7831 23.125C79.9603 23.0382 80.1773 23.1105 80.2678 23.2914C80.2786 23.3167 81.5157 25.78 83.809 25.8415C86.6737 25.9247 88.1567 23.4939 88.1712 23.4722C88.2725 23.3022 88.4968 23.2444 88.6668 23.3456C88.8368 23.4469 88.8946 23.6712 88.7934 23.8412C88.7246 23.9533 87.1259 26.5685 83.95 26.5685L83.9464 26.5649Z"
      fill="#231F20"
    />
    <path
      d="M88.9232 23.9455C88.7569 23.9455 88.5724 23.9093 88.3915 23.8189C87.9466 23.5947 87.8236 23.0955 87.8164 22.8387C87.8164 22.6397 87.9719 22.4733 88.1709 22.4697C88.3662 22.4733 88.5362 22.6253 88.5398 22.8242C88.5398 22.8242 88.5543 23.0919 88.7135 23.1715C88.9594 23.2944 89.1982 23.1461 89.209 23.1389C89.3754 23.034 89.5997 23.081 89.7082 23.2438C89.8167 23.4102 89.7733 23.6308 89.6105 23.7393C89.4731 23.8334 89.2162 23.9419 88.9196 23.9419L88.9232 23.9455Z"
      fill="#231F20"
    />
    <path
      d="M79.458 23.6415C79.241 23.6415 79.0457 23.5836 78.9227 23.5221C78.7419 23.4353 78.6659 23.2183 78.7527 23.041C78.8395 22.8602 79.0565 22.7842 79.2338 22.871C79.2338 22.871 79.4797 22.9795 79.6208 22.871C79.8415 22.7046 79.8161 22.4225 79.8161 22.4189C79.7908 22.2199 79.9283 22.0391 80.1272 22.0137C80.3262 21.9884 80.507 22.1259 80.5323 22.3248C80.5649 22.5853 80.5034 23.1134 80.0549 23.4497C79.8668 23.5908 79.6498 23.6415 79.4544 23.6415H79.458Z"
      fill="#231F20"
    />
    <path
      d="M76.2241 37.5605C76.2241 37.5605 79.7364 38.595 85.1765 38.7325C90.3382 38.8627 94.4364 37.7342 94.4364 37.7342C94.4364 37.7342 94.3279 38.9748 94.4364 40.23C94.5413 41.4164 94.6426 42.6065 94.6426 42.6065C94.6426 42.6065 91.5789 43.7567 85.1765 43.829C78.9804 43.8978 74.8496 42.1073 74.8496 42.1073"
      fill="white"
    />
    <path
      d="M84.8217 44.1902C78.8209 44.1902 74.871 42.5082 74.7046 42.4359C74.5201 42.3563 74.4369 42.1429 74.5165 41.9584C74.596 41.7739 74.8095 41.6907 74.9903 41.7703C75.0301 41.7884 79.1283 43.5391 85.1689 43.4631C90.4102 43.4053 93.3979 42.6131 94.2552 42.3491L94.0743 40.2583C94.0056 39.4662 94.0201 38.6885 94.0418 38.2002C92.7939 38.4896 89.2961 39.1949 85.1653 39.09C79.7396 38.9526 76.2672 37.947 76.1225 37.9036C75.9308 37.8457 75.8223 37.6468 75.8765 37.4551C75.9344 37.2634 76.1333 37.1512 76.325 37.2091C76.3612 37.22 79.8626 38.2291 85.1834 38.363C90.2293 38.4932 94.2986 37.39 94.3384 37.3791C94.4541 37.3466 94.5735 37.3755 94.6675 37.4515C94.758 37.5274 94.805 37.6432 94.7978 37.7589C94.7978 37.7698 94.6929 38.9851 94.7978 40.1932L95.0039 42.5697C95.0184 42.7325 94.9244 42.8844 94.7724 42.9386C94.6458 42.9857 91.5749 44.1142 85.1834 44.1829C85.064 44.1829 84.9447 44.1829 84.8253 44.1829L84.8217 44.1902Z"
      fill="#231F20"
    />
    <path
      d="M68.8416 45.728C68.6138 45.728 68.3823 45.7208 68.1435 45.7063C64.5445 45.4712 62.9783 41.9662 62.7106 38.765C62.4538 35.6868 63.4955 26.7489 63.5389 26.3691C63.5606 26.1701 63.7415 26.0255 63.9404 26.0508C64.1394 26.0725 64.2804 26.2533 64.2587 26.4523C64.2479 26.5427 63.1844 35.6977 63.434 38.7035C63.897 44.2739 67.1922 44.9178 68.1906 44.9829C71.5545 45.2035 73.779 43.7892 74.9908 40.6676C77.6023 33.9325 73.8441 18.8671 73.808 18.7152C73.7573 18.5199 73.8767 18.3246 74.0684 18.2776C74.2601 18.2305 74.4591 18.3463 74.5061 18.538C74.5169 18.5778 75.4827 22.4228 76.1048 27.0672C76.9476 33.3357 76.7993 37.9982 75.6599 40.9244C74.4229 44.1111 72.1296 45.7208 68.838 45.7208L68.8416 45.728Z"
      fill="#231F20"
    />
    <path
      d="M84.988 52.5684C85.1978 55.5995 87.0353 57.8421 89.5818 57.8421C92.5442 57.8421 93.1917 55.0425 92.9819 52.0113C92.7721 48.9802 92.0631 46.2745 89.0681 46.6182C86.8689 46.8714 84.7782 49.5372 84.9916 52.5684H84.988Z"
      fill="#231F20"
    />
    <path
      d="M89.5821 58.2001C86.8945 58.2001 84.8581 55.8924 84.6266 52.5899C84.4132 49.5081 86.4641 46.5493 89.0214 46.2563C90.0632 46.137 90.9277 46.3576 91.5932 46.911C92.9026 47.9998 93.2137 50.1954 93.3367 51.9859C93.5067 54.4093 93.1088 56.1564 92.1539 57.1801C91.5209 57.8601 90.6527 58.2037 89.5748 58.2037L89.5821 58.2001ZM89.5676 46.9472C89.4193 46.9472 89.2674 46.958 89.1082 46.9761C87.1658 47.2004 85.1475 49.6275 85.35 52.5429C85.5526 55.4475 87.2924 57.4803 89.5821 57.4803C90.461 57.4803 91.1338 57.2199 91.6294 56.6845C92.4288 55.8273 92.7724 54.2213 92.6169 52.0365C92.5011 50.3907 92.2298 48.3796 91.1302 47.4681C90.7106 47.1208 90.197 46.9508 89.564 46.9508L89.5676 46.9472Z"
      fill="#231F20"
    />
    <path
      d="M90.5545 57.672C92.7606 57.5181 94.3777 54.9378 94.1664 51.9087C93.9551 48.8796 91.9955 46.5487 89.7894 46.7026C87.5833 46.8565 85.9662 49.4368 86.1775 52.4659C86.3888 55.495 88.3484 57.8259 90.5545 57.672Z"
      fill="white"
    />
    <path
      d="M90.3463 58.0402C89.2431 58.0402 88.1869 57.4976 87.3405 56.4956C86.4651 55.4539 85.9226 54.0324 85.8141 52.4915C85.5898 49.2686 87.3622 46.5087 89.764 46.3387C90.9504 46.2591 92.1006 46.8017 93.0049 47.876C93.8803 48.9177 94.4228 50.3393 94.5314 51.8802C94.7556 55.103 92.9832 57.8629 90.5814 58.0329C90.5019 58.0365 90.4259 58.0402 90.3463 58.0402ZM89.9955 47.0513C89.934 47.0513 89.8761 47.0513 89.8146 47.0585C87.8107 47.1996 86.3385 49.6122 86.5375 52.4372C86.6352 53.8262 87.1162 55.103 87.8939 56.0254C88.6463 56.9188 89.5795 57.3746 90.5308 57.3059C92.5347 57.1648 94.0069 54.7522 93.8079 51.9272C93.7103 50.5382 93.2292 49.2614 92.4515 48.339C91.7498 47.5034 90.8853 47.0513 89.9991 47.0513H89.9955Z"
      fill="#231F20"
    />
    <path
      d="M95.5723 3.31024C95.5723 3.31024 96.2306 5.88926 98.7734 7.44101C101.562 9.14468 103.628 9.09404 103.628 9.09404C103.628 9.09404 105.773 2.34446 102.749 0.88314C99.7247 -0.578183 95.5723 3.31024 95.5723 3.31024Z"
      fill="#231F20"
    />
    <path
      d="M97.2762 25.3598C97.2256 25.3598 97.1713 25.349 97.1243 25.3273C96.9434 25.2441 96.8639 25.027 96.9507 24.8462L99.9963 18.339C100.058 18.2196 105.545 7.40797 103.946 2.72016C103.664 1.89545 103.19 1.33118 102.496 1.00564C99.9239 -0.206105 96.4334 2.73824 93.9593 5.42216C91.2103 8.40268 89.087 11.7341 89.0653 11.7666C88.9568 11.9366 88.7362 11.9836 88.5661 11.8787C88.3961 11.7702 88.3491 11.5496 88.454 11.3796C88.8085 10.8189 97.2111 -2.28958 102.803 0.350934C103.671 0.759671 104.286 1.47948 104.63 2.48866C106.326 7.46584 100.879 18.2051 100.647 18.6609L97.6054 25.1573C97.5439 25.2875 97.4137 25.367 97.2762 25.367V25.3598Z"
      fill="#231F20"
    />
    <path
      d="M76.2919 29.3679C76.2919 29.3679 80.3829 40.6462 75.7421 45.3412C72.6096 48.5098 66.3086 47.8877 66.3086 47.8877L67.8929 45.6848C67.8929 45.6848 72.0273 45.7572 74.2952 43.1384C76.9791 40.0385 76.2919 29.3679 76.2919 29.3679Z"
      fill="#231F20"
    />
    <path d="M94.6312 37.4336L94.1248 36.9236L94.0488 37.7266L94.6312 37.4336Z" fill="#231F20" />
    <path
      d="M90.1497 8.97013L88.3954 11.5709L88.3086 7.62817C88.3086 7.62817 88.8512 7.98265 89.3684 8.35884C89.7157 8.61204 90.1533 8.97013 90.1533 8.97013H90.1497Z"
      fill="#231F20"
    />
    <path
      d="M89.7891 49.526C89.6588 49.526 89.5322 49.4573 89.4671 49.3307C89.3731 49.1534 89.4418 48.9364 89.6191 48.8424C89.695 48.8026 91.5434 47.7789 91.084 45.1999C90.6861 42.9645 88.7148 42.8162 88.6316 42.8126C88.4326 42.8017 88.2807 42.6281 88.2916 42.4292C88.3024 42.2302 88.476 42.0783 88.6714 42.0892C88.6967 42.0892 91.2938 42.2628 91.7966 45.0733C92.3536 48.1913 89.9808 49.4717 89.9554 49.4862C89.9012 49.5152 89.8433 49.526 89.7891 49.526Z"
      fill="#231F20"
    />
    <path
      d="M87.2029 48.0101C87.1161 48.0101 87.0257 47.9775 86.957 47.9124C86.928 47.8871 86.2697 47.2505 86.154 45.8181C86.0346 44.3242 86.4144 43.7202 86.4578 43.6587C86.5699 43.4923 86.7942 43.4525 86.9606 43.5646C87.1234 43.6768 87.1668 43.8974 87.0583 44.0602C87.0438 44.0819 86.7797 44.5593 86.8738 45.7638C86.9642 46.896 87.4344 47.3699 87.4525 47.3916C87.5936 47.529 87.5972 47.7605 87.4598 47.9016C87.3874 47.9775 87.2934 48.0137 87.1993 48.0137L87.2029 48.0101Z"
      fill="#231F20"
    />
    <path
      d="M130.001 32.9771C122.872 35.1113 121.143 36.8366 119.012 43.966C116.878 36.8366 115.153 35.1076 108.023 32.9771C115.153 30.843 116.882 29.1177 119.012 21.9883C121.146 29.1177 122.872 30.8466 130.001 32.9771Z"
      fill="#036CFA"
    />
    <path d="M107.996 66.7361H40.5039V113.299H107.996V66.7361Z" fill="#FFDEFF" />
    <path
      d="M108.094 113.401C107.895 113.401 107.732 113.238 107.732 113.039V67.094H40.866V113.039C40.866 113.238 40.7032 113.401 40.5043 113.401C40.3053 113.401 40.1426 113.238 40.1426 113.039V66.7323C40.1426 66.5334 40.3053 66.3706 40.5043 66.3706H108.094C108.293 66.3706 108.456 66.5334 108.456 66.7323V113.039C108.456 113.238 108.293 113.401 108.094 113.401Z"
      fill="#231F20"
    />
    <path
      d="M89.1664 113.809C88.9675 113.809 88.8047 113.647 88.8047 113.448V66.7323C88.8047 66.5334 88.9675 66.3706 89.1664 66.3706C89.3653 66.3706 89.5281 66.5334 89.5281 66.7323V113.448C89.5281 113.647 89.3653 113.809 89.1664 113.809Z"
      fill="#231F20"
    />
    <path d="M103.058 113.176V78.0432H94.6152V113.314L103.058 113.176Z" fill="white" />
    <path
      d="M94.6137 113.676C94.5196 113.676 94.4292 113.64 94.3605 113.571C94.2917 113.502 94.252 113.408 94.252 113.314V78.0473C94.252 77.8483 94.4147 77.6855 94.6137 77.6855H103.056C103.255 77.6855 103.418 77.8483 103.418 78.0473V113.181C103.418 113.379 103.259 113.539 103.063 113.542L94.6209 113.68H94.6137V113.676ZM94.9754 78.4054V112.945L102.694 112.822V78.409H94.9754V78.4054Z"
      fill="#231F20"
    />
    <path d="M54.9863 77.0915V102.147H79.3622V77.0915C79.3622 77.0915 54.9863 76.8202 54.9863 77.0915Z" fill="white" />
    <path
      d="M79.3587 102.51H54.9828C54.7839 102.51 54.6211 102.347 54.6211 102.148V77.0923C54.6211 76.9946 54.6609 76.897 54.7296 76.8319C54.8707 76.6944 55.1058 76.4593 79.3623 76.7306C79.5613 76.7306 79.7204 76.8933 79.7204 77.0923V102.148C79.7204 102.347 79.5576 102.51 79.3587 102.51ZM55.3445 101.786H78.997V77.4504C70.1892 77.3527 57.381 77.2695 55.3445 77.4034V101.79V101.786Z"
      fill="#231F20"
    />
    <path
      d="M67.2426 102.101C67.0436 102.101 66.8809 101.938 66.8809 101.739V76.9547C66.8809 76.7558 67.0436 76.593 67.2426 76.593C67.4415 76.593 67.6043 76.7558 67.6043 76.9547V101.739C67.6043 101.938 67.4415 102.101 67.2426 102.101Z"
      fill="#231F20"
    />
    <path
      d="M66.9715 89.4375H55.1254C54.9264 89.4375 54.7637 89.2748 54.7637 89.0758C54.7637 88.8769 54.9264 88.7141 55.1254 88.7141H66.9715C67.1704 88.7141 67.3332 88.8769 67.3332 89.0758C67.3332 89.2748 67.1704 89.4375 66.9715 89.4375Z"
      fill="#231F20"
    />
    <path
      d="M116.606 113.878H28.3656C28.1667 113.878 28.0039 113.716 28.0039 113.517C28.0039 113.318 28.1667 113.155 28.3656 113.155H116.606C116.805 113.155 116.967 113.318 116.967 113.517C116.967 113.716 116.805 113.878 116.606 113.878Z"
      fill="#231F20"
    />
    <path d="M78.2031 66.8448L81.6756 78.4883L83.0031 66.436L78.2031 66.8448Z" fill="#231F20" />
    <path d="M92.8086 66.9463L96.281 78.5899L97.6085 66.5376L92.8086 66.9463Z" fill="#231F20" />
    <path
      d="M89.9841 65.6514C89.9841 65.6514 93.0478 57.2162 99.6527 57.3465C103.125 57.4152 105.002 59.9363 105.643 61.6364C107.426 66.3676 108.026 75.8662 106.323 80.7674C105.827 82.1926 103.939 83.2198 102.033 83.2198C100.64 83.2198 98.0141 82.7424 96.8566 80.5649C95.1711 77.3926 95.9705 66.335 95.9705 66.335L89.9805 65.655L89.9841 65.6514Z"
      fill="#036CFA"
    />
    <path
      d="M102.238 83.6504C101.573 83.6504 98.1834 83.5129 96.7438 80.8037C96.6498 80.6265 96.7185 80.4094 96.8921 80.3154C97.0694 80.2214 97.2864 80.2865 97.3804 80.4637C98.6247 82.8076 101.645 82.927 102.235 82.927C104.058 82.927 105.718 81.9974 106.185 80.7133C107.935 75.8953 107.244 66.4473 105.505 61.8318C104.814 59.9979 102.915 57.8385 99.8437 57.777C99.7967 57.777 99.7497 57.777 99.7026 57.777C93.5246 57.777 90.5513 65.7637 90.5223 65.8469C90.4536 66.0349 90.2474 66.1326 90.0593 66.0639C89.8713 65.9952 89.7736 65.789 89.8423 65.6009C89.8749 65.5141 90.6453 63.4161 92.2513 61.3037C94.4071 58.4715 97.044 56.9885 99.8582 57.0572C103.291 57.1259 105.41 59.535 106.181 61.5823C107.964 66.3098 108.666 76.011 106.865 80.9665C106.289 82.5508 104.387 83.6576 102.235 83.6576L102.238 83.6504Z"
      fill="#231F20"
    />
    <path
      d="M104.35 83.2052C104.155 83.2052 103.996 83.0533 103.989 82.858C103.981 82.6591 104.137 82.4891 104.336 82.4818C104.336 82.4818 104.669 82.4131 104.929 81.668C105.468 80.1162 105.218 78.1774 105.215 78.1593C105.189 77.9604 105.327 77.7795 105.526 77.7542C105.721 77.7289 105.906 77.8664 105.931 78.0653C105.942 78.1521 106.206 80.1922 105.609 81.9067C105.179 83.1438 104.441 83.2052 104.357 83.2052C104.354 83.2052 104.35 83.2052 104.343 83.2052H104.35Z"
      fill="#231F20"
    />
    <path
      d="M102.053 83.5995C101.858 83.5995 101.699 83.4476 101.692 83.2523C101.684 83.0533 101.84 82.8869 102.039 82.8797C102.039 82.8797 102.372 82.811 102.632 82.0658C103.175 80.5032 103.034 78.3655 103.034 78.3438C103.019 78.1449 103.171 77.9712 103.37 77.9568C103.573 77.9423 103.743 78.0942 103.757 78.2932C103.764 78.3872 103.909 80.5973 103.316 82.3009C102.885 83.538 102.147 83.5959 102.064 83.5995C102.061 83.5995 102.057 83.5995 102.05 83.5995H102.053Z"
      fill="#231F20"
    />
    <path
      d="M99.6198 83.0309C99.4244 83.0309 99.2653 82.879 99.2581 82.6837C99.2508 82.4847 99.4064 82.3184 99.6053 82.3111C99.6053 82.3111 99.9381 82.2424 100.199 81.4973C100.737 79.9455 100.495 78.2708 100.491 78.2563C100.463 78.0574 100.596 77.8765 100.795 77.844C100.991 77.8186 101.179 77.9489 101.208 78.1478C101.219 78.2238 101.479 80.0179 100.882 81.736C100.452 82.9731 99.7138 83.0309 99.6306 83.0346C99.627 83.0346 99.6234 83.0346 99.6161 83.0346L99.6198 83.0309Z"
      fill="#231F20"
    />
    <path
      d="M75.2751 65.6514C75.2751 65.6514 78.3388 57.2162 84.9437 57.3465C88.4162 57.4152 90.2934 59.9363 90.9337 61.6364C92.7169 66.3676 93.3174 75.8662 91.6137 80.7674C91.1182 82.1926 89.23 83.2198 87.3238 83.2198C85.9312 83.2198 83.3051 82.7424 82.1477 80.5649C80.4621 77.3926 81.2615 66.335 81.2615 66.335L75.2715 65.655L75.2751 65.6514Z"
      fill="#036CFA"
    />
    <path
      d="M87.5317 83.6502C86.8661 83.6502 83.4769 83.5127 82.0372 80.8035C81.9432 80.6262 82.0119 80.4092 82.1855 80.3152C82.3628 80.2211 82.5798 80.2862 82.6739 80.4635C83.9181 82.8074 86.9385 82.9267 87.528 82.9267C89.3511 82.9267 91.0114 81.9971 91.478 80.713C93.2287 75.895 92.5378 66.4471 90.7979 61.8316C90.1071 59.9977 88.2081 57.8383 85.1371 57.7768C85.0937 57.7768 85.0503 57.7768 85.0069 57.7768C78.8216 57.7768 75.6096 66.4724 75.577 66.5592C75.5083 66.7473 75.3021 66.8449 75.114 66.7762C74.926 66.7075 74.8283 66.5013 74.897 66.3132C74.9332 66.2192 75.7579 63.9512 77.4218 61.6652C79.6535 58.5943 82.3375 57.01 85.1516 57.0534C88.5843 57.1221 90.7039 59.5311 91.4743 61.5784C93.2576 66.306 93.9593 76.0072 92.158 80.9626C91.5829 82.5469 89.6802 83.6538 87.528 83.6538L87.5317 83.6502Z"
      fill="#231F20"
    />
    <path
      d="M89.6432 83.2052C89.4479 83.2052 89.2887 83.0533 89.2815 82.858C89.2743 82.6591 89.4298 82.4891 89.6287 82.4818C89.6287 82.4818 89.9615 82.4131 90.2219 81.668C90.7609 80.1162 90.5113 78.1774 90.5077 78.1593C90.4824 77.9604 90.6198 77.7795 90.8188 77.7542C91.0177 77.7289 91.1986 77.8664 91.2239 78.0653C91.2347 78.1521 91.4988 80.1922 90.902 81.9067C90.4715 83.1438 89.7336 83.2052 89.6504 83.2052C89.6468 83.2052 89.6432 83.2052 89.636 83.2052H89.6432Z"
      fill="#231F20"
    />
    <path
      d="M87.3463 83.5995C87.151 83.5995 86.9919 83.4476 86.9846 83.2523C86.9774 83.0533 87.1329 82.8869 87.3319 82.8797C87.3319 82.8797 87.6646 82.811 87.9251 82.0658C88.4676 80.5032 88.3266 78.3655 88.3266 78.3438C88.3121 78.1449 88.464 77.9712 88.663 77.9568C88.8655 77.9423 89.0355 78.0942 89.05 78.2932C89.0572 78.3872 89.2019 80.5973 88.6087 82.3009C88.1783 83.538 87.4404 83.5959 87.3572 83.5995C87.3536 83.5995 87.35 83.5995 87.3427 83.5995H87.3463Z"
      fill="#231F20"
    />
    <path
      d="M84.9127 83.031C84.7174 83.031 84.5583 82.8791 84.551 82.6837C84.5438 82.4848 84.6993 82.3184 84.8983 82.3112C84.8983 82.3112 85.231 82.2425 85.4915 81.4973C86.0268 79.9528 85.7881 78.2708 85.7845 78.2564C85.7555 78.0574 85.8894 77.873 86.0883 77.844C86.2836 77.8151 86.4681 77.9489 86.5007 78.1479C86.5115 78.2238 86.7719 80.0179 86.1751 81.7361C85.7447 82.9731 85.0068 83.031 84.9236 83.0346C84.92 83.0346 84.9164 83.0346 84.9091 83.0346L84.9127 83.031Z"
      fill="#231F20"
    />
    <path
      d="M69.7282 77.1608C69.7282 77.1608 71.233 85.6141 72.0757 87.9869C74.7307 95.4418 79.0207 95.9519 79.0207 95.9519L79.1219 77.4647L69.7246 77.1572L69.7282 77.1608Z"
      fill="#231F20"
    />
    <path
      d="M79.019 96.3131C79.019 96.3131 78.9901 96.3131 78.9756 96.3131C78.7912 96.2914 74.4325 95.6873 71.7341 88.1094C70.8877 85.7366 69.43 77.5727 69.3685 77.2254C69.3504 77.1169 69.3794 77.0084 69.4517 76.9252C69.5241 76.842 69.6217 76.7914 69.7375 76.8022L79.1348 77.1097C79.3301 77.1169 79.4857 77.2761 79.4857 77.4714L79.3844 95.9586C79.3844 96.0599 79.341 96.1575 79.2614 96.2263C79.1963 96.2841 79.1095 96.3167 79.0227 96.3167L79.019 96.3131ZM70.1607 77.5365C70.4826 79.3017 71.698 85.8559 72.4142 87.8634C74.4795 93.6617 77.565 95.1375 78.6573 95.4884L78.755 77.815L70.1571 77.5365H70.1607Z"
      fill="#231F20"
    />
    <path
      d="M79.3272 96.3613C79.3272 96.3613 77.2835 96.8713 75.5473 98.5063C74.1258 99.8446 73.4023 101.979 73.4023 101.979H79.2223L79.3236 96.3613H79.3272Z"
      fill="#231F20"
    />
    <path
      d="M79.2277 102.34H73.4077C73.292 102.34 73.1834 102.282 73.1147 102.188C73.046 102.094 73.0279 101.971 73.0677 101.862C73.1002 101.772 73.8381 99.6268 75.3067 98.2415C77.09 96.5631 79.159 96.0314 79.2458 96.0097C79.3543 95.9807 79.47 96.0097 79.5605 96.0784C79.6473 96.1471 79.6979 96.2556 79.6979 96.3678L79.5966 101.985C79.593 102.181 79.4339 102.34 79.2349 102.34H79.2277ZM73.9358 101.616H78.8732L78.96 96.8597C78.28 97.1021 76.9597 97.6772 75.7986 98.7696C74.8546 99.6558 74.2288 100.933 73.9358 101.616Z"
      fill="#231F20"
    />
    <path
      d="M101.287 96.4329C101.626 96.4329 101.902 96.1576 101.902 95.818C101.902 95.4784 101.626 95.2031 101.287 95.2031C100.947 95.2031 100.672 95.4784 100.672 95.818C100.672 96.1576 100.947 96.4329 101.287 96.4329Z"
      fill="#231F20"
    />
    <path
      d="M101.287 96.7941C100.748 96.7941 100.311 96.3564 100.311 95.8175C100.311 95.2785 100.748 94.8408 101.287 94.8408C101.826 94.8408 102.264 95.2785 102.264 95.8175C102.264 96.3564 101.826 96.7941 101.287 96.7941ZM101.287 95.5606C101.146 95.5606 101.034 95.6764 101.034 95.8138C101.034 95.9513 101.15 96.067 101.287 96.067C101.425 96.067 101.54 95.9513 101.54 95.8138C101.54 95.6764 101.425 95.5606 101.287 95.5606Z"
      fill="#231F20"
    />
    <path
      d="M16.9427 13.8936L0.904297 18.3246L3.62438 28.1705L11.1155 26.0978L4.06206 40.8702L41.0437 66.57L22.9869 1.23364L16.9427 13.8936Z"
      fill="#FFDEFF"
    />
    <path
      d="M40.7394 66.725C40.667 66.725 40.5947 66.7033 40.5332 66.6599L3.54798 40.9638C3.39607 40.8589 3.34904 40.6563 3.43585 40.4935L13.5421 21.8363C13.6362 21.6627 13.8568 21.594 14.0341 21.6917C14.2113 21.7893 14.2764 22.0064 14.1788 22.1836L4.22801 40.555L40.9456 66.0703C41.1083 66.1861 41.1517 66.4103 41.036 66.5731C40.9673 66.6744 40.8515 66.7286 40.7394 66.7286V66.725Z"
      fill="#231F20"
    />
    <path d="M40.8226 108.308L40.7539 113.314H86.5107L40.8226 108.308Z" fill="#231F20" />
    <path
      d="M35.3113 47.0838C35.1521 47.0838 35.0075 46.9789 34.9641 46.8161L22.8937 2.26747L17.269 14.0485C17.1822 14.2293 16.9652 14.3053 16.7879 14.2185C16.6071 14.1317 16.5311 13.9183 16.6179 13.7374L22.6622 1.07743C22.7273 0.93998 22.872 0.856786 23.0203 0.871255C23.1722 0.885723 23.2988 0.99062 23.3386 1.13531L35.6585 46.628C35.7092 46.8197 35.5971 47.0187 35.4053 47.0729C35.3728 47.0802 35.3402 47.0874 35.3113 47.0874V47.0838Z"
      fill="#231F20"
    />
    <path d="M13.8282 21.6663L2.66211 24.39L3.62427 28.1699L11.6145 25.9924L13.8282 21.6663Z" fill="#231F20" />
  </svg>
);

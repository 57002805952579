import { HttpBadStatusCodeError } from '@cian/peperrors/shared';

import { TThunkAction } from '../../../types/redux';
import { getMenuLoading } from './getMenuLoading';
import { getMenuSucceed } from './getMenuSucceed';
import { getMenuFailed } from './getMenuFailed';
import { fetchGetMenu, TGetMenuResponse } from '../../../repositories/journal/v2/get-menu';

export const getMenu = (): TThunkAction<Promise<void>> => {
  return async (dispatch, getState, context) => {
    const { httpApi, logger } = context;
    const {
      userNew: { isB2BModeEnabled },
    } = getState();

    dispatch(getMenuLoading());

    try {
      const { response, statusCode }: TGetMenuResponse = await fetchGetMenu({
        httpApi,
        parameters: {
          b2b: isB2BModeEnabled,
        },
      });

      if (statusCode !== 200 || !response?.items) {
        await Promise.reject(new HttpBadStatusCodeError('Не удалось загрузить меню', statusCode, 'actions.getMenu'));

        return;
      }

      dispatch(getMenuSucceed(response.items));
    } catch (e) {
      logger.error(e, {
        domain: 'actions.getMenu',
      });

      dispatch(getMenuFailed());
    }
  };
};

import * as React from 'react';

export const IconTable16 = () => {
  return (
    // eslint-disable-next-line react/forbid-elements
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 2H2V7H7V2ZM14 2H9V7H14V2ZM2 9H7V14H2V9ZM14 9H9V14H14V9Z"
        fill="currentColor"
      />
    </svg>
  );
};

/* eslint-disable */

//tslint:disable

import { IModel, IModelResponse } from '@cian/http-api/shared/model';
import { IArticlesNewsListDataV2Schema } from '../../entities/journal/ArticlesNewsListDataV2Schema';
import { IJournalArticlesMetaV2Schema } from '../../entities/journal/JournalArticlesMetaV2Schema';

export type TGetArticleListModel = IModel<IGetArticleListRequest, TGetArticleListResponse>;

export interface IGetArticleListRequest {
  /** Аудитория **/
  b2b?: boolean | null;
  /** Лимит **/
  limit?: number | null;
  /** Сдвиг **/
  offset?: number | null;
  /** Сортировка **/
  ordering?: EOrdering | null;
  /** ID региона **/
  regionId?: number | null;
  /** ID рубрики **/
  rubricId?: number | null;
  /** Название тега **/
  tag?: string | null;
}

export type TGetArticleListResponse = IGetArticleListResponse200 | IGetArticleListResponse400;

export interface IGetArticleListResponse200 extends IModelResponse<IGetArticleListResponse> {
  statusCode: 200;
}

export interface IGetArticleListResponse400 extends IModelResponse<IGetArticleListResponseError> {
  statusCode: 400;
}

export interface IGetArticleListResponse {
  /** Данные **/
  data: IArticlesNewsListDataV2Schema[];
  /** Мета данные **/
  meta: IJournalArticlesMetaV2Schema;
}

export interface IGetArticleListResponseError {
  errors: any[];
  message: string;
}

export type TResponses = IGetArticleListResponse | IGetArticleListResponseError;

export enum EOrdering {
  /** Обсуждаемое **/
  Comments_count = 'comments_count',
  /** Дата публикации **/
  Date_publish = 'date_publish',
  /** Понравившееся **/
  Likes_count = 'likes_count',
  /** Популярное **/
  Num_views = 'num_views',
}

export interface IMappers<TResponse200, TResponse400> {
  200(response: IGetArticleListResponse): TResponse200;
  400(response: IGetArticleListResponseError): TResponse400;
}

import { connect } from 'react-redux';

import { ListItem, IListItemStoreProps, IListItemOwnProps } from '../../components/list_item/list_item';
import { IMenuRubricsState } from '../../reducers/modules/rubrics_menu/rubrics_menu';
import { getMenuRubricsData, selectRubric } from '../../../app/utils';

interface IState {
  menuRubrics: IMenuRubricsState;
}

export function mapStateToProps(state: IState): IListItemStoreProps {
  return {
    menuItems: getMenuRubricsData(state.menuRubrics.items as any)(selectRubric as any),
  };
}

export const ListItemContainer = connect<IListItemStoreProps, {}, IListItemOwnProps>(mapStateToProps)(ListItem);

import React, { useCallback } from 'react';
import { IconButton, Close16 as IconClose16 } from '@cian/ui-kit';

import * as s from './ModalHeader.css';

export interface IModalHeader {
  children: React.ReactNode;
  hideCloseButton?: boolean;
  onClose?(e: React.MouseEvent): void;
}

/**
 * Зона шапки для модального окна с крестиком
 */
export const ModalHeader = ({ children, onClose, hideCloseButton }: IModalHeader) => {
  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();

      if (onClose) {
        onClose(e);
      }
    },
    [onClose],
  );

  return (
    <div className={s['wrapper']}>
      <div className={s['content']}>{children}</div>

      {!hideCloseButton && (
        <div className={s['close-button-wrapper']}>
          <IconButton icon={<IconClose16 />} onClick={handleClick} />
        </div>
      )}
    </div>
  );
};

import React from "react";

import { IRegionsItem } from '../../../../types/regions';
import * as s from './Group.css';

export interface IGroup {
  /** Заголовок группы */
  title: string,
  /** Коллекция регионов */
  regions: IRegionsItem[];
  /** Активный регион */
  activeRegion: IRegionsItem;
  /** Эвент события клика по региону */
  onClick(region: IRegionsItem): void;
}

export const Group: React.FC<IGroup> = ({ title, regions, activeRegion, onClick }) => {
  // Сортируем регионы внутри группы по алфавиту
  const regionsSorted = regions.sort(function(a,b) {
    if (a.displayName < b.displayName) {
      return -1;
    }

    if (a.displayName > b.displayName) {
      return 1;
    }

    return 0;
  });

  return (
    <div className={s['container']}>
      <div className={s['title']}>{title}</div>
      <div className={s['buttons-wrapper']}>
        {regionsSorted.map(region => (
          <button key={region.id}
                  className={`
                    ${s['button']}
                    ${activeRegion.id === region.id ? s['_active'] : ''}
                  `}
                  onClick={() => onClick(region)}
          >
            {region.displayName}
          </button>
        ))}
      </div>
    </div>
  );
};

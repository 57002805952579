/* eslint-disable */

//tslint:disable

import { IModel, IModelResponse } from '@cian/http-api/shared/model';
import { IRecommendationOfferSchema } from '../../entities/recommendations/RecommendationOfferSchema';

export type TGetRecommendationsModel = IModel<IGetRecommendationsRequest, TGetRecommendationsResponse>;

export interface IGetRecommendationsRequest {
  /** ID локации или региона пользователя, используется для холодного старта при отсутствии истории пользователя **/
  geoId?: number | null;
  /** Количество рекомендованных объектов в ответе **/
  limit?: number | null;
  /** Максимальная цена **/
  maxPrice?: number | null;
  /** Минимальная цена **/
  minPrice?: number | null;
  /** Категории, по которым будет фильтроваться результат **/
  offerCategories?: EOfferCategories[] | null;
  /** Количество комнат **/
  rooms?: number[] | null;
}

export type TGetRecommendationsResponse = IGetRecommendationsResponse200 | IGetRecommendationsResponse400;

export interface IGetRecommendationsResponse200 extends IModelResponse<IGetRecommendationsResponse> {
  statusCode: 200;
}

export interface IGetRecommendationsResponse400 extends IModelResponse<IGetRecommendationsResponseError> {
  statusCode: 400;
}

export interface IGetRecommendationsResponse {
  /** Рекомендованные офферы **/
  data: IRecommendationOfferSchema[];
}

export interface IGetRecommendationsResponseError {
  errors: any[];
  message: string;
}

export type TResponses = IGetRecommendationsResponse | IGetRecommendationsResponseError;

export enum EOfferCategories {
  /** Посуточная аренда квартиры **/
  DailyFlatRent = 'dailyFlatRent',
  /** Посуточная аренда дома, дачи, коттеджа **/
  DailyHouseRent = 'dailyHouseRent',
  /** Квартира **/
  FlatRent = 'flatRent',
  /** Квартира **/
  FlatSale = 'flatSale',
  /** Аренда таунхаус/дом/дача/коттедж **/
  HouseAllRent = 'houseAllRent',
  /** Покупка таунхаус/дом/дача/коттедж **/
  HouseAllSale = 'houseAllSale',
  /** Квартира в новостройке **/
  NewBuildingFlatSale = 'newBuildingFlatSale',
  /** Офис **/
  OfficeRent = 'officeRent',
  /** Офис **/
  OfficeSale = 'officeSale',
}

export interface IMappers<TResponse200, TResponse400> {
  200(response: IGetRecommendationsResponse): TResponse200;
  400(response: IGetRecommendationsResponseError): TResponse400;
}

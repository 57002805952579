import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowR16 as IconChevronRight16 } from '@cian/ui-kit/icons';

import { IMenuItem, TMenuItemCommonProps } from '../../types';
import * as s from './MenuItemDesktop.css';

export type TMenuItemDesktopProps = Omit<IMenuItem, 'slug' | 'items'> &
  TMenuItemCommonProps & {
    /** Активно ли состояние наведения на пункт меню */
    isHovered: boolean;
    /** Скоуп для подменю */
    children?: React.ReactNode;
    /** Обработчик наведения на пункт меню */
    onMouseEnter(e: React.MouseEvent): void;
    /** Обработчик окончания наведения на пункт меню */
    onMouseLeave(e: React.MouseEvent): void;
  };

/**
 * Элемент основного меню
 */
export const MenuItemDesktop = ({
  url,
  label,
  iconBefore,
  isActive,
  isHovered,
  children,
  onClick,
  onMouseEnter,
  onMouseLeave,
}: TMenuItemDesktopProps) => {
  const className = `${s['link']} ${isHovered ? s['_hover'] : ''} ${isActive ? s['_active'] : ''}`;
  const linkContent = (
    <>
      {iconBefore && <span className={`${s['icon-before-wrapper']}`}>{iconBefore}</span>}
      <span className={`${s['label-wrapper']}`}>{label}</span>
      {children && (
        <span className={`${s['chevron']}`}>
          <IconChevronRight16 />
        </span>
      )}
    </>
  );

  return (
    <li
      className={`${s['wrapper']}`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      data-web-ui-test-id="MenuItemDesktop"
      data-test-item-type={children ? 'with-submenu' : 'without-submenu'}
    >
      <span onClick={onClick}>
        {url ? (
          <Link className={className} to={url}>
            {linkContent}
          </Link>
        ) : (
          <span className={className}>{linkContent}</span>
        )}
      </span>

      {isHovered && children}
    </li>
  );
};

/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TGetArticleListModel,
  IGetArticleListRequest,
  IMappers,
  IGetArticleListResponse,
  IGetArticleListResponseError,
  TGetArticleListResponse,
} from './types';

const defaultConfig: TGetArticleListModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'GET',
  microserviceName: 'journal',
  pathApi: '/v2/get-article-list/',
  hostType: 'api',
} as TGetArticleListModel;

function createGetArticleListModel(parameters: IGetArticleListRequest): TGetArticleListModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetArticleListOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IGetArticleListRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchGetArticleList<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IGetArticleListOptions<TResponse200, TResponse400>): Promise<TResponse200 | TResponse400 | TGetArticleListResponse> {
  const { response, statusCode } = await httpApi.fetch(createGetArticleListModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetArticleListResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IGetArticleListResponseError);
    }
  }

  return { response, statusCode } as TGetArticleListResponse;
}

export { defaultConfig, createGetArticleListModel, fetchGetArticleList };

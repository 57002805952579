import * as React from 'react';
import { ArrowL24 } from '@cian/ui-kit';

import * as s from './IconChevronDown24.css';

export const IconChevronDown24 = () => {
  return (
    <div className={s['wrapper']}>
      <ArrowL24 />
    </div>
  );
};

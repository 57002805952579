import { ERequestStatus } from '../../types/requestStatus';
import { TPagesMetaState } from '../../types/pagesMeta';
import { ESetPagesMetaActionType, TPagesMetaActions } from '../../actions/pagesMeta';

export const defaultState: TPagesMetaState = {
  status: ERequestStatus.Initial,
  items: [],
};

export const pagesMetaReducer = (state = defaultState, action: TPagesMetaActions) => {
  switch (action.type) {
    case ESetPagesMetaActionType.Set:
      return { ...state, items: action.payload, status: ERequestStatus.Succeed };

    default:
      return state;
  }
};

import * as React from 'react';

import { ICardParams } from '../Card';
import { RecommendedOffer } from '../RecommendedOffer';
import { IRecommendedOfferSchema } from '../../repositories/search-engine/entities/recommended_offers/RecommendedOfferSchema';

interface IRecommendedOffersParams {
  height?: ICardParams['height'];
  offers: IRecommendedOfferSchema[];
  onOfferClick(offer: IRecommendedOfferSchema): void;
}

/**
 * Список подобных статей
 */
export const RecommendedOffers = ({ offers, height, onOfferClick }: IRecommendedOffersParams) => (
  <>
    {offers.map((offer, index) => {
      const { title, formattedPrice, url, address, photoUrl, underground } = offer;

      return (
        <RecommendedOffer
          key={`recommended-offer-${index}`}
          width={'250px'}
          height={height}
          imageUrl={photoUrl || ''}
          title={title}
          formattedPrice={formattedPrice}
          offerUrl={url}
          address={address}
          underground={underground || undefined}
          onClick={() => onOfferClick(offer)}
        />
      );
    })}
  </>
);

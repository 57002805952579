import { transformItem } from './transformItem';
import { transformSubItem } from './transformSubItem';
import { findByPathRecursive } from './findByPathRecursive';
import { IApplicationState } from '../../../types/redux';
import { IMenuBlockItemsSchema } from '../../../repositories/journal/entities/menu/MenuBlockItemsSchema';
import { IMenuMainItemV2Schema } from '../../../repositories/journal/entities/menu/MenuMainItemV2Schema';
import { IMenuItemV2Schema } from '../../../repositories/journal/entities/menu/MenuItemV2Schema';

/**
 * Селектор выбора активного пункта/подпункта с трансформацией объекта
 */
export const selectActiveItems =
  (pathname: string) =>
  ({ menu }: IApplicationState) => {
    const group = findByPathRecursive<IMenuBlockItemsSchema>(menu.items, pathname);
    const item = group?.items && findByPathRecursive<IMenuMainItemV2Schema>(group.items, pathname);
    const subItem = item?.items && findByPathRecursive<IMenuItemV2Schema>(item.items, pathname);

    return {
      item: item && transformItem(item),
      subItem: subItem && transformSubItem(subItem),
    };
  };

import { IMenuRubrics, IRubric } from '../../shared/reducers/modules/rubrics_menu/rubrics_menu';

/**
 * Разворачивает рубрики из IMenuRubrics[] в IRubrics[], затем мапает массив, возвращая данные,
 * которые возвращает функция selector и превращает получившийся массмив в одномерный.
 */
export const getMenuRubricsData = (rubrics: IMenuRubrics[]) => (selector: <T>(item: IRubric) => IRubric) => {
  return [].concat.apply([], rubrics.map(section => section.items.map(selector)));
};

/**
 * Возвращает рубрику целиком
 */
export function selectRubric(item: IRubric): IRubric {
  return item;
}

/**
 * Возвращает pathname каждой рубрики
 */
export function selectPaths(item?: IRubric): string | undefined {
  return item ? item.pathname : undefined;
}

import React, { FC } from 'react';
import { AdfoxContextProvider } from '../../context/AdfoxContext';
import { PParams } from '../../../types';
import { AdfoxBannerContainer } from '../../../AdfoxBannerContainer';
import { TAdFoxBannerSizeProps } from '@cian/adfox-component';

const P_PARAMS: PParams = { p1: 'ddcgw', p2: 'fkhj' };

const SIZE: TAdFoxBannerSizeProps = { minHeight: '398px', width: '100%' };

export const AdfoxBannerMainPageArticleDesktop: FC = () => {
  return (
    <AdfoxContextProvider place="mainPageArticle" size={SIZE} pParams={P_PARAMS}>
      {() => <AdfoxBannerContainer />}
    </AdfoxContextProvider>
  );
};

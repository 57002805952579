import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useDeviceType } from '@cian/ui-kit';

import { MainNews } from '../../components/MainNews';
import { selectMainNews } from '../../selectors/mainNews';
import { TThunkDispatch } from '../../types/redux';
import { getMainNews, resetMainNews } from '../../actions/mainNews';
import { ERequestStatus } from '../../types/requestStatus';
import { MAIN_NEWS_REQUEST_LIMIT } from '../../constants';
import { useTimeoutEffect } from '../../utils';
import { selectListingAppendType } from '../../selectors/settings/selectListingAppendType';
import { Skeleton } from '../../components/Skeleton';

/** Главная статья и блок новостей на Главной */
export const MainNewsContainer = () => {
  const dispatch = useDispatch<TThunkDispatch>();
  const { search } = useLocation();
  const [withTag, setWithTag] = useState(new URLSearchParams(search).get('with-tag') || '');
  const deviceType = useDeviceType();
  const isPhone = () => deviceType === 'phone';
  const { items: mainNews, status: mainNewsStatus } = useSelector(selectMainNews);
  const appendType = useSelector(selectListingAppendType);
  const [isNewsSkeletonShown, setIsNewsSkeletonShown] = useState(
    mainNewsStatus === ERequestStatus.Loading && appendType === 'set',
  );

  /**
   * Запрашиваем список новостей, если еще ни разу не запрашивали (1)
   * или сменился with-tag в qs (2)
   */
  useEffect(() => {
    const qsWithTag = new URLSearchParams(search).get('with-tag') || '';

    if (qsWithTag !== withTag || mainNewsStatus === ERequestStatus.Initial) {
      dispatch(getMainNews({ limit: MAIN_NEWS_REQUEST_LIMIT, tag: qsWithTag })).finally();
    }

    if (qsWithTag !== withTag) {
      setWithTag(qsWithTag);
    }
  }, [dispatch, mainNewsStatus, search, withTag]);

  /** Создает небольшую задержку перед скрытием скелетона, когда элементов не найдено */
  useTimeoutEffect(() => {
    setIsNewsSkeletonShown(mainNewsStatus === ERequestStatus.Loading && appendType === 'set');
  }, [!isNewsSkeletonShown || mainNews?.length ? 0 : 500]);

  /** При выходе сбрасывает стор новостей */
  useEffect(() => {
    return () => {
      dispatch(resetMainNews());
    };
  }, [dispatch]);

  return isNewsSkeletonShown ? (
    <Skeleton width={'100%'} height={isPhone() ? '50vw' : 409} />
  ) : (
    <MainNews items={mainNews} />
  );
};

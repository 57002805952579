import { HttpBadStatusCodeError } from '@cian/peperrors/shared';

import { TThunkAction } from '../../../types/redux';
import { getArticleListLoading } from './getArticleListLoading';
import { getArticleListSucceed } from './getArticleListSucceed';
import { getArticleListAppendSucceed } from './getArticleListAppendSucceed';
import { getArticleListFailed } from './getArticleListFailed';
import {
  fetchGetArticleList,
  TGetArticleListResponse,
  IGetArticleListRequest,
} from '../../../repositories/journal/v2/get-article-list';

export interface IGetArticleListParams extends Omit<IGetArticleListRequest, 'b2b' | 'regionId'> {
  setType?: 'set' | 'append';
}

export const getArticleList = ({
  setType = 'set',
  ...otherProps
}: IGetArticleListParams): TThunkAction<Promise<void>> => {
  return async (dispatch, getState, context) => {
    const { httpApi, logger } = context;
    const {
      userNew: { isB2BModeEnabled },
      geo: { currentRegion },
    } = getState();

    dispatch(getArticleListLoading());

    try {
      const { response, statusCode }: TGetArticleListResponse = await fetchGetArticleList({
        httpApi,
        parameters: {
          regionId: currentRegion.id,
          b2b: isB2BModeEnabled,
          ...otherProps,
        },
      });

      if (statusCode !== 200 || !response?.data) {
        await Promise.reject(
          new HttpBadStatusCodeError('Не удалось загрузить список статей', statusCode, 'actions.getArticleList'),
        );

        return;
      }

      if (setType === 'append') {
        dispatch(getArticleListAppendSucceed({ meta: response.meta, items: response.data }));
      } else {
        dispatch(getArticleListSucceed({ meta: response.meta, items: response.data }));
      }
    } catch (e) {
      logger.error(e, {
        domain: 'actions.getArticleList',
      });

      dispatch(getArticleListFailed());
    }
  };
};

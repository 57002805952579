import { IPostRequest } from '../../../types/post_request';
import { IClientAddForm } from '../../../types/client_add_form';

export const ADD_QUESTION = 'add_question/ACTION';
export const ADD_QUESTION_REQUEST = 'add_question/ACTION_REQUEST';
export const ADD_QUESTION_SUCCESS = 'add_question/ACTION_SUCCESS';
export const ADD_QUESTION_FAILURE = 'add_question/ACTION_FAILURE';
export const EDIT_QUESTION = 'edit_question/ACTION';
export const EDIT_QUESTION_REQUEST = 'edit_question/ACTION_REQUEST';
export const EDIT_QUESTION_SUCCESS = 'edit_question/ACTION_SUCCESS';
export const EDIT_QUESTION_FAILURE = 'edit_question/ACTION_FAILURE';
export const POST_CARD_EDIT = 'post_card_edit/ACTION';
export const POST_CARD_EDIT_NOT = 'post_card_edit_not/ACTION';
export const CLEANUP_QUESTION = 'add_question/CLEANUP_QUESTION';

export const initialState: IClientAddForm = {
  item: undefined,
  isEdit: false,
  fetching: false,
  error: undefined,
};

const defaultAction = {
  type: '',
  payload: {
    data: {},
  },
  error: undefined,
};

export const addQuestionReducer = (state = initialState, action = defaultAction) => {
  switch (action.type) {
    case ADD_QUESTION_REQUEST:
      return Object.assign({}, state, {
        error: undefined,
        fetching: true,
      });

    case ADD_QUESTION_SUCCESS:
      return Object.assign({}, state, {
        item: action.payload.data,
        fetching: false,
      });

    case ADD_QUESTION_FAILURE:
      return Object.assign({}, state, {
        error: action.error,
        fetching: false,
      });

    case POST_CARD_EDIT:
      return Object.assign({}, state, {
        isEdit: true,
        fetching: false,
      });

    case POST_CARD_EDIT_NOT:
      return Object.assign({}, state, {
        isEdit: false,
        fetching: false,
      });

    case EDIT_QUESTION_REQUEST:
      return Object.assign({}, state, {
        error: undefined,
        fetching: true,
      });

    case EDIT_QUESTION_SUCCESS:
      return Object.assign({}, state, {
        item: action.payload.data,
        fetching: false,
      });

    case EDIT_QUESTION_FAILURE:
      return Object.assign({}, state, {
        error: action.error,
        fetching: false,
      });

    case CLEANUP_QUESTION:
      return Object.assign({}, state, {
        item: undefined,
        fetching: false,
        error: undefined,
      });

    default:
      return state;
  }
};

export const submitAddQuestion = (
  content: string,
  status: string,
  is_subscribed_to_comments: boolean,
  category: string,
  regions_id: number[],
  tags: string,
  title: string,
  image: string,
  userEmail: string,
  userName: string,
): IPostRequest => {
  return {
    type: ADD_QUESTION,
    method: 'POST',
    payload: {
      pathname: '/v1/add-question',
      data: {
        attributes: Object.assign({}, {
          content,
          status,
          is_subscribed_to_comments,
          category,
          regions_id,
          tags,
          title,
          image,
        }, userEmail ? {
          email: userEmail,
          first_name: userName,
        } : {}),
      },
    },
    request: true,
  };
};

export const submitEditQuestion = (
  id: number,
  content: string,
  status: string,
  is_subscribed_to_comments: boolean,
  category: string,
  regions_id: number[],
  tags: string,
  title: string,
  image: string,
): IPostRequest => {
  return {
    type: EDIT_QUESTION,
    method: 'POST',
    payload: {
      pathname: `/v1/edit-question`,
      params: [{
        name: 'id',
        value: id,
      }],
      data: {
        attributes: {
          content,
          status,
          is_subscribed_to_comments,
          category,
          regions_id,
          tags,
          title,
          image,
        },
      },
    },
    request: true,
  };
};

export const cleanupStorage = () => {
  return {
    type: CLEANUP_QUESTION,
  };
};

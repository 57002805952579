import React from 'react';

import * as s from './PostBlockquoteUserCardLayout.css';

interface IProps {
  children: React.ReactNode;
  isWhiteColor?: boolean;
}

/**
 * Стилевая обертка для карточки пользователя внутри Цитаты
 */
export const PostBlockquoteUserCardLayout = ({ children, isWhiteColor }: IProps) => {
  return <div className={`${s['wrapper']} ${isWhiteColor ? s['_white'] : ''}`}>{children}</div>;
};

import {
  IBreadCrumb,
  IBreadItem,
  IBreadCrumbAction,
} from '../../../types/bread_crumb';

export const BREAD_CRUMB = 'bread_crumb/ACTION';

export const initialState: IBreadCrumb = {
  path: [],
};

const defaultAction: IBreadCrumbAction = {
  type: '',
  payload: initialState,
};

export const breadCrumbReducer = (state = initialState, action = defaultAction) => {
  switch (action.type) {
    case BREAD_CRUMB:
      return Object.assign({}, state, action.payload);
    default:
      return state;
  }
};

export const setBreadCrumb = (path: IBreadItem[]) => {
  return {
    type: BREAD_CRUMB,
    payload: {
      path,
    },
  };
};

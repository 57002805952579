import { HttpBadStatusCodeError } from '@cian/peperrors/shared';

interface IThrowError {
  message: string;
  domain: string;
  statusCode: number;
}

/**
 * Выбрасывает исключение
 */
export const throwError = ({ message, domain, statusCode }: IThrowError) => {
  throw new HttpBadStatusCodeError(message, statusCode, domain);
};

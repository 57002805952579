import * as queryString from 'qs';

import { IPostRequest } from '../../../types/post_request';
import { IClientAddForm } from '../../../types/client_add_form';
import { IApplicationContext } from '../../../types/applicationContext';

export const ADD_BLOG = 'add_blog/ACTION';
export const ADD_BLOG_REQUEST = 'add_blog/ACTION_REQUEST';
export const ADD_BLOG_SUCCESS = 'add_blog/ACTION_SUCCESS';
export const ADD_BLOG_FAILURE = 'add_blog/ACTION_FAILURE';
export const EDIT_BLOG = 'edit_blog/ACTION';
export const EDIT_BLOG_REQUEST = 'edit_blog/ACTION_REQUEST';
export const EDIT_BLOG_SUCCESS = 'edit_blog/ACTION_SUCCESS';
export const EDIT_BLOG_FAILURE = 'edit_blog/ACTION_FAILURE';
export const POST_CARD_EDIT = 'post_card_edit/ACTION';
export const POST_CARD_EDIT_NOT = 'post_card_edit_not/ACTION';
export const CLEANUP_BLOG = 'add_blog/CLEANUP_BLOG';

export const initialState: IClientAddForm = {
  item: undefined,
  isEdit: false,
  fetching: false,
  error: undefined,
};

const defaultAction = {
  type: '',
  payload: {
    data: {},
  },
  error: undefined,
};

export const addBlogReducer = (state = initialState, action = defaultAction) => {
  switch (action.type) {
    case ADD_BLOG_REQUEST:
      return Object.assign({}, state, {
        error: undefined,
        fetching: true,
      });

    case ADD_BLOG_SUCCESS:
      return Object.assign({}, state, {
        item: action.payload.data,
        fetching: false,
      });

    case ADD_BLOG_FAILURE:
      return Object.assign({}, state, {
        error: action.error,
        fetching: false,
      });

    case POST_CARD_EDIT:
      return Object.assign({}, state, {
        isEdit: true,
        fetching: false,
      });

    case POST_CARD_EDIT_NOT:
      return Object.assign({}, state, {
        isEdit: false,
        fetching: false,
      });

    case EDIT_BLOG_REQUEST:
      return Object.assign({}, state, {
        error: undefined,
        fetching: true,
      });

    case EDIT_BLOG_SUCCESS:
      return Object.assign({}, state, {
        item: action.payload.data,
        fetching: false,
      });

    case EDIT_BLOG_FAILURE:
      return Object.assign({}, state, {
        error: action.error,
        fetching: false,
      });

    case CLEANUP_BLOG:
      return Object.assign({}, state, {
        item: undefined,
        fetching: false,
        error: undefined,
      });

    default:
      return state;
  }
};

export const submitAddBlog = (
  content: string,
  status: string,
  is_subscribed_to_comments: boolean,
  category: string,
  regions_id: number[],
  tags: string,
  title: string,
  image: string,
  userEmail: string,
  userName: string,
): IPostRequest => {
  return {
    type: ADD_BLOG,
    method: 'POST',
    payload: {
      pathname: '/v1/add-blog',
      data: {
        attributes: Object.assign(
          {},
          {
            content,
            status,
            is_subscribed_to_comments,
            category,
            regions_id,
            tags,
            title,
            image,
          },
          userEmail
            ? {
                email: userEmail,
                first_name: userName,
              }
            : {},
        ),
      },
    },
    request: true,
  };
};

export const submitEditBlog = (
  id: number,
  content: string,
  status: string,
  is_subscribed_to_comments: boolean,
  category: string,
  regions_id: number[],
  tags: string,
  title: string,
  image: string,
  image_clear: boolean,
): IPostRequest => {
  return {
    type: EDIT_BLOG,
    method: 'POST',
    payload: {
      pathname: `/v1/edit-blog`,
      data: {
        attributes: {
          content,
          status,
          is_subscribed_to_comments,
          category,
          regions_id,
          tags,
          title,
          image,
          image_clear,
        },
      },
      params: [
        {
          name: 'id',
          value: id,
        },
      ],
    },
    request: true,
  };
};

export const cleanupStorage = () => {
  return {
    type: CLEANUP_BLOG,
  };
};

export const uploadImage = (image: string) => {
  return (dispatch: any, getState: () => any, context: IApplicationContext) => {
    const {
      custom: { subdomain },
    } = context;

    return context.makeRequest({
      method: 'POST',
      uri: {
        subdomain,
        path: '/v1/add-image',
      },
      headers: [['Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8']],
      body: queryString.stringify({
        file: image,
      }),
    });
  };
};

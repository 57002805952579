import React, { useEffect, useState } from "react";
import { IRegionsItem } from '../../../../types/regions';

import * as styles from './List.css';
import { Group } from '../Group';
import { prepareGroups } from './utils';

export interface IList {
  /** Коллекция регионов */
  regions: IRegionsItem[];
  /** Активный регион */
  activeRegion: IRegionsItem;
  /** Эвент события клика по региону */
  onClick(region: IRegionsItem): void;
}

export type IRegionGroups = Record<string, IRegionsItem[]>

export const List: React.FC<IList> = ({ regions, activeRegion, onClick }) => {
  const [groups, setGroups] = useState<IRegionGroups>({} as IRegionGroups);

  /**
   * Группирует регионы по первой букве
   * { "A": [{}, {}, {}] }
   */
  useEffect(() => {
    setGroups(prepareGroups(regions));
  }, [regions]);

  return (
    <div className={styles['container']}>
      <div className={styles['groups']}>
        {Object.keys(groups).sort().map(title => (
          <Group
            key={title}
            title={title}
            regions={groups[title]}
            onClick={onClick}
            activeRegion={activeRegion}
          />
        ))}
      </div>
    </div>
  );
};

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, ModalWindow } from '@cian/ui-kit';

import { FindRealtorBanner } from '../../components/FindRealtorBanner';
import { ModalContent } from './components/ModalContent';
import { CIAN_URL, FindRealtorUTM } from '../../constants';
import { useEventTracking, useIntersectionObserver } from '../../utils';
import * as s from './FindRealtorBannerAndModalContainer.css';

/**
 * Баннер "Подобрать риелтора" с кнопкой вызова модалки
 */
export const FindRealtorBannerAndModalContainer = () => {
  const [isModalOpened, setIsModalOpened] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [isIntersectionObserverFrozen, setIsIntersectionObserverFrozen] = useState(false);
  const entry = useIntersectionObserver(wrapperRef, { freezeOnceVisible: isIntersectionObserverFrozen });
  const { trackEvent } = useEventTracking({ category: 'Application_to_realtor' });
  const bannerLocation = FindRealtorUTM.Listing;

  /** Трекает показ баннера однократно */
  useEffect(() => {
    if (isIntersectionObserverFrozen) {
      return;
    }

    if (entry?.isIntersecting) {
      trackEvent({
        action: 'Show',
        label: 'Banner',
        page: {
          extra: {
            banner_location: bannerLocation,
          },
        },
      });
      setIsIntersectionObserverFrozen(true);
    }
  }, [bannerLocation, entry, isIntersectionObserverFrozen, trackEvent]);

  /** Переключает состояние модалки и трекает, если показана */
  const toggleModal = useCallback(
    (value?: boolean) => {
      if (value || !isModalOpened) {
        trackEvent({
          action: 'Click',
          label: 'Banner',
          page: {
            extra: {
              banner_location: bannerLocation,
            },
          },
        });
      }

      setIsModalOpened(value !== undefined ? value : !isModalOpened);
    },
    [bannerLocation, isModalOpened, trackEvent],
  );

  /** Закрывает модалку и роутит на страницу Циана */
  const handleModalSubmit = useCallback(() => {
    const searchParams = new URLSearchParams({ label: bannerLocation });

    trackEvent({
      action: 'Click',
      label: 'InfoPopup',
      page: {
        extra: {
          banner_location: bannerLocation,
        },
      },
    });

    window.open(`${CIAN_URL}/application-form/?${searchParams.toString()}`, '_blank');
    toggleModal(false);
  }, [bannerLocation, toggleModal, trackEvent]);

  return (
    <>
      <div ref={wrapperRef} className={s['banner-wrapper']}>
        <FindRealtorBanner onClick={() => toggleModal()} />
      </div>

      <ModalWindow
        open={isModalOpened}
        title={'Риелтор поможет продать или купить сложные объекты'}
        size={'XS'}
        footer={
          <Button theme="fill_primary" size={'M'} fullWidth={true} onClick={handleModalSubmit}>
            Отправить заявку риелтору
          </Button>
        }
        fixed={false}
        maxWidth={400}
        escape
        onClose={() => toggleModal(false)}
      >
        <ModalContent />
      </ModalWindow>
    </>
  );
};

import React, { memo } from 'react';
import { AdfoxContextProvider } from '../../context/AdfoxContext';
import { PParams } from '../../../types';
import { AdfoxBannerContainer } from '../../../AdfoxBannerContainer';
import { TAdFoxBannerSizeProps } from '@cian/adfox-component';

const P_PARAMS: PParams = { p1: 'ddcgu', p2: 'y' };

const SIZE: TAdFoxBannerSizeProps = { minHeight: '500px', width: '100%' };

export const AdfoxBannerMainPageFooter = memo(function AdfoxBannerMainPageFooter() {
  return (
    <AdfoxContextProvider place="mainPageFooter" size={SIZE} pParams={P_PARAMS}>
      {() => <AdfoxBannerContainer />}
    </AdfoxContextProvider>
  );
});

export interface IExtractAndReplaceCollapsersItem {
  /** Заголовок */
  title: string;
  /** Полное HTML-тело iframe */
  content: string;
  /** Id коллапсера */
  id: string;
  /** Полное HTML-тело шаблона adfox */
  outerHtml: string;
}

export interface IExtractAndReplaceCollapsers {
  /** HTML-контент */
  html: string;
  /** Коллекция параметров коллапсера */
  items: IExtractAndReplaceCollapsersItem[];
}

/**
 * 1. Обрамляет collapser div'ом c id
 * 2. Вырезает данные коллапсера
 */
export const extractAndReplaceCollapsers = (html: string): IExtractAndReplaceCollapsers => {
  const pattern =
    /<div[^>]*?(?:class="collapse")>[^<]*?<div[^>]*?(?:class="collapse__title")>(.*?)<\/div>[^<]*?<div[^>]*?(?:class="collapse__content")>(.*?)<\/div>[^<]*?<\/div>/gis;
  const items = [] as IExtractAndReplaceCollapsersItem[];
  let match;

  if (!html) {
    return {
      html: '',
      items,
    };
  }

  while ((match = pattern.exec(html))) {
    items.push({
      outerHtml: match[0],
      title: match[1],
      content: match[2],
      id: `collapser-${items.length + 1}`,
    });
  }

  return {
    html: items.reduce((acc, item) => {
      return acc.replace(
        item.outerHtml,
        `<div id="${item.id}">${item.outerHtml.replace('class="collapse"', 'class="collapse _replaced"')}</div>`,
      );
    }, html),
    items,
  };
};

import * as React from 'react';
import { TAdFoxBannerTemplate } from '@cian/adfox-component';
import { deleteTextNode } from './helpers/deleteTextNode';
import styles from "./Wrapper.css";

export function bannerWrapper(
  Component: React.ComponentType<TAdFoxBannerTemplate>
) {
  return class Wrapper extends React.Component<TAdFoxBannerTemplate, {}> {
    componentDidMount() {
      const container = document.getElementById(this.props.containerId);

      if (container) {
        /**
         * Удаляет текст, который Adfox автоматически вставляет в контейнер.
         * PS: Adfox вставляет json ответа запроса прямо в див.
         * Текст не получается удалить при помощи ref.
         */
        const observer = new MutationObserver(deleteTextNode);

        observer.observe(container, {
          childList: true,
          characterData: true,
        });
      }
    }

    render() {
      return (
        <div id={this.props.containerId} className={styles['empty-wrapper']}>
          <Component {...this.props} />
        </div>
      );
    }
  };
}

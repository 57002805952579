import { IHttpApi } from '@cian/http-api/shared';

import { createMakeRequest as _createMakeRequest, IMakeRequest } from '../app/helpers/network_helper';

export function createMakeRequest(
  httpApi: IHttpApi,
  baseSchema: string,
  baseHost: string,
  basePath: string,
): IMakeRequest {
  return _createMakeRequest(httpApi, baseHost, baseSchema, basePath);
}

import React from 'react';
import { Link } from 'react-router-dom';
import { getTime } from '@cian/utils';

import { TMainNewsItem } from '../../../../types/mainNews';
import { formatDate } from '../../../../utils';
import { getDate } from './utils/getDate';
import * as s from './NewsItem.css';

/** Элемент списка */
export const NewsItem = ({ id, title, slug, datePublish, noIndex }: TMainNewsItem) => {
  const datePublishObject = new Date(datePublish);

  const date = (
    <span className={`${s['date']}`} title={`${formatDate(datePublishObject)} в ${getTime(datePublishObject)}`}>
      {getDate(datePublishObject)}
    </span>
  );

  const newsItem = (
    <>
      <Link to={`/novosti-${slug}-${id}/`} className={`${s['title']}`}>
        {title}
      </Link>

      {date}
    </>
  );

  return (
    <div className={`${s['wrapper']}`} data-web-ui-test-id="NewsItem">
      {noIndex ? <noindex>{newsItem}</noindex> : newsItem}
    </div>
  );
};

import React, { useCallback } from 'react';
import { LinkButton } from '@cian/ui-kit';
import { ILinkButtonProps } from '@cian/ui-kit/button/types/componentsProps';

import * as s from './Chip.css';

export interface IChipParams extends ILinkButtonProps {
  isActive?: boolean;
  onClick(e: React.MouseEvent): void;
  url: string;
}

/**
 * Компонент для тэгов (видоизмененная кнопка)
 */
export const Chip = ({ isActive, onClick, url, ...otherProps }: IChipParams) => {
  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      onClick(e);
    },
    [onClick],
  );

  return (
    <div className={`${s['wrapper']} ${isActive ? s['_active'] : ''}`}>
      <LinkButton {...otherProps} href={url} onClick={handleClick} />
    </div>
  );
};

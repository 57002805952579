import { IScrollComment, IScrollCommentAction } from '../../../types/scroll_comment';

export const SCROLL_COMMENT_FORM = 'scroll_comment_form/SUCCESS_ACTION';
export const SCROLL_COMMENT_REFRESH = 'scroll_comment/REFRESH_ACTION';
export const SCROLL_COMMENTS = 'scroll_comments/SUCCESS_ACTION';

export const initialState: IScrollComment = {
  isScrollForm: false,
  isScrollComments: false,
};

const defaultAction: IScrollCommentAction = {
  type: '',
  payload: initialState,
};

export const scrollCommentReducer = (state = initialState, action = defaultAction) => {
  switch (action.type) {
    case SCROLL_COMMENT_FORM:
      return Object.assign({}, state, { ...action.payload });

    case SCROLL_COMMENT_REFRESH:
      return Object.assign({}, state, { ...action.payload });

    case SCROLL_COMMENTS:
      return Object.assign({}, state,  { ...action.payload });

    default:
      return state;
  }
};

export const scrollCommentForm = (): IScrollCommentAction => {
  return {
    type: SCROLL_COMMENT_FORM,
    payload: { isScrollForm: true, isScrollComments: false },
  };
};

export const scrollCommentDisabled = () => {
  return {
    type: SCROLL_COMMENT_REFRESH,
    payload: { isScrollForm: false, isScrollComments: false },
  };
};

export const scrollComments = (): IScrollCommentAction => {
  return {
    type: SCROLL_COMMENTS,
    payload: { isScrollForm: false, isScrollComments: true },
  };
};

import {
  ISeoUrls,
  ISeoUrlsAction,
} from '../../../types/seo_urls';
import { IGetRequest } from '../../../types/get_request';

export const SEO_URLS = 'seo_urls/ACTION';
export const SEO_URLS_REQUEST = 'seo_urls/ACTION_REQUEST';
export const SEO_URLS_SUCCESS = 'seo_urls/ACTION_SUCCESS';
export const SEO_URLS_FAILURE = 'seo_urls/ACTION_FAILURE';

export const initialState: ISeoUrls = {
  type: null,
  data: [],
};

const defaultAction: ISeoUrlsAction = {
  type: '',
  payload: initialState,
};

export const seoUrlsReducer = (state = initialState, action = defaultAction) => {
  switch (action.type) {
    case SEO_URLS_REQUEST:
      return state;

    case SEO_URLS_SUCCESS:
      return Object.assign({}, state, {
        type: (action as any).additionalFields.type,
        data: action.payload.data,
      });

    case SEO_URLS_FAILURE:
      return Object.assign({}, state);

    case SEO_URLS:
      return Object.assign({}, state, action.payload);

    default:
      return state;
  }
};

export const getSeoUrls = (type: 'stati' | 'blogs' | 'forum-rieltorov', id: number): IGetRequest => {
  return {
    type: SEO_URLS,
    method: 'GET',
    payload: {
      pathname: '/v1/get-seo-urls',
      params: [{
        name: 'id',
        value: id,
      }],
    },
    request: true,
    additionalFields: {
      type,
    },
  };
};

export const resetSeoUrls = () => {
  return {
    type: SEO_URLS,
    payload: {
      type: null,
      data: [],
    },
  };
};

import { EType } from '../../../repositories/journal/entities/page_info/PageInfoSchema';

export interface IDefaultContentTypeOption {
  label: string;
  value: EType.MainPage | EType.News | EType.Articles | EType.Blogs | EType.Questions;
}

export const DEFAULT_CONTENT_TYPE_OPTIONS: IDefaultContentTypeOption[] = [
  { label: 'Все материалы', value: EType.MainPage },
  { label: 'Новости', value: EType.News },
  { label: 'Статьи', value: EType.Articles },
  { label: 'Блоги', value: EType.Blogs },
  { label: 'Вопросы риелтору', value: EType.Questions },
];

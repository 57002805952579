import { IPosts, IPostsAction } from '../../../types/posts';
import { IGetRequest } from '../../../types/get_request';
import { options } from '../seo/seo';

export const ARTICLES = 'articles/ACTION';
export const ARTICLES_REQUEST = 'articles/ACTION_REQUEST';
export const ARTICLES_SUCCESS = 'articles/ACTION_SUCCESS';
export const ARTICLES_FAILURE = 'articles/ACTION_FAILURE';
export const SET_CATEGORY = 'articles/SET_CATEGORY';

export const initialState: IPosts = {
  items: [],
  pages_count: 0,
  count: 0,
  currentCategory: '',
};

const defaultAction: IPostsAction = {
  type: '',
  payload: {
    data: [],
    meta: {
      pagination: {
        page: 0,
        pages: 0,
        count: 0,
      },
    },
  },
};

export const articlesReducer = (state = initialState, action = defaultAction) => {
  switch (action.type) {
    case ARTICLES_REQUEST:
      return state;

    case ARTICLES_SUCCESS:
      return Object.assign({}, state, {
        items: action.payload.data,
        pages_count: action.payload.meta.pagination.pages,
        count: action.payload.meta.pagination.count,
      });

    case ARTICLES_FAILURE:
      return Object.assign({}, state);

    case SET_CATEGORY:
      return Object.assign({}, state, { currentCategory: action.payload.category });

    default:
      return state;
  }
};

export const getArticles = (
    params: {
      page: number,
      ordering: string,
      tag: string,
      category?: string,
      regionID: string,
      search: string,
      rubricId?: number,
    }): IGetRequest => {
  const requestKeyMapper = {
    'page': 'page',
    'ordering': 'ordering',
    'tag': 'tag',
    'category': 'category',
    'regionID': 'region_id',
    'search': 'search',
    'rubricId': 'rubric_id',
  };
  const needEncodedURI = ['tag', 'q'];

  const requestParams = Object.keys(params).map((key: keyof typeof params) => ({
    name:  requestKeyMapper[key],
    value: needEncodedURI ? encodeURIComponent(params[key] as string) : params[key] as string,
  }));

  return {
    type: ARTICLES,
    method: 'GET',
    payload: {
      pathname: '/v1/get-article-list',
      params: requestParams,
    },
    request: true,
    ...options,
  };
};

export const setCategory = (category: string) => {
  return {
    type: SET_CATEGORY,
    payload: { category },
  };
};

import * as React from 'react';
import { Button, Checkbox, Input, TDeviceType, Tooltip } from '@cian/ui-kit';

import { Dialog } from '../dialog/dialog';
import { validateEmail } from '../../utils/validateEmail';
import { connect } from 'react-redux';
import { IUser } from '../../types/user';
import { ISubscribeToCommentsResponse } from '../../containers/post_card/post_card';
import { trackRegistration, trackShow, trackSubscribeWithNews } from './tracking';

import { disableScroll, enableScroll } from '../../../app/helpers/scroll';

import s from './dialogs.css';

interface IProps {
  shown: boolean;
  user: IUser;
  onClose(): void;
  onDigestSubmit(email: string, sub: boolean, isNews?: boolean): Promise<ISubscribeToCommentsResponse | void> | void;
  onDigestReset(): void;
  isSubscribed: boolean;
  error?: string;
  email: string;
  deviceType: TDeviceType;
}

interface ISubState {
  value: string;
  emailValid?: boolean;
  isNews: boolean;
  scrollPosition: number | undefined;
}

const fakeEmailRex = /@social\.cian\.ru$/;

export class SubscribeDialog extends React.Component<IProps, ISubState> {
  private static renderLicense() {
    return (
      <div className={s['c-digest-form-license']}>
        <span>При подписке вы принимаете условия </span>
        <a
          target="_blank"
          href="//www.cian.ru/help/about/rules/"
          className={s['c-digest-form-license-link']}
          rel="noreferrer"
        >
          Пользовательского соглашения
        </a>
        <span> и </span>
        <a
          target="_blank"
          href="//www.cian.ru/help/about/36526/"
          className={s['c-digest-form-license-link']}
          rel="noreferrer"
        >
          Политики конфиденциальности
        </a>
      </div>
    );
  }

  public constructor(props: IProps) {
    super(props);

    this.state = {
      value: props.email && !fakeEmailRex.test(props.email) ? props.email : '',
      emailValid: true,
      isNews: true,
      scrollPosition: undefined,
    };
  }

  public componentWillReceiveProps(nextProps: IProps) {
    if (nextProps.shown && nextProps.shown !== this.props.shown) {
      this.setState({
        scrollPosition: window.pageYOffset,
      });

      disableScroll();
    } else {
      enableScroll();

      if (this.state.scrollPosition) {
        window.scrollTo(0, this.state.scrollPosition);
      }
    }
  }

  public componentWillUpdate(nextProps: IProps) {
    if (nextProps.shown && nextProps.shown !== this.props.shown) {
      trackShow();
    }
  }

  public render() {
    return (
      <Dialog
        content={
          <div className={s['sub-dialog-content']}>
            <h2 className={`${s['sub-dialog-content-header']} ${s['desktop-content']}`}>Подписка на комментарии</h2>
            <div className={`${s['sub-dialog-content-text']} ${s['desktop-content']}`}>
              Мы будем присылать вам на почту все новые комментарии
              <span className={s['break-mobile-hidden']}>
                <br />
              </span>{' '}
              к этой публикации
            </div>
            <div className={s['sub-dialog-content-controls']}>
              {this.props.error
                ? this.renderFail()
                : this.props.isSubscribed
                ? this.renderSuccess()
                : this.renderForm()}
            </div>
          </div>
        }
        shown={this.props.shown}
        onClose={() => this.props.onClose()}
      />
    );
  }

  private renderForm() {
    return (
      <div className={`${s['c-digest-form']}`}>
        <h2 className={`${s['sub-dialog-content-header']} ${s['mobile-content']}`}>Подписка на комментарии</h2>
        <div className={`${s['sub-dialog-content-text']} ${s['mobile-content']}`}>
          Мы будем присылать вам на почту все новые комментарии
          <span className={s['break-mobile-hidden']}>
            <br />
          </span>{' '}
          к этой публикации
        </div>
        <div className={s['c-digest-form-container']}>
          <Tooltip
            placement="bottom"
            arrow={true}
            title={this.state.emailValid ? '' : 'Укажите email'}
            open={!this.state.emailValid}
            onClose={() => this.onCLoseTooltip()}
          >
            <Input
              onChange={(_, value: string) => this.onChange(value)}
              invalid={!this.state.emailValid}
              value={this.state.value}
              disabled={!!this.props.email}
              placeholder="Ваша электронная почта"
              autoFocus={true}
            />
          </Tooltip>
          <Button
            theme="fill_primary"
            size={this.props.deviceType === 'phone' ? 'M' : 'XS'}
            onClick={() => this.submitEmail()}
          >
            Подписаться
          </Button>
          <Checkbox
            label="Хочу получать новости Cian.ru"
            onChange={(_, value) => this.handleCheckboxChange(value)}
            checked={this.state.isNews}
          />
          {!this.props.user.userIdentifications && SubscribeDialog.renderLicense()}
        </div>
      </div>
    );
  }

  private handleCheckboxChange = (value: boolean) => {
    this.setState({
      isNews: value,
    });
  };

  private renderSuccess() {
    return (
      <div>
        <span className={s['c-digest-success-header']}>Подписка оформлена!</span>
        <span className={s['c-digest-success-text']}>
          Мы будем присылать письма на
          <span className={s['c-digest-email']}>{this.state.value}</span>
        </span>
      </div>
    );
  }

  private renderFail() {
    return (
      <div>
        <span className={`${s['c-digest-fail-text']} ${s['desktop-content']}`}>
          <b>Что-то пошло не так, попробуйте подписаться еще раз</b>
        </span>
        <h2
          className={`
              ${s['sub-dialog-content-header']}
              ${s['c-digest-fail-text']}
              ${s['mobile-content']}`}
        >
          Что-то пошло не так...
        </h2>
        <div className={`${s['sub-dialog-fail-text']} ${s['mobile-content']}`}>Попробуйте подписаться еще раз</div>
        <Button
          theme="fill_error_primary"
          size={this.props.deviceType === 'phone' ? 'M' : 'XS'}
          onClick={() => this.props.onDigestReset()}
          fullWidth={true}
        >
          Повторить
        </Button>
      </div>
    );
  }

  private onChange(email: string) {
    this.setState({
      value: email,
      emailValid: true,
    });
  }

  private onCLoseTooltip() {
    this.setState({
      ...this.state,
      emailValid: true,
    });
  }

  private submitEmail() {
    if (validateEmail(this.state.value)) {
      const digestResponse = this.props.onDigestSubmit(this.state.value, this.state.isNews);

      if (digestResponse) {
        digestResponse.then(res => {
          const attributes = res && res.data && res.data.attributes;

          if (attributes) {
            if (this.state.isNews) {
              trackSubscribeWithNews();
            }

            if (attributes.password) {
              trackRegistration();
            }
          }
        });
      }
    } else {
      this.setState({
        value: this.state.value,
        emailValid: false,
      });
    }
  }
}

export const SubscribeDialogContainer = connect<
  Pick<IProps, 'user'>,
  {},
  Pick<
    IProps,
    'shown' | 'onClose' | 'onDigestSubmit' | 'onDigestReset' | 'isSubscribed' | 'error' | 'email' | 'deviceType'
  >
>((state: { user: IUser }) => {
  return {
    user: state.user,
  };
})(SubscribeDialog);

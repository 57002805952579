import { IPosts, IPostsAction } from '../../../types/posts';
import { IGetRequest } from '../../../types/get_request';
import { options } from '../seo/seo';
import { staticMetaPropsArray, staticMetaProps } from './config';
import { choiceOfSeo } from '../../../../app/helpers/choice_of_seo_helper';

export const BLOGS = 'blogs/ACTION';
export const BLOGS_REQUEST = 'blogs/ACTION_REQUEST';
export const BLOGS_SUCCESS = 'blogs/ACTION_SUCCESS';
export const BLOGS_FAILURE = 'blogs/ACTION_FAILURE';
export const SET_CATEGORY = 'blogs/SET_CATEGORY';

export const initialState: IPosts = {
  items: [],
  pages_count: 0,
  count: 0,
  currentCategory: '',
};

const defaultAction: IPostsAction = {
  type: '',
  payload: {
    data: [],
    meta: {
      pagination: {
        page: 0,
        pages: 0,
        count: 0,
      },
    },
  },
};

export const blogsReducer = (state = initialState, action = defaultAction) => {
  switch (action.type) {
    case BLOGS_REQUEST:
      return state;

    case BLOGS_SUCCESS:
      return Object.assign({}, state, {
        items: action.payload.data,
        pages_count: action.payload.meta.pagination.pages,
        count: action.payload.meta.pagination.count,
      });

    case BLOGS_FAILURE:
      return Object.assign({}, state);

    case SET_CATEGORY:
      return Object.assign({}, state, { currentCategory: action.payload.category });

    default:
      return state;
  }
};

export const getBlogs = (
    params: {
      page: number,
      ordering: string,
      tag: string,
      category: string,
      authorID: string,
      regionID: string,
      search: string,
    }): IGetRequest => {
  return {
    type: BLOGS,
    method: 'GET',
    payload: {
      pathname: '/v1/get-blog-list',
      params: [{
        name: 'page',
        value: params.page,
      }, {
        name: 'ordering',
        value: params.ordering,
      }, {
        name: 'category',
        value: params.category,
      }, {
        name: 'tag',
        value: encodeURIComponent(params.tag),
      }, {
        name: 'author_id',
        value: params.authorID,
      }, {
        name: 'region_id',
        value: params.regionID,
      }, {
        name: 'q',
        value: encodeURIComponent(params.search),
      }],
    },
    request: true,
    ...options,
    staticMetaProps: choiceOfSeo(staticMetaPropsArray, params.category),
  };
};

export const getMyBlog = (
    params: {
      page: number,
      ordering: string,
      tag: string,
      category: string,
    }): IGetRequest => {
  return {
    type: BLOGS,
    method: 'GET',
    payload: {
      pathname: '/v1/my-blog-list',
      params: [{
        name: 'page',
        value: params.page,
      }, {
        name: 'ordering',
        value: params.ordering,
      }, {
        name: 'category',
        value: params.category,
      }, {
        name: 'tag',
        value: encodeURIComponent(params.tag),
      }],
    },
    request: true,
    ...options,
    staticMetaProps,
  };
};

export const setCategory = (category: string) => {
  return {
    type: SET_CATEGORY,
    payload: { category },
  };
};

import React, { useCallback, useRef } from 'react';
import { Switch, useDeviceType, ModalWindow } from '@cian/ui-kit';
import { useDispatch, useSelector } from 'react-redux';

import { B2BModeSwitcher } from '../../components/B2BModeSwitcher';
import { requestAuthentication, setIsB2BForbiddingMessageShown, tryToToggleIsB2BModeEnabled } from '../../actions/user';
import { TThunkDispatch } from '../../types/redux';
import {
  selectIsB2BModeEnabled,
  isUserAuthenticatedSelect,
  isB2BForbiddingMessageShownSelect,
} from '../../selectors/user';
import { toggleMobileMenu } from '../../actions/menu';
import * as s from './B2BModeSwitcher.css';

/**
 * Переключатель режима Про (b2b/b2c)
 */
export const B2BModeSwitcherContainer = () => {
  const dispatch = useDispatch<TThunkDispatch>();
  const isB2BModeEnabled = useSelector(selectIsB2BModeEnabled);
  const isUserAuthenticated = useSelector(isUserAuthenticatedSelect);
  const isB2BForbiddingMessageShown = useSelector(isB2BForbiddingMessageShownSelect);
  const deviceType = useDeviceType();
  const handleChangeTimerRef = useRef<ReturnType<typeof setTimeout>>();
  const isDesktop = () => deviceType === 'desktop';

  /**
   * Обрабатывает событие смены состояния переключателя
   * с эффектом debounce (обрабатывает не чаще, чем раз в N ms)
   */
  const handleChange = useCallback(() => {
    if (handleChangeTimerRef.current) {
      return;
    }

    // эффект debounce
    handleChangeTimerRef.current = setTimeout(() => {
      clearTimeout(handleChangeTimerRef.current);
      handleChangeTimerRef.current = undefined;
    }, 1000);

    if (!isUserAuthenticated) {
      dispatch(
        requestAuthentication({
          autoRefresh: false,
          onSuccess: tryToToggleIsB2BModeEnabled,
        }),
      );

      return;
    }

    return dispatch(tryToToggleIsB2BModeEnabled());
  }, [dispatch, isUserAuthenticated, handleChangeTimerRef]);

  /** Обрабатывает событие закрытия запрещающей модалки */
  const handleCloseModal = useCallback(() => {
    // Закрываем модалку
    dispatch(setIsB2BForbiddingMessageShown(false));
    // Схлопываем меню
    dispatch(toggleMobileMenu(false));
  }, [dispatch]);

  return (
    <>
      {isDesktop() ? (
        <B2BModeSwitcher checked={isB2BModeEnabled} onChange={handleChange} />
      ) : (
        <Switch checked={isB2BModeEnabled} onChange={handleChange} />
      )}
      <ModalWindow
        open={isB2BForbiddingMessageShown}
        size={'XS'}
        title={'Упс...'}
        content={
          <div className={`${s['modal-content']}`}>
            Вход в раздел для B-пользователей только для залогиненных профи. Пожалуйста, войдите в свой бизнес-аккаунт
          </div>
        }
        fixed={false}
        maxWidth={500}
        print
        escape
        onClose={handleCloseModal}
      />
    </>
  );
};

import { IAdvertising, IAdvertisingAction } from '../../../types/advertising';
import { IGetRequest } from '../../../types/get_request';

export const ADVERTISING = 'advertising/ACTION';
export const ADVERTISING_REQUEST = 'advertising/ACTION_REQUEST';
export const ADVERTISING_SUCCESS = 'advertising/ACTION_SUCCESS';
export const ADVERTISING_FAILURE = 'advertising/ACTION_FAILURE';

const initialState: IAdvertising = {
  items: [],
};

const defaultAction: IAdvertisingAction = {
  type: '',
  payload: {
    items: [],
  },
};

export const advertisingReducer = (state = initialState, action = defaultAction) => {
  switch (action.type) {
    case ADVERTISING_REQUEST:
      return state;

    case ADVERTISING_SUCCESS:
      return Object.assign({}, state, {
        items: action.payload.items,
      });

    case ADVERTISING_FAILURE:
      return Object.assign({}, state);

    default:
      return state;
  }
};

export const getAdvertising = (request = true): IGetRequest => {
  return {
    type: ADVERTISING,
    method: 'GET',
    payload: {
      pathname: '/v2/58b2925a100000a814c3a934',
    },
    request,
  };
};

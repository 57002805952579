import { HttpBadStatusCodeError } from '@cian/peperrors/shared';

import { TThunkAction } from '../../../types/redux';
import { getBlogListLoading } from './getBlogListLoading';
import { getBlogListSucceed } from './getBlogListSucceed';
import { getBlogListAppendSucceed } from './getBlogListAppendSucceed';
import { getBlogListFailed } from './getBlogListFailed';
import {
  fetchGetBlogList,
  TGetBlogListResponse,
  IGetBlogListRequest,
} from '../../../repositories/journal/v2/get-blog-list';

export interface IGetBlogListParams extends Omit<IGetBlogListRequest, 'b2b' | 'regionId'> {
  setType?: 'set' | 'append';
}

export const getBlogList = ({ setType = 'set', ...otherProps }: IGetBlogListParams): TThunkAction<Promise<void>> => {
  return async (dispatch, getState, context) => {
    const { httpApi, logger } = context;
    const {
      userNew: { isB2BModeEnabled },
      geo: { currentRegion },
    } = getState();

    dispatch(getBlogListLoading());

    try {
      const { response, statusCode }: TGetBlogListResponse = await fetchGetBlogList({
        httpApi,
        parameters: {
          regionId: currentRegion.id,
          b2b: isB2BModeEnabled,
          ...otherProps,
        },
      });

      if (statusCode !== 200) {
        await Promise.reject(
          new HttpBadStatusCodeError('Не удалось загрузить список блогов', statusCode, 'actions.getBlogList'),
        );

        return;
      }

      if (setType === 'append') {
        dispatch(getBlogListAppendSucceed({ meta: response.meta, items: response.data }));
      } else {
        dispatch(getBlogListSucceed({ meta: response.meta, items: response.data }));
      }
    } catch (e) {
      logger.error(e, {
        domain: 'actions.getBlogList',
      });

      dispatch(getBlogListFailed());
    }
  };
};

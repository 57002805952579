import { IJournal, IJournalAction } from '../../../types/journal';
import { IGetRequest } from '../../../types/get_request';
import { options } from '../seo/seo';
import { staticMetaProps } from './config';

export const JOURNAL = 'journal/ACTION';
export const JOURNAL_REQUEST = 'journal/ACTION_REQUEST';
export const JOURNAL_SUCCESS = 'journal/ACTION_SUCCESS';
export const JOURNAL_FAILURE = 'journal/ACTION_FAILURE';

export const initialState: IJournal = {
  items: [],
  pages_count: 0,
  count: 0,
};

const defaultAction: IJournalAction = {
  type: '',
  payload: {
    data: [],
    meta: {
      pagination: {
        page: 0,
        pages: 0,
        count: 0,
      },
    },
  },
};

export const journalReducer = (state = initialState, action = defaultAction) => {
  switch (action.type) {
    case JOURNAL_REQUEST:
      return state;

    case JOURNAL_SUCCESS:
      return Object.assign({}, state, {
        items: action.payload.data,
        pages_count: action.payload.meta.pagination.pages,
        count: action.payload.meta.pagination.count,
      });

    case JOURNAL_FAILURE:
      return Object.assign({}, state);

    default:
      return state;
  }
};

export const getJournal = (
    page: number,
    ordering: string,
    tag: string,
    search: string,
    regionID: string,
): IGetRequest => {
  return {
    type: JOURNAL,
    method: 'GET',
    payload: {
      pathname: '/v1/get-journal-list',
      params: [{
        name: 'page',
        value: page,
      }, {
        name: 'ordering',
        value: ordering,
      }, {
        name: 'tag',
        value: encodeURIComponent(tag),
      }, {
        name: 'q',
        value: encodeURIComponent(search),
      }, {
        name: 'region_id',
        value: regionID,
      }],
    },
    request: true,
    ...options,
    staticMetaProps,
  };
};

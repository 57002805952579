import React from 'react';

import * as s from './DocumentListingItemLayout.css';

interface IDocumentListingItemLayout {
  children: React.ReactNode;
}

/**
 * Стилевая обертка для элемента списка документов
 */
export const DocumentListingItemLayout = ({ children }: IDocumentListingItemLayout) => {
  return <div className={s['wrapper']}>{children}</div>;
};

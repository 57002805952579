/**
 * Проверяет и правит номер страницы для листинга
 */
export const preparePageNumber = (page: string) => {
  const pagePrepared = Number(page);

  if (!pagePrepared || pagePrepared < 0) {
    return 1;
  }

  return pagePrepared;
};

/* eslint-disable */

//tslint:disable

import { IModel, IModelResponse } from '@cian/http-api/shared/model';
import { IBlogQuestionListDataV2Schema } from '../../entities/journal/BlogQuestionListDataV2Schema';
import { IJournalQuestionsMetaV2Schema } from '../../entities/journal/JournalQuestionsMetaV2Schema';

export type TGetQuestionListModel = IModel<IGetQuestionListRequest, TGetQuestionListResponse>;

export interface IGetQuestionListRequest {
  /** ID автора **/
  authorId?: number | null;
  /** Аудитория **/
  b2b?: boolean | null;
  /** Название категории **/
  category?: string | null;
  /** ID автора комментария **/
  commentUserId?: number | null;
  /** Лимит **/
  limit?: number | null;
  /** Сдвиг **/
  offset?: number | null;
  /** Сортировка **/
  ordering?: EOrdering | null;
  /** ID региона **/
  regionId?: number | null;
  /** Название тега **/
  tag?: string | null;
}

export type TGetQuestionListResponse = IGetQuestionListResponse200 | IGetQuestionListResponse400;

export interface IGetQuestionListResponse200 extends IModelResponse<IGetQuestionListResponse> {
  statusCode: 200;
}

export interface IGetQuestionListResponse400 extends IModelResponse<IGetQuestionListResponseError> {
  statusCode: 400;
}

export interface IGetQuestionListResponse {
  /** Данные **/
  data: IBlogQuestionListDataV2Schema[];
  /** Мета данные **/
  meta: IJournalQuestionsMetaV2Schema;
}

export interface IGetQuestionListResponseError {
  errors: any[];
  message: string;
}

export type TResponses = IGetQuestionListResponse | IGetQuestionListResponseError;

export enum EOrdering {
  /** Обсуждаемое **/
  Comments_count = 'comments_count',
  /** Дата публикации **/
  Date_publish = 'date_publish',
  /** Понравившееся **/
  Likes_count = 'likes_count',
  /** Популярное **/
  Num_views = 'num_views',
}

export interface IMappers<TResponse200, TResponse400> {
  200(response: IGetQuestionListResponse): TResponse200;
  400(response: IGetQuestionListResponseError): TResponse400;
}

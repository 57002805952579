import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import { IReduxStore } from '../../../shared/types/redux';
import { IApplicationContext } from '../../../shared/types/applicationContext';
import { App } from '../../../shared/containers/App';

interface IDependencies {
  store: IReduxStore;
  context: IApplicationContext;
}

export function renderApplication(dependencies: IDependencies) {
  const { store, context } = dependencies;

  const projectName = context.config.getStrict<string>('projectName');
  const rootElement = document.getElementById(projectName);

  if (!rootElement) {
    throw new Error(`Unable to find element #${projectName}`);
  }

  ReactDOM.hydrate(
    <BrowserRouter>
      <App context={context} reduxStore={store} />
    </BrowserRouter>,
    rootElement,
  );
}

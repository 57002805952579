import React from 'react';

import * as s from './SidebarCard.css';

export interface ISidebarCardParams {
  children: React.ReactNode;
}

/**
 * Декоратор блоков в сайдбаре
 */
export const SidebarCard = ({ children }: ISidebarCardParams) => <div className={s['wrapper']}>{children}</div>;

import { IAdvices, IJournalAction } from '../../../types/journal';
import { IGetRequest } from '../../../types/get_request';
import { options } from '../seo/seo';

export const ANSWERS = 'answers/ACTION';
export const ANSWERS_REQUEST = 'answers/ACTION_REQUEST';
export const ANSWERS_SUCCESS = 'answers/ACTION_SUCCESS';
export const ANSWERS_FAILURE = 'answers/ACTION_FAILURE';

export const initialState: IAdvices = {
  items: [],
  pages_count: 0,
  count: 0,
};

const defaultAction: IJournalAction = {
  type: '',
  payload: {
    data: [],
    meta: {
      pagination: {
        page: 0,
        pages: 0,
        count: 0,
      },
    },
  },
};

export const profileAnswersReducer = (state = initialState, action = defaultAction) => {
  switch (action.type) {
    case ANSWERS_REQUEST:
      return state;

    case ANSWERS_SUCCESS:
      return Object.assign({}, state, {
        items: action.payload.data,
        pages_count: action.payload.meta.pagination.pages,
        count: action.payload.meta.pagination.count,
      });

    case ANSWERS_FAILURE:
      return Object.assign({}, state);

    default:
      return state;
  }
};

export const getProfileAnswers = (
    authorID: number,
  ): IGetRequest => {
  return {
    type: ANSWERS,
    method: 'GET',
    payload: {
      pathname: '/v1/get-comment-list',
      params: [
        {
          name: 'type',
          value: 'question',
        }, {
          name: 'author_id',
          value: authorID,
        },
      ],
    },
    request: true,
    ...options,
  };
};

import React from 'react';

import { Skeleton } from '../Skeleton';
import * as s from './ListingItem.css';

/**
 * Скелетон для элемента списка
 */
export const SkeletonListingItem = () => {
  return (
    <div className={s['wrapper']}>
      <div className={s['content-wrapper']}>
        <div className={s['headnote']}>
          <Skeleton width={70} />
          <Skeleton width={90} />
          <Skeleton width={40} />
          <Skeleton width={40} />
        </div>

        <div className={s['title']}>
          <Skeleton width={'90%'} height={24} margin={'0 0 14px 0'} />
          <Skeleton width={'80%'} height={24} margin={'0 0 20px 0'} />
        </div>

        <div className={s['subtitle']}>
          <Skeleton width={'90%'} />
          <Skeleton width={'80%'} />
          <Skeleton width={'10%'} />
        </div>
      </div>

      <div className={s['image-wrapper']}>
        <Skeleton width={'100%'} height={200} />
      </div>
    </div>
  );
};

import React, { forwardRef } from 'react';

import * as s from './Scrolling.css';

export interface IScrollingParams {
  children: React.ReactNode;
}

/** Панель прокрутки */
export const Scrolling = forwardRef<HTMLDivElement, IScrollingParams>(({ children }, ref) => {
  if (!children) {
    return null;
  }

  return (
    <div className={s['wrapper']} ref={ref}>
      <div className={s['inner-wrapper']}>{children}</div>
    </div>
  );
});

Scrolling.displayName = 'Scrolling';

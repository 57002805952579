import { IPostRequest } from '../../../types/post_request';
import { ISubscribe, ISubscribeAction } from '../../../types/subscribe';

export const SUBSCRIBE = 'subscribe/ACTION';
export const SUBSCRIBE_REQUEST = 'subscribe/ACTION_REQUEST';
export const SUBSCRIBE_SUCCESS = 'subscribe/ACTION_SUCCESS';
export const SUBSCRIBE_FAILURE = 'subscribe/ACTION_FAILURE';

export const initialState: ISubscribe = {
  status: '',
  statusCode: 0,
};

export interface ISubmitDigestResponse {
  data: {
    type: string;
    id: string;
    attributes?: {
      email?: string;
      userId?: number;
      password?: string;
    };
  };
}

const defaultAction: ISubscribeAction = {
  type: '',
  payload: {
    data: {
      status: '',
    },
  },
  err: {
    statusCode: 0,
  },
};

export const subscribeReducer = (state = initialState, action = defaultAction) => {
  switch (action.type) {
    case SUBSCRIBE_REQUEST:
      return Object.assign({}, initialState);
    case SUBSCRIBE_SUCCESS:
      return Object.assign({}, state, { status: 'ok' });
    case SUBSCRIBE_FAILURE:
      return Object.assign({}, state, { statusCode: (action.err && action.err.statusCode) || 400 });
    default:
      return state;
  }
};

export const subscribe = (email: string): IPostRequest => {
  return {
    type: SUBSCRIBE,
    method: 'POST',
    payload: {
      pathname: '/v2/add-digest',
      data: {
        type: 'digest',
        attributes: {
          email,
        },
      },
    },
    request: true,
  };
};

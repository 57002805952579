import * as React from 'react';
import { connect } from 'react-redux';

import { IUser } from '../../types/user';
import { ISeoUrls } from '../../types/seo_urls';
import { SeoUrls as SeoUrlsComponent } from '../../components/seo_urls/seo_urls';

interface IProps {
  user: IUser;
  showDesktop: boolean;
  showMobile: boolean;
  seoUrls: ISeoUrls;
}

export class SeoUrlsContainer extends React.Component<IProps> {
  public render() {
    return (
      <div>
        {
          this.props.seoUrls.data.length > 0 &&
            <SeoUrlsComponent
              title={this.getTitle()}
              user={this.props.user}
              showDesktop={this.props.showDesktop}
              showMobile={this.props.showMobile}
              items={this.props.seoUrls.data} />
        }
      </div>
    );
  }

  private getTitle() {
    const map = {
      stati: 'статьи',
      blogs: 'блоги',
      'forum-rieltorov': 'вопросы',
    };

    return `Ещё${this.props.seoUrls.type ? ` ${map[this.props.seoUrls.type]}` : ''}`;
  }
}

export const SeoUrlsDesktop = connect<IProps, {}, {}>(
  (state: { seoUrls: ISeoUrls, user: IUser }) => ({
    seoUrls: state.seoUrls,
    showMobile: false,
    showDesktop: true,
    user: state.user,
  }),
)(SeoUrlsContainer);

export const SeoUrlsMobile = connect<IProps, {}, {}>(
  (state: { seoUrls: ISeoUrls, user: IUser }) => ({
    seoUrls: state.seoUrls,
    showMobile: true,
    showDesktop: false,
    user: state.user,
  }),
)(SeoUrlsContainer);

import { ERequestStatus } from '../../types/requestStatus';
import { TSearchingResultsState } from '../../types/searchingResults';
import {
  EGetSearchingResultsActionType,
  EResetSearchingResultsActionType,
  TSearchingResultsActions,
} from '../../actions/searchingResults';
import { EQType, EType } from '../../repositories/journal/entities/search/SearchMetaSchema';

export const defaultState: TSearchingResultsState = {
  status: ERequestStatus.Initial,
  meta: {
    b2b: null,
    offset: 0,
    limit: 18,
    qType: EQType.Journal,
    type: EType.All,
    total: 0,
    regionId: null,
  },
  items: [],
};

export const searchingResultsReducer = (state = defaultState, action: TSearchingResultsActions) => {
  switch (action.type) {
    case EGetSearchingResultsActionType.Loading:
      return { ...state, status: ERequestStatus.Loading };

    case EGetSearchingResultsActionType.Succeed:
      return { ...state, ...action.payload, status: ERequestStatus.Succeed };

    case EGetSearchingResultsActionType.AppendSucceed:
      return {
        ...state,
        meta: action.payload.meta,
        items: [...state.items, ...action.payload.items],
        status: ERequestStatus.Succeed,
      };

    case EGetSearchingResultsActionType.Failed:
      return { ...state, status: ERequestStatus.Failed };

    case EResetSearchingResultsActionType.Reset:
      return defaultState;

    default:
      return state;
  }
};

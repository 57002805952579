import * as React from 'react';
import { UIText2 } from '@cian/ui-kit/typography';

import { IRegionsItem } from '../../../../types/regions';
import * as styles from './GeoSuggest.css';

export interface IGeoSuggestProps {
  /** Коллекция регионов для выпадашки */
  suggests: IRegionsItem[];
  /** Эвент события выбора региона */
  onSelect(region: IRegionsItem): void;
}

export function GeoSuggest({ suggests, onSelect }: IGeoSuggestProps) {
  return (
    <div className={styles['suggests']}>
      {suggests.map(region => {
        return (
          <div
            key={region.id}
            className={styles['suggest']}
            onClick={() => onSelect(region)}
          >
            <UIText2>{region.displayName}</UIText2>
          </div>
        );
      })}
    </div>
  );
}

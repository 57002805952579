import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Image } from '@cian/ui-kit';

import { useEventListener } from '../../utils';
import * as s from './BeforeAfterGallery.css';

export interface IBeforeAfterGallery {
  /** Картинка До */
  imageBefore: string;
  /** Картинка После */
  imageAfter: string;
}

const CONTROL_WIDTH = 46;

/**
 * Галерея Было/Стало
 */
export const BeforeAfterGallery = ({ imageBefore, imageAfter }: IBeforeAfterGallery) => {
  const [isDraggable, setIsDraggable] = useState(false);
  const [offsetLeft, setOffsetLeft] = useState(0);
  const afterRef = useRef<HTMLDivElement>(null);
  const controlRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const calc = (clientX?: number) => {
    const wrapper = wrapperRef?.current;

    if (!wrapper) {
      return;
    }

    const galleryOffsetLeft = wrapper.offsetLeft;
    const galleryWidth = wrapper.offsetWidth;
    const clientPositionLeft = clientX === undefined ? galleryWidth / 2 : clientX - galleryOffsetLeft;

    if (clientPositionLeft <= CONTROL_WIDTH / 2) {
      setOffsetLeft(0);

      return;
    } else if (clientPositionLeft >= galleryWidth - CONTROL_WIDTH / 2) {
      setOffsetLeft(((galleryWidth - CONTROL_WIDTH) * 100) / galleryWidth);

      return;
    }

    setOffsetLeft(((clientPositionLeft - CONTROL_WIDTH / 2) * 100) / galleryWidth);
  };

  useEffect(() => {
    calc();
  }, []);

  /**
   * Передвигает шторку
   */
  const handleMove = useCallback(
    (e: React.MouseEvent<HTMLDivElement> & React.TouchEvent<HTMLDivElement>) => {
      if (!isDraggable) {
        return;
      }

      calc(e?.touches?.[0]?.clientX || e.clientX);
    },
    [isDraggable],
  );

  useEventListener('resize', () => calc());

  return (
    <div
      ref={wrapperRef}
      className={s['wrapper']}
      onMouseMove={handleMove}
      onTouchMove={handleMove}
      onMouseLeave={() => setIsDraggable(false)}
      onTouchCancel={() => setIsDraggable(false)}
    >
      {/* Невидимая картинка для создания пропорций */}
      <div className={s['back-image']}>
        <Image src={imageAfter} height={'100%'} objectFit={'cover'} onLoad={() => calc()} />
      </div>

      <Image loading={'lazy'} src={imageBefore} height={'100%'} borderRadius={0} objectFit={'cover'} />

      <Image
        loading={'lazy'}
        width={`calc(${100 - offsetLeft}% - ${CONTROL_WIDTH / 2}px)`}
        ref={afterRef}
        src={imageAfter}
        height={'100%'}
        borderRadius={0}
        objectFit={'cover'}
      />

      <div
        ref={controlRef}
        className={s['control']}
        onMouseDown={() => setIsDraggable(true)}
        onMouseUp={() => setIsDraggable(false)}
        onTouchStart={() => setIsDraggable(true)}
        onTouchEnd={() => setIsDraggable(false)}
        style={{ left: `${offsetLeft}%`, width: CONTROL_WIDTH }}
      />
    </div>
  );
};

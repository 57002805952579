import React, { useEffect, useState } from 'react';

import { YoutubePlayButton } from '../Icons';
import { EYoutubePreviewType } from '../../types/youtube';
import { useIsMounted } from '../../utils';
import { checkImageExists } from '../../utils/checkImageExists';
import * as s from './YoutubeVideoPlayer.css';

interface IYoutubeVideoParams {
  id: string;
}

export const YoutubeVideoPlayer = ({ id }: IYoutubeVideoParams) => {
  const [isShown, setIsShown] = useState(false);
  const [backgroundImage, setBackgroundImage] = useState(
    `https://img.youtube.com/vi/${id}/${EYoutubePreviewType.Default}.jpg`,
  );
  const [previewType, setPreviewType] = useState(EYoutubePreviewType.MaxRes);
  const isMounted = useIsMounted();

  /**
   * Проверяет существование превью по типу и сохраняет урл.
   * Если превью для текущего типа нет,
   * меняет тип (понижает качество) и повторяет заново
   */
  useEffect(() => {
    const imgUrl = `https://img.youtube.com/vi/${id}/${previewType}.jpg`;

    checkImageExists(imgUrl, isExists => {
      if (!isMounted) {
        return;
      }

      if (isExists) {
        setBackgroundImage(imgUrl);

        return;
      }

      const values = Object.values(EYoutubePreviewType);
      const nextValue = values[values.indexOf(previewType) + 1];

      if (nextValue) {
        setPreviewType(nextValue);
      }
    });
  }, [id, isMounted, previewType]);

  return (
    <div className={s['wrapper']}>
      {isShown ? (
        <div className={s['iframe-wrapper']}>
          <iframe
            width="640"
            height="360"
            src={`//www.youtube.com/embed/${id}?wmode=opaque&autoplay=1&mute=1`}
            frameBorder="0"
            allowFullScreen
          />
        </div>
      ) : (
        <div className={s['image-wrapper']} onClick={() => setIsShown(true)}>
          <div className={s['button']}>
            <YoutubePlayButton />
          </div>

          <div className={s['background-image']} style={{ backgroundImage: `url("${backgroundImage}")` }} />
        </div>
      )}
    </div>
  );
};

import * as React from 'react';

export const IconMessage16 = () => {
  return (
    // eslint-disable-next-line react/forbid-elements
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g clipPath="url(#clip0_2767_21241)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 7.02005C16 10.8971 12.4183 14.0401 8 14.0401C7.19913 14.0401 6.42575 13.9368 5.6962 13.7446L3.28668e-05 16L1.39465 10.9816C0.514736 9.85409 0 8.48982 0 7.02005C0 3.14298 3.58172 0 8 0C12.4183 0 16 3.14298 16 7.02005ZM4.29289 6.31091C4.68342 5.91927 5.31658 5.91927 5.70711 6.31091C6.09763 6.70256 6.09763 7.33753 5.70711 7.72918C5.31658 8.12082 4.68342 8.12082 4.29289 7.72918C3.90237 7.33753 3.90237 6.70256 4.29289 6.31091ZM10.2929 7.72918C10.6834 8.12082 11.3166 8.12082 11.7071 7.72918C12.0976 7.33753 12.0976 6.70256 11.7071 6.31091C11.3166 5.91927 10.6834 5.91927 10.2929 6.31091C9.90237 6.70256 9.90237 7.33753 10.2929 7.72918ZM8.70711 7.72918C9.09763 7.33753 9.09763 6.70256 8.70711 6.31091C8.31658 5.91927 7.68342 5.91927 7.29289 6.31091C6.90237 6.70256 6.90237 7.33753 7.29289 7.72918C7.68342 8.12082 8.31658 8.12082 8.70711 7.72918Z"
          fill="#CED1D7"
        />
      </g>
      <defs>
        <clipPath id="clip0_2767_21241">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

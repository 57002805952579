import React, { useCallback } from 'react';
import { useDeviceType, Button } from '@cian/ui-kit';
import { useHistory } from 'react-router-dom';

import { AddQuestionPortalButton } from './components/AddQuestionPortalButton';

/**
 * Кнопка "Задать вопрос"
 */
export const AddQuestionContainer = () => {
  const deviceType = useDeviceType();
  const isPhone = () => deviceType === 'phone';
  const history = useHistory();
  const AddQuestionPortalButtonTransition = 200;

  /**
   * Роутит на страницу "Задать вопрос"
   */
  const handleAddQuestionClick = useCallback(() => {
    history.push('/add-vopros/');
  }, [history]);

  return isPhone() ? (
    <AddQuestionPortalButton transitionDuration={AddQuestionPortalButtonTransition} onClick={handleAddQuestionClick} />
  ) : (
    <Button size={'M'} onClick={handleAddQuestionClick}>
      Задать вопрос
    </Button>
  );
};

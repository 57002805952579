import { ERequestStatus } from '../../../../types/requestStatus';
import { IQuickSearchingResults, TQuickSearchingResultsState } from '../../../../types/quickSearchingResults';

export enum ESearchingActionType {
  Loading = 'searching/loading',
  Succeed = 'searching/succeed',
  Failed = 'searching/failed',
  Reset = 'searching/reset',
}

interface ISearchingLoading {
  type: ESearchingActionType.Loading;
}

interface ISearchingSucceed {
  type: ESearchingActionType.Succeed;
  payload: IQuickSearchingResults;
}

interface ISearchingFailed {
  type: ESearchingActionType.Failed;
}

interface ISearchingReset {
  type: ESearchingActionType.Reset;
}

type TSearchingActions = ISearchingLoading | ISearchingSucceed | ISearchingFailed | ISearchingReset;

export const defaultState: TQuickSearchingResultsState = {
  status: ERequestStatus.Initial,
  meta: {
    total: 0,
    limit: 18,
    b2b: null,
    regionId: null,
  },
  items: [],
};

export const searchingReducer = (state = defaultState, action: TSearchingActions) => {
  switch (action.type) {
    case ESearchingActionType.Loading:
      return { ...state, status: ERequestStatus.Loading };

    case ESearchingActionType.Succeed:
      return { ...state, ...action.payload, status: ERequestStatus.Succeed };

    case ESearchingActionType.Failed:
      return { ...state, status: ERequestStatus.Failed };

    case ESearchingActionType.Reset:
      return defaultState;

    default:
      return state;
  }
};

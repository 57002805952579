import * as React from 'react';

export const DogAndClouds = () => (
  // eslint-disable-next-line react/forbid-elements
  <svg width="400" height="192" viewBox="0 0 400 192" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M183.191 48.4977C183.079 48.4977 182.967 48.4977 182.855 48.4977C182.943 47.9694 183.007 47.4412 183.007 46.8969C183.007 40.8939 177.492 36.0355 170.689 36.0355C169.576 36.0355 168.504 36.1796 167.479 36.4197C163.661 22.9249 150.919 13 135.775 13C119.815 13 106.513 24.0215 103.527 38.6368C99.9014 37.5162 96.0115 36.8999 91.9534 36.8999C72.6478 36.8999 57 50.7068 57 67.7313C57 84.7558 74.0085 97.21 93.3141 97.21C101.806 97.21 174.683 96.6977 183.199 96.6977C198.286 96.6977 210.517 85.9084 210.517 72.5977C210.517 59.2871 198.286 48.4977 183.199 48.4977H183.191Z"
      fill="white"
    />
    <path
      d="M301.777 73.5285C301.725 73.5285 301.672 73.5285 301.62 73.5285C301.661 73.2825 301.691 73.0366 301.691 72.7831C301.691 69.988 299.123 67.7258 295.955 67.7258C295.437 67.7258 294.938 67.7929 294.461 67.9047C292.683 61.6213 286.75 57 279.699 57C272.268 57 266.074 62.1318 264.684 68.937C262.995 68.4153 261.184 68.1283 259.295 68.1283C250.305 68.1283 243.02 74.5571 243.02 82.4841C243.02 90.411 250.939 96.21 259.928 96.21C263.882 96.21 297.815 95.9715 301.78 95.9715C308.806 95.9715 314.5 90.9477 314.5 84.75C314.5 78.5523 308.806 73.5285 301.78 73.5285H301.777Z"
      fill="white"
    />
    <path
      d="M242.453 112.154C240.589 77.0771 237.666 77.3274 238.954 65.7662C240.849 48.6978 262.526 18.4231 249.458 15.879C242.213 14.4689 233.702 28.1298 233.702 28.1298C233.702 28.1298 221.104 16.5329 209.537 23.8027C197.624 31.2922 193.981 56.4427 194.952 69.7C195.534 77.6748 202.15 79.0337 202.15 79.0337C202.15 79.0337 199.254 84.8527 190.482 90.5081C172.979 101.788 170.823 109.717 169.474 117.35C168.892 120.655 169.669 128.047 169.669 128.047L242.407 127.853C242.407 127.853 242.99 122.213 242.459 112.149L242.453 112.154Z"
      fill="#036CFA"
    />
    <path
      d="M211.868 20.0375C211.638 20.0375 211.433 19.8843 211.372 19.6544C211.352 19.5829 210.933 17.8765 212.062 16.5687C213.155 15.3017 214.825 15.5111 214.897 15.5214C215.178 15.5571 215.372 15.8177 215.336 16.0936C215.301 16.3745 215.04 16.5636 214.764 16.5329C214.739 16.5329 213.553 16.4001 212.833 17.2328C212.057 18.132 212.353 19.3887 212.358 19.3989C212.424 19.6748 212.261 19.9507 211.985 20.0171C211.944 20.0273 211.903 20.0324 211.857 20.0324L211.868 20.0375Z"
      fill="#231F20"
    />
    <path
      d="M203.27 23.5112C203.204 23.5112 203.132 23.4959 203.066 23.4703C202.805 23.3579 202.688 23.0616 202.8 22.8011C202.831 22.7347 203.536 21.1254 205.227 20.7729C206.862 20.4408 208.068 21.6158 208.114 21.6669C208.313 21.8662 208.313 22.188 208.114 22.3873C207.914 22.5865 207.592 22.5865 207.393 22.3873C207.378 22.3771 206.52 21.5443 205.431 21.7691C204.272 22.0092 203.74 23.1894 203.735 23.1996C203.654 23.3937 203.465 23.5061 203.265 23.5061L203.27 23.5112Z"
      fill="#231F20"
    />
    <path
      d="M226.359 34.8503C226.662 34.5235 226.302 33.697 225.555 33.0043C224.809 32.3116 223.958 32.015 223.654 32.3418C223.351 32.6686 223.711 33.4951 224.458 34.1878C225.204 34.8806 226.055 35.1772 226.359 34.8503Z"
      fill="#231F20"
    />
    <path
      d="M221.214 37.0041C221.517 36.6773 221.158 35.8508 220.411 35.1581C219.664 34.4654 218.813 34.1688 218.51 34.4956C218.207 34.8225 218.566 35.649 219.313 36.3417C220.06 37.0344 220.911 37.331 221.214 37.0041Z"
      fill="#231F20"
    />
    <path
      d="M224.429 52.5191C224.352 52.5191 224.28 52.5191 224.204 52.5191C220.342 52.4169 218.395 48.5087 218.313 48.3401C218.191 48.0846 218.293 47.7832 218.548 47.6606C218.799 47.538 219.105 47.6402 219.233 47.8956C219.248 47.9314 220.996 51.4104 224.235 51.4973C228.281 51.6148 230.375 48.1817 230.396 48.151C230.539 47.9109 230.855 47.8292 231.096 47.9722C231.336 48.1153 231.417 48.432 231.274 48.6721C231.177 48.8305 228.919 52.5242 224.434 52.5242L224.429 52.5191Z"
      fill="#231F20"
    />
    <path
      d="M231.46 48.8202C231.225 48.8202 230.964 48.7691 230.709 48.6414C230.08 48.3247 229.907 47.6197 229.896 47.2569C229.896 46.976 230.116 46.7409 230.397 46.7358C230.673 46.7409 230.913 46.9555 230.918 47.2365C230.918 47.2365 230.939 47.6146 231.163 47.7269C231.511 47.9006 231.848 47.6912 231.863 47.681C232.098 47.5328 232.415 47.5992 232.568 47.8291C232.722 48.0641 232.66 48.3758 232.43 48.529C232.236 48.6619 231.874 48.8151 231.455 48.8151L231.46 48.8202Z"
      fill="#231F20"
    />
    <path
      d="M218.09 48.3908C217.783 48.3908 217.507 48.3091 217.334 48.2223C217.078 48.0996 216.971 47.7931 217.094 47.5428C217.216 47.2873 217.523 47.1801 217.773 47.3027C217.773 47.3027 218.121 47.4559 218.32 47.3027C218.631 47.0677 218.596 46.6692 218.596 46.6641C218.56 46.3831 218.754 46.1277 219.035 46.0919C219.316 46.0561 219.571 46.2503 219.607 46.5312C219.653 46.8991 219.566 47.645 218.933 48.1201C218.667 48.3193 218.361 48.3908 218.085 48.3908H218.09Z"
      fill="#231F20"
    />
    <path
      d="M213.523 68.0496C213.523 68.0496 218.484 69.5107 226.168 69.7048C233.458 69.8887 239.246 68.2948 239.246 68.2948C239.246 68.2948 239.093 70.0471 239.246 71.8198C239.394 73.4955 239.537 75.1763 239.537 75.1763C239.537 75.1763 235.21 76.8009 226.168 76.9031C217.416 77.0002 211.582 74.4713 211.582 74.4713"
      fill="white"
    />
    <path
      d="M225.666 77.4142C217.19 77.4142 211.612 75.0386 211.377 74.9365C211.116 74.8241 210.999 74.5226 211.111 74.2621C211.223 74.0016 211.525 73.884 211.78 73.9964C211.836 74.022 217.625 76.4946 226.156 76.3874C233.559 76.3056 237.779 75.1868 238.99 74.8138L238.734 71.861C238.637 70.7421 238.657 69.6438 238.688 68.9541C236.926 69.3628 231.985 70.359 226.151 70.2108C218.488 70.0167 213.584 68.5965 213.379 68.5352C213.108 68.4534 212.955 68.1724 213.032 67.9017C213.114 67.6309 213.395 67.4725 213.665 67.5543C213.716 67.5696 218.662 68.9949 226.177 69.184C233.303 69.3679 239.051 67.8097 239.107 67.7944C239.271 67.7484 239.439 67.7893 239.572 67.8966C239.7 68.0038 239.766 68.1673 239.756 68.3308C239.756 68.3461 239.608 70.0627 239.756 71.769L240.047 75.1255C240.068 75.3554 239.935 75.5699 239.72 75.6466C239.541 75.713 235.204 77.3069 226.177 77.404C226.008 77.404 225.84 77.404 225.671 77.404L225.666 77.4142Z"
      fill="#231F20"
    />
    <path
      d="M203.096 79.5852C202.774 79.5852 202.447 79.5749 202.11 79.5545C197.027 79.2224 194.814 74.272 194.436 69.7507C194.074 65.4032 195.545 52.7794 195.606 52.2429C195.637 51.962 195.892 51.7576 196.173 51.7934C196.454 51.824 196.654 52.0795 196.623 52.3604C196.608 52.4882 195.106 65.4185 195.458 69.6639C196.112 77.5314 200.766 78.4408 202.176 78.5327C206.927 78.8444 210.069 76.8468 211.781 72.438C215.469 62.9254 210.161 41.6473 210.11 41.4327C210.039 41.1569 210.207 40.881 210.478 40.8146C210.749 40.7482 211.03 40.9117 211.096 41.1824C211.111 41.2386 212.475 46.6693 213.354 53.2289C214.545 62.0825 214.335 68.6677 212.726 72.8007C210.979 77.3015 207.74 79.5749 203.091 79.5749L203.096 79.5852Z"
      fill="#231F20"
    />
    <path
      d="M225.901 89.2462C226.197 93.5274 228.792 96.6948 232.389 96.6948C236.573 96.6948 237.488 92.7406 237.191 88.4595C236.895 84.1783 235.894 80.3569 231.664 80.8423C228.557 81.1999 225.605 84.9651 225.906 89.2462H225.901Z"
      fill="#231F20"
    />
    <path
      d="M232.39 97.2003C228.594 97.2003 225.718 93.9409 225.391 89.2765C225.089 84.9239 227.986 80.7449 231.598 80.3311C233.069 80.1625 234.29 80.4741 235.23 81.2558C237.08 82.7935 237.519 85.8945 237.693 88.4234C237.933 91.8463 237.371 94.3138 236.022 95.7596C235.128 96.72 233.902 97.2054 232.38 97.2054L232.39 97.2003ZM232.369 81.3068C232.16 81.3068 231.945 81.3222 231.72 81.3477C228.977 81.6645 226.126 85.0925 226.412 89.2101C226.699 93.3125 229.156 96.1836 232.39 96.1836C233.631 96.1836 234.581 95.8158 235.281 95.0597C236.41 93.8489 236.896 91.5806 236.676 88.4949C236.513 86.1704 236.129 83.3299 234.576 82.0425C233.984 81.5521 233.258 81.312 232.364 81.312L232.369 81.3068Z"
      fill="#231F20"
    />
    <path
      d="M233.763 96.4553C236.879 96.2379 239.163 92.5935 238.865 88.3152C238.566 84.0369 235.799 80.7449 232.683 80.9622C229.567 81.1795 227.283 84.824 227.581 89.1022C227.88 93.3805 230.648 96.6726 233.763 96.4553Z"
      fill="white"
    />
    <path
      d="M233.467 96.9755C231.909 96.9755 230.417 96.2092 229.222 94.794C227.986 93.3227 227.219 91.3149 227.066 89.1386C226.749 84.5867 229.253 80.6887 232.645 80.4486C234.321 80.3362 235.945 81.1025 237.222 82.6198C238.459 84.0911 239.225 86.0989 239.378 88.2752C239.695 92.8271 237.192 96.7251 233.799 96.9653C233.687 96.9704 233.58 96.9755 233.467 96.9755ZM232.972 81.455C232.885 81.455 232.803 81.455 232.716 81.4652C229.886 81.6645 227.807 85.072 228.088 89.062C228.226 91.0237 228.905 92.8271 230.004 94.1299C231.066 95.3918 232.384 96.0355 233.728 95.9384C236.558 95.7392 238.637 92.3316 238.357 88.3416C238.219 86.3799 237.539 84.5765 236.441 83.2737C235.45 82.0936 234.229 81.455 232.977 81.455H232.972Z"
      fill="#231F20"
    />
    <path
      d="M240.85 19.6746C240.85 19.6746 241.779 23.3172 245.371 25.5088C249.31 27.9151 252.227 27.8436 252.227 27.8436C252.227 27.8436 255.256 18.3106 250.985 16.2466C246.714 14.1827 240.85 19.6746 240.85 19.6746Z"
      fill="#231F20"
    />
    <path
      d="M243.256 50.8178C243.184 50.8178 243.107 50.8025 243.041 50.7718C242.786 50.6543 242.673 50.3478 242.796 50.0923L247.097 40.9016C247.184 40.733 254.934 25.4629 252.676 18.8419C252.278 17.6771 251.608 16.8801 250.628 16.4203C246.995 14.7089 242.065 18.8675 238.571 22.6582C234.688 26.8678 231.689 31.573 231.659 31.619C231.505 31.8591 231.194 31.9255 230.954 31.7773C230.714 31.6241 230.647 31.3125 230.795 31.0723C231.296 30.2805 243.164 11.7662 251.062 15.4957C252.288 16.0729 253.156 17.0896 253.642 18.5149C256.038 25.5446 248.344 40.7126 248.017 41.3563L243.721 50.5317C243.634 50.7156 243.45 50.828 243.256 50.828V50.8178Z"
      fill="#231F20"
    />
    <path
      d="M213.62 56.4785C213.62 56.4785 219.398 72.4077 212.843 79.0389C208.419 83.5142 199.52 82.6355 199.52 82.6355L201.757 79.5242C201.757 79.5242 207.597 79.6264 210.8 75.9277C214.59 71.5494 213.62 56.4785 213.62 56.4785Z"
      fill="#231F20"
    />
    <path d="M239.522 67.8707L238.806 67.1504L238.699 68.2845L239.522 67.8707Z" fill="#231F20" />
    <path
      d="M233.19 27.6698L230.712 31.343L230.59 25.7744C230.59 25.7744 231.356 26.2751 232.087 26.8064C232.577 27.164 233.195 27.6698 233.195 27.6698H233.19Z"
      fill="#231F20"
    />
    <path
      d="M232.68 84.9495C232.496 84.9495 232.318 84.8524 232.226 84.6736C232.093 84.4233 232.19 84.1168 232.44 83.9839C232.547 83.9277 235.158 82.482 234.509 78.8394C233.947 75.6822 231.163 75.4727 231.045 75.4676C230.764 75.4523 230.55 75.207 230.565 74.9261C230.581 74.6451 230.826 74.4305 231.102 74.4458C231.137 74.4458 234.806 74.6911 235.516 78.6606C236.302 83.0644 232.951 84.8729 232.915 84.8933C232.839 84.9342 232.757 84.9495 232.68 84.9495Z"
      fill="#231F20"
    />
    <path
      d="M229.028 82.8089C228.905 82.8089 228.778 82.7629 228.68 82.671C228.64 82.6352 227.71 81.7361 227.546 79.713C227.378 77.6031 227.914 76.7499 227.975 76.6631C228.134 76.4281 228.451 76.3719 228.686 76.5302C228.915 76.6886 228.977 77.0002 228.824 77.2301C228.803 77.2608 228.43 77.9351 228.563 79.6364C228.691 81.2354 229.355 81.9047 229.38 81.9353C229.58 82.1295 229.585 82.4564 229.391 82.6557C229.288 82.7629 229.156 82.814 229.023 82.814L229.028 82.8089Z"
      fill="#231F20"
    />
    <path
      d="M289.475 61.5769C279.405 64.5911 276.963 67.028 273.954 77.0974C270.94 67.028 268.503 64.586 258.434 61.5769C268.503 58.5627 270.945 56.1258 273.954 46.0564C276.968 56.1258 279.405 58.5678 289.475 61.5769Z"
      fill="#036CFA"
    />
    <path d="M258.399 109.257H163.074V175.022H258.399V109.257Z" fill="#FFDEFF" />
    <path
      d="M258.536 175.166C258.255 175.166 258.025 174.936 258.025 174.655V109.763H163.584V174.655C163.584 174.936 163.354 175.166 163.073 175.166C162.792 175.166 162.562 174.936 162.562 174.655V109.252C162.562 108.971 162.792 108.741 163.073 108.741H258.536C258.817 108.741 259.047 108.971 259.047 109.252V174.655C259.047 174.936 258.817 175.166 258.536 175.166Z"
      fill="#231F20"
    />
    <path
      d="M231.802 175.743C231.521 175.743 231.291 175.513 231.291 175.232V109.252C231.291 108.971 231.521 108.741 231.802 108.741C232.083 108.741 232.313 108.971 232.313 109.252V175.232C232.313 175.513 232.083 175.743 231.802 175.743Z"
      fill="#231F20"
    />
    <path d="M251.42 174.849V125.227H239.496V175.043L251.42 174.849Z" fill="white" />
    <path
      d="M239.495 175.554C239.362 175.554 239.235 175.503 239.138 175.406C239.041 175.309 238.984 175.176 238.984 175.043V125.233C238.984 124.952 239.214 124.722 239.495 124.722H251.419C251.7 124.722 251.93 124.952 251.93 125.233V174.854C251.93 175.135 251.705 175.36 251.429 175.365L239.505 175.559H239.495V175.554ZM240.006 125.738V174.522L250.908 174.348V125.743H240.006V125.738Z"
      fill="#231F20"
    />
    <path d="M183.529 123.884V159.272H217.957V123.884C217.957 123.884 183.529 123.501 183.529 123.884Z" fill="white" />
    <path
      d="M217.951 159.783H183.523C183.242 159.783 183.012 159.553 183.012 159.272V123.884C183.012 123.746 183.068 123.608 183.165 123.516C183.364 123.322 183.696 122.99 217.956 123.373C218.237 123.373 218.462 123.603 218.462 123.884V159.272C218.462 159.553 218.232 159.783 217.951 159.783ZM184.033 158.761H217.44V124.39C205 124.252 186.91 124.134 184.033 124.323V158.767V158.761Z"
      fill="#231F20"
    />
    <path
      d="M200.839 159.206C200.558 159.206 200.328 158.976 200.328 158.695V123.689C200.328 123.408 200.558 123.178 200.839 123.178C201.12 123.178 201.35 123.408 201.35 123.689V158.695C201.35 158.976 201.12 159.206 200.839 159.206Z"
      fill="#231F20"
    />
    <path
      d="M200.453 141.32H183.722C183.441 141.32 183.211 141.09 183.211 140.809C183.211 140.528 183.441 140.298 183.722 140.298H200.453C200.734 140.298 200.964 140.528 200.964 140.809C200.964 141.09 200.734 141.32 200.453 141.32Z"
      fill="#231F20"
    />
    <path
      d="M270.558 175.84H145.929C145.648 175.84 145.418 175.61 145.418 175.329C145.418 175.048 145.648 174.818 145.929 174.818H270.558C270.839 174.818 271.069 175.048 271.069 175.329C271.069 175.61 270.839 175.84 270.558 175.84Z"
      fill="#231F20"
    />
    <path d="M216.316 109.41L221.221 125.855L223.096 108.833L216.316 109.41Z" fill="#231F20" />
    <path d="M236.945 109.553L241.85 125.999L243.725 108.976L236.945 109.553Z" fill="#231F20" />
    <path
      d="M232.956 107.725C232.956 107.725 237.283 95.8109 246.612 95.9948C251.516 96.0919 254.168 99.6527 255.072 102.054C257.591 108.736 258.439 122.152 256.033 129.074C255.333 131.087 252.666 132.538 249.974 132.538C248.007 132.538 244.298 131.864 242.663 128.788C240.282 124.308 241.411 108.69 241.411 108.69L232.951 107.73L232.956 107.725Z"
      fill="#036CFA"
    />
    <path
      d="M250.265 133.146C249.325 133.146 244.539 132.952 242.505 129.125C242.372 128.875 242.469 128.569 242.715 128.436C242.965 128.303 243.272 128.395 243.404 128.645C245.162 131.956 249.428 132.124 250.26 132.124C252.835 132.124 255.18 130.811 255.839 128.998C258.312 122.193 257.336 108.849 254.879 102.33C253.903 99.7397 251.221 96.6897 246.883 96.6029C246.817 96.6029 246.751 96.6029 246.684 96.6029C237.958 96.6029 233.759 107.883 233.718 108.001C233.621 108.266 233.33 108.404 233.064 108.307C232.799 108.21 232.661 107.919 232.758 107.653C232.804 107.531 233.892 104.567 236.16 101.584C239.205 97.5838 242.929 95.4892 246.904 95.5862C251.752 95.6833 254.746 99.0858 255.834 101.977C258.353 108.655 259.344 122.356 256.8 129.355C255.987 131.593 253.3 133.156 250.26 133.156L250.265 133.146Z"
      fill="#231F20"
    />
    <path
      d="M253.25 132.518C252.974 132.518 252.749 132.303 252.739 132.027C252.728 131.746 252.948 131.506 253.229 131.496C253.229 131.496 253.699 131.399 254.067 130.346C254.828 128.155 254.476 125.416 254.471 125.391C254.435 125.11 254.629 124.854 254.91 124.819C255.186 124.783 255.446 124.977 255.482 125.258C255.497 125.381 255.87 128.262 255.027 130.684C254.419 132.431 253.377 132.518 253.26 132.518C253.255 132.518 253.25 132.518 253.239 132.518H253.25Z"
      fill="#231F20"
    />
    <path
      d="M250.003 133.074C249.728 133.074 249.503 132.86 249.493 132.584C249.482 132.303 249.702 132.068 249.983 132.058C249.983 132.058 250.453 131.961 250.821 130.908C251.587 128.701 251.388 125.682 251.388 125.651C251.367 125.37 251.582 125.125 251.863 125.105C252.149 125.084 252.389 125.299 252.41 125.58C252.42 125.713 252.624 128.834 251.786 131.24C251.178 132.987 250.136 133.069 250.019 133.074C250.014 133.074 250.009 133.074 249.998 133.074H250.003Z"
      fill="#231F20"
    />
    <path
      d="M246.566 132.272C246.29 132.272 246.065 132.058 246.055 131.782C246.045 131.501 246.264 131.266 246.545 131.255C246.545 131.255 247.015 131.158 247.383 130.106C248.145 127.914 247.802 125.549 247.797 125.528C247.756 125.248 247.945 124.992 248.226 124.946C248.502 124.91 248.768 125.094 248.809 125.375C248.824 125.483 249.192 128.016 248.349 130.443C247.741 132.19 246.699 132.272 246.581 132.277C246.576 132.277 246.571 132.277 246.561 132.277L246.566 132.272Z"
      fill="#231F20"
    />
    <path
      d="M212.185 107.725C212.185 107.725 216.512 95.8109 225.841 95.9948C230.745 96.0919 233.396 99.6527 234.301 102.054C236.819 108.736 237.667 122.152 235.261 129.074C234.561 131.087 231.894 132.538 229.202 132.538C227.235 132.538 223.526 131.864 221.891 128.788C219.511 124.308 220.64 108.69 220.64 108.69L212.18 107.73L212.185 107.725Z"
      fill="#036CFA"
    />
    <path
      d="M229.493 133.146C228.553 133.146 223.766 132.952 221.733 129.125C221.6 128.875 221.697 128.568 221.942 128.435C222.193 128.303 222.499 128.395 222.632 128.645C224.39 131.955 228.655 132.124 229.488 132.124C232.063 132.124 234.408 130.811 235.067 128.997C237.54 122.192 236.564 108.848 234.106 102.33C233.131 99.7394 230.449 96.6894 226.111 96.6026C226.05 96.6026 225.989 96.6026 225.927 96.6026C217.191 96.6026 212.655 108.884 212.609 109.007C212.512 109.272 212.22 109.41 211.955 109.313C211.689 109.216 211.551 108.925 211.648 108.659C211.699 108.526 212.864 105.323 215.214 102.095C218.366 97.7572 222.157 95.5195 226.132 95.5808C230.98 95.6779 233.974 99.0803 235.062 101.972C237.58 108.649 238.572 122.351 236.027 129.35C235.215 131.588 232.528 133.151 229.488 133.151L229.493 133.146Z"
      fill="#231F20"
    />
    <path
      d="M232.476 132.518C232.2 132.518 231.975 132.303 231.965 132.027C231.955 131.746 232.175 131.506 232.456 131.496C232.456 131.496 232.926 131.399 233.293 130.346C234.055 128.155 233.702 125.416 233.697 125.391C233.661 125.11 233.855 124.854 234.136 124.819C234.417 124.783 234.673 124.977 234.709 125.258C234.724 125.381 235.097 128.262 234.254 130.684C233.646 132.431 232.604 132.518 232.486 132.518C232.481 132.518 232.476 132.518 232.466 132.518H232.476Z"
      fill="#231F20"
    />
    <path
      d="M229.232 133.074C228.956 133.074 228.731 132.86 228.721 132.584C228.711 132.303 228.931 132.068 229.211 132.058C229.211 132.058 229.682 131.961 230.049 130.908C230.816 128.701 230.616 125.682 230.616 125.651C230.596 125.37 230.811 125.125 231.092 125.105C231.378 125.084 231.618 125.299 231.638 125.58C231.648 125.713 231.853 128.834 231.015 131.24C230.407 132.987 229.365 133.069 229.247 133.074C229.242 133.074 229.237 133.074 229.227 133.074H229.232Z"
      fill="#231F20"
    />
    <path
      d="M225.794 132.272C225.519 132.272 225.294 132.058 225.284 131.782C225.273 131.501 225.493 131.266 225.774 131.256C225.774 131.256 226.244 131.159 226.612 130.106C227.368 127.925 227.031 125.549 227.026 125.529C226.985 125.248 227.174 124.987 227.455 124.946C227.731 124.905 227.991 125.094 228.037 125.375C228.053 125.483 228.42 128.017 227.577 130.443C226.969 132.19 225.927 132.272 225.81 132.277C225.805 132.277 225.8 132.277 225.789 132.277L225.794 132.272Z"
      fill="#231F20"
    />
    <path
      d="M204.349 123.981C204.349 123.981 206.474 135.92 207.664 139.272C211.414 149.801 217.473 150.521 217.473 150.521L217.616 124.41L204.344 123.976L204.349 123.981Z"
      fill="#231F20"
    />
    <path
      d="M217.472 151.032C217.472 151.032 217.431 151.032 217.41 151.032C217.15 151.001 210.994 150.148 207.183 139.445C205.987 136.094 203.928 124.563 203.841 124.073C203.816 123.919 203.857 123.766 203.959 123.649C204.061 123.531 204.199 123.46 204.362 123.475L217.635 123.909C217.911 123.919 218.131 124.144 218.131 124.42L217.988 150.531C217.988 150.674 217.926 150.812 217.814 150.909C217.722 150.991 217.599 151.037 217.477 151.037L217.472 151.032ZM204.96 124.512C205.415 127.005 207.131 136.262 208.143 139.098C211.06 147.287 215.418 149.371 216.961 149.867L217.099 124.905L204.955 124.512H204.96Z"
      fill="#231F20"
    />
    <path
      d="M217.905 151.098C217.905 151.098 215.019 151.818 212.567 154.128C210.559 156.018 209.537 159.032 209.537 159.032H217.757L217.9 151.098H217.905Z"
      fill="#231F20"
    />
    <path
      d="M217.763 159.543H209.543C209.379 159.543 209.226 159.461 209.129 159.328C209.032 159.196 209.006 159.022 209.063 158.869C209.109 158.741 210.151 155.711 212.225 153.755C214.744 151.384 217.666 150.633 217.788 150.603C217.942 150.562 218.105 150.603 218.233 150.7C218.355 150.797 218.427 150.95 218.427 151.108L218.284 159.042C218.279 159.318 218.054 159.543 217.773 159.543H217.763ZM210.289 158.521H217.262L217.385 151.803C216.424 152.146 214.56 152.958 212.92 154.501C211.586 155.752 210.703 157.556 210.289 158.521Z"
      fill="#231F20"
    />
    <path
      d="M248.921 151.2C249.401 151.2 249.79 150.812 249.79 150.332C249.79 149.852 249.401 149.463 248.921 149.463C248.442 149.463 248.053 149.852 248.053 150.332C248.053 150.812 248.442 151.2 248.921 151.2Z"
      fill="#231F20"
    />
    <path
      d="M248.922 151.711C248.161 151.711 247.543 151.093 247.543 150.332C247.543 149.571 248.161 148.952 248.922 148.952C249.684 148.952 250.302 149.571 250.302 150.332C250.302 151.093 249.684 151.711 248.922 151.711ZM248.922 149.969C248.723 149.969 248.565 150.133 248.565 150.327C248.565 150.521 248.728 150.684 248.922 150.684C249.116 150.684 249.28 150.521 249.28 150.327C249.28 150.133 249.116 149.969 248.922 149.969Z"
      fill="#231F20"
    />
    <path
      d="M129.795 34.6229L107.143 40.8812L110.984 54.7873L121.565 51.86L111.603 72.7242L163.835 109.022L138.332 16.7422L129.795 34.6229Z"
      fill="#FFDEFF"
    />
    <path
      d="M163.404 109.242C163.302 109.242 163.2 109.211 163.113 109.15L110.876 72.8572C110.661 72.709 110.595 72.4229 110.717 72.193L124.991 45.8419C125.124 45.5967 125.436 45.4996 125.686 45.6376C125.936 45.7755 126.028 46.082 125.89 46.3324L111.836 72.2799L163.695 108.317C163.925 108.481 163.987 108.797 163.823 109.027C163.726 109.17 163.563 109.247 163.404 109.247V109.242Z"
      fill="#231F20"
    />
    <path d="M163.523 167.973L163.426 175.043H228.052L163.523 167.973Z" fill="#231F20" />
    <path
      d="M155.738 81.5009C155.513 81.5009 155.309 81.3527 155.248 81.1228L138.2 18.203L130.255 34.8424C130.133 35.0978 129.826 35.2051 129.576 35.0825C129.321 34.9599 129.213 34.6584 129.336 34.403L137.873 16.5223C137.965 16.3281 138.169 16.2106 138.378 16.2311C138.593 16.2515 138.772 16.3996 138.828 16.604L156.229 80.8572C156.3 81.1279 156.142 81.4089 155.871 81.4856C155.825 81.4958 155.779 81.506 155.738 81.506V81.5009Z"
      fill="#231F20"
    />
    <path d="M125.396 45.6018L109.625 49.4487L110.984 54.7874L122.269 51.7119L125.396 45.6018Z" fill="#231F20" />
  </svg>
);

import React, { CSSProperties } from 'react';

import * as s from './Skeleton.css';

export interface ISkeletonParams {
  animation?: 'wave' | 'none';
  variant?: 'text-22';
  width?: CSSProperties['width'];
  height?: CSSProperties['height'];
  margin?: CSSProperties['margin'];
  padding?: CSSProperties['padding'];
}

/**
 * Фейковый элемент для загрузки
 */
export const Skeleton = ({
  animation = 'wave',
  variant = 'text-22',
  width,
  height,
  margin,
  padding,
}: ISkeletonParams) => {
  return (
    <div
      className={`${s['wrapper']} ${variant ? s[variant] : ''} ${height ? s['has-height'] : ''} ${
        animation && animation !== 'none' ? s[`${animation}-animation`] : ''
      }`}
      style={{ width, height, margin, padding }}
    />
  );
};

import React from 'react';
import { useDeviceType } from '@cian/ui-kit';

import { SearchButtonContainer } from '../Search/SearchButton';
import { RegionsButtonContainer } from '../Regions/RegionsButton';
import { TagScrollingContainer } from '../TagScrolling';
import * as s from './ListingHeaderContainer.css';

/**
 * Шапка листинга для Главной
 */
export const MainPageListingHeaderContainer = () => {
  const deviceType = useDeviceType();
  const isDesktop = () => deviceType === 'desktop';

  return (
    <div className={s['wrapper']}>
      {/* Кнопки поиска и выбора региона */}
      <div className={`${s['search-and-regions-wrapper']} ${isDesktop() ? s['_active'] : ''}`}>
        <SearchButtonContainer />
        <RegionsButtonContainer />
      </div>

      {/* Отступ, если нет кнопок поиска и выбора региона */}
      {!isDesktop() && <div className={s['top-margin']} />}

      {/* Слайдер тегов */}
      <div className={s['tag-scrolling-wrapper']}>
        <TagScrollingContainer />
      </div>
    </div>
  );
};

/**
 * Проверяет существование картинки
 */
export const checkImageExists = (url: string, callback: (isExists: boolean) => void) => {
  const img = new Image();

  img.onload = function () {
    callback(true);
  };

  img.onerror = function () {
    callback(false);
  };

  img.src = url;
};

import * as React from 'react';
import { connect } from 'react-redux';

import { Discussed as DiscussedComponent } from '../../components/discussed/discussed';
import { IDiscussedItem, IDiscussed } from '../../types/discussed';

interface IProps {
  comments: IDiscussedItem[];
}

const DiscussedContainer = (props: IProps) =>
  <DiscussedComponent comments={props.comments} />;

export const Discussed = connect((state: { discussed: IDiscussed }) => {
  return {
    comments: state.discussed.data,
  };
})(DiscussedContainer);

import * as React from 'react';
import { Button } from "@cian/ui-kit";

import s from './unsubscribe.css';

interface IProps {
  email?: string;
}

export class Unsubscribe extends React.Component<IProps> {
  public render() {
    return <div className={s['unsubscribe']}>
      <div className={s['unsubscribe-content']}>
        <p className={s['unsubscribe_title']}>Вы успешно отписались от рассылки</p>
        <p className={s['unsubscribe_info']}>
          На <span className={s['unsubscribe_email']}>{this.props.email}</span> больше не будут приходить письма
        </p>
        <Button
          theme="fill_primary"
          size="XS"
          fullWidth={true}
          onClick={() => document.location.replace('/')}>Перейти на главную</Button>
      </div>
    </div>;
  }
}

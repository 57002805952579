import React from 'react';
import { ArticleParagraph1 } from '@cian/ui-kit';

export interface IDescription {
  children?: string;
}

/**
 * Описание
 */
export const Description = ({ children }: IDescription) => {
  if (!children) {
    return null;
  }

  return (
    <ArticleParagraph1 itemProp="description" color="gray60_100">
      {children}
    </ArticleParagraph1>
  );
};

/* eslint-disable max-lines */
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useDeviceType } from '@cian/ui-kit';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { AuthorBlock } from '../author_block/author_block';
import { ICommentState, IRegion, IComment } from '../../types/posts';
import { IPostCard } from '../../types/post_card';
import { IUser } from '../../types/user';
import { BreadCrumbs } from '../../containers/bread_crumbs/bread_crumbs';
import { Likes } from '../../containers/likes/likes';
import { Poll } from '../../containers/poll/poll';
import { LikesContent } from '../../containers/likes_content/likes_content';
import { Tags } from './tags';
import { formatDate, formatViews } from '../../../app/helpers/format_helper';
import { SocialNetworkShare } from './social_network_share';
import { Comments } from '../comments/comments';
import { getCategory, categories } from '../../../app/helpers/category_helper';
import { makeHTMLFromString, unescapeHtml } from '../../../app/helpers/make_html_helper';
import { getTelegramLinkData, isProfessionalAudience } from './helpers';
import { ModerBar } from './moder_bar';
import { DeclineReason } from '../decline_reason/decline_reason';
import { SubscribeDialogContainer } from '../subscribe/sub_dialog';
import { UnsubscribeDialog } from '../subscribe/unsub_dialog';
import { IScrollComment } from '../../types/scroll_comment';
import * as Analytics from '../../../app/helpers/analytics_helper';
import { PageBlocked } from '../page_blocked/page_blocked';
import { Permission } from '../../../app/permissions/permission';
import { ISubscribeToCommentsResponse } from '../../containers/post_card/post_card';
import { getPrioritizedRegion } from '../../../app/helpers/regions_helper';
import { isAdminOrModerator } from '../../../app/utils';
import { IRubric } from '../../reducers/modules/rubrics_menu/rubrics_menu';
import { trackFileDownloadClick } from './tracking';
import { buildPostUrl, formatDateToYYYYMMDD } from '../../utils';
import { RecommendedOffersContainer } from '../../containers/RecommendedOffers';
import { ReactPortal } from '../ReactPortal';
import { AuthorArticlesContainer } from '../../containers/AuthorArticles';
import { IExtractAndReplaceJournalBannersItem } from '../../containers/post_card/helpers';
import { EType } from '../../repositories/journal/entities/journal/JournalListAttributesSchema';
import s from './post_card.css';

interface IProps {
  menuItems: IRubric[];
  regionId?: string;
  user: IUser;
  postCard: IPostCard;
  preparedContent: string;
  commentsCount: number;
  commentsState: ICommentState;
  comments?: IComment[];
  onCommentSubmit?(
    comment: string,
    userId: number,
    fullName: string,
    email: string,
    parentID: number | null,
    subs: boolean,
  ): void;
  onCommentEdit?(id: number, comment: string, email: string): void;
  onCommentDelete?(id: number, email: string): void;
  onCommentHide?(id: number, email: string): void;
  onDigestSubmit(email: string, sub: boolean, isNews?: boolean): Promise<ISubscribeToCommentsResponse | void>;
  onDigestReset(): void;
  isSubscribed: boolean;
  isUnsubscribed: boolean;
  error?: string;
  onScrollComments(): void;
  onScrollCommentForm(): void;
  scrollCommentState: IScrollComment;
  scrollCommentDisabled(): void;
  onHide(): void;
  onPublish(): void;
  onEdit(): void;
  refreshScroll?(): void;
  onJournalBannerClick?(url: string, position: IExtractAndReplaceJournalBannersItem['position']): void;
}

interface ISubcategory {
  name: string;
  type: string;
}

export const PostCard: React.FC<IProps> = props => {
  const [subShown, setSubShown] = useState(false);
  const [unsubShown, setUnsubShown] = useState(false);
  const [hash, setHash] = useState(typeof window === 'undefined' ? '' : window.location.hash);
  const [likesCount, setLikesCount] = useState(props.postCard.item.attributes.likesCount || 0);
  const [dislikesCount, setDislikesCount] = useState(props.postCard.item.attributes.dislikesCount || 0);
  const [, setCurrentUserLike] = useState(false);
  const [, setCurrentUserDislike] = useState(false);
  const [isShownPopupImage, setIsShownPopupImage] = useState(false);
  const [userLike, setUserLike] = useState(props.postCard.item.attributes.userLike || null);
  const deviceType = useDeviceType();
  const location = useLocation();
  const { pathname } = location;
  const browserHistory = useHistory();

  const setVisitedLink = () => {
    const visitedLinks = localStorage['visitedLinks'] ? JSON.parse(localStorage['visitedLinks']) : [];

    if (!visitedLinks.includes(pathname)) {
      visitedLinks.push(pathname);
    }

    localStorage['visitedLinks'] = JSON.stringify(visitedLinks);
  };

  const scrollToComment = useCallback((id: string) => {
    const element = id ? document.getElementById(id) : document.body;

    if (!element) {
      return;
    }

    const { top } = element.getBoundingClientRect();

    setScrollTop(top + document.documentElement.scrollTop || document.body.scrollTop);
  }, []);

  useEffect(() => {
    if (window.location.hash) {
      setTimeout(() => scrollToComment(window.location.hash.replace('#', '')), 2000);
    } else if (!props.scrollCommentState.isScrollComments) {
      window.scrollTo(0, 0);
    }
    setVisitedLink();

    /**
     * Делаем так, потому что ссылка на скачивание файла *.doc приходит
     * с бекенда прямо ввиде html-разметки
     */
    const docLinkElement = document.querySelector('.analytics-download-link');
    if (docLinkElement) {
      docLinkElement.classList.add('content__download-btn');
      docLinkElement.addEventListener('click', (e: MouseEvent) => {
        const fileName = (e.currentTarget as HTMLAnchorElement).getAttribute('data-file_name');

        trackFileDownloadClick(fileName || '');
      });
    }
  }, []);

  useEffect(() => {
    setLikesCount(props.postCard.item.attributes.likesCount || 0);
    setDislikesCount(props.postCard.item.attributes.dislikesCount || 0);
    setUserLike(props.postCard.item.attributes.userLike || null);
    if (!window.location.hash) {
      window.scrollTo(0, 0);

      return;
    }

    if (window.location.hash !== hash) {
      setTimeout(() => scrollToComment(window.location.hash.replace('#', '')), 2000);

      setHash(window.location.hash);
    }
  }, [
    hash,
    props.postCard.item.attributes.dislikesCount,
    props.postCard.item.attributes.likesCount,
    props.postCard.item.attributes.userLike,
    props.postCard.item.id,
    scrollToComment,
  ]);

  const getRubricsLink = () => {
    const {
      postCard: { item },
      menuItems,
    } = props;
    const {
      attributes: { rubrics },
    } = item;
    const type = item.type.toLowerCase();

    if (rubrics && rubrics.length) {
      const links = rubrics.map(rubricId => {
        const rubric = menuItems.find(menuItem => menuItem.id === rubricId);

        if (rubric) {
          return (
            <Link key={rubricId} to={{ pathname: rubric.pathname }} className={s['category-name']}>
              {rubric.name}
            </Link>
          );
        }

        return null;
      });

      return <span>{links}</span>;
    }

    if (type !== 'articles') {
      return (
        <Link to={{ pathname: `/${getCategory(type.toLowerCase()).linkType}/` }} className={s['category-name']}>
          {getCategory(type.toLowerCase()).category}
        </Link>
      );
    }

    return <span className={s['category-name__empty']} />;
  };

  function setScrollTop(position: number) {
    document.documentElement.scrollTop = document.body.scrollTop = position;
  }

  /**
   * Показывает/скрывает модалку с главной картинкой поста
   */
  const togglePopupImage = useCallback(() => {
    setIsShownPopupImage(!isShownPopupImage);
  }, [isShownPopupImage]);

  /**
   * Скрывает модалку с главной картинкой поста по Escape
   */
  const handleEscPress = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        togglePopupImage();
      }
    },
    [togglePopupImage],
  );

  /**
   * Скрывает скролл у body и слушает нажатие Escape,
   * если модалка с главной картинкой активна
   */
  useEffect(() => {
    if (isShownPopupImage) {
      document.body.style.overflow = 'hidden';
      document.addEventListener('keydown', handleEscPress);
    } else {
      document.body.style.overflow = 'auto';
      document.removeEventListener('keydown', handleEscPress);
    }

    return () => {
      document.body.style.overflow = 'auto';
      document.removeEventListener('keydown', handleEscPress);
    };
  }, [handleEscPress, isShownPopupImage]);

  function currentSubCategory() {
    const subcategories: ISubcategory[] = [
      {
        name: 'Тенденции рынка',
        type: 'market_trend',
      },
      {
        name: 'Специалист–специалисту',
        type: 'specialist',
      },
      {
        name: 'Городская недвижимость',
        type: 'city_realty',
      },
      {
        name: 'Коммерческая недвижимость',
        type: 'commerce_realty',
      },
      {
        name: 'Загородная недвижимость',
        type: 'country_realty',
      },
      {
        name: 'Зарубежная недвижимость',
        type: 'foreign_realty',
      },
      {
        name: 'Ипотека',
        type: 'hypothec',
      },
      {
        name: 'Специалист–специалисту',
        type: 'specialist',
      },
      {
        name: 'Тенденции рынка',
        type: 'market_trend',
      },
    ];
    const currentSubCategory = subcategories.find(
      subcategory => props.postCard.item.attributes.category === subcategory.type,
    );

    return currentSubCategory && currentSubCategory.name;
  }

  const bestAdviceCommentSort = () => {
    const { comments } = props;

    if (comments) {
      for (let i = 0; i < comments.length; i++) {
        if (comments[i].bestAdvice) {
          comments.unshift(comments.splice(i, 1)[0]);

          return comments;
        }
      }
    }

    return comments;
  };

  const getComments = () => {
    if (props.postCard.item.type.toLowerCase() === 'question') {
      return bestAdviceCommentSort();
    }

    return props.comments;
  };

  const isBlockedPage = () => {
    const {
      user,
      postCard: {
        item: {
          attributes: { category },
        },
      },
    } = props;

    return !isAdminOrModerator(user) && (user.userId === -1 || (user.rating || 0) < 50) && category === 'specialist';
  };

  const {
    title,
    author,
    subtitle,
    datePublish,
    dateModified,
    numViews,
    image,
    tags,
    status,
    advertising,
    isSubscribedToComments,
    blockReason,
    regions,
    slug,
    telegramChannel: tgAudience,
  } = props.postCard.item.attributes;
  const type = props.postCard.item.type.toLowerCase();
  const { error } = props.postCard;

  if (typeof window !== 'undefined' && error && error.statusCode === 404) {
    document.location.replace('https://www.cian.ru/404/');

    return <span />;
  }

  if (isBlockedPage()) {
    return (
      <div className={`${s['post_card']} cg-col-16`}>
        <PageBlocked />
      </div>
    );
  }

  const blogQuestionContentShown = props.postCard.item.type === 'Blog' || props.postCard.item.type === 'Question';
  const blogContentShown = props.postCard.item.type === 'Blog';
  const permission = new Permission(props.user, props.postCard);
  const isDeclined = status === 'decline' || status === 'hidden' || status === 'removed';
  const isAccepted = status === 'checked' || status === 'unchecked';
  const subcat = props.postCard.item.attributes.category;
  const isQuestion = props.postCard.item.type === 'Question';
  const telegram = getTelegramLinkData(tags, tgAudience);

  return (
    <div className={`${s['post_card']} cg-col-16`}>
      <BreadCrumbs />
      {getRubricsLink()}
      {blogQuestionContentShown && categories[subcat || ''].link && (
        <Link
          className={s['subcategory-name']}
          to={`/${getCategory(type.toLowerCase()).linkType}-${categories[subcat || ''].link}/`}
        >
          {currentSubCategory()}
        </Link>
      )}
      {advertising && <span className={`${s['caption']} ${s['ads-caption']}`}>на правах рекламы</span>}
      {permission.canSeeCaptions() && isAccepted && (
        <span className={`${s['caption']} ${s['accepted-caption']}`}>опубликовано</span>
      )}
      {permission.canSeeCaptions() && isDeclined && (
        <span className={`${s['caption']} ${s['decline-caption']}`}>отклонён</span>
      )}
      {permission.canSeeCaptions() && !isDeclined && !isAccepted && (
        <span className={`${s['caption']} ${s['draft-caption']}`}>черновик</span>
      )}
      <DeclineReason blockReason={blockReason} />
      <div itemScope itemType={`https://schema.org/${props.postCard.item.type === 'News' ? 'NewsArticle' : 'Article'}`}>
        <meta itemProp="identifier" content={props.postCard.item.id} />
        <link
          itemProp="mainEntityOfPage"
          href={`https://cian.ru${buildPostUrl({
            type: EType[props.postCard.item.type as keyof typeof EType],
            slug,
            id: parseInt(props.postCard.item.id, 10),
          })}`}
        />

        <h1 className={s['post-card-title']} itemProp="headline">
          {unescapeHtml(title)}
        </h1>
        <div
          className={`
              ${s['post-card-info-wrap']}
              ${blogQuestionContentShown ? s['post-card-info-wrap-blog-question'] : ''}
            `}
        >
          {props.postCard.item.attributes.authorObject && (
            <AuthorBlock containerClass={s['author-container']} author={props.postCard.item.attributes.authorObject} />
          )}
          <div className={s['post-card-info']}>
            {author && <span className={s['post-card-info__author']}>{author}</span>}
            {isQuestion && (
              <span className={s['post-card-info__date']}>
                <span>{formatDate(datePublish)}</span>
                <span>
                  ・
                  {regions && regions.length > 0
                    ? (getPrioritizedRegion(regions, props.regionId) as IRegion).name
                    : 'Вся Россия'}
                </span>
              </span>
            )}
            {!isQuestion && <span className={s['post-card-info__date']}>{formatDate(datePublish)}</span>}
            <span className={s['post-card-info__views']}>
              <span className={s['views-icon']} />
              <span> {(numViews && formatViews(numViews, 3)) || 0}</span>
            </span>
            {permission.canSeeComments() && (props.commentsCount > 0 || isQuestion) && (
              <span className={s['post-card-info__comments']} role="button" onClick={props.onScrollComments}>
                <span className={s['comments-icon']} /> <span>{formatViews(props.commentsCount, 3)}</span>
              </span>
            )}
            {props.postCard.item.attributes.authorObject && (type === 'blog' || type === 'blogs') && (
              <Link
                className={s['blogs-link']}
                to={`/blogs/?author_id=${props.postCard.item.attributes.authorObject.userId}`}
              >
                <span className={s['post-card-info__blogs']}>
                  <span className={s['blogs-icon']} />
                  <span>
                    &nbsp;Блог специалиста&nbsp;
                    {props.postCard.item.attributes.authorObject.blogsCount}
                  </span>
                </span>
              </Link>
            )}
            <div className={s['post-card-top-likes']}>
              {blogContentShown && (
                <Likes
                  disabled={!permission.canLikes()}
                  isCompact={false}
                  likesCount={likesCount || 0}
                  dislikesCount={dislikesCount || 0}
                  userLike={userLike}
                  onLikesUpdate={(likes: number, dislikes: number) => {
                    setLikesCount(likes);
                    setCurrentUserLike(false);
                    setCurrentUserDislike(false);
                    setDislikesCount(dislikes);
                  }}
                  type={props.postCard.item.type.toLowerCase()}
                  objectID={props.postCard.item.id}
                />
              )}
            </div>
            {blogQuestionContentShown && props.postCard.item.type !== '' && (
              <ModerBar
                user={props.user}
                model={props.postCard}
                onHide={() => props.onHide()}
                onEdit={() => {
                  props.onEdit();
                  if (type.toLowerCase() === 'blog') {
                    location.pathname = '/add-blog';
                  } else {
                    location.pathname = '/add-vopros';
                  }
                  browserHistory.push(location);
                }}
                onPublish={() => props.onPublish()}
              />
            )}
            <div itemProp="author" itemScope itemType="https://schema.org/Organization">
              <meta itemProp="name" content={props.postCard.item.attributes.authorObject?.fullName || 'Циан Журнал'} />
              <meta itemProp="url" content="https://www.cian.ru/magazine/" />
            </div>
            <meta
              itemProp="datePublished"
              content={formatDateToYYYYMMDD(datePublish ? new Date(datePublish) : new Date())}
            />
            <meta
              itemProp="dateModified"
              content={formatDateToYYYYMMDD(dateModified ? new Date(dateModified) : new Date())}
            />
            {/* TODO: CD-167009: Удалить div после проверки на проде */}
            <div style={{ display: 'none' }}>{dateModified}</div>
            <div itemProp="publisher" itemScope itemType="https://schema.org/Organization">
              <meta itemProp="name" content="Циан Журнал" />
              <meta itemProp="logo" content={'https://content.cdn-cian.ru/realty/logo-cian.svg'} />
            </div>
          </div>
          <div
            role="button"
            className={`
                ${s['post-card-content']}
                ${blogQuestionContentShown ? s['post-card-content-blog-question'] : ''}
              `}
          >
            {image && (
              <>
                <div className={s['post-card-content__main-img-container']} onClick={togglePopupImage}>
                  <img className={s['post-card-content__main-img']} src={image} alt={title} itemProp="image" />
                </div>

                <ReactPortal wrapperId={'post-image-popup'}>
                  {isShownPopupImage && (
                    <div className={s['popup']} onClick={togglePopupImage}>
                      <span className={s['popup__close-icon']} />
                      <img className={s['popup__image']} src={image} ref={popupImage => popupImage} alt="Popup image" />
                    </div>
                  )}
                </ReactPortal>
              </>
            )}
            <div>
              {blogQuestionContentShown ? (
                <div
                  className={`
                      ${s['post-card-content__summary']}
                      ${s['post-card-content__summary-empty']}
                      ${image ? '' : s['post-card-content-blog-question-empty']}
                      ${
                        !image && (permission.canEdit() || permission.canPublish() || permission.canHide())
                          ? s['post-card-content__summary-empty-permision-valid-mobile']
                          : s['post-card-content__summary-empty-permision-invalid']
                      }
                    `}
                  itemProp="description"
                />
              ) : (
                <div
                  className={s['post-card-content__summary']}
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={makeHTMLFromString(subtitle)}
                  itemProp="description"
                />
              )}
            </div>
            <div
              className={`${s['post-card-content__text']} fr-view`}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: unescapeHtml(props.preparedContent) }}
              itemProp="articleBody"
            />
          </div>
          {!blogQuestionContentShown && (
            <p>
              <a
                className={`${s['post-card-content__link-btn']}`}
                href={telegram.link}
                rel="noopener noreferrer"
                target="_blank"
              >
                {telegram.title}
              </a>
            </p>
          )}
          {!blogQuestionContentShown && props.postCard.item.type === 'Articles' && (
            <Poll type={props.postCard.item.type.toLowerCase()} objectID={props.postCard.item.id} />
          )}
          {props.postCard.item.type === 'Articles' && props.postCard.item.id && (
            <LikesContent
              disabled={!permission.canLikes()}
              type={props.postCard.item.type.toLowerCase()}
              objectID={props.postCard.item.id}
            />
          )}
          {!!tags && tags.length > 0 && <Tags tags={tags} refreshScroll={props.refreshScroll} type={type} />}
          {blogContentShown && props.postCard.item.id && (
            <div className={`${s['card-footer-container']} ${s['mobile']}`}>
              <Likes
                disabled={!permission.canLikes()}
                likesCount={likesCount || 0}
                dislikesCount={dislikesCount || 0}
                userLike={userLike}
                onLikesUpdate={(likes: number, dislikes: number) => {
                  setLikesCount(likes);
                  setCurrentUserLike(false);
                  setCurrentUserDislike(false);
                  setDislikesCount(dislikes);
                }}
                type={props.postCard.item.type.toLowerCase()}
                objectID={props.postCard.item.id}
              />
            </div>
          )}

          <SocialNetworkShare
            canComment={permission.canComment()}
            canSeeComments={permission.canSeeComments()}
            cardType={props.postCard.item.type}
            rightAligned={!blogQuestionContentShown}
            isProfessionalAudience={isProfessionalAudience(tags, tgAudience)}
            telegramLink={telegram.link}
            onScrollCommentForm={props.onScrollCommentForm}
          >
            {blogQuestionContentShown && (
              <div
                className={`
                      ${s['card-footer-container']}
                      ${s['desktop']}
                      ${blogContentShown ? '' : s['card-footer-container-question']}
                    `}
              >
                {blogContentShown && props.postCard.item.id && (
                  <Likes
                    disabled={!permission.canLikes()}
                    likesCount={likesCount || 0}
                    dislikesCount={dislikesCount || 0}
                    userLike={userLike}
                    onLikesUpdate={(likes: number, dislikes: number) => {
                      setLikesCount(likes);
                      setCurrentUserLike(false);
                      setCurrentUserDislike(false);
                      setDislikesCount(dislikes);
                    }}
                    type={props.postCard.item.type.toLowerCase()}
                    objectID={props.postCard.item.id}
                  />
                )}
              </div>
            )}
          </SocialNetworkShare>

          {/* Блок выводится только для рубрики "Редакция" (id = 32) */}
          {props.postCard.item.attributes.rubrics?.includes(32) && props.postCard.item.attributes.seoTitle && (
            <div className={s['author-articles-wrapper']}>
              <AuthorArticlesContainer />
            </div>
          )}

          <div className={s['recommended-offers-wrapper']}>
            <RecommendedOffersContainer />
          </div>

          {permission.canSeeComments() && (
            <Comments
              location={location}
              canComment={permission.canComment()}
              canSubscribeComments={permission.canSubscribeComments()}
              likesShown={type === 'question' || type === 'questions'}
              commentsState={props.commentsState}
              author={props.postCard.item.attributes.authorObject}
              restrictComments={type === 'question' || type === 'questions'}
              onCommentSubmit={(
                comment: string,
                userId: number,
                fullName: string,
                email: string,
                parentID: number | null,
                subs: boolean,
              ) => props.onCommentSubmit && props.onCommentSubmit(comment, userId, fullName, email, parentID, subs)}
              onCommentEdit={(commentId: number, comment: string, email: string) =>
                props.onCommentEdit && props.onCommentEdit(commentId, comment, email)
              }
              onCommentDelete={(commentId: number, email: string) =>
                props.onCommentDelete && props.onCommentDelete(commentId, email)
              }
              onCommentHide={(commentId: number, email: string) =>
                props.onCommentHide && props.onCommentHide(commentId, email)
              }
              showSubscribeDialog={() => {
                setSubShown(true);
              }}
              showUnsubscribeDialog={() => {
                setUnsubShown(true);
              }}
              subscribed={isSubscribedToComments}
              user={props.user}
              count={props.commentsCount}
              comments={getComments()}
              scrollCommentState={props.scrollCommentState}
              scrollCommentDisabled={props.scrollCommentDisabled}
              cardType={props.postCard.item.type}
            />
          )}

          <SubscribeDialogContainer
            key={`sub-dialog-${props.user.email}`}
            shown={subShown}
            onDigestSubmit={(email: string, isNews?: boolean) => {
              const analyticsValue = props.postCard.item.type === 'Question' ? 'new_advices' : 'new_comments';

              Analytics.subscribeComments(analyticsValue, 'Magazine_subscription');

              return props.onDigestSubmit(email, true, isNews);
            }}
            onDigestReset={() => props.onDigestReset()}
            isSubscribed={props.isSubscribed}
            error={props.error}
            email={props.user.email}
            deviceType={deviceType}
            onClose={() => {
              setSubShown(false);
            }}
          />
          <UnsubscribeDialog
            key={`unsub-dialog-${props.user.email}`}
            shown={unsubShown}
            onDigestSubmit={(email: string) => {
              const analyticsValue = props.postCard.item.type === 'Question' ? 'new_advices' : 'new_comments';

              Analytics.subscribeComments(analyticsValue, 'Magazine_unsubscription');
              props.onDigestSubmit(email, false);
            }}
            onDigestReset={() => props.onDigestReset()}
            isUnsubscribed={props.isUnsubscribed}
            error={props.error}
            email={props.user.email}
            onClose={() => {
              setUnsubShown(false);
            }}
          />
        </div>
      </div>
    </div>
  );
};

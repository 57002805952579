import { transformItem } from './transformItem';
import { IMenuGroup, IMenuItem } from '../../../components/MainMenuGroup';
import { IMenuBlockItemsSchema } from '../../../repositories/journal/entities/menu/MenuBlockItemsSchema';

/**
 * Трансформирует группу меню
 */
export const transformGroup = ({ items }: IMenuBlockItemsSchema): IMenuGroup => {
  return {
    items: items.length
      ? items.sort((prev, next) => (prev.blockOrder || 0) - (next.blockOrder || 0)).map(item => transformItem(item))
      : ([] as IMenuItem[]),
  };
};

import { HttpBadStatusCodeError } from '@cian/peperrors/shared';

import { TThunkAction } from '../../../types/redux';
import { getSearchingResultsLoading } from './getSearchingResultsLoading';
import { getSearchingResultsSucceed } from './getSearchingResultsSucceed';
import { getSearchingResultsAppendSucceed } from './getSearchingResultsAppendSucceed';
import { getSearchingResultsFailed } from './getSearchingResultsFailed';
import { fetchSearch, TSearchResponse, ISearchRequest } from '../../../repositories/journal/v1/search';

export interface IGetSearchingResultsParams extends Omit<ISearchRequest, 'b2b' | 'regionId'> {
  setType?: 'set' | 'append';
}

export const getSearchingResults = ({
  setType = 'set',
  ...otherProps
}: IGetSearchingResultsParams): TThunkAction<Promise<void>> => {
  return async (dispatch, getState, context) => {
    const { httpApi, logger } = context;
    const {
      userNew: { isB2BModeEnabled },
      geo: { currentRegion },
    } = getState();

    dispatch(getSearchingResultsLoading());

    try {
      const { response, statusCode }: TSearchResponse = await fetchSearch({
        httpApi,
        parameters: {
          regionId: currentRegion.id,
          b2b: isB2BModeEnabled,
          ...otherProps,
        },
      });

      if (statusCode !== 200) {
        await Promise.reject(
          new HttpBadStatusCodeError(
            'Не удалось загрузить список результатов поиска',
            statusCode,
            'actions.getSearchingResults',
          ),
        );

        return;
      }

      if (setType === 'append') {
        dispatch(getSearchingResultsAppendSucceed({ meta: response.meta, items: response.data }));
      } else {
        dispatch(getSearchingResultsSucceed({ meta: response.meta, items: response.data }));
      }
    } catch (e) {
      logger.error(e, {
        domain: 'actions.getSearchingResults',
      });

      dispatch(getSearchingResultsFailed());
    }
  };
};

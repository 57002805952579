import * as React from 'react';

export const IconFunctionalInformationCircle16 = () => {
  return (
    // eslint-disable-next-line react/forbid-elements
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g clipPath="url(#clip0_3877_3908)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 8C0 3.6 3.6 0 8 0C12.4 0 16 3.6 16 8C16 12.4 12.4 16 8 16C3.6 16 0 12.4 0 8ZM9 5C9 5.55228 8.55229 6 8 6C7.44772 6 7 5.55228 7 5C7 4.44772 7.44772 4 8 4C8.55229 4 9 4.44772 9 5ZM7 12L7 7H9L9 12H7Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_3877_3908">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { ArrowDownS16 as IconChevronDownSmall16, AddStroke16 as IconPlus16 } from '@cian/ui-kit/icons';

import { IMenuItem, TControlsType, TMenuItemCommonProps } from '../../types';
import { useIsMounted } from '../../../../utils';
import * as s from './MenuItemMobile.css';

export type TMenuItemMobileProps = Omit<IMenuItem, 'slug' | 'items'> &
  TMenuItemCommonProps & {
    /** Раскрыт ли пункт меню */
    isOpened?: boolean;
    /** Скоуп для подменю */
    children?: React.ReactNode;
  };

/**
 * Элемент основного меню
 */
export const MenuItemMobile = ({
  children,
  url,
  label,
  controlsType,
  isActive,
  isOpened,
  isAbsolute,
  iconAfter,
  hasFlashEffect = false,
  onClick,
}: TMenuItemMobileProps) => {
  const childrenWrapperRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLLIElement>(null);
  const flashTimerRef = useRef<ReturnType<typeof setTimeout>>();
  const [isShown, setIsShown] = useState(false);
  const isMounted = useIsMounted();

  /** CSS-классы для элемента */
  const className = `${s['link']} ${isActive ? s['_active'] : ''}`;

  /** Варианты элементов управления раскрытием/скрытием подменю */
  const controls: Record<TControlsType, JSX.Element> = {
    chevrons: (
      <span className={`${s['icon-chevron-wrapper']} ${isOpened ? s['_rotate'] : ''}`}>
        <IconChevronDownSmall16 />
      </span>
    ),
    plusAndCross: (
      <span className={`${s['icon-plus-wrapper']} ${isOpened ? s['_rotate'] : ''}`}>
        <IconPlus16 />
      </span>
    ),
  };

  /** Контент для пункта меню */
  const linkContent = (
    <>
      <span className={`${s['label-wrapper']}`}>{label}</span>
      {iconAfter && !controlsType && <span className={`${s['icon-after-wrapper']}`}>{iconAfter}</span>}
      {controlsType && children && controls[controlsType]}
    </>
  );

  /**
   * Анимация расхлопывания/схлопывания подменю
   * При схлопывании сначала - анимация, а после - размонтируем children
   */
  useEffect(() => {
    if (childrenWrapperRef?.current) {
      childrenWrapperRef.current.classList.toggle(s['_shown'], isOpened);
    }

    const timeoutId = setTimeout(
      () => {
        if (!isMounted()) {
          return;
        }

        setIsShown(Boolean(isOpened));
      },
      isOpened ? 0 : 300,
    );

    return () => clearTimeout(timeoutId);
  }, [isMounted, isOpened]);

  /** Отправляет клик родителю и запускает анимацию вспышки  */
  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      onClick(e);

      if (!hasFlashEffect) {
        return;
      }

      if (wrapperRef?.current) {
        wrapperRef.current.classList.toggle(s['_is-flash'], false);
        wrapperRef.current.classList.toggle(s['_is-flash'], true);
      }

      clearTimeout(flashTimerRef.current);

      flashTimerRef.current = setTimeout(() => {
        if (!isMounted()) {
          return;
        }

        if (wrapperRef?.current) {
          wrapperRef.current.classList.toggle(s['_is-flash'], false);
        }
      }, 300);
    },
    [isMounted, onClick, flashTimerRef, hasFlashEffect],
  );

  return (
    <li ref={wrapperRef} className={`${s['wrapper']}`}>
      <span onClick={handleClick}>
        {url ? (
          <Link className={className} to={url}>
            {linkContent}
          </Link>
        ) : (
          <span className={className}>{linkContent}</span>
        )}
      </span>

      <div ref={childrenWrapperRef} className={`${s['children-wrapper']} ${isAbsolute ? s['_absolute'] : ''}`}>
        {isShown && children}
      </div>
    </li>
  );
};

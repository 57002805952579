import React from 'react';
import {
  MetroMoscow16,
  MetroSpb16,
  MetroSamara16,
  MetroEkb16,
  MetroKazan16,
  MetroNn16,
  MetroNovosib16,
} from '@cian/ui-kit';

export enum UndergroundType {
  /** Москва */
  Msk = 'mow',
  /** Санкт-Петербург */
  Spb = 'spb',
  /** Самара */
  Samara = 'smr',
  /** Екатеринбург */
  Ekb = 'ekb',
  /** Казань */
  Kazan = 'kzn',
  /** Нижний новгород */
  Nizhniy = 'nn',
  /** Новосибирск */
  Novosib = 'nsk',
}

export interface IMetroIconParams {
  type?: UndergroundType;
}

/**
 * Региональная иконка метро
 */
export const UndergroundIcon = ({ type }: IMetroIconParams) => {
  switch (type) {
    case UndergroundType.Msk:
      return <MetroMoscow16 />;

    case UndergroundType.Spb:
      return <MetroSpb16 />;

    case UndergroundType.Samara:
      return <MetroSamara16 />;

    case UndergroundType.Ekb:
      return <MetroEkb16 />;

    case UndergroundType.Kazan:
      return <MetroKazan16 />;

    case UndergroundType.Nizhniy:
      return <MetroNn16 />;

    case UndergroundType.Novosib:
      return <MetroNovosib16 />;

    default:
      return <MetroMoscow16 />;
  }
};

import * as React from 'react';
import { connect } from 'react-redux';

import { IUser } from '../../types/user';
import { IReadTopic } from '../../types/read_topic';
import { ISimilarJournalItem } from '../../types/posts';
import { ReadTopic as ReadTopicComponent } from '../../components/read_topic/read_topic';

interface IProps {
  user: IUser;
  showDesktop: boolean;
  showMobile: boolean;
  readTopic: IReadTopic;
}

export class ReadTopicContainer extends React.Component<IProps> {
  public render() {
    return (
      <div>
        {
          this.props.readTopic.show && this.props.readTopic.items.length > 0 &&
            <ReadTopicComponent
                user={this.props.user}
                showDesktop={this.props.showDesktop}
                showMobile={this.props.showMobile}
                items={this.formatListData(this.props.readTopic.items)} />
        }
      </div>
    );
  }

  private formatListData(items: ISimilarJournalItem[]) {
    return items.map((item: ISimilarJournalItem) => {
      return {
        attributes: item,
        id: item.id,
        type: item.type,
      };
    });
  }
}

export const ReadTopicDesktop = connect<IProps, {}, {}>(
  (state: { readTopic: IReadTopic, user: IUser }) => ({
    readTopic: state.readTopic,
    showMobile: false,
    showDesktop: true,
    user: state.user,
  }),
)(ReadTopicContainer);

export const ReadTopicMobile = connect<IProps, {}, {}>(
  (state: { readTopic: IReadTopic, user: IUser }) => ({
    readTopic: state.readTopic,
    showMobile: true,
    showDesktop: false,
    user: state.user,
  }),
)(ReadTopicContainer);

import { ITypedReduxAction } from '../../../types/redux/actionType';
import { IDocumentGroup } from '../../../types/documentGroupList';

export enum EGetDocumentGroupListActionType {
  Loading = 'documentGroupList/getDocumentGroupList/loading',
  Succeed = 'documentGroupList/getDocumentGroupList/succeed',
  Failed = 'documentGroupList/getDocumentGroupList/failed',
}

export type TGetDocumentGroupListLoading = ITypedReduxAction<EGetDocumentGroupListActionType.Loading>;
export type TGetDocumentGroupListSucceed = ITypedReduxAction<EGetDocumentGroupListActionType.Succeed, IDocumentGroup[]>;
export type TGetDocumentGroupListFailed = ITypedReduxAction<EGetDocumentGroupListActionType.Failed>;

export type TGetDocumentGroupList =
  | TGetDocumentGroupListLoading
  | TGetDocumentGroupListSucceed
  | TGetDocumentGroupListFailed;

/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TSearchModel,
  ISearchRequest,
  IMappers,
  ISearchResponse,
  ISearchResponseError,
  TSearchResponse,
} from './types';

const defaultConfig: TSearchModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'GET',
  microserviceName: 'journal',
  pathApi: '/v1/search/',
  hostType: 'api',
} as TSearchModel;

function createSearchModel(parameters: ISearchRequest): TSearchModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface ISearchOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: ISearchRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchSearch<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: ISearchOptions<TResponse200, TResponse400>): Promise<TResponse200 | TResponse400 | TSearchResponse> {
  const { response, statusCode } = await httpApi.fetch(createSearchModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as ISearchResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as ISearchResponseError);
    }
  }

  return { response, statusCode } as TSearchResponse;
}

export { defaultConfig, createSearchModel, fetchSearch };

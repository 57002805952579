import React, { useCallback, useRef, useState } from 'react';

import { ReactPortal } from '../ReactPortal';
import { throttle, useTimeout } from '../../utils';
import * as s from './Curtain.css';

export interface ICurtain {
  /** Внешний ключ управления */
  isOpen: boolean;
  /** Контент шторки */
  children: React.ReactNode;
  /** Длительность анимации в мс */
  transitionDuration?: number;
  /** Событие закрытия */
  onClose(e: React.MouseEvent): void;
}

/**
 * Компонент-шторка (альтернативная модалка)
 */
export const Curtain = ({ isOpen, children, onClose, transitionDuration = 300 }: ICurtain) => {
  const [isShown, setIsShown] = useState(false);
  const overlayRef = useRef<HTMLDivElement>(null);

  /**
   *  Слушает внешний ключ управления isOpen и переключает внутренний isShown.
   *  Перед выключением выжидает transitionDuration для анимации затухания
   *  После анимации освобождает body и выключает класс анимации _hiding
   */
  useTimeout(
    () => {
      document.body.style.overflow = isOpen ? 'hidden' : '';

      if (overlayRef?.current) {
        overlayRef.current.classList.toggle(s['_hiding'], false);
      }

      setIsShown(isOpen);
    },
    isOpen ? 0 : transitionDuration,
  );

  /**
   * Слушает внешний ключ управления isOpen и переключает анимацию.
   * Перед включением анимации необходимо дождаться включения isShown
   */
  useTimeout(
    () => {
      if (overlayRef?.current) {
        overlayRef.current.classList.toggle(s['_shown'], isOpen);
      }
    },
    isOpen ? 50 : 0,
  );

  /**
   * Отправляет родителю событие закрытия шторки, если модалка открыта
   * Включает класс анимации _hiding
   */
  const toggle = useCallback(
    (e: React.MouseEvent<Element, MouseEvent>) => {
      if (!isOpen) {
        return;
      }

      onClose(e);

      if (overlayRef?.current) {
        overlayRef.current.classList.toggle(s['_hiding'], true);
      }
    },
    [isOpen, onClose],
  );

  /**
   * Декоратор для отправки события закрытия шторки
   * с блокировкой повторного действия на время анимации
   */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleToggleDebounced = useCallback(throttle(toggle, transitionDuration), [isOpen, onClose]);

  return (
    <ReactPortal wrapperId={'curtain'}>
      <div ref={overlayRef} className={s['wrapper']} data-testid="wrapper">
        {isShown && (
          <>
            <div
              className={s['overlay']}
              style={{ transitionDuration: `${transitionDuration}ms` }}
              onClick={handleToggleDebounced}
            />

            <div className={s['modal']} style={{ transitionDuration: `${transitionDuration}ms` }}>
              {children}
            </div>
          </>
        )}
      </div>
    </ReactPortal>
  );
};

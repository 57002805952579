import * as React from 'react';
import { useLocation } from "react-router-dom";

import { Unsubscribe as UnsubscribeComponent } from '../../components/unsubscribe/unsubscribe';

export const Unsubscribe: React.FC = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  return <UnsubscribeComponent email={query.get('email') || ''} />;
}

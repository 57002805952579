import { IDateRange } from '../../utils';

/**
 * Проверяет: входит ли дата хотя бы в один из диапазонов дат
 * Диапазоны в формате [{ from?: 'YYYY-MM-dd', to?: 'YYYY-MM-dd' }]
 */
export const isDateIncludedInDateRanges = (dateRanges: IDateRange[], date = new Date()) => {
  if (!dateRanges?.length) {
    return false;
  }

  const dateFormatted = new Date(date);
  dateFormatted.setUTCHours(0, 0, 0, 0);

  return dateRanges.some(dateRange => {
    let result = true;

    if (dateRange.from) {
      result = dateFormatted >= new Date(dateRange.from);
    }

    if (result && dateRange.to) {
      result = dateFormatted <= new Date(dateRange.to);
    }

    return result;
  });
};

/**
 * Создает функцию-декоратор, которая откладывает повторный вызов fn до тех пор,
 * пока не истечет время ожидания ms с момента последнего вызова функции-декоратора.
 */
export function throttle(fn: Function, ms = 300) {
  let timeoutId: ReturnType<typeof setTimeout>;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return function (this: any, ...args: any[]) {
    if (timeoutId) {
      return;
    }

    timeoutId = setTimeout(() => {
      clearTimeout(timeoutId);
    }, ms);

    fn.apply(this, args);
  };
}

import * as React from 'react';

import { Discussed } from '../discussed/discussed';
import { Subscribe } from '../subscribe/subscribe';
import { SocialNetworks } from '../../components/social_networks/social_networks';
import { Redaction } from '../../components/redaction/redaction';
import { BreadCrumbs } from '../bread_crumbs/bread_crumbs';
import { AdfoxBannerArticleAside } from '../../components/AdfoxBanner';

import s from './aside.css';

export const Aside = () => (
  <div className={`${s['aside']} cg-col-7 cg-col-7`}>
    <Discussed />
    <Subscribe />
    <AdfoxBannerArticleAside />
    <SocialNetworks />
    <Redaction />
    <BreadCrumbs mobile={true} />
  </div>
);

import * as queryString from 'qs';

import { ILike } from '../../../types/likes';
import { IApplicationContext } from "../../../types/applicationContext";

interface ILikeInfo {
  data: {
    attributes: {
      likesCount: number;
      dislikesCount: number;
    };
    id: string;
    type: string;
  };
}

export const loadLikeInfo = (
    type: string,
    id: string,
    vote: 1|-1,
    onSuccess: (data: any) => void,
    onFail: () => void,
) => {
  return (dispatch: any, getState: () => any, context: IApplicationContext) => {
    const params = queryString.stringify({
      type,
      vote,
      object_pk: id,
      page_size: '99999',
    });
    const {custom: {subdomain}} = context;

    return context.makeRequest(
      {
        method: 'GET',
        uri: {
          subdomain: subdomain,
          path: '/v1/get-like-list',
          query: params,
        },
      })
      .then((likes: { data: ILike[] }) => {
        onSuccess(likes.data);
      })
      .catch((err: Error) => {
        onFail();
      },
    );
  };
};

export const submitVote = (
  type: string,
  objectID: string,
  vote: 1|-1,
  onSuccess: (likes: number, dislikes: number) => void,
  onFail: () => void,
) => {
  return (dispatch: any, getState: () => any, context: IApplicationContext) => {
    const {custom: {subdomain}} = context;
    return context.makeRequest(
      {
        method: 'POST',
        uri: {
          subdomain: subdomain,
          path: '/v1/add-like',
        },
        headers: [
          ['Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8'],
        ],
        body: queryString.stringify({
          content_type_model: type,
          vote,
          object_pk: objectID,
        }),
      },
    )
    .then((data: ILikeInfo) => {
      onSuccess(data.data.attributes.likesCount, data.data.attributes.dislikesCount);
    })
    .catch((err: Error) => {
      onFail();
    });
  };
};

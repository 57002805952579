import { EUserAccessType, EType, IPageInfoSchema } from '../repositories/journal/entities/page_info/PageInfoSchema';

export const PageMetaDefault: IPageInfoSchema = {
  rubricId: 0,
  category: null,
  metaTitle: 'ЦИАН - Новости, статьи и аналитика рынка недвижимости в Москве и России',
  metaDescription: 'ЦИАН - новости, статьи, аналитика и другая информация о рынке недвижимости в Москве и России',
  metaKeywords: 'новости, статьи, журнал, недвижимость, информация',
  metaImage: '',
  canonicalUrl: 'https://www.cian.ru/magazine/',
  noIndex: false,
  type: EType.MainPage,
  title: '',
  breadCrumbName: 'Журнал',
  breadCrumbParents: [],
  pathname: '/magazine/',
  audienceAccessType: null,
  userAccessType: EUserAccessType.Unauthorised,
};

import { IPaginationItem } from '../../../types/pagination';
import { correctPathname } from '../../../utils';
import { ArrowR16 as ChevronRight16, ArrowL16 as ChevronLeft16 } from '@cian/ui-kit';

interface IGeneratePageDataParams {
  /** Номер текущей страницы */
  activeNumber: number;
  /** Текущий относительный урл для формирования ссылки на страницы */
  pathname: string;
  /** Объект QS */
  searchParams: URLSearchParams;
  /** Реальное количество страниц */
  totalPages: number;
  /** Максимум отображаемых страниц без учета стрелок */
  maxShownPages: number;
  /**
   * Всегда ли показывать первую страницу
   * (если истина, то '1 ... 5 6 7', иначе '3 4 5 6 7')
   */
  showFirstPage?: boolean;
}

/**
 * Генерирует коллекцию данных страниц для пагинации
 */
export const generatePagesData = ({
  activeNumber,
  pathname,
  searchParams,
  totalPages,
  maxShownPages,
  showFirstPage = false,
}: IGeneratePageDataParams): IPaginationItem[] => {
  /**
   * Минимальная длина цикла перебора
   * Может оказаться, что всего страниц меньше,
   * чем количество показываемых
   */
  const minCount = Math.min(maxShownPages, totalPages);
  /** Коллекция страниц */
  const pages = [] as IPaginationItem[];

  if (activeNumber > totalPages) {
    return pages;
  }

  for (let i = 0; i < minCount; i++) {
    let pageNumber;
    let pageLabel;

    // Сохраняем активную страницу и страницы, идущие до нее (1 2 3 [4])
    if (activeNumber - i > 0) {
      /*
       * Частный случай:
       * - когда всегда надо показывать первую страницу с многоточием (1 ... 4 5 6)
       */
      if (showFirstPage && activeNumber > minCount && minCount - 1 === i) {
        pageNumber = 1;
        pageLabel = '1';
      } else if (showFirstPage && activeNumber > minCount && minCount - 2 === i) {
        pageNumber = activeNumber - i;
        pageLabel = '…';
      } else {
        /*
         * Общий случай:
         * - когда всегда надо показывать первую страницу и можно обойтись без многоточия (1 2 3 4 5)
         * - когда необязательно показывать первую страницу (3 4 5 6 7)
         */
        pageNumber = activeNumber - i;
        pageLabel = String(pageNumber);
      }

      searchParams.set('page', String(pageNumber));
      pages.unshift({
        label: pageLabel,
        number: pageNumber,
        link: `${correctPathname(pathname)}?${searchParams.toString()}`,
      });
    } else {
      // Страницы, следующие за активной (5 6 7 8)
      pageNumber = pages.length + 1;
      pageLabel = String(pageNumber);

      searchParams.set('page', pageLabel);
      pages.push({
        label: pageLabel,
        number: pageNumber,
        link: `${correctPathname(pathname)}?${searchParams.toString()}`,
      });
    }
  }

  // Добавляем кнопки-стрелки
  if (pages.length) {
    // Левая кнопка ведет на предыдущую страницу относительно активной
    let activeIndex = pages.findIndex(page => page.number === activeNumber);
    let pageNumber = pages[activeIndex].number - 1 || 1;

    searchParams.set('page', String(pageNumber));
    pages.unshift({
      label: '',
      number: pageNumber,
      link: `${correctPathname(pathname)}?${searchParams.toString()}`,
      Icon: ChevronLeft16,
    });

    // Правая кнопка ведет на следующую страницу относительно активной
    activeIndex = pages.findIndex(page => page.number === activeNumber);
    pageNumber = Math.min(pages[activeIndex].number + 1, totalPages);

    searchParams.set('page', String(pageNumber));
    pages.push({
      label: '',
      number: pageNumber,
      link: `${correctPathname(pathname)}?${searchParams.toString()}`,
      Icon: ChevronRight16,
    });
  }

  return pages;
};

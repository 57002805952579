import { IMenuItem } from '../../../components/MainMenuGroup';
import { transformSubItem } from './transformSubItem';
import { IMenuMainItemV2Schema } from '../../../repositories/journal/entities/menu/MenuMainItemV2Schema';

/**
 * Трансформирует основной пункт меню
 */
export const transformItem = (item: IMenuMainItemV2Schema): IMenuItem => {
  const { id, name, pathname, items } = item;

  return {
    slug: `${name}-${id}`,
    label: name,
    url: pathname,
    controlsType: items?.length ? 'plusAndCross' : undefined,
    items: items?.length
      ? items.sort((prev, next) => (prev.blockOrder || 0) - (next.blockOrder || 0)).map(item => transformSubItem(item))
      : undefined,
  };
};

import * as React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { selectUser } from '../../../selectors/user';
import { IApplicationState } from '../../../types/redux';
import { selectCurrentPageMeta } from '../../../selectors/pagesMeta';
import { EUserRole } from '../../../types/user';
import { isAdminOrModerator } from '../../../utils';
import { MIN_RATING_LIMIT } from '../../../constants';

/**
 * HOF: Обертка для листингов типа "специалист" с проверкой на роли и рейтинг
 */
export function withRatingAccess<T, S>(
  RequestedComponent: React.ComponentType<T>,
  StubComponent: React.ComponentType<S>,
) {
  function ComponentWithRatingAccess(props: T) {
    const { pathname } = useLocation();
    const { userAccessType } = useSelector((state: IApplicationState) => selectCurrentPageMeta(state, pathname));
    const { isAuthenticated, roles, rating } = useSelector(selectUser);

    // Если не требует авторизации, то показываем компонент
    if (userAccessType === 'unauthorised') {
      return <RequestedComponent {...(props as T)} />;
    }

    // Если требуется авторизация и пользователь не авторизован, то показываем заглушку
    if (userAccessType === 'authorised' && !isAuthenticated) {
      return <StubComponent {...({} as S)} />;
    }

    // Если требуется авторизация, а пользователь является админом/модератором или имеет рейтинг >= MIN_RATING_LIMIT, то показываем
    if (
      (roles?.length && isAdminOrModerator(roles.map(role => role as EUserRole))) ||
      (rating && rating >= MIN_RATING_LIMIT)
    ) {
      return <RequestedComponent {...(props as T)} />;
    }

    // Если условия не выполняются, то показываем заглушку
    return <StubComponent {...({} as S)} />;
  }

  ComponentWithRatingAccess.displayName = `withRatingAccess(${
    RequestedComponent.displayName || RequestedComponent.name
  }`;

  return ComponentWithRatingAccess;
}

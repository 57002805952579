/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TGetRecommendedOffersModel,
  IGetRecommendedOffersRequest,
  IMappers,
  IGetRecommendedOffersResponse,
  IGetRecommendedOffersResponseError,
  TGetRecommendedOffersResponse,
} from './types';

const defaultConfig: TGetRecommendedOffersModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'POST',
  microserviceName: 'search-engine',
  pathApi: '/v3/get-recommended-offers/',
  hostType: 'api',
} as TGetRecommendedOffersModel;

function createGetRecommendedOffersModel(parameters: IGetRecommendedOffersRequest): TGetRecommendedOffersModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetRecommendedOffersOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IGetRecommendedOffersRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchGetRecommendedOffers<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IGetRecommendedOffersOptions<TResponse200, TResponse400>): Promise<
  TResponse200 | TResponse400 | TGetRecommendedOffersResponse
> {
  const { response, statusCode } = await httpApi.fetch(createGetRecommendedOffersModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetRecommendedOffersResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IGetRecommendedOffersResponseError);
    }
  }

  return { response, statusCode } as TGetRecommendedOffersResponse;
}

export { defaultConfig, createGetRecommendedOffersModel, fetchGetRecommendedOffers };

/* eslint-disable */
//tslint:disable

export interface IGetJournalMoreDataSchema {
  /** Дата публикации **/
  datePublish?: string | null;
  /** Ссылка на миниатюру **/
  imageThumbnail?: string | null;
  /** Количество просмотров **/
  numViews?: number | null;
  /** Подзаголовок **/
  subtitle: string;
  /** Заголовок **/
  title?: string | null;
  /** Тип **/
  type: EType;
  /** Ссылка на ресурс **/
  url: string;
}

export enum EType {
  /** Статьи **/
  Articles = 'articles',
  /** Блоги **/
  Blogs = 'blogs',
  /** 'Новости **/
  News = 'news',
  /** Вопросы **/
  Questions = 'questions',
  /** Опросы **/
  Quizzes = 'quizzes',
}

import { ITypedReduxAction } from '../../../types/redux/actionType';
import { IArticleList } from '../../../types/articleList';

export enum EGetArticleListActionType {
  Loading = 'articleList/getArticleList/loading',
  Succeed = 'articleList/getArticleList/succeed',
  AppendSucceed = 'articleList/getArticleList/appendSucceed',
  Failed = 'articleList/getArticleList/failed',
}

export type TGetArticleListLoading = ITypedReduxAction<EGetArticleListActionType.Loading>;
export type TGetArticleListSucceed = ITypedReduxAction<EGetArticleListActionType.Succeed, IArticleList>;
export type TGetArticleListAppendSucceed = ITypedReduxAction<EGetArticleListActionType.AppendSucceed, IArticleList>;
export type TGetArticleListFailed = ITypedReduxAction<EGetArticleListActionType.Failed>;

export type TGetArticleList =
  | TGetArticleListLoading
  | TGetArticleListSucceed
  | TGetArticleListAppendSucceed
  | TGetArticleListFailed;

import { IApplicationState } from '../../../types/redux';
import { transformGroup } from './transformGroup';
import { IMenuGroup } from '../../../components/MainMenuGroup';

/**
 * Селектор групп меню с трансформацией формата
 */
export const selectAllGroups = ({ menu }: IApplicationState): IMenuGroup[] =>
  menu.items.map(item => {
    return transformGroup(item);
  });

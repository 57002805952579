import { ERequestStatus } from '../../types/requestStatus';
import { TTagsNewState } from '../../types/tags';
import { EGetTagsActionType, TTagsActions } from '../../actions/tags';

export const defaultState: TTagsNewState = {
  status: ERequestStatus.Initial,
  items: [],
};

export const tagsReducer = (state = defaultState, action: TTagsActions) => {
  switch (action.type) {
    case EGetTagsActionType.Loading:
      return { ...state, status: ERequestStatus.Loading };

    case EGetTagsActionType.Succeed:
      return { ...state, items: action.payload, status: ERequestStatus.Succeed };

    case EGetTagsActionType.Failed:
      return { ...state, status: ERequestStatus.Failed };

    case EGetTagsActionType.Reset:
      return defaultState;

    default:
      return state;
  }
};

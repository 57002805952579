import { Ticker } from '@cian/adfox-templates';
import { Label, Image, UIHeading4, UIHeading5, type TDeviceType } from '@cian/ui-kit';
import React, { FC } from 'react';

import styles from './TgbNativedescGeneral.css';
import { clsx } from 'clsx';
import { type AdfoxBannerPlace } from '../../../external/context/AdfoxContext';

export type TgbNativedescGeneralProps = {
  place: AdfoxBannerPlace;
  deviceType: TDeviceType;
  url: string;
  image: string;
  logoUrl: string | undefined;
  title: string;
  annotation: string;
  label: string | undefined;
  description: string | undefined;
};

export const TgbNativedescGeneral: FC<TgbNativedescGeneralProps> = props => {
  const { title, annotation, image, url, label, logoUrl, description, deviceType, place } = props;

  const containerClassNames = ((): string => {
    let cn = clsx(
      styles['container'],
      deviceType === 'desktop' ? styles['container--desktop'] : styles['container--mobile'],
    );

    switch (place) {
      case 'articleFooter':
      case 'articleAside':
        cn = clsx(cn, styles['container--image-bottom']);

        break;

      case 'mainPageArticle':
        cn = clsx(cn, styles['container--mp-article']);

        break;
    }

    return cn;
  })();

  return (
    <a
      data-testid="TgbNativedescGeneral"
      href={url}
      target="_blank"
      className={containerClassNames}
      rel="noopener noreferrer"
    >
      <div className={styles['photo-block']} style={{ backgroundImage: `url(${image})` }}>
        {!!label && (
          <div className={styles['photo-label']}>
            <Label color="white_100" background="black_60">
              {label}
            </Label>
          </div>
        )}
        {!!annotation && (
          <div className={styles['annotation']}>
            <Ticker text={annotation} withBackground />
          </div>
        )}
      </div>
      <div className={styles['content']}>
        <div className={styles['title-container']}>
          <UIHeading4>{title}</UIHeading4>
          {!!description && <UIHeading5>{description}</UIHeading5>}
        </div>
        {!!logoUrl && (
          <div className={styles['logo-container']}>
            <Image src={logoUrl} borderRadius="4px" width={40} height={40} objectFit="contain" />
          </div>
        )}
      </div>
    </a>
  );
};

import { ca } from '@cian/analytics';

export function trackFileDownloadClick(label: string) {
  ca('event', {
    name: 'oldevent',
    category: 'Magazine',
    action: 'download_doc',
    label,
  });
}

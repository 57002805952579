import { TSubmenuItem } from '../../../components/MainMenuGroup';
import { IMenuItemV2Schema } from '../../../repositories/journal/entities/menu/MenuItemV2Schema';

/**
 * Трансформирует подпункт меню
 */
export const transformSubItem = (item: IMenuItemV2Schema): TSubmenuItem => {
  const { id, name, pathname } = item;

  return {
    slug: `${name}-${id}`,
    label: name,
    url: pathname,
  };
};

import * as React from 'react';
import { Link } from 'react-router-dom';

import s from './tags.css';

interface IProps {
  tags: string[];
  refreshScroll?(): void;
  type: string;
}

export const Tags = (props: IProps) => {
  return (
    <div className={s['tags']}>
      {props.tags.map((tag: string, i: number) => (
        <Link
          key={`tag-${i}`}
          className={s['tags__link']}
          to={`/magazine/?tag=${tag}`}
          onClick={() => {
            if (props.refreshScroll) {
              props.refreshScroll();
            }
          }}
        >
          #{tag}
        </Link>
      ))}

      <div className={s['tags_schema_org']} itemProp="about" itemScope itemType="https://schema.org/Thing">
        {props.tags.map((tag, index) => (
          <span key={index} itemProp="name">
            {tag}
          </span>
        ))}
      </div>
    </div>
  );
};

import React from 'react';

import * as s from './SearchAndRegionsButtonsLayout.css';

/**
 * Шаблон для контейнеров SearchButtonContainer и RegionsButtonContainer
 */
export const SearchAndRegionsButtonsLayout = ({ children }: { children: React.ReactNode }) => (
  <div className={s['wrapper']}>{children}</div>
);

import * as React from 'react';
import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Select } from "@cian/ui-kit";

import { IRating, IRatingItem } from '../../types/rating';
import { IRegionsItem } from '../../types/regions';
import { IUser } from '../../types/user';
import { IConfig } from '../../types/config';
import { RatingRow } from './rating_row';

import s from './rating_table.css';

interface IProps {
  rating: IRating;
  regions: IRegionsItem[];
  user: IUser;
  config: IConfig;
}

const HEADER_ITEMS: { [index: string]: string; } = {
  rating: 'Рейтинг',
  advicesCount: 'Все',
  bestAdvicesCount: 'Лучшие',
  blogsCount: 'Все',
  popularBlogsCount: 'Популярные',
  reviewsCount: 'Все',
  popularReviewsCount: 'Популярные',
};

export const RatingTable: React.FC<IProps> = props => {
  const [rulesShown, setRulesShown] = useState(true);
  const [activeSorting, setActiveSorting] = useState('rating');
  const [sortDirectionAsc, setSortDirectionAsc] = useState(false);

  let browserHistory = useHistory();
  const currentLocation = useLocation();
  const query = new URLSearchParams(currentLocation.search);

  const year = query.get("year");
  const agentId = query.get("agent_id") || '';
  const location = query.get("location") || props.config.regionID.toString() || '0';

  function getSortedItems() {
    return props.rating.items.sort((a: IRatingItem, b: IRatingItem) => {
      const sortDirectionModificator = sortDirectionAsc ? 1 : -1;

      const aSortValue: number = (a.attributes as any)[activeSorting];
      const bSortValue: number = (b.attributes as any)[activeSorting];
      if (aSortValue > bSortValue) {
        return 1 * sortDirectionModificator;
      }

      if (aSortValue < bSortValue) {
        return -1 * sortDirectionModificator;
      }

      return a.attributes.position > b.attributes.position ? 1 : -1;
    });
  }

  function renderHeaderItem(id: string, value: string, i: number) {
    return (
      <th
          key={`header-item-${i}`}
          onClick={() => {
            setRulesShown(rulesShown);
            setSortDirectionAsc((activeSorting !== id) ? false : !sortDirectionAsc);
            setActiveSorting(id);
          }}
          className={`
            ${s['table-heading_cell']}
            ${sortDirectionAsc ? s['down'] : s['up']}
            ${(activeSorting === id) ? s['active'] : ''}
          `}>
        <span>{ value }</span>
      </th>
    );
  }

  function renderScoreRules() {
    return (
      <div className={`${s['tip-points-block']} cg-row`}>
        <h3>Рейтинг складывается из суммы баллов:</h3>
        <div className={`${s['tip-points-block-col']} cg-col-6 cg-col-xs-24`}>
          <span className={s['tip-points-block_name']}>Совет</span>
          <span className={s['tip-points-block_value']}>+1</span>
          <span className={s['tip-points-block_name']}>Лучший совет</span>
          <span className={s['tip-points-block_value']}>+5</span>
        </div>
        <div className={`${s['tip-points-block-col']} cg-col-6 cg-col-xs-24`}>
          <span className={s['tip-points-block_name']}>Пост в блоге</span>
          <span className={s['tip-points-block_value']}>+2</span>
          <span className={`${s['tip-points-block_name']} ${s['tip-points-block_popular']}`}>Популярный пост</span>
          <span className={s['tip-points-block_value']}>+10</span>
          <p className={s['tip-points-block_tip']}>Разница положительных и отрицательных оценок в 5 баллов и больше</p>
        </div>
        <div className={`${s['tip-points-block-col']} cg-col-6 cg-col-xs-24`}>
          <span className={s['tip-points-block_name']}>Отзыв о ЖК</span>
          <span className={s['tip-points-block_value']}>+2</span>
          <span className={`${s['tip-points-block_name']} ${s['tip-points-block_popular']}`}>Популярный отзыв о ЖК</span>
          <span className={s['tip-points-block_value']}>+10</span>
          <p className={s['tip-points-block_tip']}>Разница положительных и отрицательных оценок в 5 баллов и больше</p>
        </div>
      </div>
    );
  }

  const getYears = () => {
    const years = [{value: 'all', label: 'За все время'}];
    let currentYear = new Date().getFullYear();

    while (currentYear >= 2010) {
      years.push({value: currentYear.toString(), label: currentYear.toString()});
      currentYear--;
    }

    return years;
  }

  return (<div className={s['rating-table']}>
      <div className={`${s['rating-table-header']} ${rulesShown ? s['rating-table-header-rules'] : ''}`}>
        <h1 className={s['rating-table-header_heading']}>рейтинг специалистов</h1>
        <span
            onClick={() => {
                setRulesShown(!rulesShown);
                setSortDirectionAsc(sortDirectionAsc);
                setActiveSorting(activeSorting);
              }
            }
            className={s['rating-table-header_rules']}>
          Правила расчёта рейтинга
        </span>
        <span
            className={`
              ${s['rating-table-header_rules-icon']}
              ${rulesShown ? s['rating-table-header_rules-icon-open'] : ''}
            `} />
      </div>
      {
        rulesShown && renderScoreRules()
      }
      <div className={`${s['rating-table-select-wrap']} cg-row`}>
        <Select
            inline={true}
            value={location}
            onChange={(value) => {
              let locationNew = Object.assign({}, currentLocation);

              let query = new URLSearchParams(locationNew.search);
              query.set("location", value.toString());
              locationNew.search = query.toString();

              browserHistory.push(locationNew);
            }}
            options={props.regions.map((region: any) => {
              return {
                value: `${region.id}`,
                label: region.displayName,
              };
            })} />
        <div className={s['rating-filter']} />
        <Select
            inline={true}
            value={year || 'all'}
            onChange={(value) => {
              let locationNew = Object.assign({}, currentLocation);

              let query = new URLSearchParams(locationNew.search);
              query.set("year", value.toString());
              locationNew.search = query.toString();

              browserHistory.push(locationNew);
            }}
            options={getYears()} />
      </div>
      <div className={s['table-wrap']}>
        <table className={`${s['table']} cg-row`}>
          <thead>
            <tr className={`${s['table-heading-1']}`}>
              <td />
              <td />
              <td />
              <th colSpan={2} scope="colgroup" className={s['table-heading-1_cell']}>
                <span>Советы</span>
              </th>
              <th colSpan={2} scope="colgroup" className={s['table-heading-1_cell']}>
                <span>Блоги</span>
              </th>
              <th colSpan={2} scope="colgroup" className={s['table-heading-1_cell']}>
                <span>Отзывы о ЖК</span>
              </th>
            </tr>
            <tr className={s['table-heading-2']}>
              <th className={`${s['table-heading_cell']} ${s['table-row_cell-not-hover']}`}>
                <span>№</span>
              </th>
              <th className={`${s['table-heading_cell']} ${s['table-row_cell-not-hover']}`}>
                <span>Специалист</span>
              </th>
              {
                Object.keys(HEADER_ITEMS).map((headerKey: string, i: number) =>
                  renderHeaderItem(headerKey, HEADER_ITEMS[headerKey], i),
                )
              }
            </tr>
          </thead>
          <tbody>
            {
              getSortedItems().map((item: IRatingItem, i: number) => <RatingRow key={`agent-${i}`} item={item} agentId={agentId} />)
            }
          </tbody>
        </table>
      </div>
    </div>
  );
}

import * as queryString from 'qs';

import { IPostCard, IPostCardAction } from '../../../types/post_card';
import { IGetRequest } from '../../../types/get_request';
import { options } from '../seo/seo';
import { IRegionsResponse } from '../../../types/regions';

export const POST_CARD = 'post_card/ACTION';
export const POST_CARD_REQUEST = 'post_card/ACTION_REQUEST';
export const POST_CARD_SUCCESS = 'post_card/ACTION_SUCCESS';
export const POST_CARD_FAILURE = 'post_card/ACTION_FAILURE';

export const POST_CARD_HIDE = 'post_card_hide/ACTION';
export const POST_CARD_HIDE_REQUEST = 'post_card_hide/ACTION_REQUEST';
export const POST_CARD_HIDE_SUCCESS = 'post_card_hide/ACTION_SUCCESS';
export const POST_CARD_PUBLISH = 'post_card_publish/ACTION';
export const POST_CARD_PUBLISH_SUCCESS = 'post_card_publish/ACTION_SUCCESS';
export const POST_CARD_EDIT = 'post_card_edit/ACTION';
export const POST_CARD_EDIT_NOT = 'post_card_edit_not/ACTION';

export const COMMENT_SUCCESS = 'comment/ACTION_SUCCESS';
export const COMMENT_EDIT_SUCCESS = 'comment_edit/ACTION_SUCCESS';
export const COMMENT_DELETE_SUCCESS = 'comment_delete/ACTION_SUCCESS';
export const COMMENT_HIDE_SUCCESS = 'comment_hide/ACTION_SUCCESS';

export const initialState: IPostCard = {
  item: {
    attributes: {
      title: '',
      content: '',
      datePublish: '',
      image: '',
      numViews: 0,
      sectionUrl: '',
      subtitle: '',
      author: '',
      slug: '',
      comments: [],
      regions: [],
    } as any,
    id: '',
    type: '',
  } as any,
};

const defaultAction: IPostCardAction = {
  type: '',
  payload: {
    data: initialState.item,
  },
};

const PATHS: {[index: string]: string} = {
  'article': 'stati',
  'news': 'novosti',
  'blog': 'blogs',
  'question': 'forum-rieltorov',
  'questions': 'forum-rieltorov',
};

export const postCardReducer = (state = initialState, action = defaultAction) => {
  switch (action.type) {
    case POST_CARD_REQUEST:
      return state;

    case POST_CARD_SUCCESS:
      return Object.assign({}, state, {
        item: action.payload.data,
      });

    case POST_CARD_FAILURE:
      if (action.payload.error && (action.payload.error.statusCode == 301)) {
        const pathSegmentsOld = action.payload.error.path.split('?');
        const pathSegments = pathSegmentsOld[0].split('/');
        const pathObject = queryString.parse(pathSegmentsOld[1]);
        action.payload.error.path = `/${PATHS[pathSegments[pathSegments.length - 1].replace('get-', '')]}-${pathObject['id']}`;
      }
      return Object.assign({}, state, {
        error: action.payload.error,
      });

    case COMMENT_SUCCESS:
      const newState = Object.assign({}, state);

      newState.item.attributes.comments = state.item.attributes.comments.concat([{
        id: (action.payload as any).data.id,
        comment: (action.payload as any).data.attributes.comment,
        parentId: (action.payload as any).data.attributes.parentId,
        submitDate: '',
        datePublish: (action.payload as any).data.attributes.datePublish,
        comments: [],
        user: { ...(action as any).additionalFields.user, fullName: (action.payload as any).data.attributes.firstName},
      }]);

      return newState;

    case COMMENT_EDIT_SUCCESS:
      const stateEditComment = Object.assign({}, state);

      stateEditComment.item.attributes.comments = state.item.attributes.comments.map(comment => {
        if (Number(comment.id) === Number((action.payload as any).data.id)) {
          return {
            id: (action.payload as any).data.id,
            comment: (action.payload as any).data.attributes.comment,
            parentId: (action.payload as any).data.attributes.parentId,
            submitDate: '',
            datePublish: (action.payload as any).data.attributes.datePublish,
            comments: [],
            user: (action as any).additionalFields.user,
          };
        }

        return comment;
      });

      return stateEditComment;

    case COMMENT_DELETE_SUCCESS:
      const stateDeleteComment = Object.assign({}, state);

      stateDeleteComment.item.attributes.comments = state.item.attributes.comments.filter(comment => {
        return Number(comment.id) !== Number((action.payload as any).data.id);
      });

      return stateDeleteComment;

    case COMMENT_HIDE_SUCCESS:
      const stateHideComment = Object.assign({}, state);

      stateHideComment.item.attributes.comments = state.item.attributes.comments.filter(comment => {
        return Number(comment.id) !== Number((action.payload as any).data.id);
      });

      return stateHideComment;

      case POST_CARD_HIDE_REQUEST:
        return state;

      case POST_CARD_HIDE_SUCCESS:
        return Object.assign({}, state, {
          item: action.payload.data,
        });

    case POST_CARD_PUBLISH_SUCCESS:
      return Object.assign({}, state, {
        item: action.payload.data,
      });

    default:
      return state;
  }
};

export const getPostCard = (pathname: string, params?: { name: string; value: string | number }[]): IGetRequest => {
  return {
    type: POST_CARD,
    method: 'GET',
    payload: {
      pathname,
      params,
    },
    request: true,
    ...options,
  };
};

export const publishCard = (type: string, id: string, content: string, regions: IRegionsResponse[], title: string) => {
  return {
    type: POST_CARD_PUBLISH,
    method: 'POST',
    payload: {
      pathname: `/v1/edit-${type}`,
      params: [{
        name: 'id',
        value: id,
      }],
      data: {
        attributes: {
          content,
          status: 'unchecked',
          regions_id: regions.length > 0 ? regions.map((region: IRegionsResponse) => region.id) : [],
          title,
        },
      },
    },
    request: true,
    ...options,
  };
};

export const hideCard = (type: string, id: string): IGetRequest => {
  return {
    type: POST_CARD_HIDE,
    method: 'POST',
    payload: {
      pathname: `/v1/hide-${type}`,
      params: [{
        name: 'id',
        value: id,
      }],
    },
    request: true,
    ...options,
  };
};

export const editCard = () => {
  return {
    type: POST_CARD_EDIT,
  };
};

export const notEditCard = () => {
  return {
    type: POST_CARD_EDIT_NOT,
  };
};

import { HttpBadStatusCodeError } from '@cian/peperrors/shared';

import { TThunkAction } from '../../../types/redux';
import { getMainArticlesLoading } from './getMainArticlesLoading';
import { getMainArticlesSucceed } from './getMainArticlesSucceed';
import { getMainArticlesAppendSucceed } from './getMainArticlesAppendSucceed';
import { getMainArticlesFailed } from './getMainArticlesFailed';
import {
  fetchGetArticleMain,
  TGetArticleMainResponse,
  IGetArticleMainRequest,
} from '../../../repositories/journal/v2/get-article-main';

export interface IGetMainArticlesParams extends Pick<IGetArticleMainRequest, 'tag' | 'offset' | 'limit'> {
  setType?: 'set' | 'append';
}

export const getMainArticles = ({
  setType = 'set',
  ...otherProps
}: IGetMainArticlesParams): TThunkAction<Promise<void>> => {
  return async (dispatch, getState, context) => {
    const { httpApi, logger } = context;
    const {
      userNew: { isB2BModeEnabled },
      geo: { currentRegion },
    } = getState();

    dispatch(getMainArticlesLoading());

    try {
      const { response, statusCode }: TGetArticleMainResponse = await fetchGetArticleMain({
        httpApi,
        parameters: {
          regionId: currentRegion.id,
          b2b: isB2BModeEnabled,
          ...otherProps,
        },
      });

      if (statusCode !== 200 || !response?.data) {
        await Promise.reject(
          new HttpBadStatusCodeError('Не удалось загрузить статьи на Главной', statusCode, 'actions.getMainArticles'),
        );

        return;
      }

      if (setType === 'append') {
        dispatch(getMainArticlesAppendSucceed({ meta: response.meta, items: response.data }));
      } else {
        dispatch(getMainArticlesSucceed({ meta: response.meta, items: response.data }));
      }
    } catch (e) {
      logger.error(e, {
        domain: 'actions.getMainArticles',
      });

      dispatch(getMainArticlesFailed());
    }
  };
};

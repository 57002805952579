import { IHttpApi, IHTTPCompletedResponse, IHTTPRequestConfig, THTTPRequestMethod } from '@cian/http-api/shared';
import { escapeHtml } from './make_html_helper';
import { IUser } from '../../shared/types/user';
import { isServer } from '../../shared/utils/isomorphic';

const LOGIN_CODE = 202;
const REDIRECTION_CODE = 301;
const NOT_FOUND_CODE = 404;

export interface IRequestConfig {
  uri: {
    subdomain: string;
    scheme?: string;
    host?: string | null;
    port?: number;
    basePath?: string;
    path: string;
    query?: string;
  };
  method?: THTTPRequestMethod;
  headers?: [string, string][];
  body?: string;
  currentUser?: IUser;
}

export type IMakeRequest = <T>(requestConfig: IRequestConfig) => Promise<T>;

export function createMakeRequest(
  httpApi: IHttpApi,
  baseHost: string,
  baseScheme: string,
  basePath: string,
): IMakeRequest {
  return async (requestConfig: IRequestConfig) => {
    const currentUser = requestConfig.currentUser;

    if (!requestConfig.uri.host) {
      requestConfig.uri.host = baseHost;
    }
    const posJournal = requestConfig.uri.host.indexOf("journal");
    const posContent = requestConfig.uri.host.indexOf("backend-content");
    const posMonolith = requestConfig.uri.host.indexOf("monolith-python-head");
    if (posJournal < 0 && posContent < 0 && posMonolith < 0 && requestConfig.uri.subdomain) {
      requestConfig.uri.host = `${requestConfig.uri.subdomain}.${requestConfig.uri.host}`;
    } else {
      requestConfig.uri.subdomain = '';
    }
    if (!requestConfig.uri.scheme) {
      requestConfig.uri.scheme = baseScheme;
    }
    if (requestConfig.uri.basePath || (requestConfig.uri.basePath === '')) {
      requestConfig.uri.path = requestConfig.uri.basePath + requestConfig.uri.path;
    } else {
      requestConfig.uri.path = basePath + requestConfig.uri.path;
    }

    if (isServer) {
      requestConfig.headers?.push(['user-agent', 'frontend-content'])
    }

    // console.log('requestConfig.uri', requestConfig.uri);

    function handleError(error: any, responseResult?: IHTTPCompletedResponse | undefined) {
      throw error;
    }

    try {
      const result = await httpApi.httpRequest(requestConfig as IHTTPRequestConfig);
      try {
        if (result.responseStatusCode === LOGIN_CODE) {
          if ((typeof window !== 'undefined') && (window as any).triggerLogin &&
               currentUser && currentUser.userId === -1) {
            const loginDelay = 200;

            setTimeout(() => {
              (window as any).triggerLogin(
                JSON.parse(result.responseBody).data.attributes.email,
                JSON.parse(result.responseBody).data.attributes.password,
              ); }, loginDelay);
          }
        }
        if (result.responseStatusCode === NOT_FOUND_CODE) {
          handleError({
            statusCode: NOT_FOUND_CODE,
          });
          return;
        }
        if (result.responseStatusCode === REDIRECTION_CODE) {
          const headers = new Map(result.responseHeaders);
          const newPath = headers.get('location') as string;
          handleError({
            path: newPath,
            statusCode: REDIRECTION_CODE,
          });

          requestConfig.uri.path = newPath || requestConfig.uri.path;
          const redirectResult = await httpApi.httpRequest(requestConfig as IHTTPRequestConfig);
          return JSON.parse(redirectResult.responseBody);
        }
        return JSON.parse(escapeHtml(result.responseBody));
      } catch (error) {
        handleError(error, result);
      }
    } catch (error) {
      handleError(error);
    }
  };
}

import { IRating, IRatingAction } from '../../../types/rating';
import { IGetRequest } from '../../../types/get_request';
import { options } from '../seo/seo';
import { staticMetaProps } from './config';

export const RATING = 'rating/ACTION';
export const RATING_REQUEST = 'rating/ACTION_REQUEST';
export const RATING_SUCCESS = 'rating/ACTION_SUCCESS';
export const RATING_FAILURE = 'rating/ACTION_FAILURE';

export const initialState: IRating = {
  items: [],
};

const defaultAction: IRatingAction = {
  type: '',
  payload: {
    data: [],
  },
};

export const ratingReducer = (state = initialState, action = defaultAction) => {
  switch (action.type) {
    case RATING_REQUEST:
      return state;

    case RATING_SUCCESS:
      return Object.assign({}, state, {
        items: action.payload.data,
      });

    case RATING_FAILURE:
      return Object.assign({}, state);

    default:
      return state;
  }
};

export const getRating = (year: string, location: string, agentId: string): IGetRequest => {
  const params = [];

  if (year !== '') {
    params.push({
      name: 'year',
      value: year,
    });
  }
  if ((location !== '') && (location !== '0')) {
    params.push({
      name: 'region_id',
      value: location,
    });
  }
  if ((agentId !== '') && (agentId !== '0')) {
    params.push({
      name: 'agent_id',
      value: agentId,
    });
  }

  return {
    type: RATING,
    method: 'GET',
    payload: {
      pathname: '/v1/get-specialist-rating-list',
      params,
    },
    request: true,
    ...options,
    staticMetaProps,
  };
};

import * as React from 'react';

import * as Analytics from '../../../app/helpers/analytics_helper';
import { IUser } from '../../types/user';

import s from './likes_content.css';

interface IProps {
  disabled: boolean;
  likesCount: number;
  dislikesCount: number;
  onLikePressed(): void;
  onDislikePressed(): void;
  type: string;
  userLike: number;
  user?: IUser;
}

export class LikesContent extends React.Component<IProps, {}> {

  public render() {
    return (
      <div className={`${s['like']}`}>
        <h2 className={`${s['like__title']}`}>Понравилась статья?</h2>
        <div className={`${s['like__button-wrapper']}`}>
          <button className={`${s['like__button']} ${this.props.userLike === 1 ? s['like__button__check'] : ''}`} id="like"
              onClick={() => {
                if (!this.props.disabled && this.props.userLike === 0) {
                  Analytics.addLikeDislikeComplaint('add_like');
                  this.props.onLikePressed();
                }
              }}
          >
            <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#a)">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M9 6h4.3c.8 0 1.6.3 2.1 1 .5.5.7 1.3.6 2.1l-.8 4.6C15 15 13.9 16 12.6 16H4V8C4.1 8 6.4.5 6.4.5c0-.3.3-.5.6-.5 1.2 0 2 .5 2 2v4ZM0 8h2v8H0V8Z"
                      fill="#0468FF"/>
              </g>
              <defs>
                <clipPath id="a">
                  <path fill="#fff" d="M0 0h16v16H0z"/>
                </clipPath>
              </defs>
            </svg>
            <span className={`${s['like__button-text']}`}>Нравится</span>
            <span className={`${s['like__like-quantity']}`}>{ this.props.likesCount }</span>
          </button>
          <button className={`${s['like__button']} ${this.props.userLike === -1 ? s['like__button__check'] : ''}`} id="dislike"
              onClick={() => {
                if (!this.props.disabled && this.props.userLike === 0) {
                  Analytics.addLikeDislikeComplaint('add_dislike');
                  this.props.onDislikePressed();
                }
              }}
          >
            <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#a)">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M7 10H2.7c-.8 0-1.6-.3-2.1-1-.5-.5-.7-1.3-.6-2.1l.8-4.6C1 1 2.1 0 3.4 0H12v8c-.1 0-2.4 7.5-2.4 7.5 0 .3-.3.5-.6.5-1.2 0-2-.5-2-2v-4Zm9-2h-2V0h2v8Z"
                      fill="#0468FF"/>
              </g>
              <defs>
                <clipPath id="a">
                  <path fill="#fff" transform="rotate(-180 8 8)" d="M0 0h16v16H0z"/>
                </clipPath>
              </defs>
            </svg>
            <span className={`${s['like__button-text']}`}>Не нравится</span>
            <span className={`${s['like__like-quantity']}`}>{ this.props.dislikesCount }</span>
          </button>
        </div>
      </div>
    );
  }
}

import * as React from 'react';
import { IIconProps } from '@cian/ui-kit/icons/internal/types';

export const IconChevronDownSmall16 = ({ color }: IIconProps) => {
  const currentColor = color ? `var(--${color})` : 'currentColor';

  return (
    // eslint-disable-next-line react/forbid-elements
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.707 6.70706L11.2928 5.29285L7.99992 8.58574L4.70703 5.29285L3.29282 6.70706L7.99992 11.4142L12.707 6.70706Z"
        fill={currentColor}
      />
    </svg>
  );
};

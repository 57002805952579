import { EOrdering } from '../../../repositories/journal/v2/get-article-list';

export const DEFAULT_ORDERING_OPTIONS = [
  { name: EOrdering.Date_publish, title: 'Новое' },
  { name: EOrdering.Comments_count, title: 'Обсуждаемое' },
  { name: EOrdering.Num_views, title: 'Популярное' },
];

export const BLOGS_ORDERING_OPTIONS = [
  ...DEFAULT_ORDERING_OPTIONS,
  { name: EOrdering.Likes_count, title: 'Понравившееся' },
];

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { IApplicationState } from '../../types/redux';
import { selectCurrentPageMeta } from '../../selectors/pagesMeta';
import { selectCurrentListing } from '../../selectors/listings';
import { prepareQSParam } from '../prepareQSParam';

/**
 * Вычисляет текущий листинг и возвращает его мету
 */
export const useCurrentListing = () => {
  const { pathname, search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const { type: pageType } = useSelector((state: IApplicationState) => selectCurrentPageMeta(state, pathname));
  const currentListing = useSelector((state: IApplicationState) =>
    selectCurrentListing(state, {
      pageType,
      hasSearchingQuery: Boolean(prepareQSParam(searchParams.get('search')) || prepareQSParam(searchParams.get('tag'))),
    }),
  );
  const [listing, setListing] = useState(currentListing);

  useEffect(() => {
    setListing(currentListing);
  }, [currentListing]);

  return listing;
};

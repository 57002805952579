/* eslint-disable */
//tslint:disable

export interface IPageInfoSchema {
  /** Ограничения на аудиторию (null - без ограничений) **/
  audienceAccessType?: EAudienceAccessType | null;
  /** Заголовок страницы в хлебных крошках **/
  breadCrumbName: string;
  /** Родители в хлебных крошках в виде pathname (по порядку) **/
  breadCrumbParents: string[];
  /** Url для seo **/
  canonicalUrl: string;
  /** Категория **/
  category?: ECategory | null;
  /** Описание для seo **/
  metaDescription: string;
  /** Картинка для seo **/
  metaImage: string;
  /** Ключевые слова для seo **/
  metaKeywords: string;
  /** Заголовок для seo **/
  metaTitle: string;
  /** Ограничения на индексацию **/
  noIndex: boolean;
  /** Полный роут страницы **/
  pathname: string;
  /** id рубрики **/
  rubricId: number;
  /** Видимый заголовок страницы **/
  title: string;
  /** Тип точки входа **/
  type: EType;
  /** Ограничения на авторизацию **/
  userAccessType: EUserAccessType;
}

export enum EAudienceAccessType {
  /** B2B **/
  B2b = 'b2b',
  /** B2C **/
  B2c = 'b2c',
}
export enum ECategory {
  /** Аналитика **/
  Analytics = 'analytics',
  /** Городская недвижимость **/
  City_realty = 'city_realty',
  /** Коммерческая недвижимость **/
  Commerce_realty = 'commerce_realty',
  /** Новости компании **/
  Company = 'company',
  /** Загородная недвижимость **/
  Country_realty = 'country_realty',
  /** Зарубежная недвижимость **/
  Foreign_realty = 'foreign_realty',
  /** Ипотека **/
  Hypothec = 'hypothec',
  /** Интервью **/
  Interview = 'interview',
  /** Новости рынка **/
  Market = 'market',
  /** Тенденция рынка **/
  Market_trend = 'market_trend',
  /** Обзор **/
  Review = 'review',
  /** SEO **/
  Seo = 'seo',
  /** Специалист **/
  Specialist = 'specialist',
}
export enum EType {
  /** Статьи **/
  Articles = 'articles',
  /** Блоги **/
  Blogs = 'blogs',
  /** Документы **/
  Documents = 'documents',
  /** Главная страница **/
  MainPage = 'mainPage',
  /** Новости **/
  News = 'news',
  /** Страница **/
  Page = 'page',
  /** Пост **/
  PostCard = 'postCard',
  /** Вопросы **/
  Questions = 'questions',
}
export enum EUserAccessType {
  /** Авторизованный **/
  Authorised = 'authorised',
  /** Не авторизованный **/
  Unauthorised = 'unauthorised',
}

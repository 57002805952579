import * as React from 'react';
import { IIconProps } from '@cian/ui-kit/icons/internal/types';

export const IconDocuments40 = ({ color }: IIconProps) => {
  const currentColor = color ? `var(--${color})` : 'currentColor';

  return (
    // eslint-disable-next-line react/forbid-elements
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M26 17H16V19H26V17Z" fill={currentColor} />
      <path d="M16 21H29V23H16V21Z" fill={currentColor} />
      <path d="M29 25H16V27H29V25Z" fill={currentColor} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 3H25.4142L34 11.5858V33H11V3ZM13 5V31H32V13L24 13V5H13ZM26 6.41421L30.5858 11L26 11V6.41421Z"
        fill={currentColor}
      />
      <path d="M7 6H9V35H31V37H7V6Z" fill={currentColor} />
    </svg>
  );
};

import { IUserCa, IUserGaData } from '../../../types/analytics';

/**
 * Переводит данные пользователя из формата UserGa в формат CA $.user
 */
export const transformUser = (userGa: IUserGaData): IUserCa => ({
  abGroup: userGa?.abGroup ? Number(userGa.abGroup) : 100,
  email: userGa?.email || undefined,
  type: userGa?.userType || 'not_authorized',
  userId: userGa?.realtyUserId ? Number(userGa.realtyUserId) : undefined,
  cianUserId: userGa?.userId ? Number(userGa.userId) : undefined,
});

import React from 'react';
import { Link } from 'react-router-dom';
import { UIHeading2, UIHeading1, useDeviceType } from '@cian/ui-kit';

import { IMainNews } from '../../types/mainNews';
import { NewsItem } from './components/NewsItem';
import * as s from './MainNews.css';

/** Блок новостей на Главной */
export const MainNews = ({ items }: IMainNews) => {
  const deviceType = useDeviceType();
  const isPhone = deviceType === 'phone';
  const HeadingComponent = isPhone ? UIHeading2 : UIHeading1;

  return (
    <div className={s['wrapper']} data-web-ui-test-id="MainNews">
      <Link to={`/novosti/`} className={s['header-link']}>
        <HeadingComponent color={'black_100'}>Новости</HeadingComponent>
      </Link>

      <div className={s['items-wrapper']}>
        {items.map(item => (
          <NewsItem key={`news-${item.id}`} {...item} />
        ))}
      </div>

      <div className={s['more-link-wrapper']}>
        <div className={s['gradient']} />

        <Link to={`/novosti/`} className={s['more-link']}>
          Все новости
        </Link>
      </div>
    </div>
  );
};

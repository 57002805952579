import React from 'react';
import { useDeviceType } from '@cian/ui-kit';

import { BreadCrumbsContainer } from '../BreadCrumbs';
import { SearchButtonContainer } from '../Search/SearchButton';
import { RegionsButtonContainer } from '../Regions/RegionsButton';
import * as s from './ListingHeaderContainer.css';

/**
 * Шапка запрещенного листинга
 */
export const AccessDeniedHeaderContainer = () => {
  const deviceType = useDeviceType();
  const isPhone = () => deviceType === 'phone';
  const isDesktop = () => deviceType === 'desktop';

  return (
    <div className={s['wrapper']}>
      {/* Кнопки поиска и выбора региона */}
      <div className={`${s['search-and-regions-wrapper']} ${isDesktop() ? s['_active'] : ''}`}>
        <SearchButtonContainer />
        <RegionsButtonContainer />
      </div>

      {/* Отступ, если нет кнопок поиска и выбора региона */}
      {!isDesktop() && <div className={s['top-margin']} />}

      {/* Хлебные крошки */}
      {!isPhone() && (
        <div className={s['bread-crumbs-wrapper']}>
          <BreadCrumbsContainer />
        </div>
      )}

      <h1 className={s['title']}>Секретный раздел</h1>
    </div>
  );
};

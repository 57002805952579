import { ERequestStatus } from '../../types/requestStatus';
import { TAuthorArticlesState } from '../../types/authorArticles';
import { EGetAuthorArticlesActionType, TAuthorArticlesActions } from '../../actions/authorArticles';

export const defaultState: TAuthorArticlesState = {
  status: ERequestStatus.Initial,
  items: [],
};

export const authorArticlesReducer = (state = defaultState, action: TAuthorArticlesActions) => {
  switch (action.type) {
    case EGetAuthorArticlesActionType.Loading:
      return { ...state, status: ERequestStatus.Loading };

    case EGetAuthorArticlesActionType.Succeed:
      return { ...state, items: action.payload, status: ERequestStatus.Succeed };

    case EGetAuthorArticlesActionType.Failed:
      return { ...state, status: ERequestStatus.Failed };

    case EGetAuthorArticlesActionType.Reset:
      return defaultState;

    default:
      return state;
  }
};

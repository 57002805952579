import React, { useCallback } from 'react';
import { Image, UIHeading3 } from '@cian/ui-kit';
import { Link } from 'react-router-dom';

import { ISliderDataSchema } from '../../../repositories/journal/entities/slider/SliderDataSchema';
import { correctPathname } from '../../../utils';
import * as s from './MainPageSlide.css';
import { Rubric } from './components/Rubric';

export interface IMainPageSlide extends Pick<ISliderDataSchema, 'title' | 'image' | 'color'> {
  url: string;
  rubricName?: string;
  rubricUrl?: string;
  onSlideClick(e: React.MouseEvent): void;
  onRubricClick(e: React.MouseEvent): void;
}

/**
 * Элемент слайдера для Главной
 */
export const MainPageSlide = ({
  title,
  color,
  image,
  url,
  rubricName,
  rubricUrl,
  onSlideClick,
  onRubricClick,
}: IMainPageSlide) => {
  /** Отправляет клик по слайду родителю */
  const handleSlideClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      onSlideClick(e);
    },
    [onSlideClick],
  );

  return (
    <div className={s['wrapper']} onClick={handleSlideClick}>
      <div className={s['title-wrapper']}>
        {rubricName && (
          <div className={s['rubric-wrapper']}>
            <Rubric name={rubricName} url={rubricUrl} onClick={onRubricClick} backgroundColor={color || undefined} />
          </div>
        )}

        <Link
          to={correctPathname(url)}
          style={color ? { color } : undefined}
          className={s['title']}
          title={title}
          onClick={handleSlideClick}
        >
          <UIHeading3>{title}</UIHeading3>
        </Link>
      </div>

      <Image
        alt={title}
        borderRadius={'4px'}
        objectFit="cover"
        src={image || ''}
        theme="light"
        title={title}
        width={'100%'}
      />
    </div>
  );
};

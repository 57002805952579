import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { selectTags } from '../../selectors/tags/selectTags';
import { prepareQSParam } from '../prepareQSParam';

/**
 * Проверяет, содержится ли tag/with-tag из qs в наборе тегов, пришедших с бэка
 */
export const useValidTag = (qsName: 'tag' | 'with-tag' = 'tag') => {
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const { items: tags } = useSelector(selectTags);
  const [tag, setTag] = useState<string>();

  useEffect(() => {
    const qsValue = prepareQSParam(searchParams.get(qsName));

    if (!qsValue) {
      setTag(undefined);

      return;
    }

    setTag(tags.find(tag => tag.name === qsValue)?.name);
  }, [qsName, searchParams, tag, tags]);

  return tag;
};

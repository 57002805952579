import React from 'react';
import { useDeviceType, Spinner } from '@cian/ui-kit';
import { useSelector } from 'react-redux';

import { ContentTypeContainer, ListingTypeContainer } from '../ListingHeaderFilters';
import { BreadCrumbsContainer } from '../BreadCrumbs';
import { SearchButtonContainer } from '../Search/SearchButton';
import { RegionsButtonContainer } from '../Regions/RegionsButton';
import { selectSearchingResults } from '../../selectors/searchingResults';
import { numberWithDelimiter } from '../../utils';
import { ERequestStatus } from '../../types/requestStatus';
import * as s from './ListingHeaderContainer.css';

/**
 * Шапка листинга для страницы поиска
 */
export const SearchListingHeaderContainer = () => {
  const deviceType = useDeviceType();
  const isPhone = () => deviceType === 'phone';
  const isTablet = () => deviceType === 'tablet';
  const {
    meta: { total },
    status,
  } = useSelector(selectSearchingResults);

  return (
    <div className={s['wrapper']}>
      {/* Кнопки поиска и выбора региона */}
      <div className={`${s['search-and-regions-wrapper']} ${s['_active']}`}>
        <SearchButtonContainer />
        <RegionsButtonContainer />
      </div>

      {/* Хлебные крошки */}
      {!isPhone() && (
        <div className={s['bread-crumbs-wrapper']}>
          <BreadCrumbsContainer />
        </div>
      )}

      <h1 className={s['title']}>
        Найдено:{' '}
        {status === ERequestStatus.Loading ? (
          <Spinner size={isPhone() ? 28 : isTablet() ? 36 : 40} />
        ) : (
          numberWithDelimiter(total)
        )}
      </h1>

      {/* Кнопки выбора типа листинга */}
      <div className={s['group-wrapper']}>
        <div className={s['content-type-wrapper']}>
          <ContentTypeContainer />
        </div>

        {!isPhone() && (
          <div className={s['listing-type-wrapper']}>
            <ListingTypeContainer />
          </div>
        )}
      </div>
    </div>
  );
};

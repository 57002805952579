import { EPageType } from '../../../repositories/journal/v1/get-tags';
import { EType as EPagesMetaItemTypes } from '../../../repositories/journal/entities/page_info/PageInfoSchema';

/** Трансформирует pageType для апи-ручки тэгов */
export const transformPageType = (pageType: EPagesMetaItemTypes) => {
  switch (pageType) {
    case EPagesMetaItemTypes.News:
      return EPageType.News;

    case EPagesMetaItemTypes.Articles:
      return EPageType.Articles;

    case EPagesMetaItemTypes.Blogs:
      return EPageType.Blogs;

    case EPagesMetaItemTypes.Questions:
      return EPageType.Questions;

    case EPagesMetaItemTypes.Documents:
      return EPageType.Articles;

    case EPagesMetaItemTypes.MainPage:
      return EPageType.Main;

    case EPagesMetaItemTypes.Page:
    default:
      return undefined;
  }
};

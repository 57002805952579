import { connect } from 'react-redux';

import { ISubscribeProps, Subscribe as SubscribeComponent } from '../../components/subscribe/subscribe';
import { subscribe } from '../../reducers/modules/subscribe/subscribe';
import { Dispatch } from 'redux';
import { ISubscribe } from '../../types/subscribe';
import { IUser } from '../../types/user';

export const Subscribe = connect<
  Pick<ISubscribeProps, 'subscribeStatus' | 'user'>,
  Pick<ISubscribeProps, 'subscribe'>,
  {}>(
  (state: {
    subscribe: ISubscribe,
    user: IUser,
  }) => {
    return {
      user: state.user,
      subscribeStatus: state.subscribe,
    };
  },
  (dispatch: Dispatch) => ({
    subscribe: (email: string) => dispatch(subscribe(email) as any),
  }),
)(SubscribeComponent);

import { ITypedReduxAction } from '../../../types/redux/actionType';
import { TMainNewsItem } from '../../../types/mainNews';

export enum EGetMainNewsActionType {
  Loading = 'mainArticles/getMainNews/loading',
  Succeed = 'mainArticles/getMainNews/succeed',
  Failed = 'mainArticles/getMainNews/failed',
}

export type TGetMainNewsLoading = ITypedReduxAction<EGetMainNewsActionType.Loading>;
export type TGetMainNewsSucceed = ITypedReduxAction<EGetMainNewsActionType.Succeed, TMainNewsItem[]>;
export type TGetMainNewsFailed = ITypedReduxAction<EGetMainNewsActionType.Failed>;

export type TGetMainNews = TGetMainNewsLoading | TGetMainNewsSucceed | TGetMainNewsFailed;

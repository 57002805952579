import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  IQuestionsSubscribeCategory,
  IQuestionsSubscribeFormParams,
  QuestionsSubscribeForm,
} from '../../components/SubscribeForms';
import { validateEmail } from '../../utils';
import { subscribeToList, subscribeToListReset } from '../../reducers/modules/list_subscribe/list_subscribe';
import { listSubscribe } from '../../selectors/subscription/listSubscribe';
import { selectRegions } from '../../selectors/regions/selectRegions';
import { selectPageMetaItems } from '../../selectors/pagesMeta';

/**
 * Форма подписки для блогов
 */
export const QuestionsSubscribeFormContainer = () => {
  const [errorText, setErrorText] = useState<string>('');
  const [subscribedText, setSubscribedText] = useState<string>('');
  const dispatch = useDispatch();
  const { status, statusCode } = useSelector(listSubscribe);
  const { items: regions } = useSelector(selectRegions);
  const pagesMeta = useSelector(selectPageMetaItems);
  const questionCategories = pagesMeta.reduce((categories, pageMeta) => {
    if (pageMeta.type === 'questions' && pageMeta.category) {
      categories.push({ title: pageMeta.breadCrumbName, name: pageMeta.category });
    }

    return categories;
  }, [] as IQuestionsSubscribeCategory[]);

  /** Готовит сообщение об успехе */
  useEffect(() => {
    if (status === 'ok') {
      setSubscribedText('Теперь вам на почту будут приходить свежие вопросы авторов блога');

      return;
    }

    setSubscribedText('');
  }, [status]);

  /** Готовит сообщение о сетевой ошибке */
  useEffect(() => {
    if (statusCode === 400) {
      setErrorText('Что-то пошло не так. Попробуйте еще раз');

      return;
    }

    setErrorText('');
  }, [statusCode]);

  /** Сбрасывает состояние на дефолтное при уходе */
  useEffect(() => {
    return () => {
      dispatch(subscribeToListReset());
    };
  }, [dispatch]);

  /** Проверяет валидность значения и дергает ручку подписки */
  const handleSubmit = useCallback<IQuestionsSubscribeFormParams['onSubmit']>(
    (email, categoryNames, regionIds) => {
      if (!validateEmail(email) || !categoryNames.length) {
        setErrorText('Проверьте правильность написания почты и выберите хотя бы одну рубрику');

        return;
      }

      // не передаем массив с нулем [0]
      const hasRegionIds = Boolean(regionIds.filter(value => value).length);

      dispatch(subscribeToList('questions', email, categoryNames, hasRegionIds ? regionIds : undefined));
    },
    [dispatch],
  );

  const handleChange = useCallback(() => {
    if (errorText) {
      setErrorText('');
    }
  }, [errorText]);

  return (
    <QuestionsSubscribeForm
      regions={regions}
      categories={questionCategories}
      isDisabled={false}
      errorText={errorText}
      subscribedText={subscribedText}
      onChange={handleChange}
      onSubmit={handleSubmit}
    />
  );
};

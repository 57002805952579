import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { selectCurrentBreadCrumbs } from '../../selectors/breadCrumbs';
import { IApplicationState } from '../../types/redux';
import { selectCurrentPageMeta } from '../../selectors/pagesMeta';
import { useValidTag } from '../useValidTag';
import { prepareQSParam } from '../prepareQSParam';
import {
  QS_SEARCH_SUPPORTING_PAGE_PATHS,
  QS_TAG_SUPPORTING_PAGE_PATHS,
  QS_WITH_TAG_SUPPORTING_PAGE_PATHS,
} from '../../constants';

/**
 * Следит за текущими хлебными крошками
 */
export const useCurrentBreadCrumbs = () => {
  const { search, pathname } = useLocation();
  const searchParams = new URLSearchParams(search);
  const withTag = useValidTag('with-tag');
  const { pathname: currentPageMetaPathname } = useSelector((state: IApplicationState) =>
    selectCurrentPageMeta(state, pathname),
  );
  const currentBreadCrumbs = useSelector((state: IApplicationState) =>
    selectCurrentBreadCrumbs(state, {
      pathname: currentPageMetaPathname,
      withTag: (QS_WITH_TAG_SUPPORTING_PAGE_PATHS.includes(currentPageMetaPathname) && withTag) || undefined,
      search:
        (QS_SEARCH_SUPPORTING_PAGE_PATHS.includes(currentPageMetaPathname) &&
          prepareQSParam(searchParams.get('search'))) ||
        undefined,
      tag:
        (QS_TAG_SUPPORTING_PAGE_PATHS.includes(currentPageMetaPathname) && prepareQSParam(searchParams.get('tag'))) ||
        undefined,
    }),
  );
  const [breadCrumbs, setBreadCrumbs] = useState(currentBreadCrumbs);

  useEffect(() => {
    if (JSON.stringify(currentBreadCrumbs) === JSON.stringify(breadCrumbs)) {
      return;
    }

    setBreadCrumbs(currentBreadCrumbs);
  }, [breadCrumbs, currentBreadCrumbs]);

  return breadCrumbs;
};

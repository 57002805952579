import { TTgbNativedescGeneralContext } from '@cian/adfox-templates';
import { useDeviceType } from '@cian/ui-kit';
import * as React from 'react';

import { TgbNativedescGeneral } from './TgbNativedescGeneral';
import { useApplicationContext } from '../../../../../utils';
import { useAdfoxContext } from '../../../external/context/AdfoxContext';

import { useNewbuidingInfoForTgb } from './internal/hooks/useNewbuidingInfoForTgb';

type TgbNativedescGeneralContainerProps = {
  context: TTgbNativedescGeneralContext;
};

export const TgbNativedescGeneralContainer: React.FC<TgbNativedescGeneralContainerProps> = props => {
  const { context } = props;

  const deviceType = useDeviceType();

  const { annotation, image, url, description, title, label, logoImageUrl } = context;

  const { httpApi, logger } = useApplicationContext();
  const { place } = useAdfoxContext();

  const newbuidingInfoForTgb = useNewbuidingInfoForTgb({ httpApi, logger }, context);

  return (
    <TgbNativedescGeneral
      place={place}
      deviceType={deviceType}
      logoUrl={newbuidingInfoForTgb?.publisher.logo.url || logoImageUrl}
      url={url}
      label={label}
      image={image}
      annotation={annotation}
      title={title}
      description={description}
    />
  );
};

import * as React from 'react';
import { ModalWindow } from "@cian/ui-kit";

interface IProps {
  content: JSX.Element;
  shown: boolean;
  containerStyle?: string;
  onClose(): void;
}

export class Dialog extends React.Component<IProps> {
  public render() {
    if (this.props.shown) {
      return (
        <ModalWindow
          onClose={this.props.onClose}
          content={this.props.content}
          maxWidth="500px"
          fixed={false}
          size="XS"
          open={this.props.shown}
        />
      );
    } else {
      return (<div/>);
    }
  }
}

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Col, useDeviceType } from '@cian/ui-kit';

import { ListingHeaderContainer } from '../ListingHeader';
import { PaginationContainer } from '../Pagination';
import { prepareListingOrdering, preparePageNumber, useTimeoutEffect, buildPostUrl, useGetListing } from '../../utils';
import { ARTICLE_LIST_REQUEST_LIMIT } from '../../constants';
import { IApplicationState, TThunkDispatch } from '../../types/redux';
import { selectListingAppendType } from '../../selectors/settings/selectListingAppendType';
import { getArticleList, resetArticleList } from '../../actions/articleList';
import { selectCurrentPageMeta } from '../../selectors/pagesMeta';
import { EType } from '../../repositories/journal/entities/page_info/PageInfoSchema';
import {
  CardListingItem,
  ListingItem,
  SkeletonCardListingItem,
  SkeletonListingItem,
} from '../../components/ListingItem';
import { selectArticleList } from '../../selectors/articleList';
import { ERequestStatus } from '../../types/requestStatus';
import { selectFavoriteListingType } from '../../selectors/settings/selectFavoriteListingType';
import { EListingTypes } from '../../types/listing';
import { EType as EPostType } from '../../repositories/journal/entities/journal/JournalListAttributesSchema';
import { BreadCrumbsContainer } from '../BreadCrumbs';
import { NothingFound } from '../../components/NotFound';
import { unescapeHtml } from '../../../app/helpers/make_html_helper';
import { prepareSubtitle } from './helpers';
import * as s from './Listings.css';

/**
 * Листинг статей
 */
export const ArticleList = () => {
  const dispatch = useDispatch<TThunkDispatch>();
  const history = useHistory();
  const { search, pathname } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const qsPage = preparePageNumber(searchParams.get('page') as string);
  const qsOrdering = prepareListingOrdering(searchParams.get('ordering') || '') || '';
  const appendType = useSelector(selectListingAppendType);
  const favoriteListingType = useSelector(selectFavoriteListingType);
  const {
    type: pageType,
    pathname: currentPageTypePathname,
    rubricId,
    title,
  } = useSelector((state: IApplicationState) => selectCurrentPageMeta(state, pathname));
  const deviceType = useDeviceType();
  const isPhone = () => deviceType === 'phone';
  const [IsShownAsList, setIsShownAsList] = useState(false);
  const { status: articlesStatus, items: articles } = useSelector(selectArticleList);
  const [isSkeletonShown, setIsSkeletonShown] = useState(
    articlesStatus === ERequestStatus.Loading && appendType === 'set',
  );

  /** Если не нашел нужный тип, выкидывает на дефолтный */
  useEffect(() => {
    if (![EType.Articles].includes(pageType)) {
      history.replace(currentPageTypePathname);
    }
  }, [currentPageTypePathname, history, pageType]);

  /** Забирает статьи в стор и трекает показ листинга */
  useGetListing(
    () => {
      dispatch(
        getArticleList({
          tag: undefined,
          offset: (qsPage - 1) * ARTICLE_LIST_REQUEST_LIMIT,
          limit: ARTICLE_LIST_REQUEST_LIMIT,
          setType: appendType,
          rubricId: rubricId || undefined,
          ordering: qsOrdering || undefined,
        }),
      ).finally();
    },
    {
      dependencyNames: ['page', 'ordering', 'rubricId', 'deviceType'],
      eventTrackerData: {
        page: {
          extra: {
            journalPageType: 'Rubric',
            journalRubrics: title,
          },
        },
      },
    },
  );

  /** При выходе сбрасывает стор статей */
  useEffect(() => {
    return () => {
      dispatch(resetArticleList());
    };
  }, [dispatch]);

  /** Устанавливает вид листинга: карточки или список */
  useEffect(() => {
    setIsShownAsList(favoriteListingType === EListingTypes.List && deviceType !== 'phone');
  }, [favoriteListingType, deviceType]);

  /** Создает небольшую задержку перед скрытием скелетона, когда элементов не найдено */
  useTimeoutEffect(() => {
    setIsSkeletonShown(articlesStatus === ERequestStatus.Loading && appendType === 'set');
  }, [!isSkeletonShown || articles.length ? 0 : 500]);

  /**
   * При клике по плашке статьи роутит на соответствующую страницу статьи
   * При клике по шильдику рубрики в плашке статьи роутит на соответствующую рубрику
   */
  const handleClick = useCallback(
    (e, url: string) => {
      e.preventDefault();
      history.push(url);
    },
    [history],
  );

  return (
    <>
      <ListingHeaderContainer />

      <div className={IsShownAsList ? s['list-wrapper'] : s['cards-wrapper']}>
        {isSkeletonShown &&
          [...Array(6)].map((_, index) =>
            IsShownAsList ? (
              <div key={index} className={s['list-item-wrapper']}>
                <SkeletonListingItem />
              </div>
            ) : (
              <Col key={index} xs={12} m={6} l={4}>
                <SkeletonCardListingItem />
              </Col>
            ),
          )}

        {((articlesStatus === ERequestStatus.Succeed && appendType === 'set') || appendType === 'append') &&
          articles.map(
            (
              {
                id,
                attributes: {
                  image,
                  title,
                  subtitle,
                  datePublish,
                  commentsCount,
                  rubrics,
                  slug,
                  noIndex,
                  type,
                  disableComments,
                  likesCount,
                },
              },
              index,
            ) =>
              IsShownAsList ? (
                <div key={index} className={s['list-item-wrapper']}>
                  <ListingItem
                    title={unescapeHtml(title)}
                    subtitle={prepareSubtitle(subtitle, type)}
                    image={image}
                    rubric={rubrics?.length ? rubrics[0] : undefined}
                    datePublish={datePublish}
                    numComments={disableComments ? undefined : commentsCount}
                    numLikes={likesCount}
                    url={buildPostUrl({ type: EPostType.Articles, slug, id })}
                    noIndex={Boolean(noIndex)}
                    type={type}
                    onRubricButtonClick={handleClick}
                    onClick={e => handleClick(e, buildPostUrl({ type: EPostType.Articles, slug, id }))}
                  />
                </div>
              ) : (
                <Col key={index} xs={12} m={6} l={4}>
                  <CardListingItem
                    title={unescapeHtml(title)}
                    subtitle={prepareSubtitle(subtitle, type, Boolean(image))}
                    height={isPhone() ? undefined : 400}
                    image={image}
                    rubric={rubrics?.length ? rubrics[0] : undefined}
                    datePublish={datePublish}
                    numComments={disableComments ? undefined : commentsCount}
                    numLikes={likesCount}
                    url={buildPostUrl({ type: EPostType.Articles, slug, id })}
                    noIndex={Boolean(noIndex)}
                    type={type}
                    onRubricButtonClick={handleClick}
                    onClick={e => handleClick(e, buildPostUrl({ type: EPostType.Articles, slug, id }))}
                  />
                </Col>
              ),
          )}

        {[ERequestStatus.Succeed, ERequestStatus.Failed].includes(articlesStatus) && !articles.length && (
          <NothingFound pathname={currentPageTypePathname} showResetFiltersLink={qsPage > 1} />
        )}
      </div>

      {articlesStatus === ERequestStatus.Succeed && (
        <div className={s['controls-wrapper']}>
          <PaginationContainer />
        </div>
      )}

      {/* Хлебные крошки для мобилки */}
      {isPhone() && (
        <div className={s['bread-crumbs-wrapper']}>
          <BreadCrumbsContainer />
        </div>
      )}
    </>
  );
};

/**
 * Создает функцию-обертку, которая откладывает вызов fn до тех пор,
 * пока не истечет время ожидания ms с момента последнего вызова функции-обертки.
 */
export function debounce(fn: Function, ms = 300) {
  let timeoutId: ReturnType<typeof setTimeout>;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
}

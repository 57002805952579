import * as React from 'react';

import { connect } from 'react-redux';

import { IBreadCrumb } from '../../types/bread_crumb';
import { BreadCrumbs as BreadCrumbsComponent } from '../../components/bread_crumbs/bread_crumbs';

interface IProps {
  breadCrumb: IBreadCrumb;
  mobile?: boolean;
}

interface IContainerProps {
  mobile?: boolean;
}

const BreadCrumbsClass: React.FC<IProps> = props => {
  return <BreadCrumbsComponent path={props.breadCrumb.path} mobile={props.mobile} />;
};

export const BreadCrumbs = connect<IProps, {}, IContainerProps>(
  (
    state: {
      breadCrumb: IBreadCrumb;
    },
    props: {
      mobile?: boolean;
    },
  ) => ({
    breadCrumb: state.breadCrumb,
    mobile: props.mobile,
  }),
)(BreadCrumbsClass);

import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import { IconChevronRightSmall16 } from '../../components/Icons';
import { useCurrentBreadCrumbs } from '../../utils';
import * as s from './BreadCrumbsContainer.css';

/**
 * "Хлебные крошки" для текущей локации
 */
export const BreadCrumbsContainer = () => {
  const currentBreadCrumbs = useCurrentBreadCrumbs();

  if (!currentBreadCrumbs?.length) {
    return null;
  }

  return (
    <div className={s['wrapper']}>
      {currentBreadCrumbs.map(({ pathname, breadCrumbName, isParent }, index) => (
        <Fragment key={index}>
          {isParent ? (
            <Link to={pathname} className={s['link']} title={`Перейти в раздел '${breadCrumbName}'`}>
              {breadCrumbName}
            </Link>
          ) : (
            <span className={s['text']} title={breadCrumbName}>
              {breadCrumbName}
            </span>
          )}

          {index < currentBreadCrumbs.length - 1 && (
            <div className={s['delimiter']}>
              <IconChevronRightSmall16 />
            </div>
          )}
        </Fragment>
      ))}
    </div>
  );
};

import { HttpBadStatusCodeError } from '@cian/peperrors/shared';

import { TThunkAction } from '../../../types/redux';
import { getPostContentOffersLoading } from './getPostContentOffersLoading';
import { getPostContentOffersSucceed } from './getPostContentOffersSucceed';
import { getPostContentOffersFailed } from './getPostContentOffersFailed';
import {
  fetchGetRecommendations,
  IGetRecommendationsRequest,
  TGetRecommendationsResponse,
} from '../../../repositories/journal/v1/get-recommendations';
import { IPostContentRecommendedOffers } from '../../../types/postContentRecommendedOffers';

interface IGetPostContentOffersParams extends IGetRecommendationsRequest {
  id: IPostContentRecommendedOffers['id'];
  title?: IPostContentRecommendedOffers['title'];
  url?: IPostContentRecommendedOffers['url'];
  position: IPostContentRecommendedOffers['position'];
}

export const getPostContentOffers = ({
  id,
  title,
  url,
  position,
  ...params
}: IGetPostContentOffersParams): TThunkAction<Promise<void>> => {
  return async (dispatch, getState, context) => {
    const { httpApi, logger } = context;

    dispatch(getPostContentOffersLoading(id));

    try {
      const { response, statusCode }: TGetRecommendationsResponse = await fetchGetRecommendations({
        httpApi,
        parameters: params,
      });

      if (statusCode !== 200) {
        await Promise.reject(
          new HttpBadStatusCodeError(
            'Не удалось загрузить рекомендованные объявления',
            statusCode,
            'actions.getPostContentOffers',
          ),
        );

        return;
      }

      dispatch(
        getPostContentOffersSucceed({
          id,
          title,
          url,
          position,
          items: response?.data || [],
        }),
      );
    } catch (e) {
      logger.error(e, {
        domain: 'actions.getPostContentOffers',
      });

      dispatch(getPostContentOffersFailed(id));
    }
  };
};

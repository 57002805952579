import { createSelector } from 'reselect';

import { IApplicationState } from '../../types/redux';
import { EAudienceAccessType } from '../../repositories/journal/entities/page_info/PageInfoSchema';
import { ISliderNameAndAudienceType } from '../../types/slider';
import { createSlider } from '../../utils';

/** Выбирает слайдер по имени и аудитории */
export const selectSlider = createSelector(
  [
    (state: IApplicationState) => state.sliders.items,
    (state: IApplicationState) => state.userNew.isB2BModeEnabled,
    (_: IApplicationState, name: ISliderNameAndAudienceType['name']) => name,
  ],
  (items, isB2BModeEnabled, name) => {
    const audienceAccessType = isB2BModeEnabled ? EAudienceAccessType.B2b : EAudienceAccessType.B2c;

    return (
      items.find(item => item.name === name && item.audienceAccessType === audienceAccessType) ||
      createSlider({ name, audienceAccessType })
    );
  },
);

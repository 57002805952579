import { IGetRequest } from '../../../types/get_request';
import { IDiscussed, IDiscussedAction } from '../../../types/discussed';

export const DISCUSSED = 'discussed/ACTION';
export const DISCUSSED_REQUEST = 'discussed/ACTION_REQUEST';
export const DISCUSSED_SUCCESS = 'discussed/ACTION_SUCCESS';
export const DISCUSSED_FAILURE = 'discussed/ACTION_FAILURE';

export const initialState: IDiscussed = {
  data: [
    {
      type: '',
      id: '',
      attributes: {
        comment: '',
        datePublish: '',
        contentTypeModel: '',
        content: {
          title: '',
          id: 0,
          slug: '',
          type: '',
        },
        user: {
          isProfi: false,
          isBlock: false,
          linkUserProfiDescription: '',
          userIdentifications: false,
          userId: -1,
          email: '',
          userTrust: false,
          fullName: '',
          linkUserTrustDescription: '',
          avatar: '',
          permissions: {
            canModerateAnnouncements: false,
            canModerateAnnouncementsExpert: false,
            canModerateUsers: false,
            canViewAnnouncements: false,
            canViewUsers: false,
          },
          roles: [''],
        },
      },
    },
  ],
};

const defaultAction: IDiscussedAction = {
  type: '',
  payload: initialState,
};

export const discussedReducer = (state = initialState, action = defaultAction) => {
  switch (action.type) {
    case DISCUSSED_REQUEST:
      return Object.assign({}, state);
    case DISCUSSED_SUCCESS:
      return Object.assign({}, state, { data: action.payload.data });
    case DISCUSSED_FAILURE:
      return Object.assign({}, state);
    default:
      return state;
  }
};

export const getDiscussed = (regionID: string): IGetRequest => {
  return {
    type: DISCUSSED,
    method: 'GET',
    payload: {
      pathname: '/v1/last-comments',
      params: [{
        name: 'region_id',
        value: regionID,
      }],
    },
    request: true,
  };
};

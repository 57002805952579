import { ca } from '@cian/analytics';

export function trackShow() {
  ca('event', {
    name: 'oldevent',
    category: 'Magazine_subscription',
    action: 'Open',
    label: 'new_comments',
  });
}

export function trackSubscribeWithNews() {
  ca('event', {
    name: 'oldevent',
    category: 'Magazine_subscription',
    action: 'Send',
    label: 'news_new_comments',
  });
}

export function trackRegistration() {
  ca('event', {
    name: 'oldevent',
    category: 'Registration',
    action: 'subscription',
    label: 'new_comments',
  });
}

import { IUser } from '../../shared/types/user';
import { IPostCard } from '../../shared/types/post_card';
import { IList } from '../../shared/types/list_item_props';
import { isAdminOrModerator, isPublishedLessThenMonthAgo } from '../utils';

export const ROLES = {
  admin: 'admin_jornal',
  moderator: 'mod_jornal',
};

type TPermissionFunctionNames =
  'canEdit' |
  'canHide' |
  'canDelete' |
  'canPublish' |
  'canSeeCaptions' |
  'canComment' |
  'canSeeComments' |
  'canLikes' |
  'canComplaint' |
  'canSubscribeComments';

export type TPermission = {
  [key in TPermissionFunctionNames]: (user: IUser, model: IPostCard | IList) => boolean;
};

export type TPermissions = { [index: string]: TPermission };

export const DEFAULT_PERMISSION: TPermission = {
  canEdit: () => false,
  canHide: () => false,
  canDelete: () => false,
  canPublish: () => false,
  canSeeCaptions: () => false,

  /** Можно ли оставлять комментарии */
  canComment: () => true,

  /** Можно ли просматривать комментарии */
  canSeeComments: () => true,
  canLikes: () => true,
  canComplaint: () => false,
  canSubscribeComments: () => true,
};

export const PERMISSIONS: TPermissions = {
  'Blog': {
    ...DEFAULT_PERMISSION,

    canEdit: (user, { item: { attributes: { authorObject}}}) => {
      return Boolean(authorObject && user.userId === authorObject.userId);
    },

    canHide: (user, { item: { attributes: { status}}}) => {
      return isAdminOrModerator(user) && (status === 'checked' || status === 'unchecked');
    },

    canPublish: (user, { item: { attributes: { authorObject, status}}}) => {
      return Boolean(authorObject && user.userId === authorObject.userId && status === 'draft');
    },

    canSeeCaptions: (user, { item: { attributes: { authorObject}}}) => {
      return isAdminOrModerator(user) || Boolean(authorObject && user.userId === authorObject.userId);
    },

    canLikes: (user, { item: { attributes: { authorObject}}}) => {
      return Boolean(authorObject && user.userId !== authorObject.userId && !user.isBlock);
    },

    canComplaint: (user) => user.userId > 0,
  },

  'Question': {
    ...DEFAULT_PERMISSION,

    canHide: (user, { item: { attributes: { status}}}) => {
      return isAdminOrModerator(user) && (status === 'checked' || status === 'unchecked');
    },

    canPublish: (user, { item: { attributes: { authorObject, status}}}) => {
      return Boolean(authorObject && user.userId === authorObject.userId && status === 'draft');
    },

    canSeeCaptions: (user, { item: { attributes: { authorObject}}}) => {
      return isAdminOrModerator(user) || Boolean(authorObject && user.userId === authorObject.userId);
    },

    canComment: (user, model) => isPublishedLessThenMonthAgo(model),

    canLikes: (user, model) => isPublishedLessThenMonthAgo(model) && !user.isBlock,

    canComplaint: (user) => user.userId > 0,

    canSubscribeComments: (user, model) => isPublishedLessThenMonthAgo(model),
  },

  'Articles': {
    ...DEFAULT_PERMISSION,

    canComment: (user, { item: { attributes: { disableComments }}}) => {
      return !disableComments;
    },

    canSeeComments: (user, { item: { attributes: { disableComments }}}) => {
      return !disableComments;
    },
  },

  'News': {
    ...DEFAULT_PERMISSION,

    canComment: (user, { item: { attributes: { disableComments }}}) => {
      return !disableComments;
    },

    canSeeComments: (user, { item: { attributes: { disableComments }}}) => {
      return !disableComments;
    },
  },

  'Journal': {
    ...DEFAULT_PERMISSION,
  },

  'journal': {
    ...DEFAULT_PERMISSION,
  },

  '': {
    ...DEFAULT_PERMISSION,
  },
};

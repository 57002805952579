import * as React from 'react';

import { Button } from "@cian/ui-kit";

import s from './load_error.css';

export const LoadError = () =>
  <div className={s['load-error-container']}>
    <span className={s['error-caption']}>Произошла ошибка</span>
    <Button
        onClick={() => window.location.reload() }
        theme="fill_primary"
        size="XS"
        fullWidth={true}
    >
      Обновить страницу
    </Button>
  </div>;

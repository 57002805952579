import { ITypedReduxAction } from '../../../types/redux/actionType';
import { IGetJournalMoreDataSchema } from '../../../repositories/journal/entities/journal/GetJournalMoreDataSchema';

export enum EGetSimilarArticlesActionType {
  Loading = 'similarArticles/getSimilarArticles/loading',
  Succeed = 'similarArticles/getSimilarArticles/succeed',
  Failed = 'similarArticles/getSimilarArticles/failed',
  Reset = 'similarArticles/getSimilarArticles/reset',
}

export type TGetSimilarArticlesLoading = ITypedReduxAction<EGetSimilarArticlesActionType.Loading>;
export type TGetSimilarArticlesSucceed = ITypedReduxAction<
  EGetSimilarArticlesActionType.Succeed,
  IGetJournalMoreDataSchema[]
>;
export type TGetSimilarArticlesFailed = ITypedReduxAction<EGetSimilarArticlesActionType.Failed>;
export type TGetSimilarArticlesReset = ITypedReduxAction<EGetSimilarArticlesActionType.Reset>;

export type TGetSimilarArticles =
  | TGetSimilarArticlesLoading
  | TGetSimilarArticlesSucceed
  | TGetSimilarArticlesFailed
  | TGetSimilarArticlesReset;

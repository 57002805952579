import React, { CSSProperties } from 'react';

import * as s from './Card.css';

export interface ICardParams {
  width?: CSSProperties['width'];
  minWidth?: CSSProperties['minWidth'];
  height?: CSSProperties['height'];
  minHeight?: CSSProperties['minHeight'];
  children: React.ReactNode;
  onClick?(e: React.MouseEvent): void;
}

/**
 * Декоратор карточки поста
 */
export const Card = ({ children, width, minWidth, height, minHeight, onClick }: ICardParams) => (
  <div style={{ width, minWidth, height, minHeight }} className={s['wrapper']} onClick={onClick}>
    {children}
  </div>
);

/* eslint-disable */
//tslint:disable

export interface IJournalMetaV2Schema {
  /** Аудитория **/
  b2b?: boolean | null;
  /** Количество записей **/
  limit: number;
  /** Сдвиг **/
  offset: number;
  /** Сортировка **/
  ordering: EOrdering;
  /** ID региона **/
  regionId?: number | null;
  /** ID рубрики **/
  rubricId?: number | null;
  /** Тег **/
  tag?: string | null;
  /** Всего записей **/
  total: number;
}

export enum EOrdering {
  /** Обсуждаемое **/
  Comments_count = 'comments_count',
  /** Дата публикации **/
  Date_publish = 'date_publish',
  /** Понравившееся **/
  Likes_count = 'likes_count',
  /** Популярное **/
  Num_views = 'num_views',
}

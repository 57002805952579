import * as React from 'react';
import { IIconProps } from '@cian/ui-kit/icons/internal/types';

export const IconChevronUpSmall16 = ({ color }: IIconProps) => {
  const currentColor = color ? `var(--${color})` : 'currentColor';

  return (
    // eslint-disable-next-line react/forbid-elements
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.707 9.29294L11.2928 10.7072L7.99992 7.41426L4.70703 10.7072L3.29282 9.29294L7.99992 4.58583L12.707 9.29294Z"
        fill={currentColor}
      />
    </svg>
  );
};

/**
 * Подготавливает данные статуса
 */
import { POST_DECLINE_REASONS } from '../../../constants';
import { IStatusLink } from '../../../components/ListingItem/components/Status';

export const prepareStatus = (name: string, reason?: string): IStatusLink => {
  switch (name) {
    case 'decline':
    case 'hidden':
    case 'removed':
      return {
        title: 'Отклонено',
        type: 'rejected',
        tooltipHeader: reason ? 'Причина отклонения' : undefined,
        tooltipDescription: reason ? POST_DECLINE_REASONS[reason as keyof typeof POST_DECLINE_REASONS] : undefined,
      };

    case 'checked':
    case 'unchecked':
      return {
        title: 'Опубликовано',
        type: 'published',
      };

    case 'draft':
    default:
      return {
        title: 'Черновик',
        type: 'draft',
      };
  }
};

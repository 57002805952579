import { useEffect, useState } from 'react';

import { IScrollingWithControls } from '../ScrollingWithControls';

type TUseIsShownParams = [
  controlsType: Required<IScrollingWithControls['controlsType']>,
  canSeeControls: boolean,
  canScrollPrev: boolean,
  canScrollNext: boolean,
];

/**
 * Следит за отображением контролов
 */
export const useIsShown = ([controlsType, canSeeControls, canScrollPrev, canScrollNext]: TUseIsShownParams) => {
  const [prev, setPrev] = useState(false);
  const [next, setNext] = useState(false);

  useEffect(() => {
    if (!canSeeControls) {
      setPrev(false);
      setNext(false);

      return;
    }

    if (controlsType === 'relative') {
      setPrev(true);
      setNext(true);

      return;
    }

    setPrev(canScrollPrev);
    setNext(canScrollNext);
  }, [controlsType, canSeeControls, canScrollPrev, canScrollNext]);

  return {
    prev,
    next,
  };
};

import React from 'react';
import { ArticleHeading1, ArticleHeading2, ArticleHeading3, useDeviceType } from '@cian/ui-kit';

import * as s from './DocumentListingGroupLayout.css';

interface IDocumentListingGroupLayout {
  children: React.ReactNode;
}

/**
 * Стилевая обертка для группы списка документов
 */
export const DocumentListingGroupLayout = ({ children }: IDocumentListingGroupLayout) => {
  const isPhone = useDeviceType() === 'phone';
  const isTablet = useDeviceType() === 'tablet';
  const HeadingComponent = isPhone ? ArticleHeading3 : isTablet ? ArticleHeading2 : ArticleHeading1;

  return (
    <div className={s['wrapper']}>
      <HeadingComponent>{children}</HeadingComponent>
    </div>
  );
};

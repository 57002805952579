import { TDeviceType } from '@cian/ui-kit';

export const combineDataLayerObject = (
  analytics: any,
  breadCrumbs: string[],
  deviceType: TDeviceType,
  journal_pagetype?: string,
  journal_rubrics?: string,
) => {
  const { user } = analytics;

  return {
    user: {
      abGroup: user && 'abGroup' in user ? Number(user.abGroup) : 100,
      email: user && user.email ? user.email : undefined,
      type: user && user.userType ? user.userType : 'not_authorized',
      userId: user && user.realtyUserId ? Number(user.realtyUserId) : undefined,
      cianUserId: user && user.userId ? Number(user.userId) : undefined,
    },
    page: {
      breadCrumbs,
      pageType: 'Magazine',
      siteType: deviceType === 'phone' ? 'mobile' : deviceType,
      extra: {
        journal_pagetype,
        journal_rubrics,
      },
    },
    partner: {
      CriteoEmail: user && user.criteoEmail,
      CriteoNewCustomer: user && user.criteoNewCustomer,
    },
  };
};

interface ICombineDataLayerObjectParamsPageExtra {
  journalPageType?: string;
  journalRubrics?: string;
  publicationDate?: string;
  author?: string;
  journalArticleType?: string;
  type?: number;
}

export interface ICombineDataLayerObjectParamsPage {
  breadCrumbs?: string[];
  pageType?: string;
  siteType?: 'mobile' | 'tablet' | 'desktop';
  extra?: ICombineDataLayerObjectParamsPageExtra;
}

interface ICombineDataLayerObjectParamsProduct {
  id?: number;
}

export interface ICombineDataLayerObjectParams {
  page: ICombineDataLayerObjectParamsPage;
  product?: ICombineDataLayerObjectParamsProduct;
}

export const combineDataLayerObjectNew = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  analytics: any,
  { page: { breadCrumbs, pageType = 'Magazine', siteType, extra = {} }, product }: ICombineDataLayerObjectParams,
) => {
  const { user } = analytics;
  const { journalPageType, journalRubrics, publicationDate, author, journalArticleType, type } = extra;

  return {
    user: {
      abGroup: user && 'abGroup' in user ? Number(user.abGroup) : 100,
      email: user && user.email ? user.email : undefined,
      type: user && user.userType ? user.userType : 'not_authorized',
      userId: user && user.realtyUserId ? Number(user.realtyUserId) : undefined,
      cianUserId: user && user.userId ? Number(user.userId) : undefined,
    },
    page: {
      breadCrumbs,
      pageType,
      siteType,
      extra: {
        journal_pagetype: journalPageType,
        journal_rubrics: journalRubrics,
        publication_date: publicationDate,
        author,
        journal_article_type: journalArticleType,
        type,
      },
    },
    partner: {
      CriteoEmail: user && user.criteoEmail,
      CriteoNewCustomer: user && user.criteoNewCustomer,
    },
    product: {
      ...(product?.id ? { id: product.id } : {}),
    },
  };
};

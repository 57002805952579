import * as React from 'react';
import { IIconProps } from '@cian/ui-kit/icons/internal/types';

export const IconCommunicationComment24 = ({ color }: IIconProps) => {
  const currentColor = color ? `var(--${color})` : 'currentColor';

  return (
    // eslint-disable-next-line react/forbid-elements
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 6.5625C0 8.17957 0.668432 9.66004 1.77672 10.8039L0.9375 15L5.27481 12.8313C5.97787 13.0222 6.72522 13.125 7.5 13.125C11.6421 13.125 15 10.1869 15 6.5625C15 2.93813 11.6421 0 7.5 0C3.35786 0 0 2.93813 0 6.5625Z"
        fill={currentColor}
      />
      <path
        d="M14.1798 13.9407C16.5554 12.1765 18.0358 9.42871 17.9993 6.44788C21.4952 7.52877 24 10.4459 24 13.875C24 15.8155 23.1978 17.592 21.8679 18.9647L22.875 24L17.6702 21.3976C16.8265 21.6266 15.9297 21.75 15 21.75C10.8994 21.75 7.4394 19.3505 6.35376 16.0686C9.10838 16.3409 11.9513 15.5956 14.1798 13.9407Z"
        fill={currentColor}
      />
    </svg>
  );
};

import React, { useRef, useState } from 'react';
import { CustomTooltip } from '@cian/ui-kit';

import * as s from './Status.css';
import { IconFunctionalInformationCircle16 } from '../../../Icons';

export interface IStatusLink {
  title: string;
  type?: 'published' | 'moderation' | 'draft' | 'rejected';
  tooltipHeader?: string;
  tooltipDescription?: string;
}

/**
 * Плашка со статусом
 */
export const Status = ({ title, type, tooltipHeader, tooltipDescription }: IStatusLink) => {
  const [isOpened, setIsOpened] = useState(false);
  const iconRef = useRef<HTMLDivElement>(null);

  return (
    <div className={`${s['wrapper']} ${type ? s['_' + type] : ''}`}>
      {title}
      {tooltipDescription && type === 'rejected' && (
        <>
          <div
            ref={iconRef}
            className={s['icon-wrapper']}
            onMouseEnter={() => setIsOpened(true)}
            onMouseLeave={() => setIsOpened(false)}
          >
            <IconFunctionalInformationCircle16 />
          </div>

          <CustomTooltip
            anchorRef={iconRef}
            content={tooltipDescription}
            flip
            header={tooltipHeader}
            maxWidth={450}
            open={isOpened}
            placement="bottom-end"
            portal
            preventOverflow
            size="S"
            onClose={() => {}}
            theme={'white'}
          />
        </>
      )}
    </div>
  );
};

import { isValidDate, mapDayToString } from '@cian/utils';

import { getMonthNames } from './getMonthNames';

type TDayNames = 'сегодня' | 'вчера' | 'позавчера';

type TOptions = {
  dayNames?: TDayNames[];
  skipCurrentYear?: boolean;
};

/**
 * Форматирует дату
 * ----------------
 * Возможные результаты:
 * - пустое значение, если дата некорректна: ""
 * - строковое представление, если из массива опции dayNames: "сегодня" | "вчера" | "позавчера"
 * - без текущего года, если есть опция skipCurrentYear: "21 января"
 * - полное представление: "21 января 2023"
 */
export function formatDate(date: Date, options?: TOptions) {
  const day = mapDayToString(date) as TDayNames | undefined;
  const now = new Date();

  if (!isValidDate(date)) {
    return '';
  }

  if (day && (options?.dayNames || []).includes(day)) {
    return day;
  }

  if (options?.skipCurrentYear && now.getFullYear() === date.getFullYear()) {
    return `${date.getDate()} ${getMonthNames()[date.getMonth()]}`;
  }

  return `${date.getDate()} ${getMonthNames()[date.getMonth()]} ${date.getFullYear()}`;
}

import React from 'react';
import { UIHeading2 } from '@cian/ui-kit';

import { SidebarCard } from '../SidebarCard';
import { SidebarDiscussionsItem } from './components/SidebarDiscussionsItem';
import { IDiscussions } from '../../types/discussions';
import { IconCommunicationComment24 } from '../Icons';
import * as s from './SidebarDiscussions.css';

/**
 * Лента обсуждений в сайдбаре
 */
export const SidebarDiscussions = ({ items }: IDiscussions) => (
  <SidebarCard>
    <UIHeading2>
      <span className={s['heading-wrapper']}>
        <IconCommunicationComment24 color={'primary_100'} />
        Сейчас обсуждают
      </span>
    </UIHeading2>

    <div className={s['items-wrapper']}>
      {items.map((item, index) => (
        <SidebarDiscussionsItem key={index} {...item} />
      ))}
    </div>
  </SidebarCard>
);

import React, { useCallback, useRef, useState } from 'react';
import {
  ArticleParagraph1,
  ArticleParagraph2,
  Button,
  Input,
  useDeviceType,
  UIHeading2,
  UIHeading3,
  MultiSelect,
  Checkbox,
} from '@cian/ui-kit';

import { Man } from './components/Man';
import { DogAndClouds } from './components/DogAndClouds';
import { DogAndCloud } from './components/DogAndCloud';
import { useEventListener, useTimeoutEffect, useCollapse } from '../../../utils';
import { CIAN_URL } from '../../../constants';
import { IconChevronUpSmall16, IconChevronDownSmall16 } from '../../Icons';
import { IRegionsItem } from '../../../types/regions';
import * as s from './ManWithDogCollection.css';

export interface IQuestionsSubscribeCategory {
  title: string;
  name: string;
}

export interface IQuestionsSubscribeFormParams {
  regions: IRegionsItem[];
  categories: IQuestionsSubscribeCategory[];
  errorText?: string;
  subscribedText?: string;
  isDisabled?: boolean;
  onSubmit(email: string, categoryNames: string[], regionIds: number[]): void;
  onChange(): void;
}

/** Форма подписки на вопросы */
export const QuestionsSubscribeForm = ({
  regions,
  categories,
  errorText,
  subscribedText,
  isDisabled,
  onSubmit,
  onChange,
}: IQuestionsSubscribeFormParams) => {
  const [email, setEmail] = useState('');
  const [regionIds, setRegionIds] = useState([0]);
  const [categoryValues, setCategoryValues] = useState<Record<string, boolean>>(
    categories.reduce((acc, category) => ({ ...acc, ...{ [category.name]: true } }), {}),
  );
  const [errorMessage, setIsErrorMessage] = useState<string | undefined>();
  const deviceType = useDeviceType();
  const isPhone = () => deviceType === 'phone';
  const isDesktop = () => deviceType === 'desktop';
  const HeadingComponent = isPhone() ? UIHeading3 : UIHeading2;
  const ArticleParagraphComponent = isPhone() ? ArticleParagraph2 : ArticleParagraph1;
  const inputRef = useRef<HTMLInputElement>(null);
  const optionsRef = useRef<HTMLDivElement>(null);
  const [toggleOptions, isOptionsShown] = useCollapse(optionsRef);

  /** При пропадании ошибки выжидаем анимацию скрытия сообщения */
  useTimeoutEffect(() => {
    setIsErrorMessage(errorText);
  }, [errorText ? 0 : 300]);

  /** Сохраняет значение в стэйт */
  const handleChange = useCallback(
    (_, value: string) => {
      setEmail(value);
      onChange();
    },
    [onChange],
  );

  /** Отправляет значение по клику или Enter */
  const handleSubmit = useCallback(
    e => {
      e.preventDefault();

      const checkedCategoryNames = Object.keys(categoryValues).reduce((acc, name) => {
        if (categoryValues[name]) {
          acc.push(name);
        }

        return acc;
      }, [] as string[]);

      onSubmit(email, checkedCategoryNames, regionIds);
    },
    [categoryValues, onSubmit, email, regionIds],
  );

  /** Вызывает callback при нажатии на Enter */
  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.code === 'Enter') {
        handleSubmit(e);
      }
    },
    [handleSubmit],
  );

  useEventListener('keydown', handleKeyDown, inputRef);

  /** Запоминает выбранные регионы */
  const handleRegionsChange = (regionIds: number[]) => {
    if (!regionIds.length || regionIds[regionIds.length - 1] === 0) {
      setRegionIds([0]);

      return;
    }

    setRegionIds(regionIds.filter(regionId => regionId));
  };

  /** Запоминает выбранные категории */
  const handleCategoriesChange = (name: IQuestionsSubscribeCategory['name'], value: boolean) => {
    setCategoryValues(categoryNames => ({ ...categoryNames, ...{ [name]: value } }));
    onChange();
  };

  return (
    <div className={s['wrapper']}>
      <div className={s['content-wrapper']}>
        {subscribedText && (
          <div className={s['subscribed-wrapper']}>
            <HeadingComponent>
              <div className={s['subscribed-content']}>
                <span>Отлично! </span>
                <span>Вы подписались на рассылку</span>
              </div>
            </HeadingComponent>

            <ArticleParagraphComponent>{subscribedText}</ArticleParagraphComponent>

            {isPhone() && <DogAndCloud />}
          </div>
        )}

        {!subscribedText && (
          <div className={s['unsubscribed-wrapper']}>
            <HeadingComponent>
              Не пропустите новые вопросы по теме.
              <br />
              Подпишитесь на рассылку
            </HeadingComponent>

            <form className={s['form']}>
              <div className={s['fields-wrapper']}>
                <div className={s['input-wrapper']}>
                  <Input
                    ref={inputRef}
                    value={email}
                    invalid={!!errorText}
                    disabled={isDisabled}
                    size={'M'}
                    width={'100%'}
                    placeholder={'Ваш email'}
                    onChange={handleChange}
                  />

                  <div className={`${s['error-wrapper']} ${errorText ? s['_active'] : ''}`}>
                    {errorMessage && <div className={s['error']}>{errorMessage}</div>}
                  </div>
                </div>

                <Button
                  theme="fill_primary"
                  size={'M'}
                  fullWidth={isPhone() ? true : undefined}
                  loading={isDisabled}
                  onClick={handleSubmit}
                >
                  Подписаться
                </Button>
              </div>

              <div ref={optionsRef} className={s['options-wrapper']}>
                <div className={s['options-content']}>
                  <MultiSelect
                    placeholder="Выберите регион"
                    options={regions.map(({ id, displayName }) => ({
                      label: displayName,
                      value: id,
                    }))}
                    value={regionIds}
                    size={'M'}
                    onChange={handleRegionsChange}
                  />

                  <div className={s['categories-wrapper']}>
                    {categories.map(({ title, name }) => (
                      <Checkbox
                        key={name}
                        label={title}
                        checked={categoryValues[name]}
                        invalid={!!errorText && !Object.values(categoryValues).filter(value => value).length}
                        onChange={(_, value) => handleCategoriesChange(name, value)}
                      />
                    ))}
                  </div>
                </div>
              </div>

              <div className={s['options-trigger']} onClick={() => toggleOptions()}>
                {!isOptionsShown && (
                  <>
                    Настроить рассылку
                    <IconChevronDownSmall16 />
                  </>
                )}
                {isOptionsShown && (
                  <>
                    Свернуть
                    <IconChevronUpSmall16 />
                  </>
                )}
              </div>

              <div className={s['description']}>
                При подписке вы принимаете условия{' '}
                <a href={`${CIAN_URL}/help/about/rules/`} target={'_blank'} rel="noreferrer">
                  Пользовательского соглашения
                </a>{' '}
                и{' '}
                <a href={`${CIAN_URL}/help/about/36526/`} target={'_blank'} rel="noreferrer">
                  Политики конфиденциальности
                </a>
              </div>
            </form>
          </div>
        )}
      </div>

      {subscribedText && !isPhone() && (
        <div className={s['dog-and-clouds-wrapper']}>
          <DogAndClouds />
        </div>
      )}

      {!subscribedText && isDesktop() && (
        <div className={s['man-wrapper']}>
          <Man />
        </div>
      )}
    </div>
  );
};

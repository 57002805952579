import * as React from 'react';
import { Link } from 'react-router-dom';

import { TMenuItemCommonProps, ICommonItem } from '../../types';
import * as s from './SubmenuItem.css';

export type TSubmenuItemProps = Omit<ICommonItem, 'slug'> & TMenuItemCommonProps;

/**
 * Элемент группы подменю
 */
export const SubmenuItem = ({ label, url, isActive, onClick }: TSubmenuItemProps) => {
  return (
    <li className={`${s['wrapper']}`} onClick={onClick}>
      {url ? (
        <Link className={`${s['link']} ${isActive ? s['_active'] : ''}`} to={url}>
          {label}
        </Link>
      ) : (
        <span className={`${s['link']} ${isActive ? s['_active'] : ''}`}>{label}</span>
      )}
    </li>
  );
};

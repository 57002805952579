import React, { useState } from 'react';
import { Image, Eye16 as IconShow16 } from '@cian/ui-kit';

import * as s from './SimilarArticle.css';
import { formatDate, numberWithDelimiter } from '../../utils';

interface ISimilarArticleParams {
  imageUrl?: string;
  title: string;
  description: string;
  articleUrl: string;
  datePublish?: string;
  numViews?: number;
  categoryName: string;
  categoryUrl: string;
  onArticleClick(): void;
  onCategoryClick(): void;
}

/**
 * Элемент списка подобных статей
 */
export const SimilarArticle = ({
  imageUrl,
  title,
  description,
  articleUrl,
  datePublish,
  numViews,
  categoryName,
  categoryUrl,
  onArticleClick,
  onCategoryClick,
}: ISimilarArticleParams) => {
  const [isHover, setIsHover] = useState(false);

  /** Фиксирует клик по категории и блокирует клик по статье */
  const handleCategoryClick = (e: React.MouseEvent) => {
    e.preventDefault();
    onCategoryClick();
  };

  /** Блокирует естественный клик по заголовку */
  const handleArticleClick = (e: React.MouseEvent) => {
    e.preventDefault();
  };

  /** Обрабатывает наведение курсором на статью */
  const handleArticleHover = (isHover: boolean = false) => {
    setIsHover(isHover);
  };

  return (
    <div className={`${s['wrapper']} ${isHover ? s['_hover'] : ''}`}>
      <div className={s['info-wrapper']}>
        <a
          className={s['category']}
          href={categoryUrl}
          title={`Перейти в категорию ${categoryName}`}
          onClick={handleCategoryClick}
        >
          {categoryName}
        </a>

        <div
          className={s['inner-wrapper']}
          onClick={onArticleClick}
          onMouseEnter={() => handleArticleHover(true)}
          onMouseLeave={() => handleArticleHover()}
        >
          <a className={s['title']} href={articleUrl} title={title} onClick={handleArticleClick}>
            {title}
          </a>

          <p className={s['description']}>{description}</p>

          {(datePublish || numViews) && (
            <div className={s['footer-wrapper']}>
              {datePublish && <div>{formatDate(new Date(datePublish))}</div>}

              {numViews && (
                <div className={s['views']}>
                  <IconShow16 />
                  {numberWithDelimiter(numViews)}
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      <div
        className={s['image-wrapper']}
        onClick={onArticleClick}
        onMouseEnter={() => handleArticleHover(true)}
        onMouseLeave={() => handleArticleHover()}
      >
        {imageUrl && (
          <Image
            src={imageUrl}
            alt={title}
            borderRadius={0}
            objectFit={'contain'}
            preloader
            theme="light"
            title={title}
          />
        )}
      </div>
    </div>
  );
};

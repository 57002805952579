import { ca } from '@cian/analytics';

export const subscribeMagazine = () => {
  ca('event', {
    name: 'oldevent',
    category: 'Magazine_subscription',
    action: 'Send',
    label: 'news',
  });
};

export const addComment = (action: string) => {
  ca('event', {
    name: 'oldevent',
    category: 'Magazine',
    action,
    label: '',
  });
};

export const subscribeComments = (label: string, category: string) => {
  ca('event', {
    name: 'oldevent',
    category,
    action: 'Send',
    label,
  });
};

export const addLikeDislikeComplaint = (action: string) => {
  ca('event', {
      name: 'oldevent',
      category: 'Magazine',
      action,
      label: '',
  });
};

export const addPost = (action: string) => {
  ca('event', {
    name: 'oldevent',
    category: 'Magazine',
    action,
    label: '',
  });
};

export const registration = () => {
  ca('event', {
    name: 'oldevent',
    category: 'Registration',
    action: 'subscription',
    label: 'news',
  });
}

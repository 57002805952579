import { IAnalytics } from '../../../types/analytics';

export const GET_ANALYTICS_USER = 'get_analytics_user/ACTION';
export const GET_ANALYTICS_USER_REQUEST = 'get_analytics_user/ACTION_REQUEST';
export const GET_ANALYTICS_USER_SUCCESS = 'get_analytics_user/ACTION_SUCCESS';
export const GET_ANALYTICS_USER_FAILURE = 'get_analytics_user/ACTION_FAILURE';
export const GET_ANALYTICS_USER_TIMEOUT_DEGRADATION = 'get_analytics_user/ACTION_TIMEOUT_DEGRADATION';

export const initialState: IAnalytics = {
  user: {
    abGroup: '',
    criteoEmail: '',
    criteoNewCustomer: 0,
    email: '',
    realtyUserId: '',
    userId: '',
    userType: '',
  },
};

export const timeoutDegradationState: IAnalytics = {
  user: {
    abGroup: '100', // требование от Иры Евсюковой - по 100й группе определяется деградация
    criteoEmail: '',
    criteoNewCustomer: 0,
    email: '',
    realtyUserId: '',
    userId: '',
    userType: '',
  },
};

const defaultAction = {
  type: '',
  payload: { data: initialState.user },
};

export const analyticsReducer = (state = initialState, action = defaultAction) => {
  switch (action.type) {
    case GET_ANALYTICS_USER_REQUEST:
      return state;
    case GET_ANALYTICS_USER_SUCCESS:
    case GET_ANALYTICS_USER_TIMEOUT_DEGRADATION:
      return Object.assign({}, state, {
        user: action.payload.data,
      });
    case GET_ANALYTICS_USER_FAILURE:
      return Object.assign({}, state);
    default:
      return state;
  }
};

export const getAnalyticsUser = (scheme: string, host: string) => {
  return {
    type: GET_ANALYTICS_USER,
    method: 'GET',
    payload: {
      scheme: scheme,
      host: host,
      pathname: '/cian-api/site/v1/get-user-ga-data-layer-data/',
      basePath: '',
    },
    request: true,
  };
};

import * as React from 'react';
import { Route, Switch, Redirect } from 'react-router';
import { useLocation } from 'react-router-dom';

import { MainPage, ArticleList, NewsList, BlogList, QuestionList, SearchList, DocumentListing } from '../Listings';
import { PageReload } from '../../components/PageReload';
import { useContext } from 'react';
import { ApplicationContext, prepareQSParam } from '../../utils';
import { withRatingAccess } from './utils/withRatingAccess';
import { RatingAccessDeniedPageContainer } from '../AccessDeniedPages';
import { PostCardContainer } from '../PostCard';

export const RoutesNew = () => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const {
    custom: { isNewDesignEnabled, newDesignSubdomains, subdomain },
  } = useContext(ApplicationContext);
  const isCorrectSubdomain = newDesignSubdomains?.length ? newDesignSubdomains.includes(subdomain) : true;
  const qsSearch = prepareQSParam(searchParams.get('search'));
  const qsTag = prepareQSParam(searchParams.get('tag'));

  return (
    <Switch>
      <Route path={`/magazine/`} strict exact component={qsTag || qsSearch ? SearchList : MainPage} />
      {/* Переадресует оставшееся на корневую группу */}
      <Redirect from="/magazine/" to="/magazine/" />

      <Route path={`/magazine-biblioteka-dokumentov/`} strict exact component={DocumentListing} />

      <Route path={`/magazine-:path/`} strict exact component={ArticleList} />
      {/* Переадресует оставшееся на корневую группу */}
      <Redirect from="/magazine-:path/" to="/magazine/" />

      <Route
        path={`/stati/`}
        strict
        exact
        render={() => {
          return qsTag || qsSearch ? <SearchList /> : <Redirect from="/stati/" to="/magazine/" />;
        }}
      />

      <Redirect from="/stati/" to="/magazine/" />

      <Route path={`/novosti/:path/`} strict exact component={NewsList} />
      {/* Переадресует оставшееся на корневую группу */}
      <Redirect from="/novosti/:path/" to="/novosti/" />

      <Route path={`/novosti/`} strict exact component={qsTag || qsSearch ? SearchList : NewsList} />
      {/* Переадресует оставшееся на корневую группу */}
      <Redirect from="/novosti/" to="/novosti/" />

      <Route
        path={`/blogs-specialist/`}
        strict
        exact
        component={withRatingAccess(BlogList, RatingAccessDeniedPageContainer)}
      />

      <Route path={`/blogs-tendentsiy-rynka/`} strict exact component={BlogList} />

      <Route path={`/blogs/`} strict exact component={qsTag || qsSearch ? SearchList : BlogList} />
      {/* Переадресует оставшееся на корневую группу */}
      <Redirect from="/blogs(-specialist|-tendentsiy-rynka)?/" to="/blogs/" />

      {/* Поддерживает только "/moi-blog/" */}
      <Route path={`/moi-blog/`} strict exact component={BlogList} />

      {/* Переадресует оставшееся на корневую группу */}
      <Redirect from="/moi-blog/" to="/blogs/" />

      <Route
        path={`/forum-rieltorov-specialist/`}
        strict
        exact
        component={withRatingAccess(QuestionList, RatingAccessDeniedPageContainer)}
      />

      <Route
        path={`/forum-rieltorov(-tendentsiy-rynka|-gorodskaya-nedvizhimost|-kommercheskaya-nedvizhimost|-zagorodnaya-nedvizhimost|-zarubezhnaya-nedvizhimost|-ipoteka)/`}
        strict
        exact
        component={QuestionList}
      />

      <Route path={`/forum-rieltorov/`} strict exact component={qsTag || qsSearch ? SearchList : QuestionList} />

      {/* Переадресует оставшееся на корневую группу */}
      <Redirect
        from="/forum-rieltorov(-specialist|-tendentsiy-rynka|-gorodskaya-nedvizhimost|-kommercheskaya-nedvizhimost|-zagorodnaya-nedvizhimost|-zarubezhnaya-nedvizhimost|-ipoteka)?/"
        to="/forum-rieltorov/"
      />

      <Route path={`/forum-rieltorov-:id/`} component={PostCardContainer}>
        {(!isNewDesignEnabled || !isCorrectSubdomain) && <PageReload />}
      </Route>

      <Route path={`/novosti-:id/`} component={PostCardContainer}>
        {(!isNewDesignEnabled || !isCorrectSubdomain) && <PageReload />}
      </Route>

      <Route path={`/blogs-:id/`} component={PostCardContainer}>
        {(!isNewDesignEnabled || !isCorrectSubdomain) && <PageReload />}
      </Route>

      <Route path={`/stati-:id/`} component={PostCardContainer}>
        {(!isNewDesignEnabled || !isCorrectSubdomain) && <PageReload />}
      </Route>

      {/* Редирект на Главную путей вида "///path" */}
      <Redirect from="//*" to="/magazine/" />

      <Route>
        <PageReload />
      </Route>
    </Switch>
  );
};

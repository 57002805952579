import * as React from 'react';
import { shouldPopupShown } from './utils/should_popup_shown';
import { DEMAND_POPUP_TICK, DEMAND_POPUP_TIME } from './constants/demand_popup_time';
import { isInBrowser } from '../../../app/helpers/browser_helper';
import isEqual from 'lodash/isEqual';
import { RequestDemandButton } from '../../containers/realtor_demand/request_demand_button';
import { DemandBannerLocation } from '../../containers/realtor_demand/contants/demand_banner_location';

import s from './demand_activity_popup.css';

export interface IDemandActivityPopupProps {
  /** Текущий url страницы **/
  pathname: string;
  isDesktop?: boolean;
}

export interface IDemandActivityPopupState {
  /** Баннер уже показывался **/
  hasShown: boolean;
  /** Показать банер **/
  showPopup: boolean;
}

/**
 * Компонент, отображающий попап банера заявки риелтора при активности пользователя
 * на страницах форума риелторов в течении 6 минут
 */
export class DemandActivityPopup extends React.Component<IDemandActivityPopupProps, IDemandActivityPopupState> {
  state = {
    hasShown: false,
    showPopup: false,
    dialogOpen: false,
  }

  private trackCheckTimeoutId: any;

  private startTrack = () => {
    if (this.trackCheckTimeoutId !== undefined || !isInBrowser() || this.state.hasShown) {
      return;
    }

    const trackTimeStart = Date.now();

    const trackCheckTick = () => {
      const currentTime = Date.now();
      if (currentTime - trackTimeStart >= DEMAND_POPUP_TIME) {
        this.setState({ showPopup: true, hasShown: true }, this.stopTrack);
      } else {
        this.trackCheckTimeoutId = setTimeout(trackCheckTick, DEMAND_POPUP_TICK);
      }
    };

    /** Интервал тика таймера на 1с для корректировки времени **/
    this.trackCheckTimeoutId = setTimeout(trackCheckTick, DEMAND_POPUP_TICK);
  }

  private stopTrack = () => {
    if (this.trackCheckTimeoutId === undefined) {
      return;
    }
    clearTimeout(this.trackCheckTimeoutId);
    this.trackCheckTimeoutId = undefined;
  }

  private onDemandModalClosed = () => {
    this.setState({
      showPopup: false,
    });
  }

  componentDidMount() {
    if (shouldPopupShown(this.props.pathname)) {
      this.startTrack();
    }
  }

  componentWillUnmount() {
    this.stopTrack();
  }

  shouldComponentUpdate(nextProps: IDemandActivityPopupProps, nextState: IDemandActivityPopupState) {
    return nextProps.pathname !== this.props.pathname || !isEqual(nextState, this.state);
  }

  componentWillUpdate(nextProps: IDemandActivityPopupProps) {
    if (shouldPopupShown(nextProps.pathname)) {
      this.startTrack();
    } else {
      this.stopTrack();
    }
  }

  render() {
    const { showPopup, dialogOpen } = this.state;

    if (!showPopup) {
      return null;
    }

    return (
      <div className={`${s['popup']} ${dialogOpen ? s['popup__dialog-open'] : ''}`}>
        <div className={`${s['description']} ${s['desktop']}`}>
          <div className={s['description__title']}>Нужна помощь риелтора?</div>
          <div>
            Подберем специалиста, который возьмет на себя решение юридических вопросов
          </div>
        </div>
        <div className={`${s['description']} ${s['mobile']}`}>
          Нужен риелтор? Бесплатно подберем специалиста
        </div>
        <div className={s['divider']} />
        <div className={s['action']}>
          <RequestDemandButton
            bannerLocation={DemandBannerLocation.POPUP}
            onCloseDialog={this.onDemandModalClosed}
            renderButton={({ onClick }) => (
            <div className={s['button']} onClick={onClick}>Подробнее</div>
          )}/>
        </div>
      </div>
    )
  }
}

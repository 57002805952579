import * as React from 'react';

import s from './decline_reason.css';

interface IProps {
  blockReason?: string;
}

const DECLINE_REASONS: { [index: string]: string } = {
  ad_spam: 'Реклама, спам',
  obscene_language: 'Нецензурная лексика',
  rules_violation: 'Нарушение правил сайта',
};

export const DeclineReason = (props: IProps) =>
  !!props.blockReason ?
    <div className={s['advice-list-article_decline-reason']}>
      <h5>причина отклонения:</h5>
      <span>
        { DECLINE_REASONS[props.blockReason] }
      </span>
    </div> :
    <div />;

import * as React from 'react';
import { Spinner } from "@cian/ui-kit";

import { ILike } from '../../types/likes';
import { Dialog } from '../dialog/dialog';
import { unescapeHtml } from '../../../app/helpers/make_html_helper';
import * as Analytics from '../../../app/helpers/analytics_helper';
import { IUser } from '../../types/user';

import s from './likes.css';

interface IProps {
  disabled: boolean;
  isCompact: boolean;
  likesCount: number;
  dislikesCount: number;
  likes: ILike[];
  dislikes: ILike[];
  likesFetched: boolean;
  dislikesFetched: boolean;
  fetchLikes(): void;
  fetchDislikes(): void;
  onLikePressed(): void;
  onDislikePressed(): void;
  type: string;
  userLike: 1 | -1 | null;
  user?: IUser;
}

interface IState {
  likesShown?: boolean;
  dislikesShown?: boolean;
  likesModalShown?: boolean;
  dislikesModalShown?: boolean;
}

export class Likes extends React.Component<IProps, IState> {
  public isComments: boolean;

  public constructor(props: IProps) {
    super(props);

    this.state = {
      likesShown: false,
      dislikesShown: false,
      likesModalShown: false,
      dislikesModalShown: false,
    };
    this.isComments = props.type === 'threadedcomment';
  }

  public render() {
    return (
      <div
          className={`
            ${s['advice-card-article-nav-likebar']}
            ${this.props.isCompact ? s['compact'] : ''}
            ${this.isComments ? s['advice-card-article-nav-likebar-comments'] : ''}
          `}>
        <div
            className={`
              ${s['advice-card-article-nav_likes-wrap']}
              ${this.isComments ? '' : s['advice-card-article-nav_likes-wrap-card']}
            `}>
          <button
              className={`
                ${s['advice-card-article-nav_likes']}
                ${this.isComments ? s['advice-card-article-nav_likes-comments'] : ''}
                ${this.props.disabled ? s['advice-card-article-nav_likes-disabled'] : ''}
                ${this.props.userLike === 1 ? s['advice-card-article-nav_likes-liked'] : ''}
              `}
              onClick={() => {
                if (!this.props.disabled) {
                  Analytics.addLikeDislikeComplaint('add_like');
                  this.props.onLikePressed();
                }
              }}
              onMouseEnter={() => this.showLikesPopup()}
              onMouseLeave={() => this.hideLikesPopup()}>
            <span className={s['advice-card-article-nav_likes-icon']} />
            <span className={s['advice-card-article-nav_likes-count']}>{ this.props.likesCount }</span>
          </button>
          {
            ((this.state.likesShown && !this.props.likesFetched) ||
                (this.state.likesShown &&
                  this.props.likesFetched &&
                  (this.props.likes.length > 0))) &&
                    this.props.likesCount > 0 &&
              <div
                  onMouseEnter={() => this.showLikesPopup()}
                  onMouseLeave={() => this.hideLikesPopup()}
                  className={`
                    ${s['advice-card-header-footerbar_like-tip']}
                    ${s['likes-popup']}
                    ${this.isComments ? s['likes-popup-comments'] : ''}
                  `}>
                <div
                    className={`
                      ${s['advice-card-header-footerbar_like-tip__hidden-block']}
                      ${this.isComments ? s['advice-card-header-footerbar_like-tip__hidden-block-comments'] : ''}
                    `} />
                {
                  this.props.likesFetched ? this.props.likes.slice(0, 5).map((like: ILike, i: number) =>
                    this.renderShortItem(like, 'likes', i),
                  ) : <Spinner size={32} />
                }
                {
                  (this.props.likes.length > 5) &&
                    <div
                        onClick={() => {
                          document.body.style.overflow = 'hidden';
                          this.setState({ likesModalShown: true });
                        }}
                        className={s['advice-card-header-footerbar_like-tip-show-all']}>
                      Все&nbsp;
                      <span>{this.props.likes.length}</span>
                    </div>
                }
              </div>
          }
        </div>
        <div
            className={`
              ${s['advice-card-article-nav_dislikes-wrap']}
              ${this.isComments ?
                s['advice-card-article-nav_dislikes-comments-wrap'] :
                s['advice-card-article-nav_dislikes-wrap-card']}
            `}>
          <button
              className={`
                ${s['advice-card-article-nav_dislikes']}
                ${this.isComments ? s['advice-card-article-nav_dislikes-comments'] : ''}
                ${this.props.disabled ? s['advice-card-article-nav_dislikes-disabled'] : ''}
                ${this.props.userLike === -1 ? s['advice-card-article-nav_likes-liked'] : ''}
              `}
              onClick={() => {
                if (!this.props.disabled) {
                  Analytics.addLikeDislikeComplaint('add_dislike');
                  this.props.onDislikePressed();
                }
              }}
              onMouseEnter={() => this.showDislikesPopup()}
              onMouseLeave={() => this.hideDislikesPopup()}>
            <span className={s['advice-card-article-nav_dislikes-icon']} />
            <span className={s['advice-card-article-nav_dislikes-count']}>{ this.props.dislikesCount }</span>
          </button>
          {
            ((this.state.dislikesShown && !this.props.dislikesFetched) ||
                (this.state.dislikesShown &&
                  this.props.dislikesFetched &&
                  (this.props.dislikes.length > 0))) &&
                    this.props.dislikesCount > 0 &&
              <div
                  onMouseEnter={() => this.showDislikesPopup()}
                  onMouseLeave={() => this.hideDislikesPopup()}
                  className={`
                    ${s['advice-card-header-footerbar_like-tip']}
                    ${s['dislikes-popup']}
                    ${this.isComments ? s['dislikes-popup-comments'] : ''}
                  `}>
                <div className={`
                  ${s['advice-card-header-footerbar_like-tip__hidden-block']}
                    ${this.isComments ? s['advice-card-header-footerbar_like-tip__hidden-block-comments'] : ''}
                  `} />
                {
                  this.props.dislikesFetched ? this.props.dislikes.slice(0, 5).map((like: ILike, i: number) =>
                    this.renderShortItem(like, 'dislikes', i),
                  ) : <Spinner size={32} />
                }
                {
                  (this.props.dislikes.length > 5) &&
                    <div
                        onClick={() => {
                          document.body.style.overflow = 'hidden';
                          this.setState({ dislikesModalShown: true });
                        }}
                        className={s['advice-card-header-footerbar_like-tip-show-all']}>
                      Все&nbsp;
                      <span>{this.props.dislikes.length}</span>
                    </div>
                }
              </div>
          }
        </div>
        <Dialog
            content={this.renderLikesModal(this.props.likes)}
            shown={!!this.state.likesModalShown}
            containerStyle={s['advice-card-modal']}
            onClose={() => {
              document.body.style.overflow = 'auto';
              this.setState({
                likesModalShown: false,
              });
            }} />
        <Dialog
            content={this.renderLikesModal(this.props.dislikes)}
            shown={!!this.state.dislikesModalShown}
            containerStyle={s['advice-card-modal']}
            onClose={() => {
              document.body.style.overflow = 'auto';
              this.setState({
                dislikesModalShown: false,
              });
            }} />
      </div>
    );
  }

  private renderLikesModal(likes: ILike[]) {
    return (
      <div>
        <div className={s['advice-card-modal-heading']}>
          <nav
              className={`
                ${s['advice-card-modal-heading-likebar']}
                ${this.props.disabled ? s['advice-card-modal-heading-likebar-disabled'] : ''}
              `}>
            <div
                onClick={() => {
                  if (!this.props.disabled) {
                    Analytics.addLikeDislikeComplaint('add_like');
                    this.props.onLikePressed();
                  }
                }}
                className={s['advice-card-modal-heading-likebar_like']}>
              { this.props.likesCount }
            </div>
            <div
                onClick={() => {
                  if (!this.props.disabled) {
                    Analytics.addLikeDislikeComplaint('add_dislike');
                    this.props.onDislikePressed();
                  }
                }}
                className={s['advice-card-modal-heading-likebar_dislike']}>
              { this.props.dislikesCount }
            </div>
            <span className={s['advice-card-modal-heading-likebar_helper']}></span>
          </nav>
        </div>
        <div className={s['advice-card-modal-main']}>
          <ul>
            {
              likes.map((like: ILike) =>
                <li key={`like-${like.id}`}>
                  <div className={s['advice-card-modal-main-item-avatar']}>
                    <div className={s['advice-card-modal-main-item-avatar-outer']}>
                      { this.renderAvatar(like) }
                    </div>
                  </div>
                  { like.attributes.user && like.attributes.user.userId > 0 ?
                      (like.attributes.user.specialistLink ?
                        <a
                            href={`${like.attributes.user.specialistLink}`}
                            className={s['advice-card-modal-main-item_name']}>
                          { like.attributes.user.fullName || like.attributes.user.companyName || `ID: ${like.attributes.user.userId}` }
                        </a> :
                        <span
                            className={s['advice-card-modal-main-item_name']}>
                          { like.attributes.user.fullName || like.attributes.user.companyName || `ID: ${like.attributes.user.userId}` }
                        </span>) :
                      (
                        <span
                            className={s['advice-card-modal-main-item_name']}>
                          Аноним
                        </span>
                      )
                  }
                </li>,
              )
            }
          </ul>
        </div>
      </div>
    );
  }

  private renderShortItem(like: ILike, key: string, i: number) {
    let avatar = <div className={s['advice-card-header-footerbar_like-tip-avatar-img']} />;

    if (!!like.attributes.user && !!like.attributes.user.avatar) {
      if (like.attributes.user && like.attributes.user.specialistLink) {
        avatar = <a
            href={`${like.attributes.user.specialistLink}`}
            className={s['advice-card-header-footerbar_like-tip-avatar-img']}
            style={{
              backgroundImage: `url(${unescapeHtml(like.attributes.user.avatar)})`,
              backgroundSize: 'contain',
            }} />;
      } else {
        avatar = <div
            className={s['advice-card-header-footerbar_like-tip-avatar-img']}
            style={{
              backgroundImage: `url(${unescapeHtml(like.attributes.user.avatar)})`,
              backgroundSize: 'contain',
            }} />;
      }
    }

    return (
      <div
          key={`likes-${key}-${i}`}
          className={s['advice-card-header-footerbar_like-tip-avatar']}>
        <div
            className={s['advice-card-header-footerbar_like-tip-avatar-outer']}>
          <div
              className={s['advice-card-header-footerbar_like-tip-avatar-inner']}>
            {avatar}
          </div>
        </div>
      </div>
    );
  }

  private showLikesPopup() {
    if (this.props.likesCount > 0) {
      this.props.fetchLikes();
      this.setState({
        likesShown: true,
        dislikesShown: this.state.dislikesShown,
      });
    }
  }

  private hideLikesPopup() {
    this.setState({
      likesShown: false,
      dislikesShown: this.state.dislikesShown,
    });
  }

  private showDislikesPopup() {
    if (this.props.dislikesCount > 0) {
      this.props.fetchDislikes();
      this.setState({
        likesShown: this.state.likesShown,
        dislikesShown: true,
      });
    }
  }

  private hideDislikesPopup() {
    this.setState({
      likesShown: this.state.likesShown,
      dislikesShown: false,
    });
  }

  private renderAvatar = (like: ILike) => {
    let avatar = <div className={s['advice-card-modal-main-item-avatar-img']} />;

    if (!!like.attributes.user && !!like.attributes.user.avatar) {
      if (like.attributes.user.specialistLink) {
        avatar = <a
            href={`${like.attributes.user.specialistLink}`}
            className={s['advice-card-modal-main-item-avatar-img']}
            style={{
              backgroundImage: `url(${unescapeHtml(like.attributes.user.avatar)})`,
              backgroundSize: 'contain',
            }} />;
      } else {
        avatar = <div
            className={s['advice-card-modal-main-item-avatar-img']}
            style={{
              backgroundImage: `url(${unescapeHtml(like.attributes.user.avatar)})`,
              backgroundSize: 'contain',
            }} />;
      }
    }

    return <div className={s['advice-card-modal-main-item-avatar-inner']}>
      {avatar}
    </div>;
  }
}

import * as React from 'react';
import { connect } from 'react-redux';

import { IUser } from '../../types/user';
import { IAnswer } from '../../types/answer';
import { Poll as PollComponent } from '../../components/poll/poll';
import { loadPollInfo, submitPoll } from '../../reducers/modules/poll/poll';
import { TDispatch } from "../../types/redux";

interface IProps {
  type: string;
  objectID: string;
  user?: IUser;
  dispatch: TDispatch;
}

interface IContainerProps {
  type: string;
  objectID: string;
}

interface IState {
  id: number;
  answers: IAnswer[];
  title: string;
  isMultiAnswer: boolean;
  isUserVote: boolean;
  userVote: number[];
  answerCount: number;
}

class PollContainer extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);

    this.state = {
      id: 0,
      answers: [],
      title: '',
      isMultiAnswer: false,
      isUserVote: false,
      userVote: [],
      answerCount: 0,
    };
  }

  public componentWillMount() {
    this.props.dispatch(loadPollInfo(this.props.type, this.props.objectID, (data) => {
      this.setState({
        id: data.id,
        answers: data.answers,
        title: data.title,
        isMultiAnswer: data.isMultiAnswer,
        isUserVote: data.userVote.length !== 0 || data.status !== 'active',
        userVote: data.userVote,
        answerCount: data.answerCount,
      });
    }, () => {
      this.setState({
        id: 0,
        answers: [],
        title: '',
        isMultiAnswer: false,
        isUserVote: false,
        userVote: [],
        answerCount: 0,
      });
    }) as any);
  }

  public render() {
    return (
      <PollComponent
        answers={this.state.answers}
        answerCount={this.state.answerCount}
        title={this.state.title}
        isMultiAnswer={this.state.isMultiAnswer}
        isUserVote={this.state.isUserVote}
        onVotePressed={this.handleVotePressed}
        onSubmitPressed={this.handleSubmitPressed}
        type={this.props.type}
        userVote={this.state.userVote}
        user={this.props.user} />
    );
  }

  private handleVotePressed = (id: number) => {
    this.submitVote(id);
  }

  private handleSubmitPressed = () => {
    this.submitForm();
  }

  private submitVote(idVote: number) {
    if (this.state.userVote.includes(idVote)) {
      this.setState({
        userVote: this.state.userVote.filter(vote => vote !== idVote)
      });
    } else {
      this.setState({
        userVote: [...this.state.userVote, idVote]
      });
    }
    if (!this.state.isMultiAnswer) {
      this.props.dispatch(submitPoll(this.props.type, this.props.objectID, this.state.id, [idVote], (userVote: number[]) => {
        const answerCount = this.state.answerCount + 1;
        this.setState({
          userVote: userVote,
          isUserVote: true,
          answers: this.countNewAnswer(answerCount, userVote),
          answerCount: answerCount,
        });
      }, () => {}) as any);
    }
  }

  private submitForm() {
    this.props.dispatch(submitPoll(this.props.type, this.props.objectID, this.state.id, this.state.userVote, (userVote: number[]) => {
      const answerCount = this.state.answerCount + this.state.userVote.length;
      this.setState({
        userVote: userVote,
        isUserVote: true,
        answers: this.countNewAnswer(answerCount, userVote),
        answerCount: answerCount,
      });
    }, () => {}) as any);
  }

  private countNewAnswer(answerCount: number, userVote: number[]) {
    let answers = [];
    for (let i = 0; i < this.state.answers.length; i += 1) {
      answers[i] = this.state.answers[i];
      if (userVote.includes(answers[i]['id'])) {
        answers[i]['count'] += 1;
      }
      answers[i]['percent'] = Math.round(answers[i]['count'] / answerCount * 100);
    }
    return answers;
  }
}

export const Poll = connect<IContainerProps, {}, IContainerProps>(
  (state: {
    user: IUser,
  }, props: IContainerProps) => ({
    user: state.user,
    type: props.type,
    objectID: props.objectID
  }),
)(PollContainer);

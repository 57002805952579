import React from 'react';

import * as s from './PostCardLayoutWithSidebar.css';

interface IPostCardLayoutWithSidebarParams {
  body: React.ReactNode;
  header?: React.ReactNode;
  children?: React.ReactNode;
}

/**
 * Шаблон страницы поста с сайдбаром
 */
export const PostCardLayoutWithSidebar = ({ body, header, children }: IPostCardLayoutWithSidebarParams) => (
  <>
    {header && <div className={s['header']}>{header}</div>}

    <div className={s['wrapper']}>
      <div className={s['body']}>{body}</div>
      {children && <div className={s['sidebar']}>{children}</div>}
    </div>
  </>
);

/**
 * Енам возможных превью-типов для ролика YouTube
 */
export enum EYoutubePreviewType {
  MaxRes = 'maxresdefault',
  Sd = 'sddefault',
  Hq = 'hqdefault',
  Mq = 'mqdefault',
  Default = 'default',
}

import {
  IGlobalErrorState,
  IGlobalErrorAction,
} from '../../../types/global_error';

export const GLOBAL_ERROR = 'global_error/ACTION';

export const initialState: IGlobalErrorState = {
  error: false,
};

const defaultAction: IGlobalErrorAction = {
  type: '',
  payload: initialState,
};

export const globalErrorReducer = (state = initialState, action = defaultAction) => {
  switch (action.type) {
    case GLOBAL_ERROR:
      return Object.assign({}, state, { error: action.payload.error });
    default:
      return state;
  }
};

import React, { useState } from "react";
import { Link } from 'react-router-dom';

import { cropText } from '../../../app/helpers/format_helper';
import { IDiscussedItem } from '../../types/discussed';
import { formatDateTodayYesterday } from '../../../app/helpers/format_helper';
import { getLinkForCard } from '../../../app/helpers/link_card_helper';
import { unescapeHtml } from '../../../app/helpers/make_html_helper';

import s from './discussed.css';

interface IProps {
  comment: IDiscussedItem;
}

export const DiscussedItem: React.FC<IProps> = props => {
  const [isHover, setIsHover] = useState(false);

  const onHover = () => {
    setIsHover(true);
  }

  const offHover = () => {
    setIsHover(false);
  }

  const userName = () => {
    const { attributes } = props.comment;
    const name = attributes.user && attributes.user.userId > 0
      ? (attributes.user.fullName || attributes.user.companyName || `ID: ${attributes.user.userId}`)
      : 'Аноним';

    if (attributes.user) {
      if (attributes.user.specialistLink) {
        return <a
          className={s['discussed-comments-comment-author-info__name']}
          href={`${attributes.user.specialistLink}`}>{name}</a>;
      }
    }

    return <div className={s['discussed-comments-comment-author-info__name']}>{name}</div>;
  }

  const MAX_CONTENT = 140;
  const { attributes } = props.comment;

  return (
    <div className={s['discussed-comments-comment']}>
      <Link
          to={
            getLinkForCard(
                  attributes.content.id,
                  attributes.content.type,
                  attributes.content.slug,
                )}
            className={`
              ${s['discussed-comments-comment__title']}
              ${isHover && s['discussed-comments-comment__title-hover']}
            `}>
        {attributes.content.title}
      </Link>
      <noindex>
        <Link
            to={
              getLinkForCard(
                  attributes.content.id,
                  attributes.content.type,
                  attributes.content.slug,
                  props.comment.id,
                )}
            className={s['discussed-comments-comment__content']}
            onMouseOver={onHover}
            onMouseOut={offHover}>
          {cropText(unescapeHtml(attributes.comment).split('<br />').join(' '), MAX_CONTENT)}
        </Link>
        <div className={s['discussed-comments-comment-author']}>
          {(attributes.user && attributes.user.avatar) &&
            <img
              src={unescapeHtml(attributes.user.avatar)}
              className={s['discussed-comments-comment-author__photo']}
              alt={attributes.user.fullName}/>
          }
          <div className={s['discussed-comments-comment-author-info']}>
            {userName()}
            <span className={s['discussed-comments-comment-author-info__date']}>
              {formatDateTodayYesterday(attributes.datePublish)}
            </span>
          </div>
        </div>
      </noindex>
    </div>
  );
}

import { IScroll, IScrollAction } from '../../../types/scroll';

export const SCROLL = 'scroll/SUCCESS_ACTION';
export const SCROLL_REFRESH = 'scroll/REFRESH_ACTION';

export const initialState: IScroll = {
  item: undefined,
};

const defaultAction: IScrollAction = {
  type: '',
  payload: initialState,
};

export const scrollReducer = (state = initialState, action = defaultAction) => {
  switch (action.type) {
    case SCROLL:
      return Object.assign({}, state, {
        item: action.payload.item,
      });
    case SCROLL_REFRESH:
      return Object.assign({}, initialState);
    default:
      return state;
  }
};

export const saveScroll = (item: IScroll): IScrollAction => {
  return {
    type: SCROLL,
    payload: { item },
  };
};

export const refreshScroll = () => {
  return {
    type: SCROLL_REFRESH,
  };
};

import { HttpBadStatusCodeError } from '@cian/peperrors/shared';

import { fetchGetSlider, TGetSliderResponse } from '../../../repositories/journal/v1/get-slider';
import { TThunkAction } from '../../../types/redux';
import { getSliderLoading } from './getSliderLoading';
import { getSliderSucceed } from './getSliderSucceed';
import { getSliderFailed } from './getSliderFailed';
import { ISliderNameAndAudienceType } from '../../../types/slider';
import { EAudienceAccessType } from '../../../repositories/journal/entities/page_info/PageInfoSchema';

type TGetSliderParams = Pick<ISliderNameAndAudienceType, 'name'>;

export const getSlider = ({ name }: TGetSliderParams): TThunkAction<Promise<void>> => {
  return async (dispatch, getState, context) => {
    const { httpApi, logger } = context;
    const {
      userNew: { isB2BModeEnabled },
    } = getState();
    const audienceAccessType = isB2BModeEnabled ? EAudienceAccessType.B2b : EAudienceAccessType.B2c;

    dispatch(getSliderLoading({ name, audienceAccessType }));

    try {
      const { response, statusCode }: TGetSliderResponse = await fetchGetSlider({
        httpApi,
        parameters: {
          b2b: isB2BModeEnabled,
          code: name,
        },
      });

      if (statusCode !== 200) {
        await Promise.reject(
          new HttpBadStatusCodeError(
            `Не удалось загрузить слайдер ${name} для ${audienceAccessType}`,
            statusCode,
            'actions.getSlider',
          ),
        );

        return;
      }

      dispatch(
        getSliderSucceed({
          name,
          title: response?.blockName || undefined,
          items: response?.data || [],
          audienceAccessType: response.b2b ? EAudienceAccessType.B2b : EAudienceAccessType.B2c,
        }),
      );
    } catch (e) {
      logger.error(e, {
        domain: 'actions.getSlider',
      });

      dispatch(getSliderFailed({ name, audienceAccessType }));
    }
  };
};

import * as React from 'react';
import { useEffect } from 'react';
import { render } from 'react-dom';
import { connect } from '@cian/adfox-component';

import { bannerWrapper } from '../../../components/TGBanner/TGBannerEmpty';
import { IExtractAndReplaceAdfoxItem } from './extractAndReplaceAdfox';

/**
 * Рендерит баннеры Adfox
 */
export const useContentAdfoxBanners = (extractedAdfoxItems: Pick<IExtractAndReplaceAdfoxItem, 'data'>[]) => {
  useEffect(() => {
    extractedAdfoxItems.forEach(banner => {
      const { ownerId, containerId, params } = banner.data;
      const Component = connect(bannerWrapper(() => null));
      render(
        <Component ownerId={ownerId} params={params} width="100%" height="0" />,
        document.getElementById(containerId),
      );
    });
  }, [extractedAdfoxItems]);
};

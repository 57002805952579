import * as queryString from 'qs';

import { IApplicationContext } from "../../../types/applicationContext";
import { isServer } from "../../../utils/isomorphic";

interface ILikeInfo {
  likesCount: number;
  dislikesCount: number;
}

interface ILike {
  likesCount: number;
  dislikesCount: number;
  userLike: number;
}

export const loadLikeInfo = (
    type: string,
    id: string,
    onSuccess: (data: any) => void,
    onFail: () => void,
) => {
  return (dispatch: any, getState: () => any, context: IApplicationContext) => {
    const params = queryString.stringify({
      content_type_model: type,
      object_pk: id,
    });

    return context.makeRequest(
      {
        method: 'GET',
        uri: {
          scheme: isServer ? getState().config.journalApiSchemeNode : getState().config.journalApiScheme,
          host: isServer ? getState().config.journalApiHostNode : getState().config.journalApiHost,
          path: (isServer ? getState().config.journalApiPathNode : getState().config.journalApiPath) + '/v1/get-likes/',
          query: params,
          subdomain: '',
          basePath: '',
        },
      })
      .then((likes: ILike) => {
        onSuccess(likes);
      })
      .catch((err: Error) => {
        onFail();
      },
    );
  };
};

export const submitVote = (
  type: string,
  objectID: string,
  vote: 1|-1,
  onSuccess: (likes: number, dislikes: number) => void,
  onFail: () => void,
) => {
  return (dispatch: any, getState: () => any, context: IApplicationContext) => {
    return context.makeRequest(
      {
        method: 'POST',
        uri: {
          scheme: getState().config.journalApiScheme,
          host: getState().config.journalApiHost,
          path: getState().config.journalApiPath + '/v1/add-like/',
          subdomain: '',
          basePath: '',
        },
        body: JSON.stringify({
          content_type_model: type,
          vote,
          object_pk: parseInt(objectID, 10),
        }),
      },
    )
    .then((data: ILikeInfo) => {
      onSuccess(data.likesCount, data.dislikesCount);
    })
    .catch((err: Error) => {
      onFail();
    });
  };
};

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * Обновляет мету
 */
export const useMeta = ([title, description, keywords, url, image]: string[]) => {
  const { search } = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(search);
    const metaDescription = document.querySelector('meta[name="description"]') as HTMLMetaElement;
    const metaKeywords = document.querySelector('meta[name="keywords"]') as HTMLMetaElement;
    const ogTitle = document.querySelector('meta[property="og:title"]') as HTMLMetaElement;
    const ogUrl = document.querySelector('meta[property="og:url"]') as HTMLMetaElement;
    const ogImage = document.querySelector('meta[property="og:image"]') as HTMLMetaElement;
    const ogDescription = document.querySelector('meta[property="og:description"]') as HTMLMetaElement;
    const twitterTitle = document.querySelector('meta[name="twitter:title"]') as HTMLMetaElement;
    const twitterImage = document.querySelector('meta[name="twitter:image"]') as HTMLMetaElement;
    const twitterDescription = document.querySelector('meta[name="twitter:description"]') as HTMLMetaElement;
    const linkCanonical = document.querySelector('link[rel="canonical"]') as HTMLLinkElement;

    document.title = title;

    if (metaDescription) {
      metaDescription.setAttribute('content', description);
    }

    if (metaKeywords) {
      metaKeywords.setAttribute('content', keywords);
    }

    if (ogTitle) {
      ogTitle.setAttribute('content', title);
    }

    if (ogUrl) {
      ogUrl.setAttribute('content', url);
    }

    if (ogImage) {
      ogImage.setAttribute('content', image || '');
    }

    if (ogDescription) {
      ogDescription.setAttribute('content', description);
    }

    if (twitterTitle) {
      twitterTitle.setAttribute('content', title);
    }

    if (twitterImage) {
      twitterImage.setAttribute('content', image || '');
    }

    if (twitterDescription) {
      twitterDescription.setAttribute('content', description);
    }

    if (query.get('page')) {
      if (linkCanonical) {
        linkCanonical.setAttribute('href', url);
      } else {
        const link = document.createElement('link');
        link.setAttribute('rel', 'canonical');
        link.setAttribute('href', url);
        document.head.appendChild(link);
      }
    } else {
      if (linkCanonical && linkCanonical.parentNode) {
        linkCanonical.parentNode.removeChild(linkCanonical);
      }
    }
  }, [search, title, description, keywords, url, image]);
};

import * as React from 'react';

export const IconChevronRightSmall16 = () => {
  return (
    // eslint-disable-next-line react/forbid-elements
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.91421 12.7L5.5 11.2857L8.79289 7.99284L5.5 4.69995L6.91421 3.28574L11.6213 7.99284L6.91421 12.7Z"
        fill="#A1A7B3"
      />
    </svg>
  );
};

import { IUser, IUserAction } from '../../../types/user';
import { isServer } from '../../../utils/isomorphic';

export const CURRENT_USER = 'users/CURRENT_USER';
export const CURRENT_USER_REQUEST = 'users/CURRENT_USER_REQUEST';
export const CURRENT_USER_SUCCESS = 'users/CURRENT_USER_SUCCESS';
export const CURRENT_USER_FAILURE = 'users/CURRENT_USER_FAILURE';

export const initialState: IUser = {
  isProfi: false,
  isBlock: false,
  linkUserProfiDescription: '',
  userIdentifications: false,
  userId: -1,
  email: '',
  userTrust: false,
  fullName: '',
  linkUserTrustDescription: '',
  avatar: '',
  permissions: {
    canModerateAnnouncements: false,
    canModerateAnnouncementsExpert: false,
    canModerateUsers: false,
    canViewAnnouncements: false,
    canViewUsers: false,
  },
  roles: [],
  isEmailConfirmed: false,
};

const defaultAction: IUserAction = {
  type: '',
  payload: {
    data: initialState,
  },
};

export const userReducer = (state = initialState, action = defaultAction) => {
  switch (action.type) {
    case CURRENT_USER_REQUEST:
      return state;

    case CURRENT_USER_SUCCESS:
      return Object.assign({}, action.payload.data);

    case CURRENT_USER_FAILURE:
      return Object.assign({}, state);

    default:
      return state;
  }
};

export const fetchUser = () => {
  return {
    type: CURRENT_USER,
    method: 'GET',
    payload: {
      pathname: '/v1/get-user',
      basePath: isServer ? '' : '/content-api',
    },
    request: true,
  };
};

export const fetchProfileUser = (id: number) => {
  return {
    type: CURRENT_USER,
    method: 'GET',
    payload: {
      pathname: '/v1/get-user',
      params: [
        {
          name: 'id',
          value: id,
        },
      ],
    },
    request: true,
  };
};

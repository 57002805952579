import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BlogsSubscribeForm } from '../../components/SubscribeForms';
import { validateEmail } from '../../utils';
import { subscribeToList, subscribeToListReset } from '../../reducers/modules/list_subscribe/list_subscribe';
import { listSubscribe } from '../../selectors/subscription/listSubscribe';

/**
 * Форма подписки для блогов
 */
export const BlogsSubscribeFormContainer = () => {
  const [errorText, setErrorText] = useState<string>('');
  const [subscribedText, setSubscribedText] = useState<string>('');
  const dispatch = useDispatch();
  const { status, statusCode } = useSelector(listSubscribe);

  /** Готовит сообщение об успехе */
  useEffect(() => {
    if (status === 'ok') {
      setSubscribedText('Теперь вам на почту будут приходить последние новости и и публикации авторов блога');

      return;
    }

    setSubscribedText('');
  }, [status]);

  /** Готовит сообщение о сетевой ошибке */
  useEffect(() => {
    if (statusCode === 400) {
      setErrorText('Что-то пошло не так. Попробуйте еще раз');

      return;
    }

    setErrorText('');
  }, [statusCode]);

  /** Сбрасывает состояние на дефолтное при уходе */
  useEffect(() => {
    return () => {
      dispatch(subscribeToListReset());
    };
  }, [dispatch]);

  /** Проверяет валидность значения и дергает ручку подписки */
  const handleSubmit = useCallback(
    email => {
      if (!validateEmail(email)) {
        setErrorText('Проверьте правильность написания почты');

        return;
      }

      dispatch(subscribeToList('blogs', email));
    },
    [dispatch],
  );

  const handleChange = useCallback(() => {
    if (errorText) {
      setErrorText('');
    }
  }, [errorText]);

  return (
    <BlogsSubscribeForm
      isDisabled={false}
      errorText={errorText}
      subscribedText={subscribedText}
      onChange={handleChange}
      onSubmit={handleSubmit}
    />
  );
};

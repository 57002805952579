/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TGetJournalMoreModel,
  IGetJournalMoreRequest,
  IMappers,
  IGetJournalMoreResponse,
  IGetJournalMoreResponseError,
  TGetJournalMoreResponse,
} from './types';

const defaultConfig: TGetJournalMoreModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'GET',
  microserviceName: 'journal',
  pathApi: '/v1/get-journal-more/',
  hostType: 'api',
} as TGetJournalMoreModel;

function createGetJournalMoreModel(parameters: IGetJournalMoreRequest): TGetJournalMoreModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetJournalMoreOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IGetJournalMoreRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchGetJournalMore<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IGetJournalMoreOptions<TResponse200, TResponse400>): Promise<TResponse200 | TResponse400 | TGetJournalMoreResponse> {
  const { response, statusCode } = await httpApi.fetch(createGetJournalMoreModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetJournalMoreResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IGetJournalMoreResponseError);
    }
  }

  return { response, statusCode } as TGetJournalMoreResponse;
}

export { defaultConfig, createGetJournalMoreModel, fetchGetJournalMore };

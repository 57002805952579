import { TDeviceType } from "@cian/ui-kit";
import { ca } from '@cian/analytics';

interface ITrackEventProps {
  bannerLocation: string;
  deviceType?: TDeviceType
  action: string;
  label: string;
}
const trackEvent = (props: ITrackEventProps) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Application_to_realtor',
    action: props.action,
    label: props.label,
    page: {
      pageType: 'Magazine',
      siteType: props.deviceType === 'phone' ? 'mobile' : props.deviceType,
      extra: {
        banner_location: props.bannerLocation
      }
    }
  });
}

export const trackBannerViewed = (bannerLocation: string, deviceType?: TDeviceType) => {
  trackEvent({
    action: 'Show',
    label: 'Banner',
    bannerLocation,
    deviceType,
  });
}

export const trackBannerClicked = (bannerLocation: string, deviceType?: TDeviceType) => {
  trackEvent({
    action: 'Click',
    label: 'Banner',
    bannerLocation,
    deviceType,
  });
}

export const trackOpenFormClicked = (bannerLocation: string, deviceType?: TDeviceType) => {
  trackEvent({
    action: 'Click',
    label: 'InfoPopup',
    bannerLocation,
    deviceType,
  });
}

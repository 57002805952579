/* eslint-disable */
//tslint:disable

export interface ISearchMetaSchema {
  /** Аудитория **/
  b2b?: boolean | null;
  /** Количество записей **/
  limit: number;
  /** Сдвиг **/
  offset: number;
  /** Тип поиска **/
  qType: EQType;
  /** ID региона **/
  regionId?: number | null;
  /** Всего записей **/
  total: number;
  /** Тип поиска **/
  type: EType;
}

export enum EQType {
  /** Поиск по журналу ("Статьи/Новости/Блоги/Вопросы) **/
  Journal = 'journal',
  /** Поиск по тегам **/
  Tags = 'tags',
}
export enum EType {
  /** Статьи/Новости/Блоги/Вопросы **/
  All = 'all',
  /** Статьи **/
  Articles = 'articles',
  /** Блоги **/
  Blogs = 'blogs',
  /** 'Новости **/
  News = 'news',
  /** Вопросы **/
  Questions = 'questions',
}

import React, { useCallback } from 'react';
import { ArticleHeading1, ArticleHeading2, ArticleHeading3, useDeviceType } from '@cian/ui-kit';

import { IArticlesMainAttributesSchema } from '../../../../repositories/journal/entities/journal/ArticlesMainAttributesSchema';
import * as s from './Title.css';

export interface ITitle extends Partial<Pick<IArticlesMainAttributesSchema, 'noIndex'>> {
  url?: string;
  children?: string;
  onClick?(e: React.MouseEvent): void;
}

/**
 * Заголовок
 */
export const Title = ({ children, url, noIndex, onClick }: ITitle) => {
  const isPhone = useDeviceType() === 'phone';
  const isTablet = useDeviceType() === 'tablet';
  const HeadingComponent = isPhone ? ArticleHeading3 : isTablet ? ArticleHeading2 : ArticleHeading1;

  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      e.preventDefault();

      if (onClick) {
        onClick(e);
      }
    },
    [onClick],
  );

  if (!children) {
    return null;
  }

  if (url) {
    return (
      <a
        href={url}
        className={s['title']}
        title={children}
        rel={noIndex ? 'nofollow' : undefined}
        onClick={handleClick}
      >
        <HeadingComponent itemProp="headline" color="black_100">
          {children}
        </HeadingComponent>
      </a>
    );
  }

  return (
    <HeadingComponent itemProp="headline" color="black_100">
      {children}
    </HeadingComponent>
  );
};

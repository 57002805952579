import { EUserAccessType, EType, IPageInfoSchema } from '../repositories/journal/entities/page_info/PageInfoSchema';

export const PageMetaPostCard: IPageInfoSchema = {
  rubricId: 0,
  category: null,
  metaTitle: '',
  metaDescription: '',
  metaKeywords: '',
  metaImage: '',
  canonicalUrl: '',
  noIndex: false,
  type: EType.PostCard,
  title: '',
  breadCrumbName: 'Журнал',
  breadCrumbParents: [],
  pathname: '/prefix-slug-0/',
  audienceAccessType: null,
  userAccessType: EUserAccessType.Unauthorised,
};

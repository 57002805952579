import { journalReducer } from '../../shared/reducers/modules/journal/journal';
import { newsReducer } from '../../shared/reducers/modules/news/news';
import { articlesReducer } from '../../shared/reducers/modules/articles/articles';
import { blogsReducer } from '../../shared/reducers/modules/blogs/blogs';
import { questionsReducer } from '../../shared/reducers/modules/questions/questions';
import { pollReducer } from '../../shared/reducers/modules/poll/poll';
import { postCardReducer } from '../../shared/reducers/modules/post_card/post_card';
import { postPreviewReducer } from '../../shared/reducers/modules/post_preview/post_preview';
import { advertisingReducer } from '../../shared/reducers/modules/advertising/advertising';
import { seoDataReducer } from '../../shared/reducers/modules/seo/seo';
import { selectedPostsReducer } from '../../shared/reducers/modules/selected_posts/selected_posts';
import { breadCrumbReducer } from '../../shared/reducers/modules/bread_crumbs/bread_crumbs';
import { readTopicReducer } from '../../shared/reducers/modules/read_topic/read_topic';
import { seoUrlsReducer } from '../../shared/reducers/modules/seo_urls/seo_urls';
import { scrollReducer } from '../../shared/reducers/modules/scroll/scroll';
import { userReducer } from '../../shared/reducers/modules/current_user';
import { subscribeReducer } from '../../shared/reducers/modules/subscribe/subscribe';
import { discussedReducer } from '../../shared/reducers/modules/discussed/discussed';
import { commentReducer } from '../../shared/reducers/modules/comments';
import { scrollCommentReducer } from '../../shared/reducers/modules/scroll_comment/scroll_comment';
import { ratingReducer } from '../../shared/reducers/modules/rating/rating';
import { addBlogReducer } from '../../shared/reducers/modules/add_blog/add_blog';
import { configReducer } from '../../shared/reducers/modules/config/config';
import { regionsReducer } from '../../shared/reducers/modules/regions/regions';
import { tagsReducer } from '../../shared/reducers/modules/tags/tags';
import { addQuestionReducer } from '../../shared/reducers/modules/add_question/add_question';
import { listSubscribeReducer } from '../../shared/reducers/modules/list_subscribe/list_subscribe';
import { searchReducer } from '../../shared/reducers/modules/search/search';
import { complaintReducer } from '../../shared/reducers/modules/complaint/complaint';
import { profileBlogsReducer } from '../../shared/reducers/modules/profile/blogs';
import { profileQuestionsReducer } from '../../shared/reducers/modules/profile/questions';
import { profileAnswersReducer } from '../../shared/reducers/modules/profile/answers';
import { globalErrorReducer } from '../../shared/reducers/modules/global_error/global_error';
import { analyticsReducer } from '../../shared/reducers/modules/analytics/analytics';
import { deviceTypeReducer } from '../../shared/reducers/modules/device_type';
import { rubricsMenuReducer } from '../../shared/reducers/modules/rubrics_menu/rubrics_menu';

export const oldReducers = {
  addBlog: addBlogReducer,
  addQuestion: addQuestionReducer,
  advertising: advertisingReducer,
  analytics: analyticsReducer,
  articles: articlesReducer,
  blogs: blogsReducer,
  breadCrumb: breadCrumbReducer,
  comments: commentReducer,
  complaint: complaintReducer,
  config: configReducer,
  discussed: discussedReducer,
  globalError: globalErrorReducer,
  journal: journalReducer,
  listSubscribe: listSubscribeReducer,
  news: newsReducer,
  poll: pollReducer,
  postCard: postCardReducer,
  postPreview: postPreviewReducer,
  profileAnswers: profileAnswersReducer,
  profileBlogs: profileBlogsReducer,
  profileQuestions: profileQuestionsReducer,
  questions: questionsReducer,
  rating: ratingReducer,
  readTopic: readTopicReducer,
  regions: regionsReducer,
  scroll: scrollReducer,
  scrollComment: scrollCommentReducer,
  search: searchReducer,
  selectedPosts: selectedPostsReducer,
  seoData: seoDataReducer,
  seoUrls: seoUrlsReducer,
  subscribe: subscribeReducer,
  tags: tagsReducer,
  user: userReducer,
  deviceType: deviceTypeReducer,
  menuRubrics: rubricsMenuReducer,
};

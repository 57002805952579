import { IUser } from '../../shared/types/user';
import { PERMISSIONS, TPermission } from './permissions';
import { IPostCard } from '../../shared/types/post_card';
import { IList } from '../../shared/types/list_item_props';

export class Permission {
  public user: IUser;
  public model: IPostCard | IList;
  public permission: TPermission;

  public constructor(user: IUser, model: IPostCard | IList) {
    this.user = user;
    this.model = model;
    this.permission = PERMISSIONS[model.item.type];
  }

  public canEdit() {
    return this.permission.canEdit(this.user, this.model);
  }

  public canHide() {
    return this.permission.canHide(this.user, this.model);
  }

  public canDelete() {
    return this.permission.canDelete(this.user, this.model);
  }

  public canPublish() {
    return this.permission.canPublish(this.user, this.model);
  }

  public canSeeCaptions() {
    return this.permission.canSeeCaptions(this.user, this.model);
  }

  public canLikes() {
    return this.permission.canLikes(this.user, this.model);
  }

  /** Можно ли оставлять комментарии */
  public canComment() {
    return this.permission.canComment(this.user, this.model);
  }

  /** Можно ли просматривать комментарии */
  public canSeeComments() {
    return this.permission.canSeeComments(this.user, this.model);
  }

  public canComplaint() {
    return this.permission.canComplaint(this.user, this.model);
  }

  public canSubscribeComments() {
    return this.permission.canSubscribeComments(this.user, this.model);
  }
}

import { TDeviceType } from '@cian/ui-kit/types/TDeviceType';
import { IPageCa } from '../../../types/analytics';
import { IBreadItem } from '../../../types/bread_crumb';

export interface ITransformPageParams {
  breadCrumbs: IBreadItem[];
  deviceType: TDeviceType;
  extra?: IPageCa['extra'];
}

/**
 * Приводит данные страницы к формату CA $.page
 */
export const transformPage = ({ breadCrumbs, deviceType, extra }: ITransformPageParams): IPageCa => ({
  breadCrumbs: breadCrumbs.map(breadCrumb => breadCrumb.name || 'Журнал'),
  pageType: 'Magazine',
  siteType: deviceType === 'phone' ? 'mobile' : deviceType,
  extra,
});

import { ITypedReduxAction } from '../../../types/redux/actionType';
import { IRecommendedOfferSchema } from '../../../repositories/search-engine/entities/recommended_offers/RecommendedOfferSchema';

export enum EGetOffersFromHistoryActionType {
  Loading = 'recommendedOffers/getOffersFromHistory/loading',
  Succeed = 'recommendedOffers/getOffersFromHistory/succeed',
  Failed = 'recommendedOffers/getOffersFromHistory/failed',
}

export type TGetOffersFromHistoryLoading = ITypedReduxAction<EGetOffersFromHistoryActionType.Loading>;
export type TGetOffersFromHistorySucceed = ITypedReduxAction<
  EGetOffersFromHistoryActionType.Succeed,
  IRecommendedOfferSchema[]
>;
export type TGetOffersFromHistoryFailed = ITypedReduxAction<EGetOffersFromHistoryActionType.Failed>;

export type TGetOffersFromHistory =
  | TGetOffersFromHistoryLoading
  | TGetOffersFromHistorySucceed
  | TGetOffersFromHistoryFailed;

import React from 'react';

import * as s from './ModalContent.css';

/**
 * Содержимое модалки
 */
export const ModalContent = () => (
  <div className={s['wrapper']}>
    <div className={s['item']}>Риелтор возьмет на себя решение юридических вопросов</div>
    <div className={s['item']}>
      Проведет сложную сделку: продажа с последующей покупкой квартиры; покупка и продажа с
      <br />
      использованием ипотеки или
      <br />
      материнского капитала и др.
    </div>
    <div className={s['item']}>
      Риелтора подберем бесплатно. Условия
      <br />
      сотрудничества и стоимость
      <br />
      услуг обсуждается с риелтором
    </div>
  </div>
);

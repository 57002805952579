import { IComplaint, IComplaintAction } from '../../../types/complaint';

export const COMPLAINT_REQUEST = 'complaint/ACTION_REQUEST';
export const COMPLAINT_SUCCESS = 'complaint/ACTION_SUCCESS';
export const COMPLAINT_FAILURE = 'complaint/ACTION_FAILURE';
export const COMPLAINT = 'complaint/ACTION';

export const initialState: IComplaint = {
  status: false,
  objectId: -1,
};

const defaultAction: IComplaintAction = {
  type: '',
  payload: initialState,
};

export const complaintReducer = (state = initialState, action = defaultAction) => {
  switch (action.type) {
    case COMPLAINT_REQUEST:
      return Object.assign({}, { status: false, objectId: -1 });

    case COMPLAINT_SUCCESS:
      return Object.assign({}, { status: true, objectId: (action.payload as any).data.attributes.objectPk });

    case COMPLAINT_FAILURE:
      return Object.assign({}, {  status: false, objectId: -1 });

    default:
      return state;
  }
};

export const complaint = (content_type_model: string, reason: string, object_pk: number) => {
  return {
    type: COMPLAINT,
    method: 'POST',
    payload: {
      pathname: `/v1/complain-email`,
      data: {
        attributes: {
          content_type_model,
          reason,
          object_pk,
        },
      },
    },
    request: true,
  };
};

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SidebarDiscussions } from '../../components/SidebarDiscussions';
import { selectDiscussions } from '../../selectors/discussions';
import { ERequestStatus } from '../../types/requestStatus';
import { getDiscussions, resetDiscussions } from '../../actions/discussions';
import { TThunkDispatch } from '../../types/redux';

export const SidebarDiscussionsContainer = () => {
  const dispatch = useDispatch<TThunkDispatch>();
  const { items: discussions, status: discussionsStatus } = useSelector(selectDiscussions);

  /** Запрашивает список дискуссий, если еще ни разу не запрашивал */
  useEffect(() => {
    if (discussionsStatus === ERequestStatus.Initial) {
      dispatch(getDiscussions({ limit: 4 })).finally();
    }
  }, [dispatch, discussionsStatus]);

  /** При выходе сбрасывает список обсуждений */
  useEffect(() => {
    return () => {
      dispatch(resetDiscussions());
    };
  }, [dispatch]);

  return <SidebarDiscussions items={discussions} />;
};

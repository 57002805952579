import { EType } from '../../repositories/journal/entities/journal/JournalListAttributesSchema';

interface IBuildPostUrl {
  type: EType;
  slug: string;
  id: number;
}

/** Строит url материала */
export const buildPostUrl = ({ type, slug, id }: IBuildPostUrl): string => {
  const typePrefix = {
    [EType.Articles]: 'stati',
    [EType.Blogs]: 'blogs',
    [EType.News]: 'novosti',
    [EType.Questions]: 'forum-rieltorov',
    [EType.Quizzes]: 'quizzes',
  };

  return `/${typePrefix[type]}-${slug}-${id}/`;
};

import * as React from 'react';
import * as ReactDOM from 'react-dom';

import { IRatingItem } from '../../types/rating';
import { AuthorBlock } from '../author_block/author_block';

import s from './rating_table.css';

interface IProps {
  item: IRatingItem;
  agentId?: string;
}

export class RatingRow extends React.Component<IProps> {

  public componentDidMount() {
    if (this.props.item.attributes.specialist && this.props.item.attributes.specialist.userId.toString() === this.props.agentId) {
      setTimeout(this.scrollToEl, 2000);
    }
  }

  public render() {
    const { item, agentId } = this.props;

    return (
      <tr
          className={`
            ${s['table-row']}
            ${item.attributes.specialist && item.attributes.specialist.specialistLink ? s['table-row-link'] : ''}
            ${item.attributes.specialist &&
                item.attributes.specialist.userId.toString() === agentId ? s['my-rating'] : ''}`}
          onClick={() => {
            if (item.attributes.specialist && item.attributes.specialist.specialistLink) {
              window.location.replace(item.attributes.specialist.specialistLink);
            }
          }}>
        <td className={`${s['table-row_cell']}`}>
          { item.attributes.position }
        </td>
        <td className={`${s['table-row_cell']}`}>
          {
            item.attributes.specialist ?
              <AuthorBlock
                  containerClass={s['author-container']}
                  small={true}
                  author={
                    item.attributes.specialist
                  }
                  authorClass={s['author-container-specialist']} /> :
              <AuthorBlock
                  containerClass={s['author-container']}
                  small={true} />
          }
        </td>
        <td className={`${s['table-row_cell']}`}>
          { item.attributes.rating }
        </td>
        <td className={`${s['table-row_cell']}`}>
          { item.attributes.advicesCount }
        </td>
        <td className={`${s['table-row_cell']}`}>
          { item.attributes.bestAdvicesCount }
        </td>
        <td className={`${s['table-row_cell']}`}>
          { item.attributes.blogsCount }
        </td>
        <td className={`${s['table-row_cell']}`}>
          { item.attributes.popularBlogsCount }
        </td >
        <td className={s['table-row_cell']}>
          { item.attributes.reviewsCount }
        </td >
        <td className={s['table-row_cell']}>
          { item.attributes.popularReviewsCount }
        </td >
      </tr>
     );
  }

  private scrollToEl = () => {
    const node = ReactDOM.findDOMNode(this);
    if (node && node instanceof Element) {
      node.scrollIntoView();
    }
  }
}

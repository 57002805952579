import { ITypedReduxAction } from '../../../types/redux/actionType';
import { ISettings } from '../../../types/settings';

export enum ESetListingAppendTypeActionType {
  Set = 'settings/listingAppendType/set',
}

export type TSetListingAppendType = ITypedReduxAction<
  ESetListingAppendTypeActionType.Set,
  ISettings['listingAppendType']
>;

import { HttpBadStatusCodeError } from '@cian/peperrors/shared';

import {
  fetchGetUserDataByRequest,
  TGetUserDataByRequestResponse,
} from '../../../repositories/monolith-python/v1/get-user-data-by-request';
import { TThunkAction } from '../../../types/redux';
import { getUserFailed } from './getUserFailed';
import { getUserLoading } from './getUserLoading';
import { getUserSucceed } from './getUserSucceed';
import { prepareUser } from './utils';

export const getUser = (): TThunkAction<Promise<void>> => {
  return async (dispatch, getState, context) => {
    const { httpApi, logger } = context;

    dispatch(getUserLoading());

    try {
      const { response, statusCode }: TGetUserDataByRequestResponse = await fetchGetUserDataByRequest({
        httpApi,
        parameters: {},
      });

      if (statusCode !== 200 || !response.data?.user) {
        return Promise.reject(
          new HttpBadStatusCodeError('Не удалось загрузить пользователя', statusCode, 'actions.getUser'),
        );
      }

      dispatch(getUserSucceed(prepareUser(response.data.user)));
    } catch (e) {
      logger.error(e, {
        domain: 'actions.getUser',
      });

      dispatch(getUserFailed());
    }
  };
};

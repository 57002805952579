import * as React from 'react';

import { HonestWork, PopupDirection } from '@cian/honestwork-component';
import { Location } from 'history';

import { Answers } from './answers';
import { CommentForm } from './comment_form';
import { IComment, ICommentState } from '../../types/posts';
import { IUser } from '../../types/user';
import { formatDateFull } from '../../../app/helpers/format_helper';
import { canEditDelete } from '../../../app/helpers/comments_helper';
import { Likes } from '../../containers/likes/likes';
import { permissionsFull, permissionsExtend } from './permissions';
import { getCleanText } from '../../../app/helpers/make_html_helper';
import { avatar } from './avatar';

import s from './comments.css';

interface IProps {
  location: Location;
  canComment: boolean;
  user: IUser;
  likesShown: boolean;
  author?: IUser;
  restrictComments: boolean;
  commentsState: ICommentState;
  comment: IComment;
  onCommentEdit(id: number, comment: string, email: string): void;
  onCommentDelete(id: number, email: string): void;
  onCommentHide(id: number, email: string): void;
  onCommentSubmit(
    comment: string,
    userId: number,
    fullName: string,
    email: string,
    parentID: number | null,
    subs: boolean,
  ): void;
  cardType: string;
}

interface IState {
  addingComment: boolean;
  replyTo: string;
  parentID: number | null;
  editingComment: boolean;
  likesCount: number;
  dislikesCount: number;
  currentUserLike: boolean;
  currentUserDislike: boolean;
  savedFields?: {
    comment: string;
    name: string;
    email: string;
    subscribe: boolean;
  };
  userLike: 1 | -1 | null;
}

class Comment extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      addingComment: false,
      replyTo: '',
      parentID: null,
      editingComment: false,
      likesCount: props.comment.likesCount || 0,
      dislikesCount: props.comment.dislikesCount || 0,
      currentUserLike: false,
      currentUserDislike: false,
      userLike: props.comment.userLike || null,
    };
  }

  // eslint-disable-next-line react/no-deprecated
  public componentWillReceiveProps(nextProps: IProps) {
    if (!nextProps.commentsState.error && nextProps.commentsState.loaded) {
      this.setState({
        addingComment: false,
        replyTo: '',
        parentID: null,
        editingComment: false,
        likesCount: this.state.likesCount,
        dislikesCount: this.state.dislikesCount,
        currentUserLike: this.state.currentUserLike,
        currentUserDislike: this.state.currentUserDislike,
      });
    }
  }

  public render() {
    const { comment } = this.props;

    return (
      <div className={s['comments__list_item']}>
        <div
            id={`${comment.id}`}
            className={`
              ${s['comments__list_wrap-comment']}
              ${this.isNew() || this.isSelected() ? s['comments__list_item-new'] : ''}`}>
          <div className={s['comments__item_wrap-info']}>
            { comment.user && avatar(comment.user) }
            { !comment.user && <div className={s['comments__form_photo-default']}/> }
            <div className={s['comments__item_info']}>
              <span className={s['comments__item_name']}>
                {this.userName}
                { comment.user && comment.bestAdvice &&
                    <span className={s['comments__item_best-advice']}>
                      Лучший совет
                    </span>
                }
                { comment.user && comment.user.userTrust &&
                  <HonestWork
                    direction={PopupDirection.RIGHT}
                    className={s['comments__item_honest']} />
                }
              </span>
              <span className={s['comments__item_date']}>
                { formatDateFull(comment.datePublish) }
              </span>
            </div>
            {
              this.props.likesShown &&
                <div className={s['comments__likes-wrap-dekstop']}>
                  <Likes
                      disabled={!this.props.canComment ||
                        (this.props.comment.user && this.props.user.userId === this.props.comment.user.userId) ||
                        this.props.user.isBlock}
                      isCompact={true}
                      likesCount={this.state.likesCount || 0}
                      dislikesCount={this.state.dislikesCount || 0}
                      userLike={this.state.userLike}
                      onLikesUpdate={(likes: number, dislikes: number, userLike: 1 | -1 | null) =>
                        this.setState(Object.assign({}, this.state, {
                          likesCount: likes,
                          currentUserLike: false,
                          currentUserDislike: false,
                          dislikesCount: dislikes,
                          userLike: userLike,
                        }))
                      }
                      type="threadedcomment"
                      objectID={`${comment.id}`} />
                </div>
            }
          </div>
           <p
              dangerouslySetInnerHTML={{ __html: getCleanText(comment.comment) }}
              className={s['comments__item_text']} />
          <div className={s['comments__item_bottom-line']}>
            {
              this.props.likesShown &&
                <div className={s['comments__likes-wrap-mobile']}>
                  <Likes
                      disabled={!this.props.canComment ||
                        (this.props.comment.user && this.props.user.userId === this.props.comment.user.userId) ||
                        this.props.user.isBlock}
                      isCompact={true}
                      likesCount={this.state.likesCount || 0}
                      dislikesCount={this.state.dislikesCount || 0}
                      userLike={this.state.userLike}
                      onLikesUpdate={(likes: number, dislikes: number, userLike: 1 | -1 | null) =>
                        this.setState(Object.assign({}, this.state, {
                          likesCount: likes,
                          currentUserLike: false,
                          currentUserDislike: false,
                          dislikesCount: dislikes,
                          userLike: userLike,
                        }))
                      }
                      type="threadedcomment"
                      objectID={`${comment.id}`} />
                </div>
            }
            { (!this.state.addingComment) &&
                  this.canComment() &&
                  this.props.canComment &&
                <span
                    className={s['comments__item_bottom-line-item']}
                    onClick={this.handleReply}>
                  Ответить
                </span>
            }
            { this.isCanEdit &&
                <span
                    className={s['comments__item_bottom-line-item']}
                    onClick={() => this.setState({
                      addingComment: false,
                      replyTo: comment.user && comment.user.userId > 0
                        ? (comment.user.fullName || comment.user.companyName || `ID: ${comment.user.userId}`)
                        : 'Аноним',
                      parentID: comment.id,
                      editingComment: true,
                      likesCount: this.state.likesCount,
                      dislikesCount: this.state.dislikesCount,
                      currentUserLike: this.state.currentUserLike,
                      currentUserDislike: this.state.currentUserDislike,
                    })}>
                  Редактировать
                </span>
            }
            { this.isCanDelete &&
                <span
                    className={s['comments__item_bottom-line-item']}
                    onClick={() =>
                      this.props.onCommentDelete(comment.id, comment.user.email)
                    }>
                  Удалить
                </span>
            }
            { this.isCanHide &&
                <span
                    className={s['comments__item_bottom-line-item']}
                    onClick={() =>
                      this.props.onCommentHide(comment.id, comment.user.email)
                    }>
                  Скрыть
                </span>
            }
          </div>
          { this.props.canComment &&
                (this.state.addingComment || this.state.editingComment) &&
              <CommentForm
                  onOpen={() => {}}
                  onClose={() => this.setState({
                    addingComment: false,
                    replyTo: '',
                    parentID: null,
                    editingComment: false,
                    likesCount: this.state.likesCount,
                    dislikesCount: this.state.dislikesCount,
                    currentUserLike: this.state.currentUserLike,
                    currentUserDislike: this.state.currentUserDislike,
                  })}
                  onCommentSubmit={this.onCommentSubmit}
                  commentsState={this.props.commentsState}
                  replyTo={this.state.replyTo}
                  parentID={this.state.parentID}
                  editingComment={this.state.editingComment}
                  onCommentEdit={this.props.onCommentEdit}
                  comment={this.props.comment}
                  isOpened={true}
                  user={this.props.user}
                  saveFields={this.saveFields}
                  savedFields={this.state.savedFields}
                  cardType={this.props.cardType} />
          }
        </div>
        { (comment.comments.length > 0) &&
            <Answers
                location={this.props.location}
                likesShown={this.props.likesShown}
                canComment={this.props.canComment && this.canComment()}
                replyTo={comment.user && comment.user.userId > 0
                  ? (comment.user.fullName || `ID: ${comment.user.userId}`)
                  : 'Аноним'}
                parentID={comment.id}
                onCommentSubmit={this.onCommentSubmit}
                commentsState={this.props.commentsState}
                user={this.props.user}
                answers={comment.comments}
                onCommentEdit={this.props.onCommentEdit}
                onCommentDelete={this.props.onCommentDelete}
                onCommentHide={this.props.onCommentHide}
                 cardType={this.props.cardType} />
        }
      </div>
    );
  }

  private handleReply = () => {
    const { comment, user } = this.props;
    const {
      likesCount,
      dislikesCount,
      currentUserLike,
      currentUserDislike,
    } = this.state;

    if (user.userId === -1) {
      window.waitForLoginPopup();

      return;
    }

    this.setState({
      addingComment: true,
      replyTo: comment.user && comment.user.userId > 0
        ? (comment.user.fullName || comment.user.companyName || `ID: ${comment.user.userId}`)
        : 'Аноним',
      parentID: comment.id,
      editingComment: false,
      likesCount,
      dislikesCount,
      currentUserLike,
      currentUserDislike,
    })
  }

  private canComment() {
    return (
      (!this.props.restrictComments) ||
      (
        !!this.props.author && !!this.props.comment.user &&
        (
          (this.props.author.userId === this.props.user.userId) ||
          (this.props.comment.user.userId === this.props.user.userId)
        )
      )
    );
  }

  protected onCommentSubmit = (
    comment: string,
    userId: number,
    fullName: string,
    email: string,
    parentID: number | null,
    subs: boolean,
  ) => this.props.onCommentSubmit(comment, userId, fullName, email, parentID, subs);

  private get isAuthor() {
    const { user, comment } = this.props;

    return user && comment.user && (user.userId === comment.user.userId);
  }

  private isNew() {
    const query = new URLSearchParams(this.props.location.search);
    return (
      query.get('ts') &&
      parseInt(query.get('ts') as string) ||
      new Date().getTime()
    ) - 2 * 60 * 60 * 1000 < new Date(this.props.comment.datePublish).getTime();
  }

  private isSelected() {
    const hash = this.props.location.hash;
    return (
      hash &&
      Number(hash.replace('#', '')) === this.props.comment.id
    );
  }

  private get isCanEdit() {
    const { comment } = this.props;

    return this.isAuthor && canEditDelete(comment.datePublish) && !this.state.editingComment;
  }

  private get isCanDelete() {
    const { comment, cardType } = this.props;

    return this.isAuthor &&
      canEditDelete(comment.datePublish) ||
      (cardType === 'Question' && this.props.user.roles.includes(permissionsFull));
  }

  private get isCanHide() {
    return this.props.user &&
      (this.props.user.roles.includes(permissionsFull) ||
            this.props.user.roles.includes(permissionsExtend));
  }

  private saveFields = (comment: string, name: string, email: string, subscribe: boolean) => {
    this.setState({
      addingComment: this.state.addingComment,
      replyTo: this.state.replyTo,
      parentID: this.state.parentID,
      editingComment: this.state.editingComment,
      savedFields: {
        comment,
        name,
        email,
        subscribe,
      },
      likesCount: this.state.likesCount,
      dislikesCount: this.state.dislikesCount,
      currentUserLike: this.state.currentUserLike,
      currentUserDislike: this.state.currentUserDislike,
    });
  }

  private get userName() {
    const { comment } = this.props;
    const name = comment.user && comment.user.userId > 0
      ? (comment.user.fullName || comment.user.companyName || `ID: ${comment.user.userId}`)
      : 'Аноним';

    if (comment.user) {
      if (comment.user.specialistLink) {
        return <a className={s['comments__item_name-text']} href={`${comment.user.specialistLink}`}>{name}</a>;
      }
    }

    return <span className={s['comments__item_name-text']}>{name}</span>;
  }
}

export { Comment }

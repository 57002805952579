import React from 'react';
import { useDeviceType } from '@cian/ui-kit';

import { Skeleton } from '../Skeleton';
import * as s from './CardListingItem.css';

/**
 * Скелетон для карточки в списке
 */
export const SkeletonCardListingItem = () => {
  const deviceType = useDeviceType();
  const isPhone = () => deviceType === 'phone';

  return (
    <div>
      <Skeleton height={isPhone() ? 130 : 180} />
      <div className={s['content-wrapper']}>
        <Skeleton width={'50%'} height={24} margin={'0 0 16px 0'} />
        <Skeleton variant={'text-22'} />
        <Skeleton variant={'text-22'} />
        <Skeleton variant={'text-22'} width={'70%'} margin={'0 0 16px 0'} />
        <Skeleton variant={'text-22'} width={'60%'} />
      </div>
    </div>
  );
};

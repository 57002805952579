import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Select, useDeviceType } from '@cian/ui-kit';

import { DEFAULT_CONTENT_TYPE_OPTIONS, IDefaultContentTypeOption } from './constants';
import { ERequestStatus } from '../../../types/requestStatus';
import { useCurrentListing, prepareQSParam } from '../../../utils';
import { IApplicationState } from '../../../types/redux';
import { selectCurrentPageMeta } from '../../../selectors/pagesMeta';
import { EType } from '../../../repositories/journal/entities/page_info/PageInfoSchema';
import * as s from './ContentTypeContainer.css';

/**
 * Кнопки фильтрации типов постов
 */
export const ContentTypeContainer = () => {
  const deviceType = useDeviceType();
  const isPhone = () => deviceType === 'phone';
  const { pathname, search } = useLocation();
  const { type: currentPageMetaType } = useSelector((state: IApplicationState) =>
    selectCurrentPageMeta(state, pathname),
  );
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const history = useHistory();
  const [activeContextType, setActiveContextType] = useState<IDefaultContentTypeOption['value']>();
  const {
    status: listingStatus,
    meta: { total },
  } = useCurrentListing();

  /**
   * Устанавливаем активный тип
   */
  useEffect(() => {
    if (![EType.MainPage, EType.News, EType.Articles, EType.Blogs, EType.Questions].includes(currentPageMetaType)) {
      setActiveContextType(EType.MainPage);

      return;
    }

    setActiveContextType(currentPageMetaType as IDefaultContentTypeOption['value']);
  }, [currentPageMetaType]);

  /**
   * Задисэйблены ли контролы
   */
  const isDisabled = useCallback(() => {
    if (activeContextType === EType.MainPage) {
      return [ERequestStatus.Loading, ERequestStatus.Failed].includes(listingStatus) || !total;
    }

    return ERequestStatus.Loading === listingStatus;
  }, [activeContextType, listingStatus, total]);

  /**
   * Роутим на соответствующую страницу поиска
   */
  const handleClick = useCallback(
    (value: IDefaultContentTypeOption['value']) => {
      if (isDisabled()) {
        return;
      }

      let pathname = '/magazine/';
      const qsSearch = prepareQSParam(searchParams.get('search'));
      const qsTag = prepareQSParam(searchParams.get('tag'));
      const newSearchParams = new URLSearchParams();

      if (qsSearch) {
        newSearchParams.append('search', qsSearch);
      } else if (qsTag) {
        newSearchParams.append('tag', qsTag);
      }

      if (value === EType.News) {
        pathname = '/novosti/';
      } else if (value === EType.Articles) {
        pathname = '/stati/';
      } else if (value === EType.Blogs) {
        pathname = '/blogs/';
      } else if (value === EType.Questions) {
        pathname = '/forum-rieltorov/';
      }

      if (history.location.pathname === pathname) {
        return;
      }

      history.push(`${pathname}?${newSearchParams.toString()}`);
    },
    [history, isDisabled, searchParams],
  );

  return isPhone() ? (
    <Select
      value={activeContextType}
      title={'Фильтровать'}
      size={'M'}
      options={DEFAULT_CONTENT_TYPE_OPTIONS}
      onChange={handleClick}
      disabled={isDisabled()}
    />
  ) : (
    <div className={s['wrapper']}>
      {DEFAULT_CONTENT_TYPE_OPTIONS.map(({ label, value }, index) => (
        <div
          key={`content-type-${index}`}
          className={`${s['tab']} ${activeContextType === value ? s['_active'] : ''} ${
            isDisabled() ? s['_disabled'] : ''
          }`}
          onClick={() => handleClick(value)}
        >
          {label}
        </div>
      ))}
    </div>
  );
};

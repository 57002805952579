import { ERequestStatus } from '../../types/requestStatus';
import { TDiscussionsState } from '../../types/discussions';
import { EGetDiscussionsActionType, EResetDiscussionsActionType, TDiscussionsActions } from '../../actions/discussions';

export const defaultState: TDiscussionsState = {
  status: ERequestStatus.Initial,
  items: [],
};

export const discussionsReducer = (state = defaultState, action: TDiscussionsActions) => {
  switch (action.type) {
    case EGetDiscussionsActionType.Loading:
      return { ...state, status: ERequestStatus.Loading };

    case EGetDiscussionsActionType.Succeed:
      return { ...state, items: action.payload, status: ERequestStatus.Succeed };

    case EGetDiscussionsActionType.Failed:
      return { ...state, status: ERequestStatus.Failed };

    case EResetDiscussionsActionType.Reset:
      return defaultState;

    default:
      return state;
  }
};

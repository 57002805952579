/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TGetArticleMainModel,
  IGetArticleMainRequest,
  IMappers,
  IGetArticleMainResponse,
  IGetArticleMainResponseError,
  TGetArticleMainResponse,
} from './types';

const defaultConfig: TGetArticleMainModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'GET',
  microserviceName: 'journal',
  pathApi: '/v2/get-article-main/',
  hostType: 'api',
} as TGetArticleMainModel;

function createGetArticleMainModel(parameters: IGetArticleMainRequest): TGetArticleMainModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetArticleMainOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IGetArticleMainRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchGetArticleMain<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IGetArticleMainOptions<TResponse200, TResponse400>): Promise<TResponse200 | TResponse400 | TGetArticleMainResponse> {
  const { response, statusCode } = await httpApi.fetch(createGetArticleMainModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetArticleMainResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IGetArticleMainResponseError);
    }
  }

  return { response, statusCode } as TGetArticleMainResponse;
}

export { defaultConfig, createGetArticleMainModel, fetchGetArticleMain };

import { ITypedReduxAction } from '../../../types/redux/actionType';
import { ITagDataSchema } from '../../../repositories/journal/entities/tag/TagDataSchema';

export enum EGetTagsActionType {
  Loading = 'tags/getTags/loading',
  Succeed = 'tags/getTags/succeed',
  Failed = 'tags/getTags/failed',
  Reset = 'tags/getTags/reset',
}

export type TGetTagsLoading = ITypedReduxAction<EGetTagsActionType.Loading>;
export type TGetTagsSucceed = ITypedReduxAction<EGetTagsActionType.Succeed, ITagDataSchema[]>;
export type TGetTagsFailed = ITypedReduxAction<EGetTagsActionType.Failed>;
export type TGetTagsReset = ITypedReduxAction<EGetTagsActionType.Reset>;

export type TGetTags = TGetTagsLoading | TGetTagsSucceed | TGetTagsFailed | TGetTagsReset;

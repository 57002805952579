import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Button, useDeviceType } from '@cian/ui-kit';

import { PaginationNew } from '../../components/Pagination';
import { IPaginationItem } from '../../types/pagination';
import { correctPathname, preparePageNumber, useCurrentListing, useCurrentListingTotalPages } from '../../utils';
import { generatePagesData } from './utils';
import { setListingAppendType } from '../../actions/settings';
import { ERequestStatus } from '../../types/requestStatus';
import { ISettings } from '../../types/settings';
import * as s from './PaginationContainer.css';

/**
 * Строит список пагинации для листинга
 * и кнопку "Показать больше"
 */
export const PaginationContainer = () => {
  const deviceType = useDeviceType();
  const isPhone = () => deviceType === 'phone';
  const dispatch = useDispatch();
  const { search, pathname } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const [activeNumber, setActiveNumber] = useState(preparePageNumber(searchParams.get('page') || ''));
  const history = useHistory();
  const totalPages = useCurrentListingTotalPages();
  const { status: listingStatus } = useCurrentListing();

  /**
   * Слушает изменение page в qs
   * и обновляет номер активной страницы
   */
  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const qsPage = preparePageNumber(searchParams.get('page') || '');

    if (activeNumber === qsPage) {
      return;
    }

    setActiveNumber(qsPage);
  }, [activeNumber, search]);

  /**
   * Устанавливает номер активной страницы в адресную строку
   */
  const handleClick = useCallback(
    (number: IPaginationItem['number'], type: ISettings['listingAppendType'] = 'set') => {
      if (number === 1) {
        searchParams.delete('page');
      } else {
        searchParams.set('page', String(number));
      }

      history.push(`${correctPathname(pathname)}?${searchParams.toString()}`);

      /**
       * Запоминает в сторе тип обновления списка - догрузка/замена
       */
      dispatch(setListingAppendType(type));
    },
    [dispatch, history, pathname, searchParams],
  );

  if (totalPages <= 1 || totalPages < activeNumber) {
    return null;
  }

  return (
    <div className={s['wrapper']} data-web-ui-test-id="PaginationContainer">
      {activeNumber !== totalPages && (
        <Button
          theme={'fill_secondary'}
          fullWidth
          loading={listingStatus === ERequestStatus.Loading}
          onClick={() => handleClick(activeNumber + 1, 'append')}
        >
          Показать больше
        </Button>
      )}

      <PaginationNew
        pages={generatePagesData({
          maxShownPages: isPhone() ? 3 : 5,
          showFirstPage: isPhone(),
          activeNumber,
          pathname,
          searchParams,
          totalPages,
        })}
        activeNumber={activeNumber}
        onClick={number => handleClick(number, 'set')}
      />
    </div>
  );
};

import { ERequestStatus } from '../../types/requestStatus';
import {
  IPostContentRecommendedOffers,
  TPostContentRecommendedOffersState,
} from '../../types/postContentRecommendedOffers';

/** Создает пустую структуру рекомендованных оферов */
export const createPostContentRecommendedOffers = (
  id: IPostContentRecommendedOffers['id'],
): TPostContentRecommendedOffersState => ({
  id,
  title: undefined,
  items: [],
  url: undefined,
  status: ERequestStatus.Initial,
  position: 'top',
});

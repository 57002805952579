import * as React from 'react';
import { IIconProps } from '@cian/ui-kit/icons/internal/types';

export const IconDocument24 = ({ color }: IIconProps) => {
  const currentColor = color ? `var(--${color})` : 'currentColor';

  return (
    // eslint-disable-next-line react/forbid-elements
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 1.5H2.5V2V22V22.5H3H21H21.5V22V9V8.79289L21.3536 8.64645L14.3536 1.64645L14.2071 1.5H14H3ZM3.5 21.5V2.5H13.5V9V9.5H14H20.5V21.5H3.5ZM19.7929 8.5L14.5 3.20711V8.5H19.7929ZM18 18.5H6V17.5H18V18.5ZM6 15.5H14V14.5H6V15.5Z"
        fill={currentColor}
      />
    </svg>
  );
};

import * as React from 'react';
import { connect } from 'react-redux';

import { IUser } from '../../types/user';
import { LikesContent as LikesContentComponent } from '../../components/likes_content/likes_content';
import { loadLikeInfo, submitVote } from '../../reducers/modules/likes_content/likes_content';
import { TDispatch } from "../../types/redux";

interface IProps {
  disabled: boolean;
  type: string;
  objectID: string;
  user?: IUser;
  dispatch: TDispatch;
}

interface IContainerProps {
  disabled: boolean;
  type: string;
  objectID: string;
}

interface IState {
  likes: number;
  dislikes: number;
  userLike: number;
}

class LikesContentContainer extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);

    this.state = {
      likes: 0,
      dislikes: 0,
      userLike: 0,
    };
  }

  public componentWillMount() {
    this.props.dispatch(loadLikeInfo(this.props.type, this.props.objectID, (data) => {
      this.setState({
        likes: data.likesCount,
        dislikes: data.dislikesCount,
        userLike: data.userLike,
      });
    }, () => {
      this.setState({
        likes: 0,
        dislikes: 0,
        userLike: 0,
      });
    }) as any);
  }

  public render() {
    return (
      <LikesContentComponent
        disabled={this.props.disabled}
        likesCount={this.state.likes}
        dislikesCount={this.state.dislikes}
        onLikePressed={this.handleLikePressed}
        onDislikePressed={this.handleDislikePressed}
        type={this.props.type}
        userLike={this.state.userLike}
        user={this.props.user} />
    );
  }

  private handleLikePressed = () => {
    this.submitLike();
  }

  private handleDislikePressed = () => {
    this.submitDislike();
  }

  private submitLike() {
    this.props.dispatch(submitVote(this.props.type, this.props.objectID, 1, (likes, dislikes) => {
      this.setState({
        likes: likes,
        dislikes: dislikes,
        userLike: 1,
      });
    }, () => {}) as any);
  }

  private submitDislike() {
    this.props.dispatch(submitVote(this.props.type, this.props.objectID, -1, (likes, dislikes) => {
      this.setState({
        likes: likes,
        dislikes: dislikes,
        userLike: -1,
      });
    }, () => {}) as any);
  }
}

export const LikesContent = connect<IContainerProps, {}, IContainerProps>(
  (state: {
    user: IUser,
  }, props: IContainerProps) => ({
    user: state.user,
    disabled: props.disabled,
    type: props.type,
    objectID: props.objectID
  }),
)(LikesContentContainer);

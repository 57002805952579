import * as React from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

import { IBreadItem } from '../../types/bread_crumb';
import { IPostCard } from '../../types/post_card';
import { PostCard } from '../../components/post_card/post_card';
import { setBreadCrumb } from '../../reducers/modules/bread_crumbs/bread_crumbs';
import { IApplicationState, IThunkContext, TReduxActions } from '../../types/redux';

interface IProps {
  postPreview: IPostCard;
}

interface IDispatchProps {
  setBreadCrumb(path: IBreadItem[]): void;
}

class PostPreviewContainer extends React.Component<IProps & IDispatchProps, {}> {
  public componentDidMount() {
    const crumbs: IBreadItem[] = [{ path: '/magazine' }];
    const pathnames = new Map([
      ['news', '/novosti'],
      ['articles', '/stati'],
    ]);
    const {
      type,
      attributes: { category },
    } = this.props.postPreview.item;
    const pathType = pathnames.get(type) || '';

    if (pathType) {
      crumbs.push({ path: pathType });
    }

    if (category) {
      crumbs.push({ path: category });
    }

    this.props.setBreadCrumb(crumbs);
  }

  public render() {
    return (
      <PostCard
        user={{
          isProfi: false,
          isBlock: false,
          linkUserProfiDescription: '',
          userIdentifications: false,
          userId: -1,
          email: '',
          userTrust: false,
          fullName: '',
          linkUserTrustDescription: '',
          avatar: '',
          permissions: {
            canModerateAnnouncements: false,
            canModerateAnnouncementsExpert: false,
            canModerateUsers: false,
            canViewAnnouncements: false,
            canViewUsers: false,
          },
          roles: [''],
        }}
        menuItems={[]}
        postCard={this.props.postPreview}
        preparedContent={this.props.postPreview.item.attributes.content}
        commentsState={{
          loaded: false,
        }}
        onDigestSubmit={() => new Promise(() => {})}
        onDigestReset={() => {}}
        isSubscribed={false}
        isUnsubscribed={false}
        error=""
        commentsCount={0}
        onScrollComments={() => {}}
        onScrollCommentForm={() => {}}
        scrollCommentState={{ isScrollForm: false, isScrollComments: false }}
        scrollCommentDisabled={() => {}}
        onHide={() => {}}
        onPublish={() => {}}
        onEdit={() => {}}
      />
    );
  }
}

function mapStateToProps(state: IApplicationState): IProps {
  const { postPreview } = state;

  return {
    postPreview,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<IApplicationState, IThunkContext, TReduxActions>): IDispatchProps {
  return {
    setBreadCrumb: (path: IBreadItem[]) => {
      dispatch(setBreadCrumb(path));
    },
  };
}

export const PostPreview = connect(mapStateToProps, mapDispatchToProps)(PostPreviewContainer);

import { EGetPostActionType, EResetPostActionType, TPostActions } from '../../actions/post';
import { TPostState } from '../../types/post';
import { ERequestStatus } from '../../types/requestStatus';

export const defaultState: TPostState = {
  status: ERequestStatus.Initial,
};

export const postReducer = (state = defaultState, action: TPostActions) => {
  switch (action.type) {
    case EGetPostActionType.Loading:
      return { ...state, status: ERequestStatus.Loading };

    case EGetPostActionType.Succeed:
      return { ...state, ...action.payload, status: ERequestStatus.Succeed };

    case EGetPostActionType.Failed:
      return { ...state, status: ERequestStatus.Failed };

    case EResetPostActionType.Reset:
      return defaultState;

    default:
      return state;
  }
};

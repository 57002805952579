import { ISimilarJournalItem } from '../../../types/posts';
import {
  IReadTopic,
  IReadTopicAction,
} from '../../../types/read_topic';

export const READ_TOPIC = 'read_topic/ACTION';

export const initialState: IReadTopic = {
  show: false,
  items: [],
};

const defaultAction: IReadTopicAction = {
  type: '',
  payload: initialState,
};

export const readTopicReducer = (state = initialState, action = defaultAction) => {
  switch (action.type) {
    case READ_TOPIC:
      return Object.assign({}, state, action.payload);
    default:
      return state;
  }
};

export const showReadTopic = (status: boolean) => {
  return {
    type: READ_TOPIC,
    payload: {
      show: status,
    },
  };
};

export const setSimilarArticles = (items: ISimilarJournalItem[]) => {
  return {
    type: READ_TOPIC,
    payload: {
      items,
    },
  };
};

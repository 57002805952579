import { ITypedReduxAction } from '../../../types/redux/actionType';
import { IQuestionList } from '../../../types/questionList';

export enum EGetQuestionListActionType {
  Loading = 'questionList/getQuestionList/loading',
  Succeed = 'questionList/getQuestionList/succeed',
  AppendSucceed = 'questionList/getQuestionList/appendSucceed',
  Failed = 'questionList/getQuestionList/failed',
}

export type TGetQuestionListLoading = ITypedReduxAction<EGetQuestionListActionType.Loading>;
export type TGetQuestionListSucceed = ITypedReduxAction<EGetQuestionListActionType.Succeed, IQuestionList>;
export type TGetQuestionListAppendSucceed = ITypedReduxAction<EGetQuestionListActionType.AppendSucceed, IQuestionList>;
export type TGetQuestionListFailed = ITypedReduxAction<EGetQuestionListActionType.Failed>;

export type TGetQuestionList =
  | TGetQuestionListLoading
  | TGetQuestionListSucceed
  | TGetQuestionListAppendSucceed
  | TGetQuestionListFailed;

import * as React from 'react';
import { IUser } from '../../types/user';
import { IListItemProps } from '../../types/list_item_props';
import { ListSubscribe } from '../../containers/subscribe/list_subscribe';
import { ListItemContainer } from '../../containers/list_item';
import { showDemandBanner } from './utils/show_demand_banner';
import { DemandBanner } from '../realtor_demand/demand_banner';

import s from './list_content.css';

export interface IProps {
  currentUser: IUser;
  regionId?: string;
  items: any[];
  saveScroll?(item: IListItemProps): void;
  selectedItem?: IListItemProps;
  containerStyle?: any;
  listItemClass?: React.ComponentType<any>;
  pathname?: string;
  scrollComments(): void;
  search?: string;
  searchTag?: string;
}

export const ListContent = (props: IProps) => {
  const items = [...props.items];
  const subscribePos = 4;

  if (
    items.length > 0 &&
    (items[0].type === 'Blog' || items[0].type === 'Question') &&
    !props.search &&
    !props.searchTag
  ) {
    items.splice(subscribePos, 0, { type: 'sub' });
  }

  return (
    <ul className={`${s['list-content']} ${props.containerStyle}`}>
      {items.map((item, i) => {
        if (item.type === 'sub') {
          return <ListSubscribe key={`item-${item.id}-${i}`} listType={items[0].type} />;
        }

        return [
          <li className={`${s['list-content__item']} cg-col-xs-32`} key={`item-${item.id}-${i}`}>
            {props.listItemClass ? (
              React.createElement(props.listItemClass, {
                regionId: props.regionId,
                item,
                saveScroll: props.saveScroll,
                selectedItem: props.selectedItem,
                currentUser: props.currentUser,
                scrollComments: props.scrollComments,
              })
            ) : (
              <ListItemContainer
                item={item}
                saveScroll={props.saveScroll}
                selectedItem={props.selectedItem}
                scrollComments={props.scrollComments}
              />
            )}
          </li>,
          showDemandBanner(i, props.pathname) && (
            <div className={s['list-content__banner']}>
              <DemandBanner listView />
            </div>
          ),
        ];
      })}
    </ul>
  );
};

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDeviceType, IconButton, Sort16, Radio } from '@cian/ui-kit';
import { useHistory, useLocation } from 'react-router-dom';
import { ERequestStatus } from '../../../types/requestStatus';
import { Curtain } from '../../../components/Curtain';
import { ModalHeader } from '../../../components/ModalHeader';
import { EType } from '../../../repositories/journal/entities/page_info/PageInfoSchema';
import { EOrdering } from '../../../repositories/journal/v2/get-article-list';
import { prepareListingOrdering, useCurrentListing } from '../../../utils';
import { useSelector } from 'react-redux';
import { IApplicationState } from '../../../types/redux';
import { selectCurrentPageMeta } from '../../../selectors/pagesMeta';
import { BLOGS_ORDERING_OPTIONS, DEFAULT_ORDERING_OPTIONS } from './constants';
import * as s from './OrderingButtonsContainer.css';

/**
 * Кнопки сортировки для десктопа
 * Модалка сортировки для планшета или мобилки
 */
export const OrderingButtonsContainer = () => {
  const deviceType = useDeviceType();
  const isDesktop = () => deviceType === 'desktop';
  const { pathname, search } = useLocation();
  const { pathname: currentPageMetaPathname, type } = useSelector((state: IApplicationState) =>
    selectCurrentPageMeta(state, pathname),
  );
  const {
    status: listingStatus,
    meta: { total },
  } = useCurrentListing();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const [activeOrdering, setActiveOrdering] = useState<EOrdering>(EOrdering.Date_publish);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const history = useHistory();
  const orderingOptions = useMemo(
    () => (EType.Blogs === type ? BLOGS_ORDERING_OPTIONS : DEFAULT_ORDERING_OPTIONS),
    [type],
  );

  /**
   * Сохраняет состояние сортировки в стэйт
   */
  useEffect(() => {
    const qsOrdering = searchParams.get('ordering');
    const qsOrderingPrepared = qsOrdering ? prepareListingOrdering(qsOrdering) : '';

    if (
      !qsOrderingPrepared ||
      activeOrdering === qsOrderingPrepared ||
      !orderingOptions.find(orderingOption => orderingOption.name === qsOrderingPrepared)
    ) {
      return;
    }

    setActiveOrdering(qsOrderingPrepared);
  }, [activeOrdering, orderingOptions, searchParams]);

  /**
   * Закрывает модалку при смене вьюпорта на десктоп
   */
  useEffect(() => {
    if (!isModalOpened || deviceType !== 'desktop') {
      return;
    }

    setIsModalOpened(false);
  }, [deviceType, isModalOpened]);

  /**
   * Устанавливает сортировку
   */
  const handleOrderingClick = useCallback(
    (orderingName: EOrdering) => {
      searchParams.set('ordering', orderingName);
      searchParams.delete('page');
      history.push(`${currentPageMetaPathname}?${searchParams.toString()}`);
    },
    [currentPageMetaPathname, history, searchParams],
  );

  /**
   * Устанавливает сортировку и закрывает модалку с паузой
   */
  const handleModalOrderingClick = useCallback(
    (e: React.MouseEvent, orderingName: EOrdering) => {
      e.preventDefault();
      handleOrderingClick(orderingName);
      setIsModalOpened(false);
    },
    [handleOrderingClick],
  );

  /** Переключает видимость модалки */
  const handleToggleModal = useCallback(() => {
    setIsModalOpened(isModalOpened => !isModalOpened);
  }, []);

  /**
   * Задисэйблены ли контролы
   */
  const isDisabled = useCallback(() => {
    return [ERequestStatus.Loading, ERequestStatus.Failed].includes(listingStatus) || !total;
  }, [listingStatus, total]);

  return (
    <>
      <div className={s['wrapper']}>
        {/* Кнопка, вызывающая модалку с сортировкой */}
        {!isDesktop() && (
          <div className={`${s['modal-button-wrapper']} ${isModalOpened ? s['_active'] : ''}`}>
            <IconButton icon={<Sort16 />} onClick={handleToggleModal} disabled={isDisabled()} />
          </div>
        )}

        {/* Кнопки сортировки */}
        {isDesktop() &&
          orderingOptions.map(({ name, title }, index) => (
            <div key={index} className={`${s['ordering-button']} ${activeOrdering === name ? s['_active'] : ''}`}>
              <IconButton
                icon={<span>{title}</span>}
                onClick={() => handleOrderingClick(name)}
                disabled={isDisabled()}
              />
            </div>
          ))}
      </div>

      {/* Шторка с сортировкой */}
      {!isDesktop() && (
        <Curtain isOpen={isModalOpened && !isDisabled()} onClose={handleToggleModal}>
          <>
            <ModalHeader onClose={handleToggleModal}>Сортировать</ModalHeader>
            <div className={s['modal-content']}>
              {orderingOptions.map(({ name, title }, index) => (
                <div
                  key={index}
                  className={`${s['modal-ordering-button']} ${activeOrdering === name ? s['_active'] : ''}`}
                  onClick={e => handleModalOrderingClick(e, name)}
                >
                  <span>{title}</span>
                  <Radio name={'ordering'} value={name} checked={activeOrdering === name} />
                </div>
              ))}
            </div>
          </>
        </Curtain>
      )}
    </>
  );
};

import { DependencyList, EffectCallback, useEffect, useRef } from 'react';
import { useDeviceType } from '@cian/ui-kit';

import { useIsMounted } from '../useIsMounted';

/**
 * Выполняет useEffect с задержкой
 */
export const useTimeoutEffect = (effect: EffectCallback, [time, deviceTypesOnly]: DependencyList) => {
  const deviceType = useDeviceType();
  const timerRef = useRef<ReturnType<typeof setTimeout>>();
  const isMounted = useIsMounted();

  useEffect(() => {
    if (deviceTypesOnly?.length && !deviceTypesOnly.includes(deviceType)) {
      return;
    }

    clearTimeout(timerRef.current);

    timerRef.current = setTimeout(() => {
      if (!isMounted()) {
        return;
      }

      return effect();
    }, time);
  }, [deviceType, deviceTypesOnly, effect, isMounted, time]);
};

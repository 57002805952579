import { isMobile } from './responsive_helper';

export function requestAuthentication(anchorHash?: string) {
  if (isMobile()) {
    const redirectUrl = anchorHash ? new URL(`#${anchorHash}`, window.location.href).href : window.location.href;
    window.location.href = `/authenticate/?redirect_url=${encodeURIComponent(redirectUrl)}`;
  } else {
    // tslint:disable-next-line:no-any
    const waitForLoginPopup = (window as any).waitForLoginPopup;
    if (waitForLoginPopup) {
      if (anchorHash) {
        window.history.pushState({}, window.document.title, new URL(`#${anchorHash}`, window.location.href).href);
      }

      waitForLoginPopup();
    }
  }
}

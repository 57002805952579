import { ca } from '@cian/analytics';
import { transformUser } from './transformUser';
import { IUserGaData } from '../../../types/analytics';
import { ITransformPageParams, transformPage } from './transformPage';
import { ITransformProductParams, transformProduct } from './transformProduct';
import { ITransformCustomUrl, transformCustomUrl } from './transformCustomUrl';

export interface ITrackRecommendedOfferClickParams
  extends ITransformPageParams,
    ITransformProductParams,
    ITransformCustomUrl {
  userGa: IUserGaData;
}

/**
 * Трекинг клика по рекомендованному оферу
 */
export const trackRecommendedOfferClick = ({
  userGa,
  breadCrumbs,
  deviceType,
  extra,
  realtyId,
  url,
  isFromBuilder,
}: ITrackRecommendedOfferClickParams) => {
  ca('event', {
    name: 'oldevent',
    category: 'Magazine',
    action: 'to_card_bottom_block',
    label: transformCustomUrl({ url, isFromBuilder }),
    pageType: 'Magazine',
    user: transformUser(userGa),
    page: transformPage({ breadCrumbs, deviceType, extra }),
    products: transformProduct({ realtyId }),
  });
};

/**
 * Рекурсивный поиск по pathname
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const findByPathRecursive = <T extends { [key: string]: any }>(items: T[], pathname: string): T | undefined =>
  items.find(item => {
    if (item?.pathname === pathname) {
      return item;
    }

    if (item?.items?.length) {
      return findByPathRecursive(item.items, pathname);
    }

    return false;
  });

import React from 'react';
import { UIHeading4, UIText2 } from '@cian/ui-kit';
import { plural } from '@cian/utils';

import { numberWithDelimiter } from '../../utils';
import * as s from './QuickSearchingItem.css';

interface IQuickSearchingItemTagParams {
  name: string;
  count: number;
  onClick?(value?: string): void;
}

/** Элемент списка быстрого поиска (тэг) */
export const QuickSearchingTagItem = ({ name, count, onClick }: IQuickSearchingItemTagParams) => {
  return (
    <div className={`${s['wrapper']} ${s['tag']}`} onClick={() => (onClick ? onClick(name) : {})}>
      <div className={s['inner-wrapper']}>
        <div className={s['title']}>
          <UIHeading4>#{name}</UIHeading4>
        </div>

        <div className={s['description']}>
          <UIText2>
            {numberWithDelimiter(count)} {plural(count, ['материал', 'материала', 'материалов'])}
          </UIText2>
        </div>
      </div>
    </div>
  );
};

import * as React from 'react';
import { Link } from 'react-router-dom';

import s from './page_blocked.css';

interface IProps {
  content?: JSX.Element;
}

export const PageBlocked = (props: IProps) => {

  if (props.content) {
    return props.content;
  }

  return <div className={s['list-blocked']}>
    <span className={s['list-blocked_title']}>Секретный раздел!</span>
    <span className={s['list-blocked_text']}>В этом разделе обсуждение ведут специалисты с рейтингом больше 50.<br />
      {/* tslint:disable-next-line:max-line-length */}
      Хотите сюда попасть? Публикуйте объявления, отвечайте на <Link to="/forum-rieltorov" className={s['list-blocked_link']}>вопросы</Link>, заведите интересный блог.</span>
    <Link to="/reyting-rieltorov" className={s['list-blocked_link']}>Правила начисления рейтинга</Link>
  </div>;
};

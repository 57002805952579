import { useCallback, useEffect, useState } from 'react';

import { debounce } from '../debounce';
import { useEventListener } from '../useEventListener';

/**
 * Вычисляет тенденцию скрола window (вверх или вниз)
 */
export const useWindowScrollTrend = (delay: number = 30) => {
  const [trend, setTrend] = useState<'top' | 'bottom' | null>(null);
  const [y, setY] = useState<number>(0);

  useEffect(() => {
    setY(window.scrollY);
  }, []);

  /** Обрабатывает скрол и вычисляет тенденцию */
  const handleScroll = useCallback(() => {
    setTrend(window.scrollY > y ? 'bottom' : window.scrollY < y ? 'top' : null);
    setY(window.scrollY);
  }, [y]);

  /** Обрабатывает скрол с задержкой */
  const handleScrollDebounced = debounce(handleScroll, delay);

  useEventListener('scroll', handleScrollDebounced);

  return trend;
};

import { ISearch, ISearchAction } from '../../../types/journal';

export const SUGGESTIONS = 'search/ACTION';
export const SUGGESTIONS_REQUEST = 'search/ACTION_REQUEST';
export const SUGGESTIONS_SUCCESS = 'search/ACTION_SUCCESS';
export const SUGGESTIONS_FAILURE = 'search/ACTION_FAILURE';
export const TOOGLE_SEARCH = 'digest/TOGGLE_SEARCH';

export const initialState: ISearch = {
  items: [],
  isEmpty: false,
};

const defaultAction: ISearchAction = {
  type: '',
  payload: {
    data: [],
  },
};

export const searchReducer = (state = initialState, action = defaultAction) => {
  switch (action.type) {
    case SUGGESTIONS_REQUEST:
      return Object.assign({}, state, { isEmpty: false });

    case SUGGESTIONS_SUCCESS:
      return Object.assign({}, state, {
        items: action.payload.data || [],
        isEmpty: !!action.payload.data,
      });

    case SUGGESTIONS_FAILURE:
      return Object.assign({}, state, { isEmpty: false });

    case TOOGLE_SEARCH:
      return Object.assign({}, initialState);

    default:
      return state;
  }
};

export const performSearch = (value: string, regionId: number) => {
  return {
    type: SUGGESTIONS,
    method: 'GET',
    payload: {
      pathname: '/v1/search',
      params: [
        {
          name: 'q',
          value: encodeURIComponent(value),
        },
        {
          name: 'region_id',
          value: regionId,
        },
      ],
    },
    request: true,
  };
};

import { PostTypePostCardUrlPrefixes } from '../../constants';

/**
 * Парсит урл карточки поста
 */
export const parsePostCardPath = (path: string) => {
  const matches = path.match(
    new RegExp(`^/(${Object.values(PostTypePostCardUrlPrefixes).join('|')})-([^/]+)-([0-9]+)/?$`),
  );

  return { type: matches?.[1], slug: matches?.[2], id: parseInt(matches?.[3] || '0', 10) };
};

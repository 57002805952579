import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';

import { correctPathname } from '../../../../../utils';
import * as s from './Rubric.css';

export interface IRubric {
  name: string;
  url?: string;
  backgroundColor?: string;
  onClick?(e: React.MouseEvent): void;
}

/**
 * Кнопка рубрики
 */
export const Rubric = ({ name, url, backgroundColor, onClick }: IRubric) => {
  /** Отправляет клик родителю */
  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();

      if (onClick) {
        onClick(e);
      }
    },
    [onClick],
  );

  return (
    <div className={s['wrapper']}>
      {url ? (
        <Link
          to={correctPathname(url)}
          className={s['rubric']}
          title={`Перейти в рубрику ${name}`}
          style={backgroundColor ? { backgroundColor } : undefined}
          onClick={handleClick}
        >
          {name}
        </Link>
      ) : (
        <span className={s['rubric']} style={backgroundColor ? { backgroundColor } : undefined}>
          {name}
        </span>
      )}
    </div>
  );
};

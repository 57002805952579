import { HttpBadStatusCodeError } from '@cian/peperrors/shared';

import { TThunkAction } from '../../../types/redux';
import { getDocumentGroupListLoading } from './getDocumentGroupListLoading';
import { getDocumentGroupListSucceed } from './getDocumentGroupListSucceed';
import { getDocumentGroupListFailed } from './getDocumentGroupListFailed';
import { fetchGetDocumentList, TGetDocumentListResponse } from '../../../repositories/journal/v1/get-document-list';
import { mapDocumentGroup } from '../../../utils';

export const getDocumentGroupList = (): TThunkAction<Promise<void>> => {
  return async (dispatch, getState, context) => {
    const { httpApi, logger } = context;

    dispatch(getDocumentGroupListLoading());

    try {
      const { response, statusCode }: TGetDocumentListResponse = await fetchGetDocumentList({
        httpApi,
        parameters: {},
      });

      if (statusCode !== 200 || !response?.data) {
        throw new HttpBadStatusCodeError(
          'Не удалось загрузить список групп документов',
          statusCode,
          'actions.getDocumentGroupList',
        );
      }

      dispatch(getDocumentGroupListSucceed(response.data.map(group => mapDocumentGroup(group))));
    } catch (e) {
      logger.error(e, {
        domain: 'actions.getDocumentGroupList',
      });

      dispatch(getDocumentGroupListFailed());
    }
  };
};

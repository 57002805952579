/* eslint-disable */

//tslint:disable

import { IModel, IModelResponse } from '@cian/http-api/shared/model';
import { IArticlesNewsListDataV2Schema } from '../../entities/journal/ArticlesNewsListDataV2Schema';
import { IJournalNewsMetaV2Schema } from '../../entities/journal/JournalNewsMetaV2Schema';

export type TGetNewsListModel = IModel<IGetNewsListRequest, TGetNewsListResponse>;

export interface IGetNewsListRequest {
  /** Аудитория **/
  b2b?: boolean | null;
  /** Название категории **/
  category?: string | null;
  /** До даты **/
  endDate?: string | null;
  /** Лимит **/
  limit?: number | null;
  /** Сдвиг **/
  offset?: number | null;
  /** Сортировка **/
  ordering?: EOrdering | null;
  /** ID региона **/
  regionId?: number | null;
  /** Название тега **/
  tag?: string | null;
}

export type TGetNewsListResponse = IGetNewsListResponse200 | IGetNewsListResponse400;

export interface IGetNewsListResponse200 extends IModelResponse<IGetNewsListResponse> {
  statusCode: 200;
}

export interface IGetNewsListResponse400 extends IModelResponse<IGetNewsListResponseError> {
  statusCode: 400;
}

export interface IGetNewsListResponse {
  /** Данные **/
  data: IArticlesNewsListDataV2Schema[];
  /** Мета данные **/
  meta: IJournalNewsMetaV2Schema;
}

export interface IGetNewsListResponseError {
  errors: any[];
  message: string;
}

export type TResponses = IGetNewsListResponse | IGetNewsListResponseError;

export enum EOrdering {
  /** Обсуждаемое **/
  Comments_count = 'comments_count',
  /** Дата публикации **/
  Date_publish = 'date_publish',
  /** Понравившееся **/
  Likes_count = 'likes_count',
  /** Популярное **/
  Num_views = 'num_views',
}

export interface IMappers<TResponse200, TResponse400> {
  200(response: IGetNewsListResponse): TResponse200;
  400(response: IGetNewsListResponseError): TResponse400;
}

import { ISelectedPosts, ISelectedPostsAction } from '../../../types/selected_posts';
import { IGetRequest } from '../../../types/get_request';

export const SELECTED_POSTS = 'selected_posts/ACTION';
export const SELECTED_POSTS_REQUEST = 'selected_posts/ACTION_REQUEST';
export const SELECTED_POSTS_SUCCESS = 'selected_posts/ACTION_SUCCESS';
export const SELECTED_POSTS_FAILURE = 'selected_posts/ACTION_FAILURE';

export const initialState: ISelectedPosts = {
  items: [],
};

const defaultAction: ISelectedPostsAction = {
  type: '',
  payload: {
    data: [],
  },
};

export const selectedPostsReducer = (state = initialState, action = defaultAction) => {
  switch (action.type) {
    case SELECTED_POSTS_REQUEST:
      return state;

    case SELECTED_POSTS_SUCCESS:
      return Object.assign({}, state, {
        items: action.payload.data,
      });

    case SELECTED_POSTS_FAILURE:
      return Object.assign({}, state);

    default:
      return state;
  }
};

export const getSelectedPosts = (regionID: string): IGetRequest => {
  return {
    type: SELECTED_POSTS,
    method: 'GET',
    payload: {
      pathname: '/v2/get-featured-journal-list',
      params: [{
        name: 'region_id',
        value: regionID,
      }],
    },
    request: true,
  };
};

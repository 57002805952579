import { IPosts, IPostsAction } from '../../../types/posts';
import { IGetRequest } from '../../../types/get_request';
import { options } from '../seo/seo';
import { staticMetaPropsArray } from './config';
import { choiceOfSeo } from '../../../../app/helpers/choice_of_seo_helper';

export const QUESTIONS = 'questions/ACTION';
export const QUESTIONS_REQUEST = 'questions/ACTION_REQUEST';
export const QUESTIONS_SUCCESS = 'questions/ACTION_SUCCESS';
export const QUESTIONS_FAILURE = 'questions/ACTION_FAILURE';
export const ADVICES_USER = 'advices_user/ACTION';
export const ADVICES_USER_REQUEST = 'advices_user/ACTION_REQUEST';
export const ADVICES_USER_SUCCESS = 'advices_user/ACTION_SUCCESS';
export const ADVICES_USER_FAILURE = 'advices_user/ACTION_FAILURE';
export const SET_CATEGORY = 'questions/SET_CATEGORY';

export const initialState: IPosts = {
  items: [],
  pages_count: 0,
  count: 0,
  user: {
    isProfi: false,
    isBlock: false,
    linkUserProfiDescription: '',
    userIdentifications: false,
    userId: -1,
    email: '',
    userTrust: false,
    fullName: '',
    linkUserTrustDescription: '',
    avatar: '',
    permissions: {
      canModerateAnnouncements: false,
      canModerateAnnouncementsExpert: false,
      canModerateUsers: false,
      canViewAnnouncements: false,
      canViewUsers: false,
    },
    roles: [],
  },
  currentCategory: '',
};

const defaultAction: IPostsAction = {
  type: '',
  payload: {
    data: [],
    meta: {
      pagination: {
        page: 0,
        pages: 0,
        count: 0,
      },
    },
  },
};

export const questionsReducer = (state = initialState, action = defaultAction) => {
  switch (action.type) {
    case QUESTIONS_REQUEST:
      return state;

    case QUESTIONS_SUCCESS:
      return Object.assign({}, state, {
        items: action.payload.data,
        pages_count: action.payload.meta.pagination.pages,
        count: action.payload.meta.pagination.count,
      });

    case QUESTIONS_FAILURE:
      return Object.assign({}, state);

    case ADVICES_USER_REQUEST:
      return Object.assign({}, state, { user: initialState.user });

    case ADVICES_USER_SUCCESS:
      return Object.assign({}, state, { user: action.payload.data});

    case ADVICES_USER_FAILURE:
      return Object.assign({}, state, { user: initialState.user });

    case SET_CATEGORY:
      return Object.assign({}, state, { currentCategory: action.payload.category });

    default:
      return state;
  }
};

export const getQuestions = (
    params: {
      page: number,
      ordering: string,
      tag: string,
      category: string,
      authorID: string,
      commentUserID: string,
      regionID: string,
      search: string,
    }): IGetRequest => {
  return {
    type: QUESTIONS,
    method: 'GET',
    payload: {
      pathname: '/v1/get-question-list',
      params: [{
        name: 'page',
        value: params.page,
      }, {
        name: 'ordering',
        value: params.ordering,
      }, {
        name: 'category',
        value: params.category,
      }, {
        name: 'tag',
        value: encodeURIComponent(params.tag),
      }, {
        name: 'author_id',
        value: params.authorID,
      }, {
        name: 'comment_user_id',
        value: params.commentUserID,
      }, {
        name: 'region_id',
        value: params.regionID,
      }, {
        name: 'q',
        value: encodeURIComponent(params.search),
      }],
    },
    request: true,
    ...options,
    staticMetaProps: choiceOfSeo(staticMetaPropsArray, params.category),
  };
};

export const getAdvicesUser = (id: string) => {
  return {
    type: ADVICES_USER,
    method: 'GET',
    payload: {
      pathname: `/v1/get-user`,
      params: [{
        name: 'id',
        value: id,
      }],
    },
    request: true,
  };
};

export const setCategory = (category: string) => {
  return {
    type: SET_CATEGORY,
    payload: { category },
  };
};

import { ITypedReduxAction } from '../../../types/redux/actionType';
import { ISlider, ISliderNameAndAudienceType } from '../../../types/slider';

export enum EGetSliderActionType {
  Loading = 'slider/getSlider/loading',
  Succeed = 'slider/getSlider/succeed',
  Failed = 'slider/getSlider/failed',
}

export type TGetSliderLoading = ITypedReduxAction<EGetSliderActionType.Loading, ISliderNameAndAudienceType>;
export type TGetSliderSucceed = ITypedReduxAction<EGetSliderActionType.Succeed, ISlider>;
export type TGetSliderFailed = ITypedReduxAction<EGetSliderActionType.Failed, ISliderNameAndAudienceType>;

export type TGetSlider = TGetSliderLoading | TGetSliderSucceed | TGetSliderFailed;

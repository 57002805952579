/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TQuickSearchModel,
  IQuickSearchRequest,
  IMappers,
  IQuickSearchResponse,
  IQuickSearchResponseError,
  TQuickSearchResponse,
} from './types';

const defaultConfig: TQuickSearchModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'GET',
  microserviceName: 'journal',
  pathApi: '/v1/quick-search/',
  hostType: 'api',
} as TQuickSearchModel;

function createQuickSearchModel(parameters: IQuickSearchRequest): TQuickSearchModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IQuickSearchOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IQuickSearchRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchQuickSearch<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IQuickSearchOptions<TResponse200, TResponse400>): Promise<TResponse200 | TResponse400 | TQuickSearchResponse> {
  const { response, statusCode } = await httpApi.fetch(createQuickSearchModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IQuickSearchResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IQuickSearchResponseError);
    }
  }

  return { response, statusCode } as TQuickSearchResponse;
}

export { defaultConfig, createQuickSearchModel, fetchQuickSearch };

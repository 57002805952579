import React, { useState, useLayoutEffect } from 'react';
import { createPortal } from 'react-dom';

import { createWrapperAndAppendToDOM } from '../../utils/createWrapperAndAppendToDOM';

interface IReactPortalParams {
  wrapperId: string;
  children: React.ReactNode;
  parent?: string;
}

/**
 * Создает портал в body
 */
export const ReactPortal = ({ children, wrapperId = 'react-portal-wrapper', parent = 'body' }: IReactPortalParams) => {
  const [wrapperElement, setWrapperElement] = useState<HTMLElement | null>(null);

  useLayoutEffect(() => {
    let element = document.getElementById(wrapperId);
    let systemCreated = false;

    if (!element) {
      systemCreated = true;
      element = createWrapperAndAppendToDOM(wrapperId, parent);
    }
    setWrapperElement(element);

    return () => {
      if (element && systemCreated && element.parentNode) {
        element.parentNode.removeChild(element);
      }
    };
  }, [parent, wrapperId]);

  if (wrapperElement === null) {
    return null;
  }

  return createPortal(children, wrapperElement);
};

import * as React from 'react';
import { Outside } from '@cian/ui-kit/services/Outside';
import { Button, Input, useDeviceType } from '@cian/ui-kit';
import { UIHeading3 } from '@cian/ui-kit/typography';

import { IRegionsItem } from '../../../../types/regions';
import { GeoSuggest } from '../GeoSuggest';
import * as styles from './Header.css';

export interface IHeader {
  /** Поле ввода названия региона */
  inputValue: string;
  /** Коллекция регионов для выпадашки */
  suggests: IRegionsItem[];
  /** Загрузились ли регионы */
  loading: boolean;
  /** Эвент события изменения инпута ввода региона  */
  onChangeInputValue(value: string): void;
  /** Эвент события выбора региона из предложенных */
  onSelectRegion(region: IRegionsItem): void;
  /** Эвент события установки нового региона */
  onSubmitRegion(): void;
}

export const Header: React.FC<IHeader> = ({
  inputValue,
  suggests,
  loading,
  onChangeInputValue,
  onSubmitRegion,
  onSelectRegion,
}) => {
  const [visibleSuggest, setVisibleSuggest] = React.useState<boolean>(false);
  const deviceType = useDeviceType();

  /**
   * Раскрывает выпадашку
   */
  const openSuggests = React.useCallback(() => {
    setVisibleSuggest(true);
  }, []);

  /**
   * Схлопывает выпадашку
   */
  const closeSuggests = React.useCallback(() => {
    setVisibleSuggest(false);
  }, []);

  /**
   * Оповещает родителя об изменении инпута
   */
  const handleChangeValue = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChangeInputValue(e.target.value);
    },
    [onChangeInputValue],
  );

  /**
   * Оповещает родителя о выборе региона
   */
  const handleSelect = React.useCallback(
    (region: IRegionsItem) => {
      closeSuggests();
      onSelectRegion(region);
    },
    [closeSuggests, onSelectRegion],
  );

  /**
   * Оповещает родителя об установлении нового региона
   */
  const handleSubmitRegion = React.useCallback(() => {
    if (loading) {
      return;
    }

    onSubmitRegion();
  }, [loading, onSubmitRegion]);

  return (
    <div className={styles['container']}>
      <div className={styles['header']}>
        <UIHeading3>Выберите регион или город</UIHeading3>
      </div>

      <Outside onOutside={closeSuggests}>
        <div className={styles['search']}>
          <div className={styles['input']}>
            <Input
              placeholder="Выберите регион или город"
              value={inputValue}
              loading={loading}
              onChange={handleChangeValue}
              onFocus={openSuggests}
            />
            {
              suggests.length > 0 &&
              visibleSuggest &&
              <GeoSuggest
                suggests={suggests}
                onSelect={handleSelect}
              />
            }
          </div>
          <Button
            size={deviceType === 'phone' ? 'M' : 'XS'}
            onClick={handleSubmitRegion}
          >
            Выбрать
          </Button>
        </div>
      </Outside>
    </div>
  );
};

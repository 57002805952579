import { EOrdering } from '../../repositories/journal/v2/get-article-list';

/**
 * Проверяет и корректирует значение сортировки для листинга
 * Значение должно входить в EOrdering
 */
export const prepareListingOrdering = (ordering: string | string[]) => {
  if (!ordering || !ordering.length) {
    return;
  }

  const orderingPrepared = (typeof ordering === 'string' ? ordering : ordering[0]).toLowerCase();

  if (!(Object.values(EOrdering) as string[]).includes(orderingPrepared)) {
    return;
  }

  return orderingPrepared as EOrdering;
};

import * as React from 'react';

import { IBreadItem } from '../../types/bread_crumb';
import { BreadCrumb } from './bread_crumbs_item';

import s from './bread_crumbs.css';

interface IProps {
  path?: IBreadItem[];
  customName?: string;
  mobile?: boolean;
}

export const BreadCrumbs: React.FC<IProps> = props => {
  const { path, mobile } = props;

  return (
    <ul
      className={`
          ${s['breadcrumbs']}
          ${mobile ? s['breadcrumbs-mobile'] : s['breadcrumbs-dekstop']}
          ${path && path.length === 1 ? s['breadcrumbs-hidden'] : ''}
        `}
      itemScope
      itemType="https://schema.org/BreadcrumbList"
    >
      {path &&
        path.length &&
        path.map((item, i) => {
          return (
            <BreadCrumb
              key={`bread-crumb-${i}`}
              path={item.path}
              name={item.name}
              url={item.url}
              isLastItem={i === path.length - 1}
              position={i + 1}
            />
          );
        })}
    </ul>
  );
};

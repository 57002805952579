import { createSelector } from 'reselect';

import { IApplicationState } from '../../types/redux';
import { EType } from '../../repositories/journal/entities/page_info/PageInfoSchema';

/**
 * Выбирает текущий листинг по pageType
 */
export const selectCurrentListing = createSelector(
  (state: IApplicationState) => state,
  (_: IApplicationState, params: { pageType: EType; hasSearchingQuery?: boolean }) => params,
  (state, { pageType, hasSearchingQuery }) => {
    if (
      hasSearchingQuery &&
      [EType.MainPage, EType.Articles, EType.News, EType.Blogs, EType.Questions].includes(pageType)
    ) {
      return state.searchingResults;
    }

    switch (pageType) {
      case EType.Articles:
      case EType.Documents:
        return state.articleList;

      case EType.News:
        return state.newsList;

      case EType.Blogs:
        return state.blogList;

      case EType.Questions:
        return state.questionList;

      case EType.MainPage:
      default:
        return state.mainArticles;
    }
  },
);

import React from 'react';
import { Route as ReactRoute, RouteProps, RouteComponentProps } from 'react-router';

export interface IRouteParams extends RouteProps {
  onRender?(routeProps: RouteComponentProps): void;
}

export const Route = ({ component, onRender, ...rest }: IRouteParams) => {
  const Component = component as React.ElementType;

  return (
    <ReactRoute
      {...rest}
      render={routeProps => {
        if (onRender) {
          onRender(routeProps);
        }

        return <Component {...routeProps} />;
      }}
    />
  );
};

import { isInViewPort } from './isInViewPort';
import debounce from 'lodash/debounce';

export interface IObserverProps {
  target: HTMLElement;

  callback(isVisible: boolean): void;
}

export const intersectionObserver = (props: IObserverProps) => {
  const observer = new IntersectionObserver(
    entries => {
      entries.forEach(entry => {
        if (entry.intersectionRatio !== 1) {
          props.callback(false);
        } else {
          props.callback(true);
        }
      });
    },
    { threshold: 1 },
  );

  observer.observe(props.target);

  return () => {
    observer.disconnect();
  };
};

export const scrollObserver = (props: IObserverProps) => {
  const checkVisibility = debounce(() => {
    props.callback(isInViewPort(props.target));
  }, 100);

  window.addEventListener('resize', checkVisibility);
  document.addEventListener('scroll', checkVisibility);

  return () => {
    window.removeEventListener('resize', checkVisibility);
    document.removeEventListener('scroll', checkVisibility);
  };
};

export const getObserver = () => {
  return 'IntersectionObserver' in window ? intersectionObserver : scrollObserver;
};

import React, { useCallback, useRef, useState } from 'react';
import { ArticleParagraph2, Button, Input, UIHeading3 } from '@cian/ui-kit';

import { DogAndCloud } from './components/DogAndCloud';
import { useEventListener, useTimeoutEffect } from '../../../utils';
import { CIAN_URL } from '../../../constants';
import * as s from './SidebarSubscribeForm.css';

interface IBlogsSubscribeFormParams {
  errorText?: string;
  subscribedText?: string;
  isDisabled?: boolean;
  onSubmit(email: string): void;
  onChange(email: string): void;
}

/** Форма подписки для сайдбара */
export const SidebarSubscribeForm = ({
  errorText,
  subscribedText,
  isDisabled,
  onSubmit,
  onChange,
}: IBlogsSubscribeFormParams) => {
  const [value, setValue] = useState('');
  const [errorMessage, setIsErrorMessage] = useState<string | undefined>();
  const inputRef = useRef<HTMLInputElement>(null);

  /** При пропадании ошибки выжидаем анимацию скрытия сообщения */
  useTimeoutEffect(() => {
    setIsErrorMessage(errorText);
  }, [errorText ? 0 : 300]);

  /** Сохраняет значение в стэйт */
  const handleChange = useCallback(
    (_, value: string) => {
      setValue(value);
      onChange(value);
    },
    [onChange],
  );

  /** Отправляет значение по клику или Enter */
  const handleSubmit = useCallback(
    e => {
      e.preventDefault();
      onSubmit(value);
    },
    [onSubmit, value],
  );

  /** Вызывает callback при нажатии на Enter */
  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.code === 'Enter') {
        handleSubmit(e);
      }
    },
    [handleSubmit],
  );

  useEventListener('keydown', handleKeyDown, inputRef);

  return (
    <div className={s['wrapper']}>
      <div className={s['content-wrapper']}>
        {subscribedText && (
          <div className={s['subscribed-wrapper']}>
            <UIHeading3>
              <div className={s['subscribed-content']}>
                <span>Отлично! </span>
                <span>Вы подписались на рассылку</span>
              </div>
            </UIHeading3>

            <ArticleParagraph2>{subscribedText}</ArticleParagraph2>
            <DogAndCloud />
          </div>
        )}

        {!subscribedText && (
          <div className={s['unsubscribed-wrapper']}>
            <UIHeading3>Подпишитесь на рассылку и будьте в курсе новостей рынка недвижимости</UIHeading3>

            <form className={s['form']}>
              <div className={s['fields-wrapper']}>
                <div className={s['input-wrapper']}>
                  <Input
                    ref={inputRef}
                    value={value}
                    invalid={!!errorText}
                    disabled={isDisabled}
                    size={'M'}
                    width={'100%'}
                    placeholder={'Ваш email'}
                    onChange={handleChange}
                  />

                  <div className={`${s['error-wrapper']} ${errorText ? s['_active'] : ''}`}>
                    {errorMessage && <div className={s['error']}>{errorMessage}</div>}
                  </div>
                </div>

                <Button theme="fill_primary" size={'M'} fullWidth={true} loading={isDisabled} onClick={handleSubmit}>
                  Подписаться
                </Button>
              </div>

              <div className={s['description']}>
                При подписке вы принимаете условия{' '}
                <a href={`${CIAN_URL}/help/about/rules/`} target={'_blank'} rel="noreferrer">
                  Пользовательского соглашения
                </a>{' '}
                и{' '}
                <a href={`${CIAN_URL}/help/about/36526/`} target={'_blank'} rel="noreferrer">
                  Политики конфиденциальности
                </a>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

import React, { useCallback, useEffect, useRef } from 'react';

import { IconChevronDown24 } from '../Icons';
import { useWindowSize } from '../../utils';
import * as s from './Collapser.css';

export interface ICollapserParams {
  /** Заголовок коллапсера */
  title: React.ReactNode;
  /** Контент коллапсера */
  children: React.ReactNode;
  /** Внешний ключ управления */
  isOpen: boolean;
  /** Длительность анимации в мс */
  transitionDuration?: number;
  /** Событие закрытия */
  onToggle(isOpened: boolean): void;
}

/**
 * Коллапсер контента
 */
export const Collapser = ({ title, isOpen, children, onToggle, transitionDuration = 300 }: ICollapserParams) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { width: windowWidth } = useWindowSize();

  /**
   * Переключает класс анимации
   */
  useEffect(() => {
    if (wrapperRef?.current) {
      wrapperRef?.current.classList.toggle(s['_shown'], isOpen);
    }
  }, [isOpen]);

  /**
   * Вычисляет высоту раскрываемого контента и
   * подключает как maxHeight для плавной анимации
   */
  useEffect(() => {
    if (!wrapperRef?.current) {
      return;
    }

    const contentWrapper = wrapperRef.current.childNodes[1] as HTMLDivElement;
    const { height } = (contentWrapper.childNodes[0] as HTMLDivElement).getBoundingClientRect();

    if (isOpen) {
      contentWrapper.style.maxHeight = `${height}px`;
    } else {
      contentWrapper.style.maxHeight = '';
    }
  }, [isOpen, windowWidth]);

  /**
   * Отправляет родителю событие закрытия, если коллапсер открыт
   */
  const toggle = useCallback(() => {
    onToggle(isOpen);
  }, [isOpen, onToggle]);

  return (
    <div className={s['wrapper']} ref={wrapperRef}>
      <div className={s['header']} onClick={toggle}>
        <div className={s['title']}>{title}</div>

        <div className={s['icon']} style={{ transitionDuration: `${transitionDuration}ms` }}>
          <IconChevronDown24 />
        </div>
      </div>

      <div className={s['content-wrapper']} style={{ transitionDuration: `${transitionDuration}ms` }}>
        <div className={s['content']}>{children}</div>
      </div>
    </div>
  );
};

/* eslint-disable */

//tslint:disable

import { IModel, IModelResponse } from '@cian/http-api/shared/model';
import { IFastFiltersUserResponseSchema } from '../../entities/schemas/FastFiltersUserResponseSchema';

export type TGetUserDataByRequestModel = IModel<IGetUserDataByRequestRequest, TGetUserDataByRequestResponse>;

export interface IGetUserDataByRequestRequest {}

export type TGetUserDataByRequestResponse = IGetUserDataByRequestResponse200 | IGetUserDataByRequestResponse400;

export interface IGetUserDataByRequestResponse200 extends IModelResponse<IGetUserDataByRequestResponse> {
  statusCode: 200;
}

export interface IGetUserDataByRequestResponse400 extends IModelResponse<IGetUserDataByRequestResponseError> {
  statusCode: 400;
}

export interface IGetUserDataByRequestResponse {
  data: IFastFiltersUserResponseSchema;
  status: EStatus;
}

export interface IGetUserDataByRequestResponseError {
  errors: any[];
  message: string;
}

export type TResponses = IGetUserDataByRequestResponse | IGetUserDataByRequestResponseError;

export enum EStatus {
  /** undefined **/
  Ok = 'ok',
}

export interface IMappers<TResponse200, TResponse400> {
  200(response: IGetUserDataByRequestResponse): TResponse200;
  400(response: IGetUserDataByRequestResponseError): TResponse400;
}

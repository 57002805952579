import * as React from 'react';

export const IconBook16 = () => {
  return (
    // eslint-disable-next-line react/forbid-elements
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 1V13C4.42857 13.625 7 15 7 15V3C7 3 4.42857 1.5 1 1ZM15 1V13C11.5714 13.625 9 15 9 15V3C9 3 11.5714 1.5 15 1Z"
        fill="#737A8E"
      />
    </svg>
  );
};

/* eslint-disable */

//tslint:disable

import { IModel, IModelResponse } from '@cian/http-api/shared/model';
import { ISearchDataSchema } from '../../entities/search/SearchDataSchema';
import { ISearchMetaSchema } from '../../entities/search/SearchMetaSchema';

export type TSearchModel = IModel<ISearchRequest, TSearchResponse>;

export interface ISearchRequest {
  /** Аудитория **/
  b2b?: boolean | null;
  /** Количество записей **/
  limit?: number | null;
  /** Сдвиг **/
  offset?: number | null;
  /** Текст запроса **/
  q?: string | null;
  /** Тип поиска **/
  qType?: EQType | null;
  /** ID региона **/
  regionId?: number | null;
  /** Тип поиска **/
  type?: EType | null;
}

export type TSearchResponse = ISearchResponse200 | ISearchResponse400;

export interface ISearchResponse200 extends IModelResponse<ISearchResponse> {
  statusCode: 200;
}

export interface ISearchResponse400 extends IModelResponse<ISearchResponseError> {
  statusCode: 400;
}

export interface ISearchResponse {
  /** Список найденных объектов **/
  data: ISearchDataSchema[];
  /** Мета данные **/
  meta: ISearchMetaSchema;
}

export interface ISearchResponseError {
  errors: any[];
  message: string;
}

export type TResponses = ISearchResponse | ISearchResponseError;

export enum EQType {
  /** Поиск по журналу ("Статьи/Новости/Блоги/Вопросы) **/
  Journal = 'journal',
  /** Поиск по тегам **/
  Tags = 'tags',
}

export enum EType {
  /** Статьи/Новости/Блоги/Вопросы **/
  All = 'all',
  /** Статьи **/
  Articles = 'articles',
  /** Блоги **/
  Blogs = 'blogs',
  /** 'Новости **/
  News = 'news',
  /** Вопросы **/
  Questions = 'questions',
}

export interface IMappers<TResponse200, TResponse400> {
  200(response: ISearchResponse): TResponse200;
  400(response: ISearchResponseError): TResponse400;
}

export interface IExtractAndReplaceBlockquotesItemUserCard {
  /** Картинка */
  image?: string;
  /** Заголовок */
  title?: string;
  /** Описание */
  description?: string;
}

export interface IExtractAndReplaceBlockquotesItem {
  /** Текст цитаты */
  description?: string;
  /** Картинка */
  image?: string;
  /** Карточка пользователя */
  userCard?: IExtractAndReplaceBlockquotesItemUserCard;
  /** Тема оформления */
  theme?: string;
  /** Id */
  id: string;
  /** Полное HTML-тело шаблона */
  outerHtml: string;
  /** Позиция в тексте */
  position: 'top' | 'middle' | 'bottom';
}

export interface IExtractAndReplaceBlockquotes {
  /** HTML-контент */
  html: string;
  /** Коллекция галерей */
  items: IExtractAndReplaceBlockquotesItem[];
}

/**
 * Парсит и вырезает настройки для цитат
 */
export const extractAndReplaceBlockquotes = (html: string): IExtractAndReplaceBlockquotes => {
  const pattern = /<div[^>]*?data-name="blockquote"[^>]*?>[^<]*?<\/div>/gis;
  const items = [] as IExtractAndReplaceBlockquotesItem[];
  let match;

  if (!html) {
    return {
      html: '',
      items,
    };
  }

  let index = 0;
  while ((match = pattern.exec(html))) {
    index = html.indexOf(match[0], index);
    const outerHTML = match[0];

    if (outerHTML) {
      items.push({
        description: (outerHTML.match(/data-description="([^"]*?)"/)?.[1] || '').replace(/\\n/g, '<br/>'),
        image: outerHTML.match(/data-image="([^"]*?)"/)?.[1],
        userCard: {
          image: outerHTML.match(/data-usercard-image="([^"]*?)"/)?.[1],
          title: outerHTML.match(/data-usercard-title="([^"]*?)"/)?.[1],
          description: outerHTML.match(/data-usercard-description="([^"]*?)"/)?.[1],
        },
        theme: outerHTML.match(/data-theme="([^"]*?)"/)?.[1],
        id: `blockquote-${items.length + 1}`,
        outerHtml: outerHTML,
        position: index / html.length < 0.333 ? 'top' : index / html.length < 0.667 ? 'middle' : 'bottom',
      });
    }
  }

  return {
    html: items.reduce((acc, item) => acc.replace(item.outerHtml, `<div id="${item.id}"></div>`), html),
    items,
  };
};

import { IEvent, IConsumer, createConsumer, createSubscriber, getGlobalEventsLogs } from '@cian/events-log';

import { TThunkAction } from '../../../types/redux';
import { getUser } from '../getUser';

export enum EAlertType {
  Favorites = 'favorites',
  Chats = 'chats',
  Hide = 'hide',
}

export interface IRequestAuthenticationParameters {
  alert?: EAlertType;
  onSuccess?(): TThunkAction;
  onCloseNoAuth?(): TThunkAction;
  autoRefresh?: IAuthenticateEvent['autoRefresh'];
}

interface IAuthenticateEvent {
  autoRefresh?: boolean;
}

const TOPIC_USER = 'user';

type TConsumerSingleton = (() => IConsumer) & { _instance?: IConsumer };

const getUserConsumer: TConsumerSingleton = (): IConsumer => {
  if (!getUserConsumer._instance) {
    getUserConsumer._instance = createConsumer({ topic: TOPIC_USER });
  }

  return getUserConsumer._instance;
};

export function requestAuthentication(parameters?: IRequestAuthenticationParameters): TThunkAction {
  return dispatch => {
    /* istanbul ignore else */
    if (typeof window === 'undefined') {
      return;
    }

    const globalLog = getGlobalEventsLogs();
    const consumer = getUserConsumer();

    const userSubscriber = createSubscriber(async (event: IEvent<unknown>) => {
      if (event.type === 'authenticated') {
        await dispatch(getUser());

        /* istanbul ignore else */
        if (window.__reloadHeader__) {
          window.__reloadHeader__();
        }

        /* istanbul ignore else */
        if (parameters?.onSuccess) {
          dispatch(parameters.onSuccess());
        }

        consumer.unsubscribe(userSubscriber);

        return;
      }

      if (event.type === 'authentication_modal_closed') {
        /* istanbul ignore else */
        if (parameters?.onCloseNoAuth) {
          dispatch(parameters.onCloseNoAuth());
        }

        consumer.unsubscribe(userSubscriber);
      }
    });

    consumer.subscribe(userSubscriber);

    globalLog.produce<Required<IAuthenticateEvent>>(TOPIC_USER, {
      type: 'authenticate',
      value: { autoRefresh: false },
    });
  };
}

import * as React from 'react';
import s from './demand_advantages.css';

export const DemandAdvantages: React.SFC<{}> = () => (
  <div className={s['advantage__content']}>
    <div className={s['advantage']}>
      Риелтор возьмет на себя решение юридических вопросов
    </div>
    <div className={s['advantage']}>
      Проведет сложную сделку: продажа с последующей покупкой квартиры; покупка и продажа с использованием ипотеки или материнского капитала и др.
    </div>
    <div className={s['advantage']}>
      Риелтора подберем бесплатно. Условия сотрудничества и стоимость услуг обсуждается с риелтором
    </div>
  </div>
)

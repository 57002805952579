import { ITypedReduxAction } from '../../../types/redux/actionType';
import { ISearchingResults } from '../../../types/searchingResults';

export enum EGetSearchingResultsActionType {
  Loading = 'searchingResults/getSearchingResults/loading',
  Succeed = 'searchingResults/getSearchingResults/succeed',
  AppendSucceed = 'searchingResults/getSearchingResults/appendSucceed',
  Failed = 'searchingResults/getSearchingResults/failed',
}

export type TGetSearchingResultsLoading = ITypedReduxAction<EGetSearchingResultsActionType.Loading>;
export type TGetSearchingResultsSucceed = ITypedReduxAction<EGetSearchingResultsActionType.Succeed, ISearchingResults>;
export type TGetSearchingResultsAppendSucceed = ITypedReduxAction<
  EGetSearchingResultsActionType.AppendSucceed,
  ISearchingResults
>;
export type TGetSearchingResultsFailed = ITypedReduxAction<EGetSearchingResultsActionType.Failed>;

export type TGetSearchingResults =
  | TGetSearchingResultsLoading
  | TGetSearchingResultsSucceed
  | TGetSearchingResultsAppendSucceed
  | TGetSearchingResultsFailed;

import React from 'react';
import { useDeviceType } from '@cian/ui-kit';

import { numberWithDelimiter, cutNumber } from '../../utils';
import * as s from './IconWithCount.css';

interface IIconWithCount {
  count: number;
  icon: React.ReactNode;
}

/**
 * Иконка и число
 */
export const IconWithCount = ({ count, icon }: IIconWithCount) => {
  const deviceType = useDeviceType();
  const isPhone = deviceType === 'phone';
  const preparedCount = isPhone ? cutNumber(count) : numberWithDelimiter(count);

  return (
    <div className={s['wrapper']}>
      <div className={s['icon']}>{icon}</div>
      <div className={s['count']}>{preparedCount}</div>
    </div>
  );
};

import { ITypedReduxAction } from '../../../types/redux/actionType';
import { INewsList } from '../../../types/newsList';

export enum EGetNewsListActionType {
  Loading = 'newsList/getNewsList/loading',
  Succeed = 'newsList/getNewsList/succeed',
  AppendSucceed = 'newsList/getNewsList/appendSucceed',
  Failed = 'newsList/getNewsList/failed',
}

export type TGetNewsListLoading = ITypedReduxAction<EGetNewsListActionType.Loading>;
export type TGetNewsListSucceed = ITypedReduxAction<EGetNewsListActionType.Succeed, INewsList>;
export type TGetNewsListAppendSucceed = ITypedReduxAction<EGetNewsListActionType.AppendSucceed, INewsList>;
export type TGetNewsListFailed = ITypedReduxAction<EGetNewsListActionType.Failed>;

export type TGetNewsList = TGetNewsListLoading | TGetNewsListSucceed | TGetNewsListAppendSucceed | TGetNewsListFailed;

import { defaultState } from '../../reducers/searchingResults';
import { ERequestStatus } from '../../types/requestStatus';
import { IQuickSearchingResults, TQuickSearchingResultsState } from '../../types/quickSearchingResults';
import { IQuickSearchDataSchema } from '../../repositories/journal/entities/search/QuickSearchDataSchema';

export const mkQuickSearchingResultsItems = (data?: IQuickSearchDataSchema[]): IQuickSearchDataSchema[] => {
  return [
    {
      attributes: {
        title: null,
        id: 6069,
        rubrics: null,
        objectsCount: 5,
        slug: '2023',
        datePublish: null,
        name: '2023',
        category: null,
      },
      type: 'tags',
      id: 6069,
    },
    {
      attributes: {
        title: 'Семейная ипотека 2023',
        id: 332910,
        rubrics: [],
        objectsCount: null,
        slug: 'semejnaja-ipoteka-2023',
        datePublish: '2023-10-26T12:39:04.662528+00:00',
        name: null,
        category: 'market_trend',
      },
      type: 'blogs',
      id: 332910,
    },
    {
      attributes: {
        title: 'Продажа и покупка в разных налоговых периодах',
        id: 328486,
        rubrics: [],
        objectsCount: null,
        slug: 'prodazha-i-pokupka-v-raznyh-nalogovyh-periodah',
        datePublish: '2022-12-15T17:58:48.924743+00:00',
        name: null,
        category: null,
      },
      type: 'questions',
      id: 328486,
    },
    {
      attributes: {
        title: 'налоги',
        id: 330587,
        rubrics: [],
        objectsCount: null,
        slug: 'nalogi',
        datePublish: '2023-05-02T15:07:09.212585+00:00',
        name: null,
        category: null,
      },
      type: 'questions',
      id: 330587,
    },
    {
      attributes: {
        title: 'Доходы от списания ипотечных долгов освободили от НДФЛ',
        id: 328705,
        rubrics: [],
        objectsCount: null,
        slug: 'dohody-ot-spisanija-ipotechnyh-dolgov-osvobodili-ot-ndfl',
        datePublish: '2022-12-30T10:50:00+00:00',
        name: null,
        category: 'market',
      },
      type: 'news',
      id: 328705,
    },
    {
      attributes: {
        title: 'Продление семейной ипотеки позволит выдать еще 90 тыс. жилищных кредитов',
        id: 319943,
        rubrics: [],
        objectsCount: null,
        slug: 'prodlenie-semejnoj-ipoteki-pozvolit-vydat-esche-90-tys-zhilischnyh-kreditov',
        datePublish: '2021-08-18T07:05:00+00:00',
        name: null,
        category: 'market',
      },
      type: 'news',
      id: 319943,
    },
    {
      attributes: {
        title: 'В «Дом.РФ» рассказали, какими будут цены на жилье в 2023 году',
        id: 328644,
        rubrics: [],
        objectsCount: null,
        slug: 'v-domrf-rasskazali-kakimi-budut-tseny-na-zhile-v-2023-godu',
        datePublish: '2022-12-27T10:58:00+00:00',
        name: null,
        category: 'market',
      },
      type: 'news',
      id: 328644,
    },
    {
      attributes: {
        title: 'Инвестиции в коммерческую недвижимость в РФ выросли вдвое',
        id: 332007,
        rubrics: [],
        objectsCount: null,
        slug: 'investitsii-v-kommercheskuju-nedvizhimost-v-rf-vyrosli-vdvoe',
        datePublish: '2023-08-14T15:57:00+00:00',
        name: null,
        category: 'market',
      },
      type: 'news',
      id: 332007,
    },
    {
      attributes: {
        title: 'Выставка зарубежной и столичной недвижимости!',
        id: 329470,
        rubrics: [],
        objectsCount: null,
        slug: 'vystavka-zarubezhnoj-i-stolichnoj-nedvizhimosti',
        datePublish: '2023-02-22T11:01:57.519272+00:00',
        name: null,
        category: '',
      },
      type: 'blogs',
      id: 329470,
    },
    {
      attributes: {
        title:
          '🚩 Ещё не поздно получить свой билет на выставку зарубежной и курортной недвижимости Moscow Overseas Property and Inves',
        id: 329798,
        rubrics: [],
        objectsCount: null,
        slug: 'esche-ne-pozdno-poluchit-svoj-bilet-na-vystavku-zarubezhnoj-i-kurortnoj-nedvizhimosti-moscow-overseas-property-and-inves',
        datePublish: '2023-03-16T16:44:33.832476+00:00',
        name: null,
        category: '',
      },
      type: 'blogs',
      id: 329798,
    },
    ...(data || []),
  ];
};

export const mkQuickSearchingResults = (data?: Partial<IQuickSearchingResults>): IQuickSearchingResults => {
  return {
    meta: defaultState.meta,
    items: mkQuickSearchingResultsItems(),
    ...data,
  };
};

export const mkQuickSearchingResultsState = (
  data?: Partial<TQuickSearchingResultsState>,
): TQuickSearchingResultsState => {
  return {
    ...defaultState,
    ...mkQuickSearchingResults(),
    status: ERequestStatus.Succeed,
    ...data,
  };
};

import { ITypedReduxAction } from '../../../types/redux/actionType';
import { ISettings } from '../../../types/settings';

export enum ESetInitialPageNumberActionType {
  Set = 'settings/setInitialPageNumber',
}

export type TSetInitialPageNumber = ITypedReduxAction<
  ESetInitialPageNumberActionType.Set,
  ISettings['initialPageNumber']
>;

import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { selectPost } from '../../selectors/post';
import { useGetPost } from '../../utils/useGetPost';
import { TThunkDispatch } from '../../types/redux';
import { getPost } from '../../actions/post';
import { parsePostCardPath } from '../../utils';
import { ERequestStatus } from '../../types/requestStatus';
import { PostTypography, PostContentLayout } from '../../components/PostLayouts';
import s from '../../components/post_card/post_card.css';
import { unescapeHtml } from '../../../app/helpers/make_html_helper';

export const PostContainer = () => {
  const dispatch = useDispatch<TThunkDispatch>();
  const { pathname } = useLocation();
  const { id: qsPostId } = parsePostCardPath(pathname);
  const post = useSelector(selectPost);

  useGetPost(
    () => {
      dispatch(getPost({ id: qsPostId })).finally();
    },
    { dependencyNames: [] },
  );

  if (post.status === ERequestStatus.Loading) {
    return <span>Loading</span>;
  }

  if (!('id' in post)) {
    return null;
  }

  const {
    attributes: { content, title },
  } = post;

  return (
    <PostTypography>
      <h1 className={s['post-card-title']} itemProp="headline">
        {unescapeHtml(title)}
      </h1>

      <PostContentLayout>
        {/* eslint-disable-next-line react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: content as string }} />
      </PostContentLayout>
    </PostTypography>
  );
};

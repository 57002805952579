import * as React from 'react';

import { SidebarNews } from '../../components/SidebarNews';
import { mkMainNews } from '../../__stubs__/mocks/mainNews';

export const SidebarNewsContainer = () => {
  const newsItems = mkMainNews();

  return <SidebarNews items={newsItems} />;
};

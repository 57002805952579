export const POST_PREVIEW_SUCCESS = 'post_preview/ACTION_SUCCESS';
export const POST_PREVIEW_FAILURE = 'post_preview/ACTION_FAILURE';

export const initialState = {
  item: {
    attributes: {
      title: '',
      content: '',
      datePublish: '',
      image: '',
      numViews: 0,
      sectionUrl: '',
      subtitle: '',
      author: '',
      slug: '',
      comments: [],
      regions: [],
      tags: [],
    },
    id: '',
    type: '',
  },
};
const defaultAction = {
  type: '',
  payload: {
    title: '',
    image: '',
    datePublish: '',
    category: '',
    subtitle: '',
    author: '',
    content: '',
    noIndex: '',
    tags: '',
    slug: '',
    seoTitle: '',
    seoDescription: '',
    seoKeywords: '',
    type: '',
  },
};

export const postPreviewReducer = (state = initialState, action = defaultAction) => {
  switch (action.type) {
    case POST_PREVIEW_SUCCESS:
      const postData = {
        attributes: { numViews: 0, ...action.payload, tags: action.payload.tags?.length ? action.payload.tags.split(',') : [] },
        id: '',
        type: '',
      };

      return Object.assign({}, state, { item: postData });

    case POST_PREVIEW_FAILURE:
      return Object.assign({}, state);

    default:
      return state;
  }
};

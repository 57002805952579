import * as queryString from 'qs';
import { BaseError } from 'make-error-cause';

import { getCategory } from './category_helper';
import { GET_ANALYTICS_USER, timeoutDegradationState } from '../../shared/reducers/modules/analytics/analytics';
import { IApplicationContext } from '../../shared/types/applicationContext';

export interface IMakeMaybeTimeoutRequestParams {
  promise: Promise<any>;
  actionType: string;
  getUserGaDataLayer: number;
}

export const GET_USER_GA_DATA_LAYER_DEFAULT = 1000;

const NEED_TIMEOUT_ACTIONS_TYPES = [GET_ANALYTICS_USER];

class TimeoutError extends BaseError {
  public constructor(message: string) {
    super(message);
  }
}

const formatError = (errors: any) => {
  if (typeof errors[0].detail === 'object') {
    return {
      detail: errors[0].detail.message,
      source: {
        pointer: errors[0].source.pointer,
      },
      status: errors[0].status,
    };
  }

  return errors;
};

function timeoutPromise<T>(promise: Promise<T>, timeout: number): Promise<T> {
  return Promise.race([
    promise,
    new Promise<never>((resolve, reject) =>
      setTimeout(() => reject(new TimeoutError('Timeout has occurred')), timeout),
    ),
  ]);
}

function isTimeout(actionType: string): boolean {
  return NEED_TIMEOUT_ACTIONS_TYPES.includes(actionType);
}

/**
 * Возвращает промис или таймаут промис
 */
function makeMaybeTimeoutRequest<T>({
  promise,
  actionType,
  getUserGaDataLayer,
}: IMakeMaybeTimeoutRequestParams): Promise<T> {
  return isTimeout(actionType) ? timeoutPromise(promise, getUserGaDataLayer) : promise;
}

export const requestMiddleware =
  ({ telemetry, makeRequest, getUserGaDataLayer, custom: { subdomain } }: IApplicationContext) =>
  (store: any) =>
  (next: any) =>
  (action: any): any => {
    if (action.request) {
      const REQUEST = `${action.type}_REQUEST`;
      const SUCCESS = `${action.type}_SUCCESS`;
      const FAILURE = `${action.type}_FAILURE`;
      const TIMEOUT_DEGRADATION = `${action.type}_TIMEOUT_DEGRADATION`;
      let body: any;
      const headers: [string, string][] = [
        ['Accept', 'application/json, application/xml, application/vnd.api+json, text/play, text/html, *.*'],
      ];

      if (action.method === 'POST') {
        if (action.payload.data) {
          body = queryString.stringify({ ...action.payload.data.attributes }, { arrayFormat: 'repeat' });
        }

        headers.push(['Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8']);
      }

      next({ type: REQUEST, payload: action.payload });

      const params = action.payload.params;
      let pathnameParams = '';

      if (params) {
        params.forEach((param: { name: string; value: string | number }, i: number) => {
          pathnameParams += i === 0 ? `${param.name}=${param.value}` : `&${param.name}=${param.value}`;
        });
      }

      if (action.header) {
        headers.push(...action.header);
      }

      const requestConfig = {
        method: action.method,
        uri: {
          subdomain,
          scheme: action.payload.scheme,
          host: action.payload.host,
          path: action.payload.pathname,
          query: pathnameParams,
          basePath: action.payload.basePath,
        },
        body,
        headers,
        currentUser: store.getState().user,
      };
      const makeMaybeTimeoutRequestParams: IMakeMaybeTimeoutRequestParams = {
        promise: makeRequest(requestConfig),
        actionType: action.type,
        getUserGaDataLayer,
      };

      return makeMaybeTimeoutRequest(makeMaybeTimeoutRequestParams)
        .then((payload: any) => {
          // console.log('action', action);
          // console.log('payload', payload);
          if (payload.errors) {
            throw payload.errors;
          }

          next({ type: SUCCESS, payload, additionalFields: action.additionalFields });

          if (!!action.onSuccess && typeof action.onSuccess === 'function') {
            const seo = action.staticMetaProps
              ? payload.meta && payload.meta.pageTitle
                ? { ...action.staticMetaProps(subdomain), seoTitle: payload.meta.pageTitle }
                : action.staticMetaProps(subdomain)
              : {
                  seoUrl:
                    `https://www.cian.ru/` +
                    `${getCategory(payload.data.attributes.type).linkType}-${payload.data.attributes.slug}-${
                      payload.data.id
                    }/`,
                  seoTitle: payload.data.attributes.seoTitle,
                  seoDescription: payload.data.attributes.seoDescription,
                  seoKeywords: payload.data.attributes.seoKeywords,
                  seoImage: payload.data.attributes.image || payload.data.attributes.imageThumbnail,
                  noIndex: payload.data.attributes.noIndex,
                };

            next(action.onSuccess(seo));
            next({
              type: 'global_error/ACTION',
              payload: {
                error: false,
              },
            });
          }

          return payload;
        })
        .catch((errors: any) => {
          // console.log('errors', errors);

          if (errors.statusCode > 404) {
            next({
              type: 'global_error/ACTION',
              payload: {
                error: true,
              },
            });
          }

          if (errors instanceof TimeoutError) {
            telemetry.increment('get_user_ga_data_layer_data');
            next({ type: TIMEOUT_DEGRADATION, payload: { data: timeoutDegradationState.user } });

            return;
          }

          try {
            const error = formatError(errors);

            next({ type: FAILURE, error });

            if (action.onError && typeof action.onError === 'function') {
              next(action.onError(error.detail, error.status));
            }
          } catch (e) {
            next({ type: FAILURE, payload: { error: errors } });
          }
          throw errors;
        });
    } else {
      next(action);
    }
  };

import React, { memo } from 'react';
import { useDeviceType } from '@cian/ui-kit';
import { AdfoxBannerMainPageArticleDesktop } from './AdfoxBannerMainPageArticleDesktop';
import { AdfoxBannerMainPageArticleMobile } from './AdfoxBannerMainPageArticleMobile';

export const AdfoxBannerMainPageArticle = memo(function AdfoxBannerMainPageArticle() {
  const deviceType = useDeviceType();

  return deviceType === 'desktop' ? <AdfoxBannerMainPageArticleDesktop /> : <AdfoxBannerMainPageArticleMobile />;
});

import {ICategories} from '../../../types/nav';

export const RUBRICS_MENU = 'rubrics_menu/ACTION';
export const RUBRICS_MENU_REQUEST = 'rubrics_menu/ACTION_REQUEST';
export const RUBRICS_MENU_SUCCESS = 'rubrics_menu/ACTION_SUCCESS';
export const RUBRICS_MENU_FAILURE = 'rubrics_menu/ACTION_FAILURE';

export interface IRubric {
  id: number;
  name: string;
  type: string;
  pathname: string;
  fullPathname: string;
  categories: ICategories | null;
  metaTitle: string;
  metaKeywords: string;
  metaDescription: string;
}

export interface IMenuRubrics {
  items: IRubric[];
}

export interface IMenuRubricsState {
  items: IMenuRubrics[];
}

export const initialState = {
  items: [{
    items: [{
      id: 0,
      name: '',
      type: '',
      pathname: '',
      fullPathname: '',
      categories: {},
      metaTitle: '',
      metaKeywords: '',
      metaDescription: '',
    }],
  }],
};

const defaultAction = {
  type: '',
  payload: {
    items: initialState.items,
  },
};

export const rubricsMenuReducer = (state: IMenuRubricsState = initialState, action = defaultAction) => {
  switch (action.type) {
    case RUBRICS_MENU_REQUEST:
      return state;
    case RUBRICS_MENU_SUCCESS:
      return Object.assign({}, state, { items: action.payload.items });
    case RUBRICS_MENU_FAILURE:
      return state;
    default:
      return state;
  }
};

export interface IFetchRubricsMenuPayload {
  scheme: string;
  host: string;
  path: string;
}

interface IPayload {
  scheme: string;
  host: string;
  pathname: string;
}

interface IFetchRubrics {
  type: string;
  method: string;
  payload: IPayload;
  request: boolean;
}

export const fetchRubrics = ({ path, ...restPayload }: IFetchRubricsMenuPayload): IFetchRubrics => {
  return {
    type: RUBRICS_MENU,
    method: 'GET',
    payload: {
      pathname: `/v1/get-menu/`,
      ...restPayload,
    },
    request: true,
  };
};

/* eslint-disable */

//tslint:disable

import { IModel, IModelResponse } from '@cian/http-api/shared/model';
import { IDocumentLibraryGroupSchema } from '../../entities/journal/DocumentLibraryGroupSchema';

export type TGetDocumentListModel = IModel<IGetDocumentListRequest, TGetDocumentListResponse>;

export interface IGetDocumentListRequest {
  /** Сортировка **/
  ordering?: EOrdering | null;
}

export type TGetDocumentListResponse = IGetDocumentListResponse200 | IGetDocumentListResponse400;

export interface IGetDocumentListResponse200 extends IModelResponse<IGetDocumentListResponse> {
  statusCode: 200;
}

export interface IGetDocumentListResponse400 extends IModelResponse<IGetDocumentListResponseError> {
  statusCode: 400;
}

export interface IGetDocumentListResponse {
  /** Список групп **/
  data: IDocumentLibraryGroupSchema[];
}

export interface IGetDocumentListResponseError {
  errors: any[];
  message: string;
}

export type TResponses = IGetDocumentListResponse | IGetDocumentListResponseError;

export enum EOrdering {
  /** Обсуждаемое **/
  Comments_count = 'comments_count',
  /** Дата публикации **/
  Date_publish = 'date_publish',
  /** Популярное **/
  Num_views = 'num_views',
}

export interface IMappers<TResponse200, TResponse400> {
  200(response: IGetDocumentListResponse): TResponse200;
  400(response: IGetDocumentListResponseError): TResponse400;
}

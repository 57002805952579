import { TThunkAction } from '../../../types/redux';
import { getPostLoading } from './getPostLoading';
import { getPostSucceed } from './getPostSucceed';
import { getPostFailed } from './getPostFailed';
import { fetchGetJournal, TGetJournalResponse } from '../../../repositories/journal/v1/get-journal';
import { throwError } from '../../../utils';

interface IGetPostParams {
  id: number;
}

export const getPost = ({ id }: IGetPostParams): TThunkAction<Promise<void>> => {
  return async (dispatch, getState, context) => {
    const { httpApi, logger } = context;

    dispatch(getPostLoading());

    try {
      const { response, statusCode }: TGetJournalResponse = await fetchGetJournal({
        httpApi,
        parameters: {
          id,
        },
      });

      if (statusCode !== 200) {
        throwError({ message: 'Не удалось загрузить пост', statusCode, domain: 'actions.getPost' });

        return;
      }

      dispatch(getPostSucceed(response.data));
    } catch (e) {
      logger.error(e, {
        domain: 'actions.getPost',
      });

      dispatch(getPostFailed());
    }
  };
};

import { HttpBadStatusCodeError } from '@cian/peperrors/shared';

import { TThunkAction } from '../../../types/redux';
import { getSimilarArticlesLoading } from './getSimilarArticlesLoading';
import { getSimilarArticlesSucceed } from './getSimilarArticlesSucceed';
import { getSimilarArticlesFailed } from './getSimilarArticlesFailed';
import {
  fetchGetJournalMore,
  TGetJournalMoreResponse,
  IGetJournalMoreRequest,
} from '../../../repositories/journal/v1/get-journal-more';

export const getSimilarArticles = ({
  journalIds,
}: Pick<IGetJournalMoreRequest, 'journalIds'>): TThunkAction<Promise<void>> => {
  return async (dispatch, getState, context) => {
    const { httpApi, logger } = context;
    const {
      userNew: { isB2BModeEnabled },
    } = getState();

    dispatch(getSimilarArticlesLoading());

    try {
      const { response, statusCode }: TGetJournalMoreResponse = await fetchGetJournalMore({
        httpApi,
        parameters: {
          b2b: isB2BModeEnabled,
          journalIds,
        },
      });

      if (statusCode !== 200 || !response?.data) {
        await Promise.reject(
          new HttpBadStatusCodeError('Не удалось загрузить подобные статьи', statusCode, 'actions.getSimilarArticles'),
        );

        return;
      }

      dispatch(getSimilarArticlesSucceed(response.data));
    } catch (e) {
      logger.error(e, {
        domain: 'actions.getSimilarArticles',
      });

      dispatch(getSimilarArticlesFailed());
    }
  };
};

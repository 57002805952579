import { IRegion } from '../../shared/types/posts';

export function getPrioritizedRegion(regions: IRegion[], region?: string): IRegion | null {
  if (regions.length === 0) {
    return null;
  }

  if (regions.length === 1) {
    return regions[0];
  }

  return regions.find((reg) => String(reg.id) === region) || regions[0];
}

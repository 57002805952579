import { IConfig, IConfigAction } from '../../../types/config';

export const SET_CONFIG = 'digest/SET_CONFIG';
export const TOOGLE_SEARCH = 'digest/TOGGLE_SEARCH';
export const TOOGLE_REGIONS = 'digest/TOOGLE_REGIONS';
export const SET_REGION = 'digest/SET_REGION';
export const GET_REGIONS_BY_DOMAIN = 'get_regions_by_domain/ACTION';
export const GET_REGIONS_BY_DOMAIN_REQUEST = 'get_regions_by_domain/ACTION_REQUEST';
export const GET_REGIONS_BY_DOMAIN_SUCCESS = 'get_regions_by_domain/ACTION_SUCCESS';
export const GET_REGIONS_BY_DOMAIN_FAILURE = 'get_regions_by_domain/ACTION_FAILURE';

const initialState: IConfig = {
  regionsApiScheme: '',
  regionsApiHost: '',
  regionsApiSchemeNode: '',
  regionsApiHostNode: '',
  regionID: '',
  regionName: '',
  searchShown: false,
  regionsShown: false,
  subdomain: 'www',
};

const defaultAction: IConfigAction = {
  type: '',
  payload: {
    regionsApiScheme: '',
    regionsApiHost: '',
    regionsApiSchemeNode: '',
    regionsApiHostNode: '',
    regionID: '',
    searchShown: false,
    regionsShown: false,
  },
};

export const configReducer = (state = initialState, action = defaultAction) => {
  switch (action.type) {
    case SET_CONFIG:
      return Object.assign({}, state, {
        regionsApiScheme: action.payload.regionsApiScheme,
        regionsApiHost: action.payload.regionsApiHost,
        regionsApiSchemeNode: action.payload.regionsApiSchemeNode,
        regionsApiHostNode: action.payload.regionsApiHostNode,
      });

    case TOOGLE_SEARCH:
      return Object.assign({}, state, {
        searchShown: action.payload.searchShown,
      });

    case TOOGLE_REGIONS:
      return Object.assign({}, state, {
        regionsShown: action.payload.regionsShown,
      });

    case SET_REGION:
      return Object.assign({}, state, {
        regionID: action.payload.regionID,
        regionName: action.payload.regionName,
      });

    case GET_REGIONS_BY_DOMAIN_SUCCESS:
      const data = (action.payload as any).data;
      let regionID = data.id;
      let regionName = data.displayName;

      if (data.mainTownId === 1) {
        regionID = data.mainTownId;
        regionName = 'Москва и МО';
      } else if (data.mainTownId === 2) {
        regionID = data.mainTownId;
        regionName = 'Санкт-Петербург и ЛО';
      }

      return Object.assign({}, state, {
        regionID,
        regionName,
      });

    default:
      return state;
  }
};

export const setConfig = (scheme: string, host: string): IConfigAction => {
  return {
    type: SET_CONFIG,
    payload: {
      regionsApiScheme: scheme,
      regionsApiHost: host,
    },
  };
};

export const setConfigNode = (scheme: string, host: string): IConfigAction => {
  return {
    type: SET_CONFIG,
    payload: {
      regionsApiSchemeNode: scheme,
      regionsApiHostNode: host,
    },
  };
};

export const toggleSearch = (searchShown: boolean): IConfigAction => {
  return {
    type: TOOGLE_SEARCH,
    payload: {
      searchShown,
    },
  };
};

export const toggleRegions = (regionsShown: boolean): IConfigAction => {
  return {
    type: TOOGLE_REGIONS,
    payload: {
      regionsShown,
    },
  };
};

export const updateRegion = (regionID: string, regionName: string): IConfigAction => {
  return {
    type: SET_REGION,
    payload: {
      regionID,
      regionName,
    },
  };
};

export const getRegionsByDomain = (scheme: string, host: string, domain: string) => {
  return {
    type: GET_REGIONS_BY_DOMAIN,
    method: 'GET',
    payload: {
      scheme,
      host,
      pathname: `/cian-api/site/v1/get-region-by-domain/`,
      params: [{
        name: 'domain',
        value: domain,
      }],
      basePath: '',
    },
    request: true,
  };
};

import { createSelector } from 'reselect';

import { IApplicationState } from '../../types/redux';
import { PageMetaDefault, PageMetaPostCard } from '../../constants';
import { isPostCardPath } from '../../utils';

/**
 * Выбирает pageMeta для текущего pathname
 */
export const selectCurrentPageMeta = createSelector(
  (state: IApplicationState) => state.pagesMeta.items,
  (_: IApplicationState, pathname: string) => pathname,
  (pagesMeta, pathname) => {
    /** Для карточки поста возвращаем мету в виде статики */
    if (isPostCardPath(pathname)) {
      return PageMetaPostCard;
    }

    const pageMeta = pagesMeta.find(pageMeta => pageMeta.pathname === pathname);

    return pageMeta || PageMetaDefault;
  },
);

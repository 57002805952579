import React from 'react';
import { Locations16 as IconLocation16 } from '@cian/ui-kit/icons';
import { useDeviceType } from '@cian/ui-kit';
import { useDispatch, useSelector } from 'react-redux';

import { toggleRegions } from '../../../reducers/modules/config/config';
import { TDispatch } from '../../../types/redux';
import { ReactPortal } from '../../../components/ReactPortal';
import { Regions as RegionsModal } from '../Regions';
import { selectIsRegionsModalShown } from '../../../selectors/geo/isRegionsModalShown';
import { selectCurrentRegion } from '../../../selectors/geo/currentRegion';
import * as s from './RegionsButtonContainer.css';

/**
 * Кнопка вызова модалки с регионами
 */
export const RegionsButtonContainer = () => {
  const dispatch = useDispatch<TDispatch>();
  const isRegionsModalShown = useSelector(selectIsRegionsModalShown);
  const currentRegion = useSelector(selectCurrentRegion);
  const deviceType = useDeviceType();
  const isPhone = () => deviceType === 'phone';

  const handleClick = () => {
    dispatch(toggleRegions(true));
  };

  return (
    <>
      {!isPhone() && (
        <div className={`${s['wrapper']}`} onClick={handleClick}>
          <IconLocation16 />
          <span className={`${s['text']}`}>{currentRegion.name}</span>
        </div>
      )}

      <ReactPortal wrapperId={'regions-modal'}>{isRegionsModalShown && <RegionsModal />}</ReactPortal>
    </>
  );
};

import { ITypedReduxAction } from '../../../types/redux/actionType';
import { TUserState } from '../../../types/user';

export enum EGetUserActionTypes {
  Loading = 'user/getUser/loading',
  Succeed = 'user/getUser/succeed',
  Failed = 'user/getUser/failed',
}

export type TGetUserLoading = ITypedReduxAction<EGetUserActionTypes.Loading>;
export type TGetUserSucceed = ITypedReduxAction<EGetUserActionTypes.Succeed, TUserState>;
export type TGetUserFailed = ITypedReduxAction<EGetUserActionTypes.Failed>;

export type TGetUser = TGetUserLoading | TGetUserSucceed | TGetUserFailed;

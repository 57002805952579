import * as React from 'react';

import s from './social_network_share.css';

interface IProps {
  canComment: boolean;
  canSeeComments: boolean;
  isProfessionalAudience: boolean;
  telegramLink: string;
  rightAligned: boolean;
  cardType: string;
  onScrollCommentForm(): void;
}

interface IState {
  mounted: boolean;
}

const openNewWindow = (url: string) => {
  return (e: any) => {
    e.preventDefault();
    window.open(url, e.target, 'scrollbars=1, width=650, height=570');
  };
};

export class SocialNetworkShare extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      mounted: false,
    };
  }

  componentDidMount() {
    this.setState({ mounted: true });
  }

  public render() {
    // Рендерим только на клиенте
    if (!this.state.mounted) {
      return null;
    }

    const url = document.URL;
    let image = '';
    const metas = document.getElementsByTagName('meta');

    [].forEach.call(metas, (meta: HTMLElement) => {
      const property = meta.getAttribute('property');
      const content = meta.getAttribute('content');

      switch (property) {
        case 'og:image':
          image = content || '';
          break;

        default:
          break;
      }
    });

    const vkUrl = `https://vk.com/share.php?url=${url}`;
    const okUrl = `https://connect.ok.ru/offer?url=${url}&imageUrl=${image}`;

    return (
      <div className={s['social-network']}>
        {this.props.canSeeComments && <>
          {
            this.props.canComment
              ? <button
                className={s['social-network__comment-btn']}
                onClick={this.props.onScrollCommentForm}>
                {this.getButtonText()}
              </button>
              : <div className={s['social-network__comment-canot']}>Приём ответов завершён</div>
          }
        </>}
        { this.props.children }
        <div
            className={`
              ${s['social-network__icons']}
              ${this.props.rightAligned ? s['social-network__icons_aligned'] : ''}
            `}>
          <a
              href={this.props.telegramLink}
              target="_blank"
              className={s['social-network__icons_tg']} />
          <a
              href={vkUrl}
              target="_blank"
              className={s['social-network__icons_vk']}
              onClick={openNewWindow(vkUrl)} />
          {
            !this.props.isProfessionalAudience && <a
              href={okUrl}
              target="_blank"
              className={s['social-network__icons_ok']}
              onClick={openNewWindow(okUrl)} />
          }
        </div>
      </div>
    );
  }

  private getButtonText() {
    const cardType = this.props.cardType;

    switch (cardType.toLowerCase()) {
      case 'articles':
      case 'news':
      case 'blog':
        return 'Комментировать';

      case 'question':
        return 'Ответить';

      default:
        return 'Комментировать';
    }
  }
}

import React from 'react';
import { useDeviceType } from '@cian/ui-kit';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { DownloadAllDocuments } from '../ListingHeaderFilters';
import { BreadCrumbsContainer } from '../BreadCrumbs';
import { TagScrollingContainer } from '../TagScrolling';
import { IApplicationState } from '../../types/redux';
import { selectCurrentPageMeta } from '../../selectors/pagesMeta';
import { SearchButtonContainer } from '../Search/SearchButton';
import { RegionsButtonContainer } from '../Regions/RegionsButton';
import * as s from './ListingHeaderContainer.css';

/**
 * Шапка листинга для документов
 */
export const DocumentListingHeaderContainer = () => {
  const deviceType = useDeviceType();
  const isPhone = () => deviceType === 'phone';
  const isDesktop = () => deviceType === 'desktop';
  const { pathname } = useLocation();
  const { title } = useSelector((state: IApplicationState) => selectCurrentPageMeta(state, pathname));

  return (
    <div className={s['wrapper']}>
      {/* Кнопки поиска и выбора региона */}
      <div className={`${s['search-and-regions-wrapper']} ${isDesktop() ? s['_active'] : ''}`}>
        <SearchButtonContainer />
        <RegionsButtonContainer />
      </div>

      {/* Отступ, если нет кнопок поиска и выбора региона */}
      {!isDesktop() && <div className={s['top-margin']} />}

      {/* Слайдер тегов */}
      <div className={s['tag-scrolling-wrapper']}>
        <TagScrollingContainer />
      </div>

      {/* Хлебные крошки */}
      {!isPhone() && (
        <div className={s['bread-crumbs-wrapper']}>
          <BreadCrumbsContainer />
        </div>
      )}

      <div className={s['group-wrapper']}>
        {title && <h1 className={s['title']}>{title}</h1>}

        {/* Кнопка "Скачать все документы" */}
        <DownloadAllDocuments />
      </div>
    </div>
  );
};

import { HttpBadStatusCodeError } from '@cian/peperrors/shared';

import { TThunkAction } from '../../../types/redux';
import { getTagsLoading } from './getTagsLoading';
import { getTagsSucceed } from './getTagsSucceed';
import { getTagsFailed } from './getTagsFailed';
import { fetchGetTags, IGetTagsRequest, TGetTagsResponse } from '../../../repositories/journal/v1/get-tags';

type TGetTagsParams = Pick<IGetTagsRequest, 'pageType' | 'rubricId'>;

export const getTags = ({ pageType, rubricId }: TGetTagsParams): TThunkAction<Promise<void>> => {
  return async (dispatch, getState, context) => {
    const { httpApi, logger } = context;
    const {
      userNew: { isB2BModeEnabled },
    } = getState();

    dispatch(getTagsLoading());

    try {
      const { response, statusCode }: TGetTagsResponse = await fetchGetTags({
        httpApi,
        parameters: {
          b2b: isB2BModeEnabled,
          pageType,
          rubricId,
        },
      });

      if (statusCode !== 200 || !response?.data) {
        await Promise.reject(new HttpBadStatusCodeError('Не удалось загрузить тэги', statusCode, 'actions.getTags'));

        return;
      }

      dispatch(getTagsSucceed(response.data));
    } catch (e) {
      logger.error(e, {
        domain: 'actions.getTags',
      });

      dispatch(getTagsFailed());
    }
  };
};

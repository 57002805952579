import { ERequestStatus } from '../../../types/requestStatus';
import { TRecommendedOfferItemsState } from '../../../types/recommendedOffers';
import {
  EGetOffersFromRecommendedApiActionType,
  TGetOffersFromRecommendedApi,
} from '../../../actions/recommendedOffers';

export const defaultState: TRecommendedOfferItemsState = {
  status: ERequestStatus.Initial,
  items: [],
};

export const offersFromRecommendedApiReducer = (state = defaultState, action: TGetOffersFromRecommendedApi) => {
  switch (action.type) {
    case EGetOffersFromRecommendedApiActionType.Loading:
      return { ...state, status: ERequestStatus.Loading };

    case EGetOffersFromRecommendedApiActionType.Succeed:
      return { ...state, items: action.payload, status: ERequestStatus.Succeed };

    case EGetOffersFromRecommendedApiActionType.Failed:
      return { ...state, status: ERequestStatus.Failed };

    default:
      return state;
  }
};

import * as React from 'react';

export const IconClose24 = () => {
  return (
    // eslint-disable-next-line react/forbid-elements
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M20 5.41L18.59 4L12 10.59L5.41 4L4 5.41L10.59 12L4 18.59L5.41 20L12 13.41L18.59 20L20 18.59L13.41 12L20 5.41Z"
        fill="#707A95"
      />
    </svg>
  );
};

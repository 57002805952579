import * as React from 'react';
import { useSelector } from 'react-redux';
import { useDeviceType } from '@cian/ui-kit';
import { useLocation } from 'react-router-dom';

import { RoutesNew } from '../Routes';
import { selectSearchShown } from '../../selectors/searchShown';
import { MainMenuContainer } from '../MainMenu';
import { ReadTopicDesktop } from '../read_topic/read_topic';
import { SeoUrlsDesktop } from '../seo_urls/seo_urls';
import { AdfoxBannerMainPageFooter } from '../../components/AdfoxBanner';
import { DemandActivityPopup } from '../../components/realtor_demand/demand_activity_popup';
import { useMeta } from '../../utils';
import { selectCurrentPageMeta } from '../../selectors/pagesMeta';
import { IApplicationState } from '../../types/redux';
import s from './LayoutNew.css';

export const LayoutNew = () => {
  const isSearchShown = useSelector(selectSearchShown);
  const location = useLocation();
  const { pathname } = location;
  const { metaTitle, metaDescription, metaKeywords, metaImage, canonicalUrl } = useSelector(
    (state: IApplicationState) => selectCurrentPageMeta(state, pathname),
  );
  const deviceType = useDeviceType();
  const isDesktop = () => deviceType === 'desktop';
  const isPhone = () => deviceType === 'phone';

  useMeta([metaTitle, metaDescription, metaKeywords, canonicalUrl, metaImage]);

  return (
    <div className={`${s['sections-wrapper']} ${isSearchShown ? s['_is-search-shown'] : ''}`}>
      <section className={s['wrapper']}>
        <div className={s['sidebar']}>
          <MainMenuContainer />
        </div>

        <div className={s['main-area']}>
          <RoutesNew />
        </div>
      </section>

      {!isPhone() && (
        <section className={s['pre-footer']}>
          <div className={s['wrapper']}>
            <div className={s['offset-left']}>
              {isDesktop() && <ReadTopicDesktop />}
              {isDesktop() && <SeoUrlsDesktop />}
            </div>

            {isDesktop() && (
              <div className={s['adfox-banner-footer']}>
                <AdfoxBannerMainPageFooter />
              </div>
            )}
          </div>
        </section>
      )}

      <DemandActivityPopup pathname={pathname} />
    </div>
  );
};

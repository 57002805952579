import React, { memo } from 'react';
import { useDeviceType } from '@cian/ui-kit';
import { AdfoxBannerArticleFooterMobile } from './AdfoxBannerArticleFooterMobile';
import { AdfoxBannerArticleFooterDesktop } from './AdfoxBannerArticleFooterDesktop';

export const AdfoxBannerArticleFooter = memo(function AdfoxBannerArticleFooter() {
  const deviceType = useDeviceType();

  return deviceType === 'desktop' ? <AdfoxBannerArticleFooterDesktop /> : <AdfoxBannerArticleFooterMobile />;
});

import {
  TUserActions,
  ESetIsB2BModeEnabledActionType,
  EGetUserActionTypes,
  ESetLkTypeActionType,
  ESetIsB2BForbiddingMessageShownActionType,
  ESetUserDataActionType,
} from '../../actions/user';
import { TUserState } from '../../types/user';
import { ERequestStatus } from '../../types/requestStatus';

export const defaultState: TUserState = {
  status: ERequestStatus.Initial,
  isAuthenticated: false,
  isB2BModeEnabled: false,
  lkType: null,
  isB2BForbiddingMessageShown: false,
  blogsCount: 0,
  isBlock: false,
  isEmailConfirmed: false,
  journalSubscribes: [],
  rating: 0,
  roles: [],
};

export const userReducer = (state = defaultState, action: TUserActions) => {
  switch (action.type) {
    case EGetUserActionTypes.Loading:
      return {
        ...state,
        status: ERequestStatus.Loading,
      };

    case EGetUserActionTypes.Succeed:
      return {
        ...action.payload,
        status: ERequestStatus.Succeed,
      };

    case EGetUserActionTypes.Failed:
      return {
        ...state,
        status: ERequestStatus.Failed,
      };

    case ESetIsB2BModeEnabledActionType.Set:
      return { ...state, isB2BModeEnabled: action.payload };

    case ESetLkTypeActionType.Set:
      return { ...state, lkType: action.payload };

    case ESetIsB2BForbiddingMessageShownActionType.Set:
      return { ...state, isB2BForbiddingMessageShown: action.payload };

    case ESetUserDataActionType.Set:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};

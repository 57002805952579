import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useDeviceType } from '@cian/ui-kit';

import { selectMainArticles, selectTopMainArticle } from '../../selectors/mainArticles';
import { MainArticleDetailed } from '../../components/MainArticle';
import { ERequestStatus } from '../../types/requestStatus';
import { IArticlesMainAttributesRubricSchema } from '../../repositories/journal/entities/journal/ArticlesMainAttributesRubricSchema';
import { EType } from '../../repositories/journal/entities/journal/JournalListAttributesSchema';
import { buildPostUrl, useTimeoutEffect } from '../../utils';
import { selectListingAppendType } from '../../selectors/settings/selectListingAppendType';
import { Skeleton } from '../../components/Skeleton';

/** Главная статья и блок новостей на Главной */
export const MainArticleContainer = () => {
  const history = useHistory();
  const deviceType = useDeviceType();
  const isPhone = () => deviceType === 'phone';
  const mainArticle = useSelector(selectTopMainArticle);
  const appendType = useSelector(selectListingAppendType);
  const { status: articleListingStatus } = useSelector(selectMainArticles);
  const [isArticleSkeletonShown, setIsArticleSkeletonShown] = useState(
    articleListingStatus === ERequestStatus.Loading && appendType === 'set',
  );

  /** Создает небольшую задержку перед скрытием скелетона, когда элементов не найдено */
  useTimeoutEffect(() => {
    setIsArticleSkeletonShown(articleListingStatus === ERequestStatus.Loading && appendType === 'set');
  }, [!isArticleSkeletonShown || mainArticle ? 0 : 500]);

  /** Роутит по клику по рубрике */
  const handleRubricButtonClick = (url: IArticlesMainAttributesRubricSchema['url']) => {
    history.push(url);
  };

  /** Роутит по клику по статье */
  const handleArticleClick = (url: string) => {
    history.push(url);
  };

  if (!mainArticle && !isArticleSkeletonShown) {
    return null;
  }

  if (isArticleSkeletonShown) {
    return <Skeleton height={isPhone() ? '50vw' : '100%'} />;
  }

  return (
    <MainArticleDetailed
      title={mainArticle?.attributes.title || ''}
      image={mainArticle?.attributes.image}
      rubric={mainArticle?.attributes.rubrics?.length ? mainArticle.attributes.rubrics[0] : undefined}
      commentsCount={mainArticle?.attributes.commentsCount || 0}
      articleUrl={buildPostUrl({
        type: EType.Articles,
        slug: mainArticle?.attributes.slug || '',
        id: mainArticle?.id || 0,
      })}
      onClick={() =>
        handleArticleClick(
          buildPostUrl({
            type: EType.Articles,
            slug: mainArticle?.attributes.slug || '',
            id: mainArticle?.id || 0,
          }),
        )
      }
      onRubricButtonClick={() => handleRubricButtonClick(mainArticle?.attributes?.rubrics[0]?.url || '')}
    />
  );
};

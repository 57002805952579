import * as React from 'react';
import { Button } from "@cian/ui-kit";

import { Dialog } from '../dialog/dialog';

import s from './dialogs.css';

interface IProps {
  shown: boolean;
  onClose(): void;
  onDigestSubmit(email: string): void;
  onDigestReset(): void;
  email: string;
  isUnsubscribed: boolean;
  error?: string;
}

interface ISubState {
  value: string;
  emailValid?: boolean;
}

export class UnsubscribeDialog extends React.Component<IProps, ISubState> {
  public render() {
    return (
      <Dialog
          content={
            <div className={s['sub-dialog-content']}>
              <h2 className={`${s['sub-dialog-content-header']} ${s['desktop-content']}`}>
                Отписка от комментариев
              </h2>
              <div className={`${s['sub-dialog-content-text']} ${s['desktop-content']}`}>
                На {this.props.email} больше не будут приходить письма
              </div>
              <div className={s['sub-dialog-content-controls']}>
                { (this.props.error) ?
                  this.renderFail() :
                  (this.props.isUnsubscribed) ? this.renderSuccess() :
                    this.renderForm() }
              </div>
            </div>
          }
          shown={this.props.shown}
          onClose={() => this.props.onClose()}
      />);
  }

  private renderForm() {
    return (
      <div>
        <h2 className={`${s['sub-dialog-content-header']} ${s['mobile-content']}`}>
          Отписка от комментариев
        </h2>
        <div className={`${s['sub-dialog-content-text']} ${s['mobile-content']}`}>
          На {this.props.email} больше не будут приходить письма
        </div>
        <Button
          theme="fill_primary"
          size="XS"
          onClick={() => this.props.onDigestSubmit(this.props.email)}
        >
          Отписаться
        </Button>
      </div>
    );
  }

  private renderSuccess() {
    return (
      <div>
        Вы отписались от уведомлений о новых комментариях
      </div>
    );
  }

  private renderFail() {
    return (
      <div>
        <span className={`${s['c-digest-fail-text']} ${s['desktop-content']}`}>
          <b>Что-то пошло не так, попробуйте отписаться еще раз</b>
        </span>
        <h2
            className={`
              ${s['sub-dialog-content-header']}
              ${s['c-digest-fail-text']}
              ${s['mobile-content']}`}>
          Что-то пошло не так...
        </h2>
        <div
            className={`${s['sub-dialog-fail-text']} ${s['mobile-content']}`}>
          Попробуйте отписаться еще раз
        </div>
        <Button
          theme="fill_primary"
          size="XS"
          onClick={() => this.props.onDigestReset()}
        >
          Повторить
        </Button>
      </div>
    );
  }
}

import * as React from 'react';
import { useEffect } from 'react';
import { render } from 'react-dom';

import { IExtractAndReplaceYoutubeItem } from './extractAndReplaceYoutube';
import { YoutubeVideoPlayer } from '../../../components/YoutubeVideoPlayer';

/**
 * Рендерит ютюб-плеер
 */
export const useContentYoutubeVideos = (extractedYoutubeItems: Pick<IExtractAndReplaceYoutubeItem, 'id'>[]) => {
  useEffect(() => {
    extractedYoutubeItems.forEach(({ id }) => {
      render(<YoutubeVideoPlayer id={id} />, document.getElementById(id));
    });
  }, [extractedYoutubeItems]);
};

import { TThunkAction } from '../../../types/redux';
import {
  fetchLastComments,
  ILastCommentsRequest,
  TLastCommentsResponse,
} from '../../../repositories/journal/v1/last-comments';
import { getDiscussionsLoading } from './getDiscussionsLoading';
import { getDiscussionsSucceed } from './getDiscussionsSucceed';
import { getDiscussionsFailed } from './getDiscussionsFailed';
import { DISCUSSIONS_REQUEST_LIMIT } from '../../../constants';
import { throwError } from '../../../utils';

type TGetDiscussions = Pick<ILastCommentsRequest, 'limit'>;

export const getDiscussions = ({ limit = DISCUSSIONS_REQUEST_LIMIT }: TGetDiscussions = {}): TThunkAction<
  Promise<void>
> => {
  return async (dispatch, getState, context) => {
    const { httpApi, logger } = context;
    const {
      userNew: { isB2BModeEnabled },
    } = getState();

    dispatch(getDiscussionsLoading());

    try {
      const { response, statusCode }: TLastCommentsResponse = await fetchLastComments({
        httpApi,
        parameters: {
          b2b: isB2BModeEnabled,
          limit,
        },
      });

      if (statusCode !== 200) {
        throwError({ message: 'Не удалось загрузить дискуссии', domain: 'actions.getDiscussions', statusCode });

        return;
      }

      dispatch(getDiscussionsSucceed(response.data));
    } catch (e) {
      logger.error(e, {
        domain: 'actions.getDiscussions',
      });

      dispatch(getDiscussionsFailed());
    }
  };
};

import { createSelector } from 'reselect';

import { IApplicationState } from '../../types/redux';
import { selectCurrentPageMeta } from '../pagesMeta';
import { IBreadCrumbNew } from '../../types/bread_crumb';

export interface ISelectCurrentBreadCrumbsOptions {
  withTag?: string;
  search?: string;
  tag?: string;
  pathname: string;
}

/**
 * Строит и возвращает коллекцию "хлебных крошек"
 */
export const selectCurrentBreadCrumbs = createSelector(
  [
    (state: IApplicationState) => state.pagesMeta.items,
    (state: IApplicationState, options: ISelectCurrentBreadCrumbsOptions) =>
      selectCurrentPageMeta(state, options.pathname),
    (_: IApplicationState, options: ISelectCurrentBreadCrumbsOptions) => options,
  ],
  (pagesMeta, { pathname, breadCrumbName, breadCrumbParents }, options): IBreadCrumbNew[] => {
    const breadCrumbs = breadCrumbParents.reduce((acc, path) => {
      const { pathname, breadCrumbName } = pagesMeta.find(pageMeta => pageMeta.pathname === path) || {};

      if (pathname && breadCrumbName) {
        return [...acc, { pathname, breadCrumbName, isParent: true }];
      }

      return acc;
    }, []);

    // Псевдорубрика Статьи не должна попасть в хлебные крошки
    if (pathname !== '/stati/') {
      breadCrumbs.push({
        pathname,
        breadCrumbName,
        isParent: Boolean(options?.withTag) || Boolean(options?.search) || Boolean(options?.tag),
      });
    }

    if (options?.withTag) {
      breadCrumbs.push({
        pathname: '',
        breadCrumbName: options.withTag.charAt(0).toUpperCase() + options.withTag.slice(1),
        isParent: false,
      });
    } else if (options?.search) {
      breadCrumbs.push({
        pathname: '',
        breadCrumbName: options.search.charAt(0).toUpperCase() + options.search.slice(1),
        isParent: false,
      });
    } else if (options?.tag) {
      breadCrumbs.push({
        pathname: '',
        breadCrumbName: `#${options.tag}`,
        isParent: false,
      });
    }

    return breadCrumbs;
  },
);

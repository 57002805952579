import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { NewsSubscribeForm } from '../../components/SubscribeForms';
import { validateEmail } from '../../utils';
import { subscribe } from '../../reducers/modules/subscribe/subscribe';
import { selectIsSubscribed } from '../../selectors/subscription/selectIsSubscribed';
import { TDispatch } from '../../types/redux';

/** Форма подписки на новости */
export const NewsSubscribeFormContainer = () => {
  const [isInvalid, setIsInvalid] = useState(false);
  const isSubscribed = useSelector(selectIsSubscribed);
  const dispatch = useDispatch<TDispatch>();

  const handleSubmit = useCallback(
    (email: string) => {
      if (!validateEmail(email)) {
        setIsInvalid(true);

        return;
      }

      dispatch(subscribe(email));
    },
    [dispatch],
  );

  // В момент введения данных в инпут выключаем валидацию
  const handleChange = useCallback(() => {
    setIsInvalid(false);
  }, []);

  return (
    <NewsSubscribeForm
      onSubmit={handleSubmit}
      onChange={handleChange}
      isInvalid={isInvalid}
      isSubscribed={isSubscribed}
    />
  );
};

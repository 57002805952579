/* eslint-disable */
import moment from "moment";

const monthNames = [
  'января', 'февраля', 'марта',
  'апреля', 'мая', 'июня', 'июля',
  'августа', 'сентября', 'октября',
  'ноября', 'декабря',
];

export const formatViews = (num: number, minLength: number = 5, delimiter: string = ' ') => {
  const str = num.toString().split('.');
  if (str[0].length >= minLength) {
    str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, `$1${delimiter}`);
  }
  if (str[1] && str[1].length >= 5) {
    str[1] = str[1].replace(/(\d{3})/g, `$1${delimiter}`);
  }
  return str.join('.');
};

/**
 * Необходимо использовать plural из @cian/utils/lib/shared/plural
 * @deprecated
 */
export const declineNumeric = (num: number, titles: string[]) => {
  const cases = [2, 0, 1, 1, 1, 2];

  return titles[(num % 100 > 4 && num % 100 < 20) ? 2 : cases[(num % 10 < 5) ? num % 10 : 5]];
};

export const changeDotToComma = (num: number | string) => num.toString().replace('.', ',');

export const formatDate = (date: string) => {
  let newDate = moment(date);

  if (isNaN(newDate.valueOf())) {
    newDate = moment();
  }

  return newDate.date() + ' ' + monthNames[newDate.month()] + ' ' + newDate.year();
};

export const cropText = (text: string, limit: number) => {
  const ELLIPSIS = '...';

  if (text.length < limit) {
    return text;
  }
  let tokens = text.split(' ');
  text = '';
  while (text.length < limit - ELLIPSIS.length) {
    text += (text == '' ? '' : ' ') + tokens.shift();
  }
  while (text.endsWith('.') || text.endsWith(','))  {
    text = text.slice(0, -1);
  }
  return text + ELLIPSIS;
};

export const formatDateTodayYesterday = (date: string) => {
  const today = moment();
  const yesterday = moment().add(-1, 'days');
  const sentDate = moment(date);
  const minutes = sentDate.minutes() > 9 ? sentDate.minutes() : `0${sentDate.minutes()}`;
  const isToday = today.date() === sentDate.date() &&
    today.month() === sentDate.month() &&
    today.year() === sentDate.year();
  const isYesterday = yesterday.date() === sentDate.date() &&
    yesterday.month() === sentDate.month() &&
    yesterday.year() === sentDate.year();

  if (isToday) {
    return `сегодня в ${sentDate.hours()}:${minutes}`;
  } else if (isYesterday) {
    return `вчера в ${sentDate.hours()}:${minutes}`;
  } else {
    return formatDate(date);
  }
};

const formatDateWithTime = (date: string) => {
  let newDate = moment(date);

  if (isNaN(newDate.valueOf())) {
    newDate = moment();
  }

  const minutes = newDate.minutes() > 9 ? newDate.minutes() : `0${newDate.minutes()}`;
  return `${newDate.date()} ${monthNames[newDate.month()]} ${newDate.year()},
    ${newDate.hours()}:${minutes}`;
};

export const formatDateFull = (date: string) => {
  const today = moment();
  const yesterday = moment().add(-1, 'days');
  const sentDate = moment(date);
  const minutes = sentDate.minutes() > 9 ? sentDate.minutes() : `0${sentDate.minutes()}`;
  const isToday = today.date() === sentDate.date() &&
    today.month() === sentDate.month() &&
    today.year() === sentDate.year();
  const isYesterday = yesterday.date() === sentDate.date() &&
    yesterday.month() === sentDate.month() &&
    yesterday.year() === sentDate.year();

  if (isToday) {
    return `сегодня в ${sentDate.hours()}:${minutes}`;
  } else if (isYesterday) {
    return `вчера в ${sentDate.hours()}:${minutes}`;
  } else {
    return formatDateWithTime(date);
  }
};

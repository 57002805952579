import { ERequestStatus } from '../../types/requestStatus';
import { TMainNewsState } from '../../types/mainNews';
import { EGetMainNewsActionType, TMainNewsActions, EResetMainNewsActionType } from '../../actions/mainNews';

export const defaultState: TMainNewsState = {
  status: ERequestStatus.Initial,
  items: [],
};

export const mainNewsReducer = (state = defaultState, action: TMainNewsActions) => {
  switch (action.type) {
    case EGetMainNewsActionType.Loading:
      return { ...state, status: ERequestStatus.Loading };

    case EGetMainNewsActionType.Succeed:
      return { ...state, items: action.payload, status: ERequestStatus.Succeed };

    case EGetMainNewsActionType.Failed:
      return { ...state, status: ERequestStatus.Failed };

    case EResetMainNewsActionType.Reset:
      return defaultState;

    default:
      return state;
  }
};

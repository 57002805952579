import { useEffect, useMemo, useState } from 'react';

import type { AdfoxContextData } from '../../types';
import { PParams } from '../../../../../types';
import { calculatedParamsMiddleware, fetchTargets, mergeMiddlewares } from '@cian/adfox';

type Params = AdfoxContextData['params'];

let caughtPuds: Params | null = null;

export const PR_RANDOM = getRandomId();

export function getRandomId() {
  return String(Math.floor(Math.random() * 4294967295) + 1);
}

export function useAdfoxParams(pParams: PParams): Params | null {
  const [puids, setPuids] = useState<Params | null>(caughtPuds);

  useEffect(() => {
    if (!caughtPuds) {
      mergeMiddlewares(
        calculatedParamsMiddleware(),
        fetchTargets('apps', {
          baseUrl: `//${window.__mainpage_api_host__ || window.location.host}/`,
          section_type: 1,
        }),
      ).then(puids => {
        caughtPuds = puids;

        setPuids(puids);
      });
    }
  }, []);

  return useMemo((): Params | null => {
    if (!puids) {
      return null;
    }

    return {
      pr: PR_RANDOM,
      ...puids,
      ...pParams,
    };
  }, [pParams, puids]);
}

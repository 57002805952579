import * as React from 'react';
import { Button, useDeviceType } from '@cian/ui-kit';

import { IRegionsItem } from '../../../../types/regions';
import * as styles from './Tabs.css';

export interface ITabs {
  /** Коллекция регионов */
  regions: IRegionsItem[];
  /** Эвент события выбора региона */
  onClick(city: IRegionsItem): void;
}

export const Tabs: React.FC<ITabs> = ({ regions, onClick }) => {
  const deviceType = useDeviceType();

  return (
    <div className={styles['container']}>
      <div className={styles['tabs']}>
        {regions.map(region => (
          <Button
            key={region.id}
            theme="fill_secondary"
            size={deviceType === 'phone' ? 'M' : 'XS'}
            onClick={() => onClick(region)}
          >
            {region.displayName}
          </Button>
        ))}
      </div>
    </div>
  );
};

import { EffectCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { ERequestStatus } from '../../types/requestStatus';
import { useCurrentListing } from '../useCurrentListing';
import { selectListingAppendType } from '../../selectors/settings/selectListingAppendType';
import { setInitialPageNumber, setListingAppendType } from '../../actions/settings';
import { TDependencyName, useSensitiveParams } from '../useSensitiveParams';
import { selectInitialPageNumber } from '../../selectors/settings/selectInitialPageNumber';
import { preparePageNumber } from '../preparePageNumber';
import { useEffectOnce } from '../useEffectOnce';
import { correctPathname } from '../correctPathname';
import { IEventTrackingData, useEventTracking } from '../useEventTracking';

interface IUseGetListingOptions {
  dependencyNames?: TDependencyName[];
  eventTrackerData?: IEventTrackingData;
}

/**
 * Выполняет callback для запроса листинга
 */
export const useGetListing = (
  callback: EffectCallback,
  { dependencyNames = ['page'], eventTrackerData }: IUseGetListingOptions = {},
) => {
  const { search, pathname } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const qsPage = preparePageNumber(searchParams.get('page') as string);
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    status: listingStatus,
    meta: { offset, limit },
  } = useCurrentListing();
  const appendType = useSelector(selectListingAppendType);
  const key = useSensitiveParams(dependencyNames);
  const [prevKey, setPrevKey] = useState(key);
  const initialPageNumber = useSelector(selectInitialPageNumber);
  const { trackEvent, trackPageView } = useEventTracking(eventTrackerData);

  /**
   * 1. Корректирует page в qs, если реальный номер страницы
   * не соответствует page
   * 2. Однократно трекает открытие листинга.
   * Не трекает, если листинг в статусе инициализации
   * или реальный номер страницы отличается от page в qs,
   * чтобы не дублировать событие
   */
  useEffectOnce(() => {
    if (listingStatus === ERequestStatus.Initial) {
      return;
    }

    const realPage = offset / limit + 1;

    if (realPage === 1) {
      searchParams.delete('page');
    } else {
      searchParams.set('page', String(realPage));
    }

    if (realPage !== qsPage) {
      history.replace(`${correctPathname(pathname)}?${searchParams.toString()}`);
    } else {
      trackPageView();
    }
  });

  /**
   * Запускает callback, если хотя бы соблюдено одно из условий:
   * - список находится в статусе инициализации
   * - слепок значений из чувствительных ключей изменился
   * Трекает callback, различая действие смены/подгрузки страницы
   */
  useEffect(() => {
    if (listingStatus !== ERequestStatus.Initial && key === prevKey) {
      return;
    }

    /**
     * Переписывает индекс страницы инициализации
     * только для режима set
     */
    if (appendType === 'set') {
      dispatch(setInitialPageNumber(qsPage));
    }

    callback();
    setPrevKey(key);

    if (appendType === 'set') {
      trackPageView();
    } else {
      // Если подгружаем статьи, то отправляем событие newpage_infinite_search
      trackEvent({
        category: 'page',
        action: 'newpage_infinite_search',
        label: '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key, listingStatus, prevKey]);

  /**
   * Скролим наверх пока идет загрузка
   * (не для режима подгрузки)
   */
  useEffect(() => {
    if (listingStatus !== ERequestStatus.Loading) {
      return;
    }

    if (appendType === 'set') {
      window.scrollTo(0, 0);
    }
  }, [appendType, listingStatus]);

  /**
   * Сбрасываем тип сохранения материалов на дефолтный
   * в любом конечном статусе запроса
   */
  useEffect(() => {
    if (listingStatus === ERequestStatus.Loading) {
      return;
    }

    dispatch(setListingAppendType('set'));
  }, [dispatch, listingStatus]);

  /**
   * initialPageNumber не должен быть меньше qsPage
   */
  useEffect(() => {
    if (qsPage < initialPageNumber) {
      dispatch(setInitialPageNumber(qsPage));
    }
  }, [dispatch, initialPageNumber, qsPage]);
};

import { setCookie } from '@cian/utils/lib/browser/cookie';

import { TThunkAction } from '../../../types/redux';
import { EListingTypes } from '../../../types/listing';
import { setFavoriteListingType } from '../setFavoriteListingType';

export const saveFavoriteListingType = (type: EListingTypes): TThunkAction => {
  return dispatch => {
    const now = new Date();

    setCookie('favoriteListingType', type, {
      domain: process.env.NODE_ENV === 'development' ? '' : '.cian.ru',
      expires: new Date(now.setMonth(now.getMonth() + 12)),
    });

    dispatch(setFavoriteListingType(type));
  };
};

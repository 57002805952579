import { actionGenerator } from '../../../utils/redux/actionGenerator';
import { TGetOffersFromRecommendedApiSucceed, EGetOffersFromRecommendedApiActionType } from './types';
import { IRecommendedOfferSchema } from '../../../repositories/search-engine/entities/recommended_offers/RecommendedOfferSchema';

export const getOffersFromRecommendedApiSucceed: (
  offers: IRecommendedOfferSchema[],
) => TGetOffersFromRecommendedApiSucceed = actionGenerator<
  EGetOffersFromRecommendedApiActionType.Succeed,
  IRecommendedOfferSchema[]
>(EGetOffersFromRecommendedApiActionType.Succeed);

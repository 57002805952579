import * as React from 'react';
import { useEffect } from "react";
import { connect } from 'react-redux';
import { useLocation } from "react-router-dom";

import { ISeoData, ISeoItem } from '../../types/seo_data';
import { TDispatch } from "../../types/redux";

interface IProps {
  seo: ISeoItem;
  dispatch: TDispatch;
}

const SeoContainer: React.FC<IProps> = props => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  useEffect(() => {
    const {
      seoTitle,
      seoDescription,
      seoKeywords,
      seoUrl,
      seoImage,
    } = props.seo;
    const metaDesctiption = document.querySelector('meta[name="description"]') as HTMLMetaElement;
    const metaKeywords = document.querySelector('meta[name="keywords"]') as HTMLMetaElement;
    const linkCanonical = document.querySelector('link[rel="canonical"]') as HTMLLinkElement;

    const ogTitle = document.querySelector('meta[property="og:title"]') as HTMLMetaElement;
    const ogImage = document.querySelector('meta[property="og:image"]') as HTMLMetaElement;
    const ogDescription = document.querySelector('meta[property="og:description"]') as HTMLMetaElement;
    const ogUrl = document.querySelector('meta[property="og:url"]') as HTMLMetaElement;
    const twitterTitle = document.querySelector('meta[name="twitter:title"]') as HTMLMetaElement;
    const twitterImage = document.querySelector('meta[name="twitter:image"]') as HTMLMetaElement;
    const twitterDescription = document.querySelector('meta[name="twitter:description"]') as HTMLMetaElement;

    document.title = seoTitle;
    metaDesctiption.setAttribute('content', seoDescription);
    metaKeywords.setAttribute('content', seoKeywords);
    ogUrl.setAttribute('content', seoUrl);

    ogTitle.setAttribute('content', seoTitle);
    ogImage.setAttribute('content', seoImage || '');
    ogDescription.setAttribute('content', seoDescription);
    twitterTitle.setAttribute('content', seoTitle);
    twitterImage.setAttribute('content', seoImage || '');
    twitterDescription.setAttribute('content', seoDescription);

    if (query.get('page')) {
      if (linkCanonical) {
        linkCanonical.setAttribute('href', seoUrl);
      } else {
        const link = document.createElement('link');
        link.setAttribute('rel', 'canonical');
        link.setAttribute('href', seoUrl);
        document.head.appendChild(link);
      }
    } else {
      if (linkCanonical && linkCanonical.parentNode) {
        linkCanonical.parentNode.removeChild(linkCanonical);
      }
    }
  }, [props.seo])

  return <span />;
}

const SeoConnected = connect((state: { seoData: ISeoData }) => {
    return {
      seo: state.seoData.seo,
    };
  },
)(SeoContainer);

/** @deprecated */
export const Seo = SeoConnected;

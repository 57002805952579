import { IApplicationState } from '../../../types/redux';

/**
 * Включен ли режим b2b у пользователя
 */
export const selectIsB2BModeEnabled = ({ userNew }: IApplicationState) => {
  if (!userNew.isAuthenticated) {
    return false;
  }

  return userNew.isB2BModeEnabled;
};

import React, { useCallback } from 'react';
import { Image } from '@cian/ui-kit';

import { Card, ICardParams } from '../Card';
import { Underground, TUndergroundParams } from './components/Underground';
import * as s from './RecommendedOffer.css';

interface IRecommendedOfferParams {
  width?: ICardParams['width'];
  height?: ICardParams['height'];
  imageUrl?: string;
  imageLabel?: string;
  title: string;
  formattedPrice: string;
  offerUrl: string;
  address: string;
  underground?: TUndergroundParams;
  onClick(): void;
}

/**
 * Элемент списка подобных статей
 */
export const RecommendedOffer = ({
  width,
  height,
  imageUrl,
  title,
  formattedPrice,
  offerUrl,
  address,
  underground,
  onClick,
}: IRecommendedOfferParams) => {
  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      onClick();
    },
    [onClick],
  );

  return (
    <Card width={width} height={height} onClick={handleClick}>
      <a
        href={offerUrl}
        className={s['image-wrapper']}
        title={title}
        onClick={handleClick}
        target={'_blank'}
        rel="noreferrer"
      >
        <Image
          alt={title}
          borderRadius={0}
          objectFit={'cover'}
          preloader
          src={imageUrl || ''}
          theme="light"
          title={title}
          height={160}
        />
      </a>

      <a href={offerUrl} className={s['content-wrapper']} onClick={handleClick} target={'_blank'} rel="noreferrer">
        <div className={`${s['price']}`} title={formattedPrice}>
          {formattedPrice}
        </div>

        <div className={s['title']} title={title}>
          {title}
        </div>

        {underground && underground.name && (
          <div className={s['underground-wrapper']}>
            <Underground {...underground} />
          </div>
        )}

        <div className={`${s['address']} ${underground && underground.name ? s['_two-strings'] : ''}`} title={address}>
          {address}
        </div>
      </a>
    </Card>
  );
};

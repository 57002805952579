import React from 'react';
import { Link } from 'react-router-dom';
import { UIText1 } from '@cian/ui-kit';

import { AccessDeniedHeaderContainer } from '../ListingHeader';
import * as s from './AccessDeniedPages.css';

/** Запрещающая страница для рейтинга (<50) */
export const RatingAccessDeniedPageContainer = () => {
  return (
    <div className={s['wrapper']}>
      <div className={s['header-wrapper']}>
        <AccessDeniedHeaderContainer />
      </div>

      <UIText1>В этом разделе обсуждение ведут специалисты с рейтингом больше 50.</UIText1>

      <UIText1>
        Хотите сюда попасть? Публикуйте объявления, отвечайте на{' '}
        <Link className={s['link']} to={'/forum-rieltorov/'}>
          вопросы
        </Link>
        , заведите интересный блог.
      </UIText1>

      <UIText1>
        <Link className={s['link']} to={'/reyting-rieltorov/'}>
          Правила начисления рейтинга
        </Link>
      </UIText1>
    </div>
  );
};

import * as React from 'react';
import { Switch, ICheckboxProps } from '@cian/ui-kit';

import * as s from './B2BModeSwitcher.css';

type TB2BModeSwitcherParams = Pick<ICheckboxProps, 'checked' | 'onChange'>;

/**
 * Переключатель режима Про (b2b/b2c)
 */
export const B2BModeSwitcher = (props: TB2BModeSwitcherParams) => {
  return (
    <div className={`${s['wrapper']}`}>
      <div>
        <div className={`${s['title']}`}>Циан.Журнал Pro</div>
        <div className={`${s['description']}`}>Всё для профи</div>
      </div>

      <div className={`${s['switch-wrapper']}`}>
        <Switch {...props} />
      </div>
    </div>
  );
};

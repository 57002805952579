/* eslint-disable */
//tslint:disable

export interface IJournalListAttributesSchema {
  /** На правах рекламы **/
  advertising: boolean;
  /** Автор **/
  author?: string | null;
  /** Описание блокировки **/
  blockDescription?: string | null;
  /** Причина блокировки **/
  blockReason?: string | null;
  /** Категория **/
  category?: string | null;
  /** Количество комментарий **/
  commentsCount: number;
  /** Дата публикации **/
  datePublish: string;
  /** Количество дизлайков **/
  dislikesCount: number;
  /** Ссылка на изображение **/
  image?: string | null;
  /** Ссылка на миниатюру **/
  imageThumbnail?: string | null;
  /** Ссылка на миниатюру для мобильных устройств **/
  imageThumbnailMobile?: string | null;
  /** Количество лайков **/
  likesCount: number;
  /** Без индекса **/
  noIndex?: boolean | null;
  /** Количество просмотров **/
  numViews: number;
  /** Регионы **/
  regions: number[];
  /** Рубрики (для статей) **/
  rubrics: number[];
  /** Слаг **/
  slug: string;
  /** Подзаголовок **/
  subtitle?: string | null;
  /** Заголовок **/
  title: string;
  /** Тип **/
  type: EType;
  /** Количество голосов **/
  votesCount: number;
}

export enum EType {
  /** Статьи **/
  Articles = 'articles',
  /** Блоги **/
  Blogs = 'blogs',
  /** 'Новости **/
  News = 'news',
  /** Вопросы **/
  Questions = 'questions',
  /** Опросы **/
  Quizzes = 'quizzes',
}

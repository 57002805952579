import { ITypedReduxAction } from '../../../types/redux/actionType';
import { IBlogList } from '../../../types/blogList';

export enum EGetBlogListActionType {
  Loading = 'blogList/getBlogList/loading',
  Succeed = 'blogList/getBlogList/succeed',
  AppendSucceed = 'blogList/getBlogList/appendSucceed',
  Failed = 'blogList/getBlogList/failed',
}

export type TGetBlogListLoading = ITypedReduxAction<EGetBlogListActionType.Loading>;
export type TGetBlogListSucceed = ITypedReduxAction<EGetBlogListActionType.Succeed, IBlogList>;
export type TGetBlogListAppendSucceed = ITypedReduxAction<EGetBlogListActionType.AppendSucceed, IBlogList>;
export type TGetBlogListFailed = ITypedReduxAction<EGetBlogListActionType.Failed>;

export type TGetBlogList = TGetBlogListLoading | TGetBlogListSucceed | TGetBlogListAppendSucceed | TGetBlogListFailed;

import React, { useCallback, useEffect, useRef, useState } from 'react';

import * as s from './PostBannerDecorator.css';

export interface IPostBannerDecoratorParams {
  children: React.ReactNode;
  onClick(link: string): void;
}

/**
 * Декоратор для баннера
 * Устанавливает клик на враппере баннера
 */
export const PostBannerDecorator = ({ children, onClick }: IPostBannerDecoratorParams) => {
  const bannerRef = useRef<HTMLDivElement>(null);
  const [url, setUrl] = useState('');

  /**
   * Открывает ссылку в отдельном окне
   * Отправляет родителю событие клика
   */
  const handleClick = useCallback(() => {
    if (!bannerRef?.current) {
      return;
    }

    window.open(url, '_blank');

    onClick(url);
  }, [url, onClick]);

  /**
   * Перехватывает клик по нативной ссылке и блокирует его
   * Сохраняет url из нативной ссылки
   */
  useEffect(() => {
    if (!bannerRef?.current) {
      return;
    }

    const linkElement = bannerRef.current.querySelector('a');

    if (!linkElement) {
      return;
    }

    setUrl(linkElement.getAttribute('href') || '');

    linkElement.addEventListener('click', e => e.preventDefault());

    return () => {
      linkElement.removeEventListener('click', e => e.preventDefault());
    };
  }, []);

  return (
    <div ref={bannerRef} onClick={handleClick} className={s['wrapper']}>
      {children}
    </div>
  );
};

import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDeviceType, UIHeading1, UIHeading2 } from '@cian/ui-kit';
import { useHistory } from 'react-router-dom';

import { Scrolling, ScrollingWithControls } from '../../components/Scrolling';
import { IApplicationState, TThunkDispatch } from '../../types/redux';
import { getSlider } from '../../actions/slider';
import { ERequestStatus } from '../../types/requestStatus';
import { ApplicationContext, correctPathname, useWindowSize, buildPostUrl, useEventTracking } from '../../utils';
import { selectSlider } from '../../selectors/sliders';
import { MainPageSlide } from '../../components/Slides';
import * as s from './MainPageSliderContainer.css';

/** Скроллинг тэгов для мобилки и десктопа */
export const MainPageSliderContainer = () => {
  const dispatch = useDispatch<TThunkDispatch>();
  const {
    custom: { mainPageSliderName = '' },
  } = useContext(ApplicationContext);
  const { status, items, title } = useSelector((state: IApplicationState) => selectSlider(state, mainPageSliderName));
  const deviceType = useDeviceType();
  const isPhone = () => deviceType === 'phone';
  const { width: windowWidth } = useWindowSize();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [slideWidth, setSlideWidth] = useState(0);
  const history = useHistory();
  const { trackEvent } = useEventTracking();

  /** Запрашивает слайдер, если еще не был запрошен */
  useEffect(() => {
    if (status !== ERequestStatus.Initial || !mainPageSliderName) {
      return;
    }

    dispatch(getSlider({ name: mainPageSliderName })).finally();
  }, [dispatch, mainPageSliderName, status]);

  /**
   * Вычисляет ширину слайда
   * Важно! Повторно вычислять необходимо,
   * если слайдер был запрошен с клиента
   */
  useEffect(() => {
    if (!wrapperRef?.current || !items.length) {
      return;
    }

    const { width: wrapperWidth } = wrapperRef.current.getBoundingClientRect();

    if (deviceType === 'desktop') {
      // 20 - расстояние между слайдами
      setSlideWidth((wrapperWidth + 20) / 3);
    } else if (deviceType === 'tablet') {
      // 20 - расстояние между слайдами
      setSlideWidth((wrapperWidth + 20) / 2);
    } else {
      // 8 - расстояние между слайдами
      setSlideWidth(288 + 8);
    }
  }, [windowWidth, deviceType, items]);

  /** Роутит по клику по рубрике */
  const handleRubricClick = (url: string) => {
    if (!url) {
      return;
    }

    history.push(url);
  };

  /**
   * Роутит по клику по статье
   * Трекает событие
   */
  const handleSlideClick = (url: string, index: number) => {
    trackEvent({
      category: 'Magazine',
      action: 'click_slider',
      label: `https://www.cian.ru${url}`,
      page: {
        extra: {
          type: index + 1,
        },
      },
    });

    history.push(url);
  };

  if (!items.length) {
    return null;
  }

  const itemsMap = items.map(({ title, image, rubric, type, slug, journalId, color }, index) => (
    <div key={`slide-${index}`} className={s['slide-wrapper']} style={{ width: slideWidth }}>
      <MainPageSlide
        title={title}
        color={color}
        image={image}
        rubricName={rubric?.name || undefined}
        rubricUrl={rubric?.link || undefined}
        url={buildPostUrl({ type, slug, id: journalId })}
        onSlideClick={() => handleSlideClick(buildPostUrl({ type, slug, id: journalId }), index)}
        onRubricClick={() => handleRubricClick(rubric?.link ? correctPathname(rubric.link) : '')}
      />
    </div>
  ));

  return (
    <div className={s['wrapper']} ref={wrapperRef}>
      <div className={s['title']}>
        {isPhone() ? <UIHeading2 as={'h2'}>{title}</UIHeading2> : <UIHeading1 as={'h2'}>{title}</UIHeading1>}
      </div>

      {isPhone() ? (
        <div className={s['scrolling-wrapper']}>
          <Scrolling>
            <div className={s['scrolling-inner-wrapper']}>{itemsMap}</div>
          </Scrolling>
        </div>
      ) : (
        <ScrollingWithControls controlsType={'absolute'} controlsSize={'M'} scrollLength={slideWidth}>
          <div className={s['scrolling-inner-wrapper']}>{itemsMap}</div>
        </ScrollingWithControls>
      )}
    </div>
  );
};

import React, { useEffect, useState } from 'react';
import { Input } from '@cian/ui-kit';
import { mergeStyles } from '@cian/utils';

import { ISubscribe } from '../../types/subscribe';
import { IEvent } from '../../types/event';
import { validateEmail } from '../../utils/validateEmail';
import * as Analytics from '../../../app/helpers/analytics_helper';
import { ISubmitDigestResponse } from '../../reducers/modules/subscribe/subscribe';
import { IUser } from '../../types/user';

import s from './subscribe.css';

const fakeEmailRex = /@social\.cian\.ru$/;

export interface ISubscribeProps {
  user: IUser;
  subscribe(email: string): Promise<ISubmitDigestResponse | void>;
  subscribeStatus: ISubscribe;
}

export const Subscribe: React.FC<ISubscribeProps> = props => {
  const [isValid, setIsValid] = useState(true);
  const [errorText, setErrorText] = useState('');
  const [isEmailSend, setIsEmailSend] = useState(false);
  const [subscribeDone, setSubscribeDone] = useState(false);
  const [emailValue, setEmailValue] = useState(fakeEmailRex.test(props.user.email) ? '' : props.user.email || '');

  function renderResponseSuccess() {
    return (
      <div className={s['subscribe-done']}>
        <span className={s['subscribe-done__header']}>
          <span className={s['subscribe-done-header__icon']} />
          <span className={s['subscribe-done__status']}>Подписка оформлена</span>
        </span>
        <span className={s['subscribe-done__text']}>Каждую неделю будем сообщать  о важном в мире недвижимости</span>
      </div>
    );
  }

  function renderLicense() {
    return (
      <div className={s['subscribe-licence']}>
        <span>При подписке вы принимаете условия </span>
        <a target="_blank" className={s['subscribe-link']} href="//www.cian.ru/help/about/rules/" rel="noreferrer">
          Пользовательского соглашения
        </a>
        <span> и </span>
        <a target="_blank" href="//www.cian.ru/help/about/36526/" className={s['subscribe-link']} rel="noreferrer">
          Политики конфиденциальности
        </a>
      </div>
    );
  }

  function renderResponse() {
    return subscribeDone ? renderResponseSuccess() : renderResponseError();
  }

  function renderResponseError() {
    return (
      <div className={s['subscribe-done']}>
        <span className={s['subscribe-done__header']}>
          <span className={s['subscribe-err-header__icon']} />
          <span className={s['subscribe-done__status']}>Что-то пошло не так, попробуйте подписаться еще раз</span>
        </span>
        <button className={s['subscribe-done__replay']} onClick={subscribeReplay}>
          Повторить
        </button>
      </div>
    );
  }

  function renderForm() {
    const fieldErrorClassName = !isValid
      ? `${s['subscribe-email__val-error']} ${s['subscribe-email__val-error_visible']}`
      : s['subscribe-email__val-error'];

    return (
      <div {...mergeStyles([s['subscribe-email'], !props.user.userIdentifications && s['subscribe-email--license']])}>
        <Input
          value={emailValue}
          size="M"
          onChange={(_, value: string) => handleInputChild(value)}
          invalid={!isValid}
          placeholder="Ваш email"
        />
        <span className={fieldErrorClassName}>{errorText}</span>
        <div id="send-email" role="button" className={s['subscribe-email-arrow']} onClick={checkEmail}>
          <span className={s['subscribe-email-arrow__icon']} />
        </div>
        {!props.user.userIdentifications && renderLicense()}
      </div>
    );
  }

  const handleInputChild = (emailValue: string) => {
    setEmailValue(emailValue);
  };

  function checkEmail() {
    const isValidInner = validateEmail(emailValue);
    setIsValid(isValidInner);
    if (!isValidInner) {
      showError(emailValue);
    } else {
      props.subscribe(emailValue).then(function (res: ISubmitDigestResponse) {
        const attributes = res && res.data && res.data.attributes;
        if (attributes) {
          Analytics.subscribeMagazine();
          if (attributes.password) {
            Analytics.registration();
          }
        }
      });
    }
  }

  function showError(email: string) {
    let errorText;

    if (!email.length) {
      errorText = 'Укажите email';
    } else {
      errorText = 'Некорректный адрес email';
    }

    setIsValid(false);
    setErrorText(errorText);
    document.addEventListener('click', hideError);
  }

  function hideError(e: IEvent) {
    const target = e.target;
    const sendEmailId = 'send-email';

    if (target.id === sendEmailId || (target.parentNode && target.parentNode.id === sendEmailId)) {
      return;
    }

    setIsValid(true);
    setErrorText('');
    document.removeEventListener('click', hideError);
  }

  const subscribeReplay = () => {
    setIsEmailSend(false);
    setSubscribeDone(false);
  };

  useEffect(() => {
    const RESPONSE_ERROR = 400;
    let email = emailValue;
    if (!emailValue && props.user.email) {
      email = props.user.email;
    }

    if (props.subscribeStatus.status === 'ok') {
      setIsValid(true);
      setErrorText('');
      setIsEmailSend(true);
      setSubscribeDone(true);
      setEmailValue(email);
    } else if (props.subscribeStatus.statusCode === RESPONSE_ERROR) {
      setIsValid(true);
      setErrorText('');
      setIsEmailSend(true);
      setSubscribeDone(false);
      setEmailValue(email);
    } else if (email !== emailValue) {
      setEmailValue(email);
    }
  }, [props.user, props.subscribeStatus]);

  return (
    <form
      className={s['subscribe']}
      onSubmit={e => {
        e.preventDefault();
        checkEmail();
      }}
    >
      <span className={s['subscribe__text']}>Подпишитесь на рассылку</span>
      {!isEmailSend ? renderForm() : renderResponse()}
    </form>
  );
};

/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TGetOffersFromHistoryModel,
  IGetOffersFromHistoryRequest,
  IMappers,
  IGetOffersFromHistoryResponse,
  IGetOffersFromHistoryResponseError,
  TGetOffersFromHistoryResponse,
} from './types';

const defaultConfig: TGetOffersFromHistoryModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'GET',
  microserviceName: 'search-engine',
  pathApi: '/v1/get-offers-from-history/',
  hostType: 'api',
} as TGetOffersFromHistoryModel;

function createGetOffersFromHistoryModel(parameters: IGetOffersFromHistoryRequest): TGetOffersFromHistoryModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetOffersFromHistoryOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IGetOffersFromHistoryRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchGetOffersFromHistory<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IGetOffersFromHistoryOptions<TResponse200, TResponse400>): Promise<
  TResponse200 | TResponse400 | TGetOffersFromHistoryResponse
> {
  const { response, statusCode } = await httpApi.fetch(createGetOffersFromHistoryModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetOffersFromHistoryResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IGetOffersFromHistoryResponseError);
    }
  }

  return { response, statusCode } as TGetOffersFromHistoryResponse;
}

export { defaultConfig, createGetOffersFromHistoryModel, fetchGetOffersFromHistory };

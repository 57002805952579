import { TDeviceType } from "@cian/ui-kit";
import * as React from 'react';
import { connect } from 'react-redux';
import { ca } from '@cian/analytics';
import { RouteComponentProps } from "react-router";
import { useLocation } from "react-router-dom";
import { ThunkDispatch } from "redux-thunk";

import {
  RatingTable as RatingTableComponent,
} from '../../components/rating_table/rating_table';
import { IAnalytics } from "../../types/analytics";

import { IRating } from '../../types/rating';
import { IApplicationState, IThunkContext, TReduxActions } from "../../types/redux";
import { IRegions } from '../../types/regions';
import { IUser } from '../../types/user';
import { getRating } from '../../reducers/modules/rating/rating';
import { getRegions } from '../../reducers/modules/regions/regions';
import { BreadCrumbs } from '../bread_crumbs/bread_crumbs';
import { setBreadCrumb } from '../../reducers/modules/bread_crumbs/bread_crumbs';
import { IBreadItem } from '../../types/bread_crumb';
import { combineDataLayerObject } from '../../../app/helpers/combine_data_layer_object';
import { getBreadCrumbsNames } from '../../../app/helpers/breadcrumbs_helper';
import { IConfig } from '../../types/config';

import s from './rating_table.css';

interface IProps {
  rating: IRating;
  regions: IRegions;
  user: IUser;
  config: IConfig;
  deviceType: TDeviceType;
  analytics: IAnalytics;
}

interface IDispatchProps {
  getRating(year: string, location: string, agentId: string): void;
  getRegions(scheme: string, host: string): void;
  setBreadCrumb(path: IBreadItem[]): void;
}

type Props = IProps & IDispatchProps & RouteComponentProps;

const RatingTableWidget: React.FC<Props> = props => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  React.useEffect(() => {
    const {config, deviceType} = props;
    const year = ((query.get("year") === 'all') ? '' : query.get("year")) || '';
    const location = query.get("location") || config.regionID || '';
    const agentId = query.get("agent_id") || '';

    const crumbs: IBreadItem[] = [
      {path: '/magazine'},
      {path: '/reyting-rieltorov'},
    ];

    if (typeof window !== 'undefined') {
      ca('pageviewSite', combineDataLayerObject(props.analytics, getBreadCrumbsNames(crumbs), deviceType));
    }

    props.getRating(year, location, agentId);
    props.getRegions(config.regionsApiSchemeNode, config.regionsApiHostNode);
    props.setBreadCrumb(crumbs);
  }, []);

  React.useEffect(() => {
    const {config} = props;
    const year = ((query.get("year") === 'all') ? '' : query.get("year")) || '';
    const location = query.get("location") || config.regionID || '';
    const agentId = query.get("agent_id") || '';

    props.getRating(year, location, agentId);
  }, [props.location.search]);

  return <div>
    <div className={s['rating-table__breadcrumbs-wrap']}>
      <BreadCrumbs />
    </div>
    <RatingTableComponent
        regions={props.regions.items}
        rating={props.rating}
        user={props.user}
        config={props.config} />
  </div>;
}

function mapStateToProps(state: IApplicationState): IProps {
  const {
    rating,
    user,
    regions,
    config,
    deviceType,
    analytics,
  } = state;

  return {
    rating,
    user,
    regions,
    config,
    deviceType,
    analytics,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<IApplicationState, IThunkContext, TReduxActions>): IDispatchProps {
  return {
    getRating: (year: string, location: string, agentId: string) => {
      dispatch(getRating(year, location, agentId));
    },
    getRegions: (scheme: string, host: string) => {
      dispatch(getRegions(scheme, host));
    },
    setBreadCrumb: (path: IBreadItem[]) => {
      dispatch(setBreadCrumb(path));
    },
  }
}

export const RatingTable = connect(mapStateToProps, mapDispatchToProps)(RatingTableWidget);

import { asyncComponent } from '../../../app/decorators/async_component';


export default asyncComponent({
  resolve: () => new Promise(resolve =>
    // Webpack's code splitting API w/naming
    require.ensure(
      ['./client_add_form'],
      () => {
        resolve(require('./client_add_form'));
      },
      'client_add_form'
    )
  )
})

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDeviceType } from '@cian/ui-kit';
import { useHistory, useLocation } from 'react-router-dom';

import { Scrolling, ScrollingWithControls } from '../../components/Scrolling';
import { selectTags } from '../../selectors/tags/selectTags';
import { Chip } from '../../components/Chip';
import { IApplicationState, TThunkDispatch } from '../../types/redux';
import { getTags, getTagsReset } from '../../actions/tags';
import { selectCurrentPageMeta } from '../../selectors/pagesMeta';
import { ERequestStatus } from '../../types/requestStatus';
import { Skeleton } from '../../components/Skeleton';
import { transformPageType } from './utils';
import { useSensitiveParams, useValidTag } from '../../utils';
import { EType } from '../../repositories/journal/entities/page_info/PageInfoSchema';
import * as s from './TagScrollingContainer.css';

/** Скроллинг тэгов для мобилки и десктопа */
export const TagScrollingContainer = () => {
  const { search, pathname } = useLocation();
  const dispatch = useDispatch<TThunkDispatch>();
  const {
    type: pageType,
    rubricId,
    pathname: currentPageMetaPathname,
  } = useSelector((state: IApplicationState) => selectCurrentPageMeta(state, pathname));
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const [activeTagId, setActiveTagId] = useState(0);
  const deviceType = useDeviceType();
  const isPhone = () => deviceType === 'phone';
  const isTablet = () => deviceType === 'tablet';
  const { status: tagsStatus, items: tags } = useSelector(selectTags);
  const withTag = useValidTag('with-tag');
  const history = useHistory();
  const key = useSensitiveParams(['rubricId', 'pageType']);
  const [prevKey, setPrevKey] = useState(key);

  /** Запрашивает набор тэгов, если их нет */
  useEffect(() => {
    if (tagsStatus !== ERequestStatus.Initial && key === prevKey) {
      return;
    }

    dispatch(getTags({ pageType: transformPageType(pageType), rubricId: rubricId || 0 })).finally();
    setPrevKey(key);
  }, [dispatch, key, pageType, prevKey, rubricId, tagsStatus]);

  /** Очищает текущий набор тэгов */
  useEffect(() => {
    return () => {
      dispatch(getTagsReset());
    };
  }, [dispatch]);

  /** Устанавливает активный тэг */
  useEffect(() => {
    const activeTag = tags.find(tag => tag.name === withTag);

    setActiveTagId(activeTag?.id || 0);
  }, [tags, withTag]);

  /** Отправляет на страницу поиска по тэгам */
  const handleClick = useCallback(
    (tagName: string) => {
      let pathname = '/magazine/';
      const newSearchParams = new URLSearchParams();

      if (pageType === EType.News) {
        pathname = '/novosti/';
      } else if (pageType === EType.Articles) {
        pathname = '/stati/';
      } else if (pageType === EType.Blogs) {
        pathname = '/blogs/';
      } else if (pageType === EType.Questions) {
        pathname = '/forum-rieltorov/';
      }

      if (pageType === EType.MainPage) {
        newSearchParams.append('with-tag', tagName);
      } else if ([EType.News, EType.Articles, EType.Blogs, EType.Questions].includes(pageType)) {
        newSearchParams.append('tag', tagName);
      }

      history.push(`${pathname}?${newSearchParams.toString()}`);
    },
    [history, pageType],
  );

  const tagsMap = tags.map(({ id, name }, index) => (
    <Chip
      key={`tag-${index}`}
      isActive={activeTagId === id}
      size={isPhone() ? 'M' : 'XS'}
      theme="stroke_secondary"
      onClick={() => handleClick(name)}
      url={`${currentPageMetaPathname}?${searchParams.toString()}`}
    >
      #{name}
    </Chip>
  ));

  return (
    <div className={s['wrapper']} data-web-ui-test-id="TagScrollingContainer">
      {tagsStatus === ERequestStatus.Loading && (
        <div className={s['skeleton-wrapper']}>
          {(isPhone()
            ? ['40%', '20%', '30%']
            : isTablet()
            ? ['20%', '10%', '20%', '10%', '20%']
            : ['20%', '10%', '15%', '10%', '20%', '13%', '10%']
          ).map((percent, index) => (
            <Skeleton key={index} width={percent} height={isPhone() ? 40 : 28} />
          ))}
        </div>
      )}

      {tagsStatus === ERequestStatus.Succeed && tags.length ? (
        isPhone() ? (
          <Scrolling>{tagsMap}</Scrolling>
        ) : (
          <ScrollingWithControls>{tagsMap}</ScrollingWithControls>
        )
      ) : null}
    </div>
  );
};

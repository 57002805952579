import * as React from 'react';
import { Button, Checkbox, MultiSelect } from '@cian/ui-kit';

import { validateEmail } from '../../utils/validateEmail';
import { IRegionsItem } from '../../types/regions';
import { IUser } from '../../types/user';
import { ISubscribe } from '../../types/subscribe';
import { getSubCategory, categories } from '../../../app/helpers/category_helper';
import { Tooltip } from '../tooltip/tooltip';

import s from './subscribe_list.css';

interface IProps {
  listType: string;
  subscribeStatus: ISubscribe;
  currentUser: IUser;
  regions: IRegionsItem[];
  listSubscribe?(email: string, categories?: string[], regions?: number[]): void;
}

interface IState {
  isValid?: boolean;
  errorText?: string;
  isEmailSend?: boolean;
  subscribeDone?: boolean;
  emailText: string;
  showContent: boolean;
  emailValid: boolean;
  selectedRegion: number[];
  selectedCategories: string[];
  dropdownOpen?: boolean;
}

export class SubscribeList extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);
    this.state = {
      isValid: true,
      errorText: '',
      isEmailSend: false,
      subscribeDone: false,
      emailText: props.currentUser ? props.currentUser.email : '',
      showContent: false,
      emailValid: true,
      selectedRegion: [0],
      selectedCategories: Object.keys(categories).filter(key => !!key),
      dropdownOpen: false,
    };
  }

  public componentWillReceiveProps(nextProps: IProps) {
    const RESPONSE_ERROR = 400;

    if (nextProps.subscribeStatus.status === 'ok') {
      this.setState({
        isValid: true,
        errorText: '',
        isEmailSend: true,
        subscribeDone: true,
        emailText: this.state.emailText,
        showContent: this.state.showContent,
        emailValid: true,
        selectedRegion: this.state.selectedRegion,
        selectedCategories: this.state.selectedCategories,
      });
    } else if (nextProps.subscribeStatus.statusCode === RESPONSE_ERROR) {
      this.setState({
        isValid: true,
        errorText: '',
        isEmailSend: true,
        subscribeDone: false,
        emailText: this.state.emailText,
        showContent: this.state.showContent,
        emailValid: true,
        selectedRegion: this.state.selectedRegion,
        selectedCategories: this.state.selectedCategories,
      });
    }
  }

  public render() {
    const category = getSubCategory(this.props.listType.toLowerCase()).category;

    return (
      <div className={s['subscribe-list']}>
        <div
          className={`
            ${s['subscribe-list__header']}
            ${this.state.showContent ? s['subscribe-list__header-open-content-mobile'] : ''}
          `}
          onClick={() => this.setState(Object.assign({}, this.state, { showContent: !this.state.showContent }))}
        >
          <span className={s['subscribe-list__header_mail-icon']} />
          <span className={s['subscribe-list__header_text-dekstop']}>подпишитесь на новые {category}</span>
          <span className={s['subscribe-list__header_text-mobile']}>подписаться на {category}</span>
          <span
            className={`
          ${s['subscribe-list__header_arrow-icon']}
          ${this.state.showContent ? s['subscribe-list__header_arrow-icon-open-content'] : ''}
        `}
          />
        </div>
        {this.state.showContent && (
          <form
            className={s['subscribe-list__content']}
            onSubmit={e => {
              e.preventDefault();
              if (!this.state.isEmailSend) {
                this.onSubmitSubscribe();
              }
            }}
          >
            {this.props.listType.toLowerCase().startsWith('question') && this.renderRegionsCategories()}
            {this.state.isEmailSend ? (
              this.renderSubscribeStatus()
            ) : (
              <div>
                <Tooltip
                  containerStyle={s['tooltip-email']}
                  content={this.state.emailText ? 'Некорректный адрес email' : 'Укажите email'}
                  show={!this.state.emailValid}
                >
                  <input
                    type="text"
                    className={s['subscribe-list__content_email']}
                    disabled={!!this.props.currentUser && !!this.props.currentUser.email}
                    value={this.state.emailText}
                    onChange={(e: React.FormEvent<HTMLInputElement>) =>
                      this.setState(
                        Object.assign({}, this.state, {
                          emailText: (e.currentTarget as HTMLInputElement).value,
                          emailValid: true,
                        }),
                      )
                    }
                    placeholder="Ваш email"
                  />
                </Tooltip>
                <button className={s['subscribe-list__content_button']}>Подписаться</button>
                <div className={s['license']}>
                  <span>При подписке вы принимаете условия</span>
                  <br />
                  <a href="//www.cian.ru/help/about/rules/" target="_blank" className={s['link']} rel="noreferrer">
                    Пользовательского соглашения
                  </a>

                  <span> и </span>
                  <a href="//www.cian.ru/help/about/36526/" target="_blank" className={s['link']} rel="noreferrer">
                    Политики конфиденциальности
                  </a>
                </div>
              </div>
            )}
          </form>
        )}
      </div>
    );
  }

  private onSubmitSubscribe = () => {
    if (!validateEmail(this.state.emailText)) {
      return this.setState(Object.assign({}, this.state, { emailValid: false }));
    }

    this.setState(Object.assign({}, this.state, { emailValid: true }), () => {
      let regions = this.state.selectedRegion;

      if (regions.indexOf(0) !== -1) {
        regions = [];
      }

      if (this.props.listSubscribe) {
        if (!this.props.listType.toLowerCase().startsWith('question')) {
          this.props.listSubscribe(this.state.emailText);
        } else {
          this.props.listSubscribe(this.state.emailText, this.state.selectedCategories, regions);
        }
      }
    });
  };

  private renderRegionsCategories() {
    return (
      <div>
        <div className={s['subscribe-list__wrap-regions']}>
          <span>Регион:</span>
          <div className={s['subscribe-list__wrap-regions_dropdown-wrap']}>
            <MultiSelect
              placeholder="Выберите регион"
              value={this.state.selectedRegion}
              onChange={(value: number[]) => {
                if (value[value.length - 1] === 0) {
                  this.setState(
                    Object.assign({}, this.state, {
                      selectedRegion: [0],
                    }),
                  );
                } else {
                  this.setState(
                    Object.assign({}, this.state, {
                      selectedRegion: value.filter((val: number) => val !== 0),
                    }),
                  );
                }
              }}
              options={this.props.regions.map((region: any) => {
                return {
                  value: region.id,
                  label: region.displayName,
                };
              })}
            />
          </div>
        </div>
        <div className={s['subscribe-list__wrap-categories-wrap']}>
          {Object.keys(categories)
            .filter((key: string) => !!key)
            .map((key: string) => (
              <div
                className={s['subscribe-list__wrap-categories-wrap_checkbox-container-wrap']}
                key={`check-category-${key}`}
              >
                <Checkbox
                  label={categories[key].name}
                  checked={this.state.selectedCategories.includes(key)}
                  onChange={(_, value: boolean) => {
                    const existCategory = this.state.selectedCategories.includes(key);

                    if (value && !existCategory) {
                      this.state.selectedCategories.push(key);
                    } else if (existCategory) {
                      this.state.selectedCategories.splice(this.state.selectedCategories.indexOf(key), 1);
                    }
                    this.forceUpdate();
                  }}
                />
              </div>
            ))}
        </div>
      </div>
    );
  }

  private subscribeSuccess = () => {
    const isQuestion = this.props.listType === 'Question';

    return (
      <div
        className={`
          ${s['subscribe-list__success']}
          ${isQuestion ? s['subscribe-list__success-question'] : ''}
        `}
      >
        <span className={s['subscribe-list__success_header']}>Подписка оформлена!</span>
        <span className={s['subscribe-list__success_text']}>
          Мы будем присылать письма на{' '}
          <span className={s['subscribe-list__success_email']}>{this.state.emailText}</span>
        </span>
      </div>
    );
  };

  private subscribeFailure = () => {
    const isQuestion = this.props.listType === 'Question';

    return (
      <div
        className={`
          ${s['subscribe-list__error']}
          ${isQuestion ? s['subscribe-list__error-question'] : ''}
        `}
      >
        <span className={s['subscribe-list__error_header']}>Что-то пошло не так, попробуйте подписаться еще раз</span>
        <Button
          theme="fill_primary"
          size="XS"
          onClick={() =>
            this.setState(
              Object.assign({}, this.state, {
                isValid: true,
                errorText: '',
                isEmailSend: false,
                subscribeDone: false,
                emailText: this.props.currentUser ? this.props.currentUser.email : '',
                showContent: true,
                emailValid: true,
              }),
            )
          }
        >
          Повторить
        </Button>
      </div>
    );
  };

  private renderSubscribeStatus = () => {
    return this.state.subscribeDone ? this.subscribeSuccess() : this.subscribeFailure();
  };
}

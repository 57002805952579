import { IRegions, IRegionsAction, ERegionsStatus } from '../../../types/regions';

export const GET_REGIONS = 'get_regions/ACTION';
export const GET_REGIONS_REQUEST = 'get_regions/ACTION_REQUEST';
export const GET_REGIONS_SUCCESS = 'get_regions/ACTION_SUCCESS';
export const GET_REGIONS_FAILURE = 'get_regions/ACTION_FAILURE';

export const initialState: IRegions = {
  items: [],
  status: ERegionsStatus.Initial,
};

const defaultAction: IRegionsAction = {
  type: '',
  payload: [],
};

export const regionsReducer = (state = initialState, action = defaultAction) => {
  switch (action.type) {
    case GET_REGIONS_REQUEST:
      return { ...state, status: ERegionsStatus.Loading };

    case GET_REGIONS_SUCCESS:
      const regions = (action.payload as any).data.items.map((region: {
          id: number,
          displayName: string,
          baseHost: string,
          mainTownId: number,
        }) => {
        return {
          id: region.id,
          displayName: region.displayName,
          baseHost: region.baseHost,
          mainTownId: region.mainTownId,
        };
      });

      regions.unshift({ id: 0, displayName: 'Любой' });

      return Object.assign({}, state, { items: regions, status: ERegionsStatus.Succeed });

    case GET_REGIONS_FAILURE:
      return Object.assign({}, { ...state, status: ERegionsStatus.Failed });

    default:
      return state;
  }
};

export const getRegions = (scheme: string, host: string) => {
  return {
    type: GET_REGIONS,
    method: 'GET',
    payload: {
      scheme,
      host,
      pathname: '/cian-api/site/v1/get-regions/',
      basePath: '',
    },
    request: true,
  };
};

import * as React from 'react';
import { Link } from 'react-router-dom';
import { unescapeHtml } from '../../../app/helpers/make_html_helper';

import s from './bread_crumbs.css';

interface IProps {
  path: string;
  name?: string;
  url?: string;
  isLastItem: boolean;
  position: number;
}

export const pages = [
  {
    name: 'Журнал',
    pathname: '/magazine',
    url: '/magazine/',
  },
  {
    name: 'Новости',
    pathname: '/novosti',
    url: '/novosti/',
  },
  {
    name: 'Статьи',
    pathname: '/stati',
    url: '/stati/',
  },
  {
    name: 'Блоги',
    pathname: '/blogs',
    url: '/blogs/',
  },
  {
    name: 'Мой блог',
    pathname: '/moi-blog',
    url: '/moi-blog/',
  },
  {
    name: 'Вопросы риелтору',
    pathname: '/forum-rieltorov',
    url: '/forum-rieltorov/',
  },
  {
    name: 'Интервью',
    pathname: 'interview',
    url: '/stati/?category=interview',
  },
  {
    name: 'Обзор',
    pathname: 'review',
    url: '/stati/?category=review',
  },
  {
    name: 'Аналитика',
    pathname: 'analytics',
    url: '/stati/?category=analytics',
  },
  {
    name: 'Тенденции рынка',
    pathname: 'b-market_trend',
    url: '/blogs-tendentsiy-rynka/',
  },
  {
    name: 'Новости рынка',
    pathname: 'market',
    url: '/novosti/?category=market',
  },
  {
    name: 'Новости компании',
    pathname: 'company',
    url: '/novosti/?category=company',
  },
  {
    name: 'Специалист–специалисту',
    pathname: 'b-specialist',
    url: '/blogs-specialist/',
  },
  {
    name: 'Статьи',
    pathname: '/preview',
    url: '',
  },
  {
    name: 'Добавить блог',
    pathname: '/add-blog',
    url: '/add-blog/',
  },
  {
    name: 'Тенденции рынка',
    pathname: 'q-market_trend',
    url: '/forum-rieltorov-tendentsiy-rynka/',
  },
  {
    name: 'Специалист–специалисту',
    pathname: 'q-specialist',
    url: '/forum-rieltorov-specialist/',
  },
  {
    name: 'Городская недвижимость',
    pathname: 'q-city_realty',
    url: '/forum-rieltorov-gorodskaya-nedvizhimost/',
  },
  {
    name: 'Коммерческая недвижимость',
    pathname: 'q-commerce_realty',
    url: '/forum-rieltorov-kommercheskaya-nedvizhimost/',
  },
  {
    name: 'Загородная недвижимость',
    pathname: 'q-country_realty',
    url: '/forum-rieltorov-zagorodnaya-nedvizhimost/',
  },
  {
    name: 'Зарубежная недвижимость',
    pathname: 'q-foreign_realty',
    url: '/forum-rieltorov-zarubezhnaya-nedvizhimost/',
  },
  {
    name: 'Ипотека',
    pathname: 'q-hypothec',
    url: '/forum-rieltorov-ipoteka/',
  },
  {
    name: 'Задать вопрос',
    pathname: '/add-vopros',
    url: '/add-vopros/',
  },
  {
    name: 'Рейтинг специалистов',
    pathname: '/reyting-rieltorov',
    url: '/reyting-rieltorov/',
  },
];

export const BreadCrumb: React.FC<IProps> = props => {
  let currentPage;

  if (props.name && props.url) {
    currentPage = {
      url: unescapeHtml(props.url),
      name: unescapeHtml(props.name),
    };
  } else {
    const { path } = props;

    currentPage = pages.find(page => path === page.pathname);

    if (!currentPage) {
      return <div />;
    }
  }

  return (
    <li itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem" className={s['breadcrumbs__item']}>
      {props.isLastItem ? (
        <span itemProp="name" className={s['breadcrumbs__item-empty']}>
          {currentPage.name}
        </span>
      ) : (
        <Link className={s['breadcrumbs__link']} to={currentPage.url.replace(/([^/])$/, '$1/')} itemProp="item">
          <span itemProp="name">{currentPage.name}</span>
        </Link>
      )}
      <meta itemProp="position" content={props.position.toString()} />
    </li>
  );
};

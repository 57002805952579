import { getTime } from '@cian/utils';

import { formatDate } from '../../../../../utils';

/** Приводит дату-время к 2-м форматам: "сегодня в 21:00" или "9 мая 2023" */
export const getDate = (date: Date) => {
  const dateFormatted = formatDate(date, { dayNames: ['сегодня'] });

  return dateFormatted === 'сегодня' ? `${dateFormatted} в ${getTime(date)}` : dateFormatted;
};

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IconButton, Input, Search16 as IconSearch16, useDeviceType } from '@cian/ui-kit';

import { IconClose24 } from '../Icons';
import { useEventListener } from '../../utils';
import * as s from './QuickSearchingModal.css';

interface IQuickSearchingModal {
  isOpened: boolean;
  children?: React.ReactNode;
  inputValue: string;
  onClose(): void;
  onSubmit(): void;
  onChange(value: string): void;
}

/** Модалка быстрого поиска */
export const QuickSearchingModal = ({
  isOpened,
  children,
  inputValue,
  onClose,
  onSubmit,
  onChange,
}: IQuickSearchingModal) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [isShown, setIsShown] = useState(isOpened);
  const deviceType = useDeviceType();

  /** Фиксирует body и скролит вверх */
  useEffect(() => {
    if (isOpened) {
      window.scrollTo(0, 0);
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpened]);

  /**
   * Вычисляет высоту модалки и только затем отображает ее (плавно),
   * чтобы устранить дребезг по высоте
   */
  useEffect(() => {
    if (!wrapperRef?.current) {
      return;
    }

    if (isOpened) {
      const headerRoot = document.getElementById('header-frontend');

      if (!headerRoot) {
        return;
      }

      const { height } = headerRoot.getBoundingClientRect();
      const mobileMenuHeight = deviceType === 'desktop' ? 0 : 50;

      wrapperRef.current.style.height = `calc(100vh - ${height + mobileMenuHeight}px)`;
      wrapperRef.current.style.top = `${mobileMenuHeight}px`;
      wrapperRef.current.style.opacity = '1';
      setIsShown(true);
    } else {
      wrapperRef.current.style.opacity = '0';
      setIsShown(false);
      wrapperRef.current.style.height = 'auto';
    }
  }, [deviceType, isOpened]);

  /** Вызывает callback при нажатии на Escape/Enter */
  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.code === 'Escape') {
        onClose();
      }

      if (e.code === 'Enter') {
        onSubmit();
      }
    },
    [onClose, onSubmit],
  );

  /**
   * Если форма скрыта, то подменяем реф инпута рефом враппера,
   * иначе будем слушать keydown для window
   */
  useEventListener('keydown', handleKeyDown, inputRef?.current ? inputRef : wrapperRef);

  /** При изменении значения запоминает его и отправляет родителю */
  const handleChange = useCallback(
    (value: string) => {
      onChange(value);
    },
    [onChange],
  );

  return (
    <div className={s['wrapper']} ref={wrapperRef}>
      {isShown && (
        <>
          <div className={s['header-wrapper']}>
            <div className={s['header']}>
              <IconSearch16 />

              <div className={s['input-wrapper']}>
                <Input
                  ref={inputRef}
                  autoFocus
                  size={'XS'}
                  value={inputValue}
                  maxLength={100}
                  placeholder="Поиск по журналу"
                  onChange={(e, value) => handleChange(value)}
                />
              </div>

              <IconButton icon={<IconClose24 />} onClick={onClose} />
            </div>
          </div>

          <div className={s['content-wrapper']}>
            <div className={s['content']}>{children}</div>
          </div>
        </>
      )}
    </div>
  );
};

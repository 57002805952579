import * as React from 'react';

/** TODO: выпилить это. Заменить на useLocation */
// eslint-disable-next-line import/no-extraneous-dependencies
import { Location } from 'history';

import { Comment } from './comment';
import { CommentForm } from './comment_form';
import { IComment, ICommentState } from '../../types/posts';
import { IUser } from '../../types/user';
import { IScrollComment } from '../../types/scroll_comment';

import s from './comments.css';

interface IProps {
  location: Location;
  canComment: boolean;
  canSubscribeComments: boolean;
  user: IUser;
  likesShown: boolean;
  author?: IUser;
  restrictComments: boolean;
  count: number;
  commentsState: ICommentState;
  comments?: IComment[];
  onCommentEdit(id: number, comment: string, email: string): void;
  onCommentDelete(id: number, email: string): void;
  onCommentHide(id: number, email: string): void;
  onCommentSubmit(
    comment: string,
    userId: number,
    fullName: string,
    email: string,
    parentID: number | null,
    subs: boolean,
  ): void;
  showSubscribeDialog(): void;
  showUnsubscribeDialog(): void;
  subscribed?: boolean;
  scrollCommentState: IScrollComment;
  scrollCommentDisabled(): void;
  cardType: string;
}

interface IState {
  addingComment: boolean;
  commentParentID: number;
  editingComment: boolean;
}

class Comments extends React.Component<IProps, IState> {
  public commentsCount = 0;
  private readonly wrapperRef: React.RefObject<HTMLDivElement>;

  public constructor(props: IProps) {
    super(props);
    this.state = {
      addingComment: false,
      commentParentID: -1,
      editingComment: false,
    };

    this.wrapperRef = React.createRef();
  }

  public componentDidMount() {
    if (this.props.scrollCommentState?.isScrollComments) {
      this.scrollToComments();
    }
  }

  public componentDidUpdate() {
    if (this.props.scrollCommentState?.isScrollComments) {
      this.scrollToComments();
    }
  }

  // eslint-disable-next-line react/no-deprecated
  public componentWillReceiveProps(nextProps: IProps) {
    if (
      !nextProps.commentsState.error &&
      nextProps.commentsState.loaded &&
      !this.props.scrollCommentState.isScrollForm
    ) {
      this.setState({
        addingComment: false,
        commentParentID: -1,
        editingComment: false,
      });
    }

    if (nextProps.scrollCommentState.isScrollForm) {
      this.setState({
        addingComment: true,
        commentParentID: -1,
        editingComment: false,
      });
    }
  }

  public render() {
    return (
      <div className={s['comments']} ref={this.wrapperRef}>
        <div className={s['comments__headline']}>
          <span className={s['comments___headline_title']}>{this.headlineTitle} </span>
          <span className={s['comments__headline_num']} itemProp="commentCount">
            {this.getCommentsCount()}
          </span>
          {this.props.canSubscribeComments && <span className={s['subscribe-wrap']}>{this.subscribe()}</span>}
        </div>
        {this.props.comments && this.props.comments.length > 0 && (
          <div className={s['comments__list']}>
            {this.props.comments.map((comment: IComment) => (
              <Comment
                location={this.props.location}
                canComment={this.props.canComment}
                user={this.props.user}
                likesShown={this.props.likesShown}
                author={this.props.author}
                restrictComments={this.props.restrictComments}
                comment={comment}
                onCommentSubmit={(
                  commentText: string,
                  userId: number,
                  fullName: string,
                  email: string,
                  parentID: number | null,
                  subs: boolean,
                ) => this.props.onCommentSubmit(commentText, userId, fullName, email, parentID, subs)}
                onCommentEdit={this.onCommentEdit}
                onCommentDelete={this.onCommentDelete}
                onCommentHide={this.onCommentHide}
                commentsState={this.props.commentsState}
                key={`comment-${comment.id}`}
                cardType={this.props.cardType}
              />
            ))}
          </div>
        )}
        {this.props.canComment && (
          <CommentForm
            onClose={() =>
              this.setState({
                addingComment: false,
                commentParentID: -1,
                editingComment: false,
              })
            }
            onOpen={this.handleOpenCommentForm}
            onCommentSubmit={(
              comment: string,
              userId: number,
              fullName: string,
              email: string,
              parentID: number | null,
              subs: boolean,
            ) => this.props.onCommentSubmit(comment, userId, fullName, email, parentID, subs)}
            commentsState={this.props.commentsState}
            onCommentEdit={this.onCommentEdit}
            replyTo=""
            isOpened={this.state.addingComment}
            parentID={null}
            user={this.props.user}
            scrollCommentState={this.props.scrollCommentState}
            scrollCommentDisabled={this.props.scrollCommentDisabled}
            cardType={this.props.cardType}
          />
        )}
      </div>
    );
  }

  private handleOpenCommentForm = () => {
    if (this.props.user.userId === -1) {
      window.waitForLoginPopup();

      return;
    }

    this.setState({
      addingComment: true,
      commentParentID: -1,
      editingComment: false,
    });
  };

  protected onCommentEdit = (id: number, comment: string, email: string) => {
    this.props.onCommentEdit(id, comment, email);
  };

  protected onCommentDelete = (id: number, email: string) => {
    this.props.onCommentDelete(id, email);
  };

  protected onCommentHide = (id: number, email: string) => {
    this.props.onCommentHide(id, email);
  };

  private get headlineTitle() {
    const { cardType } = this.props;

    switch (cardType.toLowerCase()) {
      case 'articles':
      case 'news':
      case 'blog':
        return 'Комментарии';

      case 'question':
        return 'Советы';

      default:
        return 'Комментарии';
    }
  }

  private scrollToComments = () => {
    if (this.wrapperRef?.current) {
      this.wrapperRef.current.scrollIntoView({ behavior: 'smooth' });
    }

    if (this.props.scrollCommentDisabled) {
      this.props.scrollCommentDisabled();
    }
  };

  private subscribe = () => {
    const isQuestion = this.props.cardType === 'Question';

    if (this.props.subscribed) {
      return (
        <span role="button" onClick={() => this.props.showUnsubscribeDialog()} className={s['comments__subscribe']}>
          Отписаться от {isQuestion ? 'советов' : 'комментариев'}
        </span>
      );
    }

    return (
      <span role="button" onClick={() => this.props.showSubscribeDialog()} className={s['comments__subscribe']}>
        Подписаться на {isQuestion ? 'советы' : 'комментарии'}
      </span>
    );
  };

  private getCommentsCount = () => {
    let count = 0;

    if (!this.props.comments) {
      return count;
    }

    for (let i = 0; i < this.props.comments.length; i++) {
      count++;
      for (let j = 0; j < this.props.comments[i].comments.length; j++) {
        count++;
      }
    }

    return count;
  };
}

export { Comments };

import * as React from 'react';
import { connect } from 'react-redux';

import { SubscribeList as SubscribeComponent } from '../../components/subscribe/subscribe_list';
import { subscribeToList } from '../../reducers/modules/list_subscribe/list_subscribe';
import { IRegions } from '../../types/regions';
import { ISubscribe } from '../../types/subscribe';
import { IUser } from '../../types/user';
import { TDispatch } from "../../types/redux";

interface IProps {
  subscribeStatus: ISubscribe;
  currentUser: IUser;
  regions: IRegions;
  listType: string;
  dispatch: TDispatch;
}

interface IContainerProps {
  listType: string;
}

interface ISubscribeType {
  [key: string]: string;
  blog: string;
  question: string;
}

class ListSubscribeContainer extends React.Component<IProps> {
  public render() {
    const subscribeType: ISubscribeType = {
      blog: 'blogs',
      question: 'questions',
    };

    return (
        <SubscribeComponent
            subscribeStatus={this.props.subscribeStatus}
            listType={this.props.listType}
            currentUser={this.props.currentUser}
            regions={this.props.regions.items}
            listSubscribe={(email: string, categories?: string[], regions?: number[]) =>
              this.props.dispatch(
                subscribeToList( subscribeType[this.props.listType.toLowerCase()], email, categories, regions),
              )
            } />
    );
  }
}

export const ListSubscribe = connect((state: {
    listSubscribe: ISubscribe,
    currentUser: IUser,
    regions: IRegions
  }, props: IContainerProps) => {
  return {
    subscribeStatus: state.listSubscribe,
    currentUser: state.currentUser,
    regions: state.regions,
    listType: props.listType,
  };
})(ListSubscribeContainer);

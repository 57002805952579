import React, { useCallback, useState } from 'react';

import { Collapser, ICollapserParams } from './Collapser';

interface ICollapserUncontrolledParams extends Omit<ICollapserParams, 'isOpen'> {
  /** Значение состояния по умолчанию */
  isDefaultOpened?: boolean;
}

/**
 * Неконтролируемый коллапсер контента
 */
export const CollapserUncontrolled = ({
  isDefaultOpened = false,
  children,
  onToggle,
  ...props
}: ICollapserUncontrolledParams) => {
  const [isOpened, setIsOpened] = useState(isDefaultOpened);

  const handleToggle = useCallback(
    (isOpened: boolean) => {
      onToggle(isOpened);
      setIsOpened(isOpened => !isOpened);
    },
    [onToggle],
  );

  return (
    <Collapser isOpen={isOpened} onToggle={handleToggle} {...props}>
      {children}
    </Collapser>
  );
};

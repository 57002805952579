import React from 'react';

import { SearchButtonContainer } from '../Search/SearchButton';
import { RegionsButtonContainer } from '../Regions/RegionsButton';
import { SearchAndRegionsButtonsLayout } from '../../components/SearchAndRegionsButtonsLayout';
import { PostCardHeaderLayout } from '../../components/PostCardHeaderLayout';

/**
 * Шапка карточки поста по умолчанию
 */
export const PostCardHeaderContainer = () => (
  <PostCardHeaderLayout>
    <SearchAndRegionsButtonsLayout>
      <SearchButtonContainer />
      <RegionsButtonContainer />
    </SearchAndRegionsButtonsLayout>
  </PostCardHeaderLayout>
);

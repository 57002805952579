import { ca } from '@cian/analytics';

export function trackPollAnswerClick(label: string) {
  ca('event', {
    name: 'oldevent',
    category: 'Magazine',
    action: 'quiz_answer',
    label,
  });
}

/**
 * Отфильтровывает аватар, если это заглушка из ЛК
 */
export const prepareUserAvatar = (url?: string) => {
  if (!url || url.includes('cian.ru/avatar.axd')) {
    return undefined;
  }

  return url;
};

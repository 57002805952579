import { ITypedReduxAction } from '../../../types/redux/actionType';
import { TAuthorArticlesItem } from '../../../types/authorArticles';

export enum EGetAuthorArticlesActionType {
  Loading = 'authorArticles/getAuthorArticles/loading',
  Succeed = 'authorArticles/getAuthorArticles/succeed',
  Failed = 'authorArticles/getAuthorArticles/failed',
  Reset = 'authorArticles/getAuthorArticles/reset',
}

export type TGetAuthorArticlesLoading = ITypedReduxAction<EGetAuthorArticlesActionType.Loading>;
export type TGetAuthorArticlesSucceed = ITypedReduxAction<EGetAuthorArticlesActionType.Succeed, TAuthorArticlesItem[]>;
export type TGetAuthorArticlesFailed = ITypedReduxAction<EGetAuthorArticlesActionType.Failed>;
export type TGetAuthorArticlesReset = ITypedReduxAction<EGetAuthorArticlesActionType.Reset>;

export type TGetAuthorArticles =
  | TGetAuthorArticlesLoading
  | TGetAuthorArticlesSucceed
  | TGetAuthorArticlesFailed
  | TGetAuthorArticlesReset;

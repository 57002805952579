export interface IDateRange {
  from?: string;
  to?: string;
}

/**
 * Парсит строку с датами вида ':2024-05-20;:;2024-05-30;2024-05-29:;2024-05-28:2024-05-29'
 * в структуру [{ from: '...', to: '...' }, {...}]
 * @example
 * Поддерживает форматы дат:
 * - '2024-05-30:2024-05-31' - преобразует в { from: '2024-05-30', to: '2024-05-31' }
 * - '2024-05-30:' - преобразует в { from: '2024-05-30' } - без даты окончания
 * - ':2024-05-30' - преобразует в { to: '2024-05-30' } - без даты начала
 * - '2024-05-30' - преобразует в { from: '2024-05-30', to: '2024-05-30' } - только один день
 */
export const parseDateRanges = (dateRangesString: string) => {
  if (!dateRangesString) {
    return [];
  }

  return dateRangesString.split(';').reduce((acc, pair) => {
    const dates = pair.split(':');

    if (dates[0] || dates[1]) {
      acc.push({
        ...(dates[0] ? { from: dates[0] } : {}),
        ...(dates[1] ? { to: dates[1] } : {}),
        ...(dates[0] && dates.length === 1 ? { to: dates[0] } : {}),
      });
    }

    return acc;
  }, [] as IDateRange[]);
};

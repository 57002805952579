import { IRecommendedOfferSchema } from '../../../repositories/search-engine/entities/recommended_offers/RecommendedOfferSchema';

export interface ITransformCustomUrl {
  url: IRecommendedOfferSchema['url'];
  isFromBuilder: IRecommendedOfferSchema['isFromBuilder'];
}

/**
 * Собирает CustomUrl для CA
 */
export const transformCustomUrl = ({ url, isFromBuilder }: ITransformCustomUrl): string => {
  return `${url}from_developer=${Number(isFromBuilder)}`;
};

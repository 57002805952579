import { IPostRequest } from '../../../types/post_request';
import { IUser } from '../../../types/user';

export const COMMENT = 'comment/ACTION';
export const COMMENT_REQUEST = 'comment/ACTION_REQUEST';
export const COMMENT_SUCCESS = 'comment/ACTION_SUCCESS';
export const COMMENT_FAILURE = 'comment/ACTION_FAILURE';
export const COMMENT_EDIT = 'comment_edit/ACTION';
export const COMMENT_EDIT_REQUEST = 'comment_edit/ACTION_REQUEST';
export const COMMENT_EDIT_SUCCESS = 'comment_edit/ACTION_SUCCESS';
export const COMMENT_EDIT_FAILURE = 'comment_edit/ACTION_FAILURE';
export const COMMENT_DELETE = 'comment_delete/ACTION';
export const COMMENT_DELETE_REQUEST = 'comment_delete/ACTION_REQUEST';
export const COMMENT_DELETE_SUCCESS = 'comment_delete/ACTION_SUCCESS';
export const COMMENT_DELETE_FAILURE = 'comment_delete/ACTION_FAILURE';
export const COMMENT_HIDE = 'comment_hide/ACTION';
export const COMMENT_HIDE_REQUEST = 'comment_hide/ACTION_REQUEST';
export const COMMENT_HIDE_SUCCESS = 'comment_hide/ACTION_SUCCESS';
export const COMMENT_HIDE_FAILURE = 'comment_hide/ACTION_FAILURE';

export const initialState = {
  loaded: true,
  error: undefined,
};

const defaultAction = {
  type: '',
  payload: {
    loaded: true,
  },
  error: {
    detail: '',
    status: '',
    pointer: '',
  },
};

export const commentReducer = (state = initialState, action = defaultAction) => {
  switch (action.type) {
    case COMMENT_REQUEST:
    case COMMENT_EDIT_REQUEST:
    case COMMENT_DELETE_REQUEST:
    case COMMENT_HIDE_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
        error: undefined,
      });

    case COMMENT_SUCCESS:
    case COMMENT_EDIT_SUCCESS:
    case COMMENT_DELETE_SUCCESS:
    case COMMENT_HIDE_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        error: undefined,
      });

    case COMMENT_FAILURE:
    case COMMENT_EDIT_FAILURE:
    case COMMENT_DELETE_FAILURE:
    case COMMENT_HIDE_FAILURE:
      return Object.assign({}, state, {
        loaded: true,
        error: action.error,
      });

    default:
      return state;
  }
};

export const submitComment = (
  itemId: string,
  contentType: string,
  comment: string,
  userId: number,
  fullName: string,
  email: string,
  parentID: number | null,
  subs: boolean,
  user: IUser,
): IPostRequest => {
  return {
    type: COMMENT,
    method: 'POST',
    payload: {
      pathname: '/v1/add-comment',
      data: {
        attributes: {
          comment,
          user_id: (userId >= 0) ? userId : null,
          first_name: fullName,
          email,
          content_type_model: contentType.toLowerCase(),
          object_pk: itemId,
          parent_id: parentID,
          is_subscribed_to_comments: subs,
        },
      },
    },
    request: true,
    additionalFields: {
      user,
    },
  };
};

export const editComment = (id: number, comment: string, email: string, user: IUser) => {
  return {
    type: COMMENT_EDIT,
    method: 'POST',
    payload: {
      pathname: '/v1/edit-comment',
      params: [{
        name: 'id',
        value: id,
      }],
      data: {
        attributes: { comment },
      },
    },
    request: true,
    additionalFields: {
      user,
    },
  };
};

export const deleteComment = (id: number, email: string) => {
  return {
    type: COMMENT_DELETE,
    method: 'POST',
    payload: {
      pathname: '/v1/delete-comment',
      params: [{
        name: 'id',
        value: id,
      }],
    },
    request: true,
  };
};

export const hideComment = (id: number, email: string) => {
  return {
    type: COMMENT_HIDE,
    method: 'POST',
    payload: {
      pathname: '/v1/hide-comment',
      params: [{
        name: 'id',
        value: id,
      }],
    },
    request: true,
  };
};

import React, { forwardRef } from 'react';
import { UIHeading1 } from '@cian/ui-kit';
import { ITypographyComponentProps } from '@cian/ui-kit/typography/types';

import * as s from './UIHeading0.css';

/**
 * Недостающий элемент 0-ого заголовка ui-kit'а
 */
export const UIHeading0 = forwardRef<HTMLSpanElement, ITypographyComponentProps>(({ children, ...props }, ref) => (
  <span className={s['wrapper']}>
    <UIHeading1 {...props} ref={ref}>
      {children}
    </UIHeading1>
  </span>
));

UIHeading0.displayName = 'UIHeading0';

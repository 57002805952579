import React, { useEffect } from 'react';
import { renderToString } from 'react-dom/server';
import { hydrate } from 'react-dom';
import { TDeviceType } from '@cian/ui-kit';

import { IExtractAndReplaceBlockquotesItem } from './extractAndReplaceBlockquotes';
import { PostBlockquote } from '../../../components/PostBlockquote';
import { TPostBlockquoteTheme } from '../../../components/PostBlockquote/PostBlockquote';
import { UserCard } from '../../../components/UserCard';
import { PostBlockquoteUserCardLayout } from '../../../components/PostBlockquoteUserCardLayout';

interface IUseContentBeforeAfterGalleries {
  html: string;
  blockquotes: IExtractAndReplaceBlockquotesItem[];
  deviceType: TDeviceType;
}

/**
 * Рендерит и управляет Цитатами
 */
export const useContentBlockquotes = ({ html, blockquotes, deviceType }: IUseContentBeforeAfterGalleries): string => {
  /** Рендерит для CSR */
  useEffect(() => {
    blockquotes.forEach(({ image, description, theme, userCard, id }: IExtractAndReplaceBlockquotesItem) => {
      const container = document.getElementById(id);

      if (container) {
        hydrate(
          <PostBlockquote image={image} text={description} theme={theme as TPostBlockquoteTheme}>
            {userCard?.title && (
              <PostBlockquoteUserCardLayout isWhiteColor={theme === 'blue' && true}>
                <UserCard
                  imageSrc={userCard.image}
                  name={userCard.title}
                  description={userCard.description}
                  size={deviceType === 'phone' ? 'M' : 'L'}
                />
              </PostBlockquoteUserCardLayout>
            )}
          </PostBlockquote>,
          container,
        );
      }
    });
  }, [blockquotes, deviceType]);

  /** Рендерит для SSR */
  if (typeof window === 'undefined') {
    return blockquotes.reduce((acc, { image, description, theme, userCard, id }) => {
      return acc.replace(
        new RegExp(`<div id="${id}"[^>]*?></div>`),
        `<div id="${id}">${renderToString(
          <PostBlockquote image={image} text={description} theme={theme as TPostBlockquoteTheme}>
            {userCard?.title && (
              <PostBlockquoteUserCardLayout isWhiteColor={theme === 'blue' && true}>
                <UserCard
                  imageSrc={userCard.image}
                  name={userCard.title}
                  description={userCard.description}
                  size={deviceType === 'phone' ? 'M' : 'L'}
                />
              </PostBlockquoteUserCardLayout>
            )}
          </PostBlockquote>,
        )}</div>`,
      );
    }, html);
  }

  return html;
};

import { AsyncProps } from '../../shared/types/async_props';

interface ICategory {
  linkType: string;
  category: string;
}

export const getCategory = (type: string): ICategory => {
  const types = new Map([
    ['articles', { linkType: 'stati', category: 'статьи' }],
    ['news', { linkType: 'novosti', category: 'новости' }],
    ['blog', { linkType: 'blogs', category: 'блоги' }],
    ['blogs', { linkType: 'blogs', category: 'блоги' }],
    ['question', { linkType: 'forum-rieltorov', category: 'советы' }],
    ['questions', { linkType: 'forum-rieltorov', category: 'советы' }],
  ]);
  const linkTypeObj = types.get(type.toLowerCase());

  return {
    linkType: linkTypeObj ? linkTypeObj.linkType : '',
    category: linkTypeObj ? linkTypeObj.category : '',
  };
};

export const getSubCategory = (type: string): ICategory => {
  const types = new Map([
    ['articles', { linkType: 'stati', category: 'статьи' }],
    ['news', { linkType: 'novosti', category: 'новости' }],
    ['blog', { linkType: 'blogs', category: 'блоги' }],
    ['blogs', { linkType: 'blogs', category: 'блоги' }],
    ['question', { linkType: 'voprosy', category: 'вопросы' }],
    ['questions', { linkType: 'voprosy', category: 'вопросы' }],
  ]);
  const linkTypeObj = types.get(type.toLowerCase());

  return {
    linkType: linkTypeObj ? linkTypeObj.linkType : '',
    category: linkTypeObj ? linkTypeObj.category : '',
  };
};

export const categoryFromProps = (props: AsyncProps) =>
  props.params.category || props.location.query.category || '';

export const categories: { [key: string]: { link: string, name: string }; } = {
  '': { link: '', name: '' },
  'specialist': { link: 'specialist', name: 'Специалист–специалисту' },
  'market_trend': { link: 'tendentsiy-rynka', name: 'Тенденции рынка' },
  'city_realty': { link: 'gorodskaya-nedvizhimost', name: 'Городская недвижимость' },
  'commerce_realty': { link: 'kommercheskaya-nedvizhimost', name: 'Коммерческая недвижимость' },
  'country_realty': { link: 'zagorodnaya-nedvizhimost', name: 'Загородная недвижимость' },
  'foreign_realty': { link: 'zarubezhnaya-nedvizhimost', name: 'Зарубежная недвижимость' },
  'hypothec': { link: 'ipoteka', name: 'Ипотека' },
};

import { ITags, ITagsAction } from '../../../types/tags';

export const GET_TAGS = 'get_tags/ACTION';
export const GET_TAGS_REQUEST = 'get_tags/ACTION_REQUEST';
export const GET_TAGS_SUCCESS = 'get_tags/ACTION_SUCCESS';
export const GET_TAGS_FAILURE = 'get_tags/ACTION_FAILURE';

export const initialState: ITags = {
  items: [],
};

const defaultAction: ITagsAction = {
  type: '',
  payload: {
    data: [],
  },
};

export const tagsReducer = (state = initialState, action = defaultAction) => {
  switch (action.type) {
    case GET_TAGS_REQUEST:
      return state;

    case GET_TAGS_SUCCESS:
      return Object.assign({}, state, { items: action.payload.data });

    case GET_TAGS_FAILURE:
      return Object.assign({}, state);

    default:
      return state;
  }
};

export const getTags = () => {
  return {
    type: GET_TAGS,
    method: 'GET',
    payload: {
      pathname: '/v1/get-tag-list',
      params: [{
        name: 'ordering',
        value: 'name',
      }],
    },
    request: true,
  };
};
